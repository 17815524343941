import React from 'react'

const Instagram = () => (
    <svg width={28} height={28} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 28 28'>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14 1.731c-3.395 0-3.82.015-5.154.076-1.33.061-2.239.274-3.034.585a6.123 6.123 0 0 0-2.214 1.452 6.174 6.174 0 0 0-1.442 2.229c-.309.8-.52 1.715-.58 3.055-.062 1.342-.076 1.771-.076 5.19 0 3.417.014 3.846.075 5.188.06 1.34.272 2.255.581 3.055.32.828.747 1.53 1.442 2.23s1.392 1.13 2.214 1.451c.795.311 1.704.524 3.034.585 1.334.061 1.76.076 5.154.076 3.395 0 3.82-.015 5.154-.076 1.33-.06 2.239-.274 3.034-.585a6.124 6.124 0 0 0 2.214-1.451 6.174 6.174 0 0 0 1.442-2.23c.309-.8.52-1.715.58-3.055.062-1.342.076-1.77.076-5.189 0-3.418-.014-3.847-.075-5.189-.06-1.34-.272-2.255-.581-3.055a6.174 6.174 0 0 0-1.442-2.23 6.123 6.123 0 0 0-2.214-1.45c-.795-.312-1.704-.525-3.034-.586-1.334-.061-1.76-.076-5.154-.076Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        d="M13.432 9.346c1.518 0 2.892.62 3.887 1.622a5.547 5.547 0 0 1 1.613 3.921 5.547 5.547 0 0 1-1.613 3.921 5.462 5.462 0 0 1-3.887 1.622 5.462 5.462 0 0 1-3.887-1.622 5.547 5.547 0 0 1-1.613-3.92c0-1.532.616-2.919 1.613-3.922a5.462 5.462 0 0 1 3.887-1.622Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        d="M21.955 8.024A1.71 1.71 0 0 1 20.25 9.74a1.71 1.71 0 0 1-1.705-1.716 1.71 1.71 0 0 1 1.705-1.716 1.71 1.71 0 0 1 1.705 1.716"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default Instagram