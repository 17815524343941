import React from 'react'

const Play = () => (
    <svg viewBox="0 0 23 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.19 24.826c-.55 0-1.1-.165-1.595-.44C.605 23.78 0 22.79 0 21.58V3.26C0 2.104.605 1.06 1.595.454c1.046-.605 2.256-.605 3.246 0l16.56 9.683c.826.495 1.266 1.32 1.266 2.255 0 .936-.495 1.76-1.266 2.256l-16.56 9.738c-.495.275-1.1.44-1.65.44z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
)

export default Play