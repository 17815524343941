import React from 'react';

const Link = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.088 20.912C25.4498 19.2745 23.2283 18.3546 20.912 18.3546C18.5957 18.3546 16.3742 19.2745 14.736 20.912L8.55799 27.088C6.91974 28.7262 5.99939 30.9482 5.99939 33.265C5.99939 35.5818 6.91974 37.8037 8.55799 39.442C10.1962 41.0802 12.4182 42.0006 14.735 42.0006C17.0518 42.0006 19.2737 41.0802 20.912 39.442L24 36.354'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.912 27.088C22.5502 28.7255 24.7717 29.6454 27.088 29.6454C29.4043 29.6454 31.6258 28.7255 33.264 27.088L39.442 20.912C41.0802 19.2737 42.0006 17.0518 42.0006 14.735C42.0006 12.4182 41.0802 10.1962 39.442 8.55799C37.8037 6.91974 35.5818 5.99939 33.265 5.99939C30.9482 5.99939 28.7262 6.91974 27.088 8.55799L24 11.646'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Link;

