import React from 'react';

const Repeat = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.534 7H15.466C15.5135 7.00002 15.56 7.01357 15.6001 7.03907C15.6402 7.06457 15.6722 7.10096 15.6923 7.14398C15.7125 7.187 15.72 7.23487 15.7139 7.28198C15.7078 7.32909 15.6884 7.3735 15.658 7.41L13.692 9.77C13.6686 9.79814 13.6392 9.82077 13.606 9.8363C13.5728 9.85184 13.5367 9.85989 13.5 9.85989C13.4634 9.85989 13.4272 9.85184 13.394 9.8363C13.3608 9.82077 13.3315 9.79814 13.308 9.77L11.342 7.41C11.3116 7.3735 11.2922 7.32909 11.2862 7.28198C11.2801 7.23487 11.2876 7.187 11.3077 7.14398C11.3279 7.10096 11.3598 7.06457 11.3999 7.03907C11.44 7.01357 11.4865 7.00002 11.534 7V7ZM0.534025 9H4.46603C4.51353 8.99998 4.56004 8.98643 4.60012 8.96093C4.6402 8.93543 4.67219 8.89905 4.69234 8.85603C4.71248 8.81301 4.71995 8.76514 4.71388 8.71802C4.7078 8.67091 4.68843 8.6265 4.65803 8.59L2.69202 6.23C2.66856 6.20187 2.6392 6.17923 2.60602 6.1637C2.57285 6.14817 2.53666 6.14011 2.50003 6.14011C2.46339 6.14011 2.4272 6.14817 2.39403 6.1637C2.36085 6.17923 2.33149 6.20187 2.30803 6.23L0.342025 8.59C0.311623 8.6265 0.292249 8.67091 0.286173 8.71802C0.280096 8.76514 0.287568 8.81301 0.307714 8.85603C0.32786 8.89905 0.359846 8.93543 0.399926 8.96093C0.440006 8.98643 0.486522 8.99998 0.534025 9V9Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00001 3C6.44801 3 5.06001 3.707 4.14301 4.818C4.10206 4.87108 4.05086 4.91537 3.99245 4.94826C3.93404 4.98115 3.86961 5.00196 3.803 5.00945C3.73638 5.01694 3.66895 5.01096 3.60469 4.99186C3.54043 4.97276 3.48068 4.94094 3.42897 4.89828C3.37726 4.85563 3.33465 4.80301 3.30368 4.74356C3.27272 4.68411 3.25402 4.61904 3.24871 4.55221C3.24339 4.48539 3.25157 4.41818 3.27275 4.35458C3.29394 4.29099 3.32769 4.2323 3.37201 4.182C4.1042 3.29553 5.0756 2.63801 6.17067 2.28766C7.26575 1.9373 8.43841 1.90885 9.54918 2.20569C10.66 2.50253 11.6621 3.11217 12.4364 3.96209C13.2107 4.81201 13.7246 5.86646 13.917 7H12.9C12.6691 5.87081 12.0554 4.856 11.1625 4.12715C10.2697 3.3983 9.15256 3.00014 8.00001 3V3ZM3.10001 9C3.28932 9.92463 3.73636 10.7768 4.38949 11.4581C5.04261 12.1395 5.87517 12.6221 6.79097 12.8503C7.70677 13.0785 8.66845 13.0429 9.5649 12.7477C10.4613 12.4525 11.256 11.9097 11.857 11.182C11.898 11.1289 11.9492 11.0846 12.0076 11.0517C12.066 11.0189 12.1304 10.998 12.197 10.9906C12.2636 10.9831 12.3311 10.9891 12.3953 11.0081C12.4596 11.0272 12.5193 11.0591 12.5711 11.1017C12.6228 11.1444 12.6654 11.197 12.6963 11.2564C12.7273 11.3159 12.746 11.381 12.7513 11.4478C12.7566 11.5146 12.7484 11.5818 12.7273 11.6454C12.7061 11.709 12.6723 11.7677 12.628 11.818C11.8958 12.7045 10.9244 13.362 9.82934 13.7123C8.73427 14.0627 7.56161 14.0912 6.45083 13.7943C5.34006 13.4975 4.33791 12.8878 3.5636 12.0379C2.78928 11.188 2.27538 10.1336 2.08301 9H3.10001Z'
        fill='black'
      />
    </svg>
  );
};

export default Repeat;
