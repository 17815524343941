import React from 'react'
import { graphql } from 'gatsby'

import Club from '../components/organisms/Club/Club';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

import { transformWpClub } from '../helpers/wordpress';

const WPPendingClub = ({ data }) => {
    const { wpPendingClub: rawClub } = data;
    const club = transformWpClub(rawClub);
    
    const metaTitle = `${club.title} | University of Sydney Union`;
    const metaDescription = club.excerpt;

    return (
        <Layout disablePaddingBottom>
            <Seo title={metaTitle} description={metaDescription} />
            <Club club={club} events={[]} />
        </Layout>
    );
};

export default WPPendingClub

export const pageQuery = graphql`
    query PendingClubByID($id: String!) {
        wpPendingClub(id: { eq: $id }) {
            id
            databaseId
            status
            slug
            uri
            title
            content
            excerpt
            acf {
                application_settings {
                    accepting_signatures_period
                    campaign_start_date
                    minimum_amount_of_signatures_required
                }
            }
        }
    }
`;