import React from 'react';
import * as styles from './HorizontalCarousel.module.css';
import CustomSlider from '../../organisms/CustomSlider/CustomSlider';
// import { useWindowDimensions } from '../../../helpers/general';

// turn this into component if necessary
const GenericCard = ({cardDetails}) => {
    const fallbackImage = 'https://via.placeholder.com/360x450.png?text=Image+Placeholder';
    const src = cardDetails.image === "" || cardDetails.image === undefined ? fallbackImage : cardDetails.image;
    return(
      <a href={cardDetails?.link} className={styles.cardContainer}>
        <img src={`${src}?w=340&h=450&fit=fill`} alt={`${cardDetails?.name}`}></img>
        <span className={styles.name}>{cardDetails?.name}</span>
        <span className={styles.subtitle}>{cardDetails?.subtitle}</span>
      </a>
    )
  }

function HorizontalCarousel(props) {
    const { data, slidesToDisplay, title } = props;
    // const { width } = useWindowDimensions();
    // const [pageCount, setPageCount] = useState(0);
    // const [currentPage, setCurrentPage] = useState(0);

    // useEffect(() => {
    //     if(data !== undefined && data?.length !== 0) {
    //         const computedPageCount = data.length; 
    //         setPageCount(computedPageCount);
    //     }
    // },[data, width]); 

    // const sliderRef = useRef();
    // const sliderSettings = {
    //     slidesToShow: slidesToDisplay,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     dots: false,
    //     adaptiveHeight: false,
    //     responsive: [
    //         {
    //           breakpoint: 800,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         },
    //       ],
    //     afterChange: (currentSlideNumber) => handlePaginationMarker(currentSlideNumber),
    // }

    // const handlePaginationMarker = (e) => {
    //     setCurrentPage(e);
    // }

    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // }

    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // }

    // const renderCard = (image, title, altText) => {
    //     return(<div>
    //         <div className={styles.imageContainer}>
    //             <img src={image} alt={altText !== undefined ? altText : title} />
    //         </div>
    //         <div className={styles.titleContainer}>
    //             <p>{title}</p>
    //         </div>
    //     </div>);
    // }

    // const renderUnevenCarousel = () => {
    //     let renderList = [];
    //     if(width > 800) {
    //         for(let x = 0; x < data.length; x += 2) {
    //             const isLast = x + 1 === data.length;
    //             const element = (<div key={data[x].id} className={`${styles.mainContent} ${styles.cardContainer} ${isLast === true ? styles.singleContent : ''}`}>
    //                {renderCard(data[x].image, data[x].title, data[x].altText)}
    //                { !isLast && renderCard(data[x+1].image, data[x+1].title, data[x].altText)}
    //             </div>)
    //             renderList.push(element);
    //         }
    //     } else {
    //         renderList = data.map((datum) => <div className={styles.cardContainer} key={datum.id}>
    //             {renderCard(datum.image,datum.title)}
    //             </div>)
    //     }

    //     return renderList;
    // }

    // const renderCarousel = () => {
    //     let renderList = [];

    //     renderList = data.map((datum) => <div className={styles.cardContainer} key={datum.id}>
    //         {renderCard(datum.image,datum.title)}
    //         </div>)

    //     return renderList;
    // }

    // const renderLinePagination = () => {
    //     const divs = [];
    //     for(let x = 0; x < pageCount; x++) {
    //         divs.push(<div key={x} className={`${currentPage === x ? styles.active: ''}`}></div>)
    //     }
    //     return divs;
    // }

  return (
    <div className={styles.root}>
        <div className={styles.hideOnMobile}>
          <CustomSlider 
            title={title}
            slidesToShow={slidesToDisplay}
          >
            {data !== false && data?.map((cardDetails, index) => {
              return <GenericCard key={index} cardDetails={cardDetails} />
            })}
          </CustomSlider>
        </div>

        <div className={styles.overflowContainer} >
          {data !== false && data?.map((cardDetails, index) => {
            return <GenericCard key={index} cardDetails={cardDetails} />
          })}
        </div>

        {/* <div className={styles.controlPanelContainer}>
            <div className={styles.paginationContainer}>
                {renderLinePagination()}
            </div>
            <div className={styles.controlPanel}>
                <div className={styles.arrow} role={'presentation'} onClick={() => sliderNext()}>
                    <Icon symbol={'lineArrow'}></Icon>
                </div>
                <div className={`${styles.prevArrow} ${styles.arrow}`} role={'presentation'} onClick={() => sliderPrev()}>
                    <Icon symbol={'lineArrow'}></Icon>
                </div>
            </div>
        </div>
        <Slider sliderRef={sliderRef} {...sliderSettings}>
            {renderCarousel()}
        </Slider> */}
    </div>
  );
}

export default HorizontalCarousel;