import React from "react"
import { isNumeric, formatPrice } from "../../../helpers/general";
const CurrencyFormatter = ({ currency = 'AUD', amount, appendZero = false }) => {

  const formattedPrice = formatPrice(amount, currency, appendZero);

  const priceComponent = 
    <>
      <span>$</span>
      <span>{formattedPrice}</span>
    </>;

  return isNumeric(amount) ? priceComponent : '$0.00'
};
  
export default CurrencyFormatter;