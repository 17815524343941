/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from "react"

import Layout from "../../../components/organisms/Layout/Layout"
import Seo from "../../../components/organisms/Seo/Seo"
import Container from "../../../components/atoms/Container/Container"
// import PageBanner from "../components/atoms/PageBanner/PageBanner"
import ReturnsForm from "../../../components/organisms/ReturnsForm/ReturnsForm"

const ReturnsRequest = () => {
    return (
        <Layout>
            <Seo title="Returns Form" />
            {/* <PageBanner breadcrumb="Returns" title="Returns request" color="latte" imageSize="medium" /> */}
            
            <Container size="small">
                <div style={{marginTop: '40px'}}>
                    <ReturnsForm/>
                </div>
            </Container>
        </Layout>
    )
  }
  
  export default ReturnsRequest
  