// extracted by mini-css-extract-plugin
export var button = "Search-module--button--76918";
export var close = "Search-module--close--2a139";
export var code = "Search-module--code--13d77";
export var compact = "Search-module--compact--5ed7a";
export var details = "Search-module--details--20179";
export var excerpt = "Search-module--excerpt--a6204";
export var form = "Search-module--form--a7049";
export var formContainer = "Search-module--formContainer--4d6da";
export var helperContainer = "Search-module--helperContainer--bb568";
export var hideSearch = "Search-module--hideSearch--bd56e";
export var input = "Search-module--input--317a6";
export var inputContainer = "Search-module--inputContainer--59c3d";
export var link = "Search-module--link--ee062";
export var root = "Search-module--root--b6586";
export var searchHeaderContainer = "Search-module--searchHeaderContainer--74fc5";
export var searchResultRow = "Search-module--searchResultRow--2150d";
export var showSearch = "Search-module--showSearch--c629f";
export var showingResults = "Search-module--showingResults--d4a40";
export var suggestions = "Search-module--suggestions--d4902";
export var thumbnail = "Search-module--thumbnail--7b468";