import React from 'react';


const Delete = ({ clickHandler }) => {
  return (
    <svg
      onClick={clickHandler}
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.655177 0.655177C0.704532 0.605698 0.763163 0.566441 0.827712 0.539656C0.892261 0.512871 0.961461 0.499084 1.03135 0.499084C1.10123 0.499084 1.17043 0.512871 1.23498 0.539656C1.29953 0.566441 1.35816 0.605698 1.40752 0.655177L4.21922 3.46795L7.03093 0.655177C7.08033 0.605778 7.13898 0.566592 7.20352 0.539858C7.26806 0.513123 7.33724 0.499363 7.4071 0.499363C7.47696 0.499363 7.54614 0.513123 7.61068 0.539858C7.67523 0.566592 7.73387 0.605778 7.78327 0.655177C7.83267 0.704577 7.87186 0.763222 7.89859 0.827765C7.92532 0.892308 7.93908 0.961486 7.93908 1.03135C7.93908 1.10121 7.92532 1.17039 7.89859 1.23493C7.87186 1.29947 7.83267 1.35812 7.78327 1.40752L4.9705 4.21922L7.78327 7.03093C7.83267 7.08033 7.87186 7.13898 7.89859 7.20352C7.92532 7.26806 7.93908 7.33724 7.93908 7.4071C7.93908 7.47696 7.92532 7.54614 7.89859 7.61068C7.87186 7.67523 7.83267 7.73387 7.78327 7.78327C7.73387 7.83267 7.67523 7.87186 7.61068 7.89859C7.54614 7.92532 7.47696 7.93908 7.4071 7.93908C7.33724 7.93908 7.26806 7.92532 7.20352 7.89859C7.13898 7.87186 7.08033 7.83267 7.03093 7.78327L4.21922 4.9705L1.40752 7.78327C1.35812 7.83267 1.29947 7.87186 1.23493 7.89859C1.17039 7.92532 1.10121 7.93908 1.03135 7.93908C0.961486 7.93908 0.892308 7.92532 0.827765 7.89859C0.763222 7.87186 0.704577 7.83267 0.655177 7.78327C0.605778 7.73387 0.566592 7.67523 0.539858 7.61068C0.513123 7.54614 0.499363 7.47696 0.499363 7.4071C0.499363 7.33724 0.513123 7.26806 0.539858 7.20352C0.566592 7.13898 0.605778 7.08033 0.655177 7.03093L3.46795 4.21922L0.655177 1.40752C0.605698 1.35816 0.566441 1.29953 0.539656 1.23498C0.512871 1.17043 0.499084 1.10123 0.499084 1.03135C0.499084 0.961461 0.512871 0.892261 0.539656 0.827712C0.566441 0.763163 0.605698 0.704532 0.655177 0.655177Z'
        fill='#0E0E10'
      />
    </svg>
  );
};

export default Delete;
