import React, { useMemo, useState, useContext} from 'react'
import { toast } from 'react-toastify'

import { searchUser } from '../helpers/rosterfy';

import AuthContext from '../context/AuthProvider';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import NoVolunteerDialog from '../components/molecules/NoVolunteerDialog/NoVolunteerDialog';
import Seo from '../components/organisms/Seo/Seo';
import VTeamEventList from '../components/organisms/VTeamEventList/VTeamEventList';

import * as styles from './vteam-events.module.css';

const VTeamEventsPage = () => {
    
    const auth = useContext(AuthContext);
    const userChecked = auth && auth.state.userChecked;
    const isLoggedIn = auth && auth.state.isLoggedIn;

    const [rosterfyDialog, setRosterfyDialog] = useState(false);
    const [rosterfyId, setRosterfyId] = useState();
    const [usuNumberState, setUsuNumberState] = useState();

    useMemo(() => {
        if (userChecked && !isLoggedIn) {
            toast.info('To volunteer for an event, login first to your USU account.');
        }
    }, [isLoggedIn, userChecked])

    useMemo(() => {
        if(isLoggedIn === true) {
            // check for existing rosterfy id based on email and Member Number
            if(Array.isArray(auth.state.object.form_fields)) {
            // const usuNumber = auth.state.object.form_fields.filter((field) => field.name === 'usunumber').map((field) => field.value)[0];
            const usuNumber = auth.state.usu?.MemberNumber;
            const usuEmail = auth.state.usu?.Email;

            searchUser(usuNumber, usuEmail).then((response) => {
                // To Do: go back here when auth is cleared out
                if(response === undefined) { 
                    setRosterfyId(undefined) 
                    setRosterfyDialog(true);
                    setUsuNumberState(usuNumber);
                }
                else setRosterfyId(response.response[0].object.id);
            })
            }
        }

        //eslint-disable-next-line
    }, [isLoggedIn])

    return ( 
        <Layout>
            <Seo title="VTeam Events" />
            <div className={styles.root}>
            <Container size={'large'}>
                <div className={styles.headerContainer}>
                <h1>VTeam Events</h1>
                {rosterfyId === undefined ||
                    <Button
                    size={'noWidth'}
                    level={'primary'}
                    type={'a'}
                    href={'/account/volunteer'}
                    target={true}
                    >
                    My Applied Events
                    </Button>
                }
            </div>
                <VTeamEventList rosterfyId={rosterfyId}/>
            </Container>
            </div>
            <NoVolunteerDialog show={rosterfyDialog} setShow={setRosterfyDialog} usuNumber={usuNumberState} />
        </Layout>
    );
}
 
export default VTeamEventsPage;