import React from 'react'
import * as styles from './SelectField.module.css';

const SelectField = ({
    id,
    label,
    placeholder,
    value,
    handleChange,
    handleBlur,
    required,
    customHandler,
    disabled,
    data = []
}) => {
    return (
        <div className={styles.root}>
            {label && <label htmlFor={id}>{label}</label>}
            <select disabled={disabled} id={id} value={value}
                onBlur={handleBlur ? e => handleBlur(id, e.target.value) : null}
                onChange={customHandler ? customHandler : e => handleChange(id, e.target.value)} >

                {placeholder && <option value={''}>{placeholder}</option>}
                {data.map(option => {
                    if (option.group === true) {
                        return (
                            <optgroup key={option.text} label={option.text} />
                        )
                    } else {
                        return (
                            <option key={option.value} value={option.value}>{option.text}</option>
                        )
                    }
                })}
            </select>
        </div>
    )
}

export default SelectField