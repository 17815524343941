import { Link } from 'gatsby';
import React from 'react';
import Icon from '../Icon/Icon';
import * as innateStyles from './SmartLink.module.css'; //inherited

//looking for a way to refactor this
const SmartLink = ({menuLink, linkStyle, activeLinkStyle}) => {

  const { label, url, target, location, stylized = false } = menuLink;

  let newTabOptions = {}
  if(target === 'New Tab') {
    newTabOptions = {
      target: '_blank',
      rel: 'noreferrer'
    }
  }

  const renderExternalLink = () => {

    if(stylized === true) {
      return(
      <div className={innateStyles.stylizedLinkContainer}>
        <a {...newTabOptions} className={`${linkStyle} ${innateStyles.stylized}`} href={url}>
          {label}
          <span className={innateStyles.iconContainer}>
            <Icon symbol={'arrowLink'}></Icon>
          </span>
        </a>
      </div>)

    } else {
      return(<a {...newTabOptions} className={linkStyle} href={url}>{label}</a>)  
    }
  }

  const renderInternalLink = () => {
    if(stylized === true) {
      return(
        <div className={innateStyles.stylizedLinkContainer}>
          <Link className={`${linkStyle} ${innateStyles.stylized}`} to={url}>
            {label}
            <span className={innateStyles.iconContainer}>
              <Icon symbol={'arrowLink'}></Icon>
            </span>
          </Link>
        </div>
      )
    } else {
      return <Link activeClassName={activeLinkStyle} className={`${linkStyle}`} to={url}>{label}</Link>  
    }
  }

  let isExternal = false;
  if(target === 'New Tab' || url.startsWith('http')) { // Gatsby Link does not support internal new tab
    isExternal = true;
  } else if(location === 'External') {
    isExternal = true;
  }

  return (
    isExternal === true ? renderExternalLink() : renderInternalLink()
  );
};

export default SmartLink;
