import React, { useCallback, useEffect, useRef, useState } from 'react';
import CurrencyFormatter from '../../../atoms/CurrencyFormatter/CurrencyFormatter';

// import * as styles from PARENT

const Range = ({ name, min, max, reset, setter, getter, styles }) => {
    const range = useRef(null);
    const [timeoutObj, setTimeoutObj] = useState(null);
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);

    useEffect(() => {
        setMinVal(min);
        setMaxVal(max);
    }, [min, max, reset]);

    useEffect(() => {
        if (getter) {
            let activeRange = null;
            Object.keys(getter)
                .map((a) => {
                    if (a && a.indexOf(`${name}=range+`) > -1) {
                        activeRange = a;
                    }
                    return a;
                });
            
            if (activeRange) {
                // console.log(activeRange);
                const explode = activeRange.split('+');
                if (explode.length > 1) {
                    const rangeExplode = explode[1].split(' TO ');
                    if (rangeExplode.length === 2) {
                        setMinVal(parseInt(rangeExplode[0] >= min ? rangeExplode[0] : min));
                        setMaxVal(parseInt(rangeExplode[1] <= max ? rangeExplode[1] : max));
                    }
                }
            }
        }
    }, [getter, min, max, name])

    // Convert to percentage
    const getPercent = useCallback((value) => {
        return Math.round(((value - min) / (max - min)) * 100);
    }, [min, max]);

    // Set width of the range to change from the left side
    useEffect(() => {
        let minPercent = getPercent(minVal);
        let maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, maxVal, getPercent]);

    // Get min and max values when their state changes
    // useEffect(() => {
    //     onChange({ min: minVal, max: maxVal });
    // }, [minVal, maxVal]); //onChange

    const triggerChanged = (e) => {
        e.persist();
        if (timeoutObj) {
            clearTimeout(timeoutObj);
        }

        setTimeoutObj(
            setTimeout(() => {
                setter(e, { name: name, min: minVal, max: maxVal, type: 'numeric_range' })
            }, 1000)
        );
    }

    const valueFormat = (value) => {
        if (name === 'price') {
            return <CurrencyFormatter amount={value} currency="AUD" />
        }

        // if (name === 'filter_example') {
        //     return `${value}unit`;
        // }

        return value;
    }

    return (
        <div className={styles.slider}>
            <input type="range" min={min} max={max} value={minVal} className={`${styles.thumb} ${styles.thumbLeft}`} 
                onChange={(event) => {
                    const value = Math.min(Number(event.target.value), maxVal - 1);
                    // console.log(event);
                    setMinVal(value);
                    triggerChanged(event);
                }}
                style={{ zIndex: minVal > max - 100 && "5" }}
            />
            <input type="range" min={min} max={max} value={maxVal} className={`${styles.thumb} ${styles.thumbRight}`}
                onChange={(event) => {
                    const value = Math.max(Number(event.target.value), minVal + 1);
                    setMaxVal(value);
                    triggerChanged(event);
                }}
            />
            <div className={styles.mainSlider}>
                <div className={styles.labelBox}>
                    <div className={styles.sliderRangeLeftValue}>{valueFormat(minVal)}</div>&nbsp;-&nbsp;
                    <div className={styles.sliderRangeRightValue}>{valueFormat(maxVal)}</div>
                </div>
                <div className={styles.sliderTrack} />
                <div ref={range} className={styles.sliderRange}>
                </div>
            </div>
        </div>
    )
};

export default Range;