import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"

import Layout from "../components/organisms/Layout/Layout"
// import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
// import PageTitle from "../components/atoms/PageTitle/PageTitle"
// import ProductGrid from '../components/organisms/ProductGrid/ProductGrid'
import SearchForm from '../components/molecules/Search/Search'

import ProcessSearch from '../components/molecules/Search/ProcessSearch.js'

import * as styles from './search.module.css'

const Search = () => {
    const [keyword, setKeyword] = useState('');
    // const [results, setResults] = useState({items: [], totals: {count: 0}});
    // const [searchCount, setSearchCount] = useState(0);
    const [qsChecked, setQSChecked] = useState(false);

    useEffect(() => {
        if (!qsChecked && typeof window !== 'undefined') {
            setQSChecked(true);
            const qs = window.location.search;
            const qsObj = new URLSearchParams(qs);
            const kw = qsObj.get('q');
            if (kw && kw !== '') {
                setKeyword(kw);
                ProcessSearch(kw, 100).then((results) => {
                    // setResults(results);
                    const productsObj = results.items.find(result => 'products' in result);
                    if (productsObj && productsObj.allItems) {
                        // setSearchCount(productsObj.allItems.length);
                    }
                })
            } else {
                window.location = '/';
                // setResults([{error: true}]);
            }
        }
    // }, [qsChecked, setQSChecked, setKeyword, setResults]);
    }, [qsChecked, setQSChecked, setKeyword]);

    // const noResults = <div className="flex-center" key="noResults">
    //     <div className="body3">
    //         No results found for this search term. Please try an alternate search below:
    //         <br />
    //         <br />
    //         <div className={`formField ${styles.searchContainer}`}>
    //             <SearchForm toggleSuggestions={false} passedTerm={keyword} />
    //         </div>
    //     </div>
    // </div>;
    // console.log(keyword);
    return (
        <Layout>
            <Seo title="Search" />
            <Container size="large">
                <div className={styles.root}>
                    <SearchForm passedTerm={keyword} compact={false} redirect={false} />
                    {/* <Breadcrumbs crumbs={[{ link: '/', label:'Home' }, {label: 'Search Results' }]} /> */}

                    {/* {keyword && searchCount > 0  && (
                        <PageTitle customMode={true}>{searchCount} results for <strong>“{keyword}”</strong></PageTitle>
                    )}
                    {!keyword && results.items.length === 0 && (
                        <PageTitle customMode={true}>Searching...</PageTitle>
                    )}
                    {keyword && searchCount === 0 && (
                        <PageTitle customMode={true}>No results found!</PageTitle>
                    )}
                    {!keyword && results.items.length > 0 && (
                        <PageTitle customMode={true}>Unable to search :(</PageTitle>
                    )} */}
                
                    {/* {results.items.length > 0 && results.items.map((result, resultIndex) => {
                        // Add logic
                        if ('products' in result && result.products.length > 0) {
                            return <ProductGrid key={resultIndex} products={result.products} />
                        } else if ('products' in result && result.products.length === 0) {
                            return noResults;
                        }

                        if ('posts' in result && result.posts.length > 0) {
                            // TODO: Convert to component
                            return (
                                <div key={resultIndex}>
                                    {result.posts && (
                                        result.posts.map((post, postIndex) => (
                                            <a key={postIndex} href={post.url}>{post.title}</a>
                                        ))
                                    )}
                                </div>
                            )
                        }

                        if ('error' in result) {
                            return (
                                <div key={resultIndex}>
                                    Something went wrong, please <Link to="/">return home</Link>
                                </div>
                            )
                        }

                        return null
                    })}

                    {keyword && results.items.length === 0 && searchCount === 0 && noResults} */}
                </div>
            </Container>
        </Layout>
    )
  }
  
  export default Search
  