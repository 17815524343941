import React from 'react';
import parse from 'html-react-parser';

import * as styles from './JobCard.module.css';

const JobCard = props => {
  const { title, employment_status, description, location, public_url } = props.job;

  const replaceFunction = (domNode) => {
    if(domNode && domNode?.name === 'span') return <></> // remove styling from Scout API
    if(domNode && domNode?.name === 'img') return <></> // remove styling from Scout API
  }

  const parserOptions = {
    replace: replaceFunction
  }

  return (
    <div className={ styles.JobCard }>
      <div className={styles.top}>
        <h6>{title}</h6>
        <div className={styles.descriptionContainer}>
          {description && parse(description, parserOptions)}
          <div className={styles.blur}>&nbsp;</div>
        </div>
      </div>
      <div className={styles.metaContainer}>
          <a href={public_url}>Read More</a>
          <span className={styles.label}>Location: <span className={styles.value}>{location}</span></span>
          <span className={styles.label}>Employment: <span className={styles.value}>{employment_status}</span></span>
      </div>
      <div className={styles.bottom}>
        {/* <Button href={public_url} type={'a'} level={'primary'}>Apply</Button> */}
        <a target="_blank" rel="noopener noreferrer" href={public_url}>Apply</a>
      </div>
    </div>
  );
};

export default JobCard;
