import React from 'react'

const StoreLocator = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.13 0.69 13.59 18.28"
  >
    <path
      d="M9.919 4.984a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 3.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
      fill="currentColor"
    />
    <path
      d="M14.723 2.676a6.794 6.794 0 0 0-10.496 8.512L8.95 18.44a1.157 1.157 0 0 0 1.938 0l4.723-7.25a6.794 6.794 0 0 0-.888-8.513Zm-.159 7.83L9.92 17.637l-4.645-7.13a5.57 5.57 0 0 1 .725-6.947 5.544 5.544 0 0 1 7.84 0 5.57 5.57 0 0 1 .725 6.946Z"
      fill="currentColor"
    />
  </svg>
)

export default StoreLocator