import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { transformWpEat } from '../helpers/wordpress';
import Eat from '../components/organisms/Eat/Eat';

const WPEat = ({ data }) => {
  const { wpEat: rawEat, allWpEat: wpMoreEats  } = data;
  const eat = transformWpEat(rawEat);
  // console.log(data);
  const metaTitle = eat.seo.title ? eat.seo.title : `${eat.title} | University of Sydney Union`;
  const metaDescription = eat.seo.metaDesc ? eat.seo.metaDesc : eat.excerpt;

  // console.log(eat.featuredImage);

  return (
    <Layout>
        <Seo title={metaTitle} description={metaDescription} />
        {/* TODO: To be updated to the design */}
        {/* <div className='banner' style={{backgroundImage: `url(${eat.featuredImage})`, minHeight: '250px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <Container>
                
            </Container>
        </div> */}
        <Eat eat={eat} moreEats={wpMoreEats && wpMoreEats.nodes} />
    </Layout>
  );
};

export default WPEat

export const pageQuery = graphql`
  query EatByID($id: String!) {
    wpEat(id: { eq: $id }) {
        id
        status
        uri
        slug
        title
        content
        excerpt
        featuredImage {
            node {
                publicUrl
                sourceUrl
            }
        }
        usueatsFields {
            openingHours {
              days
              hours
            }
            menus {
              name
              file {
                localFile {
                  base
                }
                publicUrl
                filename
                sourceUrl: mediaItemUrl
              }
            }
            gallery {
              publicUrl
              sourceUrl
            }
            address {
              address
              country
              postCode
              state
              suburb
            }
          }
        seo {
            title
            metaDesc
            metaKeywords
            opengraphTitle
            opengraphDescription
            opengraphImage {
                publicUrl
                sourceUrl
            }
            twitterTitle
            twitterDescription
            twitterImage {
                publicUrl
                sourceUrl
            }
        }
        eatsLocations {
            nodes {
                uri
                name
            }
        }
    }

    allWpEat(filter: {id: {ne: $id}}) {
      nodes {
        status
        uri
        title
        featuredImage {
          node {
            publicUrl
            sourceUrl
          }
        }
        usueatsFields {
          openingHours {
            days
            hours
          }
        }
      }
    }
  }
`;
