import React from 'react';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import RecentlyViewed from '../../components/organisms/RecentlyViewed/RecentlyViewed';

const RecentViews = () => {
  return <RecentlyViewed columns={3} emptyFeedback={true} />;
};

const RecentViewOutput = () => (
  <AccountPageWrapper
    metaTitle="Recent Views - Your Recent Items"
    title="Recent Views"
  >
    <RecentViews />
  </AccountPageWrapper>
);

export default RecentViewOutput;
