import React from 'react'

const Phone = () => (
    <svg
      width={14}
      height={18}
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.032 1.114C3.675-.736 4.221-.271 5.07 2.43c.645 2.056.276 2.313-1.305 3.421.528 2.624 2.526 5.478 4.812 6.872 1.582-1.107 1.95-1.365 3.66-.056 2.25 1.721 2.5 2.393-.142 4.243C7.652 20.021-3.41 4.224 1.032 1.114z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
)

export default Phone