import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { dataLayerPush, dissectTitle } from '../helpers/general';

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Product from "../components/organisms/Product/Product"

export const query = graphql`
query ProductTemplateQuery($id: String!, $bigcommerce_id: Int!, $sku: String!, $categoryId: Int!) {
  product: allBigCommerceProducts(filter: { id: { eq: $id } }) {
    edges {
      node {
        id
        entityId: bigcommerce_id
        sku
        name
        price
        calculated_price
        retail_price
        sale_price
        map_price
        description
        brand_id
        weight
        categories
        page_title
        related_products
        warranty
        order_quantity_maximum
        custom_url {
          url
        }
        images {
          url_standard
          url_thumbnail
          url_zoom
          id
          description
          image_file
          is_thumbnail
        }
        variants {
          product_id
          inventory_level
          entityId: id
          calculated_price
          sale_price
          price
          option_values {
            id
            label
            option_display_name
            option_id
          }
        }
        options {
          option_values {
            id
            is_default
            label
          }
          id
          display_name
          type
        }
        inventory_level
        inventory_tracking
        inventory_warning_level
        availability
        availability_description
        reviews_count
        reviews_rating_sum
        custom_fields {
          name
          value
        }
      }
    }
  }

  youMayAlsoLike: allBigCommerceProducts(filter: {sku: {ne: $sku}, categories: {eq: $categoryId}}) {
    edges {
      node {
        id
        entityId: bigcommerce_id
        sku
        name
        price
        calculated_price
        retail_price
        sale_price
        map_price
        description
        brand_id
        weight
        categories
        page_title
        related_products
        warranty
        custom_url {
          url
        }
        images {
          url_standard
          url_thumbnail
          url_zoom
          id
          description
          image_file
          is_thumbnail
        }
        variants {
          product_id
          entityId: id
        }
        inventory_level
        inventory_tracking
        inventory_warning_level
        availability
        availability_description
        reviews_count
        reviews_rating_sum
        custom_fields {
          name
          value
        }
      }
    }
  }
  productPrices: allBigCommerceProductPricing(filter: {product_id: { eq: $bigcommerce_id } }) {
    edges {
      node {
        price {
          as_entered
          entered_inclusive
          tax_exclusive
          tax_inclusive
        }
        retail_price {
          as_entered
          entered_inclusive
          tax_exclusive
          tax_inclusive
        }
        sale_price {
          is_on_sale
          as_entered
          entered_inclusive
          tax_exclusive
          tax_inclusive
        }
        calculated_price {
          as_entered
          entered_inclusive
          tax_exclusive
          tax_inclusive
        }
      }
    }
  }
  categories: allBigCommerceCategories {
    edges {
      node {
        bigcommerce_id
        name
        parent_id
        sort_order
        custom_url {
          url
          is_customized
        }
      }
    }
  }
}
`

const ProductTemplate = props => {
  const { data, errors } = props;
  const product = data && data.product.edges[0].node;
  const [init, setInit] = useState(false);

  const productName = dissectTitle(product.name, ' (')[0];

  product.categories = product.categories
    .map(cat_id => {
      if (typeof cat_id === 'number') {
        const cat = data.categories.edges
          .map(a => a.node)
          .filter(cat => cat.bigcommerce_id === cat_id);
        return cat.length
          ? {
              id: cat[0].bigcommerce_id,
              name: cat[0].name,
              parent_id: cat[0].parent_id,
              sort_order: cat[0].sort_order,
              url: cat[0].custom_url?.url
            }
          : null;
      } else {
        return cat_id;
      }
    })
    .filter(a => a !== null);

  product.youMayAlsoLike = data.youMayAlsoLike.edges;
  product.detailedPrices = data.productPrices.edges[0].node;

  useEffect(() => {
    if (!init && product) {
      setInit(true);
      dataLayerPush('productDetail', null, product);
    }
  }, [init, product]);

  return (
    <Layout>
        {errors && <Seo title='GraphQL Error' />}
        {product && <Seo title={product.page_title || productName} />}

        {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

        {product && (
            <Product data={product} />
        )}
    </Layout>
  )
}

export default ProductTemplate
