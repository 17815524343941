import React, { useState, useRef, useEffect } from "react"
import _ from 'lodash';
import Icon from "../../atoms/Icon/Icon";
import ProcessSearch from './ProcessSearch.js'

import { Link } from "gatsby";
// import ProductGrid from '../../organisms/ProductGrid/ProductGrid'

import * as styles from './Search.module.css';

const Search = ({toggleSuggestions, passedTerm = '', compact = true, showing, close, redirect = true}) => {
    const [searchTerm, setSearchTerm] = useState(passedTerm);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFocused, setFocused] = useState(false)
    const input = useRef(null)

    const defImage = 'https://via.placeholder.com/90x90?text=placeholder+to+be+replaced';

    const submitHandler = e => {
        e.preventDefault();
        if(close !== undefined) close();
        const results = suggestionsCount();
        if(redirect === true) {
          if (results.count === 1) {
            // window.location = `/product${results.items[0].custom_url.url}`;
            // console.log(results);
            window.location = results.items[0].url;
          } else {
            window.location = `/search/?q=${searchTerm}`;
          }
        }

    }

    const actionSuggestiveSearch = term => {
        setFocused(true);
        setSearchTerm(term);
        suggestiveSearch(term);
    };

    const suggestiveSearch = _.debounce(term => {
        if (term.length >= 3) {
          setLoading(true);
          ProcessSearch(term, (compact ? 12 : 50)).then(results => {
            setLoading(false);
            // console.log(results)
            results.items.map((items) => {
              // suggestion.mergeItems.slice(0, 12).map((entity) => {
              items.all = items.all.filter((entity, index) => {
                if (entity.type === 'event') {
                  const currentDay = padFigure(new Date().getDate());
                  const currentMonth = padFigure(new Date().getMonth());
                  const currentYear = new Date().getFullYear();
                  const current = Number(`${currentYear}${currentMonth}${currentDay}`);
                  const endDate = entity.eventFieldsSide?.endDate;
    
                  if (endDate) {
                    const dateEndObject = new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3'));
                    const end = Number(`${dateEndObject.getFullYear()}${padFigure(dateEndObject.getMonth())}${padFigure(dateEndObject.getDate())}`);
                    
                    if (end < current) {
                      return false; // outdated event by date
                    }
    
                    if (end === current) {
                      const currentTime = Number(`${new Date().getHours()}${new Date().getMinutes()}`);
                      const endTime = entity.eventFieldsSide?.endTime;
    
                      if (endTime === '') return entity;
                      
                      const timeEndObject = new Date(`${endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')} ${endTime}`);
                      const checkTime = Number(`${timeEndObject.getHours()}${padFigure(timeEndObject.getMinutes())}`);
    
                      if (checkTime < currentTime) {
                          return false; // outdated event by time
                      }
                    }
                  }
                }

                return entity;
              });

              return items;
            });

            setSuggestions(results.items);
            if (toggleSuggestions) {
              toggleSuggestions(true);
            }
          });
        } else {
          if (toggleSuggestions) {
            toggleSuggestions(false);
          }
          setSuggestions([]);
        }
    }, 200);

    const suggestionsCount = () => {
        let merger = [];
        suggestions.map(suggestion => {
            const items = suggestion.all;
            merger = merger.concat(items);

            return true;
        });

        return {count: merger.length, items: merger}
    }

    const focusInput = _.debounce(() => {
        input.current?.focus();
    }, 310)

    useEffect(() => {
        if (input.current && !isFocused && showing) {
            focusInput()
        }
        if (!showing) {
            setFocused(false)
        }
    }, [showing, focusInput, isFocused]);

    useEffect(() => {
        if (window && document && document.body) {
          // if (showing && !_.isEmpty(suggestions) && window.innerWidth < 767) {
          if(showing) {
            document.body.classList.add('disable-scrolling');
          } else {
            document.body.classList.remove('disable-scrolling');
          }
        }
    }, [showing, suggestions]);

    useEffect(() => {
      if(passedTerm !== '' && searchTerm === '' && input.current !== document.activeElement) {
        setSearchTerm(passedTerm);
        suggestiveSearch(passedTerm);
      }

    }, [passedTerm, suggestiveSearch, searchTerm])

    const createLinkElement = (
      title,
      type,
      link,
      image = null,
      excerpt = null,
      index = null
    ) => {
      if (compact) {
        return (
          <Link
            key={index || title}
            className={styles.link}
            to={link}>
              <div>
                <code className={`${styles.code} ${type}`}>{type}</code>
              </div>
              {title}
          </Link>
        )
      } else {
        return (
          <div key={index || title} className={styles.searchResultRow}>
            <Link to={link}><img src={image || defImage} alt={title} className={styles.thumbnail} /></Link>
            <div className={styles.details}>
              <code className={`${styles.code} ${type}`}>{type}</code>
              <Link to={link}><h5>{title}</h5></Link>
              <div className={styles.excerpt} 
                dangerouslySetInnerHTML={{
                  __html: excerpt
                }}></div>
            </div>
          </div>
        )
      }
    }

    const padFigure = (figure) => {
      return String(figure).padStart(2, '0');
    }
    const generateSuggestions = () => {
      const elements = [];
      let hasValues = false;

      if (suggestions.length > 0) {
        suggestions.forEach((suggestion) => {
          // suggestion.mergeItems.slice(0, 12).map((entity) => {
          suggestion.all.map((entity, index) => {
            hasValues = true;
            elements.push(createLinkElement(entity?.title || entity?.name, entity.search_item_type, entity.d_link, entity.d_image, entity.d_excerpt, index))
            return entity;
          })
        });

        // suggestions.forEach((suggestion, suggestionIndex) => {
        //   if ('products' in suggestion) {
        //     suggestion.products.slice(0, 4).map((product) => {
        //       hasValues = true;
        //       elements.push(createLinkElement(product.name, 'products', `/product${product.custom_url.url}`, product.defaultImage?.url, product.description))
        //       return product;
        //     })
        //   }

        //   if ('events' in suggestion) {
        //     suggestion.events.slice(0, 4).map((event) => {
        //       hasValues = true;
        //       elements.push(createLinkElement(event.title, 'events', `${event.uri}`, null, event.excerpt))
        //       return event;
        //     })
        //   }

        //   if ('clubs' in suggestion) {
        //     suggestion.clubs.slice(0, 4).map((club) => {
        //       hasValues = true;
        //       elements.push(createLinkElement(club.title, 'clubs', `${club.uri}`, null, club.excerpt))
        //       return club;
        //     })
        //   }

        //   if ('articles' in suggestion) {
        //     suggestion.articles.slice(0, 4).map((article) => {
        //       hasValues = true;
        //       elements.push(createLinkElement(article.title, 'articles', `/article/${article.url}`, null, null))
        //       return article;
        //     })
        //   }

        //   if ('posts' in suggestion) {
        //     // TODO: Convert to component
        //     hasValues = true;
        //     elements.push(<div key={suggestionIndex}>
        //       {suggestion.posts &&
        //       suggestion.posts.map((post, postIndex) => (
        //         <a key={postIndex} href={post.url}>
        //           {post.title}
        //         </a>
        //       ))}
        //     </div>);
        //   }
        // })
      }

      return hasValues ? elements : ''; 
    };

    return (
        // <div className={`${styles.root} ${showing === true ? styles.showSearch : styles.hideSearch}`}>
        //   <div className={styles.searchHeaderContainer}>
        //       <Brand size='md'/>
        //       <span role={'presentation'} onClick={close} className={styles.close}>close</span>
        //   </div>
          <div className={`${styles.formContainer}${compact ? ` ${styles.compact}` : ''}`}>
            <div className={styles.helperContainer}>
              <span>Search</span>
              <span>Start typing and press enter</span>
            </div>
            <form
              className={`${styles.form}`}
              onSubmit={submitHandler}
            >
              <div className={styles.inputContainer}>
                <input
                  className={styles.input}
                  ref={input}
                  type="text"
                  placeholder="Type here..."
                  onChange={e => actionSuggestiveSearch(e.target.value)}
                  onFocus={e => actionSuggestiveSearch(e.target.value)}
                  onBlur={() => setFocused(false)}
                  value={searchTerm}
                ></input>
                <button 
                  onClick={()=>{
                    setSuggestions([]);
                    setSearchTerm('')
                  }} 
                  className={`${styles.button} ${searchTerm === '' ? styles.hideSearch : ''} `} 
                  type="button">
                  <Icon symbol="cross" />
                </button>
              </div>
            </form>

            {/* only checking for product condition; might be modified in the future to include blog posts */}
            {!compact && searchTerm && <div className={styles.showingResults}>
              <h4>Showing {/*suggestions && suggestions.length > 0 ? (suggestions[0]?.products?.length + suggestions[0]?.events?.length + suggestions[0]?.clubs?.length) : 0*/}
                {suggestionsCount().count} results for <b>‘{searchTerm}’</b>
              </h4>
            </div>}
            {/* {(suggestions[0]?.products?.length === 0 && suggestions[0]?.events?.length === 0 && suggestions[0]?.clubs?.length === 0 && suggestions[0]?.articles.length === 0)  */}
            {(suggestions[0]?.all?.length === 0) 
              && loading === false && searchTerm.length >= 3 &&
              <div>
                <h4>No results found</h4>
              </div>}
            {/* {toggleSuggestions && suggestions.length > 0 && ( */}
            {suggestions.length > 0 && (
              <div className={styles.suggestions}>
                {generateSuggestions()}
              </div>
            )}
          </div>

        // </div>
    );
}

export default Search