import React from 'react'

const Arrow = () => (
    <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 15 15'
  >
    <path d="m13.5 7.5-4-4m4 4-4 4m4-4H1" stroke="currentColor" />
  </svg>
)

export default Arrow