import React from 'react'

const Tiktok = () => (
  <svg width={26} height={30} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 26 30'>
    <title>{"D991AC39-A80D-4341-BD61-95DA59448095"}</title>
    <path
      d="M18.468 1c.461 3.971 2.678 6.34 6.532 6.591v4.467c-2.233.218-4.19-.512-6.465-1.89v8.355c0 10.613-11.57 13.93-16.222 6.322-2.989-4.895-1.158-13.484 8.43-13.828v4.71c-.73.118-1.511.302-2.225.546-2.133.722-3.342 2.074-3.006 4.458.647 4.568 9.026 5.92 8.33-3.006V1.008h4.626V1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>

)

export default Tiktok