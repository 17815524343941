import React from 'react'

const Purse = () => (
    <svg viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M16.997 16.54L15.804 5.76c-.06-.607-.587-1.073-1.214-1.073l-12.175-.02c-.627 0-1.133.465-1.214 1.072L.008 16.54c-.04.344.08.688.303.951.223.263.566.405.91.405h14.563c.343 0 .667-.142.91-.405.242-.263.323-.607.303-.95z"
          fill="currentColor"
        />
        <path
          d="M12.224 6.589a.47.47 0 00-.465.465c0 1.8-1.477 3.276-3.277 3.276a3.289 3.289 0 01-3.276-3.276.47.47 0 00-.466-.465.458.458 0 00-.465.465c.04 2.306 1.922 4.187 4.227 4.187a4.195 4.195 0 004.187-4.187.458.458 0 00-.465-.465z"
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#FFF"
        />
        <path
          d="M12.224 5.29a.47.47 0 01-.465-.465c0-1.8-1.477-3.276-3.277-3.276a3.289 3.289 0 00-3.276 3.276.47.47 0 01-.466.466.458.458 0 01-.465-.466C4.315 2.52 6.197.64 8.502.64a4.195 4.195 0 014.187 4.186.458.458 0 01-.465.466z"
          stroke="currentColor"
          strokeWidth={0.5}
          fill="currentColor"
        />
      </g>
    </svg>
)

export default Purse