import React from 'react';

/**
 * Converts images and links to RTE entity
 */
const FromHTMLConverter = {
  htmlToBlock: (nodeName, node, lastList, inBlock) => {


    if (
      (nodeName === 'figure' && node.firstChild && node.firstChild.nodeName === 'IMG') ||
      (nodeName === 'img' && inBlock !== 'IMAGE')
    ) {
      return 'atomic';
    }
  },
  htmlToStyle: (nodeName, node, currentStyle) => {


  
    if (node.className !== undefined) {
      return currentStyle.add(node.className);
    } else {
      return currentStyle;
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {

    if (nodeName === 'img') {
      const entityConfig = {};
      entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
      if(node.style.cssText.includes('width') ) {
        const splitWidth  = node.style.cssText.split(':')[1].replace(/%;/g, '')
        entityConfig.width = splitWidth;
      }

     return createEntity(
        'IMAGE',
        'MUTABLE',
        entityConfig,
      );
    }

    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', {
        url: node.getAttribute('href'),
      });
    }
  },
};

/**
 * Parse blocks into html
 */
const ToHTMLConverter = {
  entityToHTML: (entity, originalText) => {

    if (entity.type === 'IMAGE') {
      return `<img src="${entity.data.src}" style='${entity.data.style ? entity.data.style: ''}${entity.data.height && entity.data.height > 0 ? `height: ${entity.data.height}%;` :''}${entity.data.width && entity.data.width > 0 ? `width: ${entity.data.width}%;` :''}'/>`;
    } else if (entity.type === 'LINK') {
      return (
        // eslint-disable-next-line
        <a
          aria-label='rte-link'
          className='underline'
          rel='noreferrer'
          href={entity.data.url}
        />
      );
    }
    return originalText;
  },
};

export { FromHTMLConverter, ToHTMLConverter };

