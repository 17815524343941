import React from "react";
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import PaymentMethod from "../../components/organisms/PaymentMethod/PaymentMethod";


const PaymentMethodsOutput = () => (
    <AccountPageWrapper metaTitle="Account - Payment Methods" title="Payment Methods">
        <PaymentMethod />
    </AccountPageWrapper>
)

export default PaymentMethodsOutput