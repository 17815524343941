import React from 'react';

const Mail = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 21L17.6 19.6L19.175 18H15V16H19.175L17.6 14.4L19 13L23 17L19 21ZM4 19C3.45 19 2.97933 18.8043 2.588 18.413C2.196 18.021 2 17.55 2 17V7C2 6.45 2.196 5.97933 2.588 5.588C2.97933 5.196 3.45 5 4 5H17C17.55 5 18.021 5.196 18.413 5.588C18.8043 5.97933 19 6.45 19 7V11H17V8.4L10.4 13L4 8.425V17H13V19H4ZM5.45 7L10.4 10.55L15.5 7H5.45Z'
        fill='black'
      />
    </svg>
  );
};

export default Mail;
