import React from 'react';
import * as styles from './Layout2.module.css';

import { returnAlt, returnSrc } from '../../../../helpers/contentful';
import Container from '../../../atoms/Container/Container';
import ContentfulRichText from '../../../atoms/ContentfulRichText/ContentfulRichText';
import MobileLayout from './MobileLayout';

const Layout2 = props => {

  const { title, landscapePicture, smallPicture, portraitPicture, content, size } = props;

  const landscapePictureAlt = returnAlt(landscapePicture);
  const landscapePictureSrc = returnSrc(landscapePicture);

  const smallPictureAlt = returnAlt(smallPicture);
  const smallPictureSrc = returnSrc(smallPicture);

  const portraitPictureAlt = returnAlt(portraitPicture);
  const portraitPictureSrc = returnSrc(portraitPicture);

  const contentfulImages = {
    landscapePictureAlt, landscapePictureSrc,
    portraitPictureAlt, portraitPictureSrc,
    smallPictureAlt, smallPictureSrc
  }

  return (
    <div className={ styles.ContentfulBlockOne }>
      <Container size={size ? size.toLowerCase() : ''}>
        <div className={styles.mainGrid}>
          <div className={styles.leftContainer}>
            <h2 className={styles.title}>
              {title}
            </h2>
            <div className={styles.landscapeContainer}>
              <img src={`${landscapePictureSrc}?w=829&h=520&fit=fill`} alt={landscapePictureAlt}></img>
            </div>

            {/* only appears on mobile */}
            {/* <div className={styles.mobileGrid}>
              <div className={styles.portraitContainer}>
                <img src={portraitPictureSrc} alt={portraitPictureAlt}></img>
              </div>
              <div className={styles.smallImageContainer}>
                <img src={smallPictureSrc} alt={smallPictureAlt}></img>
              </div>
            </div> */}

            <MobileLayout {...contentfulImages}/>

            {content?.raw && 
              <div className={styles.contentContainer}>
                <ContentfulRichText raw={content?.raw} />
              </div>
            }

          </div>
          <div className={styles.rightContainer}>
            <div className={styles.smallImageContainer}>
              <img src={`${smallPictureSrc}?w=230&h=230&fit=fill`} alt={smallPictureAlt}></img>
            </div>
            <div className={styles.portraitContainer}>
              <img src={`${portraitPictureSrc}?w=447&h=605&fit=fill`} alt={portraitPictureAlt}></img>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Layout2;
