import React from 'react';
import * as styles from './CtbBorder.module.css';

const CtbBorder = props => {
  return (
    <div className={ styles.CtbBorder }/>
  );
};

export default CtbBorder;
