import React from 'react';
import Icon from '../Icon/Icon';
import * as styles from './MessageAlert.module.css';

const MessageAlert = ({type, message, children}) => {
  return (
    <div className={ `${styles.MessageAlert} ${styles[type]}` }>
      <span className={styles.icon}><Icon symbol="infoSolid" /></span>
      {message || children}
    </div>
  );
};

export default MessageAlert;
