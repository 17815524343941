import React from 'react';
import Layout1 from './Layouts/Layout1';
import Layout2 from './Layouts/Layout2';
import Layout3 from './Layouts/Layout3';

const ContentfulBlockTrio = props => {
  
  const { layout } = props;

  // check for example layouts
  switch(layout) {

    case 'Layout 1':
      return <Layout1 {...props} />;

    case 'Layout 2':
      return <Layout2 {...props} />;

    case 'Layout 3':
      return <Layout3 {...props} />;

    default:
      return null;
  }
  
};

export default ContentfulBlockTrio;
