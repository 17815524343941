import React, { useMemo } from 'react';
import { transformWpPost } from '../../../helpers/wordpress';
import Layout from '../Layout/Layout';
import Seo from '../Seo/Seo';
import PostList from '../PostList/PostList';
import Pagination from '../../atoms/Pagination/Pagination';
import PageTitle from '../../atoms/PageTitle/PageTitle';

import * as styles from './BlogList.module.css';

const BlogList = ({ posts, categories, pageContext, title, breadcrumbs = [], largeGridView = false }) => {
  const [tPosts, tCats] = useMemo(() => {
    const postsToRender = [];
    posts?.map(({ node: post }) => {
      postsToRender.push(transformWpPost(post));
      return post;
    });

    // For testing purposes only, remove if there will more records from the source
    // for(let x = 1; x <= 24; x++) {
    //   postsToRender.push(postsToRender[0]);
    // }

    // const lstPost = (postsToRender ? postsToRender.splice(0, 1) : [])[0];
    const catToRender = categories?.map(cat => ({
      uri: cat.uri ? `/blog${cat.uri}` : '',
      label: cat.name,
      itemKey: cat.id,
      description: cat.description,
      active: cat.id === pageContext.catId
    }));
    return [postsToRender, catToRender, pageContext];
  }, [posts, categories, pageContext]);
  // console.log('postsToRender', tPosts)

  return (
    <Layout>
      <Seo title={title}>
        <body className="noFooterMargin" />
      </Seo>
      <div className={styles.blogContainer}>
        <section className={styles.blogHeading}>
          <PageTitle title={title} titleClass={styles.title} />{/* To use dynamic category */}
          {/* <div className={styles.categoryList}>
            {categories && categories.map((category, index) => (
              <Link key={index} className={`${styles.categoryLink} ${category.id === pageContext.catId ? styles.catActive : ''}`} to={`/spotlight${category.uri}`}>{category.name}</Link>
            ))}
          </div> */}
        </section>
        {/* <section className={styles.latestPostContainer}>
          {lstPost && (
            <BlogPostCard 
              size={'large'}
              key={lstPost.id}
              title={lstPost.title}
              excerpt={lstPost.excerpt}
              slug={lstPost.slug}
              categories={lstPost.categories}
              // content={lstPost.content}
              // authorName={lstPost.author_name}
              // authorUri={lstPost.author_uri}
              date={lstPost.published_date}
              viewMode={'grid'}
              thumbnail={lstPost.thumbnail_path || 'https://via.placeholder.com/560x560?text=Not+Found'}
              titleOnly={false}
              noCategories={true}
              highlightView={true}/>
          )}
        </section> */}
        <section className={styles.postsContainer}>
          {tPosts && tPosts.length > 0 ? (
            <PostList posts={tPosts} categories={tCats} viewMode="grid" cardSize={largeGridView ? 'xlarge' : 'large'} /> // To condition if news media 'large' | blog category 'xlarge'
          ) : (
            <h6>Oops, it looks like there are no blogs for this category yet.</h6>
            // <>{lstPost === undefined && (<h6>Oops, it looks like there are no blogs for this category yet.</h6>)}</>
          )}
          <Pagination pageContext={pageContext} pathPrefix="/spotlight" />
        </section>

        {/* <section className={styles.featuredPosts}>
          <div className={styles.featuredPostsBackground}>
              <h4 className="text-center">Featured</h4>
              <FeaturedPosts />
          </div>
        </section> */}
        
        {/* <section className={styles.products}>
          <div className={styles.background}>
              <h4 className="text-center">Best Sellers</h4>
              <FeaturedProducts
                responsive={[
                  {
                    breakpoint: 880,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              />
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export default BlogList;
