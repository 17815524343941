import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import BlogPost from '../components/organisms/BlogPost/BlogPost';
import { transformBCPost } from '../helpers/bigcommerce';

const BCPost = ({ data }) => {
  const { post } = data;

  return (
    <Layout>
      <Helmet title={post.title} />
      <BlogPost post={transformBCPost(post)} />
    </Layout>
  );
};

BCPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BCPost;

export const query = graphql`
  query BCBlogPostByID($id: String!) {
    post: bigCommercePosts(id: { eq: $id }) {
      ...BCPostListFields
    }
  }
`;
