import React, { useContext, useEffect, useState } from 'react';
import Icon from '../../atoms/Icon/Icon';
import CartContext from '../../../context/CartProvider';
import { get } from 'lodash';
// import { getStorage, setStorage } from '../../../helpers/general';
// import { LOCAL_STORE } from '../../molecules/Localisr/Statics';
// import Localisr from '../../molecules/Localisr/Localisr';
import DropDown from '../../atoms/Dropdown/Dropdown';
// import Loader from '../../atoms/Loader/Loader';

import * as styles from './CartShippingOptions.module.css';

const CartShippingOptions = ({
  isValid,
  setShippingAddress,
  setShippingOptions,
}) => {
  // const CartShippingOptions = () => {

  const DELIVERY = 'delivery';
  const COLLECT = 'collect';

  const ctx = useContext(CartContext);
  const initCheckout = get(ctx, 'initCheckout');
  const changeShippingMethod = get(ctx, 'changeShippingMethod');
  // const cartPhysicalItems = get(ctx, 'state.cart.lineItems.physical_items');
  const ctxSelectedStore = get(ctx, 'state.selectedStore');
  // const ctxStockLoading = get(ctx, 'state.stockLoading');
  //   const ctxStockAvailability = get(ctx, 'state.stockAvailability');
  // const [localStockAvailability, setLocalStockAvailability] = useState(ctxStockAvailability);

  const ctxShippingMethod = get(ctx, 'state.shippingMethod');
  const withGradHire = get(ctx, 'state.withGradHire');
  const [localShippingMethod, setLocalShippingMethod] = useState(
    ctxShippingMethod
  );

  const [selected, setSelected] = useState(ctxShippingMethod || '');
  const defaultStore = {
    store_id: ctxSelectedStore?.store_id || '',
    store_name: ctxSelectedStore?.store_name || '',
    store_address: ctxSelectedStore?.store_address || '',
    store_location: ctxSelectedStore?.store_location || '',
  };
  const [selectedStore, setSelectedStore] = useState({ ...defaultStore });
  const [loading, setLoading] = useState(false);
  const [forcingMethodUpdate, setForcingMethodUpdate] = useState(false);
  const [issueMessage, setIssueMessage] = useState(null);
  const [unableDelivery, setUnableDelivery] = useState(false);

  const storeList = [
    {
      store_name: 'USYD Store- Holme',
      store_id: 'holme',
      store_address:
        `Ground Floor of the Holme Building (across from Courtyard Bar and Restaurant),
        Science Road,
        THE UNIVERSITY OF SYDNEY,
        NSW 2006`,
      store_location: {
        address_1: 'Ground Floor of the Holme Building',
        address_2: '(across from Courtyard Bar and Restaurant) Science Road',
        suburb: 'Sydney',
        phone: '(02) 9563 6019',
        email: 'noreply@usu.edu.au',
        postcode: '2006',
        state: 'New South Wales',
        country: 'AU',
      },
      hours : [{
        days : 'Monday to Friday',
        hours: '9:00am - 4:00pm'
      }]
    },
  ];

  useEffect(() => {
    if (ctxSelectedStore && ctxSelectedStore.store_id && setSelectedStore) {
      setSelectedStore(ctxSelectedStore);
    }
  }, [ctxSelectedStore, setSelectedStore]);

  // commenting out certain operations
  const selectOption = async (shippingType, force) => {
    // const selectOption = async (shippingType) => {
    // setLocalShippingMethod(shippingType);

    if (withGradHire && shippingType === DELIVERY) return;

    if (selected !== shippingType || force) {
      isValid(false);
      setLoading(true);
      const result = await changeShippingMethod(shippingType);

      if (result === 'forceDelivery') {
        setSelected(DELIVERY);
        validTest(DELIVERY);
        setIssueMessage(
          'The current item(s) in the cart can not be collected.'
        );
      } else {
        setSelected(shippingType);
        validTest(shippingType);
        if (shippingType === COLLECT && storeList.length > 0) {
          const newStore = storeList.find(
            store =>
              store.store_id === selectedStore.store_id || storeList[0].store_id
          );
          if (newStore) {
            await changeShippingMethod(COLLECT, {
              ...ctx.state,
              selectedStore: newStore,
            });
            validTest(COLLECT, newStore);
          }
        }
      }

      setLoading(false);

      if (!force) {
        setForcingMethodUpdate(false);
      }
    }
  };

  const onSelectStore = async storeId => {
    setLoading(true);
    isValid(false);
    const newStore = storeList.find(
      store => store.store_id === storeId.target.value
    );
    if (newStore) {
      await changeShippingMethod(COLLECT, {
        ...ctx.state,
        selectedStore: newStore,
      });
      validTest(COLLECT, newStore);
    }
    setLoading(false);
  };

  const validTest = (method = '', store = false) => {
    const _method = method || selected;
    const _store = store || selectedStore;

    if (_method === COLLECT && _store.store_id === '') {
      // && ctxStockAvailability.length === 0
      isValid(false);
    } else if (_method === COLLECT && _store.store_id !== '') {
      isValid(true);
    } else if (_method === DELIVERY) {
      if (_store.store_id !== '') {
        // Update cart if set to
        setShippingAddress &&
          setShippingAddress({ billingSameAsShipping: true, country: 'AU' });
        setShippingOptions && setShippingOptions([]);
      }
      isValid(true);
    }
  };

  useEffect(() => {
    validTest();
    const initProcess = async () => {
      if (selected === COLLECT && selectedStore.store_id !== '') {
        setLoading(true);
        const result = await changeShippingMethod(selected);
        if (result === 'forceDelivery') {
          setSelected(DELIVERY);
          validTest(DELIVERY);
          setIssueMessage(
            'The current item(s) in the cart can not be collected.'
          );
        }

        initCheckout();
        setLoading(false);
      }
    };

    if (withGradHire) {
      setSelected(COLLECT);
      validTest(COLLECT);
      setUnableDelivery(true);
      setIssueMessage(
        'The current item(s) in the cart can not be shipped.'
      );
    }
    initProcess();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //     if (JSON.stringify(ctxStockAvailability) !== JSON.stringify(localStockAvailability)) {
  //         setLocalStockAvailability(ctxStockAvailability);
  //         validTest();
  //     }
  //     // eslint-disable-next-line
  // }, [ctxStockAvailability])

  useEffect(() => {
    if (!forcingMethodUpdate) {
      setLocalShippingMethod(ctxShippingMethod);
    }
    // eslint-disable-next-line
  }, [ctxShippingMethod]);

  useEffect(() => {
    if (selected !== localShippingMethod && !loading) {
      // Force the right selected shipping method. `selected` maintains the user selection and should be followed.
      setForcingMethodUpdate(true);
      setLocalShippingMethod(selected);
      selectOption(selected, true);
    }
    // eslint-disable-next-line
  }, [localShippingMethod, selected, loading]);

  return (
    <div className={styles.optionsContainer}>
      {issueMessage && (
        <div className={styles.issueMessage}>
          <Icon symbol='alert' /> {issueMessage}
        </div>
      )}
      <div className={styles.options}>
        <div
          role='presentation'
          className={`${styles.option} ${
            localShippingMethod === DELIVERY ? styles.selected : ''
          } ${unableDelivery ? styles.unable : ''}`}
          onClick={() => selectOption(DELIVERY)}>
          <div className={styles.optionTitle}>
            <span className={styles.title}>
              <Icon symbol='truck' /> Delivery
            </span>
            <span className={styles.radio}></span>
          </div>
          <div className={styles.optionDesc}>
            Shipping calculated at checkout
          </div>
        </div>

        <div
          role='presentation'
          className={`${styles.option} ${
            localShippingMethod === COLLECT ? styles.selected : ''
          }`}
          onClick={() => selectOption(COLLECT)}>
          <div className={styles.optionTitle}>
            <span className={styles.title}>
              <Icon symbol='house' />
              Collect from store
            </span>
            <span className={styles.radio}></span>
          </div>
          <div className={styles.optionDesc}>
            <div>
              <DropDown
                optionList={storeList.map(store => ({
                  label: store.store_name,
                  value: store.store_id,
                }))}
                action={onSelectStore}
              />
            </div>
            <div className={styles.ccStoreWrap}>
              {(localShippingMethod !== COLLECT ||
                (localShippingMethod === COLLECT &&
                  selectedStore.store_id === '')) && (
                <span>Order online, collect in-store</span>
              )}
              {localShippingMethod === COLLECT &&
                selectedStore.store_id !== '' && (
                  <span className={styles.ccStoreDetail}>
                    {!loading && (
                      <span className={styles.ccStoreAddress}>
                        {selectedStore?.store_address.split(',').map((element, eI) => <React.Fragment key={eI}>{element} <br/></React.Fragment>)} 
                        <br/>
                        <strong>Opening hours</strong><br/>
                        {selectedStore?.hours?.map((time, tI) => <React.Fragment key={tI}>{time.days} {time.hours}</React.Fragment>)}
                      </span>
                    )}
                  </span>
                )}
              {/* <span className={[styles.ccChangeStore, ...(localShippingMethod === COLLECT ? [] : ['hide-option'])].join(' ')}>
                                <Localisr text={selectedStore.store_id === '' ? 'Select a Store' : 'Change Store'} products={cartPhysicalItems} onStoreSelected={onSelectStore}/>
                            </span> */}
            </div>
          </div>
        </div>
      </div>
      {/* {(loading || ctxStockLoading) && (
                <div className={styles.loadingSwitching}>
                    <Loader />
                </div>
            )} */}
    </div>
  );
};

export default CartShippingOptions;

