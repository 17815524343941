// extracted by mini-css-extract-plugin
export var actionCard = "details-module--actionCard--5847c";
export var addCategory = "details-module--addCategory--46a08";
export var addRow = "details-module--addRow--4d628";
export var block = "details-module--block--b5a1b";
export var buttonWrap = "details-module--buttonWrap--83419";
export var columnTitle = "details-module--columnTitle--1d9c7";
export var extraDetails = "details-module--extraDetails--39b56";
export var extraDetailsWrapper = "details-module--extraDetailsWrapper--8a9f3";
export var fileHelper = "details-module--fileHelper--38aa3";
export var fileHelperContainer = "details-module--fileHelperContainer--f3c12";
export var fileName = "details-module--fileName--4f0a7";
export var filePicker = "details-module--filePicker--ef23b";
export var filePickerContainer = "details-module--filePickerContainer--990b5";
export var first = "details-module--first--c8864";
export var grantDetails = "details-module--grantDetails--c3fbb";
export var header = "details-module--header--bd704";
export var label = "details-module--label--9ae15";
export var last = "details-module--last--6ba2e";
export var loading = "details-module--loading--c56ad";
export var rowDelete = "details-module--rowDelete--a855e";
export var rowTitle = "details-module--rowTitle--458e3";
export var rowValue = "details-module--rowValue--e2872";
export var spaceSection = "details-module--spaceSection--b8a53";
export var spendSplit = "details-module--spendSplit--8ee95";
export var spendValue = "details-module--spendValue--e5328";