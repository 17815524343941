import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify'
import { getFutureEvents, applyUser, getShiftList, mapShiftStatus } from '../../../helpers/rosterfy';

import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import Icon from '../../atoms/Icon/Icon';
import Loader from '../../atoms/Loader/Loader';

import * as styles from './VTeamEventList.module.css';

const VTeamCard = ({event, showDialog, rId}) => {

  const [toggle, setToggle] = useState(false);

  const startDate = new Date(event.start_timestamp).toDateString();
  const endDate = new Date(event.end_timestamp).toDateString();

  const startTime = new Date(event.start_timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', timeZone: event.timezone})
  const endTime = new Date(event.end_timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', timeZone: event.timezone})

  let isDisabled = false;
  if(rId === undefined || event.message) isDisabled = true;

  return(
    <div className={styles.vTeamCard}>
      <div className={styles.imageContainer}>
        <img alt={event.title} src={event.image}></img>
      </div>
      <div className={styles.contentContainer}>
        <span className={styles.title}>{event.name}</span>
        <div className={styles.eventAttribute}>
          <div className={styles.iconContainer}><Icon symbol={'storeLocator'}></Icon></div>
          <span>{event.address_text}</span>
        </div>
        <div className={styles.eventAttribute}>
        <div className={`${styles.iconContainer} ${styles.calendarIconContainer}`}><Icon symbol={'calendar'}></Icon></div>
          <span>{startDate} {startDate === endDate ? '' : `- ${endDate}`}</span> 
        </div>
        <div className={styles.eventAttribute}>
        <div className={styles.iconContainer}><Icon symbol={'clock'}></Icon></div>
          <span>{startTime} - {endTime}</span> 
        </div>
        <div className={styles.eventAttribute}>
          <div className={styles.iconContainer}><Icon symbol={'infoCircle'}></Icon></div>
          <span>{event.message ? <span className={styles.error}>{event.message}</span> : 'Applications are currently open'}</span>
        </div>
        <div className={styles.eventAttribute}>
          <div className={`${styles.iconContainer} ${styles.eventDescriptionIcon} ${toggle === true ? styles.rotate :''}`}>
            <Icon symbol={'chevronDown'}></Icon></div>
            <span role={'presentation'} className={styles.descriptionToggle} onClick={() => setToggle(!toggle)}>{ toggle === false ? 'See event description' : 'Hide event description'}</span>
        </div>
        {toggle === true && 
          <div className={styles.eventDescription}>
            <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
            {/* <span onClick={()=> setToggle(false) } className={styles.descriptionToggle}>Hide description</span> */}
          </div>
        }

      </div>
      <div className={styles.buttonContainer}>
          {/* //External Link */}
          {/* <a href={event.url} type={'a'} level={'primary'}>View Event</a> */}
          {rId === undefined && <span className={styles.errorRosterfy}>No volunteer account</span>}
          <Button 
            size={'noWidth'}
            level={'primary'} 
            type={'button'} onClick={showDialog}
            disabled={isDisabled}
            >View Shifts
          </Button>
      </div>
    </div>
  )
}


const VTeamEventList = props => {

  const { rosterfyId } = props;
  const [events, setEvents] = useState(false);

  const [eventId, setEventId] = useState();
  const [shifts, setShifts] = useState();
  const [showShift, setShowShift] = useState(false);
  const [shiftLoading, setShiftLoading] = useState(false);
  
  // useMemo(() => {
  //   // console.log(auth.state);
  //   if(auth?.state?.object?.form_fields) {
  //     // check for existing rosterfy id
  //     // to refactor: bind this to auth context
  //     // const usunumber
  //     if(Array.isArray(auth.state.object.form_fields)) {
  //       const usuNumber = auth.state.object.form_fields.filter((field) => field.name === 'usunumber').map((field) => field.value)[0];
  //       // console.log('USU Number:', usuNumber);
  //       searchUser((usuNumber)).then((response) => {
  //         // To Do: go back here when auth is cleared out
  //         if(response.response.length === 0) setRosterfyId(undefined)
  //         else setRosterfyId(response.response[0].object.id);
  //       })
  //     }
      
  //   }
  // }, [auth])
  
  useMemo(() => {
      if (!events && typeof window !== 'undefined') {
          getFutureEvents().then((response) => {
            setEvents(response);           
          });
      }
  }, [events]);

  useMemo(() => {
    if(showShift === true) {
      setShiftLoading(true);
      getShiftList(eventId, rosterfyId).then((response) => {
        setShifts(response);
        setShiftLoading(false);
      })
    }
  }, [eventId, showShift, rosterfyId]);

  const reloadShifts = (eventId) => {
    setShifts(undefined);
    setShiftLoading(true);
    getShiftList(eventId, rosterfyId).then((response) => {
      setShifts(response);
      setShiftLoading(false);
    })
  }

  return (
    <div className={ styles.VTeamEventList }>
      {!events && (<div>
          <Loader />
      </div>)}

      {events && events.length === 0 && 
        <p>No events listed</p>
      }

      {events && 
        <div className={styles.eventGrid}>
          {events.map((event) => <VTeamCard rId={rosterfyId} key={event.id} showDialog={() => {
            setShowShift(true);
            setEventId(event.id);
          }} event={event} />)}
        </div>
      }

      <Dialog 
        open={showShift} 
        size="sm" 
        hideBtnCancel 
        hideBtnOk 
        title={'Select a Shift'}
        onCancel={()=>{
          setShowShift(false);
          setShifts();
        }}>
          <div className={styles.shiftDialogContainer}>            
            {shifts?.length === 0 ? <span>No shifts available</span> :
            <div className={styles.shiftGridHeader}>
              <span>Date</span>
              <span>Time</span>
            </div>}

            {shifts && shifts.map((shiftObj) => {
              return(
                <div className={styles.shiftGrid} key={shiftObj.shift_id}>
                  <span>{shiftObj.date}</span>
                  <span>{shiftObj.start_timestamp} - {shiftObj.end_timestamp}</span> 
                  <Button 
                    type={'button'} 
                    size={'noWidth'} 
                    onClick={() => {
                      if(shiftObj.status === 0) {
                        applyUser(shiftObj.event_id,shiftObj.shift_id,rosterfyId).then((applicationResponse) => {
                          if(applicationResponse === true) {
                            reloadShifts(shiftObj.event_id);
                            toast.success('Application received');
                          } else {
                            toast.error('Something went wrong');
                          }
                        })
                      } 
                    }}
                    disabled={shiftObj.status === 0 ? false : true}
                    level={'primary'}>
                    {mapShiftStatus(shiftObj.status)}
                  </Button>
                </div>
              )
            })}
            {shiftLoading === true && <div className={styles.shiftLoaderContainer}><Loader /></div>}
          </div>
      </Dialog>
      
    </div>
  );
};

export default VTeamEventList;
