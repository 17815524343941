import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby';
// import BlogList from '../components/organisms/BlogList/BlogList';

// import * as styles from './blogs.module.css';

const Blogs = props => {
  // const brandsQuery = useStaticQuery(graphql`
  //   query IndexQuery {
  //     posts: allWpPost {
  //       edges {
  //         node {
  //           id
  //           title
  //           excerpt
  //           uri
  //           date(formatString: "MMMM DD, YYYY")
  //           featuredImage {
  //             node {
  //               uri
  //               sourceUrl
  //             }
  //           }
  //           categories {
  //             nodes {
  //               name
  //               uri
  //               slug
  //             }
  //           }
  //         }
  //       }
  //     }
  //     categories: allWpCategory {
  //       nodes {
  //         id
  //         uri
  //         name
  //         description
  //       }
  //     }
  //   }
  // `);

  // const posts = (brandsQuery && brandsQuery.posts?.edges) || [];
  // const categories = (brandsQuery && brandsQuery.categories.nodes) || [];

  return ( 
    <></>
    // <BlogList
    //   posts={posts}
    //   categories={categories}
    //   pageContext={pageContext}
    //   breadcrumbs={[{label: 'Latest posts' }]}
    //   title="Latest posts"
    // />
  );
}
 
export default Blogs;