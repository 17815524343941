import React from 'react';
import * as styles from './ContentfulButton.module.css';
import { camelCase } from 'lodash';

import Button from '../../atoms/Button/Button';
import { useWindowDimensions } from '../../../helpers/general';

const ContentfulButton = props => {
  const { width } = useWindowDimensions();

  let buttonFontSize = props?.fontSize;
  if(width < 800) buttonFontSize = props?.fontSizeMobile; 

  const mapButtonSize = () => {
    let buttonSize = '';
    if(width < 800) {
        if(props?.sizeMobile === 'Medium') return buttonSize;
        if(props?.sizeMobile === 'No Width' || props?.sizeMobile === 'Full Width') { return camelCase(props?.sizeMobile);}
        else {
            return props?.sizeMobile?.toLowerCase(); //smallest small large
        }
    } else {
        if(props?.size) {
            if(props?.size === 'Medium') return buttonSize;
            if(props?.size === 'No Width' || props?.size === 'Full Width') return camelCase(props?.size);
            else {
                return props?.size?.toLowerCase(); //smallest small large
            }
        }
    }
    return buttonSize;
}

const buttonCustomStyling = {
  fontSize: buttonFontSize,
  marginTop: props?.marginTop ? props?.marginTop : '16px',
}

  return (
    <div className={ styles.ContentfulButton }>
      <Button 
        inlineStyling={buttonCustomStyling} 
        size={mapButtonSize()} 
        level={'primary'}
        type={'a'}
        href={props?.link?.url}
      >
          {props.label}
      </Button>
    </div>
  );
};

export default ContentfulButton;
