import { useEffect } from 'react';

const UseOutsideClick = (
  ref, // Element ref,
  closeHandler, // Function to close element,
  listener // event (e.g mousedown, keyup)
) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        closeHandler();
      }
    };
    document.addEventListener(listener ?? 'click', handleClickOutside);
    return () => {
      document.removeEventListener(listener ?? 'click', handleClickOutside);
    };
  }, [closeHandler, ref, listener]);
};

export default UseOutsideClick;

