import React from 'react'
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';

import HorizontalCarousel from '../../atoms/HorizontalCarousel/HorizontalCarousel';
import * as styles from './ContentfulBlockCarousel.module.css';

const ContentfulBlockCarousel = props => {
    const { 
        size,
        slides,
        title,
        slidesToDisplay,
        // carouselType,
    } = props;

    // To do: support carouselType and slidesToDisplay
    // note: react-slick has a problem with width being dynamic https://github.com/akiran/react-slick/issues/1241

    const backgroundColor = get(props, 'backgroundColour.hexCode', false);
    const textColor = get(props, 'backgroundColour.supportingColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    const normalizedCarouselContent = slides?.map((slide) => {
        return {
            image: `https:${slide.cardImage?.image?.file?.url}`,
            name: slide.name,
            subtitle: slide.subtitle,
            altText: slide.altText,
            id: slide.id,
            link: slide.link?.url,
        }
    })

    return (
        <section className={`${styles.pageCarousel}`} style={mainStyles}>
            <Container size={typeof size === 'string' && size?.toLowerCase()}>
                <HorizontalCarousel 
                    slidesToDisplay={slidesToDisplay} 
                    data={normalizedCarouselContent}
                    title={title}
                />
                {/* Carousel Code here<br/>
                Carousel Type: {carouselType}<br />
                Title: {title}<br />
                Slides to output here: {slides.length}<br />
                Slides to display in one view: {slidesToDisplay} */}
            </Container>
        </section>
    )
}

export default ContentfulBlockCarousel;