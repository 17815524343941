import { get } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import Dialog from '../../../components/atoms/Dialog/Dialog';
import Button from '../../../components/atoms/Button/Button';
import Icon from '../../../components/atoms/Icon/Icon';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, persistLocation } from '../../../helpers/general';
import { getPerson, getTags, updatePerson, formatDate } from '../../../helpers/ortto';
import { getProfile } from '../../../helpers/usuapi';

const Members = ({ location }) => {
  const fileRef = useRef();
  const auth = useContext(AuthContext);
  const [authed, setAuthed] = useState(null);
  const { state } = location;
  const [memberList, setMemberList] = useState(null);
  const [csv, setCsv] = useState();
  const [csvCount, setCsvCount] = useState(0);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [processingCsv, setProcessingCsv] = useState(false);
  const [csvLines, setCsvLines] = useState([]);
  const [csvLinesCompleted, setCsvLinesCompleted] = useState(0);
  const [csvResults, setCsvResults] = useState(false);

  const fetchClubMembers = useCallback(async () => {
    const result = await getTags(`club-${state.clubSlug}`);
    if (
      result.status === 200 &&
      result.response &&
      result.response.length > 0
    ) {
      const findClub = result.response.find(
        club => club.name === `club-${state.clubSlug}`
      );
      // Successfully found
      if (findClub) {
        const memberList = await getPerson(
          ['first', 'last', 'email', 'Tags', 'MemberNumber'],
          findClub.id,
          'tag_id'
        );
        if (
          memberList.status === 200 &&
          'contacts' in memberList.response &&
          Array.isArray(memberList.response.contacts)
        ) {
          setMemberList(
            memberList.response.contacts.map(member => {
              // console.log(member);
              const name = `${member.fields['str::first'] || ''} ${member
                .fields['str::last'] || ''}`.trim();
              const level =
                member.fields.tags
                  .find(t => t.indexOf(`clubLevel-${state.clubSlug}-`) === 0)
                  ?.replace(`clubLevel-${state.clubSlug}-`, '') || 'Standard';
              return {
                id: member.id,
                member_number: member.fields['str:cm:membernumber'],
                member_name: name || '(No name set)',
                email: member.fields['str::email'],
                level: level,
              };
            })
          );
        }
      }
    }
  }, [ state]);

  const removeMemberHandler = async data => {
    const clubKey = `club-${state.clubSlug}`;
    const userKey = data.email;

    const person = [
      {
        email: userKey,
        unset_tags: [clubKey],
      },
    ];
    const updateResponse = await updatePerson(person);

    if (updateResponse.status === 200 && 'people' in updateResponse.response) {
      toast.success('Member has been removed');
      fetchClubMembers();
    }
  };

  const uploadBulk = () => {
    setProcessingCsv(true);
    let processCount = 0;
    const clubTag = `club-${state.clubSlug}`;
    const clubLevel = `clubLevel-${state.clubSlug}-Standard`;
    const processed = csvLines.map(async line => { // .splice(2, 2)
      const memberNumber = line[0];
      const profile = await getProfile(memberNumber);
      if (String(profile.status).startsWith('2') && profile.response.length > 0) {
        const record = await getPerson(['email'], profile.response[0].Email);
        if (String(record.status).startsWith('2') && record.response.contacts?.length > 0) {
          if (!record.response.contacts[0].fields.tags || record.response.contacts[0].fields.tags.indexOf(clubTag) === -1) {
            const person = [
              {
                email: profile.response[0].Email,
                tags: [clubTag, clubLevel],
              },
            ];
            const updateResponse = await updatePerson(person);

            if (updateResponse.status === 200 && 'people' in updateResponse.response) {
              processCount++;
              setCsvLinesCompleted(processCount);
              return {status: 'success', memberNumber: memberNumber};
            } else {
              // Failed to update member
              processCount++;
              setCsvLinesCompleted(processCount);
              return {status: 'error', memberNumber: memberNumber, reason: 'Failed to update member in Ortto'};
            }
          } else {
            // Member already on list
            processCount++;
            setCsvLinesCompleted(processCount);
            return {status: 'error', memberNumber: memberNumber, reason: 'Member already on list'};
          }
        } else {
          // No record in Ortto - create it
          const person = [
            {
              first: profile.response[0].FirstName,
              last: profile.response[0].LastName,
              phone: profile.response[0].Mobile,
              email: profile.response[0].Email,
              externalId: profile.response[0].MemberNumber,
              customFields: {
                "membernumber": profile.response[0].MemberNumber,
                "mid":  profile.response[0].MID,
                "lastregistered": (parse(profile.response[0].LastRegistered, 'dd/LL/yyyy h:mm:ss a', new Date())).toISOString(),
                "sid": profile.response[0].SID,
                "faculty": profile.response[0].Faculty,
                "location": profile.response[0].Location,
                "expiry": formatDate(parse(profile.response[0].Expiry, 'dd/LL/yyyy h:mm:ss a', new Date())),
                "memberstatus": profile.response[0].Status,
                "specialname": profile.response[0].SpecialName,
                "barcode": profile.response[0].barcode,
                "student": ["True", "1"].indexOf(profile.response[0].Student) > -1 ? true : false,
                "usustaff": profile.response[0].USUStaff > 0 ? true : false,
                "usustafflevel": profile.response[0].USUStaff,
                "usyd": ["True", "1"].indexOf(profile.response[0].USYD) > -1 ? true : false,
                "lifemember": ["True", "1"].indexOf(profile.response[0].Life_Member) > -1 ? true : false,
                "bepozid": profile.response[0].BepozID,
                "vectronid": profile.response[0].VectronID
              },
            }
          ]
          const updateResponse = await updatePerson(person);

          if (updateResponse.status === 200 && 'people' in updateResponse.response) {
            processCount++;
            setCsvLinesCompleted(processCount);
            return {status: 'success', memberNumber: memberNumber};
          } else {
            // Failed to update member
            processCount++;
            setCsvLinesCompleted(processCount);
            return {status: 'error', memberNumber: memberNumber, reason: 'Failed to create member in Ortto'};
          }
          // processCount++;
          // setCsvLinesCompleted(processCount);
          // return {status: 'error', memberNumber: memberNumber, reason: 'No profile exists in Ortto'};
        }
      } else {
        processCount++;
        setCsvLinesCompleted(processCount);
        return {status: 'error', memberNumber: memberNumber, reason: 'No profile returned from USU API'};
      }
    });

    Promise.all(processed).then(data => {
      setCsvResults(data);
    })
  }

  const resetCsv = () => {
    setCsv(false); 
    setCsvCount(0); 
    setProcessingCsv(false);
    setCsvLines([]);
    setCsvLinesCompleted(0);
    setCsvResults(false);
    setOpenBulkUpload(false);
  }

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
    }
  }, [auth, authed, setAuthed]);

  useEffect(() => {
    if (memberList === null) {
      fetchClubMembers();
    }
  }, [fetchClubMembers , memberList]);

  useEffect(() => {
    if (csv) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const lines = content.split('\n').filter(line => line !== '').map(line => line.replace('\r', '').split(',')).slice(1);
        setCsvLines(lines);
        setCsvCount(lines.length);
      }
      reader.readAsText(csv);
    }
  }, [csv]);

  return (
    <div>
      <DataTable
        tableData={memberList}
        topActions={[
          {
            label: 'Upload bulk',
            icon: <Icon symbol='upload' />,
            event: () => setOpenBulkUpload(true),
          },
        ]}
        headingKeys={[
          { label: 'Member Name', data_key: 'member_name', sortable: true },
          { label: 'Email', data_key: 'email', sortable: true },
          {
            label: 'Membership Level',
            data_key: 'level',
            filterable: true,
          },
        ]}
        rowActions={[
          {
            label: 'Remove member',
            cta: data => removeMemberHandler(data),
            withWarning: true,
            warningMessage: `Are you sure you want to remove this member?`
          },
        ]}
        noFirstAction={true}
      />
      <Dialog size='xs' open={openBulkUpload} hideBtnOk={true} hideBtnCancel={true} onBtnCancel={() => resetCsv()}>
        {!csv && (
          <>
            <h6>Provide CSV file</h6>
            <p className='lowerFont'>CSV should have the member number in the first column, include headers and be delimited by a comma.</p>
            <input
              accept='.csv'
              type={'file'}
              style={{display: 'none'}}
              ref={fileRef}
              multiple={false}
              onChange={e => {
                if (
                  e.currentTarget?.files &&
                  e.currentTarget.files.length > 0
                )
                setCsv(e.currentTarget.files?.[0]);
              }}
            />
            <Button
              size={'tiniest'}
              level={'primary'}
              type='span'
              onClick={() => fileRef.current.click()}>
              Select CSV File
            </Button>
          </>
        )}
        {(csv && !processingCsv) && (
          <>
            <h6>Summary</h6>
            <p className='lowerFont'>Members to be uploaded: {csvCount}</p>
            <Button
              size={'tiniest'}
              level={'primary'}
              type='span'
              onClick={() => uploadBulk()}>
              Proceed
            </Button>
          </>
        )}
        {(processingCsv && !csvResults) && (
          <>
            <h6>Please wait while import is being processed...</h6>
            <p className='lowerFont'>Completed: {csvLinesCompleted}/{csvCount}</p>
          </>
        )}
        {csvResults && (
          <>
            <h6>Completed</h6>
            <p className='lowerFont'>Imported successfully: {csvResults.filter(r => r.status === 'success').length || 0}</p>
            <p className='lowerFont'>Failed to import: {csvResults.filter(r => r.status === 'error').length || 0}</p>
            {csvResults.filter(r => r.status === 'error').length > 0 && (
              <>
                <p className='lowerFont'><span style={{fontWeight: '700'}}>Details:</span></p>
                <ul>
                  {csvResults.filter(r => r.status === 'error').map((r, rI) => (
                    <li key={rI}>{r.memberNumber}: {r.reason}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

const USUClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      bgRaw
      title={`Manage Clubs Members for ${location.state?.clubName}`}
      breadcrumbTitle='Manage Clubs Members'>
      <Members location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUClubManagementOutput;

