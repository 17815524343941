import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { get } from "lodash"
import { decode } from 'he';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions } from '../../../helpers/general';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AccordionCard from '../../../components/atoms/AccordionCard/AccordionCard';
import GrantsList from '../../../components/molecules/GrantsList/GrantsList';
import AcquittalsList from '../../../components/molecules/AcquittalsList/AcquittalsList';

import * as styles from '../manage-screens.module.css';

const ClubDetails = ({ location }) => {
    const auth = useContext(AuthContext);
    // const memberDetails = auth && get(auth, 'state');
    const [authed, setAuthed] = useState(null);

    useEffect(() => {
        if (authed === null && get(auth, 'state.userChecked') && get(location, 'state.club')) {
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
            
        } else if (!get(location, 'state.club')) {
            navigate(`/account/manage-finances/`);
        }
    }, [auth, authed, setAuthed, location]);

    return location.state && location.state.club ? (
        <>
            <AccordionCard title="All Grants" showContent={false}>
                <GrantsList location={location} staffView={true} />
            </AccordionCard>

            <AccordionCard title="All Acquittals" showContent={false}>
                <AcquittalsList location={location} staffView={true} />
            </AccordionCard>
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(authed === null) && (
                <div>Fetching club finance data</div>
            )}
            {/* No grant found */}
            {(authed === true && get(location, 'state.club') === undefined) && (
                <div>An error occurred. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
            {/* No grant found */}
            {(authed === false) && (
                <div>You are not authorised to manage this club's finances. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    return (
        <AccountPageWrapper bgRaw metaTitle="Account - USU Management" title={`Manage ${decode(location.state ? location.state.clubName : 'club')}`} breadcrumbTitle={`Manage ${decode(location.state ? location.state.clubName : 'club')}`} breadcrumbs={[{ link: "/account/manage-finances/", label: "Manage Club Finances"}]} location={location}>
            <ClubDetails location={location} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

