import React from 'react';
import * as styles from './ContentfulBlockLinkContainer.module.css';

import Container from '../../atoms/Container/Container';
import SmartLink from '../../atoms/SmartLink/SmartLink';

const ContentfulBlockLinkContainer = props => {

  const { size, linkCollection, containerAlignment = 'Left', padding = 'None', paddingBottom } = props;

  // let localContainerSize = '';
  // if(typeof containerSize === 'string' && containerSize !== 'Default') {
  //   localContainerSize = containerSize.toLowerCase();
  // }

  return (
    <div className={ styles.ContentfulBlockLinkContainer }>
      <Container size={typeof size === 'string' ? size.toLowerCase(): ''}>
          <div className={styles[`container${containerAlignment}`] }>
            <div className={`${styles[`padding${padding}`]} ${styles[`paddingBottom${paddingBottom}`]}`}>
              {linkCollection && linkCollection.map((link, index) => {
                return <SmartLink key={index} menuLink={{...link, stylized: true}} />
              })}
            </div>
          </div>
      </Container>
    </div>
  );
};

export default ContentfulBlockLinkContainer;
