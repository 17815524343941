import React from 'react'

const GiftBox = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0.000244141H17V16.8837H0V0.000244141Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9286 7.16941H15.7857V4.74084H10.9286V7.16941ZM10.9286 15.6694H14.5714V8.3837H10.9286V15.6694ZM7.28571 15.6694H9.71429V8.3837H7.28571V15.6694ZM2.42857 15.6694H6.07143V8.3837H2.42857V15.6694ZM1.21429 7.16941H6.07143V4.74084H1.21429V7.16941ZM3.64286 2.37055C3.64286 1.7367 4.1565 1.22305 4.79036 1.22305C5.13461 1.22305 5.46064 1.37788 5.67861 1.64441L7.21893 3.52655H4.79036C4.15468 3.52473 3.64104 3.00805 3.64286 2.37238V2.37055ZM7.28571 7.16941H9.71429V4.74084H7.28571V7.16941ZM11.3214 1.64441C11.7227 1.15323 12.4452 1.08098 12.9364 1.4823C13.2048 1.70209 13.3596 2.03177 13.3571 2.37905C13.3565 3.0123 12.8429 3.52595 12.2096 3.52655H9.78107L11.3214 1.64441ZM16.3929 3.52655H14.2594C14.9011 2.39363 14.5028 0.955305 13.3699 0.313555C12.3699 -0.252302 11.1077 -0.0149094 10.3815 0.875162L8.5 3.17502L6.61846 0.875162C5.80004 -0.136945 4.31557 -0.294195 3.30346 0.524233C2.40429 1.25159 2.16568 2.52295 2.73882 3.52655H0.607143C0.272 3.52655 0 3.79855 0 4.1337V7.77656C0 8.1117 0.272 8.3837 0.607143 8.3837H1.21429V16.2766C1.21429 16.6117 1.48629 16.8837 1.82143 16.8837H15.1786C15.5143 16.8837 15.7857 16.6117 15.7857 16.2766V8.3837H16.3929C16.7286 8.3837 17 8.1117 17 7.77656V4.1337C17 3.79855 16.7286 3.52655 16.3929 3.52655V3.52655Z" fill="black"/>
        </g>
    </svg>
)

export default GiftBox