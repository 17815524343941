import React from 'react';

import Icon from '../../../atoms/Icon/Icon'

// import * as styles from PARENT

const Colors = ({ name, values, setter, getter, styles }) => {
    return (
        <div className={styles.colorOptions}>
            {values && values.map((value, valueIndex) => {
                const isChecked = `${name}=${value.key}` in getter ? true : false;

                return (
                    <div key={valueIndex} className={styles.colorItem}>
                        <label>
                            <input 
                                type="checkbox"  
                                className={styles.input} 
                                data-label={value.label}
                                value={value.key} 
                                name={name} 
                                onChange={(e) => setter(e)} 
                                checked={isChecked}
                                key={valueIndex} />
                            <Icon symbol="swatch" />
                            <span>{value.label}</span>
                        </label>
                    </div>
                )
            })}
        </div>
    )
};

export default Colors;