import React from 'react';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

import FreshdeskContactUsForm from '../components/molecules/FreshdeskContactUsForm/FreshdeskContactUsForm';

import * as styles from './contact.module.css';

// to do: connect this to contentful data

const ContactUs = () => {

    return (
        <div>
            <Layout>
                <Seo title="Contact us" />
                <Container size={'large'}>

                    <div className={styles.contactUs}>
                        <h1>Say Hello.</h1>
                        <span className={styles.subtitle}>
                            Have a general comment, question and/or suggestions?
                        </span>
                        <div className={styles.formContainer}>
                            <FreshdeskContactUsForm />
                                {/* <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
                                <style type="text/css" media="screen, projection">
                                    @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
                                </style>
                                <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://contactusu.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded" scrolling="no" height="500px" width="100%" frameborder="0" >
                                </iframe> */}
                        </div>
                    </div>

                </Container>
            </Layout>
        </div>
    );
}

export default ContactUs;