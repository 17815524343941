// extracted by mini-css-extract-plugin
export var background = "BlogList-module--background--3b327";
export var blogContainer = "BlogList-module--blogContainer--3e637";
export var blogHeading = "BlogList-module--blogHeading--113e5";
export var catActive = "BlogList-module--catActive--28e16";
export var categoryLink = "BlogList-module--categoryLink--c8f6d";
export var categoryList = "BlogList-module--categoryList--8b4a4";
export var featuredPosts = "BlogList-module--featuredPosts--ecdfe";
export var featuredPostsBackground = "BlogList-module--featuredPostsBackground--4c83e";
export var latestPostContainer = "BlogList-module--latestPostContainer--20bbb";
export var postsContainer = "BlogList-module--postsContainer--201b6";
export var products = "BlogList-module--products--4ceae";
export var title = "BlogList-module--title--7fd0b";