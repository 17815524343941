import React, { useState } from 'react';
import SelectField from '../../atoms/SelectField/SelectField';
import * as styles from './SizeList.module.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
  `${process.env.GATSBY_BC_PATH}/content/size-guide.jpeg`
];

const BoxList = (props) => {
  const { label, boxList, variantSpecs, id, handleVariantChange } = props;
  const [isOpen, setIsOpen] = useState(false)
  const variant = variantSpecs?.filter((tempVariant) => tempVariant.option_id === id)[0];
  let selectedValue = '';

  if (variant) {
    selectedValue = `${variant.id}|${variant.label}`
  }

  const sizes = boxList?.map((variant) => {
    return {
      value: `${variant.id}|${variant.label}`,
      text: variant.label,
    }
  })

  return (
    <div className={styles.root}>
      <div className={styles.sizeLabelContainer}>
        <span className={styles.label}>Select {label}: <strong>{variant?.label}</strong></span>
        <span
          role='presentation'
          onKeyDown={() => setIsOpen(true)} onClick={() => setIsOpen(true)} className={`${styles.label} ${styles.guide}`}>Size Guide</span>
      </div>
      <div className={styles.sizeSelection}>
        <SelectField
          id={variant?.option_id || ''}
          value={selectedValue}
          data={sizes}
          handleChange={handleVariantChange}
        />
      </div>
      {isOpen && <Lightbox
        mainSrc={images[0]}
        onCloseRequest={() => setIsOpen(false)}
      />}
    </div>
  );
};

export default BoxList;
