import React, { useContext } from 'react';
import CartContext from '../../../context/CartProvider';
// import Icon from '../../atoms/Icon/Icon';

import Button from '../../atoms/Button/Button';
import * as styles from './AddToCartButton.module.css';

const AddToCartButton = ({
  children,
  productId,
  product = null,
  variantId,
  additionalAction,
  extraItems = [],
  className,
  fullWidth,
  quantity,
  disabled = false,
  customPrice,
}) => {
  const value = useContext(CartContext);  
  const addToCart = value && value.addToCart;
  const addAllToCart = value && value.addAllToCart;
  const addingToCart = value && value.state.addingToCart;
  const isValidGradProductToCart = value && value.isValidGradProductToCart;

  if (!productId) return null;

  const addToCartHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (extraItems.length > 0) {
      const items = extraItems.map(extraItem => ({
        product_id: extraItem.productId,
        variant_id: extraItem.variantId,
        quantity: (extraItem.matchQty && parseInt(quantity, 10) > 0) ? quantity : 1
      }));
      items.push({
        product_id: productId,
        variant_id: variantId,
        quantity: (quantity && parseInt(quantity, 10) > 0) ? quantity : 1
      });
      addAllToCart(items);
    } else {
      if (quantity && parseInt(quantity, 10) > 0) {
        addToCart(productId, variantId, null, quantity, customPrice);
      } else {
        addToCart(productId, variantId);
      }
    }
    additionalAction && additionalAction();
  }

  if (className) {
    return (
      <span
        role="presentation"
        className={className}
        onClick={() => {
          if (extraItems.length > 0) {
            const items = extraItems.map(extraItem => ({
              product_id: extraItem.productId,
              variant_id: extraItem.variantId,
              quantity: (extraItem.matchQty && parseInt(quantity, 10) > 0) ? quantity : 1
            }));
            items.push({
              product_id: productId,
              variant_id: variantId,
              quantity: (quantity && parseInt(quantity, 10) > 0) ? quantity : 1
            });
            addAllToCart(items);
          } else {
            if (quantity && parseInt(quantity, 10) > 0) {
              addToCart(productId, variantId, null, quantity);
            } else {
              addToCart(productId, variantId);
            }
          }
          additionalAction && additionalAction();
        }}
      >
        {children}
      </span>
    );
  } else {
    return (
      <div className={`${styles.root} bc-form bc-addtocart-form`}>
        <div className="bc-product-form__product-message"></div>
        <Button 
          level='primary' 
          type='buttonSubmit'
          disabled={disabled ? true : ((addingToCart === productId) ? true : (value && isValidGradProductToCart(product) ? false : true))}
          size={fullWidth && 'fullWidth'}
          onClick={(e) => addToCartHandler(e)}
          className={addingToCart === productId ? styles.addingActive : ''}
        >
          <span>
            {addingToCart === productId ? 'Adding to cart' : <>{children}</>}
          </span>
        </Button>
        {/* <Button
          level="primary"
          type="buttonSubmit"
          disabled={disabled || addingToCart === productId}
          onClick={(e) => addToCartHandler(e)}
          size={fullWidth && 'fullWidth'}
          className={addingToCart === productId ? styles.addingActive : ''}
        >
          <span className={styles.addToCartHover}>
            {addingToCart === productId ? 'Adding to bag' : 'Add to bag'}
          </span>
          <Icon symbol="cartAdd" />
        </Button> */}
      </div>
    );
  }
};

export default AddToCartButton;
