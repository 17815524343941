import React, { useContext, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import { wpApiNew, uploadMedia } from '../../../helpers/wordpress';
import { updatePerson } from '../../../helpers/ortto';
import { getBase64 } from '../../../helpers/general';
import Container from '../../atoms/Container/Container';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectField from '../../atoms/SelectField/SelectField';
import Button from '../../atoms/Button/Button';

import * as styles from './StartClubForm.module.css';

const StartClubForm = () => {
  const auth = useContext(AuthContext);
  const memberDetails = auth && get(auth, 'state');
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [metaFileName, setMetaFileName] = useState(false);

  const [values, setValues] = useState({
    title: '',
    category: '',
    content: '',
    campus: '',
    affiliations: '',
    affiliation_detail: '',
    similar_club_details: '',
    constitution_document: '',
  });

  const { allWpClubCategory } = useStaticQuery(graphql`
    query {
      allWpClubCategory {
        nodes {
          name
          id: databaseId
        }
      }
    }
  `);

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const handleUpload = e => {
    if (e === undefined) return;
    setSelectedFile(e.target.files[0]);
    setMetaFileName(e.target.files[0]?.name);
  };

  const submitForm = async () => {
    // TODO: Validate form
    // const checkForm = validateForm(values);

    // if (checkForm) {
    //     toast.error(checkForm);
    //     return;
    // }

    if (!selectedFile) {
      toast.error(
        'You must include a constitution document in your application'
      );
    } else {
      try {
        setSaving(true);

        /* TODO: Handle fields */
        const base64Doc = await getBase64(selectedFile);
        const responseDoc = await uploadMedia(
          `Club-Constitution-${values.title}.pdf`,
          base64Doc,
          selectedFile.type
        );
        const fields = {
          status: 'applied',
          title: values.title,
          content: values.content,
          categories: [values.category],
          fields: {
            full_title: values.title,
            application_fields: {
              constitution_document: responseDoc.response.id,
              campus: values.campus,
              affiliations: values.affiliations,
              affiliation_detail: values.affiliation_detail,
              similar_club_details: values.similar_club_details,
            },
            details: {
              contact: `${memberDetails.usu.FirstName} ${memberDetails.usu.LastName}`,
              email: memberDetails.email,
              mobile: memberDetails.usu.Mobile,
            },
            club_execs: [
              {
                member_number: memberDetails.usu.MemberNumber,
                position: 'Registrar',
              },
            ],
            agm_month: '',
            // new Date()
            //   .toLocaleString('en-au', { month: 'long' })
            //   .toLowerCase(),
            election_month: '',
            // new Date()
            //   .toLocaleString('en-au', { month: 'long' })
            //   .toLowerCase(),
            submitted: new Date(
              new Date().getTime() + -new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split('.')[0],
            submitted_by: `${memberDetails.usu.FirstName} ${memberDetails.usu.LastName} (${memberDetails.usu.MemberNumber})`,
            // Default values
            disable_free_memberships: false,
            abbr: '',
            social_links: {
              discord: '',
              facebook: '',
              instagram: '',
              linkedin: '',
              pinterest:'',
              tiktok: '',
              twitter: '',
              website: ''
            },
            fomo: '',
            meetups: '',
            printer_code: '',
            vendor_code: '',
            why_join: ''
          },
        };

        const response = await wpApiNew('upsertClub', {
          clubId: false,
          object: fields,
          fieldSet: 1
        });
        if (String(response.status).startsWith('2')) {
          const club = response.response;

          const clubKey = `club-${club.slug}`;
          const userKey = memberDetails.email;

          const personData = [
            {
              email: userKey,
              tags: [clubKey],
            },
          ];
          const updateResponse = await updatePerson(personData);
          if (
            String(updateResponse.status).startsWith('2') &&
            'people' in updateResponse.response
          ) {
            toast.success(
              "Your application has been submitted. We'll be in touch shortly."
            );
            navigate(`/account/my-usu/clubs`);
          } else {
            toast.error('Application assignment failed.\nPlease contact USU.');
          }
        } else {
          toast.error('Application failed to save.\nPlease try again.');
        }
      } catch (error) {
        console.log(error);
        toast.error('An unexpected error has occurred.\nPlease try again.');
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div className={styles.formRoot}>
      <Container>
        <div className={`grid grid-50 grid-responsive`}>
          <FormInputField
            id='title'
            type='input'
            labelName='Proposed club name'
            value={values['title']}
            handleChange={onHandleChange}
          />

          <SelectField
            id='category'
            placeholder={'Select...'}
            value={values['category']}
            label={'What category is your club?'}
            handleChange={onHandleChange}
            data={allWpClubCategory.nodes.map(cat => ({
              value: cat.id,
              text: cat.name,
            }))}
          />
        </div>

        <FormInputField
          id='content'
          type='textarea'
          labelName='Please provide a description about what the club is about'
          value={values['content']}
          handleChange={onHandleChange}
        />

        <div className={`grid grid-50 grid-responsive `}>
          <SelectField
            id='campus'
            label={'What campus will your club be based on?'}
            placeholder={'Select...'}
            value={values['campus']}
            handleChange={onHandleChange}
            data={[
              { value: 'main', text: 'Main campus - Camperdown' },
              { value: 'music', text: 'The Conservatorium of Music' },
              { value: 'rural', text: 'Rural Campus' },
              { value: 'other', text: 'Other Satellite Campus' },
            ]}
          />
          <SelectField
            id='affiliations'
            label={
              'Does your club have an affiliation with any external parties, sponsors, organisations, political parties etc?'
            }
            placeholder={'Select...'}
            value={values['affiliations']}
            handleChange={onHandleChange}
            data={[
              { value: 'yes', text: 'Yes' },
              { value: 'no', text: 'No' },
              { value: 'unsure', text: 'Unsure if relevant' },
            ]}
          />
        </div>

        {['yes', 'unsure'].indexOf(values['affiliations']) > -1 && (
          <FormInputField
            id='affiliation_detail'
            type='textarea'
            labelName='Please list any relevant organisations and the extent of their involvement in your club'
            value={values['affiliation_detail']}
            handleChange={onHandleChange}
          />
        )}

        <FormInputField
          id='similar_club_details'
          type='textarea'
          labelName='If your research has identified that you are similar to an existing club, please name them and tell us how you differentiate'
          value={values['similar_club_details']}
          handleChange={onHandleChange}
        />

        <div className={styles.filePickerContainer}>
          <div className={styles.fileHelperContainer}>
            <span className={styles.fileName}>
              {metaFileName ? metaFileName : 'Constitution Document'}
            </span>
            <span className={styles.fileHelper}>
              Must be PDF and cannot exceed 1MB.
            </span>
            <span className={styles.dlConstitutionLabel}>Download the Constitution Document <a target={'_blank'}
              href={
                  'https://usu.wpengine.com/wp-content/uploads/2024/09/model-constitution-1.docx'
              }
              rel='noreferrer'><u>here</u></a></span>
          </div>
          <label className={styles.filePicker} htmlFor='file'>
            <input
              id='file'
              type='file'
              name='file'
              onChange={handleUpload}></input>
            Select File
          </label>
        </div>

        <div>
          <p>
            The C&S Committee will make determinations on USU Club Applications.
            You will be notified shortly via email if your club has been
            accepted, not accepted or if further information is required.
          </p>
        </div>

        <Button
          type={'button'}
          level='primary'
          className={styles.submitButton}
          disabled={saving}
          onClick={submitForm}>
          Submit application
        </Button>
      </Container>
    </div>
  );
};

export default StartClubForm;

