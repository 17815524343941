import React from 'react'

const PinterestMin = () => (
    <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 32 32'
  >
    <path
      d="M16.75.406C10.337.406 4 4.681 4 11.6c0 4.4 2.475 6.9 3.975 6.9.619 0 .975-1.725.975-2.212 0-.581-1.481-1.819-1.481-4.238 0-5.025 3.825-8.588 8.775-8.588 4.256 0 7.406 2.419 7.406 6.863 0 3.319-1.331 9.544-5.644 9.544-1.556 0-2.888-1.125-2.888-2.737 0-2.363 1.65-4.65 1.65-7.088 0-4.137-5.869-3.387-5.869 1.613 0 1.05.131 2.212.6 3.169-.863 3.713-2.625 9.244-2.625 13.069 0 1.181.169 2.344.281 3.525.212.238.106.213.431.094 3.15-4.313 3.038-5.156 4.463-10.8.769 1.463 2.756 2.25 4.331 2.25 6.637 0 9.619-6.469 9.619-12.3 0-6.206-5.363-10.256-11.25-10.256l.001-.002Z"
      fill="currentColor"
    />
  </svg>
)

export default PinterestMin