// extracted by mini-css-extract-plugin
export var accountType = "signup-module--accountType--e02c5";
export var accountTypeOp = "signup-module--accountTypeOp--0e594";
export var formSignUp = "signup-module--formSignUp--e5936";
export var gridForm = "signup-module--gridForm--d0cf7";
export var gridPass = "signup-module--gridPass--62e93";
export var gridSignup = "signup-module--gridSignup--e6ce0";
export var isOpen = "signup-module--isOpen--3bec0";
export var notice = "signup-module--notice--dfbc8";
export var subtitle = "signup-module--subtitle--d1f2c";
export var title = "signup-module--title--8132f";
export var typeLabel = "signup-module--typeLabel--2e06a";
export var typeRadio = "signup-module--typeRadio--eaeb5";