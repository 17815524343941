// extracted by mini-css-extract-plugin
export var buttonWrapp = "ReturnsForm-module--buttonWrapp--9c5bd";
export var dialogContent = "ReturnsForm-module--dialogContent--441f8";
export var error = "ReturnsForm-module--error--7ac55";
export var errorMsg = "ReturnsForm-module--errorMsg--60bf3";
export var fieldSection = "ReturnsForm-module--fieldSection--78f1c";
export var formContainer = "ReturnsForm-module--formContainer--ae35d";
export var formInput = "ReturnsForm-module--formInput--a120a";
export var formLabel = "ReturnsForm-module--formLabel--19918";
export var formSubmit = "ReturnsForm-module--formSubmit--45f2f";
export var formTextArea = "ReturnsForm-module--formTextArea--938a1";
export var invalid = "ReturnsForm-module--invalid--df949";
export var mobileFormField = "ReturnsForm-module--mobileFormField--35108";
export var mobileGrid = "ReturnsForm-module--mobileGrid--b6956";
export var spinnerWrapper = "ReturnsForm-module--spinnerWrapper--84fbe";
export var stretchFormField = "ReturnsForm-module--stretchFormField--a7f19";
export var thankYou = "ReturnsForm-module--thankYou--508b8";