import React from 'react';
import * as styles from './MobileLayout.module.css';
const MobileLayout = props => {

  return (
    <div className={ styles.root }>
        <div className={styles.landscapeContainer}>
            <img src={`${props?.landscapePictureSrc}?w=900&h=500&fit=fill`} alt={props?.landscapePictureAlt}></img>
        </div>
        <div className={styles.bottomContainer}>
            <div className={styles.portraitContainer}>
                <img src={`${props?.portraitPictureSrc}?w=526&h=650&fit=fill`} alt={props?.portraitPictureAlt}></img>
            </div>
            <div className={styles.smallContainer}>
                <img src={`${props?.smallPictureSrc}?w=350&h=325&fit=fill`} alt={props?.smallPictureAlt}></img>
            </div>
        </div>
    </div>
  );
};

export default MobileLayout;
