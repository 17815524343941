import { Link } from 'gatsby';
import React from 'react';
import { formatEventDateTime } from '../../../helpers/general';
import { transformWpEvent } from '../../../helpers/wordpress';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './EventCard.module.css';

const EventCard = props => {

  const { eventObj, customStyle = false, aspectRatio1x1 = false, match = null, clubMediaDefaults } = props;

  if (!eventObj) return (<div>Requires Event Object</div>)

  // const fallbackImage = 'https://via.placeholder.com/600x550.png?text=Place+Event+Image+Here';
  // const src = imageSrc ? imageSrc : fallbackImage;

  const dates = () => {
      const preppedEvent = transformWpEvent(eventObj);
      const formattedDates = formatEventDateTime(preppedEvent.startDate, preppedEvent.endDate, preppedEvent.startTime, preppedEvent.endTime , true );
      return formattedDates.formatDate;
  }

  const image = (event) => {
      const preppedEvent = transformWpEvent(eventObj, { clubMediaDefaults: clubMediaDefaults });
      // console.log(`${eventObj.title} - ${preppedEvent.featuredImage ?? (preppedEvent.club?.clubFieldsSide?.logo?.localFile.childImageSharp.fixed.src || preppedEvent.club?.clubFieldsSide?.logo?.sourceUrl)}`);
      return preppedEvent.featuredImage ?? (preppedEvent.club?.clubFieldsSide?.logo?.localFile.childImageSharp.fixed.src || preppedEvent.club?.clubFieldsSide?.logo?.sourceUrl);
  }

  const eventLabel = () => {
    const preppedEvent = transformWpEvent(eventObj);
    
    if (preppedEvent.categories && preppedEvent.categories.length > 0) {
      if (preppedEvent.categories[0].slug === 'club-events') {
        return preppedEvent.club?.title || '';
      } else if (preppedEvent.categories[0].slug === 'usu-events') {
        return 'USU';
      } else if (preppedEvent.categories[0].slug === 'partner-offers') {
        return 'Partner Offers';
      }
    }

    return false
  }

  return (
    <div className={styles.EventCard}>
      <Link to={eventObj.uri} className={styles.link}>&nbsp;</Link>
      {/* <img alt={imageAlt} src={src} className={styles.image}></img> */}
      {aspectRatio1x1 && <div className={styles.imageContainer}>
        <div className={styles.image1x1} style={{backgroundImage: `url(${image(eventObj)})`}}></div>
      </div>}

      {aspectRatio1x1 || <div className={ styles.image} style={{backgroundImage: `url(${image(eventObj)})`}}></div>}
      <div className={styles.detailContainer}>
          {eventLabel && <span className={styles.eventSublabel}>{eventLabel()}</span>}
          <h3 className={customStyle ? styles.titleCustom : styles.name}>{eventObj.title}</h3>
          <div className={styles.dateContainer}>
              <Icon symbol={'arrowDownAlt'}></Icon>
              <span className={customStyle ? styles.dateCustom : styles.date}>{dates()}</span>
          </div>
          {match && (
            <span className={styles.match}>{match}</span>
          )}
      </div>
    </div>
  );
};

export default EventCard;
