// extracted by mini-css-extract-plugin
export var button = "styleguide-module--button--3c185";
export var buttonGroup = "styleguide-module--buttonGroup--8a506";
export var color = "styleguide-module--color--79b86";
export var colorBox = "styleguide-module--colorBox--c09a9";
export var colorGroup = "styleguide-module--colorGroup--69091";
export var colorRow = "styleguide-module--colorRow--8a4d5";
export var fontWeight100 = "styleguide-module--fontWeight100--dc8a5";
export var fontWeight200 = "styleguide-module--fontWeight200--19c50";
export var fontWeight300 = "styleguide-module--fontWeight300--46f94";
export var fontWeight400 = "styleguide-module--fontWeight400--398b9";
export var fontWeight500 = "styleguide-module--fontWeight500--40458";
export var fontWeight600 = "styleguide-module--fontWeight600--3cc2c";
export var fontWeight700 = "styleguide-module--fontWeight700--5e6f4";
export var fontWeight800 = "styleguide-module--fontWeight800--fdbc7";
export var fontWeight900 = "styleguide-module--fontWeight900--978d6";
export var fontWeight950 = "styleguide-module--fontWeight950--765e7";
export var fontWeights = "styleguide-module--fontWeights--9a1ad";
export var styleExample = "styleguide-module--styleExample--649ee";