import React from 'react'

const PaypalLogo = () => (
    <svg viewBox="0 0 43 30" width={40} height={28} xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M3.467 29.129A3.435 3.435 0 010 25.662V3.467A3.435 3.435 0 013.467 0h36.066A3.435 3.435 0 0143 3.467v22.195a3.435 3.435 0 01-3.467 3.467H3.467z"
          fill="#F2F2F2"
        />
        <path
          d="M38.969 1.344a2.695 2.695 0 012.687 2.687v21.5a2.695 2.695 0 01-2.687 2.688H4.03a2.695 2.695 0 01-2.687-2.688v-21.5A2.695 2.695 0 014.03 1.344H38.97zm0-1.344H4.03C1.881 0 .001 1.747.001 4.031v21.5c0 2.285 1.746 4.031 4.03 4.031H38.97c2.284 0 4.03-1.746 4.03-4.03v-21.5C43 1.746 41.254 0 38.97 0z"
          fill="#D8D8D8"
        />
        <path
          d="M17.927 25.042l.379-2.482-.844-.02h-4.024l2.797-18.307a.245.245 0 01.078-.144.224.224 0 01.148-.056h6.789c2.252 0 3.806.483 4.62 1.439.38.449.621.916.738 1.432.124.54.127 1.187.006 1.976l-.008.056v.505l.38.222c.321.176.575.376.77.607.326.383.537.87.626 1.448.091.594.06 1.3-.09 2.1-.173.92-.454 1.721-.833 2.378a4.837 4.837 0 01-1.32 1.492c-.503.37-1.103.65-1.779.829a8.605 8.605 0 01-2.222.266h-.528c-.38.001-.748.14-1.034.391-.289.258-.48.61-.537.993l-.04.223-.668 4.373-.031.16c-.008.05-.022.076-.042.095a.11.11 0 01-.07.025h-3.261z"
          fill="#253B80"
        />
        <path
          d="M28.324 9.406c-.02.135-.043.27-.069.412-.895 4.743-3.957 6.382-7.869 6.382h-1.99a.977.977 0 00-.957.844l-1.018 6.675-.29 1.892a.521.521 0 00.503.608h3.532a.859.859 0 00.84-.739l.034-.185.664-4.357.043-.239a.859.859 0 01.84-.74h.528c3.421 0 6.1-1.434 6.883-5.585.328-1.733.158-3.18-.707-4.198a3.393 3.393 0 00-.967-.77z"
          fill="#179BD7"
        />
        <path
          d="M27.416 8.394a6.88 6.88 0 00-.869-.2 10.72 10.72 0 00-1.756-.131h-5.32a.822.822 0 00-.367.086.873.873 0 00-.47.654L17.5 16.202l-.032.216a.977.977 0 01.955-.847h1.992c3.91 0 6.974-1.639 7.867-6.381.027-.14.05-.277.07-.41a4.691 4.691 0 00-.937-.386z"
          fill="#222D65"
        />
        <path
          d="M19.05 9.783a.87.87 0 01.47-.656.825.825 0 01.369-.085h5.32c.63 0 1.217.042 1.755.132a6.88 6.88 0 011.07.263c.265.09.511.198.737.321.266-1.753-.003-2.946-.92-4.027-1.012-1.19-2.84-1.7-5.178-1.7h-6.786a.98.98 0 00-.96.847l-2.826 18.497a.597.597 0 00.575.696h4.19l1.053-6.891L19.05 9.78v.002z"
          fill="#253B80"
        />
      </g>
    </svg>
)

export default PaypalLogo