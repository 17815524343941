import React from "react"

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"

const Loyalty = () => {
    return (
        <>
            Body
        </>
    )
}

const LoyaltyOutput = () => (
    <AccountPageWrapper metaTitle="Account - Loyalty" title="Loyalty">
        <Loyalty />
    </AccountPageWrapper>
)

export default LoyaltyOutput