import React from 'react';
import { get } from 'lodash';
import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulCtaBanner.module.css';

const ContentfulCtaBanner = props => {

  const {backgroundColor, content, image, ctaLink, size} = props;

  const bgColor = backgroundColor?.hexCode ? backgroundColor?.hexCode : 'var(--standard-white)'; 
  const backgroundImage = get(image, 'image.file.url', false) ? `url(https:${get(image, 'image.file.url')}?w=1100&h=550&fit=fill)` : false;

  return (
    <Container size={size ? size.toLowerCase(): ''}>
      <div className={ styles.ContentfulCtaBanner }>
          <div className={styles.contentContainer} style={{backgroundColor: bgColor}}>
            <ContentfulRichText raw={content.raw} />
            <Button size={'smallest'} href={ctaLink?.url} level={'primary'}>{ctaLink?.label}</Button>
          </div>
          {backgroundImage && (
            <div className={styles.bannerImage} style={{backgroundImage: backgroundImage}} />
          )}
      </div>
    </Container>
  );
};

export default ContentfulCtaBanner;
