import React, { useEffect, useState } from 'react';
import { navigate, useStaticQuery, graphql } from "gatsby";
import { toast } from 'react-toastify';
import { createNewAcquittal, wpAllNew } from '../../../helpers/wordpress';
import Container from '../../atoms/Container/Container';
import FormInputField from '../../atoms/FormInputField/FormInputField';

import * as styles from './AcquittalSubmissionForm.module.css';
import { extractDate } from '../../../helpers/general';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';
import Button from '../../atoms/Button/Button';
import ReceiptsListDialog from '../ReceiptsListDialog/ReceiptsListDialog';

const initState = {
  summary: '',
  explanation: '',
  club: '',
  status: 'pending',
  grants: [],
  receipts: [],
  no_amount_spent: false,
  calculated_received: 0,
  calculated_spent: 0,
  submitted: `${new Date().getFullYear()}-${String(Number(new Date().getMonth()) + 1).padStart(2, '0')}-${new Date().getDate()}`,
  submitted_by: '',
};

const AcquittalSubmissionForm = props => {
  const { club, member, location } = props;
  const [values, setValues] = useState(initState);
  const [grants, setGrants] = useState(null);
  const [receipts, setReceipts] = useState(null);
  const [valueProvided, setValueProvided] = useState(0);
  const [valueSpent, setValueSpent] = useState(0);
  const [isSaving, setIsSaving] = useState(false)

  const [isReceiptListDialog, setIsReceiptListDialog] = useState(false);

  const {
      allWpGrantType: { nodes: grantTypes },
  } = useStaticQuery(graphql`
      query {
        allWpGrantType {
          nodes {
            name
            id: databaseId
          }
        }
      }
  `);

  // console.log(club, member);

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { response: grantsList } = await wpAllNew(`getClubGrantsList`, {
        clubId: location.state.clubId,
        type: 'paid',
        fieldSet: 0
      });
      // console.log(grantTypes, grantsList);
      if (grantsList) {
        const _receipts = [];
        let provided = 0;
        let spent = 0;
        const _grants = grantsList.map(grant => ({
            id: grant.id,
            // name: decodeURI(grant.title.rendered),
            type: grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]))?.name,
            status: grant.acf.status
                .substr(
                grant.acf.status.lastIndexOf(':') + 1,
                grant.acf.status.length
                )
                .trim(),
            acquittal: grant.acf.acquittal,
            // valueGranted: grant.acf.value_granted,
            valueProvided: grant.acf.value_provided,
            // submittedBy: grant.acf.submitted_by,
            submittedDate: grant.date,
            // reviewedBy: grant.acf.reviewed_by,
            // staffNotes: grant.acf.staff_notes,
            // reason: grant.acf.reason,
            // approvedBy: grant.acf.approved_by,
        })).filter(g => g.status === 'Paid' && !g.acquittal);
        
        if (_grants.length > 0) {
          const promises = _grants.map(async grant => {
            /* Fetch all receipts for grant */
            const { response: receiptsList } = await wpAllNew('getClubReceiptsList', {
              clubId: club.id,
              grantId: grant.id,
              fieldSet: 1
            });
            // console.log('Receipts', receiptsList);
            _receipts.push(...receiptsList.map(receipt => {
              spent += Number(receipt.acf.value);
              return {
                id: receipt.id,
                receipt: receipt.acf.receipt,
                value: receipt.acf.value,
                description: receipt.acf.description,
                date: receipt.acf.date_of_purchase,
                submittedBy: receipt.acf.submitted_by,
                submittedDate: receipt.acf.submitted,
              };
            }))

            provided += Number(grant.valueProvided);

            return true;
          });

          Promise.all(promises).then(() => {
            setGrants(_grants);
            setReceipts(_receipts);
            setValueProvided(provided);
            setValueSpent(spent);

            const _values = { ...values };
            _values.club = club.id;
            _values.grants = _grants.map(g => ({grant: g.id}));
            _values.receipts = _receipts.map(r => ({receipt: r.id}));
            _values.calculated_received = provided;
            _values.calculated_spent = spent;
            _values.submitted_by = `${member.FirstName} ${member.LastName} (${member.MemberNumber})`

            setValues(_values);
          })
        } else {
          setGrants(false);
          setReceipts(false);
        }
      } else {
        setGrants(false);
        setReceipts(false);
      }
    }

    if (grants === null || receipts === null) {
      // Fetch open grants
      fetchData();
    }
  }, [grants, receipts, club, member, values, grantTypes, location]);

  const handleSubmit = async () => {
    setIsSaving(true);
    
    try {
      const newRequestData = {
        club: {
          clubId: club.id,
          clubTitle: club.acf.full_title,
          clubStatus: club.acf.status.label,
        },
        fields: values
      };

      // const { response: newAcquittal } = 
      await createNewAcquittal(
        newRequestData
      );

      // console.log(newAcquittal);

      toast.success("Acquittal successfully submitted!");

      navigate(`/account/my-usu/clubs/manage/finances/acquittals`,
        {
          state: {
            ...location.state,
          },
        }
      );
    } catch (e) {
      console.error(e);
      toast.success("An unexpected error occurred. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Container>
      <div className={styles.root}>
        {grants === false && (
          <>You have no open grants to submit an acquittal for.</>
        )}

        {grants === null && (
          <>Fetching data...</>
        )}

        {grants && (
          <>
            <section>
              <div className={`grid grid-50 grid-responsive `} style={{alignItems: 'flex-start', marginBottom: '40px'}}>
                <div>
                  <p>Included grants:</p>
                  {grants && grants.map((grant, gI) => (
                    <div key={gI}>
                      {grant.type} - {extractDate(grant.submittedDate)} (<CurrencyFormatter amount={grant.valueProvided} />)
                    </div>
                  ))}

                  <span role="presentation" onClick={() => setIsReceiptListDialog(!false)}>Total receipts: {receipts && receipts.length}</span>
                  <ReceiptsListDialog open={isReceiptListDialog} onClose={() => setIsReceiptListDialog(false)} receipts={receipts}></ReceiptsListDialog>
                </div>
                <div>
                  <p style={{display: "flex", justifyContent: 'space-between'}}><span>Total provided: </span><span><CurrencyFormatter amount={valueProvided} /></span></p>
                  <p style={{display: "flex", justifyContent: 'space-between'}}><span>Total spent: </span><span><CurrencyFormatter amount={valueSpent} /></span></p>
                  <hr />
                  <p style={{display: "flex", justifyContent: 'space-between'}}><span>Total remaining: </span><span><CurrencyFormatter amount={valueProvided - valueSpent} /></span></p>
                </div>
              </div>

              <MessageAlert type="warn">Do the above values look correct? If not, please cancel this submission and update the grants accordingly, then return here.</MessageAlert>
            </section>
            <section>
                {/* TODO: Handle "no amount spent" option */}
                <FormInputField
                  id='summary'
                  type='textarea'
                  labelName="Please provide a brief summary on your club's activities this semester and how the grant was spent"
                  placeholder='Your brief summary...'
                  value={values['summary']}
                  handleChange={onHandleChange}
                />
                <FormInputField
                  id='explanation'
                  type='textarea'
                  labelName="If your expenses were significantly different to what you planned when you applied at the start of semester, please explain what happened"
                  placeholder='Your explanation...'
                  value={values['explanation']}
                  handleChange={onHandleChange}
                />
                {/* <div className={`grid grid-50 grid-responsive `}>
                  <div>

                  </div>
                </div> */}
                <Button type="button" level="primary" disabled={isSaving} onClick={() => handleSubmit()}>Submit</Button>
            </section>
          </>
        )}
      </div>
    </Container>
  );
};

export default AcquittalSubmissionForm;
