import React, { useMemo, useState } from 'react';
// import { has } from 'lodash';
// import { navigate } from 'gatsby';
import { dataLayerPush, dissectTitle } from '../../../helpers/general';
// import Slider from '../../atoms/Slider/Slider';
// import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Icon from '../../atoms/Icon/Icon';

import Price from '../../molecules/Price/Price';
import ProductQuickView from '../ProductQuickView/ProductQuickView';
// import ProductFlags from '../ProductFlags/ProductFlags';
// import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';

import * as styles from './ProductCard.module.css';

/**
 * Build an array of product images for the product card
 * BC API generated(url_thumbnail) or BC GraphQL(edges) or Empty image(https://via.placeholder.com/300x300?text=Not+Found')
 * @param {*} product
 * @returns array images
 * @todo maybe needs image resize function(320X320) based on original image url
 */

//  const getImages = product => {
//     if (product && product.defaultImage && product.defaultImage.url)
//       return [product.defaultImage.url];
//     if (!product || !product.images) return [];

//     return product.images && has(product.images, 'edges')
//       ? product.images.edges.map(image => image.node.url)
//       : has(product.images[0], 'url_standard')
//         ? product.images.map(image => image.url_standard)
//         : ['https://via.placeholder.com/260x260?text=Not+Found'];
// };

const getImages = product => {
  let images = 'https://via.placeholder.com/510x510/FFFFFF/000000?text=USU+Product';
  if (product?.images && product.images.length > 0) {
    const defaultImage = product.images.find(image => image.is_thumbnail);
    const generatedImage = product?.generatedImages?.image500;
    if (defaultImage) images = defaultImage.url_standard;
    if (generatedImage) images = generatedImage;
  }
  return images;};

const ProductCardTemplate = ({ data, removeFunction, overflowScroll = false, inventories = [], basicCardView = false, options = {}, currentCategory }) => {
    const [isQuickViewOpen, setIsQuickviewOpen] = useState(false);
    const images = useMemo(() => getImages(data), [data]);

    if (!data) return null;
    // console.log(data);

    const getVariantValue = key => {
        if (key === 'variantId') {
          if ('variants' in data) {
            // BC API generated
            if (Array.isArray(data.variants) && 'entityId' in data.variants[0]) {
              return data.variants[0].entityId;
            }
            // BC API generated
            if (Array.isArray(data.variants) && 'id' in data.variants[0]) {
              return data.variants[0].id;
            }
            // BC GraphQL
            if (
              typeof data.variants === 'object' &&
              'edges' in data.variants &&
              data.variants.edges.length > 0 &&
              'entityId' in data.variants.edges[0].node
            ) {
              return data.variants.edges[0].node.entityId;
            }
          }
          return null;
        }
        if (key === 'product_id' && 'entityId' in data) {
          return data.entityId;
        }
        if (key === 'product_id' && 'product_id' in data) {
          return data.product_id;
        }
        return null;
    };

      const getLink = () => {
        const prepend = '/product';
        const append = '';

        // BC API generated
        if ('custom_url' in data)
          return `${prepend}${data.custom_url.url}${append}`;
        // BC GraphQL
        else if ('path' in data) return `${prepend}${data.path}${append}`;
        // Not found
        else return '#';
      };

      const getPrices = () => {
        if ('prices' in data) {
          return {
            price: data.prices.price ? data.prices.price.value : null,
            calculated_price: data.prices.calculatedPrice
              ? data.prices.calculatedPrice.value
              : null,
            sale_price: data.prices.salePrice ? data.prices.salePrice.value : null,
            retail_price: data.prices.retailPrice
              ? data.prices.retailPrice.value
              : null
          };
        } else if (typeof data.price === 'object' && data.price !== null) {
          return {
            price: data.price && data.price[Object.keys(data.price)[0]]
              ? data.price[Object.keys(data.price)[0]].value
              : null,
            calculated_price: data.calculated_price && data.calculated_price[
              Object.keys(data.calculated_price)[0]
            ]
              ? data.calculated_price[Object.keys(data.calculated_price)[0]].value
              : null,
            sale_price: data.sale_price && data.sale_price[Object.keys(data.sale_price)[0]]
              ? data.sale_price[Object.keys(data.sale_price)[0]].value
              : null
          };
        } else {
          let price = data.price;
          if (!price && data.default_price) {
            price = data.default_price;
          } else if (!price && data.variant_data) {
            const variants = JSON.parse(data.variant_data);
            price = variants[0].price;
          }
          return {
            price,
            calculated_price: data.calculated_price,
            sale_price: data.sale_price
          };
        }
      };

      const onClick = (e, linkTo) => {
        e.preventDefault();
        e.stopPropagation();
        // navigate(linkTo);
        dataLayerPush('productClick', null, data, linkTo, currentCategory);
      };

      // const toggleQuickView = e => {
      //   e.preventDefault();
      //   e.stopPropagation();

      //   setIsQuickviewOpen(!isQuickViewOpen);
      // };

      const onChangeQuickView = () => {
        setIsQuickviewOpen(false);
      };

      // let inventory = inventories.filter(a => a.entityId === data.entityId);
      // inventory = inventory.length ? inventory[0] : false;

      const productId = getVariantValue('product_id');
      const variantId = getVariantValue('variantId');
      const [productName] = dissectTitle(data.name);

      return (
        <>
          <ProductQuickView
            open={isQuickViewOpen}
            data={data}
            onTouchEnd={onChangeQuickView}
            productId={productId}
            variantId={variantId}
          />
          <div
            data-productcardroot
            style={ options.cardStyling !== undefined ? options.cardStyling : {}}
            className={`${styles.productCard} ${basicCardView ? styles.basicView : styles.fullView} ${'fullImage' in options ? styles.fullImage : ''} ${'cardLoading' in options && options.cardLoading ? styles.loading : ''}`}
            role="button"
            tabIndex="0"
          >
            {/* <div className={styles.productCardHead}>
              <span
                className={styles.viewButton}
                onClick={toggleQuickView}
                role="presentation"
              >
                <Icon symbol="quickView" />
              </span>
              <WishlistButton productId={productId} variantId={variantId} />
            </div> */}
            <div className={styles.productMainCardBody}>
              {/* Seo and crawler support */}
              <a href={getLink()} onClick={(e) => {
                  onClick(e, getLink())
                }}>&nbsp;</a>
              <div
                className={`${styles.imageContainer} ${overflowScroll && styles.overflowScroll}`}
                onClick={(e) => {
                  onClick(e, getLink())
                }}
                onKeyPress={(e) => {
                  onClick(e, getLink())
                }}
                role="presentation"
              >
                <img
                  loading="lazy"
                  src={images}
                  alt={data.name}
                  className={`${styles.productImage}`}
                />

                {/* removed image slider - restore this if needed */}
                {/* <div className={styles.imageSlider}>
                  <Slider settings={{
                    dots: false,
                    customArrows: true,
                    autoplay: false,
                    slidesToShow: 1
                  }}>
                    {images.map((image, index) => (
                      <div className={styles.productSliderImageWrapp} key={index}>
                        <img
                          loading="lazy"
                          src={image}
                          alt={data.name}
                          className={styles.productSliderImage}
                        />
                      </div>
                    ))}
                  </Slider>
                </div> */}

                {/* <ProductFlags data={data} inventory={inventory} /> */}
              </div>

              <div className={`${styles.textBox}`}>
                <div
                  className={styles.productDetails}
                  onClick={(e) => {
                    onClick(e, getLink())
                  }}
                  onKeyPress={(e) => {
                    onClick(e, getLink())
                  }}
                  role="presentation"
                >
                  <h5 className={styles.productName}>{productName}</h5>
                  {/* <h5 className={styles.productSku}>
                    {data.brand_name || (has(data.brand, 'name') && data.brand.name)}
                  </h5> */}
                  {options.displayPrice === false ||
                  <Price
                    entityId={data.entityId}
                    {...getPrices()}
                    styles={styles}
                    options={{ hideDiscountPrice: true }}
                  />}
                </div>

                {/* <div className={styles.addCartButtonWrapp}>
                  {(inventory === false || (inventory && (inventory.isInStock || inventory.availability === 'Preorder'))) && (
                   <AddToCartButton
                    productId={productId}
                    variantId={variantId}
                    fullWidth={true}
                  >
                    <Icon symbol="whiteBag" />
                  </AddToCartButton>
                 )}

                 {inventory && !inventory.isInStock && inventory.availability !== 'Preorder' && (<button className={styles.btnOutOfStock}>
                   Out of Stock
                 </button>)}
                </div> */}
              </div>
            </div>
            {removeFunction && (
              <span
                role="presentation"
                className={styles.removeIcon}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeFunction(
                    getVariantValue('product_id'),
                    getVariantValue('variantId')
                  );
                }}
              >
                <Icon symbol="close" />
              </span>
            )}
          </div>
        </>
    );
}

export default ProductCardTemplate;
