import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash";
import AuthContext from "../context/AuthProvider";

const AppConnect = () => {
    const auth = useContext(AuthContext);
    const [error, setError] = useState(false);

    useEffect(() => {
        const authFromApp = async () => {
            if (auth && get(auth, 'state.userChecked', false) && get(auth, 'state.isLoggedIn', false) && get(auth, 'state.usu', false) && typeof window !== 'undefined') {
                /* User already authenticated - send them to the destination */
                const params = new URL(window.location).searchParams;
                const path = params.get("load");
                window.location = path;
            } else if (auth && get(auth, 'state.userChecked', false) && !get(auth, 'state.isLoggedIn', false) && typeof window !== 'undefined') {
                const params = new URL(window.location).searchParams;
                const token = params.get("token");
                try {
                    const authed = await auth.appAuth(token);
                    if (String(authed.status).startsWith('2') && authed.response.token) {
                        const path = params.get("load");
                        window.location = path;
                    }
                } catch (e) {
                    // console.log(e);
                    setError(e.response.message);
                }
            }
        }

        authFromApp();
    }, [auth]);
    
    return (
        <>
        {error && (
            <>{error}</>
        )}
        </>
    )
}

export default AppConnect