// extracted by mini-css-extract-plugin
export var addToCartButton = "ProductQuickView-module--addToCartButton--d7c8f";
export var closeIcon = "ProductQuickView-module--closeIcon--674aa";
export var colDetails = "ProductQuickView-module--colDetails--b83c4";
export var colImages = "ProductQuickView-module--colImages--5f5c6";
export var colThumbnails = "ProductQuickView-module--colThumbnails--3787b";
export var container = "ProductQuickView-module--container--4a5c9";
export var drawerPaper = "ProductQuickView-module--drawerPaper--47a60";
export var drawerWrapp = "ProductQuickView-module--drawerWrapp--b42ba";
export var head = "ProductQuickView-module--head--93e0e";
export var layout = "ProductQuickView-module--layout--e0558";
export var mainSliderImage = "ProductQuickView-module--mainSliderImage--982ba";
export var mainSliderImageWrapp = "ProductQuickView-module--mainSliderImageWrapp--b05b9";
export var middleSide = "ProductQuickView-module--middleSide--da3e1";
export var mobileSlider = "ProductQuickView-module--mobileSlider--1c614";
export var priceDetails = "ProductQuickView-module--priceDetails--7d434";
export var productCategories = "ProductQuickView-module--productCategories--1d6cc";
export var productDetails = "ProductQuickView-module--productDetails--bc929";
export var productDetailsWrapp = "ProductQuickView-module--productDetailsWrapp--82ebc";
export var productImageThumb = "ProductQuickView-module--productImageThumb--a82d6";
export var productMessage = "ProductQuickView-module--productMessage--54323";
export var productPage = "ProductQuickView-module--productPage--e4bf3";
export var productPrice = "ProductQuickView-module--productPrice--d9856";
export var productQtyFav = "ProductQuickView-module--productQtyFav--223e8";
export var productTop = "ProductQuickView-module--productTop--8b0e9";
export var productTopCol = "ProductQuickView-module--productTopCol--fdd81";
export var productTopEntry = "ProductQuickView-module--productTopEntry--d0e80";
export var productWishlistWrapp = "ProductQuickView-module--productWishlistWrapp--5089a";
export var qtyMinus = "ProductQuickView-module--qtyMinus--e9fad";
export var qtyPlus = "ProductQuickView-module--qtyPlus--302d4";
export var rightSide = "ProductQuickView-module--rightSide--0aea4";
export var slickThumb = "ProductQuickView-module--slickThumb--848ad";