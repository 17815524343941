import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
// import parse from 'html-react-parser';
import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '../../../../../components/atoms/Dialog/Dialog';
import SelectField from '../../../../../components/atoms/SelectField/SelectField';
// import Icon from '../../../../../components/atoms/Icon/Icon';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../../../components/organisms/DataTable/DataTable';
import { getPerson, updatePerson, getTags } from '../../../../../helpers/ortto';
import { getProfile } from '../../../../../helpers/usuapi';
import { authClubExec } from '../../../../../helpers/wordpress';
import { extractDate, persistLocation } from '../../../../../helpers/general';
import * as styles from '../../usu.module.css';

const Members = ({ location }) => {
  const [isAuthed, setAuthed] = useState(null);
  const [club, setClub] = useState();
  const [membershipLevels, setMembershipLevels] = useState([]);
  const [memberList, setMemberList] = useState(null);
  const [exportMemberList, setExportMemberList] = useState(null);
  const [openMembershipSelect, toggleMembershipSelect] = useState(false);
  const [openConfirmUpdate, toggleConfirmUpdate] = useState(false);
  const [membersToUpdate, setMembersToUpdate] = useState([]);
  const [updateLevel, setUpdateLevel] = useState(null);

  const fetchClubMembers = useCallback(async () => {
    const tags = await getTags(`club-${location.state.clubSlug}`);
    if ((String(tags.status).startsWith('2') && Array.isArray(tags.response))) {
      const tagId = tags.response.find(tag => `club-${location.state.clubSlug}` === tag.name);
      const orttoMemberList = await getPerson(
        ['first', 'last', 'email', 'Tags', 'MemberNumber'],
        tagId.id,
        'tag_id'
      );

      if (
        orttoMemberList.status === 200 &&
        'contacts' in orttoMemberList.response &&
        Array.isArray(orttoMemberList.response.contacts)
      ) {
        setClub(location.state.clubId)
        const _memberList = 
          orttoMemberList.response.contacts.map(async member => {
            // console.log(member);
            let name = `${member.fields['str::first'] || ''} ${member.fields['str::last'] || ''}`.trim();
            const level =
              member.fields.tags
                .find(
                  t =>
                    t.indexOf(`clubLevel-${location.state.clubSlug}-`) ===
                    0
                )
                ?.replace(`clubLevel-${location.state.clubSlug}-`, '') ||
              'Standard';
            const dateJoined = 
              member.fields.tags
                .find(
                  t =>
                    t.indexOf(`clubJoined-${location.state.clubSlug}-`) ===
                    0
                )
                ?.replace(`clubJoined-${location.state.clubSlug}-`, '') ||
              '';
            // console.log('------');
            // console.log("Name: ", name);
            // console.log("Number: ", member.fields['str:cm:membernumber']);
            if (name === '' && member.fields['str:cm:membernumber'] && member.fields['str:cm:membernumber'] !== '') {
              // console.log('------');
              // console.log("Have number, missing name", member);
              const usuProfile = await getProfile(member.fields['str:cm:membernumber']);
              // console.log(usuProfile);
              if (String(usuProfile.status).startsWith('2') && usuProfile.response.length > 0) {
                const personData = [{
                  email: member.fields['str::email'],
                  first: usuProfile.response[0].FirstName,
                  last: usuProfile.response[0].LastName
                }];
                name = `${personData.first || ''} ${personData.last || ''}`.trim();
                await updatePerson(personData); // const updateResult = 
                // console.log(updateResult);
              }
            } else if (name !== '' && (!member.fields['str:cm:membernumber'] || member.fields['str:cm:membernumber'] === '')) {
              // console.log('------');
              // console.log("Have name, missing number", member);
            } else if (name === '' && (!member.fields['str:cm:membernumber'] || member.fields['str:cm:membernumber'] === '')) {
              // console.log('------');
              // console.log("Missing name & missing number", member);
            }

            return {
              id: member.id,
              member_number: member.fields['str:cm:membernumber'],
              member_name: name || '(No name set)',
              email: member.fields['str::email'],
              level: level,
              date_joined: dateJoined !== '' ? parseInt(dateJoined, 10) : dateJoined
            };
          })
        
        Promise.all(_memberList).then(list => {
          setMemberList(list);
          const _list = [ ...list ];
          const exportList = _list.map(m => {
            const newM = { ...m };
            if (Number.isInteger(newM.date_joined)) {
              newM.date_joined = extractDate(newM.date_joined, true);
            }

            return newM;
          });

          setExportMemberList(exportList);
        });
      }
    }
  }, [location]);

  useMemo(() => {
    if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
      setMemberList(null)
      authClubExec(get(location, 'state.clubId')).then(authed => {
        if (String(authed.status).startsWith('2') && authed.response) {
          // setClubData(authed.response);
          setAuthed(true);
          // Define membership levels
          const _levels = [];
          const fees = authed.response.acf.fees;
          const freeOption = authed.response.acf.disable_free_memberships;
          if (!freeOption) _levels.push('Standard');
          if (fees) fees?.map(f => _levels.push(f.label));
          setMembershipLevels(_levels);
          fetchClubMembers();
        } else {
          setAuthed(false);
        }
      });
    }
  }, [isAuthed, location, fetchClubMembers, club]);

  const handleBulkActions = (action, selected) => {
    switch (action) {
      case 'membershipUpdate':
        // console.log(selected);
        setMembersToUpdate(selected);
        toggleMembershipSelect(true);
        break;

      default:
        // nothing
        break;
    }
  };

  const defineNewLevel = (f, level) => {
    setUpdateLevel(level);
  };

  const prepMembershipChange = (email, oldLevel, newLevel) => {
    const newTag = `clubLevel-${location.state.clubSlug}-${newLevel}`;
    const oldTag = `clubLevel-${location.state.clubSlug}-${oldLevel}`;
    const personData = {
      email,
      tags: [newTag],
      unset_tags: [oldTag],
    };
    return personData;
  };

  const updateMembership = async () => {
    // console.log(membersToUpdate);
    const personData = membersToUpdate.map(s =>
      prepMembershipChange(s.email, s.level, updateLevel)
    );
    // console.log(personData);
    await updatePerson(personData);
    // console.log(updateResponse);
    toggleConfirmUpdate(false);
    setUpdateLevel(null);
    setMembersToUpdate([]);
    setMemberList(null);
    fetchClubMembers();
  };

  return (
    <>
      {memberList ? (
        <div className={`${styles.root} ${styles.paddingHeader}`}>
          {/* Club Edit Details */}
          <DataTable
            tableData={memberList}
            allowExport
            exportData={exportMemberList}
            showSearch={true}
            fileName={`${location?.state?.clubSlug}-members.csv`}
            headingKeys={[
              { label: 'Member Name', data_key: 'member_name', sortable: true },
              { label: 'Email', data_key: 'email', sortable: true },
              {
                label: 'Membership Level',
                data_key: 'level',
                filterable: true,
              },
              {
                label: 'Joined',
                data_key: 'date_joined',
                sortable: true,
                format: 'dateTime'
              },
            ]}
            rowActions={[
              {
                label: 'Update membership level',
                cta: data => {
                  setMembersToUpdate([data]);
                  toggleMembershipSelect(true);
                },
              }
            ]}
            bulkAction={true}
            bulkActions={[
              { label: 'Update membership', key: 'membershipUpdate' },
            ]}
            handleBulkAction={handleBulkActions}
          />
          <Dialog
            size='xs'
            open={openMembershipSelect}
            onOk={() => {
              toggleMembershipSelect(false);
              toggleConfirmUpdate(true);
            }}
            okBtnText='Select'
            hideBtnCancel={true}>
            <h6 className='mb-5'>Select membership to update to:</h6>
            <SelectField
              data={membershipLevels.map(l => ({ value: l, text: l }))}
              placeholder='Select membership'
              handleChange={defineNewLevel}
            />
          </Dialog>
          <Dialog
            size='xs'
            open={openConfirmUpdate}
            okBtnText='Confirm'
            onOk={() => updateMembership()}>
            Are you sure you want to update {membersToUpdate.length} member
            {membersToUpdate.length > 1 ? 's' : ''} to {updateLevel} membership
            level?
          </Dialog>
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching club members */}
          {((isAuthed === null && memberList === null) ||
            (isAuthed === true && memberList === null)) && (
            <div>Fetching your club members</div>
          )}
          {/* No clubs found */}
          {isAuthed === true && memberList === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
          {/* No clubs found */}
          {isAuthed === false && (
            <div>
              You are not authorised to manage this club. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementMembers = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  return (
    <AccountPageWrapper
      bgRaw={true}
      metaTitle='Account - Club Management'
      breadcrumbTitle='Members'
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      title={'Club Members list'}
      location={persistedLocation}>
      <Members location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementMembers;

