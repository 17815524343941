import React from 'react'

const Logout = () => (
    <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 15 15'
  >
    <path d="m13.5 7.5-3 3.25m3-3.25-3-3m3 3H4m4 6H1.5v-12H8" stroke="currentColor" />
  </svg>
)

export default Logout