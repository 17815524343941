import React from 'react';
import parse from 'html-react-parser'

import Icon from '../Icon/Icon'

import * as styles from './Checkbox.module.css'

// import * as styles from PARENT

const Checkbox = ({ value, label, id, name, action, isChecked , rounded = true, inverted = false }) => {
    return (
        <div className={styles.checkboxWrapper}>
            <div className={styles.inputWrapper}>
                <input type="checkbox" className={styles.input} id={id} value={value} name={name} data-label={label} onClick={(e) => e.stopPropagation()} onChange={(e) => action(e)} checked={isChecked} />
                <span className={[styles.box, rounded && styles.circle && (inverted && styles.inverted)].join(' ')} role="presentation"><Icon symbol="tick" /></span>
            </div>
            <label className={`${styles.label} ${isChecked ? `active` : ''}`} forhtml={id}>{label && parse(label)}</label>
        </div>
    )
};

export default Checkbox;