import React from 'react';
import Layout from '../../components/organisms/Layout/Layout';
import Container from '../../components/atoms/Container/Container'
import Breadcrumbs from '../../components/atoms/Breadcrumbs/Breadcrumbs'
import PageTitle from '../../components/atoms/PageTitle/PageTitle';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import GetCertificateCodeForm from '../../components/molecules/GetCertificateCodeForm/GetCertificateCodeForm';
import Seo from '../../components/organisms/Seo/Seo';

import * as styles from './giftCertificates.module.css';

const Title = ({ children }) => (
  <div className={styles.redeemTitle}>{children}</div>
);

const CheckGiftCertificateBalance = () => (
  <div className={styles.container}>
    <Title>
      You can check the balance of a gift certificate by typing the code in to
      the box below.
    </Title>
    <div>
      <GetCertificateCodeForm />
    </div>
  </div>
);

const CheckGiftCertificateBalancePage = () => {
  return (
    <Layout>
      <Seo title="Check Gift Card Balance" />
      <Container size="large">
      <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {link: '/gift-certificates/', label:'Gift Certificates'}, {label:'Check Balance'}]}/>
      <PageTitle title="Gift Certificates" titleClass={styles.title}>
      </PageTitle>

      <GiftCertificatesTab pageId={'check-gift-certificate-balance'}>
        <CheckGiftCertificateBalance />
      </GiftCertificatesTab>
      </Container>
    </Layout>
  );
};

export default CheckGiftCertificateBalancePage;
