import React, { useContext, useMemo, useState } from 'react';
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';
import EventCard from '../../molecules/EventCard/EventCard';
import EventsTab from '../EventsTab/EventsTab';
import { parse } from 'date-fns';

import * as styles from './EventsAll.module.css';
import ContentfulContext from '../../../context/ContentfulProvider';
const EventsAll = ({ events, tabId }) => {
  const [filter, setFilter] = useState('future');
  const [futureEvents, setEvents] = useState(false);
  const [eventsByMonth, setEventsByMonth] = useState(false);
  const siteConfig = useContext(ContentfulContext);
  // const clubMediaDefaults = useEffect(() => (siteConfig && siteConfig.clubMediaDefaults) || [], [siteConfig]);
  const clubMediaDefaults = (siteConfig && siteConfig.clubMediaDefaults) || [];

  const padFigure = figure => {
    return String(figure).padStart(2, '0');
  };

  useMemo(() => {
    if (!futureEvents) {
      // console.log('events', events)
      const _futureEvents = events.filter((e) => {
        const currentDay = padFigure(new Date().getDate());
        const currentMonth = padFigure(new Date().getMonth());
        const currentYear = new Date().getFullYear();
        const current = Number(`${currentYear}${currentMonth}${currentDay}`);
        const endDate = e.eventFieldsSide.endDate;
        const dateEndObject = endDate ? new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')) : null;
        const end = dateEndObject ? Number(`${dateEndObject.getFullYear()}${padFigure(dateEndObject.getMonth())}${padFigure(dateEndObject.getDate())}`) : null;
        
        if (get(e, 'eventFieldsSide.club.databaseId', 0) === 4668) {
          return false;
        }

        if (end > current) {
          return e;
        }

        if (end === current) {
          const currentTime = Number(
            `${new Date().getHours()}${new Date().getMinutes()}`
          );
          const endTime = e.eventFieldsSide.endTime;

          if (endTime === '') return e;

          let timeEndObject = new Date(
            `${endDate.replace(
              /([0-9]{4})([0-9]{2})([0-9]{2})/,
              '$1-$2-$3'
            )} ${endTime}`
          );
          
          if (isNaN(timeEndObject.getTime())) {
            timeEndObject = parse(`${endDate.replace(
              /([0-9]{4})([0-9]{2})([0-9]{2})/,
              '$1-$2-$3'
            )} ${endTime}`, 'yyyy-MM-dd h:mm a', new Date());
          }

          const checkTime = Number(
            `${timeEndObject.getHours()}${padFigure(
              timeEndObject.getMinutes()
            )}`
          );

          if (checkTime > currentTime) {
            return e;
          }
        }

        return false;
      });
      setEvents(_futureEvents);

      const eventsByMonth = _futureEvents.filter(e => {
        const currentMonth = new Date().getMonth();
        const endDate = e.eventFieldsSide.endDate;
        const dateEndObject = new Date(
          endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')
        );
  
        if (currentMonth === dateEndObject.getMonth()) {
          return e;
        }
        return false;
      });
      setEventsByMonth(eventsByMonth);
    }
  }, [events, futureEvents]);

  // const filterEventByMonth = () => {
  //   return futureEvents.filter(e => {
  //     const currentMonth = new Date().getMonth();
  //     const endDate = e.eventFieldsSide.endDate;
  //     const dateEndObject = new Date(
  //       endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')
  //     );

  //     if (filter === 'month' && currentMonth === dateEndObject.getMonth()) {
  //       return e;
  //     } else if (
  //       filter === 'future' &&
  //       currentMonth <= dateEndObject.getMonth()
  //     ) {
  //       return e;
  //     }
  //     return false;
  //   });
  // };

  const renderEvents = () => {
    // const filteredEvents =
    //   filter !== 'all' ? filterEventByMonth() : futureEvents;

    const filteredEvents =
      filter === 'month' ? eventsByMonth : futureEvents;
    if (filteredEvents.length === 0) {
      return <div>No events currently listed</div>;
    }

    return filteredEvents.map((event, eventIndex) => (
      <EventCard eventObj={event} key={eventIndex} clubMediaDefaults={clubMediaDefaults} />
    ));
  };

  return (
    <>
      <Container>
        <div className={styles.eventsHeaderContainer}>
          <h1 className={styles.title}>What's On</h1>
        </div>
      </Container>
      <EventsTab pageId={tabId} setFilter={setFilter}>
        <div className={styles.root}>{futureEvents && eventsByMonth && renderEvents()}</div>
      </EventsTab>
    </>
  );
};

export default EventsAll;
