import React from 'react';
import Link from 'gatsby-link';
import Layout from '../../components/organisms/Layout/Layout';
import Container from '../../components/atoms/Container/Container'
import Breadcrumbs from '../../components/atoms/Breadcrumbs/Breadcrumbs'
import PageTitle from '../../components/atoms/PageTitle/PageTitle';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import Seo from '../../components/organisms/Seo/Seo';

import * as styles from './giftCertificates.module.css';

const Title = ({ children }) => (
  <div className={styles.redeemTitle}>{children}</div>
);
const Description = ({ children }) => (
  <li className={styles.redeemDescription}>{children}</li>
);

const RedeemGiftCertificate = () => (
  <div className={styles.container}>
    <Title>To redeem a gift certificate follow the simple steps below.</Title>
    <ul>
      <Description>
        You need your unique gift certificate code, which is part of the gift
        certificate that was emailed to you as an attachment. It will look
        something like Z50-Y6K-COS-402.
      </Description>
      <Description>
        Browse the store and add items to your cart as you normally would.
      </Description>
      <Description>
        Click the <Link to="/cart">'View Cart'</Link> link to view the contents
        of your shopping cart.
      </Description>
      <Description>
        Type your gift certificate code in to the 'Redeem Gift Certificate' box
        and click 'Go'
      </Description>
    </ul>
  </div>
);

const RedeemGiftCertificatePage = () => {
  return (
    <Layout>
      <Seo title="Redeem Gift Certificates" />
      <Container size="large">
        <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {link: '/gift-certificates/', label:'Gift Certificates'}, {label:'Redeem'}]}/>
        <PageTitle title="Gift Certificates" titleClass={styles.title}>
      
        </PageTitle>

        <GiftCertificatesTab pageId={'redeem-gift-certificate'}>
          <RedeemGiftCertificate />
        </GiftCertificatesTab>
      </Container>
    </Layout>
  );
};

export default RedeemGiftCertificatePage;
