import React from 'react'

const CheckOutline = () => (
    <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M48 0c26.5 0 48 21.5 48 48S74.5 96 48 96 0 74.5 0 48 21.5 0 48 0zm0 2C22.6 2 2 22.6 2 48s20.6 46 46 46 46-20.6 46-46S73.4 2 48 2z"
          fill="currentColor"
        />
        <path
          stroke="currentColor"
          strokeWidth={2}
          d="M64 37L45.615 58.818 34 49.113"
        />
      </g>
    </svg>
)

export default CheckOutline