import React, { useEffect } from 'react';

import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../atoms/Container/Container';
import parse from 'html-react-parser';
import CustomSlider from '../CustomSlider/CustomSlider';

import * as styles from './Eat.module.css';
// import ContentCard from '../../molecules/ContentCard/ContentCard';

const Eat = ({ eat, moreEats }) => {
  // console.log(eat);
  const dates = [];
  if (eat.startDate) dates.push(eat.startDate);
  if (eat.endDate && eat.endDate !== eat.startDate) dates.push(eat.endDate);

  const featuredImage = eat?.featuredImage;

  // const [randMoreEats, setRandMoreEats] = useState([]);
  useEffect(() => {
    if (moreEats && moreEats.length > 0) {
      let randomIndex = 0;
      let randomiseEats = moreEats;
      let currentIndex = randomiseEats.length;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [randomiseEats[currentIndex], randomiseEats[randomIndex]] = [
          randomiseEats[randomIndex],
          randomiseEats[currentIndex],
        ];
      }

      if (randomiseEats.length === 0) randomiseEats = false;

      // setRandMoreEats(randomiseEats.slice(0, 3));
    }
  // }, [moreEats, setRandMoreEats]);
    }, [moreEats]);

  return (
    <div className={styles.root}>
      <div
        className={styles.pageBanner}
        style={{
          backgroundImage: `url(${featuredImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}></div>
      <Container>
        <div className={styles.main}>
          <Breadcrumbs
            crumbs={[
              { label: 'Food & Drink', link: '/food-drink/' },
              { label: eat.title },
            ]}
          />
          <h1>{eat.title}</h1>
          <div className={styles.content}>
            {eat.content && parse(eat.content)}
          </div>
          <div className={styles.detailsGrid}>
            {eat.openingHours && (
              <div className={styles.openinghours}>
                <h4>Opening hours</h4>
                {eat.openingHours.map((hours, hoursIndex) => (
                  <div key={hoursIndex}>
                    <span>
                      <strong>{hours.days}:&nbsp;</strong>
                    </span>
                    <span>{hours.hours}</span>
                  </div>
                ))}
              </div>
            )}
            {eat.formatted_address && (
              <div className={styles.addresses}>
                <h4>address</h4>
                <div>
                  <span>{eat.formatted_address}</span>
                </div>
              </div>
            )}
          </div>

          {eat.menus.length > 0 && (
            <div className={styles.menus}>
              <h4>Menus</h4>
                {eat.menus.map((menu, menuIndex) => (
                  <div className={styles.menu} key={menuIndex}>
                    {menu.name}: <a href={menu.file.publicUrl} target="blank" rel="noreferrer noopener">View</a>
                  </div>
                ))}
            </div>
          )}

          <div className={styles.gallery}>
            {eat.gallery && (
              <CustomSlider title={''} slidesToShow={3}>
                {eat.gallery &&
                  eat.gallery.map(
                    (image, galleryIndex) =>
                      image &&
                      image.publicUrl && (
                        <div className={styles.galleryItem} key={galleryIndex}>
                          <img src={image.publicUrl || ''} alt={eat.title} />
                        </div>
                      )
                  )}
              </CustomSlider>
            )}
          </div>
          {/* <div className={styles.divider}></div> */}
          {/* https://matterdesign.atlassian.net/browse/USU-524 */}
          {/* <div className={styles.moreRestaurant}>
            <h6 className={`h7`}>More Restaurants</h6>
            <div className={styles.eatGrid}>
              {randMoreEats &&
                randMoreEats.map((eat, eatIndex) => (
                  <ContentCard key={eatIndex} cardInfo={eat} />
                ))}
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default Eat;

