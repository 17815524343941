import React, { useState } from 'react';

import GlobalContent from '../../atoms/ContentfulGlobalContent/ContentfulGlobalContent';
import parse from 'html-react-parser';
import * as styles from './ProductInformation.module.css';

const ProductInformation = ({ details }) => {
  const [active, setActive] = useState('details');

  return (
    <div className={styles.ProductInformation}>
      <div className={styles.labelContainer}>
        <span
          className={`${styles.label} ${
            active === 'details' ? styles.active : ''
          }`}
          key={'details'}
          role={'presentation'}
          onClick={() => setActive('details')}>
          details
        </span>
        {/* <span
          className={`${styles.label} ${active === 'care' ? styles.active : ''}`}
          key={'care'}
          role={'presentation'}
          onClick={() => setActive('care')}
        >
          care
        </span> */}
        <span
          className={`${styles.label} ${
            active === 'delivery' ? styles.active : ''
          }`}
          key={'delivery'}
          role={'presentation'}
          onClick={() => setActive('delivery')}>
          delivery
        </span>
      </div>
      <div className={styles.infoContainer}>
        <div className={`${active === 'details' ? styles.show : styles.hide}`}>
          {parse(details)}
        </div>
        {/* <div className={`${active === 'care' ? styles.show : styles.hide}`}>
          <GlobalContent showTitle={false} name={'Product Care'}></GlobalContent>
        </div> */}
        <div className={`${active === 'delivery' ? styles.show : styles.hide}`}>
          <GlobalContent
            showTitle={false}
            name={'Product Delivery'}></GlobalContent>
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;

