import React from 'react';
import * as styles from './ContentfulMenuGroup.module.css';

import SmartLink from '../../atoms/SmartLink/SmartLink';

const ContentfulMenuGroup = props => {

  const { menuItems } = props; 

  return (
    <div className={ styles.ContentfulMenuGroup }>
        <div className={styles.linkGroupContainer}>
          {menuItems?.map((link, index) => {
            return <SmartLink key={index} menuLink={link}></SmartLink>
          })}
        </div>
    </div>
  );
};

export default ContentfulMenuGroup;
