// extracted by mini-css-extract-plugin
export var active = "ContentfulBlockHero-module--active--4a1ad";
export var contentBlur = "ContentfulBlockHero-module--contentBlur--90164";
export var heroSubmenu = "ContentfulBlockHero-module--heroSubmenu--a31f3";
export var inner = "ContentfulBlockHero-module--inner--68d2c";
export var innerSticky = "ContentfulBlockHero-module--innerSticky--330df";
export var navSlide = "ContentfulBlockHero-module--navSlide--2039c";
export var navSlideDown = "ContentfulBlockHero-module--navSlideDown--4bf9f";
export var pageHero = "ContentfulBlockHero-module--pageHero--44465";
export var pageHeroImage = "ContentfulBlockHero-module--pageHeroImage--83ffd";
export var stickyShow = "ContentfulBlockHero-module--stickyShow--bc36e";
export var stickyTabs = "ContentfulBlockHero-module--stickyTabs--56ab3";
export var withSubmenu = "ContentfulBlockHero-module--withSubmenu--7b32f";