import React, {useContext} from 'react';

import { Link } from 'gatsby';
import AdjustItem from '../../molecules/DemoAdjustItem/AdjustItem';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import CartContext from '../../../context/CartProvider';

import * as styles from './MiniCartItem.module.css';
// import { isGradHireProduct } from '../../../helpers/bigcommerce';

const MiniCartItem = (props) => {
  const {
    image,
    alt,
    name,
    price,
    id,
    options,
    qty,
    item,
    disableItemAdjustment,
    is_visible,
    // product_id = null
  } = props;

  const ctx = useContext(CartContext);
  const { removeItemFromCart } = ctx;

  const getProductSlug = () => {
    const prepend = '/product';
    let slug = '/';

    if(item.url !== undefined && item.url !== null) {
      const urlArray = item.url.split('/').filter((url) => url !== '');
      if(urlArray.length !== 0) {
        slug = urlArray[urlArray.length - 1]
      }
    }
    return `${prepend}/${slug}`;
  }

  let size = '';
  if(options) {
    const findSize = options.find(option => option.name ==='size');
    if(findSize) size = findSize.value;
  }

  return (
    <div className={styles.root}>
      {is_visible
        ?
        <Link to={getProductSlug()} className={styles.imageContainer}>
          <img src={image} alt={alt} />
        </Link>
        :
        <div className={styles.imageContainer}>
          <img src={image} alt={alt} />
        </div>
      }
      {/* <Link to={getProductSlug()} className={styles.imageContainer}>
        <img src={image} alt={alt} />
      </Link> */}
      {/* <div className={styles.imageContainer} role={'presentation'} onClick={() => navigate(getProductSlug())}>
        <img src={image} alt={alt} />
      </div> */}
      <div className={styles.detailsContainer}>
        <div className={styles.metaContainer}>
          {is_visible === true
            ?
            <Link to={getProductSlug()}>
              <span style={disableItemAdjustment === true ? {marginLeft: '0px'} : {}} className={styles.name}>
                {name}{size && ` (${size}) `}
              </span>
            </Link>
            :
              <span style={disableItemAdjustment === true ? {marginLeft: '0px'} : {}} className={styles.name}>
                {name}{size && ` (${size}) `}
              </span>
          }
          {/* <Link to={getProductSlug()}>
            <span style={disableItemAdjustment === true ? {marginLeft: '0px'} : {}} className={styles.name}>
              {name}{size && ` (${size}) `}
            </span>
          </Link> */}
          <span className={`${styles.mobileRemove}`} role={'presentation'} onClick={() => removeItemFromCart(id)}>
              Remove
          </span>
          {/* <span className={styles.meta}>Color: {color}</span>
          <span className={styles.meta}>Size:
            <span className={styles.size}>{size}</span>
          </span> */}
        </div>
        <div className={styles.controlContainer}>
          <div className={styles.adjustItemContainer}>
            {disableItemAdjustment === true || <AdjustItem isTransparent initQty={qty} item={item} />}
            <span className={`${styles.remove}`} role={'presentation'} onClick={() => removeItemFromCart(id)}>
              Remove
            </span>
          </div>
          <div className={styles.priceContainer}>
            <CurrencyFormatter amount={price}/>
          </div>
        </div>
      </div>
      {/* <div className={styles.priceContainer}>
        <CurrencyFormatter amount={price}/>
      </div> */}
    </div>
  );
};

export default MiniCartItem;
