import { CompositeDecorator } from 'draft-js';
import React from 'react';

/**
 * Draft JS decorator to display links correctly inside RTE
 */
 function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback
  );
}

const Image = (props) => {
  const {
    height,
    src,
    width,
  } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <img src={src} height={height} width={width} alt='' />
  );
};
const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a className='underline' rel='noreferrer' target='_blank' href={`${url}`}>
      {props.children}
    </a>
  );
};

const decoratorsRaw =   [
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findImageEntities,
    component: Image,
  },
]
const decorators = new CompositeDecorator(
  decoratorsRaw
);

export { decoratorsRaw, decorators };

