import React from 'react'

const Link = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.2091 10.9855C16.9034 10.2987 16.4749 9.65217 15.9113 9.08858C15.3473 8.52542 14.7012 8.09647 14.0144 7.79077L13.1635 8.64165L12.5723 9.23244L11.9587 9.84603C11.8446 9.95963 11.7477 10.0833 11.6631 10.2127C12.4775 10.2658 13.2398 10.5943 13.8223 11.1772C14.4214 11.7759 14.7328 12.5469 14.7846 13.3316C14.8139 13.7675 14.76 14.2044 14.6201 14.6232C14.4648 15.089 14.2148 15.5281 13.8517 15.9026C13.8403 15.9136 13.8337 15.9272 13.8231 15.9381L13.1262 16.6342L12.1596 17.6009L11.2578 18.5031L8.90736 20.8535C8.27139 21.4895 7.42666 21.839 6.52666 21.839C5.62841 21.839 4.78192 21.4886 4.14596 20.8526C3.51087 20.2175 3.16087 19.3719 3.16087 18.4728C3.16087 17.5737 3.51043 16.7276 4.1464 16.0921L6.50034 13.7377C6.36569 13.3202 6.26964 12.893 6.21043 12.4618C6.16657 12.1403 6.13675 11.8171 6.13543 11.493C6.13104 10.8974 6.20473 10.3022 6.34245 9.71928L2.05736 14.0039C-0.409745 16.4719 -0.409745 20.4737 2.05736 22.9416C3.29157 24.175 4.90868 24.7925 6.52578 24.7925C8.14376 24.7925 9.76087 24.1754 10.9946 22.9416L15.2731 18.6631L15.9104 18.0267C16.2749 17.6618 16.5793 17.2601 16.8359 16.8381C17.2398 16.1759 17.5144 15.4583 17.6517 14.718C17.7223 14.3373 17.76 13.9517 17.76 13.5653C17.7609 13.0938 17.703 12.6232 17.5986 12.1609C17.5091 11.7596 17.3793 11.3662 17.2091 10.9855Z" fill="currentColor"/>
  <path d="M22.9414 2.05876C21.7071 0.824991 20.0896 0.207886 18.4729 0.207886C16.8549 0.208324 15.2378 0.824991 14.0041 2.0592L9.726 6.33727L9.08872 6.97455C8.72381 7.33947 8.41986 7.74122 8.16285 8.16271C7.75934 8.82499 7.48434 9.54253 7.34662 10.2829C7.276 10.6636 7.23828 11.0496 7.23828 11.4355C7.23785 11.907 7.2953 12.3776 7.39969 12.8403C7.49048 13.2412 7.6203 13.6346 7.79048 14.0158C8.09618 14.7022 8.52425 15.3482 9.08828 15.9118C9.65232 16.4754 10.2979 16.9039 10.9852 17.2101L11.8357 16.3596L12.4269 15.7689L13.04 15.1557C13.1541 15.0417 13.2514 14.918 13.3361 14.7881C12.5212 14.7351 11.7589 14.407 11.176 13.8237C10.576 13.2241 10.2646 12.4513 10.2128 11.6649C10.1848 11.2303 10.2396 10.7956 10.3786 10.3776C10.533 9.91183 10.783 9.47236 11.1471 9.09824C11.158 9.08727 11.165 9.07411 11.176 9.06271L11.8725 8.36622L12.8396 7.39955L13.7418 6.49736L16.0918 4.1478C16.7278 3.51183 17.5729 3.16139 18.4729 3.16139C19.3721 3.16139 20.2177 3.51183 20.8528 4.14736C22.1659 5.46008 22.1659 7.59604 20.8536 8.90832L18.4988 11.2627C18.6339 11.6807 18.7295 12.1074 18.7887 12.539C18.833 12.8605 18.8624 13.1838 18.8637 13.5074C18.8681 14.1035 18.7949 14.6982 18.6558 15.2811L22.9414 10.9965C25.4085 8.5285 25.4085 4.52675 22.9414 2.05876Z" fill="currentColor"/>
  </svg>
  
)

export default Link