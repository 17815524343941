// extracted by mini-css-extract-plugin
export var block = "KlarnaInfo-module--block--15645";
export var blockMessage = "KlarnaInfo-module--blockMessage--4877e";
export var blockSubtitle = "KlarnaInfo-module--blockSubtitle--be910";
export var body = "KlarnaInfo-module--body--32b8b";
export var bottomLine = "KlarnaInfo-module--bottomLine--c8911";
export var footer = "KlarnaInfo-module--footer--157ea";
export var header = "KlarnaInfo-module--header--d1580";
export var imgContainer = "KlarnaInfo-module--imgContainer--3652d";
export var intro = "KlarnaInfo-module--intro--cf511";
export var modal = "KlarnaInfo-module--modal--7a16c";
export var svgSection = "KlarnaInfo-module--svgSection--1fe4f";
export var titles = "KlarnaInfo-module--titles--b5bc1";
export var zipMoney = "KlarnaInfo-module--zipMoney--99b9e";
export var zipPay = "KlarnaInfo-module--zipPay--a2ee9";