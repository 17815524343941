import React, { useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Icon from '../Icon/Icon';
import * as styles from './AccordionCard.module.css';
const AccordionCard = ({ children, title, showContent = true, toggleSelector = false, selectorName, isChecked = false, contentPadding = true, fetchOutput = false }) => {
  const [isOpen, setIsOpen] = useState(showContent);

  return (
    <div className={styles.root}>
      <div
        className={[styles.header, isOpen && styles.active].join(' ')}
        onClick={() => setIsOpen(!isOpen)}
        role='presentation'
        >
          {toggleSelector && (
            <Checkbox action={toggleSelector} isChecked={isChecked} name={selectorName} />
          )}
          <h1>{title}</h1>
          <Icon symbol="chevronDown" />
      </div>
      <div className={[styles.content, isOpen && styles.visible, !contentPadding && styles.noPad].join(' ')}>
        {(fetchOutput && isOpen) ? fetchOutput() : children}
      </div>
    </div>
  );
};

export default AccordionCard;

