import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
// import { dataLayerPush } from '../helpers/general';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Catalog from '../components/organisms/Catalog/Catalog';

export const query = graphql`
  query CategoryTemplateQuery(
    $id: String!
    $bigcommerce_id: Int!
    $skip: Int!
    $limit: Int!
  ) {
    category: allBigCommerceCategories(filter: { id: { eq: $id } }) {
      edges {
        node {
          description
          id
          image_url
          meta_description
          page_title
          name
          bigcommerce_id
        }
      }
    }
    categories: allBigCommerceCategories(
      sort: { order: ASC, fields: sort_order }
      filter: { is_visible: {eq: true} }
    ) {
      edges {
        node {
          bigcommerce_id
          name
          image_url
          description
          parent_id
          custom_url {
            url
            is_customized
          }
        }
      }
    }

    brands: allBigCommerceBrands {
      edges {
        node {
          name
          id
          bigcommerce_id
        }
      }
    }

    products: allBigCommerceProducts(
      filter: { categories: { eq: $bigcommerce_id } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          entityId: bigcommerce_id
          name
          sku
          price
          calculated_price
          retail_price
          sale_price
          brand_id
          categories
          warranty
          custom_url {
            url
          }
          images {
            url_standard
            url_thumbnail
            url_zoom
            id
            description
            image_file
            is_thumbnail
          }
          variants {
            product_id
            id
            sku
          }
          options {
            option_values {
              id
              is_default
              label
            }
            id
            display_name
            type
          }
          custom_fields {
            name
            value
          }
        }
      }
    }

    productImages: allBigCommerceProductImages(
      filter: { categories: { eq: $bigcommerce_id } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        entityId: bigcommerce_id
        images {
          image500
        }
      }
    }
  }
`;

const CategoryTemplate = props => {
  const { data, errors, pageContext } = props;
  // console.log(pageContext);
  const category = data && data.category.edges[0].node;
  const categories = (category.bigcommerce_id !== 84 && data) ? data.categories.edges?.map(a => a.node).filter(a => a.bigcommerce_id !== 84) : [];
  const [init, setInit] = useState(false);

  // console.log(data);
  const foundFilters = {
    colors: [],
    price: [],
    brand: [],
    sizes: [],
  };

  let _colors = [];
  let _sizes = [];
  const products =
    data &&
    data.products.edges.map(product => {
      // console.log('product', product.node);
      const brand =
        data &&
        data.brands.edges.find(
          brand => brand.node.bigcommerce_id === product.node.brand_id
        );
      product.node.brand_name = brand ? brand.node.name : '';

      if (
        brand &&
        brand.node.name &&
        foundFilters.brand.indexOf(brand.node.name) < 0
      ) {
        foundFilters.brand.push(
          brand.node.name.replace(/\s+/g, '_').toLowerCase()
        );
      }

      const images = 
        data &&
        data.productImages.nodes.find(
          pi => pi.entityId === product.node.entityId
        );
      product.node.generatedImages = images?.images || {};

      // let productColor = [];
      // if(Array.isArray(product.node.options) && product.node.options?.length !== 0) {
      //   const colorOptions = product.node.options.filter((bcOption) => bcOption.type === 'swatch').map((bcColor) => {
      //       let colorArray = [];
      //       if(Array.isArray(bcColor.option_values) && bcColor.option_values.length !== 0) {
      //           colorArray = bcColor.option_values.map((color) => color.label);
      //       }
      //       return colorArray;
      //   })
      //   _colors = [...colorOptions[0], ..._colors];
      //   productColor = colorOptions[0];
      // }
      _colors = [..._colors, ...product.colors || []];
      _sizes = [..._sizes, ...product.sizes || []];

      return product;
    });

  if (_colors) {
    foundFilters.colors = _colors;
  }
  if (_sizes) {
    foundFilters.colors = _sizes;
  }

  useEffect(() => {
    if (!init && products) {
      setInit(true);
      // dataLayerPush(
      //   'productImpressions',
      //   null,
      //   products.map(product => product.node),
      //   null,
      //   category
      // )
    }
  }, [init, products]);

  // console.log(category);
  // console.log('foundFilters', foundFilters);
  // console.log('categories', categories);


  return (
    <Layout disablePaddingBottom>
      {errors && <Seo title='GraphQL Error' />}
      {category && <Seo title={category.page_title || category.name} />}

      {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

      {category && (
        <Catalog
          pageContext={pageContext}
          category={category}
          products={products}
          foundFilters={foundFilters}
          categories={categories}
        />
      )}
    </Layout>
  );
};

export default CategoryTemplate;

