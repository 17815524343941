import React from 'react'

const List = () => (
    <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 24 24'
  >
    <path
      d="M7 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM15 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM15 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7 20a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM15 20a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
)

export default List