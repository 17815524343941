import React from 'react'

const Bin = () => (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24'>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M6 21h12V7H6v14ZM8 9h8v10H8V9Zm7.5-5-1-1h-5l-1 1H5v2h14V4h-3.5Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Bin