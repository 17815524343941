import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import Breadcrumbs from '../../../components/atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../../components/atoms/Container/Container';
import Loader from '../../../components/atoms/Loader/Loader';
// import Hero from '../components/organisms/StaticHero/Hero';
import Seo from "../../../components/organisms/Seo/Seo"
import JobCard from '../../../components/molecules/JobCard/JobCard';
import Layout from "../../../components/organisms/Layout/Layout";
import { listJobs } from '../../../helpers/scout';

import * as styles from './jobs.module.css';

const CareersPage = (props) => {
    const [jobs, setJobs] = useState(false);

    useMemo(() => {
        if (!jobs && typeof window !== 'undefined') {
            listJobs().then(response => {
                if (get(response, 'response.jobs')) {
                    const jl = response?.response?.jobs.filter((j) => {
                        const normalizedFilter = j.entity.trim();
                        if(normalizedFilter !== 'USU (Volunteers)') return true
                        else return false;
                    })

                    // setJobs(response?.response?.jobs);
                    setJobs(jl);
                } else {
                    setJobs([]);
                }
            });
        }
    }, [jobs]);

    return (
        <Layout>
            <Seo title="Job Openings" />
            <div className={styles.root}>
                <Container size={'large'} spacing={'min'}>
                    <Breadcrumbs crumbs={[
                        // {link: '/', label: 'Home'},
                        {link: '/about/careers', label: 'Work with Us'},
                        {label: 'Job Openings'},
                    ]}/>
                    <h1>Job Opportunities</h1>   
                    <div className={styles.jobsContainer}>
                        {!jobs && (<div>
                            <Loader />
                        </div>)}
                        {(jobs && jobs.length === 0) && (
                            <div>No active openings currently available. Please check back soon.</div>
                        )}
                        {(jobs && jobs.length > 0) && (
                            <div className={styles.jobGrid}>
                                {jobs.map((job) => {
                                    return(
                                        <JobCard key={job.id} job={job} />
                                    )
                                })}
                            </div>
                        )}
                        {/* {jobs.length === 0 && <span>No available jobs at the moment</span>} */}
                    </div>
                </Container>

            </div>
        </Layout>
    );
};

export default CareersPage;
