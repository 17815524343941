import React from 'react';
import Toggle from '../../atoms/Toggle/Toggle';
import * as styles from './ApprovalToggle.module.css';
const ApprovalToggle = ({
  title,
  content,
  id,
  checked,
  onChange,
  children,
}) => {
  return (
    <div>
      <div className={styles.approvalContainer}>
        <div className={styles.approval}>
          <span className={styles.sublabel}>{title}</span>
          {content ? (
            <span
              className={styles.current}
              dangerouslySetInnerHTML={{
                __html: content,
              }}></span>
          ) : (
            { children }
          )}
        </div>
        <Toggle
          id={id}
          checked={checked}
          onChange={() => onChange(id, !checked)}
          label={'Approve?'}
        />
      </div>
    </div>
  );
};

export default ApprovalToggle;

