import React, { useState } from 'react';
import Icon from '../../Icon/Icon'
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import ZipPayInfo from "../../ZipPayInfo/ZipPayInfo";
import Dialog from "../../Dialog/Dialog";

const Zip = ({ product, styles }) => {
    const [displayTnC, setDisplayTnC] = useState(false);
    const payments = 26;
    const orderLimit = 10000;

    const thePrice = () => {
        let thePrice = 0;
        if ('detailedPrices' in product) {
          if (product.detailedPrices.sale_price.is_on_sale) {
            thePrice = product.detailedPrices.sale_price.tax_inclusive;
          } else {
            thePrice = product.detailedPrices.price.tax_inclusive;
          }
        } else if ('sale_price' in product && product.sale_price > 0) {
          thePrice = product.sale_price;
        } else {
          thePrice = product.price;
        }

        return thePrice;
    }

    const calculation = () => {
        return <CurrencyFormatter
          currency='AUD'
          amount={thePrice() / payments}
        />
    }

    if (thePrice() <= orderLimit) {
        return (
            <div className={styles.option} role="presentation" onClick={() => setDisplayTnC(!displayTnC)}>
                <div className={styles.zip}>
                    <Icon symbol='zipLogo'></Icon>
                </div>
                {thePrice() > 1000 ? (
                    <span>Own it from {calculation()} weekly for 6 months <Icon symbol="helpCircle" /></span>
                ) : (
                    <span>Own it from $10 per week <Icon symbol="helpCircle" /></span>
                )}
                <Dialog open={displayTnC} size="md" hideBtnCancel hideBtnOk>
                    <ZipPayInfo />
                </Dialog>
            </div>
        )
    }
    
    return null;
}

export default Zip