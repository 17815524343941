import React, { useState, useCallback, useRef, useContext, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
// import { Link } from 'gatsby';
// import Checkbox from '../../atoms/Checkbox/Checkbox';
import AuthContext from '../../../context/AuthProvider';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';
import FreshdeskArticleSearch from '../../organisms/FreshdeskArticleSearch/FreshdeskArticleSearch';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectField from "../../atoms/SelectField/SelectField";
import { validateEmail, getBase64 } from '../../../helpers/general';
// import Dialog from '../../atoms/Dialog/Dialog';

import AlgoliaArticleSearch from '../Search/searches/AlgoliaArticleSearch';
import { debounce } from 'lodash';
import { freshdeskApi } from '../../../helpers/freshdesk';

import * as styles from './FreshdeskContactUsForm.module.css';
import GlobalContent from '../../atoms/ContentfulGlobalContent/ContentfulGlobalContent';
import Loader from '../../atoms/Loader/Loader';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Captcha from '../../atoms/Captcha/Captcha';

import { success, error } from '../../../helpers/toast';
import Icon from '../../atoms/Icon/Icon';


const FreshdeskContactUsForm = () => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
    subject: '',
    member_number: '',
    mobile: '',
    enquiryType: '',
  });

  const captchaRef = useRef(null)
  const [terms, setTerms] = useState(false);
  
  // const [dialogOpen, setDialogOpen] = useState(false);
  const auth = useContext(AuthContext);
  const userChecked = auth && auth.state.userChecked;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const [formLabel, setFormLabel] = useState('form');
  const [searchResults, setSearchResults] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const [formLoading, setFormLoading ] = useState(false);
  const [enquiriesType, setEnquiriesType] = useState([]);
  const [captcha, setCaptcha] = useState(null);
  // const [selectedFile, setSelectedFile] = useState();
  // const [metaFileName, setMetaFileName] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  // eslint-disable-next-line
  const handler = useCallback(debounce(async (subject) => {
    if(subject === '') {
        setSearchResults(undefined);
        return
    };
    setSearchLoading(true);
    const articleSearch = await AlgoliaArticleSearch(subject);
    setSearchLoading(false);
    if(articleSearch) {
      setSearchResults(articleSearch)
    }
  }, 1500), []);

  const validForm = data => {
    if (!data.enquiryType || data.enquiryType === 'Select an option') {
      return 'Please select your enquiry type.';
    }
    if (!data.first_name) {
      return 'First Name is required.';
    }
    if (!data.email) {
      return 'Email is required.';
    }
    if (!validateEmail(data.email)) {
      return 'Invalid email address.';
    }
    if (!data.subject) {
      return 'Subject is required.';
    }
    if (!data.message) {
      return 'Message is required.';
    }
    if (!data.terms) {
      return 'You must accept the terms & conditions.';
    }

    if(captcha !== false) {
      return 'Invalid captcha.'
    }
  };

  const handleUpload = (e) => {
    if(e === undefined) return;
    // setSelectedFile(e.target.files[0]);
    // setMetaFileName(e.target.files[0]?.name);
    setFileSizeExceeded(false);
    if (e.target.files[0]) {
      let currentSize = e.target.files[0].size;
      if (uploadedFiles && uploadedFiles.length > 0) {
        uploadedFiles.map((file) => {
          currentSize += file.file.size;
          return file;
        })
      }

      if (currentSize > 20000) {
        setFileSizeExceeded(true);
        return;
      }

      setUploadedFiles([
        ...(uploadedFiles || []),
        { name: e.target.files[0]?.name, file: e.target.files[0] }
      ])
    }
  }

  const removeThisFile = (index) => {
    setUploadedFiles(
      uploadedFiles.filter((arr, x) => x !== index)
    )
  }

  const submitContactUsInfo = async() => {
    setFormLoading(true);

    const validationMsg = validForm({...values , terms : terms});
    if (validationMsg) {
      setMessage({ error: validationMsg });
      setFormLoading(false);
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      return;
    }

    let base64Docs = undefined;
    if (uploadedFiles && uploadedFiles.length > 0) {
      base64Docs = [];
      await Promise.all(uploadedFiles.map(async (upload) => {
        const file = await getBase64(upload.file);
        base64Docs.push({
          attachments: file,
          name: upload.name,
          type: upload.file?.type
        })
    
        return upload;
      }))
    }
    // const base64Doc = selectedFile ? await getBase64(selectedFile) : undefined;
    const { first_name, last_name, email, message, subject, enquiryType, member_number, mobile } = values;
    const body = {
      custom_fields: {
        cf_full_name: `${first_name} ${last_name}`,
        cf_enquiry_related: enquiryType,
        cf_member_number: member_number,
        cf_mobile: mobile,
      },
      email: email,
      subject: subject,
      type: 'Question',
      source: 2, // portal
      description: message,
      status: 2, //open -- do not remove
      priority: 1, //low -- do not remove,
      // responder_id: 51023715896, // id of unisyd
      group_id: 51000236711, // id of General Inquiries Team
      file_info: base64Docs,
    };

    freshdeskApi(`tickets`, 'POST', body).then(response => {
      setFormLoading(false);
      if ((response && response.status === 200) || response.status === 201) {
        if(response?.response.errors) {
          error('Sorry, something went wrong.');
          return;
        } else {
          setMessage({
            error: '',
            success: '',
            dialogSuccess: '',
            dialogError: ''
          })
          success('Thanks, we received your enquiry.');
          captchaRef.current.reset();
        }
        // setSelectedFile();
        // setMetaFileName();
        setUploadedFiles(null);
        setValues({
          first_name: '',
          last_name: '',
          email: '',
          message: '',
          subject: '',
          member_number: '',
          enquiryType: '',
        });
        
      } else {
        if(response.status === 403) error('Invalid file type');
        else error('Sorry, something went wrong.');
      }
    });
  };

  useEffect(() => {
    if(isLoggedIn === true && userChecked === true && auth.state?.object) {
      setValues({
        ...values,
        first_name: auth.state.object.first_name,
        last_name: auth.state.object.last_name,
        email: auth.state.object.email,
        mobile: auth.state.object.phone,
        member_number: auth.state.usu.MemberNumber,
      });
    }
    //eslint-disable-next-line
  }, [isLoggedIn, userChecked])

    // to get the list of agent ids
    // freshdeskApi('/agents').then((response) => {
    //   console.log(response);
    // })

    // to get the list of groups
    // freshdeskApi('/groups').then((response) => {
    //   console.log(response);
    // })



    useEffect(() => {
      // to get the list of ticket fields
      freshdeskApi('/ticket_fields').then((response) => {
        if(String(response.status).startsWith('2') && Array.isArray(response.response)) {
          const typeOfEnquiry = response?.response?.filter((field) => field.name === "cf_enquiry_related");
          if(typeOfEnquiry[0]) {

            // put general enquiry in front
            // 'General Enquiry' ,
            const arrangedArray = [...typeOfEnquiry[0].choices.filter((v) => v !== 'General Enquiry')];
            const mappedOutEnquiry = arrangedArray.map((v) => {
              return { value: v, text: v }
            })
            setEnquiriesType(mappedOutEnquiry);
          }
        } else {
          setEnquiriesType([{value: 'General Enquiry', text: 'General Enquiry'}]);
        }
      })
    }, [])
  
  return (
    <div className={styles.root}>
      {/* {msg.error && <p className={styles.error}>{msg.error}</p>} */}
      {msg.error && <MessageAlert type={'error'} message={msg.error} />}
      <div className={styles.labelContainer}>
          <span className={`${styles.label} ${formLabel === 'form' ? styles.active : ''}`} role={'presentation'} onClick={() => setFormLabel('form')}>
            Contact Form
          </span>
          <span className={`${styles.label} ${formLabel === 'faqs' ? styles.active: ''}`} role={'presentation'} onClick={() => setFormLabel('faqs')}>
            Search FAQ {searchResults && `(${searchResults.hits.length})`}
          </span>
      </div>
      {formLabel === 'form' && 
      <div className={styles.formContainer}>
        <div className={styles.form}>
          <SelectField
              label={'Type of enquiry'}
              id={'enquiryType'}
              handleChange={onHandleChange}
              data={enquiriesType}
          />
          <FormInputField
            id="first_name"
            type="input"
            labelName="First Name"
            value={values['first_name']}
            handleChange={onHandleChange}
          />
          <FormInputField
            id="last_name"
            type="input"
            labelName="Last Name"
            value={values['last_name']}
            handleChange={onHandleChange}
          />
          <FormInputField
            id="email"
            type="input"
            labelName="Email"
            value={values['email']}
            handleChange={onHandleChange}
          />
          {/* <FormInputField
            id="member_number"
            type="input"
            labelName="Membership Number"
            value={values['member_number']}
            handleChange={onHandleChange}
          /> */}
          {/* <FormInputField
            id={'student_id'}
            className={styles.contactInput}
            handleChange={onHandleChange}
            labelName="Student ID"
            value={values['student_id']}
          /> */}
          <div className={styles.subjectContainer} style={{marginBottom: `${searchResults ? '36px' : '16px'}`}}>
            <FormInputField
              id="subject"
              type="input"
              labelName="Subject"
              value={values['subject']}
              handleChange={(id, value) => {
                onHandleChange(id, value);
                handler(value);
              }}
            />
            {searchResults && 
            <span className={styles.articleLabel} role={'presentation'} onClick={() => {setFormLabel('faqs')}}>
              {searchResults.hits.length} articles found
            </span>}
            {searchLoading === true && 
            <div className={styles.loaderContainer}>
              <Loader />
            </div>}
          </div>
          <FormInputField
            id="message"
            type="textarea"
            labelName="Message"
            value={values['message']}
            handleChange={onHandleChange}
          />
          <div>

          <div>
            <span className={styles.fileUploadLabel}>Upload File (optional)</span>
            <div className={styles.filePickerContainer}>
                <div className={styles.fileHelperContainer}>
                    {/* <div className={styles.fileNameContainer}>
                      <span className={styles.fileName}>
                        {metaFileName ? metaFileName : ''}
                      </span>
                      {metaFileName && 
                        <div className={styles.closeIconContainer} role={'presentation'} onClick={() => {
                          setMetaFileName();
                          setSelectedFile();
                        }}>
                          <Icon symbol={'close'}></Icon>
                        </div>
                      }
                    </div> */}
                      {uploadedFiles && uploadedFiles.map((file, index) => (
                        <div key={index} className={`${styles.fileNameContainer} ${styles.fileNameRow}`}>
                          <span className={styles.fileName}>
                            {file ? file.name : ''}
                          </span>
                          <div className={styles.closeIconContainer} role={'presentation'} onClick={() => {
                              // setMetaFileName();
                              // setSelectedFile();
                              removeThisFile(index)
                            }}>
                              <Icon symbol={'close'}></Icon>
                          </div>
                        </div>
                      ))}
                    <span className={`${styles.fileHelper} ${fileSizeExceeded ? styles.fileHelperError : ''}`}>File cannot exceed 20MB.</span>
                </div>
                <label className={styles.filePicker} htmlFor='file' >
                <input id="file" type="file" name="file" onChange={handleUpload}></input>
                  <span className={styles.fileButtonUpload}>
                    Select File
                  </span>
              </label>
            </div>
          </div>

          </div>
          <div className={styles.checkBoxContainer}>
            <Checkbox
              inverted
              name="terms"
              label="I accept the <a href='/terms-of-use'><u>terms and conditions</u></a>"
              action={() => setTerms(!terms)}
              isChecked={terms}
            />
          </div>
          <Captcha setCaptcha={setCaptcha} captchaRef={captchaRef}/>
          <div className={`row ${styles.buttonWrapp}`}>
            <Button
              type="span"
              level="primary"
              fullWidth
              disabled={formLoading}
              onClick={() => submitContactUsInfo()}
            >
              Send
              {formLoading === true && 
              <div className={styles.formLoaderContainer}>
                <Loader />
              </div>}
            </Button>
          </div>
        </div>
        <div className={styles.contactMessageContainer}>
          <GlobalContent name={'Contact Us Details'}></GlobalContent>
        </div>
      </div>
      }

      {formLabel === 'faqs' && <FreshdeskArticleSearch term={values['subject']} data={searchResults} />}
      {/* <Dialog
        hideBtnCancel={true}
        size="sm"
        open={dialogOpen}
        hideBtnOk={false}
        title="Contact Us Message"
        onCancel={() => setDialogOpen(false)}
      >
        {msg.dialogSuccess && (
          <p className={styles.dialogContent}>{msg.dialogSuccess}</p>
        )}
        {msg.dialogError && (
          <p className={styles.dialogContent}>{msg.dialogError}</p>
        )}
      </Dialog> */}
    </div>
  );
};

export default FreshdeskContactUsForm;
