import React from 'react';

const ArrowDown = () => (
    <svg width={12} height={11} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 12 11'>
    <path
      d="M1.247.648v5.594l5.929.001V2.838l4.2 3.905-4.2 3.904V7.243H.247V.648h1Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default ArrowDown;
