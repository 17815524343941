import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { get, isEmpty } from 'lodash'; // debounce
import AuthContext from '../context/AuthProvider';
import CartContext from '../context/CartProvider';
import ContentfulContext from '../context/ContentfulProvider';
import {
  getStorage,
  setStorage,
  validateEmail,
  rewriteShippingOptionLabel,
  removeStorage,
} from '../helpers/general';
import { bcApi } from '../helpers/bigcommerce';
import { toast } from 'react-toastify';
import { predictAddress } from '../helpers/geoscape'; // fetchAddressType, getAddressDetails
import Container from '../components/atoms/Container/Container';
import Button from '../components/atoms/Button/Button';
import Loader from '../components/atoms/Loader/Loader';
import Checkbox from '../components/atoms/Checkbox/Checkbox';
import Radio from '../components/atoms/Radio/Radio';
import CurrencyFormatter from '../components/atoms/CurrencyFormatter/CurrencyFormatter';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Cart from '../components/organisms/Cart/Cart';
import CartSummary from '../components/molecules/CartSummary/CartSummary';
import CartShippingOptions from '../components/molecules/CartShippingOptions/CartShippingOptions';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import MessageAlert from '../components/atoms/MessageAlert/MessageAlert';
import Icon from '../components/atoms/Icon/Icon';
import { dataLayerPush } from '../helpers/general';

/* Enable Fast Checkout */
// import FastCheckout from '../components/atoms/FastCheckout/FastCheckout';

import * as styles from './confirm-order.module.css';

const ConfirmOrderPage = () => {
  const cartCtx = useContext(CartContext);
  const cartFetched = get(cartCtx, 'state.cartFetched', false);
  const cart = get(cartCtx, 'state.cart', {});
  const checkout = get(cartCtx, 'state.checkout', {});
  const numberItems = get(cartCtx, 'state.cart.numberItems');
  const { lineItems } = cart; // cartAmount, 
  // const grandTotal =
  //   (checkout && 'grand_total' in checkout && checkout.grand_total) ||
  //   cartAmount;

  /* SUPPORT FOR LOCALISR AND CnC */
  // const ctxStockAvailability = get(cartCtx, 'state.stockAvailability');
  // const ctxSelectedStore = get(cartCtx, 'state.selectedStore');
  const cartShippingMethod = get(cartCtx, 'state.shippingMethod', 'delivery');
  const ctxReloadCheckout = get(cartCtx, 'initCheckout');
  const ctxLoadCart = get(cartCtx, 'loadCart');
  const [loadCart, setLoadedCart] = useState(false);
  const [fetchingCheckout, setFetchingCheckout] = useState(false);

  const authCtx = useContext(AuthContext);
  const contentfulCtx = useContext(ContentfulContext);
  const isLoggedIn = get(authCtx, 'state.isLoggedIn', false);
  const userChecked = get(authCtx, 'state.userChecked', false);
  const accountDetails = get(authCtx, 'state.object', {});
  const refreshUser = authCtx && authCtx.refreshData;
  const [accountAutomated, setAccountAutomated] = useState(false);

  const storeKey = '__jammcd';
  const stepKey = '__jammstep';
  const stateKey = '__jammqs';
  const [init, setInit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [shippingRequired, setShippingRequired] = useState(true);
  const [displayNotice, setDisplayNotice] = useState(null);
  /* SUPPORT FOR LOCALISR AND CnC */
  // const ccEligible = false;
  const storedStep = getStorage(stepKey);
  const [cartStep, setCartStep] = useState(
    storedStep && storedStep !== 'cart' ? false : true
  );
  const setStep =
    (storedStep && storedStep === 'account' && isLoggedIn
      ? 'shipping'
      : storedStep) || 'cart';
  const [currentStep, setCurrentStep] = useState(setStep);
  const [nextStepValid, setNextStepValid] = useState(true);
  const [allProductsAvailable, setAvailable] = useState(true);
  const [methodBeingSet, settingMethod] = useState(false);

  const [checkoutEmail, setCheckoutEmail] = useState(
    (isLoggedIn && accountDetails.email) || null
  );
  // const [manualShippingAddress, setManualShippingAddress] = useState(true);
  const manualShippingAddress = true;
  // const [shippingAddressResults, setShippingAddressResults] = useState(false);
  // const [shippingAddressMessage, setShippingAddressMessage] = useState(false);
  const [shippingAddressId, setShippingAddressId] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    billingSameAsShipping: true,
    country_code: 'AU',
    phone: '',
  });
  const [consignment, setConsignment] = useState({});
  const [shippingOptionMessage, setShippingOptionMessage] = useState(
    'Please enter your shipping address to calculate available options'
  );
  const [shippingOptions, setShippingOptions] = useState([]);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState(false);
  const [shippingMethod, setShippingMethod] = useState(false);
  // const [manualBillingAddress, setManualBillingAddress] = useState(false);
  const manualBillingAddress = true;
  // const [billingAddressResults, setBillingAddressResults] = useState(false);
  // const [billingAddressMessage, setBillingAddressMessage] = useState(false);
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingAddressValid, setBillingAddressValid] = useState(false);
  const [countries, setCountries] = useState([]);
  const [shippingCountryStates, setShippingCountryStates] = useState([]);
  const [billingCountryStates, setBillingCountryStates] = useState([]);
  const [confirmingCheckoutData, setConfirmingCheckoutData] = useState(false);
  const [checkoutFailMessage, setCheckoutFailMessage] = useState([]);
  const [expressCheckout, setExpressCheckout] = useState(false);
  const [currentLineItems, setCurrentLineItems] = useState(false);

  // const stateTranslations = {
  //     'ACT': 'Australian Capital Territory',
  //     'NSW': 'New South Wales',
  //     'NT': 'Northern Territory',
  //     'QLD': 'Queensland',
  //     'SA': 'South Australia',
  //     'TAS': 'Tasmania',
  //     'VIC': 'Victoria',
  //     'WA': 'Western Australia'
  // };

  const requiredAddressFields = [
    'first_name',
    'last_name',
    'phone',
    'address1',
    'city',
    'country_code',
    'state_or_province',
    'postal_code',
  ];

  const dataQuery = useStaticQuery(graphql`
    query {
      allBigCommerceCountries {
        edges {
          node {
            country
            country_iso2
            states {
              resource
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (displayNotice === null) {
      if (typeof window !== 'undefined' && window.location.search.indexOf('mpaa=true') > -1) {
        setDisplayNotice("We see you're already part way through setting up your membership. Please continue below to complete your purchase.");
      } else {
        setDisplayNotice(false);
      }
    }
  }, [displayNotice]);

  const scrollToSection = sectionId => {
    setTimeout(() => {
      const sTop = document.getElementById(sectionId).offsetTop - 112;
      window.scrollTo({
        top: sTop,
        left: 0,
        behavior: 'smooth',
      });
    }, 50);
  };

  const isCartDigitalItemsOnly = useCallback(() => {
    let isDigitalItemsOnly = false;

    if (
      cart &&
      cart.lineItems &&
      'physical_items' in cart.lineItems && cart.lineItems.physical_items.length === 0 &&
      (('gift_certificates' in cart.lineItems && cart.lineItems.gift_certificates.length > 0) ||
        ('digital_items' in cart.lineItems && cart.lineItems.digital_items.length > 0))
        // && shippingRequired === true -- sometimes results to false
    ) {
      isDigitalItemsOnly = true;
    }

    return isDigitalItemsOnly;
  }, [cart])

  const cartContainsMembershipOnly = () => {

    let result = false;
    const membershipArray = ['20183', '13610'];

    if('physical_items' in cart.lineItems && cart.lineItems.physical_items.length !== 0)
    return result;

    if('gift_certificates' in cart.lineItems && cart.lineItems.gift_certificates.length !== 0)
    return result;

    if('digital_items' in cart.lineItems && cart.lineItems.digital_items.length === 1) {
      // if(cart.lineItems.digital_items[0].sku === '20183') {
      if (membershipArray.includes(cart.lineItems.digital_items[0].sku) === true) {
        result = true;
      }
    }

    return result;
  }

  const cartContainsLockerHireOnly = () => {
    let result = false;
    const lockerArray = ['643', '654'];

    if('physical_items' in cart.lineItems && cart.lineItems.physical_items.length !== 0)
    return result;

    if('gift_certificates' in cart.lineItems && cart.lineItems.gift_certificates.length !== 0)
    return result;

    if('digital_items' in cart.lineItems && cart.lineItems.digital_items.length >= 1) {
      let subResult = true;
      cart.lineItems.digital_items.map(i => {
        if (!(lockerArray.includes(i.sku))) {
          subResult = false;
        }
        return true;
      })
      result = subResult;
    }

    return result;
  }

  const cartContainsGraddieFlow = () => {
    let result = false;
    
    result = get(cartCtx, 'state.withGradHire', false);

    return result;
  }

  const toggleBillingAddressCnC = async () => {
    toggleNextStep('billing');
    try {
      const _newState = await ctxReloadCheckout();
      setShippingAddress(_newState.checkout.consignments[0].shipping_address);
    } catch (_e) {
      console.log(_e);
    }
  };

  const viewCartDataLayer = (lineItems, event) => {
    if (lineItems) {
      const allCartItems = [];

      if (lineItems.custom_items && lineItems.custom_items.length) {
        lineItems.custom_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.digital_items.length) {
        lineItems.digital_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.gift_certificates.length) {
        lineItems.gift_certificates.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.physical_items.length) {
        lineItems.physical_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (allCartItems.length) {
        dataLayerPush(event, null, allCartItems);
      }
    }
  }

  const toggleNextStep = nextStep => {
    setCheckoutFailMessage([]);
    switch (nextStep) {
      case 'cart':
        setCartStep(true);
        setCurrentStep('cart');
        setStorage(stepKey, 'cart', true);
        scrollToSection('section-cart');
        break;

      case 'account':
        setCartStep(false);
        setStorage(
          stateKey,
          JSON.stringify({
            baseAmount: cart.baseAmount,
            items: cart.numberItems,
          })
        );
        storeCheckoutData({ cartConfirmed: true });
        let isShippingRequired = shippingRequired;
        if (
          cart &&
          'physical_items' in cart.lineItems &&
          cart.lineItems.physical_items.length === 0 &&
          'gift_certificates' in cart.lineItems &&
          cart.lineItems.gift_certificates.length > 0
        ) {
          // DIGITAL ITEMS IN CART ONLY - No need for shipping
          isShippingRequired = false;
          setShippingRequired(isShippingRequired);
        }

        if (isLoggedIn) {
          // console.log(checkout);
          if (checkout && checkout.cart && checkout.cart.customer_id === 0) {
            // console.log("Set customer ID on to Cart");
            bcApi(`carts/${checkout.id}`, 'PUT', {
              customer_id: accountDetails.id,
            }).then(async () => {
              // console.log("Refresh checkout");
              await ctxReloadCheckout();
              if (isShippingRequired) {
                toggleNextStep('shipping');
              } else {
                toggleNextStep('billing');
              }
            });
          } else {
            if (isShippingRequired) {
              toggleNextStep('shipping');
            } else {
              toggleNextStep('billing');
            }
          }
          setCheckoutEmail(accountDetails.email);
          storeCheckoutData({ email: accountDetails.email });
        } else if (accountAutomated) {
          /**
           * Member data provided from signup flow, so skip this step
           */
          if (isShippingRequired) {
            toggleNextStep('shipping');
          } else {
            toggleNextStep('billing');
          }
        } else {
          setCurrentStep('account');
          setStorage(stepKey, 'account', true);
          scrollToSection('section-account');
        }

        break;

      case 'shipping':
        if (cartShippingMethod === 'collect') {
          toggleBillingAddressCnC();
          break;
        }
        if (!shippingRequired) {
          toggleNextStep('billing');
          break;
        }
        setCurrentStep('shipping');
        setStorage(stepKey, 'shipping', true);
        scrollToSection('section-shipping');
        if (
          !shippingAddress.state_or_province &&
          !shippingAddress.country_code
        ) {
          fetchStates({ target: { value: 'AU' } }, 'shipping');
        } else if (shippingAddress.country_code) {
          fetchStates(
            { target: { value: shippingAddress.country_code } },
            'shipping'
          );
        }
        checkForShippingMethods();

        if (lineItems) {
          viewCartDataLayer(lineItems, 'addShippingInfo')
        }

        break;

      case 'billing':
        setCurrentStep('billing');
        setStorage(stepKey, 'billing', true);
        scrollToSection('section-billing');
        if (!billingAddress.state_or_province && !billingAddress.country_code) {
          fetchStates({ target: { value: 'AU' } }, 'billing');
        } else if (billingAddress.country_code) {
          fetchStates(
            { target: { value: billingAddress.country_code } },
            'billing'
          );
        }
        setBillingAddressValid(validateRequiredAddressFields(billingAddress));

        if (lineItems) {
          viewCartDataLayer(lineItems, 'addBillingInfo')
        }
        break;

      case 'payment':
        setCurrentStep('payment');
        setStorage(stepKey, 'payment', true);
        scrollToSection('section-payment');

        if (lineItems) {
          viewCartDataLayer(lineItems, 'addPaymentInfo')
        }
        break;

      default:
        break;
    }
  };

  const storeCheckoutData = obj => {
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    const newSet = JSON.stringify({ ...existing, ...obj });
    setStorage(storeKey, newSet);
  };

  const removeCheckoutData = objKey => {
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    delete existing[objKey];
    const newSet = JSON.stringify({ ...existing });
    setStorage(storeKey, newSet);
  };

  const setIsValid = res => {
    setNextStepValid(res);
  };

  const validateRequiredAddressFields = obj => {
    let allValid = true;
    //Check if store pickup
    requiredAddressFields.map(field => {
      if (!(field in obj)) allValid = false;
      else if (obj[field].trim() === '') allValid = false;

      return true;
    });

    return allValid;
  };

  const handleGuestEmail = email => {
    // Validate email
    if (!validateEmail(email)) {
      setCheckoutEmail(null);
      removeCheckoutData('email');
      return false;
    }

    setCheckoutEmail(email);
    storeCheckoutData({ email });
  };

  const assignGuestToOrder = () => {
    // TODO: Handle logged in user
    // TODO: Add guest to order
    toggleNextStep('shipping');
  };

  const setPreviousAddress = async (type, addressId) => {
    if (accountDetails && addressId) {
      const addressDetails = accountDetails.addresses.find(
        address => address.id === parseInt(addressId)
      );
      const field = { ...addressDetails };
      document.getElementById(`${type}_first_name`).value = field.first_name;
      document.getElementById(`${type}_last_name`).value = field.last_name;
      document.getElementById(`${type}_phone`).value = field.phone;
      document.getElementById(`${type}_address1`).value = field.address1;
      document.getElementById(`${type}_address2`).value = field.address2;
      document.getElementById(`${type}_city`).value = field.city;
      document.getElementById(`${type}_state`).value = field.state_or_province;
      document.getElementById(`${type}_country_code`).value =
        field.country_code;
      document.getElementById(`${type}_postal_code`).value = field.postal_code;
      if (type === 'shipping') {
        const newFields = { ...shippingAddress, ...field };
        // setShippingAddressResults(false);
        storeCheckoutData({ shipping: newFields });
        setShippingAddress(newFields);
        // Attempt to get search address
        const suggestions = await predictAddress(
          `${field.address1}, ${field.city}`
        );
        let geoAddressId = false;
        if (
          suggestions &&
          suggestions.type === 'results' &&
          suggestions.results[0]
        ) {
          geoAddressId = suggestions.results[0].id;
        }
        setShippingAddressId(geoAddressId);
        setShippingMethod(false);
        removeCheckoutData('shippingMethod');
        checkForShippingMethods(checkoutEmail, newFields, geoAddressId);
      } else if (type === 'billing') {
        const newFields = { ...billingAddress, ...field };
        // setBillingAddressResults(false);
        setBillingAddress(newFields);
        storeCheckoutData({ billing: newFields });
        setBillingAddressValid(validateRequiredAddressFields(newFields));
      }
    }
  };

  // const triggerSearch = debounce(async (type, address) => {
  //     const suggestions = await predictAddress(address);
  //     if (suggestions) {
  //         if (suggestions.type === 'results') {
  //             if (type === 'shipping') {
  //                 // setShippingAddressResults(suggestions.results);
  //             } else if (type === 'billing') {
  //                 setBillingAddressResults(suggestions.results);
  //             }
  //         } else if (type === 'shipping') {
  //             // setShippingAddressResults(false);
  //             // setShippingAddressMessage(suggestions[suggestions.type]);
  //         } else if (type === 'billing') {
  //             setBillingAddressResults(false);
  //             setBillingAddressMessage(suggestions[suggestions.type]);
  //         }
  //     }
  // }, 200, {trailing: true});

  // const searchAddress = (type, value) => {
  //     triggerSearch(type, value);
  // }

  // const selectAddress = async (type, addressId) => {
  //     const addressData = await getAddressDetails(addressId);
  //     if (addressData.type === 'address') {
  //         const field = {};
  //         const addressProps = addressData.address.properties;
  //         // console.log(addressProps);
  //         const addressField = `${addressProps.street_number_1}${addressProps.street_number_2 ? `-${addressProps.street_number_2}` : ''} ${addressProps.street_name} ${addressProps.street_type}`;
  //         // console.log("Full Address: ", addressField);
  //         const addressDetail = [];
  //         if (addressProps.complex_level_type !== null) {
  //             addressDetail.push(`${addressProps.complex_level_type_description} ${addressProps.complex_level_number}`);
  //         }
  //         if (addressProps.complex_unit_type !== null) {
  //             addressDetail.push(`${addressProps.complex_unit_type_description} ${addressProps.complex_unit_identifier}`);
  //         }
  //         const addressDetails = addressDetail.join(', ');
  //         field.address1 = addressDetails !== '' ? addressDetails : addressField;
  //         field.address2 = addressDetails !== '' ? addressField : '';
  //         field.city = addressProps.locality_name;
  //         field.state_or_province = stateTranslations[addressProps.state_territory];
  //         field.country_code = 'AU';
  //         field.postal_code = addressProps.postcode;
  //         document.getElementById(`${type}_address1`).value = field.address1;
  //         document.getElementById(`${type}_address2`).value = field.address2;
  //         document.getElementById(`${type}_city`).value = field.city;
  //         document.getElementById(`${type}_state`).value = field.state_or_province;
  //         document.getElementById(`${type}_country_code`).value = field.country_code;
  //         document.getElementById(`${type}_postal_code`).value = field.postal_code;
  //         if (type === 'shipping') {
  //             const newFields = {...shippingAddress, ...field};
  //             // setShippingAddressResults(false);
  //             storeCheckoutData({shipping: newFields});
  //             setShippingAddress(newFields);
  //             setShippingAddressId(addressId);
  //             setShippingMethod(false);
  //             removeCheckoutData('shippingMethod');
  //             checkForShippingMethods(checkoutEmail, newFields, addressId);
  //         } else if (type === 'billing') {
  //             const newFields = {...billingAddress, ...field};
  //             setBillingAddressResults(false);
  //             setBillingAddress(newFields);
  //             storeCheckoutData({billing: newFields});
  //             setBillingAddressValid(validateRequiredAddressFields(newFields));
  //         }
  //     } else {
  //         // TODO: Throw error
  //     }
  // }

  // const triggerManualAddress = (type) => {
  //     if (type === 'shipping') {
  //         setManualShippingAddress(true);
  //         setShippingAddressId(false);
  //         if (document.getElementById('shipping_state').value === '') {
  //             document.getElementById('shipping_country_code').value = '';
  //         }
  //     } else if (type === 'billing') {
  //         setManualBillingAddress(true);
  //         if (document.getElementById('billing_state').value === '') {
  //             document.getElementById('billing_country_code').value = '';
  //         }
  //     }
  // }

  const handleShippingAddress = (key, value) => {
    const field = {};
    field[key] = value;
    const newFields = { ...shippingAddress, ...field };
    // console.log(newFields);
    setShippingAddress(newFields);
    // setShippingMethod(false);
    // removeCheckoutData('shippingMethod'); // Theres a reason this was here, but causes issues elsewhere
    storeCheckoutData({ shipping: newFields });
  };

  const handleShippingToBilling = e => {
    handleShippingAddress('billingSameAsShipping', e.target.checked);
  };

  const checkForShippingMethods = (
    newCheckoutEmail,
    newShippingAddress,
    newShippingAddressId
  ) => {
    const useCheckoutEmail = newCheckoutEmail || checkoutEmail;
    const useShippingAddress = newShippingAddress || shippingAddress;
    const useShippingAddressId = newShippingAddressId || shippingAddressId;
    // console.log(useGuestEmail, useShippingAddress);

    if (
      useCheckoutEmail &&
      validateRequiredAddressFields(useShippingAddress) &&
      cart && 'physical_items' in cart.lineItems
    ) {
      // console.log('cart', cart);
      setFetchingOptions(true);
      setFetchedOptions(false);
      const cartId = cart.cartId;
      const consignment = get(checkout, 'consignments[0]');
      const lineItems = [
        ...cart.lineItems.physical_items,
        ...cart.lineItems.custom_items,
      ];
      let requestBody = [
        {
          shipping_address: {
            email: useCheckoutEmail,
            first_name: useShippingAddress.first_name,
            last_name: useShippingAddress.last_name,
            company: useShippingAddress.company,
            address1: useShippingAddress.address1,
            address2: useShippingAddress.address2,
            city: useShippingAddress.city,
            country_code: useShippingAddress.country_code,
            state_or_province: useShippingAddress.state_or_province,
            state_or_province_code: useShippingAddress.state_or_province,
            postal_code: useShippingAddress.postal_code,
            phone: useShippingAddress.phone,
          },
          line_items: lineItems.map(item => ({
            item_id: item.id,
            quantity: item.quantity,
          })),
        },
      ];

      let endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;
      let method = 'POST';
      if (consignment) {
        endpoint = `checkouts/${cartId}/consignments/${consignment.id}?include=consignments.available_shipping_options`;
        method = 'PUT';
        requestBody = requestBody[0];
      }
      // console.log("consignment endpoint", endpoint, method, requestBody);
      bcApi(endpoint, method, requestBody).then(async response => {
        // console.log("consignment response", response);
        if (
          response.status === 200 &&
          response.response.data.consignments.length > 0 &&
          response.response.data.consignments[0].available_shipping_options
            .length > 0
        ) {
          // console.log("Shipping Address ID", useShippingAddressId);

          // Default to Residential unless we can determine the true nature of the address
          // let addressType = 'Residential';
          if (useShippingAddressId) {
            //     const addressDetail = await fetchAddressType(useShippingAddressId);
            //     if (addressDetail.response.data && addressDetail.response.data.length > 0) {
            //         addressType = addressDetail.response.data[0].zonings[0];
            //         /* Potential options:
            //             0 Unknown
            //             1 Commercial/Business
            //             2 Community Use
            //             3 Conservation/National Park
            //             4 Industrial/Utilities
            //             5 Mixed Use    (This one means it can be both residential and commercial)
            //             6 Recreational/Open Space
            //             7 Residential
            //             8 Rural/Primary Production
            //             9 Special Use
            //             10 Transport/Infrastructure
            //             11 Water
            //             Videopro requests that all options result to 'Business' except for 7
            //         */
            //         addressType = 'Business';
            //         if (addressType === 'Residential') addressType = 'Residential';
            //     }
          }

          // console.log("consignment", response.response.data);
          const newConsignment = response.response.data.consignments[0];
          setConsignment(newConsignment);
          // const hideAddressType = addressType === 'Business' ? 'Residential' : 'Business';
          // console.log('Address is', addressType);
          // console.log('so hide', hideAddressType);
          const availableShippingOptions =
            newConsignment.available_shipping_options; // .filter(option => option.description.indexOf(`(${hideAddressType}:`) > -1 ? false : true);
          let formatShippingOptions = [];
          if (cartShippingMethod === 'delivery') {
            // let totalCost = 'under';
            // if (grandTotal > 100) {
            //   totalCost = 'over';
            // }
            formatShippingOptions = availableShippingOptions.filter(
              option =>
                option.type !== 'shipping_pickupinstore'
                && option.type !== 'freeshipping'
                // option.description.toLowerCase().includes(totalCost)
            );
          }

          // Hide Click and Collect option
          // availableShippingOptions = availableShippingOptions.filter(a => ['click', 'collect', 'pickup'].filter(b => a.description.toLowerCase().includes(b)).length === 0);
          setShippingOptions(formatShippingOptions);
          setFetchedOptions(true);

          //Set default if there's only 1 option
          if (formatShippingOptions.length === 1)
            handleShippingMethod(
              formatShippingOptions[0].id,
              formatShippingOptions,
              newConsignment
            );
        } else if (
          response.status === 200 &&
          response.response.data.consignments.length > 0 &&
          response.response.data.consignments[0].available_shipping_options
            .length === 0
        ) {
          setConsignment(response.response.data.consignments[0]);
          setShippingOptions([]);
          setFetchedOptions(true);
        } else {
          setConsignment({});
          setShippingOptions([]);
          setFetchedOptions(false);
        }
        setFetchingOptions(false);
      });
    } else {
      setShippingOptionMessage(
        'One or more fields are still required before available options can be calculated'
      );
    }
  };

  const handleShippingMethod = (
    value,
    options = [],
    customConsignment = {}
  ) => {
    settingMethod(true);
    const selectedMethod =
      options.length > 0
        ? options.find(method => method.id === value)
        : shippingOptions.find(method => method.id === value);
    // console.log("Selected method", selectedMethod);
    const cartId = cart.cartId;
    const requestBody = {
      shipping_option_id: value,
    };
    bcApi(
      `checkouts/${cartId}/consignments/${customConsignment?.id ||
        consignment.id}`,
      'PUT',
      requestBody
    ).then(response => {
      if (
        response.status === 200 &&
        response.response.data.consignments.length > 0 &&
        'selected_shipping_option' in response.response.data.consignments[0]
      ) {
        setShippingMethod(selectedMethod);
        storeCheckoutData({ shippingMethod: selectedMethod });
        cartCtx.fetchCart().then(() => {
          settingMethod(false);
        });
      }
    });
  };

  const assignShippingToOrder = () => {
    // console.log("clicked to move from shipping");
    // Validate we have all required fields
    if (!validateRequiredAddressFields(shippingAddress)) {
      // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
      // console.log("Validation Shipping failed");
      return false;
    }

    if (shippingAddress.billingSameAsShipping) {
      const newBillingFields = { ...shippingAddress };
      delete newBillingFields.comments;
      delete newBillingFields.billingSameAsShipping;
      setBillingAddress(newBillingFields);
      storeCheckoutData({ billing: newBillingFields });
      setBillingAddressValid(true);
      // toggleNextStep('payment');
      // console.log("Adding billing to order");
      assignBillingToOrder(newBillingFields);
    } else {
      // console.log("Toggling to billing");
      toggleNextStep('billing');
    }
  };

  const handleBillingAddress = (key, value) => {
    const field = {};
    field[key] = value;
    const newFields = { ...billingAddress, ...field };
    setBillingAddress(newFields);
    storeCheckoutData({ billing: newFields });
    setBillingAddressValid(validateRequiredAddressFields(newFields));
  };

  const assignBillingToOrder = newBillingFields => {
    if (!validateRequiredAddressFields(newBillingFields || billingAddress)) {
      // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
      // console.log("Validation Billing failed")
      return false;
    }

    setBillingAddressValid(true);
    const cartId = cart.cartId;
    const billingData = newBillingFields || billingAddress;
    const requestBody = {
      email: checkoutEmail,
      first_name: billingData.first_name,
      last_name: billingData.last_name,
      company: billingData.company,
      address1: billingData.address1,
      address2: billingData.address2,
      city: billingData.city,
      country_code: billingData.country_code,
      state_or_province: billingData.state_or_province,
      state_or_province_code: billingData.state_or_province,
      postal_code: billingData.postal_code,
      phone: billingData.phone,
    };

    bcApi(`checkouts/${cartId}/billing-address`, 'POST', requestBody).then(
      response => {
        if (response.status === 200) {
          toggleNextStep('payment');
        }
      }
    );
  };

  const fetchDefaultStates = countryData => {
    const defaultCountry = countryData.find(
      country => country.node.country_iso2 === 'AU'
    );
    const endpoint = defaultCountry.node.states.resource.substring(1);
    if (typeof window !== 'undefined') {
      bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
        ({ response, status }) => {
          if (status === 200) {
            setShippingCountryStates(response);
            setBillingCountryStates(response);
          } else {
            setShippingCountryStates([]);
            setBillingCountryStates([]);
          }
        }
      );
    } else {
      setShippingCountryStates([]);
      setBillingCountryStates([]);
    }
  };

  const fetchStates = (evt, type) => {
    const { value: countryIso } = evt.target;

    let countryObject = false;
    countries.map(country => {
      if (country.node.country_iso2 === countryIso) {
        countryObject = country;
      }
      return true;
    });
    setIsFetching(true);

    const endpoint = countryObject.node.states.resource.substring(1);
    bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
      ({ response, status }) => {
        if (status === 200) {
          if (type === 'shipping') {
            setShippingCountryStates(response);
          } else if (type === 'billing') {
            setBillingCountryStates(response);
          }
        } else {
          if (type === 'shipping') {
            setShippingCountryStates([]);
          } else if (type === 'billing') {
            setBillingCountryStates([]);
          }
        }
        setIsFetching(false);
      }
    );
  };

  const checkOrderValue = (checkoutData) => {
    return checkoutData.grand_total;
  }

  const validateCheckoutData = evt => {
    evt?.preventDefault();
    setConfirmingCheckoutData(true);
    setCheckoutFailMessage([]);
    const promises = [];

    bcApi(`checkouts/${checkout.id}`).then(response => {
      // console.log(response);
      if (response.status === 200 && 'data' in response.response) {
        const checkoutData = response.response.data;
        let isCheckoutValid = true;
        const failMessage = [];

        // check if logged in user has customer ID set
        if (isLoggedIn && checkoutData.cart.customer_id === 0) {
          if (expressCheckout) {
            promises.push(
              new Promise(res => 
                bcApi(`carts/${checkout.id}`, 'PUT', {
                customer_id: accountDetails.id,
                }).then(() => res(true))
              )
            )
          } else {
            isCheckoutValid = false;
            console.log('logged in customer not set');
            failMessage.push(
              'Logged in account is not assigned to the checkout - please refresh.'
            );
          }
        }
        // check for consignment set
        else if (
          (!checkoutData.consignments ||
            checkoutData.consignments.length === 0) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          console.log('No consignment');
          failMessage.push(
            'No consignment found for the order. Please revisit step 3 and re-choose a shipping option.'
          );
        }
        // check for selected shipping option
        else if (
          checkoutData.consignments.length > 0 &&
          !('selected_shipping_option' in checkoutData.consignments[0]) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          console.log('No shipping option');
          failMessage.push(
            'No shipping option found for the order. Please revisit step 3 and re-choose a shipping option.'
          );
        }
        // check if shipping address is set
        else if (
          checkoutData.consignments.length > 0 &&
          !('shipping_address' in checkoutData.consignments[0]) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          console.log('no shipping address');
          failMessage.push(
            'No shipping address found for the order. Please revisit step 3 and re-submit.'
          );
        }
        // check if set shipping address is valid
        else if (
          checkoutData.consignments.length > 0 &&
          !validateRequiredAddressFields(
            checkoutData.consignments[0].shipping_address
          ) &&
          shippingRequired
        ) {
          isCheckoutValid = false;
          console.log('Shipping address invalid');
          failMessage.push(
            'The shipping address entered is invalid. Please return to step 3 and ensure all required fields are filled.'
          );
        }
        // check if billing address is set
        else if (!('billing_address' in checkoutData)) {
          isCheckoutValid = false;
          console.log('no billing address');
          failMessage.push(
            'No billing address found for the order. Please revisit step 4 and re-submit.'
          );
        }
        // check if set billing address is valid
        else if (!validateRequiredAddressFields(checkoutData.billing_address)) {
          isCheckoutValid = false;
          console.log(
            'billing address not valid',
            checkoutData,
            checkoutData.billing_address
          );
          failMessage.push(
            'The billing address entered is invalid. Please return to step 3 and ensure all required fields are filled.'
          );
        }

        Promise.all(promises).then(() => {
          if (!isCheckoutValid) {
            // TODO: Return meaningful feedback to user on failed check
            setConfirmingCheckoutData(false);
            console.log('Checkout invalid returned');
            setCheckoutFailMessage(failMessage);
          } else {
            if (typeof window !== 'undefined') {
              const orderValue = checkOrderValue(checkoutData);
              if (orderValue === 0) {
                bypassPaymentStep(checkoutData);
              } else {
                /**
                 * Remove stored member info to avoid any issues with future carts
                 */
                removeStorage('memberInfo');
                navigate('/checkout')
                // window.location = '/checkout/';
                //dataLayerPush('Checkout', null, checkoutData, '/checkout/');
              }
            }
          }
        })
      } else {
        // TODO: Return meaningful feedback to user on failed check
        console.log('Check failed');
        setConfirmingCheckoutData(false);
        setCheckoutFailMessage([
          'Something unexpected went wrong - please refresh the page.',
        ]);
      }
    });
  };

  const bypassPaymentStep = (checkoutData) => {
    // console.log("Handle alternate flow");
    // 1. Trigger endpoint to create order from cart
    bcApi(`checkouts/${checkoutData.id}/orders`, 'POST', {}).then(response => {
      if (response.status === 200 && 'data' in response.response) {
        const orderCreated = response.response.data;
        let status = 11; // Awaiting Fulfillment
        if (
          isCartDigitalItemsOnly()
          || cartContainsMembershipOnly()
          || cartContainsLockerHireOnly()
          || cartContainsGraddieFlow()
        ) {
          status = 10; // Completed
        }
        // 2. Update created order to a payment type of 'manual' and status
        const orderBody = {
          "payment_method": "manual",
          "status_id": status
        };
        bcApi(`orders/${orderCreated.id}`, 'PUT', orderBody, 2).then(response2 => {
          if (response2.status === 200 && response2.response) {
            // 3. Set order number to session for the page to pickup and send user to custom confirmation page
            const orderData = response2.response;
            navigate(`/order-confirmation`, {
              state: {
                __uoi: btoa(orderData.id),
              },
            });
          } else {
            // TODO: Something went wrong
            console.log("Order update failed");
            // console.log(response2);
            setConfirmingCheckoutData(false);
            setCheckoutFailMessage([
              'Your order has been created, however something unexpected went wrong during processing - please contact USU staff.',
            ]);
          }
        });
      } else {
        // TODO: Something went wrong
        console.log("Order create failed");
        // console.log(response);
        setConfirmingCheckoutData(false);
        setCheckoutFailMessage([
          'Something unexpected went wrong creating your order - please refresh the page and try again.',
        ]);
      }
    })
  }

  useMemo(() => {
    if (
      !loadCart &&
      typeof window !== 'undefined' &&
      window.location.search.indexOf('cartId') > -1
    ) {
      setLoadedCart(true);
      const cartId = window.location.search.replace('?cartId=', '');
      ctxLoadCart(cartId);
    }

    if (lineItems && JSON.stringify(lineItems) !== JSON.stringify(currentLineItems)) {
      viewCartDataLayer(lineItems, 'beginCheckout');
      setCurrentLineItems(lineItems);
    }

  }, [ctxLoadCart, loadCart, setLoadedCart, lineItems, currentLineItems]);

  useMemo(() => {
    // console.log("Memo fired", userChecked, isLoggedIn);
    const prevState = getStorage(stateKey);
    let changedState = false;
    if (prevState && cart && cartFetched) {
      const prevStateObj = JSON.parse(prevState);
      // console.log("Cart Fetched", cartFetched);
      // console.log("BaseAmount", prevStateObj.baseAmount, cart.baseAmount);
      // console.log("Items", prevStateObj.items, cart.numberItems);
      if (
        prevStateObj.baseAmount !== cart.baseAmount ||
        prevStateObj.items !== cart.numberItems
      ) {
        // console.log("Set to change back to cart", prevStateObj.baseAmount !== cart.baseAmount, prevStateObj.items !== cart.numberItems);
        changedState = true;
      }
    }

    /**
     * Check if we have a stored member object coming from the signup flow to bypass the guest customer step
     */
    const memberInfo = getStorage('memberInfo');
    if (memberInfo) {
      const memberObj = JSON.parse(memberInfo);
      setAccountAutomated(true);
      setCheckoutEmail(memberObj.Email);

      // express checkout
      const isDigital = isCartDigitalItemsOnly();
      if(isDigital === true) {
        const contentfulData = contentfulCtx;
        const automatedFields = {
          email: checkoutEmail,
          first_name: memberObj.firstname,
          last_name: memberObj.lastname,
          phone: memberObj.Mobile,
          postal_code: contentfulData.seoData.postcode || '',
          address1: contentfulCtx.seoData.streetAddress || '',
          state_or_province: contentfulCtx.seoData.state || 'New South Wales',
          country_code: 'AU',
          city: contentfulCtx.seoData.city || '',
        }
        setBillingAddress(automatedFields);
        storeCheckoutData({ email: memberObj.Email, billing: automatedFields });

        bcApi(`checkouts/${cart.cartId}/billing-address`, 'POST', automatedFields).then((response) => {
          if(response.status === 200) {
            setExpressCheckout(true); // flag responsible
          }
        })
      } else {
        setBillingAddress({
          first_name: memberObj.firstname,
          last_name: memberObj.lastname,
          phone: memberObj.Mobile,
        });
        storeCheckoutData({ email: memberObj.Email });
      }
    } else if(isCartDigitalItemsOnly() === true && cartContainsMembershipOnly() === true && userChecked === true && isLoggedIn === false) {
      // check if cart's contents only contains membership item
      if(confirmingCheckoutData !== true) { // currently checking out

        // inform visitor
        toast.info('No membership information. Going to signup page', {
          toastId: 'toastId-la8n9o' // random string
        });

        // give time user to read info
        setTimeout(() => {
          cartCtx.clearCart('/join'); // destroy cart
        }, [2000])
      }
    } else if (
      (
        (isCartDigitalItemsOnly() === true && cartContainsLockerHireOnly() === true) ||
        (cartContainsGraddieFlow() === true)
      ) &&
      userChecked === true && 
      isLoggedIn === true
    ) {
      setAccountAutomated(true);
      const memberObj = get(authCtx, 'state.usu');
      setCheckoutEmail(memberObj.Email);
      const contentfulData = contentfulCtx;
      const automatedFields = {
        email: memberObj.Email,
        first_name: memberObj.FirstName,
        last_name: memberObj.LastName,
        phone: memberObj.Mobile,
        postal_code: contentfulData.seoData.postcode || '',
        address1: contentfulCtx.seoData.streetAddress || '',
        state_or_province: contentfulCtx.seoData.state || 'New South Wales',
        country_code: 'AU',
        city: contentfulCtx.seoData.city || '',
      }
      setBillingAddress(automatedFields);
      storeCheckoutData({ email: memberObj.Email, billing: automatedFields });

      bcApi(`checkouts/${cart.cartId}/billing-address`, 'POST', automatedFields).then((response) => {
        if(response.status === 200) {
          setExpressCheckout(true); // flag responsible
        }
      })
    }

    if (
      cart &&
      cart.lineItems &&
      'physical_items' in cart.lineItems &&
      cart.lineItems.physical_items.length === 0 &&
      (('gift_certificates' in cart.lineItems &&
        cart.lineItems.gift_certificates.length > 0) ||
        ('digital_items' in cart.lineItems &&
          cart.lineItems.digital_items.length > 0)) &&
      shippingRequired === true
    ) {
      // DIGITAL ITEMS IN CART ONLY - No need for shipping
      setShippingRequired(false);

      if (currentStep !== 'cart' && cartShippingMethod === 'collect') {
        // console.log("Digital only - back to cart");
        setCurrentStep('cart');
        setCartStep(true);
        setStorage(stepKey, 'cart', true);
      }
    }

    const storedCheckoutJSON = getStorage(storeKey) || JSON.stringify({});
    const storedCheckout = JSON.parse(storedCheckoutJSON);
    if (
      ((Object.keys(storedCheckout).length === 0 || changedState) &&
        currentStep !== 'cart') ||
      (currentStep === 'account' && isLoggedIn)
    ) {
      // console.log("reset to cart", currentStep, storedCheckout, changedState);
      setCurrentStep('cart');
      setCartStep(true);
      setStorage(stepKey, 'cart', true);
      // removeCheckoutData('cartConfirmed');
    }
    if (!init) {
      if ('email' in storedCheckout) {
        setCheckoutEmail(storedCheckout.email);
      }
      if ('shipping' in storedCheckout) {
        setShippingAddress(storedCheckout.shipping);
      }
      if ('shippingMethod' in storedCheckout) {
        setShippingMethod(storedCheckout.shippingMethod);
      }
      if ('billing' in storedCheckout) {
        setBillingAddress(storedCheckout.billing);
      }
      if (countries.length === 0) {
        setCountries(dataQuery.allBigCommerceCountries.edges);
        fetchDefaultStates(dataQuery.allBigCommerceCountries.edges);
      }
      if (currentStep === 'shipping' || currentStep === 'billing') {
        refreshUser();
      }
      setInit(true);
    }

    /* SUPPORT FOR LOCALISR AND CnC */
    // if ((ctxStockAvailability && ctxStockAvailability.length > 0) && ctxSelectedStore && cartShippingMethod === 'collect') {
    //     const availabilityData = [];
    //     cart.lineItems.physical_items.map(a => {
    //         const availableData = ctxStockAvailability.find(s => s.sku === a.sku);
    //         if (availableData) {
    //         availabilityData.push(availableData);
    //         }
    //         return true;
    //     });

    //     const unavailableItems = availabilityData.filter(item => {
    //         const store = item.locations.filter(a => a.code === ctxSelectedStore.store_id);
    //         if (store.length === 0 || (parseInt(store[0].qty, 10) === 0 && store[0].vendorOrderETA === null)) {
    //             return true;
    //         }
    //         return false
    //     });

    //     if (unavailableItems.length > 0) {
    //         setAvailable(false);
    //     } else if (!allProductsAvailable) {
    //         setAvailable(true);
    //     }
    // } else
    if (!allProductsAvailable) {
      setAvailable(true);
    }

  /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    init,
    cart,
    cartFetched,
    currentStep,
    dataQuery,
    countries,
    refreshUser,
    isLoggedIn,
    userChecked,
    shippingRequired,
    cartShippingMethod,
    allProductsAvailable,
    contentfulCtx,
    checkoutEmail,
    isCartDigitalItemsOnly,
    // validateRequiredAddressFields,
  ]); //, ctxStockAvailability, ctxSelectedStore

  useMemo(() => {
    const confirmAccountAssigned = async () => {
      if (
        !fetchingCheckout &&
        currentStep !== 'cart' &&
        checkout &&
        'cart' in checkout &&
        checkout.cart.customer_id === 0 &&
        isLoggedIn
      ) {
        setFetchingCheckout(true);
        // console.log("Found logged in, but account not assigned in checkout");
        // console.log("Attempt to set...");
        await bcApi(`carts/${checkout.id}`, 'PUT', {
          customer_id: accountDetails.id,
        });
        // console.log("Refresh checkout state");
        const latestCheckout = await ctxReloadCheckout();
        // console.log(latestCheckout);
        if (
          latestCheckout &&
          'cart' in latestCheckout.checkout &&
          latestCheckout.checkout.cart.customer_id === 0 &&
          isLoggedIn
        ) {
          // console.log("Resetting back to cart because the cart doesn't have the customer ID assigned");
          // console.log("Checkout", checkout);
          setCurrentStep('cart');
          setCartStep(true);
          setCheckoutEmail(null);
          removeCheckoutData('email');
          setStorage(stepKey, 'cart', true);
          // } else {
          //     console.log("All good - continue");
        }
        setFetchingCheckout(false);
      }
    };
    confirmAccountAssigned();


  }, [
    checkout,
    isLoggedIn,
    ctxReloadCheckout,
    fetchingCheckout,
    currentStep,
    accountDetails,
  ]);

  return (
    <Layout>
      <Seo title='Confirm your order' />
      <Container size='large'>
        {!cartFetched && !methodBeingSet && (
          <div className={styles.bcConfirmOrderContainer}>
            <div className={styles.bcCartTitle}>
              <h1 className='h3 text-left'>Cart</h1>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className='text-center'>
                <Loader />
                <p
                  className='subtitle text-center'
                  style={{ paddingTop: '40px' }}>
                  Loading cart...
                </p>
              </div>
            </div>
          </div>
        )}
        {cartFetched && !numberItems && (
          <div className={styles.bcConfirmOrderContainer}>
            <div className={styles.bcCartTitle}>
              <h1 className='h3 text-left'>Cart</h1>
              <Link to='/' className={styles.continueShopping}>
                continue shopping
              </Link>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className='text-center'>
                <p className='subtitle text-center'>Your cart is empty.</p>
                <Link to='/shop/all'>Take a look around.</Link>
              </div>
            </div>
          </div>
        )}
        {((cartFetched && !!numberItems) ||
          (!!numberItems && methodBeingSet)) && (
          <div name='section-cart' id='section-cart'>
            <div className={styles.breadCrumbsContainer}>
              <Breadcrumbs
                crumbs={[
                  { link: '/', label: 'Home' },
                  { link: '/shop/all', label: 'Shop' },
                  { link: '/clothing', label: 'Categories' },
                  { label: 'Confirm your order' },
                ]}
              />
            </div>
            <div className={styles.bcCartTitle}>
              {currentStep === 'cart' && <h1 className='h3 text-left'>Cart</h1>}
            </div>
            <div className={clsx('grid-7030', styles.bcConfirmOrderContainer)}>
              <div>
                {displayNotice && (
                  <div style={{marginTop: '30px', marginBottom: '-15px'}}>
                    <MessageAlert type="warn" message={displayNotice} />
                  </div>
                )}
                {currentStep === 'cart' && (
                  <div>
                    {/* {FastCheckout && (
                                            <>
                                                <FastCheckout cart_id={cart.cartId} />
                                                <div className={styles.orSeparate}>OR</div>
                                            </>
                                        )} */}
                    {shippingRequired && (
                      <CartShippingOptions
                        isValid={res => setIsValid(res)}
                        setShippingAddress={setShippingAddress}
                        setShippingOptions={setShippingOptions}
                      />
                    )}
                    <Cart cartType='full' />
                  </div>
                )}

                {currentStep !== 'cart' && (
                  <>
                    <div className={styles.step}>
                      <div className={styles.stepHeader}>
                        <span
                          className={clsx(styles.stepNumber, styles.completed)}>
                          1
                        </span>
                        <span className={styles.stepTitle}>Cart</span>
                        <span
                          className={`${styles.stepAction} ${styles.ctaStep}`}
                          role='presentation'
                          onClick={() => toggleNextStep('cart')}>
                          Edit Cart
                        </span>
                      </div>
                    </div>

                    {!accountAutomated && (
                      <div
                        className={styles.step}
                        id='section-account'
                        name='section-account'>
                        <div className={styles.stepHeader}>
                          <span
                            className={`${styles.stepNumber} ${
                              currentStep === 'shipping' ||
                              currentStep === 'billing' ||
                              currentStep === 'payment'
                                ? styles.completed
                                : ''
                            }`}>
                            2
                          </span>
                          <span className={styles.stepTitle}>Customer</span>
                          {(currentStep === 'shipping' ||
                            currentStep === 'billing' ||
                            currentStep === 'payment') && (
                            <>
                              <span className={styles.setValue}>
                                {checkoutEmail}
                              </span>
                              {!isLoggedIn && (
                                <span
                                  className={`${styles.stepAction} ${styles.ctaStep}`}
                                  role='presentation'
                                  onClick={() => toggleNextStep('account')}>
                                  Modify
                                </span>
                              )}
                              {isLoggedIn && (
                                <a
                                  className={`${styles.stepAction} link fancy lightLink`}
                                  href='/logout'>
                                  Logout
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        {currentStep === 'account' && (
                          <div className={styles.stepBody}>
                            {!isLoggedIn && (
                              <>
                                {/* <p>To continue as a Guest, please enter your email address below.</p> */}
                                <div
                                  className={`flex-between formField ${styles.guestEmailWrapper}`}>
                                  <div
                                    className={styles.guestEmailInputContainer}>
                                    <input
                                      placeholder='Email'
                                      type='email'
                                      className={`${styles.checkoutEmail}`}
                                      defaultValue={checkoutEmail}
                                      onChange={e => handleGuestEmail(e.target.value)} />
                                    <div className={styles.emailCheckIcon}>
                                      {checkoutEmail && (
                                        <Icon symbol={'checkAlt'}></Icon>
                                      )}
                                    </div>
                                  </div>
                                  <Button
                                    level='primary'
                                    className={'responsiveButton'}
                                    type='button'
                                    disabled={!checkoutEmail}
                                    onClick={() => assignGuestToOrder()}>
                                    Continue as guest
                                  </Button>
                                </div>
                                <div
                                  className={`flex-between ${styles.guestEmailOptions}`}>
                                  {/* <div>
                                                  <Checkbox value={true} label="Subscribe to our Newsletter" />
                                              </div> */}
                                  <div className={styles.guestEmailReminder}>
                                    <span>Already have an account?</span>
                                    <Link to='/login/'>Login</Link>
                                    <span>or</span>
                                    <Link to='/join/'>Join now</Link>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {shippingRequired && (
                      <div
                        className={styles.step}
                        id='section-shipping'
                        name='section-shipping'>
                        <div className={styles.stepHeader}>
                          <span
                            className={`${styles.stepNumber} ${
                              currentStep === 'billing' ||
                              currentStep === 'payment'
                                ? styles.completed
                                : ''
                            }`}>
                            3
                          </span>
                          <span className={styles.stepTitle}>
                            {cartShippingMethod === 'collect'
                              ? 'Pickup'
                              : 'Shipping'}
                          </span>

                          {cartShippingMethod === 'collect' &&
                            (currentStep === 'billing' ||
                              currentStep === 'payment') && (
                              <>
                                <span className={styles.setValue}>
                                  {shippingAddress &&
                                    shippingAddress.first_name}
                                  <br />
                                  {shippingAddress &&
                                    [
                                      shippingAddress.address1,
                                      shippingAddress.city,
                                      shippingAddress.postal_code,
                                      shippingAddress.state_or_province,
                                    ].join(', ')}
                                </span>
                              </>
                            )}
                          {cartShippingMethod !== 'collect' &&
                            (currentStep === 'billing' ||
                              currentStep === 'payment') && (
                              <>
                                <span className={styles.setValue}>
                                  {shippingAddress &&
                                    shippingAddress.first_name}{' '}
                                  {shippingAddress && shippingAddress.last_name}
                                  <br />
                                  {shippingAddress && shippingAddress.address1}
                                </span>
                              </>
                            )}
                          {cartShippingMethod !== 'collect' &&
                            ['billing', 'payment'].indexOf(currentStep) >
                              -1 && (
                              <span
                                className={`${styles.stepAction} ${styles.ctaStep}`}
                                role='presentation'
                                onClick={() => toggleNextStep('shipping')}>
                                Modify
                              </span>
                            )}
                        </div>
                        {currentStep === 'shipping' && (
                          <div className={styles.stepBody}>
                            {accountDetails &&
                              'addresses' in accountDetails &&
                              accountDetails.addresses.length > 0 && (
                                <>
                                  <div
                                    className={`flex-between ${styles.formFields}`}>
                                    <div className='formField'>
                                      <label htmlFor='shipping_preselect_address'>
                                        Select previously used address
                                      </label>
                                      <select
                                        id='shipping_preselect_address'
                                        onBlur={() => {}}
                                        onChange={e =>
                                          setPreviousAddress(
                                            'shipping',
                                            e.target.value
                                          )
                                        }>
                                        <option selected value={''}>
                                          Select an address...
                                        </option>
                                        {accountDetails.addresses.map(
                                          (address, addressIndex) => (
                                            <option
                                              key={addressIndex}
                                              value={address.id}>
                                              {address.address1}
                                              {address.address2 !== ''
                                                ? `, ${address.address2}`
                                                : ''}
                                              , {address.city}{' '}
                                              {address.state_or_province}{' '}
                                              {address.postal_code}{' '}
                                              {address.country}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </>
                              )}
                            <div
                              className={`flex-between g-24 ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_first_name'>
                                  First Name
                                </label>
                                <input
                                  type='text'
                                  id='shipping_first_name'
                                  defaultValue={shippingAddress.first_name}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'first_name',
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                              <div className='formField required'>
                                <label htmlFor='shipping_last_name'>
                                  Last Name
                                </label>
                                <input
                                  type='text'
                                  id='shipping_last_name'
                                  defaultValue={shippingAddress.last_name}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'last_name',
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                            </div>
                            {/* <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField">
                                                                <label htmlFor="shipping_company">Company Name</label>
                                                                <input type="text" id="shipping_company" defaultValue={shippingAddress.company} onChange={(e) => handleShippingAddress('company', e.target.value)} />
                                                            </div>
                                                        </div> */}
                            <div
                              className={`flex-between ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_phone'>
                                  Phone Number
                                </label>
                                <input
                                  type='text'
                                  id='shipping_phone'
                                  // defaultValue={shippingAddress.phone}
                                  value={shippingAddress.phone}
                                  onChange={e => {
                                      if(isNaN(e.target.value) === false) {
                                        handleShippingAddress(
                                          'phone',
                                          e.target.value
                                        )
                                      }
                                    }
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                            </div>
                            {/* {!manualShippingAddress && (
                                                            <div className={`flex-between ${styles.formFields}`}>
                                                                <div className="formField required">
                                                                    <label htmlFor="shipping_address_search">Address Search</label>
                                                                    <span role="presentation" className={`${styles.manualAddressTrigger} link fancy lightLink`} onClick={() => triggerManualAddress('shipping')}>
                                                                        Can't find your address or live outside Australia? Manually enter your address.
                                                                    </span>
                                                                    <input type="text" autoComplete="new-password" id="shipping_address_search" onChange={(e) => searchAddress('shipping', e.target.value)} />
                                                                    {shippingAddressResults && (
                                                                        <div className={styles.addressSearchResults}>
                                                                            {shippingAddressResults.map((address, addressIndex) => {
                                                                                return (
                                                                                    <div key={addressIndex} role="presentation" onClick={() => selectAddress('shipping', address.id)}>
                                                                                        {address.address}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {shippingAddressMessage && (
                                                                        <div></div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )} */}
                            <div
                              className={`flex-between ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_address1'>
                                  Address
                                </label>
                                <input
                                  type='text'
                                  id='shipping_address1'
                                  disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.address1}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'address1',
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                            </div>
                            <div
                              className={`flex-between ${styles.formFields}`}>
                              <div className='formField'>
                                <label htmlFor='shipping_address2'>
                                  Address Line 2
                                </label>
                                <input
                                  type='text'
                                  id='shipping_address2'
                                  disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.address2}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'address2',
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                            </div>
                            <div
                              className={`flex-between g-24 ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_city'>City</label>
                                <input
                                  type='text'
                                  id='shipping_city'
                                  disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.city}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'city',
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                              <div className='formField required'>
                                <label htmlFor='shipping_country_code'>
                                  Country
                                </label>
                                <select
                                  id='shipping_country_code'
                                  disabled={!manualShippingAddress}
                                  defaultValue={
                                    shippingAddress.country_code || 'AU'
                                  }
                                  onBlur={e => {
                                    handleShippingAddress(
                                      'country_code',
                                      e.target.value
                                    );
                                    checkForShippingMethods();
                                  }}
                                  onChange={e => fetchStates(e, 'shipping')}>
                                  <option disabled>Select a country...</option>
                                  {countries.map((country, countryIndex) => (
                                    <option
                                      key={countryIndex}
                                      value={country.node.country_iso2}>
                                      {country.node.country}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div
                              className={`flex-between g-24 ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_state'>State</label>
                                {!isEmpty(shippingCountryStates) && (
                                  <select
                                    id='shipping_state'
                                    disabled={!manualShippingAddress}
                                    defaultValue={
                                      shippingAddress.state_or_province
                                    }
                                    onBlur={e => {
                                      handleShippingAddress(
                                        'state_or_province',
                                        e.target.value
                                      );
                                      checkForShippingMethods();
                                    }}>
                                    <option>Select a state...</option>
                                    {shippingCountryStates.map(
                                      (state, stateIndex) => (
                                        <option
                                          key={stateIndex}
                                          value={state.state}>
                                          {state.state}
                                        </option>
                                      )
                                    )}
                                  </select>
                                )}
                                {isEmpty(shippingCountryStates) && (
                                  <input
                                    type='text'
                                    id='shipping_state'
                                    disabled={!manualShippingAddress}
                                    defaultValue={
                                      shippingAddress.state_or_province
                                    }
                                    onChange={e =>
                                      handleShippingAddress(
                                        'state_or_province',
                                        e.target.value
                                      )
                                    }
                                    onBlur={() => checkForShippingMethods()}
                                  />
                                )}
                                {isFetching && (
                                  <div className={styles.spinnerWrapper}>
                                    <Loader />
                                  </div>
                                )}
                              </div>
                              <div className='formField required'>
                                <label htmlFor='shipping_postal_code'>
                                  Postcode
                                </label>
                                <input
                                  type='text'
                                  id='shipping_postal_code'
                                  disabled={!manualShippingAddress}
                                  defaultValue={shippingAddress.postal_code}
                                  onChange={e => handleShippingAddress('postal_code',e.target.value)}
                                  onBlur={() => checkForShippingMethods()}
                                />
                              </div>
                            </div>
                            <div
                              className={`flex-between ${styles.formFields}`}
                              style={{ display: 'none' }}>
                              <div className='formField'>
                                <label htmlFor='shipping_comments'>
                                  Comments
                                </label>
                                <textarea
                                  id='shipping_comments'
                                  defaultValue={shippingAddress.comments}
                                  onChange={e =>
                                    handleShippingAddress(
                                      'comments',
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={`flex-between ${styles.formFields}`}>
                              <div className='formField required'>
                                <label htmlFor='shipping_methods'>
                                  Shipping methods
                                </label>
                                <span
                                  role='presentation'
                                  className={`${styles.supportFunction} link fancy lightLink`}
                                  onClick={() => checkForShippingMethods()}>
                                  Calculate shipping options
                                </span>
                                <div
                                  id='shipping_methods'
                                  className={`${styles.shippingMethods} ${
                                    shippingOptions.length > 0
                                      ? styles.displayOptions
                                      : ''
                                  }`}>
                                  {shippingOptions.length === 0 &&
                                    !fetchingOptions &&
                                    !fetchedOptions && (
                                      <span>{shippingOptionMessage}</span>
                                    )}
                                  {fetchingOptions && (
                                    <span>
                                      Calculating options - Please wait...
                                    </span>
                                  )}
                                  {shippingOptions.length === 0 &&
                                    !fetchingOptions &&
                                    fetchedOptions && (
                                      <span>
                                        Unfortunately one or more items in your
                                        cart can't be shipped to your location.
                                        Please choose a different delivery
                                        address.
                                      </span>
                                    )}
                                  {shippingOptions.length > 0 &&
                                    !fetchingOptions &&
                                    fetchedOptions && (
                                      <>
                                        {shippingOptions.map(
                                          (option, optionIndex) => (
                                            <div key={optionIndex}>
                                              <Radio
                                                value={option.id}
                                                label={
                                                  <>
                                                    {rewriteShippingOptionLabel(
                                                      option.description,
                                                      true
                                                    )}{' '}
                                                    -{' '}
                                                    <CurrencyFormatter
                                                      amount={option.cost}
                                                      currency='AUD'
                                                    />
                                                  </>
                                                }
                                                name='shipping_method'
                                                action={handleShippingMethod}
                                                isChecked={
                                                  shippingMethod
                                                    ? shippingMethod.id ===
                                                      option.id
                                                    : false
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                        {/* <span className={styles.byline}>
                                                                                Residential address pricing includes applicable carrier surcharges for bulky goods
                                                                            </span> */}
                                      </>
                                    )}
                                  {methodBeingSet && (
                                    <div className={styles.loadingShipping}>
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`flex-between ${styles.formFields}`}>
                              <div className='formField'>
                                <Checkbox
                                  rounded={true}
                                  value={true}
                                  isChecked={
                                    shippingAddress.billingSameAsShipping
                                  }
                                  action={handleShippingToBilling}
                                  label='My billing address is the same as my shipping address'
                                />
                              </div>
                            </div>

                            {/* <Button level="primary" type="button" disabled={methodBeingSet} onClick={() => assignShippingToOrder()} className={`${styles.full} responsiveButton`}>Continue</Button> */}
                            {/* usu-391 */}
                            <Button
                              level='primary'
                              type='button'
                              disabled={
                                methodBeingSet ||
                                (shippingMethod?.cost === undefined &&
                                  cartShippingMethod === 'delivery')
                              }
                              onClick={() => assignShippingToOrder()}
                              className={`${styles.full} responsiveButton`}>
                              Continue
                            </Button>
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      className={styles.step}
                      id='section-billing'
                      name='section-billing'>
                      <div className={styles.stepHeader}>
                        <span
                          className={`${styles.stepNumber} ${
                            currentStep === 'payment' ? styles.completed : ''
                          }`}>
                          {shippingRequired && !accountAutomated
                            ? '4'
                            : (shippingRequired && accountAutomated) ||
                              (!shippingRequired && !accountAutomated)
                            ? '3'
                            : '2'}
                        </span>
                        <span className={styles.stepTitle}>Billing</span>
                        {currentStep === 'payment' && (
                          <>
                            <span className={styles.setValue}>
                              {billingAddress && billingAddress.first_name}{' '}
                              {billingAddress && billingAddress.last_name}
                              <br />
                              {billingAddress && billingAddress.address1}
                            </span>
                            <span
                              className={`${styles.stepAction} ${styles.ctaStep}`}
                              role='presentation'
                              onClick={() => toggleNextStep('billing')}>
                              Modify
                            </span>
                          </>
                        )}
                      </div>
                      {currentStep === 'billing' && (
                        <div className={styles.stepBody}>
                          {accountDetails &&
                            'addresses' in accountDetails &&
                            accountDetails.addresses.length > 0 && (
                              <>
                                <div
                                  className={`flex-between ${styles.formFields}`}>
                                  <div className='formField'>
                                    <label htmlFor='billing_preselect_address'>
                                      Select previously used address
                                    </label>
                                    <select
                                      id='billing_preselect_address'
                                      onBlur={() => {}}
                                      onChange={e =>
                                        setPreviousAddress(
                                          'billing',
                                          e.target.value
                                        )
                                      }>
                                      <option selected value=''>
                                        Select an address...
                                      </option>
                                      {accountDetails.addresses.map(
                                        (address, addressIndex) => (
                                          <option
                                            key={addressIndex}
                                            value={address.id}>
                                            {address.address1}
                                            {address.address2 !== ''
                                              ? `, ${address.address2}`
                                              : ''}
                                            , {address.city}{' '}
                                            {address.state_or_province}{' '}
                                            {address.postal_code}{' '}
                                            {address.country}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                          <div
                            className={`flex-between g-24 ${styles.formFields}`}>
                            <div className='formField required'>
                              <label htmlFor='billing_first_name'>
                                First Name
                              </label>
                              <input
                                type='text'
                                id='billing_first_name'
                                defaultValue={billingAddress.first_name}
                                onChange={e =>
                                  handleBillingAddress(
                                    'first_name',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className='formField required'>
                              <label htmlFor='billing_last_name'>
                                Last Name
                              </label>
                              <input
                                type='text'
                                id='billing_last_name'
                                defaultValue={billingAddress.last_name}
                                onChange={e =>
                                  handleBillingAddress(
                                    'last_name',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField">
                                                            <label htmlFor="billing_company">Company Name</label>
                                                            <input type="text" id="billing_company" defaultValue={billingAddress.company} onChange={(e) => handleBillingAddress('company', e.target.value)} />
                                                        </div>
                                                    </div> */}
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className='formField required'>
                              <label htmlFor='billing_phone'>
                                Phone Number
                              </label>
                              <input
                                type='text'
                                id='billing_phone'
                                // defaultValue={billingAddress.phone}
                                value={billingAddress.phone}
                                onChange={e => {
                                    if(isNaN(e.target.value) === false) {
                                      handleBillingAddress('phone', e.target.value)
                                    }
                                  }

                                }
                              />
                            </div>
                          </div>
                          {/* {!manualBillingAddress && (
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="billing_address_search">Address Search</label>
                                                                <span role="presentation" className={`${styles.manualAddressTrigger} link fancy lightLink`} onClick={() => triggerManualAddress('billing')}>
                                                                    Can't find your address or live outside Australia? Manually enter your address.
                                                                </span>
                                                                <input type="text" autoComplete="new-password" id="billing_address_search" onChange={(e) => searchAddress('billing', e.target.value)} />
                                                                {billingAddressResults && (
                                                                    <div className={styles.addressSearchResults}>
                                                                        {billingAddressResults.map((address, addressIndex) => {
                                                                            return (
                                                                                <div key={addressIndex} role="presentation" onClick={() => selectAddress('billing', address.id)}>
                                                                                    {address.address}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )}
                                                                {billingAddressMessage && (
                                                                    <div></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )} */}
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className='formField required'>
                              <label htmlFor='billing_address1'>Address</label>
                              <input
                                type='text'
                                id='billing_address1'
                                disabled={!manualBillingAddress}
                                defaultValue={billingAddress.address1}
                                onChange={e =>
                                  handleBillingAddress(
                                    'address1',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className={`flex-between ${styles.formFields}`}>
                            <div className='formField'>
                              <label htmlFor='billing_address2'>
                                Address Line 2
                              </label>
                              <input
                                type='text'
                                id='billing_address2'
                                disabled={!manualBillingAddress}
                                defaultValue={billingAddress.address2}
                                onChange={e =>
                                  handleBillingAddress(
                                    'address2',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div
                            className={`flex-between g-24 ${styles.formFields}`}>
                            <div className='formField required'>
                              <label htmlFor='billing_city'>City</label>
                              <input
                                type='text'
                                id='billing_city'
                                disabled={!manualBillingAddress}
                                defaultValue={billingAddress.city}
                                onChange={e =>
                                  handleBillingAddress('city', e.target.value)
                                }
                              />
                            </div>
                            <div className='formField required'>
                              <label htmlFor='billing_country_code'>
                                Country
                              </label>
                              <select
                                id='billing_country_code'
                                disabled={!manualBillingAddress}
                                defaultValue={
                                  billingAddress.country_code || 'AU'
                                }
                                onBlur={e =>
                                  handleBillingAddress(
                                    'country_code',
                                    e.target.value
                                  )
                                }
                                onChange={e => fetchStates(e, 'billing')}>
                                <option disabled>Select a country...</option>
                                {countries.map((country, countryIndex) => (
                                  <option
                                    key={countryIndex}
                                    value={country.node.country_iso2}>
                                    {country.node.country}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div
                            className={`flex-between g-24 ${styles.formFields}`}>
                            <div className='formField required'>
                              <label htmlFor='billing_state'>State</label>
                              {!isEmpty(billingCountryStates) && (
                                <select
                                  id='billing_state'
                                  disabled={!manualBillingAddress}
                                  defaultValue={
                                    billingAddress.state_or_province
                                  }
                                  onBlur={e =>
                                    handleBillingAddress(
                                      'state_or_province',
                                      e.target.value
                                    )
                                  }>
                                  <option>Select a state...</option>
                                  {billingCountryStates.map(
                                    (state, stateIndex) => (
                                      <option
                                        key={stateIndex}
                                        value={state.state}>
                                        {state.state}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                              {isEmpty(billingCountryStates) && (
                                <input
                                  type='text'
                                  id='billing_state'
                                  disabled={!manualBillingAddress}
                                  defaultValue={
                                    billingAddress.state_or_province
                                  }
                                  onChange={e =>
                                    handleBillingAddress(
                                      'state_or_province',
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {isFetching && (
                                <div className={styles.spinnerWrapper}>
                                  <Loader />
                                </div>
                              )}
                            </div>
                            <div className='formField required'>
                              <label htmlFor='billing_postal_code'>
                                Postcode
                              </label>
                              <input
                                type='text'
                                id='billing_postal_code'
                                disabled={!manualBillingAddress}
                                defaultValue={billingAddress.postal_code}
                                //value={billingAddress.postal_code}
                                onChange={e => handleBillingAddress('postal_code',e.target.value)}
                              />
                            </div>
                          </div>
                          <Button
                            level='primary'
                            type='button'
                            disabled={!isBillingAddressValid}
                            onClick={() => assignBillingToOrder()}
                            className={styles.full}>
                            Continue
                          </Button>
                        </div>
                      )}
                    </div>

                    <div
                      className={styles.step}
                      id='section-payment'
                      name='section-payment'>
                      <div className={styles.stepHeader}>
                        <span className={styles.stepNumber}>
                          {shippingRequired && !accountAutomated
                            ? '5'
                            : (shippingRequired && accountAutomated) ||
                              (!shippingRequired && !accountAutomated)
                            ? '4'
                            : '3'}
                        </span>
                        <span className={styles.stepTitle}>Confirm</span>
                      </div>
                      {currentStep === 'payment' && (
                        <div className={styles.stepBody}>
                          <div className={styles.customerSummary}>
                            <strong>Customer: </strong>
                            {checkoutEmail}
                          </div>
                          <div
                            className={`flex-between ${styles.addressSummary}`}>
                            {shippingRequired && (
                              <div className={styles.shippingSummary}>
                                <strong>
                                  {cartShippingMethod === 'collect'
                                    ? 'Collection'
                                    : 'Shipping'}
                                  :
                                </strong>
                                <br />
                                {shippingAddress &&
                                  shippingAddress.first_name}{' '}
                                {shippingAddress && shippingAddress.last_name}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.company &&
                                  shippingAddress.company !== '' && (
                                    <>
                                      {shippingAddress.company}
                                      <br />
                                    </>
                                  )}
                                {shippingAddress && shippingAddress.address1}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.address2 &&
                                  shippingAddress.address2 !== '' && (
                                    <>
                                      {shippingAddress.address2}
                                      <br />
                                    </>
                                  )}
                                {shippingAddress && shippingAddress.city}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.state_or_province}
                                ,{' '}
                                {shippingAddress && shippingAddress.postal_code}
                                <br />
                                {shippingAddress &&
                                  countries &&
                                  countries.find(
                                    country =>
                                      country.node.country_iso2 ===
                                      shippingAddress.country_code
                                  )?.node.country}
                                <br />
                                {shippingAddress && shippingAddress.phone}
                                <br />
                                {shippingAddress &&
                                  shippingAddress.comments &&
                                  shippingAddress.comments !== '' && (
                                    <>
                                      <br />
                                      {shippingAddress.comments}
                                    </>
                                  )}
                              </div>
                            )}
                            <div className={styles.billingSummary}>
                              <strong>Billing:</strong>
                              <br />
                              {billingAddress && billingAddress.first_name}{' '}
                              {billingAddress && billingAddress.last_name}
                              <br />
                              {billingAddress && billingAddress.address1}
                              <br />
                              {billingAddress &&
                                billingAddress.address2 &&
                                billingAddress.address2 !== '' && (
                                  <>
                                    {billingAddress.address2}
                                    <br />
                                  </>
                                )}
                              {billingAddress && billingAddress.city}
                              <br />
                              {billingAddress &&
                                billingAddress.state_or_province}
                              , {billingAddress && billingAddress.postal_code}
                              <br />
                              {billingAddress &&
                                countries &&
                                countries.find(
                                  country =>
                                    country.node.country_iso2 ===
                                    billingAddress.country_code
                                )?.node.country}
                              <br />
                              {billingAddress && billingAddress.phone}
                            </div>
                          </div>
                          {shippingRequired && (
                            <div className={styles.shippingMethodSummary}>
                              <strong>Shipping Method: </strong>

                              {cartShippingMethod === 'collect' && (
                                <>
                                  Store pickup -{' '}
                                  <CurrencyFormatter
                                    amount={0}
                                    currency='AUD'
                                  />
                                </>
                              )}
                              {cartShippingMethod !== 'collect' && (
                                <>
                                  {shippingMethod.description} -{' '}
                                  <CurrencyFormatter
                                    amount={shippingMethod.cost}
                                    currency='AUD'
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {checkoutFailMessage &&
                            checkoutFailMessage.length > 0 && (
                              <div className={styles.errorMessages}>
                                {checkoutFailMessage.map((msg, msgIndex) => (
                                  <span key={msgIndex}>{msg}</span>
                                ))}
                              </div>
                            )}
                          <Button
                            level='primary'
                            type='button'
                            disabled={confirmingCheckoutData}
                            className={`${styles.full} responsiveButton`}
                            onClick={validateCheckoutData}>
                            {confirmingCheckoutData
                              ? 'Please wait...'
                              : (checkOrderValue(checkout) === 0 ? 'Finalise Order' : 'Proceed to Payment')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <CartSummary
                cartType='full'
                nextStepValid={nextStepValid && allProductsAvailable}
                cartStep={cartStep}
                cartFunc={() => {
                  if(expressCheckout === true) {
                    validateCheckoutData();
                  } else {
                    toggleNextStep('account');
                  }
                }}
                editCartFunc={() => toggleNextStep('cart')}
              />
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default ConfirmOrderPage;
