// extracted by mini-css-extract-plugin
export var addressSearchResults = "confirm-order-module--addressSearchResults--767c7";
export var addressSummary = "confirm-order-module--addressSummary--395a1";
export var bcCartEmpty = "confirm-order-module--bcCartEmpty--e2dc6";
export var bcCartTitle = "confirm-order-module--bcCartTitle--e4e2a";
export var bcConfirmOrderContainer = "confirm-order-module--bcConfirmOrderContainer--e758d";
export var billingSummary = "confirm-order-module--billingSummary--3fb0f";
export var breadCrumbsContainer = "confirm-order-module--breadCrumbsContainer--c8e27";
export var byline = "confirm-order-module--byline--dba52";
export var checkoutEmail = "confirm-order-module--checkoutEmail--54e8b";
export var completed = "confirm-order-module--completed--2039f";
export var continueShopping = "confirm-order-module--continueShopping--32c1d";
export var ctaStep = "confirm-order-module--ctaStep--e3a17";
export var customerSummary = "confirm-order-module--customerSummary--5da0a";
export var displayOptions = "confirm-order-module--displayOptions--32a27";
export var emailCheckIcon = "confirm-order-module--emailCheckIcon--45561";
export var errorMessages = "confirm-order-module--errorMessages--6d948";
export var formFields = "confirm-order-module--formFields--1375b";
export var full = "confirm-order-module--full--3c822";
export var guestEmailInputContainer = "confirm-order-module--guestEmailInputContainer--a207c";
export var guestEmailOptions = "confirm-order-module--guestEmailOptions--0939b";
export var guestEmailReminder = "confirm-order-module--guestEmailReminder--45eab";
export var guestEmailWrapper = "confirm-order-module--guestEmailWrapper--b7d31";
export var loadingShipping = "confirm-order-module--loadingShipping--d0f91";
export var manualAddressTrigger = "confirm-order-module--manualAddressTrigger--750fc";
export var orSeparate = "confirm-order-module--orSeparate--c051b";
export var recommendedAddOnsContainer = "confirm-order-module--recommendedAddOnsContainer--33c85";
export var recommendedAddOnsContainerMobile = "confirm-order-module--recommendedAddOnsContainerMobile--43613";
export var setValue = "confirm-order-module--setValue--3033a";
export var shippingMethodSummary = "confirm-order-module--shippingMethodSummary--64847";
export var shippingMethods = "confirm-order-module--shippingMethods--29572";
export var shippingSummary = "confirm-order-module--shippingSummary--6980a";
export var spinnerWrapper = "confirm-order-module--spinnerWrapper--c6f57";
export var step = "confirm-order-module--step--13d54";
export var stepAction = "confirm-order-module--stepAction--8d969";
export var stepBody = "confirm-order-module--stepBody--184ee";
export var stepHeader = "confirm-order-module--stepHeader--982e7";
export var stepNumber = "confirm-order-module--stepNumber--1e4f7";
export var stepTitle = "confirm-order-module--stepTitle--51dc7";
export var supportFunction = "confirm-order-module--supportFunction--cfc3a";
export var title = "confirm-order-module--title--89e9e";