import algoliasearch from 'algoliasearch'
const siteVariables = require('../../../../../variables.json');

const AlgoliaArticleSearch = async (searchTerm, limit = 100, facetFilters = {}) => {
    const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY);
    const index = client.initIndex(siteVariables.ALGOLIA_ARTICLES_INDEX_NAME);

    const responseData = await index.search(searchTerm).then((data) => {
        return data;
    }).catch((err) => {
        console.log(err);
        return undefined;
    })

    return responseData;
}

export default AlgoliaArticleSearch