import React from 'react';
import Dialog from '../../atoms/Dialog/Dialog';

const ReceiptsListDialog = ({ open, title, onClose, receipts }) => {

    const handleClose = () => {
        onClose();
    }
    console.log(receipts);

    return (
        <Dialog
            open={open}
            title={title || 'Receipts List'}
            size='sm'
            onCancel={() => handleClose()}
            cancelBtnText="Close"
            hideBtnOk={true}
            hideBtnClose={true}>
                <table>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Name</th>
                            <th class="text-right">Date Submitted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts && receipts.map((receipt) => (
                            <tr>
                                <td>
                                    <a href={receipt.receipt} target="_blank" rel="noreferrer nofollow">
                                        <img
                                            width={50}
                                            height={50}
                                            alt='Action'
                                            style={{ cursor: 'pointer', marginBottom: 0 }}
                                            src={receipt.receipt}
                                        />
                                    </a>
                                </td>
                                <td>
                                    <a href={receipt.receipt} target="_blank" rel="noreferrer nofollow">{receipt.description}</a>
                                </td>
                                <td class="text-right">{receipt.submittedDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </Dialog>
    )
};

export default ReceiptsListDialog;