import React, { useEffect, useMemo, useState } from 'react';
import { extractDate, transformToEventDate } from '../../../helpers/general';
import { wpApiNew } from '../../../helpers/wordpress';
import { decode } from 'he';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import * as styles from './details.module.css';

const GrantDetails = ({ grant, club, spent, sharedStyles, forApproval }) => {
  const [eventDetail, setEventDetail] = useState(null);
  const [fetchingEvent, setFetchingEvent] = useState(false);
  const maps = {
    funding_type: {
      individual: "Individual USU Discretionary Club Grant",
      group: "USU Discretionary Group Application"
    },
    event_type: {
      offcampus: "Off Campus USU Club event",
      oncampus: "On Campus USU Club event",
      capital: "Capital Expenditure"
    }
  }

  const budget = useMemo(() => {
    try {
      return JSON.parse(grant.additionalInformation.spend_split);
    } catch {
      return {__error: 'Invalid data'}
    }
  }, [grant]);

  useEffect(() => {
    if (grant.associated_event && !eventDetail && !fetchingEvent) {
      setFetchingEvent(true);
      wpApiNew('getEventData', {
        eventId: grant.associated_event.ID,
        fieldSet: 1
      }).then(response => {
        if (String(response.status).startsWith("2") && response.response) {
            const evt = response.response;
            const dates = transformToEventDate(evt.acf.start_date, evt.acf.start_time, evt.acf.end_date, evt.acf.end_time, true);
            const eventTitle = `${decode(evt.title.rendered)} (${dates.join(' - ')})`;
            setEventDetail(eventTitle);
            setFetchingEvent(false);
        } else {
            setEventDetail("Failed to fetch");
            setFetchingEvent(false);
        }
      })
    }
  }, [grant, eventDetail, fetchingEvent]);

  return (
    <section
      className={`${styles.root} ${styles.withBg} ${styles.grantDetails}`}>
      <div>
        <div>
          <ul>
            <li>
              <p>
                <strong>Type</strong>
              </p>
              <p>{grant.type}</p>
            </li>
            <li>
              <p>
                <strong>Status</strong>
              </p>
              <p>{grant.status}</p>
            </li>
            <li>
              <p>
                <strong>Amount Requested</strong>
              </p>
              <p><CurrencyFormatter amount={grant.valueGranted} /></p>
            </li>
            {grant.status === 'Paid' && (
              <>
                <li>
                  <p>
                    <strong>Amount Provided</strong>
                  </p>
                  <p><CurrencyFormatter amount={grant.valueProvided} /></p>
                </li>
                <li>
                  <p>
                    <strong>Amount Remaining</strong>
                  </p>
                  <p>{spent !== null ? (
                    <CurrencyFormatter amount={grant.valueProvided - spent} />
                  ) : (
                    <>Calculating...</>
                  )}
                  </p>
                    
                </li>
              </>
            )}
            {forApproval && (
              <li>
                <p>
                  <strong>Club</strong>
                </p>
                <p>{decode(grant.club)}</p>
              </li>
            )}
            <li>
              <p>
                <strong>Submitted By</strong>
              </p>
              <p>{grant.submittedBy}</p>
            </li>
            <li>
              <p>
                <strong>Date Submitted</strong>
              </p>
              <p>{extractDate(grant.submittedDate)}</p>
            </li>
            {grant.status !== 'Pending' && (
              <>
                <li>
                  <p>
                    <strong>Reviewed By</strong>
                  </p>
                  <p>{grant.reviewedBy ? grant.reviewedBy : 'N/A'}</p>
                </li>
                <li>
                  <p>
                    <strong>Approved By</strong>
                  </p>
                  <p>{grant.approvedBy ? grant.approvedBy : 'N/A'}</p>
                </li>
              </>
            )}
            {grant.status === 'Paid' && (
              <li>
                  <p>
                    <strong>Paid By</strong>
                  </p>
                  <p>{grant.paidBy ? grant.paidBy : 'N/A'}</p>
              </li>
            )}
            {(grant.associated_event) && (
              <li style={{ gridColumn: '1/3' }}>
                <p>
                  <strong>Associated Event</strong>
                </p>
                <p>{eventDetail ? eventDetail : "Fetching event information..."}</p>
              </li>
            )}
            {(grant.type !== 'Discretionary' && grant.reason) && (
              <li style={{ gridColumn: '1/3' }}>
                <p>
                  <strong>Reason</strong>
                </p>
                <p>{grant.reason ? grant.reason : '-'}</p>
              </li>
            )}
            {(grant.type === 'Discretionary' && grant.additionalInformation) && (
              <>
                <li>
                  <p>
                    <strong>Funding applied for</strong>
                  </p>
                  <p>{maps.funding_type[grant.additionalInformation.funding_type]}</p>
                </li>
                <li>
                  <p>
                    <strong>Type of event</strong>
                  </p>
                  <p>{maps.event_type[grant.additionalInformation.event_type]}</p>
                </li>
                {grant.additionalInformation.funding_type === 'group' && (
                  <li style={{ gridColumn: '1/3' }}>
                    <p>
                      <strong>Involved clubs</strong>
                    </p>
                    <p>{grant.additionalInformation.joint_club_list}</p>
                  </li>
                )}
                <li>
                  <p>
                    <strong>Name of event</strong>
                  </p>
                  <p>{grant.additionalInformation.event_name}</p>
                </li>
                <li>
                  <p>
                    <strong>Proposed date of event</strong>
                  </p>
                  <p>{grant.additionalInformation.event_date}</p>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>Description</strong>
                  </p>
                  <p>{grant.additionalInformation.event_description}</p>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>How this event and/or activity is different to what your club usually produces</strong>
                  </p>
                  <p>{grant.additionalInformation.detail_differences}</p>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>How members benefit from this additional funding</strong>
                  </p>
                  <p>{grant.additionalInformation.member_benefits}</p>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>Value this event and/or activity going to add to the C&S Program</strong>
                  </p>
                  <p>{grant.additionalInformation.cs_value_add}</p>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>Estimates for how the grant will be spent</strong>
                  </p>
                  <div className={styles.spendSplit}>
                      <div className='grid grid-50'>
                          <div className='blank'></div>
                          <div className={styles.columnTitle}>Estimated Budget</div>

                          {Object.keys(budget).map((row, rId) => (
                              <React.Fragment key={rId}>
                                  <div className={`${styles.rowTitle}${rId === 0 ? ` ${styles.first}` : ''}${(rId === (Object.keys(budget).length - 1)) ? ` ${styles.last}` : ''}`}>{row.replace('_', ' ')}</div>
                                  <div className={styles.rowValue}><CurrencyFormatter amount={budget[row]} /></div>
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                </li>
                <li style={{ gridColumn: '1/3' }}>
                  <p>
                    <strong>Risk Assessment</strong>
                  </p>
                  <p><a href={grant.additionalInformation.risk_assessment} target="_blank" rel="noreferrer noopener">Download</a></p>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default GrantDetails;

