import { graphql, Link, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Button from '../../../components/atoms/Button/Button';
import Checkbox from '../../../components/atoms/Checkbox/Checkbox';
import Dialog from '../../../components/atoms/Dialog/Dialog';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import Icon from '../../../components/atoms/Icon/Icon';
import Loader from "../../../components/atoms/Loader/Loader";
import SelectField from '../../../components/atoms/SelectField/SelectField';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, getBase64, persistLocation } from '../../../helpers/general';
import { error, success } from '../../../helpers/toast';
import { uploadMedia, wpApiNew, wpAllNew } from '../../../helpers/wordpress';

import * as styles from '../manage-screens.module.css';

const Manage = () => {
    const auth = useContext(AuthContext);
    const [authed, setAuthed] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [groups, setGroups] = useState(null);
    const [folders, setFolders] = useState(null);
    const [tags, setTags] = useState(null);
    const [selectedClubs, setSelectedClubs] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [documents, setDocuments] = useState(null);
    const [editDoc, setEditDoc] = useState(false);
    const [createNew, setCreateNew] = useState(false);
    const [docName, setDocName] = useState('');
    const [currentDoc, setCurrentDoc] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
	const [metaFileName, setMetaFileName] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newFolder, setNewFolder] = useState(false);
    const [workingFolder, setWorkingFolder] = useState(false);
    const [newTag, setNewTag] = useState(false);
    const [workingTag, setWorkingTag] = useState(false);

    const { clubsData, clubGroups, documentFolders, documentTags } = useStaticQuery(graphql`
        query {
            clubsData: allWpClub {
                nodes {
                    id: databaseId,
                    name: title
                }
            }
            clubGroups: allWpClubGrouping {
                nodes {
                  id: databaseId
                  name
                }
            }
            documentFolders: allWpDocumentFolder(sort: {fields: name, order: ASC}) {
                nodes {
                    id: databaseId,
                    name
                    parentId: parentDatabaseId
                }
            }

            documentTags: allWpDocumentTag(sort: {fields: name, order: ASC}) {
                nodes {
                    id: databaseId,
                    name
                }
            }
        }
    `);

    useMemo(() => {
        if (authed === null && get(auth, 'state.userChecked')) {
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
        }
    }, [auth, authed, setAuthed]);

    const determineIcon = (ext) => {
        let icon = 'Other';
        // console.log(ext);
        switch (ext.toLowerCase()) {
            case 'csv':
            case 'xls':
            case 'xlsx':
                icon = 'Xls';
                break;

            case 'doc':
            case 'docx':
            case 'txt':
                icon = 'Doc';
                break;

            case 'png':
            case 'jpg':
            case 'jpeg':
                icon = 'Img';
                break;

            case 'zip':
                icon = 'Zip';
                break;

            case 'pdf':
                icon = 'Pdf';
                break;

            default:
                icon = 'Other';
        }
        // console.log('icon', icon);
        return icon;
    }

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        const documentsData = await wpAllNew('getDocumentsStaffList', {filter: 'all=true', limit: 100});

        if (String(documentsData.status).startsWith('2') && documentsData.response.length > 0) {
            setDocuments(documentsData.response.map(doc => {
                const extension = doc.acf.file.split('.').pop();
                return {
                    id: doc.id,
                    fileType: <span className='icon'><Icon symbol={`file${determineIcon(extension)}`} /></span>,
                    name: doc.title.rendered,
                    modified: doc.modified,
                    url: doc.acf.file,
                    displayFolders: doc.document_folders.map(f => documentFolders?.nodes.find(_f => _f.id === f)?.name),
                    selectedFolders: doc.document_folders,
                    displayTags: doc.document_tags.map(t => documentTags?.nodes.find(_t => _t.id === t)?.name),
                    selectedTags: doc.document_tags,
                    displayClubs: doc.acf.specific_clubs ? doc.acf.specific_clubs.map(c => clubsData?.nodes.find(_c => _c.id === c.ID)?.name) : [],
                    selectedClubs: doc.acf.specific_clubs ? doc.acf.specific_clubs.map(sc => sc.ID) : [],
                    displayGroups: doc.acf.specific_groups ? doc.acf.specific_groups.map(g => clubGroups?.nodes.find(_g => _g.id === g.term_id)?.name) : [],
                    selectedGroups: doc.acf.specific_groups ? doc.acf.specific_groups.map(sc => sc.term_id) : []
                }
            }));
        } else {
        // Failed fetch of club data
        setDocuments(false);
        }
        setLoading(false);
    }, [clubGroups, clubsData, documentFolders, documentTags]);

    useMemo(() => {
        if (documents === null) {
            fetchDocuments();
        }

        if (clubs === null) {
            setClubs(clubsData?.nodes);
        }

        if (groups === null) {
            setGroups(clubGroups?.nodes);
        }

        if (folders === null) {
            setFolders(documentFolders?.nodes);
        }

        if (tags === null) {
            setTags(documentTags?.nodes);
        }
    }, [documents, fetchDocuments, clubsData, clubs, setClubs, clubGroups, groups, setGroups, documentFolders, folders, setFolders, documentTags, tags, setTags]);

    const refresh = () => {
        setDocuments(false);
        fetchDocuments();
    }

    const refreshFolders = async () => {
        const _documentFolders = await wpAllNew('getDocumentFoldersList', {
            callId: 0,
            fieldSet: 0
        });
        if (String(_documentFolders.status).startsWith("2")) {
            const _folders = _documentFolders.response.map(f => ({id: f.id, name: f.name})).sort((a,b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            setFolders(_folders);
        }
    }

    const refreshTags = async () => {
        const _documentTags = await wpAllNew('getDocumentTagsList', {
            callId: 0,
            fieldSet: 0
        });
        if (String(_documentTags.status).startsWith("2")) {
            const _tags = _documentTags.response.map(f => ({id: f.id, name: f.name})).sort((a,b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            setTags(_tags);
        }
    }

    const preview = async data => {
        window.open(data.url);
    };

    const handleFile = async (file, name) => {
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(file.name)[1] || undefined;
        if (ext) {
            const base64File = await getBase64(file);
            const responseFile = await uploadMedia(`${name.toLowerCase().replaceAll(' ', '-')}.${ext}`, base64File, file.type);

            return responseFile;
        }

        return {status: 400, message: 'No file type'};
    }

    const createDocument = async () => {
        const data = {};

        setLoading(true);
        data.status = 'publish';
        data.title = docName;
        data.fields = {};

        if (selectedClubs.length > 0) {
            data.fields.specific_clubs = selectedClubs;
        }

        if (selectedGroups.length > 0) {
            data.fields.specific_groups = selectedGroups;
        }

        if (selectedFolders.length > 0) {
            data.document_folders = selectedFolders;
        }

        if (selectedTags.length > 0) {
            data.document_tags = selectedTags;
        }

        const responseFile = await handleFile(selectedFile, docName);

        if (responseFile.status === 200) {
            data.fields.file = responseFile.response.id;
            data.fields.file_id = responseFile.response.id;
            const response = await wpApiNew('upsertDocumentLibrary', {
                object: data
            });
            if (String(response.status).startsWith('2')) {
                refresh();
                clearDoc();
                success('File uploaded');
            } else {
                error('Failed to add resource');
            }
        } else {
            error('Invalid file');
        }
    }

    const editDocument = async data => {
        setDocName(data.name);
        setCurrentDoc(data.url);
        setSelectedClubs(data.selectedClubs);
        setSelectedGroups(data.selectedGroups);
        setSelectedFolders(data.selectedFolders);
        setSelectedTags(data.selectedTags);
        setEditDoc(data);
    }

    const updateDoc = async () => {
        const data = {};

        setLoading(true);
        data.status = 'publish';
        data.title = docName;
        data.fields = {};

        if (selectedClubs.length > 0) {
            data.fields.specific_clubs = selectedClubs;
        }

        if (selectedGroups.length > 0) {
            data.fields.specific_groups = selectedGroups;
        }

        if (selectedFolders.length > 0) {
            data.document_folders = selectedFolders;
        }

        if (selectedTags.length > 0) {
            data.document_tags = selectedTags;
        }
        
        if (selectedFile) {
            const responseFile = await handleFile(selectedFile, docName);

            if (responseFile.status === 200) {
                data.fields.file = responseFile.response.id;
                data.fields.file_id = responseFile.response.id;
            } else {
                error('Invalid file');
                return false;
            }
        }

        const response = await wpApiNew('upsertDocumentLibrary', {
            documentId: editDoc.id,
            object: data
        });
        if (String(response.status).startsWith('2')) {
            refresh();
            clearDoc();
            success('Resource updated');
        } else {
            error('Failed to update resource');
        }
    }

    const clearDoc = () => {
        setCreateNew(false);
        setDocName('');
        setCurrentDoc(null);
        setEditDoc(false);
        setSelectedFile();
        setMetaFileName(false);
        setSelectedClubs([]);
        setSelectedGroups([]);
        setSelectedFolders([]);
        setSelectedTags([]);
    }

    const deleteDocument = async data => {
        const response = await wpApiNew('deleteDocumentLibrary', {
            documentId: data.id
        });
        // TODO: Delete media item as well to keep media library clean
        if (String(response.status).startsWith('2')) {
            refresh();
            success('File deleted');
        } else {
            error('Failed to delete resource');
        }
    }

    const handleUpload = (e) => {
        if(e === undefined) return;
		setSelectedFile(e.target.files[0]);
        setMetaFileName(e.target.files[0]?.name);
    }

    const addSelective = (id, value) => {
        if (value !== '') {
            const selectData = value.split('_');
            if (selectData[0] === 'club') {
                addClub(selectData[1]);
            }
            if (selectData[0] === 'group') {
                addGroup(selectData[1]);
            }
            document.getElementById(id).value = '';
        }
    }

    const addGroup = (value) => {
        const tempSelectedGroups = [...selectedGroups];
        if (tempSelectedGroups.indexOf(value) === -1) {
            tempSelectedGroups.push(value);
        }
        
        setSelectedGroups(tempSelectedGroups);
    }

    const addClub = (value) => {
        const tempSelectedClubs = [...selectedClubs];
        if (tempSelectedClubs.indexOf(value) === -1) {
            tempSelectedClubs.push(value);
        }
        
        setSelectedClubs(tempSelectedClubs);
    }

    const removeGroup = (index) => {
        const tempSelectedGroups = [...selectedGroups];
        tempSelectedGroups.splice(index, 1);
        setSelectedGroups(tempSelectedGroups);
    }

    const removeClub = (index) => {
        const tempSelectedClubs = [...selectedClubs];
        tempSelectedClubs.splice(index, 1);
        setSelectedClubs(tempSelectedClubs);
    }

    const handleNewFolder = (id, value) => {
        setNewFolder(value);
    }

    const updateFolder = (e) => {
        const _selectedFolders = [...selectedFolders];
        const value = Number(e.target.value);
        if (_selectedFolders.indexOf(value) === -1) {
            _selectedFolders.push(value);
        } else {
            _selectedFolders.splice(_selectedFolders.indexOf(value), 1);
        }
        setSelectedFolders(_selectedFolders);
    }

    const triggerFolder = () => {
        setNewFolder('');
    }

    const addFolder = async () => {
        setWorkingFolder(true);
        const createNew = await wpApiNew('upsertDocumentFolder', {
            object: {name: newFolder}
        });
        if (String(createNew.status).startsWith("2")) {
            setNewFolder(false);
            setWorkingFolder(false);
            refreshFolders();
        } else {
            setWorkingFolder(false);
            // TODO: Handle error
        }
    }

    const handleNewTag = (id, value) => {
        setNewTag(value);
    }

    const updateTag = (e) => {
        const _selectedTags = [...selectedTags];
        const value = Number(e.target.value);
        if (_selectedTags.indexOf(value) === -1) {
            _selectedTags.push(value);
        } else {
            _selectedTags.splice(_selectedTags.indexOf(value), 1);
        }
        setSelectedTags(_selectedTags);
    }

    const triggerTag = () => {
        setNewTag('');
    }

    const addTag = async () => {
        setWorkingTag(true);
        const createNew = await wpApiNew('upsertDocumentTag', {
            object: {name: newTag}
        });
        if (String(createNew.status).startsWith("2")) {
            setNewTag(false);
            setWorkingTag(false);
            refreshTags();
        } else {
            setWorkingTag(false);
            // TODO: Handle error
        }
    }

    const handleBulkActions = (value, selected) => {
        switch (value) {
            case 'delete':
                const deletes = selected.map(async data => {
                    const response = wpApiNew('deleteDocumentLibrary', {
                        documentId: data.id
                    });
                    // TODO: Delete media item as well to keep media library clean
                    if (String(response.status).startsWith('2')) {
                        return true;
                    } else {
                        return false;
                    }
                });

                Promise.all(deletes).then(() => {
                    refresh();
                    success('Files deleted');
                });
                break;

            default:
                break;
        }
    }

    const visibilityList = () => {
        const groupList = groups.map(c => ({text: c.name, value: `group_${c.id}`})).sort((a,b) => a.text > b.text ? 1 : -1);
        const clubList = clubs.map(c => ({text: c.name, value: `club_${c.id}`})).sort((a,b) => a.text > b.text ? 1 : -1);

        return [{group: true, text: "Groups"}, ...groupList, {group: true, text: "Clubs"}, ...clubList]
    }

    return (
        <>
        {authed ? (
            <div className={`${styles.root} ${styles.withBg}`} style={{opacity: loading ? '0.5' : '1'}}>
            {(loading || !documents) ? (
                <>Fetching resources. Please wait...</>
            ) : (
                <>
                    <DataTable
                        tableData={documents}
                        bulkActions={[{ label: 'Bulk delete', key: 'delete' }]}
                        handleBulkAction={handleBulkActions}
                        topActions={[
                            { label: 'Upload new', icon: <Icon symbol="upload" />, event: () => setCreateNew(true) }
                        ]}
                        headingKeys={[
                            { label: 'File Type', data_key: 'fileType' },
                            { label: 'Name', data_key: 'name', sortable: true, searchable: true },
                            { label: 'Folders', data_key: 'displayFolders', searchable: true, filterable: true, format: 'hoverDetail' },
                            { label: 'Tags', data_key: 'displayTags', searchable: true, filterable: true, format: 'hoverDetail' },
                            { label: 'Groups', data_key: 'displayGroups', searchable: true, filterable: true, format: 'hoverDetail' },
                            { label: 'Clubs', data_key: 'displayClubs', searchable: true, filterable: true, format: 'hoverDetail' },
                            { label: 'Updated', data_key: 'modified', sortable: true, format: 'dateTime' },
                        ]}
                        rowActions={[
                            { label: 'Edit', cta: data => editDocument(data) },
                            { label: 'Preview', cta: data => preview(data) },
                            { label: 'Delete', cta: data => deleteDocument(data) },
                        ]}
                        bulkAction={true}
                        showSearch={true}
                    />
                    {loading && <div className={styles.loaderContainer}><Loader /></div>}

                    <Dialog
                        size='sm'
                        title='Edit Resource'
                        okBtnText='Update'
                        onOk={() => updateDoc()}
                        onCancel={() => clearDoc()}
                        open={typeof editDoc === 'object'}>
                            {editDoc && (
                                <div className={styles.dialogContainer}>
                                <div className="formField">
                                    <label htmlFor="name">Resource Name</label>
                                    <input 
                                        type="text" 
                                        id="name" 
                                        value={docName} 
                                        onChange={(e) => setDocName(e.target.value)
                                    } />
                                </div>
                                <div className={styles.currentFile}><a href={currentDoc} target="_blank" rel="noreferrer noopener">View current file</a></div>
                                <div className={styles.filePickerContainer}>
                                    <div className={styles.fileHelperContainer}>
                                        <span className={styles.fileName}>{metaFileName ? metaFileName : 'Replacement File'}</span>
                                        <span className={styles.fileHelper}>Leave empty to retain current file. Allowed files: PDF, DOC(X), TXT, XLS(X), CSV, ZIP, JPG, PNG</span>
                                    </div>
                                    <label className={styles.filePicker} htmlFor='documentFile'>
                                        <input id="documentFile" type="file" name="documentFile" onClick={(e) => console.log(e)} onChange={handleUpload} />
                                        Select File
                                    </label>
                                </div>
                                <div className='grid grid-50'>
                                    <div className={styles.folders}>
                                        <h6>Folders <span>(<Button type="span" link={true} onClick={() => refreshFolders()}>Refresh list</Button>)</span></h6>
                                        <div className={styles.folderList}>
                                            {folders && folders.map((f, fId) => (
                                                <div key={fId} className={styles.folder}>
                                                    <Checkbox
                                                        value={f.id}
                                                        label={f.name}
                                                        action={updateFolder}
                                                        isChecked={selectedFolders.indexOf(f.id) > -1}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.addFolder}>
                                            <Button type="span" link={true} onClick={() => triggerFolder()}>+ Add</Button>
                                            <div className={`${styles.newFolder} ${newFolder !== false ? styles.active : undefined}`}>
                                                <FormInputField
                                                    handleChange={handleNewFolder}
                                                    placeholder="Folder name"
                                                    disabled={workingFolder}
                                                />
                                                <Button type="span" size="tinier" level="primary" disabled={workingFolder} onClick={() => addFolder()}>Add</Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.tags}>
                                        <h6>Tags <span>(<Button type="span" link={true} onClick={() => refreshTags()}>Refresh list</Button>)</span></h6>
                                        <div className={styles.tagList}>
                                            {tags && tags.map((t, tId) => (
                                                <div key={tId} className={styles.tag}>
                                                    <Checkbox
                                                        value={t.id}
                                                        label={t.name}
                                                        action={updateTag}
                                                        isChecked={selectedTags.indexOf(t.id) > -1}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.addTag}>
                                            <Button type="span" link={true} onClick={() => triggerTag()}>+ Add</Button>
                                            <div className={`${styles.newTag} ${newTag !== false ? styles.active : undefined}`}>
                                                <FormInputField
                                                    handleChange={handleNewTag}
                                                    placeholder="Tag name"
                                                    disabled={workingTag}
                                                />
                                                <Button type="span" size="tinier" level="primary" disabled={workingTag} onClick={() => addTag()}>Add</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='formField'>
                                    <label htmlFor="clubs">Resource visibility</label>
                                    <span style={{display: 'block', marginBottom: '10px'}}>If not clubs are selected, all clubs will have visibility of this file.</span>
                                    <SelectField
                                        id="visibilitySelect"
                                        data={visibilityList()}
                                        placeholder="Select one or more groups and clubs"
                                        handleChange={addSelective}
                                    />
                                    <div className={styles.selected}>
                                        {selectedGroups && selectedGroups.map((c, cI) => (
                                            <span key={cI} className={styles.selectedItem} role="presentation" onClick={() => removeGroup(cI)}>{groups.find(cl => cl.id === Number(c))?.name || 'Unknown'}</span>
                                        ))}
                                        {selectedClubs && selectedClubs.map((c, cI) => (
                                            <span key={cI} className={styles.selectedItem} role="presentation" onClick={() => removeClub(cI)}>{clubs.find(cl => cl.id === Number(c))?.name || 'Unknown'}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            )}
                    </Dialog>

                    <Dialog
                        size='sm'
                        title='Upload new resource'
                        okBtnText='Upload'
                        onOk={() => createDocument()}
                        onCancel={() => setCreateNew(false)}
                        open={createNew}
                        hideBtnCancel
                        disabled={ !selectedFile ? true : false}
                    >
                        <div className={styles.dialogContainer}>
                            <div className="formField">
                                <label htmlFor="name">Resource Name</label>
                                <input 
                                    type="text" 
                                    id="name" 
                                    value={docName} 
                                    onChange={(e) => setDocName(e.target.value)
                                } />
                            </div>
                            <div className={styles.filePickerContainer}>
                                <div className={styles.fileHelperContainer}>
                                    <span className={styles.fileName}>{metaFileName ? metaFileName : 'File'}</span>
                                    <span className={styles.fileHelper}>Allowed files: PDF, DOC(X), TXT, XLS(X), CSV, ZIP, JPG, PNG</span>
                                </div>
                                <label className={styles.filePicker} htmlFor='documentFile'>
                                    <input id="documentFile" type="file" name="documentFile" onClick={(e) => console.log(e)} onChange={handleUpload} />
                                    Select File
                                </label>
                            </div>
                            <div className='grid grid-50'>
                                <div className={styles.folders}>
                                    <h6>Folders <span>(<Button type="span" link={true} onClick={() => refreshFolders()}>Refresh list</Button>)</span></h6>
                                    <div className={styles.folderList}>
                                        {folders && folders.map((f, fId) => (
                                            <div key={fId} className={styles.folder}>
                                                <Checkbox
                                                    value={f.id}
                                                    label={f.name}
                                                    action={updateFolder}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.addFolder}>
                                        <Button type="span" link={true} onClick={() => triggerFolder()}>+ Add</Button>
                                        <div className={`${styles.newFolder} ${newFolder !== false ? styles.active : undefined}`}>
                                            <FormInputField
                                                handleChange={handleNewFolder}
                                                placeholder="Folder name"
                                                disabled={workingFolder}
                                            />
                                            <Button type="span" size="tinier" level="primary" disabled={workingFolder} onClick={() => addFolder()}>Add</Button>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tags}>
                                    <h6>Tags <span>(<Button type="span" link={true} onClick={() => refreshTags()}>Refresh list</Button>)</span></h6>
                                    <div className={styles.tagList}>
                                        {tags && tags.map((t, tId) => (
                                            <div key={tId} className={styles.tag}>
                                                <Checkbox
                                                    value={t.id}
                                                    label={t.name}
                                                    action={updateTag}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.addTag}>
                                        <Button type="span" link={true} onClick={() => triggerTag()}>+ Add</Button>
                                        <div className={`${styles.newTag} ${newTag !== false ? styles.active : undefined}`}>
                                            <FormInputField
                                                handleChange={handleNewTag}
                                                placeholder="Tag name"
                                                disabled={workingTag}
                                            />
                                            <Button type="span" size="tinier" level="primary" disabled={workingTag} onClick={() => addTag()}>Add</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='formField'>
                                <label htmlFor="clubs">Resource visibility</label>
                                <span style={{display: 'block', marginBottom: '10px'}}>If not clubs are selected, all clubs will have visibility of this file.</span>
                                <SelectField
                                    id="visibilitySelect"
                                    data={visibilityList()}
                                    placeholder="Select one or more groups and clubs"
                                    handleChange={addSelective}
                                />
                                <div className={styles.selected}>
                                    {selectedGroups && selectedGroups.map((c, cI) => (
                                        <span key={cI} className={styles.selectedItem} role="presentation" onClick={() => removeGroup(cI)}>{groups.find(cl => cl.id === Number(c))?.name || 'Unknown'}</span>
                                    ))}
                                    {selectedClubs && selectedClubs.map((c, cI) => (
                                        <span key={cI} className={styles.selectedItem} role="presentation" onClick={() => removeClub(cI)}>{clubs.find(cl => cl.id === Number(c))?.name || 'Unknown'}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </>
            )}
            </div>
        ) : (
            <div className={styles.root}>
            {/* Fetching data */}
            {authed === null && <div>Fetching data</div>}
            {/* No data found */}
            {authed === false && (
                <div>
                An error occurred. Return back to{' '}
                <Link to='/account/'>
                    <u>dashboard</u>
                </Link>
                </div>
            )}
            </div>
        )}
        </>
    );
};

const USUClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
        metaTitle='Account - USU Management'
        bgRaw
        title='Manage Club Resources'
        breadcrumbTitle='Manage Club Resources'>
        <Manage location={persistedLocation} />
    </AccountPageWrapper>
    )
};

export default USUClubManagementOutput;

