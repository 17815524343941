import React, { useEffect, useState } from 'react'
import * as styles from './WeekdaysSelector.module.css';

const WeekdaysSelector = ({
    id,
    handleChange,
    label,
    values
}) => {

    const [selected, setSelected] = useState(values);

    const weekdays = [
        { text: 'Mon', value: 'mon' },
        { text: 'Tue', value: 'tue' },
        { text: 'Wed', value: 'wed' },
        { text: 'Thu', value: 'thu' },
        { text: 'Fri', value: 'fri' },
        // { text: 'Sat', value: 'sat' },
        // { text: 'Sun', value: 'sun' },
    ];
    
    const handleClick = (day) => {
        if (selected) {
            const exist = selected.indexOf(day.value);
            if (exist > -1) {
                setSelected(selected.filter((key) => key !== day.value));
            } else {
                setSelected([...selected, day.value])
            }
        } else {
            setSelected([day.value])
        }
    }

    useEffect(() => {
        handleChange(selected);
    }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.root} formField`}>
            {label && <label htmlFor={id}>{label}</label>}
            <div className={`${styles.weekSelectors}`}>
                {weekdays && weekdays.map((weekday, index) => (
                    <span role="presentation" className={`${styles.weekdayBox} ${selected && selected.indexOf(weekday.value) > -1 ? styles.active : ''}`} 
                        key={index} 
                        onClick={() => handleClick(weekday)}>{weekday.text}</span>
                ))}
            </div>
        </div>
    )
}

export default WeekdaysSelector