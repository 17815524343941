
import React from 'react'

const InfoSolid = () => (
    <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
  >
    <path
      clipRule="evenodd"
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Z"
      fill="currentColor"
    />
    <rect
      x={7.387}
      y={7.563}
      width={1.313}
      height={3.938}
      rx={0.656}
      fill="white"
    />
    <circle cx={8.045} cy={5.506} r={1.006} fill="white" />
  </svg>
)

export default InfoSolid