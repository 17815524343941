import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '../Button/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton';
import Icon from '../Icon/Icon'
import Typography from '@material-ui/core/Typography';

import * as customStyles from './Dialog.module.css';

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeIconSize: {
        width: '24px',
        height: '24px',
    }
});

const localStyles = {
    // closeButtonContainer: {
    //     display: 'flex',
    //     flexDirection: 'row-reverse',
    // },
    closeButton: {
        color: '#ccc',
        cursor: 'pointer',
    },
    closeIconSize: {
        width: '24px',
        height: '24px',
    },
    dialogContainer: {
        borderRadius: '8px',
        // padding: '40px',
        position: 'relative',
    },
    contentContainer: {
        border: '0',
        padding: 8
    },
    actionContainer : {
        justifyContent: 'center',
    }
}

const MDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <div className={customStyles.titleContainer}>
            <DialogTitle disableTypography className={classes.root} {...other}>
                <Typography style={{fontSize: '48px'}} variant="h6">{children}</Typography>
            </DialogTitle>
            {/* {onClose ? (
                <IconButton aria-label="close" className={`${classes.closeButton} ${customStyles.customCloseButton}`} onClick={onClose}>
                    <Icon symbol="close" />
                </IconButton>
                ) : null} */}
        </div>
    );
});

const DialogMatter = ({
    children,
    title,
    onOk,
    onCancel,
    open,
    bindState = null, // passes: show | hide function
    size = 'md', // 'lg' | 'md' | 'sm' | 'xl' | 'xs'
    okBtnText = 'Ok',
    cancelBtnText = 'Cancel',
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    hideBtnOk = false,
    hideBtnCancel = false,
    hideBtnClose = false,
    dividers=true,
    disabled=false,
    disableAutoClose=false
}) => {

    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (open && setDialogOpen) {
            setDialogOpen(open)
        }
    }, [open, setDialogOpen]);

    const handleCancel = (event, reason) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }
        if (onCancel) {
            onCancel()
        }
        if (bindState) {
            bindState(false)
        }
        setDialogOpen(false)
    }

    const handleOk = () => {
        if (onOk) {
            onOk()
        }
        if(!disableAutoClose) {
            if (bindState) {
                bindState(false)
            }
            setDialogOpen(false)
        }
     
    }

    return (
        <Dialog
            className={customStyles.mainDialog}
            disableEscapeKeyDown={disableEscapeKeyDown}
            keepMounted
            fullWidth
            maxWidth={size}
            aria-labelledby="confirmation-dialog-title"
            open={bindState ? open : dialogOpen}
            PaperProps={{
                style: {
                    ...localStyles.dialogContainer,
                }
            }}
            onClose={(event, reason) => handleCancel(event, reason)}
            >
            {title && (
                <MDialogTitle id="confirmation-dialog-title" onClose={handleCancel}>
                    {title}
                </MDialogTitle>
            )}

            {!hideBtnClose && (
                <div className={customStyles.closeButtonContainer}>
                    <IconButton aria-label="close" style={localStyles.closeButton} onClick={handleCancel}>
                        <div style={localStyles.closeIconSize}>
                            <Icon symbol="close" />
                        </div>
                    </IconButton>
                </div>
            )}

            <DialogContent  className={customStyles.dialogContainer} style={localStyles.contentContainer} dividers={dividers}>
                {children}
            </DialogContent>
            {(!hideBtnOk || !hideBtnCancel) && (
                <DialogActions style={localStyles.actionContainer}>
                    {!hideBtnCancel && (
                        <div className={customStyles.buttonContainer}>
                            <Button fullWidth onClick={handleCancel} level="secondary" size="small" type="span">
                                {cancelBtnText}
                            </Button>
                        </div>
                    )}
                    {!hideBtnOk && (
                        <div className={customStyles.buttonContainer}>
                            <Button disabled={disabled} fullWidth onClick={handleOk} level="primary" size="small" type="span">
                                {okBtnText}
                            </Button>
                        </div>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default DialogMatter