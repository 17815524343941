import React from 'react';
import AfterPay from './suppliers/AfterPay';
import Klarna from './suppliers/Klarna';
import PayPal from './suppliers/PayPal';
import Zip from './suppliers/Zip';

import * as styles from './BNPL.module.css';

const BNPL = ({ product, suppliers }) => {
    if (!product || typeof product !== 'object' || !suppliers || typeof suppliers !== 'object') return null;

    const supplierComponents = {
        afterpay: <AfterPay key="afterpay" product={product} styles={styles} />,
        klarna: <Klarna key="klarna" product={product} styles={styles} />,
        paypal: <PayPal key="paypal" product={product} styles={styles} />,
        zip: <Zip key="zip" product={product} styles={styles} />
    }

    const outputSuppliers = suppliers.map(supplier => supplierComponents[supplier]);

    return (
        <div className={styles.bnplOptions}>
            {outputSuppliers}
        </div>
    )
}

export default BNPL