import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './StoreButton.module.css';

const StoreButton = ({link, icon, title}) => {
  return (
    <div className={styles.store} onClick={()=>window.open(link)} role={'presentation'}>
      <Icon symbol={icon}/>
      <span>{title}</span>
    </div>
  );
};

export default StoreButton;
