import React, { useContext, useMemo, useState } from "react"

import AuthContext from '../context/AuthProvider';
import { getStorage, setStorage, getQuerystringValue } from '../helpers/general'

import Layout from "../components/organisms/Layout/Layout"
import Form from "../components/organisms/LoginForm/LoginForm"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"

import * as styles from './login.module.css'

const LoginPage = () => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const [pageLoaded, setLoaded] = useState(false);
    let redirectTo = '/account';
    const afterAuthCalls = getStorage('_afterAuth');
    let afterAuthCallsObject = {};

    if (afterAuthCalls) {
        afterAuthCallsObject = JSON.parse(afterAuthCalls);
    }

    if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
        redirectTo = getQuerystringValue('redirectTo');
        setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
    }

    
    useMemo(() => {
        if (!pageLoaded && isLoggedIn && typeof window !== 'undefined') {
            window.location = "/account/";
        }
        if (userChecked) {
            setLoaded(true);
        }
    }, [isLoggedIn, pageLoaded, userChecked]);


    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Login" />
            <Container>
                <div className={`${styles.loginContainer}`}>
                    <div className={styles.loginFormHeader}>
                        <h6>Login</h6>
                        <span className={styles.subtitle}>Discover clubs, events, parties and much more.</span>
                    </div>
                    <div className={`${styles.loginForm}`}>
                        {'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist' && (
                            <div className={styles.notice}>
                                An account is required to save a wishlist. Please login or sign up for a free account to save your wishlist.
                            </div>
                        )}
                        <Form />
                    </div>
                </div>
            </Container>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
  export default LoginPage
  