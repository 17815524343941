import React, { useState, useContext } from 'react';
import { bcApi } from '../../../helpers/bigcommerce';
import CartContext from '../../../context/CartProvider';

import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { validateEmail } from '../../../helpers/general';
import Dialog from '../../atoms/Dialog/Dialog';
// import Preview from './Preview'

import * as styles from './PurchaseForm.module.css';
import Checkbox from '../../atoms/Checkbox/Checkbox';

const min = 20;
const max = 200;

const validForm = data => {
  if (!data.your_name) {
    return 'Fist Name is required.';
  }
  if (!data.last_name) {
    return 'Last Name is required.';
  }
  if (!data.recipients_name) {
    return 'Recipients Name is required.';
  }

  if (!data.recipients_email) {
    return 'Your recipients email is required.';
  }
  if (!validateEmail(data.recipients_email)) {
    return 'Invalid recipients email address.';
  }
  if (!data.amount) {
    return 'Amount is required.';
  }
  if (!data.agree) {
    return 'You must agree to the terms and conditions.';
  }
  if (data.amount < min || data.amount > max) {
    return `You must enter a certificate amount between $${min}.00 and $${max}.00`;
  }
};

const PurchaseForm = () => {
  const [values, setValues] = useState({
    your_name: '',
    last_name: '',
    recipients_name: '',
    recipients_email: '',
    amount: '',
    optional_message: ''
  });

  const [agree, setAgree] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false);
  const defaultMessage = { error: '', success: '' };
  const [msg, setMessage] = useState({ ...defaultMessage });

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const cart = useContext(CartContext);

  const onSubmit = () => {
    const validationMsg = validForm({...values, agree : agree});
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    const reqBody = {
      gift_certificates: [
        {
          name: `$${values.amount} Gift Certificate`,
          theme: 'General',
          amount: values.amount,
          quantity: 1,
          sender: {
            name: values.your_name,
            email: values.your_email
          },
          recipient: {
            name: values.recipients_name,
            email: values.recipients_email
          },
          message: values.optional_message
        }
      ]
    };

    bcApi(`carts${typeof cart?.state?.cart?.cartId === 'undefined' ? '' : `/${cart?.state?.cart?.cartId}/items`}`, 'POST', reqBody).then(
      ({ response, status }) => {
        if (status === 404) {
          setMessage({
            error: 'Sorry, something went wrong. Try again later.'
          });
          setDialogOpen(true);
          setValues({
            your_name: '',
            last_name: '',
            recipients_name: '',
            recipients_email: '',
            amount: '',
            optional_message: ''
          });
        } else {
          setValues({
            your_name: '',
            last_name: '',
            recipients_name: '',
            recipients_email: '',
            amount: '',
            optional_message: ''
          });
          window.location.href = `/cart?cache=${Date.now()}`;
        }
      }
    );
  };

  return (
    <div className={styles.purchaseform}>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="your_name"
          type="input"
          labelName="First Name"
          value={values['your_name']}
          handleChange={onHandleChange}
        />

        <FormInputField
          id="last_name"
          type="input"
          labelName="Last name"
          value={values['last_name']}
          handleChange={onHandleChange}
        />
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="recipients_name"
          type="input"
          labelName="Recipients Name"
          value={values['recipients_name']}
          handleChange={onHandleChange}
        />
        <FormInputField
          id="recipients_email"
          type="email"
          labelName="Recipients Email"
          value={values['recipients_email']}
          handleChange={onHandleChange}
        />
      </div>

      <FormInputField
        id="amount"
        type="number"
        labelName="Amount"
        value={values['amount']}
        min={min}
        max={max}
        handleChange={onHandleChange}
      />

      <FormInputField
        id="optional_message"
        type="textarea"
        labelName="Optional Message"
        value={values['optional_message']}
        handleChange={onHandleChange}
      />

      {/* <div className={`${styles.inputGridGap} ${styles.inputGridWrapp}`}>
        <Preview values={values} />
      </div> */}

      <Checkbox
        name="agree"
        label="I agree that gift certificates are nonrefundable"
        action={() => setAgree(!agree)}
        isChecked={agree}
        
        rounded
      />

      <div
        className={`mt-5 flex-left ${styles.submitWrap} ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        {msg.error && <p className={styles.error}>{msg.error}</p>}
        <Button type="span" level="primary" className={'responsiveButton'} onClick={() => onSubmit()}>
          Add to Cart
        </Button>
      </div>

      <Dialog
        hideBtnCancel={true}
        size="sm"
        open={dialogOpen}
        dividers={false}
        hideBtnOk={true}
        onCancel={() => setDialogOpen(false)}
      >
        {msg.success && (
          <p className={`${styles.success} ${styles.dialogContent}`}>
            {msg.success}
          </p>
        )}
        {msg.error && (
          <p className={`${styles.error} ${styles.dialogContent}`}>
            {msg.error}
          </p>
        )}
      </Dialog>
    </div>
  );
};

export default PurchaseForm;
