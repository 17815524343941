import { useContext, useEffect } from "react"
import AuthContext from '../context/AuthProvider';

const Logout = () => {
    const auth = useContext(AuthContext);
    const handleLogout = auth && auth.logout;

    useEffect(() => {
        handleLogout()
    }, [handleLogout])

    return null
}

export default Logout