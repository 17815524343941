import { get } from 'lodash';
import React from 'react';

import Layout1 from './Layouts/Layout1';

const ContentfulBlockFours = props => {

  const { layout } = props;

  const backgroundColor = get(props, 'backgroundColour.hexCode', false);
  const textColor = get(props, 'backgroundColour.supportingColour', false);

  const mainStyles = {};
  if (backgroundColor) {
      mainStyles.backgroundColor = backgroundColor;
      mainStyles.color = textColor;
  }

  // check for example layouts
  switch(layout) {

    // case 'Layout 1':
    //   return <Layout1 {...props} />;

    // case 'Layout 2':
    //   return <Layout2 {...props} />;

    // case 'Layout 3':
    //   return <Layout3 {...props} />;

    default:
      return <Layout1 {...props} mainStyles={mainStyles} />;
  }
};

export default ContentfulBlockFours;
