import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulBlockSideCopy.module.css';

const ContentfulBlockSideCopy = props => {
    const { 
        size,
        title,
        content,
        asset,
        orientation,
        widths
    } = props;

    const backgroundColor = get(props, 'backgroundColour.hexCode', false);
    const textColor = get(props, 'backgroundColour.supportingColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    let configuration = 'even';
    if (widths === 'Larger image section') configuration = 'largerImage';
    if (widths === 'Larger text section') configuration = 'largerText';
    
    return (
        <section className={`${styles.pageSideCopy} ${orientation ? styles[`orientation${orientation.replace('Image ', '')}`] : styles.orientationleft} ${styles[configuration]}`} style={mainStyles}>
            <AosInit>
                <Container size={size} classes={styles.container}>
                    <div className={styles.content}>
                        <h4>{title}</h4>
                        {content && content.raw && (<ContentfulRichText raw={content.raw} />)}
                    </div>
                    <div className={styles.asset}>
                        <img src={asset.file.url} alt={title} />
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockSideCopy;