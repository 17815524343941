import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash"
import { persistLocation } from "../../../../../../helpers/general";
import { authClubExec } from '../../../../../../helpers/wordpress';
import Icon from '../../../../../../components/atoms/Icon/Icon';
import AccountPageWrapper from "../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import GrantsList from '../../../../../../components/molecules/GrantsList/GrantsList';
import AcquittalsList from "../../../../../../components/molecules/AcquittalsList/AcquittalsList";
import Receipts from "../../../../../../components/organisms/Receipts/Receipts";

import * as styles from '../../../usu.module.css';
import * as financeStyles from './details.module.css';

const Finances = ({location}) => {
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);

    useEffect(() => {
        if (isAuthed === null || (isAuthed && club  && club.id !== get(location, 'state.clubId'))) {
          authClubExec(get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, location, club]);

    return (
        <>
            {(isAuthed && club) ? (
                <>
                    {/* <section className={`${styles.root} ${styles.withBg} ${financeStyles.spaceSection} ${financeStyles.overview}`}>
                        <div className="grid grid-50">
                            <div>
                                <h6 style={{position: "relative", textAlign: "center"}}>Current grant total</h6>
                                <span className={financeStyles.spendValue}>$0.00</span>
                            </div>
                            <div>
                                <h6 style={{position: "relative", textAlign: "center"}}>Current total spend</h6>
                                <span className={financeStyles.spendValue}>$0.00</span>
                            </div>
                        </div>
                    </section> */}

                    <section className={`${styles.root} ${styles.withBg} ${financeStyles.spaceSection} ${financeStyles.receipts}`}>
                        <h6 style={{position: "relative"}}>Latest Receipts</h6>
                        <Receipts 
                            clubId={location.state.clubId}
                            type={'latest'}
                            extraTopActions={[
                                {
                                    label: 'Manage Receipts',
                                    icon: <Icon symbol='fileOther' />,
                                    event: () => navigate(`/account/my-usu/clubs/manage/finances/receipts`, {
                                        state: { ...location.state },
                                    }),
                                    }
                            ]}
                        />
                    </section>

                    <section className={`${styles.root} ${styles.withBg} ${financeStyles.spaceSection} ${financeStyles.grants}`}>
                        <h6 style={{position: "relative"}}>Active Grants</h6>
                        <GrantsList
                            location={location}
                            filteredResults={true}
                            extraTopActions={[
                                {
                                    label: 'Manage Grants',
                                    icon: <Icon symbol='fileOther' />,
                                    event: () => navigate(`/account/my-usu/clubs/manage/finances/grants`, {
                                      state: { ...location.state },
                                    }),
                                  }
                            ]}
                        />
                    </section>

                    <section className={`${styles.root} ${styles.withBg} ${financeStyles.spaceSection} ${financeStyles.acquittals}`}>
                        <h6 style={{position: "relative"}}>Pending Acquittals</h6>
                        <AcquittalsList
                            location={location}
                            filteredResults={true}
                            extraTopActions={[
                                {
                                    label: 'Manage Acquittals',
                                    icon: <Icon symbol='fileOther' />,
                                    event: () => navigate(`/account/my-usu/clubs/manage/finances/acquittals`, {
                                    state: { ...location.state },
                                    }),
                                }
                            ]}
                        />
                    </section>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club preferences */}
                    {((isAuthed === null)) && (
                        <div>Authenticating...</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Club Finances" breadcrumbTitle="Club Finances" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <Finances location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput