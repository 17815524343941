import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

// import clubSample from './clubs.json';
import AosInit from '../../atoms/Aos/Aos';
import Button from '../../atoms/Button/Button';

import Container from '../../atoms/Container/Container';
import { useWindowDimensions, slugifyClubCategory } from '../../../helpers/general';

import * as styles from './ClubSelection.module.css';

const ClubSelection = props => {

  const { width } = useWindowDimensions();

  const wpCategoryClubQuery = useStaticQuery(graphql`
  query ccList {
  allWpClubCategory(sort: {order: ASC, fields: name}, limit: 9) {
        edges {
          node {
            name
          }
        }
      }
    }
  `);

  const clubList = wpCategoryClubQuery?.allWpClubCategory?.edges.map((c) => c.node?.name);

  const renderClubRow = () => {

    let clubGroup = [];
    let classIndex = 0;
    for(let x = 0; x < clubList.length; x += 3) {
      
      let classClub = styles.clubRow0;
      if(classIndex === 0) classClub = styles.clubRow0;
      else if(classIndex === 1) classClub = styles.clubRow1;
      else if(classIndex === 2) classClub = styles.clubRow2;
    
      clubGroup.push(
      <div className={`${styles.clubRow} ${classClub}`} key={x}>
        <Link to={`/clubs?category=${slugifyClubCategory(clubList[x])}`}>
          <div className={styles.club}>{clubList[x]}</div>
        </Link>
        {clubList[x + 1] && 
          <Link to={`/clubs?category=${slugifyClubCategory(clubList[x + 1])}`}>
            <div className={styles.club}>{clubList[x + 1]}</div>
          </Link>
          }
        {clubList[x + 2] && <Link to={`/clubs?category=${slugifyClubCategory(clubList[x + 2])}`}>
          <div className={styles.club}>{clubList[x + 2]}</div>
          </Link>}
      </div>);

      classIndex = classIndex + 1;
      if(classIndex === 3) classIndex = 0; // reset to 3
    }

    return clubGroup;
  }

  return (
    <div className={ styles.ClubSelection }>
      <AosInit>
        <div className={styles.headlineContainer}>
          <span className={styles.headline}>Explore over 200 clubs</span>
          <span className={styles.subheading}>What are you interested in?</span>
        </div>
        <Container size={'large'}>
          {renderClubRow()}
        </Container>
        <div className={`${styles.buttonContainer}`}>
          <Button fullWidth={width < 800 ? true : false} type={'a'} href={'/clubs'} level={'primary'}>All Clubs</Button>
        </div>
      </AosInit>
    </div>
  );
};

export default ClubSelection;
