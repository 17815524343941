import React from 'react';
import { returnAlt, returnSrc, mapPaddingValues, normalizeContainerSize } from '../../../helpers/contentful';
import * as styles from './ContentfulBlockVisual.module.css';

import Container from '../../atoms/Container/Container';

const ContentfulBlockVisual = props => {

  const { padding, containerSize, image } = props;

  const imageAlt = returnAlt(image);
  const imageSrc = returnSrc(image);

  const contentStyling = {};
  contentStyling['padding'] = mapPaddingValues(padding);

  return (
    <div className={ styles.ContentfulBlockVisual } style={contentStyling}>
      <Container size={normalizeContainerSize(containerSize)}>
        <img className={styles[`height${containerSize}`]} src={`${imageSrc}?w=1100&h=550&fit=fill`} alt={imageAlt} />
      </Container>
    </div>
  );
};

export default ContentfulBlockVisual;
