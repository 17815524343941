import React from 'react'

const Burger = () => (
  <svg width={34} height={16} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 36 16'>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M33.4 12.256v2.8H.6v-2.8zM33.4.069v2.8H.6v-2.8z" />
    </g>
  </svg>
)

export default Burger