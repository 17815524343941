import React from "react"

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"

const EmailPreferences = () => {
    return (
        <>
            Body
        </>
    )
}

const EmailPreferencesOutput = () => (
    <AccountPageWrapper metaTitle="Account - Email Preferences" title="Email Preferences">
        <EmailPreferences />
    </AccountPageWrapper>
)

export default EmailPreferencesOutput