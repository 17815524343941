import React from 'react'

const Click = () => (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.054 0a.486.486 0 00-.486.486v3.892a.486.486 0 10.973 0V.486A.486.486 0 007.054 0zM2.41 1.923a.483.483 0 00-.342.145.482.482 0 000 .684l2.41 2.41c.19.19.493.19.683 0a.482.482 0 000-.685l-2.41-2.41a.483.483 0 00-.341-.144zm9.288 0a.483.483 0 00-.342.145l-2.41 2.41a.482.482 0 000 .683c.19.19.495.19.685 0l2.41-2.41a.482.482 0 000-.683.483.483 0 00-.343-.145zM.486 6.568a.486.486 0 100 .973h3.877a.486.486 0 100-.973H.486zm6.165.083l4.318 10.855 1.87-3.291L16.624 18 18 16.624l-3.785-3.785 3.29-1.87L6.652 6.65zM4.82 8.802a.483.483 0 00-.342.145l-2.41 2.41a.482.482 0 000 .684c.19.19.495.19.685 0l2.41-2.41a.482.482 0 000-.684.483.483 0 00-.343-.145z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
)

export default Click