import React, { useRef, useState, useEffect, useCallback } from 'react';
// import Link from 'gatsby-link';
// import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia'
import parse from 'html-react-parser';
import Container from '../../atoms/Container/Container'
import Dialog from '../../atoms/Dialog/Dialog';
// import Icon from '../../atoms/Icon/Icon'

import * as styles from './InformationBar.module.css';

const InformationBar = props => {
    const { messages } = props
    const messagesRef = useRef()
    const [initRotate, setInitRotate] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTimeout, setCurrentTimeout] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [dialogContent, setDialogContent] = useState(null);
    
    const rotate = useCallback((index) => {
        const infoDelay = setTimeout(() => {
            const container = messagesRef.current;
            if (container) {
                const messageContainers = Array.from(container.children);
                const currentChild = messageContainers[index];
                let nextIndex = index + 1;
                if (nextIndex >= messageContainers.length) {
                    nextIndex = 0;
                }
                const nextChild = messageContainers[nextIndex];
                messageContainers.map((msg) => {
                    msg.classList.remove(styles.rollout);
                    return true;
                });
                currentChild.classList.remove(styles.active)
                currentChild.classList.add(styles.rollout);
                nextChild.classList.add(styles.active);
                setTimeout(() => { currentChild.classList.remove(styles.rollout); }, 600);
                setCurrentIndex(nextIndex);
                rotate(nextIndex);
            }
        }, 4000);
        setCurrentTimeout(infoDelay);
    }, [messagesRef]);

    useEffect(() => {
        if (messages.length > 1 && !initRotate) {
            setInitRotate(true);
            rotate(currentIndex);
        }
    }, [messages, initRotate, currentIndex, setInitRotate, rotate]);

    const triggerDialog = message => {
        setDialogTitle(parse(message.text));
        setDialogContent(message.content);
        setDialogOpen(true);
    }

    const pause = () => {
        clearTimeout(currentTimeout);
    }

    const restart = () => {
        rotate(currentIndex);
    }

    return (
        <section className={styles.root}>
            <Container size="large">
                {/* <div className={styles.socials}>
                    <NavSocialMedia />
                </div> */}
                <div className={styles.messages} ref={messagesRef} onMouseEnter={() => pause()} onMouseLeave={() => restart()} role="presentation">
                    {messages && messages.length > 0 && (
                        messages.map((message, messageIndex) => {
                            const activeClass = messageIndex === 0 ? styles.active : '';
                            return (
                                <div className={`${styles.message} ${activeClass}${message.modalDetail ? ` ${styles.withDetail}` : ''}`} key={messageIndex}>
                                    {message.link && (
                                        <a href={message.link}>&nbsp;</a>
                                    )}
                                    {message.modalDetail && (
                                        <span className={styles.modalClick} onClick={() => triggerDialog(message)} role="presentation">&nbsp;</span>
                                    )}
                                    {parse(message.text)}
                                </div>
                            )
                        })
                    )}
                </div>
                <Dialog title={dialogTitle} hideBtnOk={true} cancelBtnText='Close' size='lg' open={dialogOpen} onCancel={() => setDialogOpen(false)}>
                    {dialogContent && parse(dialogContent)}
                </Dialog>
                {/* <div className={styles.storeFinder}>
                    <Link to="/find-your-store">
                        <Icon symbol="mapMarker" /> <span className={styles.storeFinderContent}>Find your Store</span><span className={styles.storeFinderArrow} />
                    </Link>
                </div> */}
            </Container>
        </section>
    );
};

export default InformationBar