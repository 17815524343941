import React from 'react'
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContentfulAsset from '../ContentfulAsset/ContentfulAsset';
import ContentfulAssetHyperlink from '../ContentfulAssetHyperlink/ContentfulAssetHyperlink';
import CtbBorder from './CtbBorder/CtbBorder';
import CtbStep from './CtbStep/CtbStep';
import ContentfulEntry from '../ContentfulEntry/ContentfulEntry';

/**
 * Available Blocks:
    DOCUMENT
    PARAGRAPH
    HEADING_1
    HEADING_2
    HEADING_3
    HEADING_4
    HEADING_5
    HEADING_6
    OL_LIST
    UL_LIST
    LIST_ITEM
    HR
    QUOTE
    EMBEDDED_ENTRY
    EMBEDDED_ASSET
    TABLE
    TABLE_ROW
    TABLE_CELL
    TABLE_HEADER_CELL

 * Available Marks:
    BOLD
    ITALIC
    UNDERLINE
    CODE

 * Available Inlines:
    HYPERLINK
    ENTRY_HYPERLINK
    ASSET_HYPERLINK
    EMBEDDED_ENTRY
 */

// const Bold = ({ children }) => <strong>{children}</strong>
// const Italic = ({ children }) => <em>{children}</em>
// const Text = ({ children }) => typeof children[0] === 'string' && children[0].startsWith('##ANCHOR:') ? <div id={children[0].replace('##ANCHOR:', '').trim()} /> : <p>{children}</p>

const Text = ({ children }) => {
  if(typeof children[0][1] === 'string' && children[0][1].startsWith('##BORDER')) {
    return <CtbBorder />
  }

  return <p>{children}</p>
}

const CustomStep = ({children, node}) => {

  if( typeof node.content[0].value === 'string') {
    if(node.content[0].value.startsWith('##STEP')) {
      const processedString = node.content[0].value.split(' ');
      const symbol = processedString[0].split('##STEP')[1];
      processedString.shift();
      return <CtbStep symbol={symbol}><h5>{processedString.join(' ')}</h5></CtbStep>
    }
  }
  return <h5>{children}</h5>
}

// const assetMap = new Map();
const options = {
  // renderMark: {
  //   // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //   // [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  // },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_5]: (node, children) => <CustomStep node={node}>{children}</CustomStep>,
    [INLINES.ASSET_HYPERLINK]: (node, children) => <ContentfulAssetHyperlink node={node}>{children}</ContentfulAssetHyperlink>,
    [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
      return (
        <ContentfulAsset id={node.data.target.sys.id} />
      )
      // find the asset in the assetMap by ID
      // switch (asset.contentType) {
      //   case "video/mp4":
      //     return (
      //       <video width="100%" height="100%" controls>
      //         <source src={asset.url} type="video/mp4" />
      //       </video>
      //     );
      //   case "image/png":
      //     return (
      //       <img
      //         src={asset.url}
      //         height={asset.height}
      //         width={asset.width}
      //         alt={asset.description}
      //       />
      //     );
      //   default:
      //     return "Nothing to see here...";
      // }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, next) => {
      return (
        <ContentfulEntry id={node.data.target.sys.id} />
      )
    }
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  }
}

const ContentfulRichText = (raw) => {
  if (raw) {
    return renderRichText(raw, options)
  }

  return <></>
}

export default ContentfulRichText