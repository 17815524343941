import React from 'react';

const CaretDown = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L6.99527 7L13 1" stroke="black" strokeWidth="1.5" />
  </svg>
);

export default CaretDown;
