import React, {useState, useCallback, useEffect} from 'react';
import { debounce } from 'lodash';

import Icon from '../../atoms/Icon/Icon';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import AlgoliaArticleSearch from '../../molecules/Search/searches/AlgoliaArticleSearch';
import FreshdeskArticleSearchResults from '../../organisms/FreshdeskArticleSearchResults/FreshdeskArticleSearchResults';

import * as styles from './FreshdeskArticleSearch.module.css';
import Loader from '../../atoms/Loader/Loader';

const ArticleSearchResults = props => {
  const { data, term } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState();

  // eslint-disable-next-line
  const handler = useCallback(debounce(async (subject) => {
    if(subject === '') {
        setSearchResults(undefined);
        return
    };
    
    setSearchLoading(true);
    const articleSearch = await AlgoliaArticleSearch(subject);
    setSearchLoading(false);
    if(articleSearch) {
      setSearchResults(articleSearch)
    }
  }, 1500), []);

  // data is flowing from parent component
  useEffect(() => {
    if(data !== undefined) setSearchResults(data);
    if(searchTerm !== undefined) setSearchTerm(term);
  // eslint-disable-next-line
  }, [term, data]);

  return (
    <div className={ styles.root }>
      <div className={styles.inputContainer}>
      <FormInputField
        id="seach"
        type="input"
        labelName="Search"
        value={searchTerm}
        handleChange={(id, value) => {
          setSearchTerm(value);
          handler(value);
        }}
      />
        <div className={styles.iconContainer}>
          {searchLoading === false ? <Icon symbol={'search'}></Icon> : 
          <div className={styles.loaderContainer}>
            <Loader></Loader>
          </div>}
        </div>
      </div>
      <div>
        <FreshdeskArticleSearchResults data={searchResults} />
      </div>
    </div>
  );
};

export default ArticleSearchResults;
