import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../atoms/Button/Button';

// import * as styles from PARENT

const CategoryTree = ({ values, setter, getter, styles }) => {
    const _hash = '#';
    const blockCount = 5;
    const [openItem, setOpenItem] = useState([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const [chunkLevel, setChunkLevel] = useState(1);
    const [displayValues, setDisplayValues] = useState([]);

    const _loopCategories = (_parent_id) => {
        return displayValues?.filter((a) => a.parent_id === _parent_id).map(a => {
            return {
            ...a,
            children: _loopCategories(a.bigcommerce_id)
            }
        });
    }

    useEffect(() => {
        if (values) {
            let increment = 0;
            let maxCount = chunkLevel * blockCount;
            const updated = values.filter(arr => {
                if (increment <= maxCount && arr.parent_id === 0) {
                    increment++;
                }
                if (increment <= maxCount) {
                    return arr;
                }

                return false;
            })
            // const updated = values.slice(0, (chunkLevel * blockCount));
            setDisplayValues(updated);
        }
    }, [values, chunkLevel]);

    const handleMore = (e) => {
        e.preventDefault();
        setChunkLevel(9999); // To show all respectively OLD (chunkLevel + 1)
    };
    
    let bcCategories = _loopCategories(0);
    // children is non existent in object structure
    // bcCategories = bcCategories && bcCategories.length ? bcCategories[0].children?.map(a => ({...a, id: a.bigcommerce_id, key: a.name, url: a.custom_url.url})) : []

    const recursivePath = useCallback((cat_id) => {
        const _existingCat = values?.filter(a => a.bigcommerce_id === cat_id);
        const _recs = _existingCat.length && parseInt(_existingCat[0].parent_id, 10) > 0 ? recursivePath(_existingCat[0].parent_id) : [];
        return [cat_id, ..._recs];
    }, [
        values
    ]);
    
    useEffect(() => {
        if (!firstLoad) {
            setFirstLoad(true)

            if (window.location.pathname !== '') {
                let existingCat = values?.filter(a => a.custom_url && a.custom_url.url === window.location.pathname);
                if (window.location.pathname.slice(-1) !== '/' && existingCat?.length < 1) {
                    existingCat = existingCat && existingCat.length ? existingCat : values?.filter(a => a.custom_url?.url === `${window.location.pathname}/`);
                    window.history.replaceState({}, ``, `${window.location.pathname}/${window.location.search}`)
                }
                if (existingCat && existingCat.length) {
                    setOpenItem(recursivePath(existingCat[0].bigcommerce_id));
                }
            }
        }
    }, [
        firstLoad,
        openItem,
        values,
        setOpenItem,
        recursivePath,
    ]);

    const onParentItem = (e, val) => {
        e.preventDefault();
        setOpenItem(recursivePath(val.bigcommerce_id));
        setter({
            target: {
                name: 'categories',
                value: val.bigcommerce_id,
                dataset: {
                    label: val.name,
                },
                type: 'radio'
            }
        }, null, true);
    };

    const onCheckboxClick = (e) => {
        e.preventDefault();
        setter(e);
    }

    const renderChildren = (value, parent = false) => {
        if (value?.children?.length || parent) {
            return (
                <li key={value?.bigcommerce_id}
                    className={`${styles.categoryItem} ${openItem.indexOf(value.bigcommerce_id) > -1 ? styles.liOpen : ''}`}>
                    <a href={_hash} onClick={(e) => { onParentItem(e, value); }} className={styles.parentLabel}>
                        {value.name}
                        {value.children.length > 0 && (
                            <>
                                <span className="minus"> -</span>
                                <span className="plus"> +</span>
                            </>
                        )}
                    </a>
                    {value.children.length > 0 && (
                        <ul>
                            {value.children.map((_item, _index) => renderChildren(_item))}
                        </ul>
                    )}
                </li>
            )
        } else {
            let isChecked = `categories=${value.bigcommerce_id}` in getter ? true : false;
            if (!isChecked && openItem?.indexOf(value?.bigcommerce_id) > -1) {
                isChecked = true;
            }
            return (
                <li key={value?.bigcommerce_id}>
                    <label>
                        <input type="radio"
                            data-label={value.name}
                            value={value.bigcommerce_id} 
                            name="categories"
                            onChange={(e) => onCheckboxClick(e)} 
                            checked={isChecked} />
                        <span>{value.name}</span>
                    </label>
                </li>
            );
        }
    }

    return (
        <>
        <ul className={styles.categoryList}>
            {bcCategories && bcCategories.map((value) => {
                return renderChildren(value, true)
            })}
        </ul>
        {values && values.length > blockCount && (chunkLevel * blockCount) < values.length && (
            <Button type="a" href="#" link={true} className={styles.moreButton} onClick={handleMore}>
                + More
            </Button>
        )}
        </>
    )
};

export default CategoryTree;