import React from 'react';

const Clock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    viewBox="2.5 2.5 19 19"
    strokeWidth={1.5}
  >
    <path d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" />
  </svg>
);

export default Clock;
