import React from 'react'

import Button from '../../atoms/Button/Button';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import { camelCase } from 'lodash';
import { useWindowDimensions } from '../../../helpers/general';

import * as styles from './ContentfulBlockArticle.module.css';

const ContentfulBlockArticle = props => {
    const { 
        size,
        content,
        maxWidth,
        alignment,
        ctaButton,
        containerSpacing,
        containerAlignment,
        backgroundColor,
        padding,
        paddingBottom,
        paragraphFontSize,
        paragraphFontSizeMobile,
    } = props;

    const { width } = useWindowDimensions();

    let buttonFontSize = ctaButton?.fontSize;
    if(width < 800) buttonFontSize = ctaButton?.fontSizeMobile;

    const mapButtonSize = () => {
        let buttonSize = '';

        if(ctaButton?.size) {
            if(ctaButton?.size === 'Medium') return buttonSize;
            if(ctaButton?.size === 'No Width' || ctaButton?.size === 'Full Width') return camelCase(ctaButton?.size);
            else {
                return ctaButton?.size.toLowerCase(); //smallest small large
            }
        }
        return buttonSize;
    }

    const determineContainerAlignment = () => {
        if(containerAlignment === null || containerAlignment === undefined) return({});
        if(containerAlignment === 'Left') return({});
        if(containerAlignment === 'Center') return({ marginLeft: 'auto', marginRight: 'auto' })
        if(containerAlignment === 'Right') return ({marginLeft: 'auto'})
    }

    const determineFontSize = () => {
        if(paragraphFontSize === null && paragraphFontSizeMobile === null) return '25px';

        if(width > 800 && paragraphFontSize) return paragraphFontSize;
        else if (paragraphFontSizeMobile) return paragraphFontSizeMobile;
        else return '18px';
    }

    const rootProperty = {
        backgroundColor: backgroundColor ? backgroundColor?.hexCode : 'var(--standard-light-grey)'
    }

    const styleProperty = {
        maxWidth: maxWidth,
        textAlign: alignment,
        fontSize: determineFontSize(),
        lineHeight: `calc(${determineFontSize()} + 8px)`,
    }

    const buttonCustomStyling = {
        fontSize: buttonFontSize,
        marginTop: 
        '16px',
    }

    return (
        <section className={`${styles.pageArticle} ${styles[`padding${padding}`]} ${styles[`paddingBottom${paddingBottom}`]}`} style={rootProperty}>
            <AosInit>
                <Container spacing={containerSpacing?.toLowerCase()} size={size?.toLowerCase()}>
                    <div 
                        className={`${styles.textContainer}`}
                        style={{...styleProperty, ...determineContainerAlignment()}}>
                        <ContentfulRichText raw={content.raw} />
                        {ctaButton &&
                            <Button
                                size={mapButtonSize()}
                                inlineStyling={buttonCustomStyling} 
                                href={ctaButton?.link?.url} 
                                type={'a'} 
                                level={'primary'}
                            >
                                {ctaButton.label}
                            </Button>
                        }
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockArticle;