// extracted by mini-css-extract-plugin
export var VTeamEventList = "VTeamEventList-module--VTeamEventList--4a377";
export var buttonContainer = "VTeamEventList-module--buttonContainer--d13cc";
export var calendarIconContainer = "VTeamEventList-module--calendarIconContainer--1a3c1";
export var contentContainer = "VTeamEventList-module--contentContainer--04d2d";
export var descriptionToggle = "VTeamEventList-module--descriptionToggle--1f373";
export var error = "VTeamEventList-module--error--e22b0";
export var errorRosterfy = "VTeamEventList-module--errorRosterfy--ca98b";
export var eventAttribute = "VTeamEventList-module--eventAttribute--55279";
export var eventDescription = "VTeamEventList-module--eventDescription--de873";
export var eventDescriptionIcon = "VTeamEventList-module--eventDescriptionIcon--3e684";
export var eventGrid = "VTeamEventList-module--eventGrid--54ce1";
export var iconContainer = "VTeamEventList-module--iconContainer--19fb4";
export var imageContainer = "VTeamEventList-module--imageContainer--c73ad";
export var rotate = "VTeamEventList-module--rotate--782b8";
export var shiftButtonLabel = "VTeamEventList-module--shiftButtonLabel--ec35c";
export var shiftContainer = "VTeamEventList-module--shiftContainer--2420a";
export var shiftDialogContainer = "VTeamEventList-module--shiftDialogContainer--25fe5";
export var shiftGrid = "VTeamEventList-module--shiftGrid--e0a93";
export var shiftGridHeader = "VTeamEventList-module--shiftGridHeader--c065f";
export var shiftListContainer = "VTeamEventList-module--shiftListContainer--fb81c";
export var shiftLoaderContainer = "VTeamEventList-module--shiftLoaderContainer--66afc";
export var title = "VTeamEventList-module--title--248da";
export var vTeamCard = "VTeamEventList-module--vTeamCard--d40de";