import React from 'react'
import { get } from 'lodash';

// import VideosGrid from '../../organisms/VideosGrid/VideosGrid';
// import InstagramFeed from '../../organisms/InstagramFeed/InstagramFeed';
import AosInit from '../../atoms/Aos/Aos';
import ClubList from '../../organisms/ClubList/ClubList';
import ClubSelection from '../../organisms/ClubSelection/ClubSelection';
import FeaturedEvents from '../../organisms/FeaturedEvents/FeaturedEvents';
import EatsList from '../../organisms/EatsList/EatsList';
import FeaturedProducts from '../../organisms/FeaturedProducts/FeaturedProducts';
import ProductCategorySlider from '../../organisms/ProductCategorySlider/ProductCategorySlider';
import HealthServicesList from '../../organisms/HealthServicesList/HealthServicesList';
// import StudentBoardSlider from '../../organisms/StudentBoardSlider/StudentBoardSlider';
// import RevuesList from '../../organisms/RevuesList/RevuesList';

const ContentfulBlockCodedComponent = props => {
    const component = get(props, 'component');
    switch (component) {

        case 'Club List':
            return <ClubList size={props.containerSize} />;

        case 'Club Selection':
            return <ClubSelection/>

        case 'Eats List':
            return <EatsList />

        case 'Services List':
            return <HealthServicesList />

        case 'Featured Events':
            return <AosInit><FeaturedEvents /></AosInit>

        case 'Featured Products':
            return <FeaturedProducts size={props.containerSize}/>

        case 'Product Category Slider':
            return <AosInit><ProductCategorySlider size={props.containerSize} /></AosInit>
        
        // case 'Student Board':
        //     return <StudentBoardSlider />
        
        // case 'Revues List':
        //     return <RevuesList />

        default:
            return <>Component not found: {component}</>;
    }
}

export default ContentfulBlockCodedComponent;