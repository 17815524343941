import React from 'react';

import Attribute from '../../molecules/Attribute/Attribute';

import * as styles from './AttributeGrid.module.css';

const AttributeGrid = (props) => {
  return (
    <div className={styles.root}>
      <Attribute icon={'delivery'} title={'free delivery worldwide'} subtitle={'Click to learn more'} />
      <Attribute icon={'cycle'} title={'returns'} subtitle={'Return goods in 30 days'} />
      <Attribute icon={'creditcard'} title={'secured payment'} subtitle={'Shop safely'} />
    </div>
  );
};

export default AttributeGrid;
