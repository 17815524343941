import React, { useMemo } from 'react';
import { Link } from 'gatsby';

import Icon from '../Icon/Icon';

import * as styles from './Pagination.module.css';

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const Pagination = ({ pageContext, pathPrefix }) => {
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    pageNumber,
    queryStringPaging,
    onClick
  } = pageContext;

  const [btns, showFirstDot, showLastDot] = useMemo(() => {
    const pages = new Array(numberOfPages).fill(true).map((_, i) => i);
    if (numberOfPages <= 3) {
      return [pages];
    }

    let start = pageNumber > 0 ? pageNumber - 1 : 0;
    const end = pageNumber > 0 ? pageNumber + 2 : pageNumber + 3;

    if (numberOfPages < start + 3) {
      start = numberOfPages - 3;
    }

    return [pages.slice(start, end), start > 0, end < numberOfPages];
  }, [numberOfPages, pageNumber]);

  const getPaginationQry = (path, _page) => {
    let _url = path.indexOf('https') > -1 ? (new URL(path)).search.slice(1) : path;
    _url = _url.indexOf('?') > -1 ? _url.split('?')[1] : _url;
    const urlParams = new URLSearchParams(_url);

    let _params = [];
    for(var key of urlParams.keys()) {
      if (key !== undefined && key !== 'undefined' && urlParams.get(key) !== '') {
        _params[key] = encodeURIComponent(urlParams.get(key));
      }
    }
    _params['page'] = _page;

    let _pathname = path.indexOf('https') > -1 ? (new URL(path)).pathname : path;
    _pathname = _pathname.indexOf('?') > -1 ? _pathname.split('?')[0] : _pathname;
    return `${_pathname}?${Object.keys(_params).map(a => `${a}=${_params[a]}`).join('&')}`
  }

  return (
    <nav className={styles.pagination} role="navigation">
      <ul className={styles.navbar}>
        {previousPagePath && (
          <li>
            <Link to={previousPagePath} onClick={onClick ? (e) => onClick(e, pageNumber) : undefined} rel="prev">
              <span data-button className={styles.arrow} role="presentation">
                <Icon symbol="arrowLeft" />
              </span>
            </Link>
          </li>
        )}

        {showFirstDot && previousPagePath && (
          <>
            <li>
              <Link
                to={`${queryStringPaging ? getPaginationQry(pathPrefix, 1) : pathPrefix}`}
                onClick={onClick ? (e) => onClick(e, 1) : undefined}>
                <span data-button role="presentation">
                  1
                </span>
              </Link>
            </li> 
            {btns[0] > 1 && (
              <li>
                <span>...</span>
              </li>
            )}
          </>
        )}

        {btns.map(btnIndx => (
          <li
            key={btnIndx}
            className={btnIndx === pageNumber ? styles.active : ''}
          >
            {btnIndx === 0 ? (
              <Link
                to={`${queryStringPaging ? getPaginationQry(pathPrefix, 1) : pathPrefix}`}
                onClick={onClick ? (e) => onClick(e, btnIndx + 1) : undefined}>
                <span data-button role="presentation">
                  {btnIndx + 1}
                </span>
              </Link>
            ) : (
              <Link
                to={`${queryStringPaging ? getPaginationQry(pathPrefix, btnIndx + 1) : pathPrefix}${queryStringPaging ? '' : `/page/${btnIndx + 1}`}`}
                onClick={onClick ? (e) => onClick(e, btnIndx + 1) : undefined}>
                <span data-button role="presentation">
                  {btnIndx + 1}
                </span>
              </Link>
            )}
          </li>
        ))}

        {showLastDot && nextPagePath && (
          <>
            {btns[2] < (numberOfPages - 2) && (
              <li>
                <span>...</span>
              </li>
            )}
            <li>
              <Link
                to={`${queryStringPaging ? getPaginationQry(pathPrefix, numberOfPages) : pathPrefix}${queryStringPaging ? '' : `/page/${numberOfPages}`}`}
                onClick={onClick ? (e) => onClick(e, numberOfPages - 1) : undefined}>
                <span data-button role="presentation">
                  {numberOfPages}
                </span>
              </Link>
            </li> 
          </>
        )}
        {nextPagePath && (
          <li>
            <Link to={nextPagePath} onClick={onClick ? (e) => onClick(e, pageNumber + 2) : undefined} rel="next">
              <span data-button className={styles.arrow} role="presentation">
                <Icon symbol="arrowRight" />
              </span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
