import React from 'react';

const Swatch = ({ fill }) => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='13'
      cy='13'
      r='12'
      stroke='#eee'
      strokeWidth='1'
      fill={'currentColor'}
    />
    <path
      d='M26 10L19.2588 18L15 14.4416'
      stroke='#FFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      transform='translate(-7.5 0)'
    />
  </svg>
);

export default Swatch;
