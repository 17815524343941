import React from 'react';

const ListEdit = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 10H14V12H3V10ZM3 8H14V6H3V8ZM3 16H10V14H3V16ZM18.01 12.87L19.42 11.46L21.54 13.58L20.13 14.99L18.01 12.87ZM17.3 13.58L12 18.88V21H14.12L19.42 15.7L17.3 13.58V13.58Z'
        fill='black'
      />
    </svg>
  );
};

export default ListEdit;
