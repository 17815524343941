import React from 'react';
import CartItem from './CartItem';

const CustomItems = ({
  items,
  productInfo,
  cartType,
  removeItemFromCart,
  updatingItem,
  updateCartItemQuantity,
  currency
}) => {
  return items.map(item => (
    <CartItem
      key={item.id}
      item={item}
      productInfo={productInfo}
      itemType="custom"
      currency={currency}
      cartType={cartType}
      updatingItem={updatingItem}
      removeItemFromCart={removeItemFromCart}
      updateCartItemQuantity={updateCartItemQuantity}
    />
  ));
};

export default CustomItems;
