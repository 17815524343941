import React from 'react'

const Caret = () => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 24 24'
  >
    <path d="m11.998 17 7-8h-14l7 8Z" fill="currentColor" />
  </svg>
)

export default Caret