import React from 'react'

const MasterCardLogo = () => (
    <svg
      width={40} height={28}
      viewBox="0 0 43 30"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="prefix__a" d="M0 29.563V0h43v29.563z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.031 28.89a3.327 3.327 0 01-3.36-3.359v-21.5a3.327 3.327 0 013.36-3.36H38.97a3.327 3.327 0 013.36 3.36v21.5a3.327 3.327 0 01-3.36 3.36H4.03z"
          fill="#F2F2F2"
          fillRule="nonzero"
        />
        <g>
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M38.969 0H4.03C1.881 0 0 1.747 0 4.031v21.5c0 2.285 1.747 4.032 4.031 4.032H38.97c2.284 0 4.031-1.747 4.031-4.032v-21.5C43 1.747 41.253 0 38.969 0m0 1.344a2.695 2.695 0 012.687 2.687v21.5a2.695 2.695 0 01-2.687 2.688H4.03a2.695 2.695 0 01-2.687-2.688v-21.5A2.695 2.695 0 014.03 1.344H38.97"
            fill="#D8D8D8"
            fillRule="nonzero"
            mask="url(#prefix__b)"
          />
        </g>
        <g transform="translate(9.053 6.79)" fillRule="nonzero">
          <circle fill="#CE4549" cx={7.921} cy={7.921} r={7.921} />
          <circle fill="#FFAA2E" cx={16.974} cy={7.921} r={7.921} />
          <path
            d="M15.842 7.921c0-2.716-1.358-5.092-3.395-6.45-2.036 1.471-3.394 3.847-3.394 6.45s1.358 5.092 3.394 6.45c2.037-1.358 3.395-3.734 3.395-6.45z"
            fill="#F73"
          />
          <path fill="#FFF" d="M4.526 6.79h15.842v2.263H4.526z" />
        </g>
      </g>
    </svg>
)

export default MasterCardLogo