// extracted by mini-css-extract-plugin
export var center = "Tabs-module--center--188f8";
export var dropDownContainer = "Tabs-module--dropDownContainer--3a08e";
export var full = "Tabs-module--full--bfc5f";
export var left = "Tabs-module--left--9e48b";
export var noWrap = "Tabs-module--noWrap--60b50";
export var root = "Tabs-module--root--8d7b7";
export var rootDropDown = "Tabs-module--rootDropDown--1fa9b";
export var tabDropDown = "Tabs-module--tabDropDown--ed950";
export var tabDropDownActive = "Tabs-module--tabDropDownActive--4e692";
export var tabList = "Tabs-module--tabList--d2031";