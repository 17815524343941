import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';
import SelectField from '../../atoms/SelectField/SelectField';
import * as styles from './LoginForm.module.css';

const LoginForm = () => {
  const auth = useContext(AuthContext);
  const smsCheck = auth && auth.smsCheck;
  const emailCheck = auth && auth.emailCheck;
  const validateCode = auth && auth.validateCode;
  const handleLogin = auth && auth.login;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [method2FA, setMethod2FA] = useState('email');
  const [remember, setRemember] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [expectedCode, setExpectedCode] = useState(null);
  const [timeToGo, setTimeToGo] = useState(90);
  // const [retryAuth, setRetryAuth] = useState(false);
  const [countdownObj, setCountdown] = useState(null);

  const [error, setError] = useState({
    type: 'warn',
    message: '',
  });

  const methods = [
    {value: 'email', text: 'Email'},
    {value: 'sms', text: 'SMS'}
  ];

  const startCountDown = () => {
    let _timeToGo = 90;
    let countdown = null;

    setTimeToGo(_timeToGo);

    countdown = setInterval(() => {
      _timeToGo = _timeToGo - 1;
      setTimeToGo(_timeToGo);
      if (_timeToGo <= 0) {
        clearInterval(countdown);
        setExpectedCode(null);
        setTimeToGo(90);
        // setRetryAuth(true);
        setError({
          type: 'error',
          message: 'Code expired. Please login again.',
        });
      }
    }, 1000);
    setCountdown(countdown);
  }

  const attemptLogin = (e, reattempt = false) => {
    e && e.preventDefault();
    if (expectedCode === null || reattempt) {
      let loginError = '';
      setError({
        type: '',
        message: '',
      });
      if (!email) {
        loginError = 'Mobile number is required';
      }
      if (!password) {
        loginError = 'USU number is required';
      }
      if (loginError) {
        setError({
          type: 'warn',
          message: loginError,
        });
        return;
      }

      // setExpectedCode(123456);
      // startCountDown();
      
      setLoggingIn(true);
      if ((reattempt && reattempt === 'sms') || (!reattempt && method2FA === 'sms')) {
        smsCheck(email, password).then(response => {
          if (String(response.status).startsWith("2") && 'code' in response.response) {
            setExpectedCode(response.response.code);
            startCountDown();
          } else {
            setError({
              type: 'error',
              message: 'An unexpected error occurred',
            });
          }
        }).catch(error => {
          if ('message' in error.response) {
            setError({
              type: 'error',
              message: error.response.message,
            });
          } else {
            setError({
              type: 'error',
              message: 'An unexpected error occurred',
            });
          }
        })
        .finally(() => setLoggingIn(false));
      } else {
        emailCheck(email, password).then(response => {
          if (String(response.status).startsWith("2") && 'code' in response.response) {
            setExpectedCode(response.response.code);
            startCountDown();
          } else {
            setError({
              type: 'error',
              message: 'An unexpected error occurred',
            });
          }
        }).catch(error => {
          if ('message' in error.response) {
            setError({
              type: 'error',
              message: error.response.message,
            });
          } else {
            setError({
              type: 'error',
              message: 'An unexpected error occurred',
            });
          }
        })
        .finally(() => setLoggingIn(false));
      }
    }
  };

  const confirmLogin = (code) => {
    if (code.length === 6) {
      validateCode(code, expectedCode).then(result => {
        if (String(result.status).startsWith('2') && result.response.match === true) {
          setLoggingIn(true);
          clearInterval(countdownObj);
          handleLogin(email, password, remember)
            .then(() => {
              // console.log(response);
            })
            .catch(error => {
              if ('message' in error.response) {
                setError({
                  type: 'error',
                  message: error.response.message,
                });
              } else {
                setError({
                  type: 'error',
                  message: 'An unexpected error occurred',
                });
              }
            })
            .finally(() => setLoggingIn(false));
        } else {
          setError({
            type: 'error',
            message: 'Code does not match the expected'
          });
        }
      }).catch(error => {
        if ('message' in error.response) {
          setError({
            type: 'error',
            message: error.response.message,
          });
        } else {
          setError({
            type: 'error',
            message: 'Code does not match the expected'
          });
        }
      })
    } else {
      setError({
        type: '',
        message: '',
      });
    }
  }

  const onChangeCheckbox = e => {
    return setRemember(e.target.checked);
  };

  const handleMethods = (id, value) => {
    setMethod2FA(value);
  }

  const switchMethods = (method) => {
    setMethod2FA(method);
    clearInterval(countdownObj);
    // setExpectedCode(null);
    setTimeToGo(90);
    attemptLogin(null, method);
  }

  return (
    <form onSubmit={e => attemptLogin(e)} className={styles.loginForm}>
      {error.message && <MessageAlert type={error.type} message={error.message} />}

      {!expectedCode && (
        <>
          <div className='formField'>
            <input
              className={styles.loginFields}
              type='text'
              value={email}
              placeholder='Mobile number'
              name='email'
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='formField'>
            <input
              className={styles.loginFields}
              type='text'
              value={password}
              placeholder='SID or Email or USU Member No'
              name='password'
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {/* {retryAuth && ( */}
            <div className='formField'>
              <label htmlFor="method2fa">Code delivery method</label>
              <SelectField
                id="method2fa"
                value={method2FA}
                data={methods}
                handleChange={handleMethods}
              />
            </div>
          {/* )} */}
          <div className={`${styles.formRemember} formField`}>
            <Checkbox
              name='rememberme'
              label='Remember me'
              action={e => onChangeCheckbox(e)}
              isChecked={remember}
            />
            <Link to='/forgot/'>Having trouble logging in?</Link>
          </div>
          <div className={`${styles.formActions} formField`}>
            <Button
              disabled={loggingIn}
              level='primary'
              type='buttonSubmit'
              className={'responsiveButton'}>
              {loggingIn ? 'Logging in...' : 'Login'}
            </Button>
          </div>
          <div className={styles.formFooter}>
            <span>Don't have an account? </span>
            <Link to={'/join'}>Join USU</Link>
          </div>
        </>
      )}

      {expectedCode && (
        <>
          <p className='center' style={{fontSize: '16px', lineHeight: '20px'}}>Please enter the 6 digit code that has been sent via {method2FA === 'sms' && (<>SMS to the mobile number</>)}{method2FA === 'email' && (<>email to the email address</>)} registered on the account.</p>
          <p className='center' style={{color: '#D4302F', fontSize: '16px', fontWeight: '700'}}>Time remaining: {timeToGo}s</p>
          {(timeToGo < 45 && method2FA === 'email') && (
            <p className='center' style={{fontSize: '16px', lineHeight: '20px'}}><Button type="span" link={true} onClick={() => switchMethods('sms')}>Trouble receiving your email? Try via SMS instead.</Button></p>
          )}
          {(timeToGo < 45 && method2FA === 'sms') && (
            <p className='center' style={{fontSize: '16px', lineHeight: '20px'}}><Button type="span" link={true} onClick={() => switchMethods('email')}>Trouble receiving your sms? Try via email instead.</Button></p>
          )}
          <div className={`formField ${styles.loginDigits}`}>
            <input className={styles.loginFields} disabled={loggingIn} type="text" onChange={(e) => confirmLogin(e.target.value)} />
            {loggingIn && (<div className='center'>Please wait...</div>)}
          </div>
        </>
      )}
    </form>
  );
};

export default LoginForm;

