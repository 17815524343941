import React, { useMemo } from 'react';
import Tabs from '../../molecules/Tabs/Tabs';

import * as styles from './MyUsuTab.module.css';

const subMenuData = [
  {
    itemKey: 'member',
    label: 'MEMBERSHIP',
    uri: '/account/'
  },
  {
    itemKey: 'address',
    label: 'ADDRESSES',
    uri: '/account/my-usu/addresses'
  },
  {
    itemKey: 'clubs',
    label: 'CLUBS',
    uri: '/account/my-usu/clubs'
  },
  {
    itemKey: 'events',
    label: 'MY EVENTS',
    uri: '/account/my-usu/events'
  },
  {
    itemKey: 'settings',
    label: 'UPDATE DETAILS',
    uri: '/account/my-usu/settings'
  },


];

const MyUsuTab = ({
  children,
  pageId
}) => {
  const subMenu = useMemo(() => {
    const items = subMenuData?.map(item => ({
      uri: item.uri,
      label: item.label,
      itemKey: item.itemKey,
      active: item.itemKey === pageId
    }));
    return items;
  }, [pageId]);

  return (
    <div className={styles.myUsuTabContainer}>
      <Tabs items={subMenu} noWrap={false} align="left" isDropDown/>
      {children}
    </div>
  );
};

export default MyUsuTab;
