import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../../atoms/Button/Button';

import Container from '../../atoms/Container/Container';

import EventCard from '../../molecules/EventCard/EventCard';

import * as styles from './FeaturedEvents.module.css';

const FeaturedEvents = props => {
  /* TODO: Needs to be updated to include the ability to first select featured events in Wordpress and then filter by that selection here */
  const featuredEventsData = useStaticQuery(graphql`
    query {
      allWpEvent(sort: { fields: eventFieldsSide___startDate, order: ASC }) {
        nodes {
          id
          status
          uri
          slug
          title
          featuredImage {
            node {
              publicUrl
              sourceUrl
              localFile {
                childImageSharp {
                  fixed(
                    width: 570
                    height: 570
                    quality: 75
                    cropFocus: CENTER
                  ) {
                    src
                  }
                }
              }
            }
          }
          eventFieldsSide {
            startDate
            endDate
            endTime
            startTime
            club {
              ... on WpClub {
                id
                clubFieldsSide {
                  logo {
                    sourceUrl
                    localFile {
                      childImageSharp {
                        fixed(
                          width: 570
                          height: 570
                          quality: 75
                          cropFocus: CENTER
                        ) {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const featuredEvents = (featuredEventsData.allWpEvent.nodes || []).map(
    event => event
  );

  const padFigure = figure => {
    return String(figure).padStart(2, '0');
  };

  const futureEvents = featuredEvents
    .filter(e => {
      const currentDay = padFigure(new Date().getDate());
      const currentMonth = padFigure(new Date().getMonth());
      const currentYear = new Date().getFullYear();
      const current = Number(`${currentYear}${currentMonth}${currentDay}`);
      const endDate = e.eventFieldsSide.endDate;
      const dateEndObject = endDate ? new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')) : null;
      const end = dateEndObject ? Number(`${dateEndObject.getFullYear()}${padFigure(dateEndObject.getMonth())}${padFigure(dateEndObject.getDate())}`) : null;
      
      if (end > current) {
        return e;
      }

      if (end === current) {
        const currentTime = Number(
          `${new Date().getHours()}${new Date().getMinutes()}`
        );
        const endTime = e.eventFieldsSide.endTime;

        if (endTime === '') return e;

        const timeEndObject = new Date(
          `${endDate.replace(
            /([0-9]{4})([0-9]{2})([0-9]{2})/,
            '$1-$2-$3'
          )} ${endTime}`
        );
        const checkTime = Number(
          `${timeEndObject.getHours()}${padFigure(timeEndObject.getMinutes())}`
        );

        if (checkTime > currentTime) {
          return e;
        }
      }

      return false;
    })
    .slice(0, 4);

  return (
    <div className={styles.FeaturedEvents}>
      <Container size={'large'}>
        <h2>What's On</h2>
        <div className={styles.featuredEventContainer}>
          {futureEvents.map((event, eventIndex) => (
            <EventCard eventObj={event} key={eventIndex} />
          ))}
        </div>
        <div className={styles.actionContainer}>
          <Button type={'a'} href={'/events'} level={'primary'}>
            Full Calendar
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default FeaturedEvents;

