import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

const ContentfulContext = createContext();

export const ContentfulProvider = ({ children }) => {
    const [headerMenu, setHeaderMenu] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const [navigationMenu, setNavigationMenu] = useState([]);
    const [secondaryFooterMenu, setSecondaryFooterMenu] = useState([]);
    const [socialLinks, setSocialLinks] = useState({});
    const [storeLinks, setStoreLinks] = useState({});
    const [seoData, setSeoData] = useState({});
    const [bannerLinks, setBannerLinks] = useState('');
    const [membershipNotice, setMembershipNotice] = useState('');
    const [clubMediaDefaults, setClubMediaDefaults] = useState({});
    
    const contentfulData = useStaticQuery(graphql`
        query {
            siteConfig: allContentfulSiteConfig(filter: {name: {eq: "Current"}}) {
                nodes {
                  headerMenu {
                    menuItems {
                      label
                      url
                      target
                      location
                    }
                  }
                  navigationMenu {
                    menuGroups {
                      menuItems {
                        label
                        url
                        target
                        location
                      }
                    }
                  }
                  footerMenu {
                    menuItems {
                      label
                      url
                      target
                      location
                    }
                    menuGroups {
                      label
                      menuItems{
                        label
                        url
                        target
                        location
                      }
                    }
                  }
                  secondaryFooterMenu {
                    menuItems {
                      label
                      url
                      target
                      location
                    }
                  }
                  instagramLink
                  youTubeLink
                  facebookLink
                  appStoreLink
                  tiktokLink
                  playStoreLink
                  bannerMessage
                  bannerLinks {
                    message
                    link {
                      label
                      location
                      url
                      target
                    }
                  }
                  defaultSocialShareImage {
                    file {
                      url
                    }
                  }
                  defaultMetaDescription {
                    defaultMetaDescription
                  }
                  streetAddress
                  suburb
                  postcode
                  country
                  state,
                  city
                  membershipNotice {
                    raw
                  }
                  clubBanner {
                    file {
                      url
                    }
                  }
                  clubThumbnail {
                    file {
                      url
                    }
                  }
                }
            }
        }
    `);

    const fetchData = useCallback(() => {
        if (contentfulData && contentfulData.siteConfig.nodes.length > 0) {
            setHeaderMenu(get(contentfulData, 'siteConfig.nodes[0].headerMenu.menuItems', []));
            setFooterMenu(get(contentfulData, 'siteConfig.nodes[0].footerMenu', []));
            setNavigationMenu(get(contentfulData, 'siteConfig.nodes[0].navigationMenu', []));
            setSecondaryFooterMenu(get(contentfulData, 'siteConfig.nodes[0].secondaryFooterMenu.menuItems', []));
            setSocialLinks({
                instagram: get(contentfulData, 'siteConfig.nodes[0].instagramLink', ''),
                facebook: get(contentfulData, 'siteConfig.nodes[0].facebookLink', ''),
                youTube: get(contentfulData, 'siteConfig.nodes[0].youTubeLink', ''),
                tiktok: get(contentfulData, 'siteConfig.nodes[0].tiktokLink', ''),
            });
            setStoreLinks({
              appStore: get(contentfulData, 'siteConfig.nodes[0].appStoreLink', ''),
              playStore: get(contentfulData, 'siteConfig.nodes[0].playStoreLink', ''),
            })
            setBannerLinks(get(contentfulData, 'siteConfig.nodes[0].bannerLinks', ''));
            setSeoData({
                ogImage: get(contentfulData, 'siteConfig.nodes[0].defaultSocialShareImage.file.url', ''),
                metaDescription: get(contentfulData, 'siteConfig.nodes[0].defaultMetaDescription.defaultMetaDescription', ''),
                streetAddress: get(contentfulData, 'siteConfig.nodes[0].streetAddress', ''),
                suburb: get(contentfulData, 'siteConfig.nodes[0].suburb', ''),
                postcode: get(contentfulData, 'siteConfig.nodes[0].postcode', ''),
                country: get(contentfulData, 'siteConfig.nodes[0].country', ''),
                state: get(contentfulData, 'siteConfig.nodes[0].state', ''),
                city: get(contentfulData, 'siteConfig.nodes[0].city', ''),
            })
            setMembershipNotice(get(contentfulData, 'siteConfig.nodes[0].membershipNotice', ''))
            setClubMediaDefaults({
              'banner': get(contentfulData, 'siteConfig.nodes[0].clubBanner.file.url', null),
              'thumbnail': get(contentfulData, 'siteConfig.nodes[0].clubThumbnail.file.url', null)
            })
        }
    }, [contentfulData]);

    useEffect(() => fetchData(), [fetchData]);

    return (
        <ContentfulContext.Provider value={{
            headerMenu,
            footerMenu,
            navigationMenu,
            secondaryFooterMenu,
            socialLinks,
            storeLinks,
            seoData,
            bannerLinks,
            membershipNotice,
            clubMediaDefaults
        }}>{children}</ContentfulContext.Provider>
    );
};

export default ContentfulContext;