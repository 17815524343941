/**
 * Install Algolia to system
 * 
 * 1. Install packages to packages/web folder: 
 *      npm i algoliasearch gatsby-plugin-algolia
 * 
 * 2. Add environment variables:
 *      ALGOLIA_APP_ID=XXX
 *      ALGOLIA_API_KEY=XXX
 *      ALGOLIA_INDEX_NAME=XXX
 * 
 * 3. Add the following to the top of `gatsby-config.js` above the module.exports:
        const fs = require('fs');

        const AlgoliaQuery = `{
            products: allBigCommerceProducts {
                nodes {
                    id
                    entityId: bigcommerce_id
                    objectID: bigcommerce_id
                    name
                    sku
                    price
                    calculated_price
                    sale_price
                    brand_id
                    description
                    inventory_level
                    inventory_tracking
                    custom_url {
                        url
                        is_customized
                    }
                    images {
                        url_standard
                        url_thumbnail
                        url_zoom
                        id
                        description
                        image_file
                        is_thumbnail
                    }
                    categories
                    variants {
                        product_id
                        id
                        sku
                    }
                    custom_fields {
                        name
                        value
                    }
                }
            }
            
            brands: allBigCommerceBrands {
                edges {
                    node {
                        name
                        id
                        bigcommerce_id
                    }
                }
            }

            categories: allBigCommerceCategories {
                edges {
                    node {
                        bigcommerce_id
                        name
                        image_url
                        description
                        parent_id
                        custom_url {
                        url
                        is_customized
                        }
                    }
                }
            }
        }`;

        const queries = [
            {
                query: AlgoliaQuery,
                transformer: async ({ data }) => {
                    let _json = data.products.nodes;
                    // create list of filters
                    let _filterName = [];

                    const dataBrands = data.brands.edges.map(c => c.node)
                    const dataCategories = data.categories.edges.map(c => c.node)

                    const _result = _json.map((a) => {
                        const __customFields = a.custom_fields;
                        const __filterName = __customFields.filter(b => b.name === 'Filter Name').map(b => b.value);

                        try {
                            _filterName = [..._filterName, ...__filterName];
                        } catch (_e) {
                            console.log(_e);
                        }

                        return {
                            id: a.id,
                            entityId: a.entityId,
                            objectID: a.entityId,
                            name: a.name,
                            sku: a.sku,
                            price: a.price,
                            calculated_price: a.calculated_price,
                            sale_price: a.sale_price,
                            brand_id: a.brand_id,
                            brand_name: dataBrands.filter(b => b.bigcommerce_id === a.brand_id).length ? dataBrands.filter(b => b.bigcommerce_id === a.brand_id)[0].name : '',
                            description: a.description,
                            custom_url: a.custom_url,
                            defaultImage: {
                                url: a.images.filter(b => b.is_thumbnail).length ? a.images.filter(b => b.is_thumbnail)[0].url_thumbnail : ''
                            },
                            images: a.images,
                            filterName: __filterName,
                            variants: a.variants,
                            product_categories: dataCategories.filter(b => a.categories.indexOf(b.bigcommerce_id) > -1).map(b => {
                                return {
                                    entityId: b.bigcommerce_id,
                                    name: b.name,
                                    path: b.custom_url.url
                                }
                            }),
                            customFields: a.custom_fields,
                        };
                    });

                    try {
                        _filterName = [...new Set(_filterName)];
                        
                        const _filters = [
                            {
                                label: 'Filter Name',
                                field: 'filterName',
                                type: 'multiselect',
                                collapse: 'expanded',
                                values: _filterName.map(b => ({key: b, label: b}))
                            },
                        ];
                        fs.writeFile('./src/components/organisms/Filters/FilterData.json', JSON.stringify(_filters), err => {
                            if (err) {
                                console.error(err)
                                return
                            }
                        });
                    } catch (_e) {
                        console.log(_e);
                    }

                    return _result;
                }, // optional
                // indexName: 'index name to target', // overrides main index name, optional
                settings: {
                    // optional, any index settings
                    // Note: by supplying settings, you will overwrite all existing settings on the index
                    attributesForFaceting: [
                        'filterName', 'product_categories.name', 'product_categories.entityId'
                    ],
                    searchableAttributes: [
                        'name', 'description', 'product_categories.name', 'filterName'
                    ]
                },
                // matchFields: ['slug', 'modified'], // Array<String> overrides main match fields, optional
            },
        ];

 * 4. Update the `AlgoliaQuery` with the data that needs to be searchable and returned in the results
 * 5. Add the following to the `plugins` in `gatsby-config.js` at the very end. Most important that its last in the list:
        {
            // This plugin must be placed last in your list of plugins to ensure that it can query all the GraphQL data
            resolve: `gatsby-plugin-algolia`,
            options: {
                appId: process.env.ALGOLIA_APP_ID,
                // Use Admin API key without GATSBY_ prefix, so that the key isn't exposed in the application
                // Tip: use Search API key with GATSBY_ prefix to access the service from within components
                apiKey: process.env.ALGOLIA_API_KEY,
                indexName: process.env.ALGOLIA_INDEX_NAME, // for all queries
                queries,
                chunkSize: 10000, // default: 1000
                settings: {
                // optional, any index settings
                // Note: by supplying settings, you will overwrite all existing settings on the index
                },
                enablePartialUpdates: false, // default: false
                matchFields: ['objectID', 'slug', 'modified'], // Array<String> default: ['modified']
                concurrentQueries: false, // default: true
                skipIndexing: false, // default: false, useful for e.g. preview deploys or local development
                continueOnFailure: false // default: false, don't fail the build if algolia indexing fails
            },
        },

 * 6. Enable this component in the `ProcessSearch.js` component.
 */

        import { getStorage, setStorage } from '../../../../helpers/general'
        import algoliasearch from 'algoliasearch'
        const siteVariables = require('../../../../../variables.json');
        // require('dotenv').config()
        
        const AlgoliaSearch = async (searchTerm, limit = 100, facetFilters = {}) => {
            const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
            const sortBy = {
                newest: false,
                A2Z: 'asc(name)',
                Z2A: 'desc(name)',
                PriceAsc: 'asc(calculated_price)',
                PriceDesc: 'desc(calculated_price)',
            };

            // console.log(searchTerm);
            // For fast and accurate sorting, create replicas on the primary index and modify the sort setting in the Algolia admin for that replica
            const replicaIndexes = { 
                newest: siteVariables.ALGOLIA_INDEX_NAME,
                A2Z: siteVariables.ALGOLIA_INDEX_NAME_ASC, // siteVariables.ALGOLIA_INDEX_NAME_ASC,
                Z2A: siteVariables.ALGOLIA_INDEX_NAME_DESC, // siteVariables.ALGOLIA_INDEX_NAME_DESC,
                PriceAsc: siteVariables.ALGOLIA_INDEX_PRICE_ASC, // siteVariables.ALGOLIA_INDEX_PRICE_ASC,
                PriceDesc: siteVariables.ALGOLIA_INDEX_PRICE_DESC, // siteVariables.ALGOLIA_INDEX_PRICE_DESC,
            };

            const indices = [
                {name: siteVariables.ALGOLIA_ALL_INDEX_NAME},
                {name: siteVariables.ALGOLIA_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_CLUBS_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_EVENTS_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_ARTICLES_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_POSTS_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_EATS_INDEX_NAME},
                // {name: siteVariables.ALGOLIA_PAGES_INDEX_NAME},
            ];

            const sortIndex = typeof facetFilters.sortBy !== 'undefined' && replicaIndexes[facetFilters.sortBy] ? replicaIndexes[facetFilters.sortBy] : siteVariables.ALGOLIA_INDEX_NAME;
            // const index = client.initIndex(
            //     typeof facetFilters.sortBy !== 'undefined' && replicaIndexes[facetFilters.sortBy] ? replicaIndexes[facetFilters.sortBy] : siteVariables.ALGOLIA_INDEX_NAME
            // )

            const gradCategory = 84;
            let theCategory = null
            if (facetFilters && 'filters' in facetFilters) {
                const arrayFacetFilters = facetFilters.filters.replace('(', '').replace(')', '').split(':')
                const catIndex = arrayFacetFilters.indexOf('product_categories.entityId');
                const catValueIndex = catIndex + 1;
                theCategory = typeof arrayFacetFilters[catValueIndex] !== 'undefined' ? Number(arrayFacetFilters[catValueIndex].replace(`'`, '').replace(`'`, '')) : null;            }    

            const jammSortBy = getStorage('jamm_sortby');
            if (!(jammSortBy === null && facetFilters.sortBy === 'newest')) {
                if (typeof facetFilters.sortBy !== 'undefined' && Object.keys(sortBy).indexOf(facetFilters.sortBy) > -1 && facetFilters.sortBy !== jammSortBy) {
                    setStorage('jamm_sortby', facetFilters.sortBy);
                    const currentSort = facetFilters.sortBy;
                    const _urlParams = new URLSearchParams(window.location.search);
                    _urlParams.set('sortBy', currentSort);
                    window.history.replaceState({}, ``, `${window.location.pathname}?${_urlParams.toString()}`);
                    setTimeout(() => {
                        window.location.reload()
                    }, 800)
                }
            }
        
            if (typeof facetFilters.sortBy !== 'undefined') {
                delete facetFilters['sortBy'];
            }

            const formatUrl = (url, type) => {
                const prepPath = (path) => `${!path.startsWith('/') ? '/' : ''}${path}`
                switch (type) {
                    case 'product':
                        return `/product${prepPath(url)}`;

                    case 'article':
                        return `/article${prepPath(url)}`;

                    case 'post':
                        return `/blog${prepPath(url)}`;

                    case 'eat':
                        return `/food-drink${prepPath(url)}`;

                    case 'services':
                        return `/health-services${prepPath(url)}`;

                    default:
                        return prepPath(url);
                }
            }
        
            return new Promise((res) => {
                // let currentPage = 0;
                // if (typeof facetFilters.page !== 'undefined') {
                //     currentPage = facetFilters['page'];
                //     delete facetFilters['page'];
                // }

                let rangeNumFilters = [];
                if (typeof facetFilters.rangeNumFilters !== 'undefined') {
                    rangeNumFilters = facetFilters['rangeNumFilters'];
                    delete facetFilters['rangeNumFilters'];
                }

                // Create array of indices to use for multiple queries below
                searchTerm = searchTerm.trim(); // To disable empty spaces for search analytics
                // console.log('analytics', searchTerm || (searchTerm && searchTerm.length > 0) ? true : false)
                const queries = [];
                indices.forEach(val => {
                    let index = val.name;
                    if (index === siteVariables.ALGOLIA_INDEX_NAME && typeof sortIndex !== undefined) {
                        index = sortIndex;
                    }
                    queries.push({
                        indexName: index,
                        query: searchTerm,
                        params: {
                            hitsPerPage: limit,
                            numericFilters: [...rangeNumFilters],
                            ...facetFilters,
                            analytics: searchTerm || (searchTerm && searchTerm.length > 0) ? true : false
                        }
                    })
                });

                // console.log(queries);
        
                client.multipleQueries(queries).then(response => {
                    if (response) {
                        const defaultTotals = {
                            totalItems: 0,
                            totalPages: 0,
                            currentPage: 0,
                            perPage: 0
                        }
                        const products = [],
                            clubs = [],
                            events = [],
                            articles = [],
                            eats = [],
                            posts = [],
                            pages = [],
                            merge = [],
                            all = [],
                            
                            totals = {
                                products: {...defaultTotals},
                                clubs: {...defaultTotals},
                                events: {...defaultTotals},
                                articles: {...defaultTotals},
                                eats: {...defaultTotals},
                                posts: {...defaultTotals},
                                pages: {...defaultTotals},
                                merge: {...defaultTotals},
                                all: {...defaultTotals},
                            };

                        response.results.forEach(resp => {
                            if (resp.nbHits > 0 && 'hits' in resp) {
                                switch(resp.index){
                                    case siteVariables.ALGOLIA_INDEX_NAME:
                                    case siteVariables.ALGOLIA_INDEX_NAME_ASC:
                                    case siteVariables.ALGOLIA_INDEX_NAME_DESC:
                                    case siteVariables.ALGOLIA_INDEX_PRICE_ASC:
                                    case siteVariables.ALGOLIA_INDEX_PRICE_DESC:
                                    
                                        totals.products.totalItems = totals.products.totalItems + resp.nbHits;
                                        totals.products.totalPages = totals.products.totalPages + resp.nbPages;
                                        totals.products.currentPage = resp.page;
                                        totals.products.perPage =resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_CLUBS_INDEX_NAME:
                                    
                                        totals.clubs.totalItems = totals.clubs.totalItems + resp.nbHits;
                                        totals.clubs.totalPages = totals.clubs.totalPages + resp.nbPages;
                                        totals.clubs.currentPage = resp.page;
                                        totals.clubs.perPage = resp.hitsPerPage;
                                        
                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_EVENTS_INDEX_NAME:
                                    
                                        totals.events.totalItems = totals.events.totalItems + resp.nbHits;
                                        totals.events.totalPages = totals.events.totalPages + resp.nbPages;
                                        totals.events.currentPage = resp.page;
                                        totals.events.perPage = resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_ARTICLES_INDEX_NAME:
                                    
                                        totals.articles.totalItems = totals.articles.totalItems + resp.nbHits;
                                        totals.articles.totalPages = totals.articles.totalPages + resp.nbPages;
                                        totals.articles.currentPage = resp.page;
                                        totals.articles.perPage = resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_POSTS_INDEX_NAME:
                                    
                                        totals.posts.totalItems = totals.posts.totalItems + resp.nbHits;
                                        totals.posts.totalPages = totals.posts.totalPages + resp.nbPages;
                                        totals.posts.currentPage = resp.page;
                                        totals.posts.perPage = resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_EATS_INDEX_NAME:
                                    
                                        totals.eats.totalItems = totals.eats.totalItems + resp.nbHits;
                                        totals.eats.totalPages = totals.eats.totalPages + resp.nbPages;
                                        totals.eats.currentPage = resp.page;
                                        totals.eats.perPage = resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_PAGES_INDEX_NAME:
                                   
                                        totals.pages.totalItems = totals.pages.totalItems + resp.nbHits;
                                        totals.pages.totalPages = totals.pages.totalPages + resp.nbPages;
                                        totals.pages.currentPage = resp.page;
                                        totals.pages.perPage = resp.hitsPerPage;

                                        totals.merge.totalItems = totals.merge.totalItems + resp.nbHits;
                                        totals.merge.totalPages = totals.merge.totalPages + resp.nbPages;
                                        totals.merge.currentPage = resp.page;
                                        totals.merge.perPage = resp.hitsPerPage;
                                    break;

                                    case siteVariables.ALGOLIA_ALL_INDEX_NAME:
                                    
                                        totals.all.totalItems = totals.all.totalItems + resp.nbHits;
                                        totals.all.totalPages = totals.all.totalPages + resp.nbPages;
                                        totals.all.currentPage = resp.page;
                                        totals.all.perPage = resp.hitsPerPage;
                                    break;

                                    default:
                                }

                                /* Loop products per index */
                                resp.hits.map(hit => {
                                    switch(resp.index){
                                        case siteVariables.ALGOLIA_INDEX_NAME:
                                        case siteVariables.ALGOLIA_INDEX_NAME_ASC:
                                        case siteVariables.ALGOLIA_INDEX_NAME_DESC:
                                        case siteVariables.ALGOLIA_INDEX_PRICE_ASC:
                                        case siteVariables.ALGOLIA_INDEX_PRICE_DESC:
                                        // To filter out the graddie items to show when not in graddie category
                                        if (hit.product_categories.length < 2 && theCategory !== gradCategory) {
                                            if (hit.product_categories.filter((p) => p.entityId === gradCategory).length > 0) {
                                                break;
                                            };
                                        }
                                        hit.search_item_type = "product";
                                        hit.d_link = `/product${hit.custom_url.url}`;
                                        hit.d_image = hit.defaultImage?.url;
                                        hit.d_excerpt = hit.description;
                                        products.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_CLUBS_INDEX_NAME:
                                        hit.search_item_type = "clubs";
                                        hit.d_link = hit.uri;
                                        hit.d_image = hit.clubFieldsSide?.logo?.sourceUrl;
                                        hit.d_excerpt = hit.excerpt;
                                        clubs.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_EVENTS_INDEX_NAME:
                                        hit.search_item_type = "events";
                                        hit.d_link = hit.uri;
                                        hit.d_image = hit.featuredImage?.node?.sourceUrl;
                                        hit.d_excerpt = hit.excerpt;
                                        events.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_ARTICLES_INDEX_NAME:
                                        hit.search_item_type = "articles";
                                        hit.d_link = `/article/${hit.url}`;
                                        hit.d_image = null;
                                        hit.d_excerpt = null;
                                        articles.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_POSTS_INDEX_NAME:
                                        hit.search_item_type = "posts";
                                        hit.d_link = `/blog/${hit.uri}`;
                                        hit.d_image = hit.featuredImage?.node?.sourceUrl;
                                        hit.d_excerpt = hit.excerpt;
                                        posts.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_EATS_INDEX_NAME:
                                        hit.search_item_type = "eats";
                                        hit.d_link = hit.uri;
                                        hit.d_image = hit.featuredImage?.node?.sourceUrl;
                                        hit.d_excerpt = hit.excerpt;
                                        eats.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_PAGES_INDEX_NAME:
                                        hit.search_item_type = "pages";
                                        hit.d_link = `${hit.slug}`;
                                        hit.d_image = null;
                                        hit.d_excerpt = null;
                                        pages.push(hit);
                                        merge.push(hit);
                                        break;

                                        case siteVariables.ALGOLIA_ALL_INDEX_NAME:
                                        hit.search_item_type = hit.type;
                                        hit.d_link = formatUrl(hit.url, hit.type);
                                        hit.d_image = hit.image;
                                        hit.d_excerpt = hit.content;
                                        all.push(hit);
                                        break;

                                        default:
                                    }
                                    
                                    return hit
                                });
                            }
                        })
                
                        res({
                            products: products, // allItems.slice((currentPage * 60), ((currentPage + 1) * 60)),
                            clubs: clubs,
                            events: events,
                            articles: articles,
                            eats: eats,
                            posts: posts,
                            pages: pages,
                            mergeItems: merge,
                            all: all,
                            totals: totals
                            // totalPages: 'nbPages' in response ? response.nbPages : 1,
                            // currentPage: 'page' in response ? response.page : 0,
                            // perPage: 'page' in response ? response.hitsPerPage : 60,
                            // allItems
                            // To-do pagination support
                        })
                    } else {
                        res({products: [], clubs: [], events: [], eats: [], posts: [], pages: [],  articles: [], all: [], totalPages: 0, currentPage: 0})
                    }
                });

                // index.search(searchTerm, { hitsPerPage: limit, numericFilters: [...rangeNumFilters], ...facetFilters }).then(response => {
                //     console.log('response', response);
                //     if (response.nbHits > 0 && 'hits' in response) {
                //         const allItems = response.hits.map(product => {
                //             product.search_item_type = "product"
                //             return product
                //         });
        
                //         res({
                //             products: allItems.slice((currentPage * 60), ((currentPage + 1) * 60)),
                //             totalPages: 'nbPages' in response ? response.nbPages : 1,
                //             currentPage: 'page' in response ? response.page : 0,
                //             perPage: 'page' in response ? response.hitsPerPage : 60,
                //             allItems
                //         })
        
                //     } else {
                //         res({products: [], totalPages: 0, currentPage: 0})
                //     }
                // })
            })
        }
        
        export default AlgoliaSearch