// extracted by mini-css-extract-plugin
export var bestSelling = "Product-module--bestSelling--d469a";
export var border = "Product-module--border--71842";
export var breadcrumbs = "Product-module--breadcrumbs--8d9c9";
export var breadcrumbsSm = "Product-module--breadcrumbsSm--cd0a1";
export var colDetails = "Product-module--colDetails--40d33";
export var colImages = "Product-module--colImages--1e705";
export var colThumbnails = "Product-module--colThumbnails--e2811";
export var noReviews = "Product-module--noReviews--4d5e2";
export var pinkBackground = "Product-module--pinkBackground--4cd71";
export var productCategories = "Product-module--productCategories--ef149";
export var productPage = "Product-module--productPage--6c081";
export var productTop = "Product-module--productTop--be861";
export var productTopCol = "Product-module--productTopCol--14470";
export var productTopEntry = "Product-module--productTopEntry--d03c7";
export var recentlyViewedProducts = "Product-module--recentlyViewedProducts--d1efe";
export var subheader = "Product-module--subheader--f2fbd";