import React from 'react';
import * as styles from './CtbStep.module.css';

const CtbStep = (props) => {

  const {symbol, children} = props;
  
  return (
    <div className={ styles.CtbStep }>
      <div className={styles.symbolContainer}>
        <span className={styles.symbol}>{symbol}</span>
      </div>
      {children}
    </div>
  );
};

export default CtbStep;
