import React from 'react'

const Delivery = () => (
    <svg
    width={60}
    height={62}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 60 62'
  >
    <path
      d="M53.063 17.607a3.026 3.026 0 0 0-1.055-1.066L31.383 4.553a2.832 2.832 0 0 0-2.766 0L7.992 16.541a3.025 3.025 0 0 0-1.055 1.066 2.932 2.932 0 0 0-.375 1.453v23.88a2.957 2.957 0 0 0 .378 1.474c.251.447.615.817 1.052 1.069L28.617 57.47c.403.228.853.352 1.313.363h.14a3.13 3.13 0 0 0 1.313-.363l20.625-11.988c.437-.252.8-.622 1.052-1.069.252-.447.382-.957.377-1.474V19.06a3.106 3.106 0 0 0-.374-1.453ZM29.53 6.224a.982.982 0 0 1 .938 0l20.133 11.698L30.21 29.886 9.42 17.922l20.11-11.698ZM8.906 43.788a.954.954 0 0 1-.345-.359.994.994 0 0 1-.124-.49V19.57l20.836 11.988-.187 23.952-20.18-11.721Zm42.188 0L30.96 55.484l.187-23.928 20.415-11.964V42.94a.994.994 0 0 1-.124.49.953.953 0 0 1-.345.358Z"
      fill="currentColor"
    />
    <path
      d="m37.167 36.5 3.25 3.25 5.416-6.5"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>

)

export default Delivery