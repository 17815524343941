import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'

import * as styles from './FreshdeskArticleSearchResults.module.css';

const FreshdeskArticleSearchResults = props => {
  const { data } = props;

  const [searchResults, setSearchResults] = useState();
  useEffect(() => {
    if(data) {
        const groups = data.hits.reduce((groups, article) => {
            const group = (groups[article.category_name] || []);
            const tempArticle = {...article, url: article.url}
            group.push(tempArticle);
            groups[article.category_name] = group;
            return groups;
          }, {});
        setSearchResults(groups);
    } else {
        setSearchResults(undefined);
    }
  }, [data])

  return (
    <div className={ styles.FreshdeskArticleSearchResults }>
      <div className={styles.folderGrid}>
        {searchResults && 
        Object.keys(searchResults).map((key, index) => {
          return(
            <div key={index}>
                <h4 className={styles.folderName}>{key}</h4>
                <div className={styles.articleListContainer}>
                    {searchResults[key].map((article) => <Link key={article.id} className={styles.articleLink} to={`/article/${article.url}`}>{article.title}</Link>)}
                </div>
            </div>
            )
          })
        }
        
      </div>
      {data === undefined && 
      <p>Type a subject or topic to check our knowledge base. Alternatively, check out our <Link style={{textDecoration: 'underline'}} to={'/faq'}>FAQs page.</Link></p>}
      {data?.hits?.length === 0 && <p>0 articles found.</p>}
    </div>
  );
};

export default FreshdeskArticleSearchResults;
