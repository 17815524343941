import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/organisms/Layout/Layout';
import Pagination from '../components/atoms/Pagination/Pagination';
import PostList from '../components/organisms/PostList/PostList';
import { transformBCPost } from '../helpers/bigcommerce';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { edges: posts } = data.allBigCommercePosts;
    const transformedPosts = posts.map(({ node: post }) =>
      transformBCPost(post)
    );

    return (
      <Layout>
        <PostList posts={transformedPosts} title="Latest posts" isBC />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allBigCommercePosts: PropTypes.shape({
      edges: PropTypes.array
    })
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number
  })
};

export const query = graphql`
  query BCBlogQuery($limit: Int!, $skip: Int!) {
    allBigCommercePosts(limit: $limit, skip: $skip) {
      edges {
        node {
          ...BCPostListFields
        }
      }
    }
  }
`;
