import React from 'react'

const Klarna = () => (
    <svg
      viewBox="0 0 46 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <rect fill="#FFB3C7" width={46} height={25.556} rx={4.321} />
        <path
          d="M41.693 15c-.601 0-1.09.492-1.09 1.1 0 .607.489 1.1 1.09 1.1.602 0 1.09-.493 1.09-1.1 0-.608-.488-1.1-1.09-1.1zm-3.585-.85c0-.832-.704-1.505-1.573-1.505s-1.573.674-1.573 1.505c0 .831.704 1.505 1.573 1.505s1.573-.674 1.573-1.505zm.006-2.926h1.736v5.852h-1.736v-.374a3.025 3.025 0 01-1.721.536c-1.69 0-3.06-1.383-3.06-3.088s1.37-3.088 3.06-3.088c.638 0 1.23.199 1.721.536v-.374zm-13.894.762v-.762h-1.778v5.852h1.782v-2.732c0-.922.99-1.417 1.677-1.417h.02v-1.702c-.705 0-1.353.304-1.701.761zm-4.428 2.164c0-.831-.704-1.505-1.573-1.505s-1.573.674-1.573 1.505c0 .831.704 1.505 1.573 1.505s1.573-.674 1.573-1.505zm.006-2.926h1.736v5.852h-1.736v-.374a3.025 3.025 0 01-1.721.536c-1.69 0-3.06-1.383-3.06-3.088s1.37-3.088 3.06-3.088c.638 0 1.23.199 1.72.536v-.374zm10.45-.157c-.694 0-1.35.217-1.789.816v-.658H26.73v5.85h1.75v-3.074c0-.89.592-1.326 1.304-1.326.763 0 1.201.46 1.201 1.314v3.087h1.735v-3.721c0-1.362-1.073-2.288-2.472-2.288zm-17.76 6.009h1.817v-8.46h-1.817v8.46zm-7.983.002H6.43V8.615H4.505v8.463zm6.73-8.463a6.763 6.763 0 01-1.968 4.803l2.66 3.66H9.55l-2.89-3.977.746-.564a4.874 4.874 0 001.947-3.922h1.883z"
          fill="#0A0B09"
        />
      </g>
    </svg>
)

export default Klarna