import React from 'react';
import Container from '../../atoms/Container/Container';
import * as styles from './Health.module.css';
import parse from 'html-react-parser';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
const Health = ({ health }) => {
  return (
    <div className={styles.root}>
      <div
        className={styles.pageBanner}
        style={{
          backgroundImage: `url(${health.featuredImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}></div>
      <Container>
        <div className={styles.main}>
          <Breadcrumbs
            crumbs={[
              { label: 'Health & Services', link: '/health-services/' },
              { label: health.title },
            ]}
          />

          <h1>{health.title}</h1>
          <div className={styles.content}>
            <div>
              {health.content && parse(health.content)}
              <div className={styles.detailsGrid}>
                {health.openingHours && (
                  <div className={styles.openinghours}>
                    <h4>Opening hours</h4>
                    {health.openingHours.map((hours, hoursIndex) => (
                      <div key={hoursIndex}>
                        <span>
                          <strong>{hours.days}:&nbsp;</strong>
                        </span>
                        <span>{hours.hours}</span>
                      </div>
                    ))}
                  </div>
                )}
                {health.formatted_address && (
                  <div className={styles.addresses}>
                    <h4>address</h4>
                    <div>
                      <span>{health.formatted_address}</span>
                    </div>
                    {health.addresses.contact && (
                      <div>
                        <strong>T</strong> {health.addresses.contact}
                      </div>
                    )}
                    {health.addresses.email && (
                      <div>
                        <strong>E</strong> {health.addresses.email}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Health;

