import React from 'react';

import Dialog from '../../atoms/Dialog/Dialog';

import * as styles from './NoVolunteerDialog.module.css';

const NoVolunteerDialog = props => {

  const { show, setShow, usuNumber } = props;

  return (
    <Dialog
        open={show}
        size={'sm'}
        hideBtnCancel 
        hideBtnOk 
        title={'No Volunteer Account'}
        onCancel={() => setShow(false)}
    >   
        <div className={styles.dialogContainer}>
            {/* <p>No volunteer account was found linked to this USU account. 
                You can <a rel='noreferrer' target="_blank" href={'https://usuvteam.rosterfy.co/register'}> register for an account here. </a>
                If you already have an account, kindly check your <strong>USU Member Number ({usuNumber})</strong> or your <strong>email.</strong>
            </p>  */}
            <p>
              No volunteer account was found linked to this USU account. 
              You can register for an account <a rel='noreferrer' target="_blank" href={'https://usuvteam.rosterfy.co/register'}>here</a>.
              If you already have an account, please check that you've entered your <strong>email</strong> and USU number correctly <strong>({usuNumber})</strong>.
            </p>
        </div>
    </Dialog>
  );
};

export default NoVolunteerDialog;
