import React from 'react'

const Bag = () => (
    <svg width={24} height={29} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 29'>
        <g fill="currentColor" fillRule="nonzero">
            <path d="M20.625 10.125H4a3.25 3.25 0 0 0-3.25 3.25v10.938A4.25 4.25 0 0 0 5 28.563h14.625a4.25 4.25 0 0 0 4.25-4.25V13.374a3.25 3.25 0 0 0-3.25-3.25ZM4 12.625h16.625a.75.75 0 0 1 .75.75v10.938a1.75 1.75 0 0 1-1.75 1.75H5a1.75 1.75 0 0 1-1.75-1.75V13.374a.75.75 0 0 1 .75-.75ZM12.068.75c3.6 0 6.504 3.001 6.626 6.718l.004.244v1.25h-2.5v-1.25c0-2.48-1.866-4.462-4.13-4.462-2.197 0-4.02 1.867-4.126 4.244l-.005.218v1.25h-2.5v-1.25c0-3.83 2.952-6.962 6.63-6.962Z" />
        </g>
    </svg>

)

export default Bag