import React, { useMemo, useState } from 'react';
import { navigate } from "gatsby";
import { get } from 'lodash';
import { decode } from 'he';
import { toast } from 'react-toastify';
import { persistLocation } from '../../../../helpers/general';
import AccountPageWrapper from '../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import SelectField from '../../../../components/atoms/SelectField/SelectField';
import Button from '../../../../components/atoms/Button/Button';
import { wpApiNew } from '../../../../helpers/wordpress';

import * as styles from '../usu.module.css';

const Nominations = ({ location }) => {
    // const auth = useContext(AuthContext);
    // const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    // const userEmail = auth && get(auth, 'state.email');
    const [nominationsData, setNominationsData] = useState(null);
    const [nominations, setNominations] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useMemo(() => {
        const fetchNominations = async () => {
            const data = await wpApiNew('checkGmNominations', {
                eventId: location.state.eventId
            });

            if (String(data.status).startsWith('2') && data.response.length > 0) {
                setNominationsData(data.response[0]);
                setNominations(JSON.parse(data.response[0].acf.roles))
            } else if (String(data.status).startsWith('2') && data.response.length === 0) {
                setNominationsData(false);
                setNominations([]);
            }
        }
        
        fetchNominations();
    }, [location]);

    const updateNomination = (role, value) => {
        const tempNoms = {...nominations};
        tempNoms[role] = value;
        setNominations(tempNoms);
    }

    const submitNominations = async () => {
        // console.log(nominations);
        setSubmitting(true);
        const cleanNominations = {...nominations};
        Object.keys(cleanNominations).forEach(key => {
            if (cleanNominations[key] === 'no') delete cleanNominations[key];
        });
        const obj = {
            title: `${location.state.clubTitle} | ${location.state.eventId}`,
            fields: {
                club: location.state.clubId,
                event: location.state.eventId,
                roles: JSON.stringify(cleanNominations)
            }
        }
        
        // console.log(cleanNominations);
        if (Object.keys(cleanNominations).length > 0) {
            const saving = await wpApiNew('upsertGmNomination', {
                nominationId: nominationsData ? nominationsData.id : false,
                object: obj
            });
            // console.log(saving);
            if (String(saving.status).startsWith("2") && 'id' in saving.response) {
                toast.success("Nominations submitted!");
                setTimeout(() => {
                    navigate(`/account/my-usu/clubs`,{
                        state: {...location.state}
                    });
                }, 4000);
            } else {
                toast.error("Unable to save. Please try again.");
                setSubmitting(false);
            }
        } else if (nominationsData && Object.keys(cleanNominations).length === 0) {
            const deleting = await wpApiNew('deleteGmNomination', {
                nominationId: nominationsData.id
            });
            if (String(deleting.status).startsWith("2") && 'id' in deleting.response) {
                toast.success("Nominations removed!");
                setTimeout(() => {
                    navigate(`/account/my-usu/clubs`,{
                        state: {...location.state}
                    });
                }, 4000);
            } else {
                toast.error("Unable to remove nominations. Please try again.");
                setSubmitting(false);
            }
        } else {
            toast.warn("No nominations to save.");
            setSubmitting(false);
        }
    }

    return (
        <>
        {(nominations && location.state.clubRoles) ? (
            <>
                <p>Update against the roles you'd like to self-nominate for and go into the running to be voted in for these roles.</p>
                <div className={styles.roles}>
                    {location.state.clubRoles.map((role, roleIndex) => {
                        return (
                            <div key={roleIndex} className={styles.role}>
                                <div className={styles.name}>
                                    {role.role}
                                </div>
                                <div className={styles.vote}>
                                    <SelectField label="" id="nominate" value={nominations[role.role] || "no"} data={[{value: 'no', text: 'Not running'}, {value: 'yes', text: 'Running'}]} customHandler={(e) => updateNomination(role.role, e.target.value)} />
                                </div>
                            </div>
                        )
                    })}
                </div>

                <Button level='primary' disabled={submitting} type='button' onClick={() => submitNominations()}>Submit Nominations</Button>
            </>
        ) : (
            <div className={styles.root}>
                <div>Compiling nomination information...</div>
            </div>
        )}
        </>
    );
};

const ClubManagementOutput = ({ location }) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper
            metaTitle={`Account - ${decode(get(persistedLocation, 'state.clubTitle', 'Club'))} Nominations`}
            bgRaw
            title={`${decode(get(persistedLocation, 'state.clubTitle', 'Club'))} Nominations`}
            breadcrumbTitle={decode(get(persistedLocation, 'state.clubTitle', ''))}
            breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }]}
            location={persistedLocation}>
            <Nominations location={persistedLocation} />
        </AccountPageWrapper>
    )
};

export default ClubManagementOutput;

