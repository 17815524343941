import React, { useCallback, useMemo, useState } from 'react';
// import { navigate } from "gatsby";
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { persistLocation } from '../../../../helpers/general';
import AccountPageWrapper from '../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import SelectField from '../../../../components/atoms/SelectField/SelectField';
import FormInputField from "../../../../components/atoms/FormInputField/FormInputField";
import Button from '../../../../components/atoms/Button/Button';
import Checkbox from '../../../../components/atoms/Checkbox/Checkbox';
import Radio from '../../../../components/atoms/Radio/Radio';
// import AuthContext from '../../../../context/AuthProvider';
import { wpApiNew } from '../../../../helpers/wordpress';
// import { getTags, getPerson } from '../../../../helpers/ortto';

import * as styles from '../usu.module.css';

const Vote = ({ location }) => {
    // const auth = useContext(AuthContext);
    // const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    // const userEmail = auth && get(auth, 'state.email');
    const [init, setInit] = useState(false);
    // const [club, setClub] = useState(null);
    const [votingStatus, setVotingStatus] = useState(null);
    const [votesExisting, setVotesExisting] = useState(false);
    const [votes, setVotes] = useState({});
    const [currentRole, setCurrentRole] = useState(null);
    const [positionsAmount, setPositionsAmount] = useState(1);
    const [candidates, setCandidates] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [abstainVote, setAbstainVote] = useState(false);
    const [voted, setVoted] = useState(false);

    const fetchVotingData = useCallback(async () => {
        const eventCheckInData = await wpApiNew('checkEventRegistration', {eventId: location.state.eventId});
        const voteData = await wpApiNew('checkGmVote', {eventId: location.state.eventId, fieldSet: 1});
        const historyData = await wpApiNew('getClubHistoryData', {
            historyId: false,
            eventId: location.state.eventId,
            clubId: location.state.clubId,
            fieldSet: 1
        });
        if (
            String(voteData.status).startsWith('2') && Array.isArray(voteData.response) &&
            String(historyData.status).startsWith('2') && Array.isArray(historyData.response) &&
            String(eventCheckInData.status).startsWith('2') && Array.isArray(eventCheckInData.response)
        ) {
            // Determine where the vote is up to from history data
            const roles = historyData.response[0].acf.new_club_roles;
            const nominations = (!historyData.response[0].acf.nominations || historyData.response[0].acf.nominations === '') ? {} : JSON.parse(historyData.response[0].acf.nominations);
            // console.log(nominations);
            const execs = historyData.response[0].acf.new_club_execs || [];
            
            const _currentRole = roles.find(role => {
                const hasNominations = (role.role in nominations);
                const hasExec = execs.find(e => e.position === role.role);

                if (hasNominations && hasExec) {
                    return false;
                } else if (hasNominations && !hasExec) {
                    setVotingStatus('voting');
                    setCandidates(nominations[role.role]);
                    return true;
                } else if (!hasNominations && !hasExec) {
                    setVotingStatus('confirming');
                    return true;
                }

                return false;
            });

            if (_currentRole) {
                setCurrentRole(_currentRole.role);
                setPositionsAmount(_currentRole.amount);
            } else {
                setCurrentRole(false);
                setVotingStatus('complete');
            }

            // check if user has already submitted votes for the role
            // console.log(voteData);
            if (voteData.response.length > 0) {
                setVotesExisting(voteData.response[0].id);
                const existingVotes = voteData.response[0].acf.votes;
                const existingVoteFields = JSON.parse(existingVotes);
                // console.log(existingVoteFields);
                setVotes(existingVoteFields);
                if (_currentRole) {
                    Object.keys(existingVoteFields).find(ev => {
                        if (ev === _currentRole.role) {
                            setVoted(true);
                            return true;
                        }

                        return false;
                    })
                } else {
                    setVoted(true);
                }
            }

            // Get candidates for the current role
            if (_currentRole && _currentRole.role in nominations) {
                const _candidates = nominations[_currentRole.role].map(nom => {
                    return {
                        member_name: nom.name || "(No name set)",
                        member_number: nom.member_number,
                    }
                })

                setCandidates(_candidates);
            }
        }
    }, [location]);

    useMemo(() => {
        if (!init) {
            setInit(true);
            fetchVotingData();
        }
    }, [init, fetchVotingData]);

    const refetch = () => {
        setVoted(false);
        fetchVotingData();
    }

    const submitVotes = async () => {
        // console.log(votes);
        setSubmitting(true);
        const tempVotes = { ...votes };
        if (abstainVote) {
            tempVotes[currentRole] = 'abstained';
        }
        const obj = {
            fields: {
                votes: JSON.stringify(tempVotes)
            }
        }
        if (!votesExisting) {
            obj.title = `${location.state.clubTitle} | ${location.state.eventId}`;
            obj.fields.club = location.state.clubId;
            obj.fields.event = location.state.eventId;
        }
        
        const saving = await wpApiNew('upsertGmVote', {
            voteId: votesExisting || false,
            object: obj
        });
        
        if (String(saving.status).startsWith("2") && 'id' in saving.response) {
            toast.success("Votes submitted!");
            setVoted(true);
            setSubmitting(false);
        } else {
            toast.error("Unable to save. Please try again.");
            setSubmitting(false);
        }
    }

    const makeVote = (memberNumber, value, type) => {
        const tempVotes = { ...votes };
        if (!(currentRole in tempVotes)) {
            tempVotes[currentRole] = [];
        }

        const exists = tempVotes[currentRole].findIndex(v => v.member_number === memberNumber);
        if (exists > -1) {
            tempVotes[currentRole].splice(exists, 1);
        }

        if (value !== '') {
            switch(type) {
                case 'single':
                    tempVotes[currentRole] = [{member_number: memberNumber, vote: String(value)}];
                    break;

                case 'duel':
                    tempVotes[currentRole] = [{member_number: memberNumber, vote: '1'}];
                    break;

                case 'multi':
                    const voteNumberExists = tempVotes[currentRole].find(vn => vn.vote === value);
                    // console.log(Number.isInteger(Number(value)), Number(value) <= candidates.length, !voteNumberExists);
                    if (Number.isInteger(Number(value)) && Number(value) <= candidates.length && !voteNumberExists) {
                        tempVotes[currentRole].push({member_number: memberNumber, vote: String(value)});
                        // console.log(tempVotes[currentRole]);
                    }
                    break;

                default:
                    // Do nothing
                    break;
            }
        }

        setVotes(tempVotes);
    }

    return (
        <>
        {(candidates) ? (
            <>
                {votingStatus === 'confirming' && (
                    <>
                        <p>Currently finalising candidates for the role of {currentRole}. Voting will commence shortly.</p>
                        <Button type="span" link={true} onClick={() => refetch()}>Update voting screen</Button>
                    </>
                )}

                {(votingStatus === 'voting' && !voted) && (
                    <div>
                        <p>Voting for the role of {currentRole}.</p>
                        <div className={styles.roles}>
                            <div className={styles.abstain}>
                                <Checkbox label="Abstain vote for this role" isChecked={abstainVote} action={() => setAbstainVote(!abstainVote)} />
                            </div>

                            {!abstainVote && (
                                <div>
                                    {candidates.map((candidate, cIndex) => (
                                        <div key={cIndex} className={styles.role}>
                                            <div className={styles.name}>
                                                {candidate.member_name}
                                            </div>
                                            {/* {candidates.length === 1 && ( */}
                                            {(candidates.length <= Number(positionsAmount)) && (
                                                <div className={styles.vote}>
                                                    <SelectField label="" placeholder="Vote of Confidence" id={`${currentRole}_${candidate.member_name}`} value={currentRole in votes && Array.isArray(votes[currentRole]) && votes[currentRole].length > 0 && votes[currentRole][0].vote} data={[{value: '1', text: 'Yes'}, {value: '0', text: 'No'}]} customHandler={(e) => makeVote(candidate.member_number, e.target.value, 'single')} />
                                                </div>
                                            )}
                                            
                                            {(candidates.length === 2 && Number(positionsAmount) === 1) && (
                                                <div className={styles.vote}>
                                                    <Radio id={`${currentRole}_${candidate.member_name}`} name={currentRole} label="" isChecked={currentRole in votes && Array.isArray(votes[currentRole]) && votes[currentRole].length > 0 && votes[currentRole][0].member_number === candidate.member_number} action={() => makeVote(candidate.member_number, true, 'duel')} />
                                                </div>
                                            )}

                                            {candidates.length > 2 && (
                                                <div className={styles.vote}>
                                                    <FormInputField
                                                        id={`${currentRole}_${candidate.member_name}`}
                                                        type='number'
                                                        min='1'
                                                        max={candidates.length}
                                                        pattern="[0-9]"
                                                        labelName=''
                                                        placeholder={`1 - ${candidates.length}`}
                                                        handleChange={(id, value) => makeVote(candidate.member_number, value, 'multi')}
                                                        error={(currentRole in votes && Array.isArray(votes[currentRole]) && votes[currentRole].length > 0 && !(votes[currentRole].find(v => v.member_number === candidate.member_number))) ? `Enter value between 1 and ${candidates.length}` : false}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <p>Once submitted, these votes can NOT be changed. Please double check all votes before submitting.</p>
                            <Button level='primary' disabled={!abstainVote && (!(currentRole in votes) || votes[currentRole].length !== (candidates.length === 2 ? 1 : candidates.length) || submitting)} type='button' onClick={() => submitVotes()}>{abstainVote ? 'Confirm abstainment' : 'Submit votes'}</Button>
                        </div>
                    </div>
                )}

                {(votingStatus === 'voting' && voted) && (
                    <>
                        <p>Thank you for your vote for the role of {currentRole}. Please wait while voting is finalised.</p>
                        <Button type="span" link={true} onClick={() => refetch()}>Update voting screen</Button>
                    </>
                )}

                {votingStatus === 'complete' && (
                    <p>Voting has been completed. Thank you for your involvement.</p>
                )}
                {/* {eligibleMembers.length > votes.length ? ( */}
                {/* {2>1 ? (
                    <>
                        <div className={styles.roles}>
                            {votes.map((vote, voteIndex) => {
                                return (
                                    <div key={voteIndex} className={styles.role}>
                                        <div className={styles.name}>
                                            {vote.role}
                                        </div>
                                        <div className={styles.vote}>
                                            {!vote.vote ? (
                                                <SelectField label="" placeholder="Choose member..." id="amount" value="" data={availableMembers.map(am => ({value: am.member_number, text: am.member_name}))} customHandler={(e) => selectVote(e.target.value, voteIndex)} />
                                            ) : (
                                                <>
                                                    {vote.vote.member_name} (<span role="presentation" className={styles.changeSelection} onClick={() => changeVote(voteIndex)}>Change?</span>)
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <p>Once submitted, these votes can NOT be changed. Please double check all votes before submitting.</p>
                        <Button level='primary' disabled={submitting} type='button' onClick={() => submitVotes()}>Submit Votes</Button> {/*votes.filter(v => v.vote === null) || *}
                    </>
                ) : (
                    <>There are not enough checked in members to the IGM to carry out the vote and fill all roles.</>
                )} */}
            </>
        ) : (
            <div className={styles.root}>
                <div>Compiling vote information...</div>
            </div>
        )}
        </>
    );
};

const ClubManagementOutput = ({ location }) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper
            metaTitle={`Account - ${get(persistedLocation, 'state.clubTitle', 'Club')} Voting`}
            bgRaw
            title={`${get(persistedLocation, 'state.clubTitle', 'Club')} Voting`}
            breadcrumbTitle={get(persistedLocation, 'state.clubTitle', '')}
            breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }]}
            location={persistedLocation}>
            <Vote location={persistedLocation} />
        </AccountPageWrapper>
    )
};

export default ClubManagementOutput;

