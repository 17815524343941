import React, { useEffect, useState } from 'react';

import CategoryTree from './components/CategoryTree'
import Colors from './components/Colors'
import MultiSelect from './components/Multiselect'
import Range from './components/Range'

import * as styles from './FilterBlock.module.css';

const FilterBlock = ({ filter, setter, getter, removeFilter, clearFilters }) => {
    // console.log('f',filter);
    const [firstLoad, setFirstLoad] = useState(false);
    const [isActive, setActive] = useState(false);
    const [reset, setReset] = useState(false);
    const [toggleCollapse, setToggleCollapse] = useState(filter && typeof filter.collapse !== 'undefined' ? filter.collapse === 'collapsed' : false);

    const toggle = (e) => {
        e.preventDefault()
        setToggleCollapse(!toggleCollapse)
    };

    useEffect(() => {
        if (!firstLoad) {
            setFirstLoad(true)
            if (typeof filter.collapse !== 'undefined' && filter.collapse === 'collapsed') {
                const _params = [];
                `${window.location.search.substring(1)}`.split('&').filter(v => v !== '').map(v => {
                    _params[v.split('=')[0]] = decodeURIComponent(v.split('=')[1]).split(',')
                    return v;
                })
                if (Object.keys(_params).filter(a => a === filter.field).length) {
                    setToggleCollapse(false)
                }
            }
        }
    }, [firstLoad, filter]);

    useEffect(() => {
        if (Object.values(getter).indexOf(filter.field) > -1 && !isActive) {
            setActive(true);
            setReset(false);
        } else if (Object.values(getter).indexOf(filter.field) === -1 && isActive) {
            setActive(false);
            setReset(true);
        }
    }, [getter, isActive, filter]);

    return (
        <>
        {((filter.values && filter.values.length > 0) || (filter.min > -1 && filter.max > -1 && filter.min !== filter.max)) && (
            <div className={`${styles.filter} filterType-${filter.type} ${isActive ? 'isActive' : ''}`}
                {...(toggleCollapse ? {'data-open': 'open'} : {})}>
                <h6 onClick={toggle} role="presentation">
                    {filter.label}
                    {Object.values(getter).indexOf(filter.field) > -1 && (
                        <span role="presentation" className={styles.clearFilter} onClick={(e) => removeFilter(e, Object.keys(getter)[Object.values(getter).indexOf(filter.field)])}>Clear</span>
                    )}
                </h6>

                {filter.type && ['multiselect', 'pim_catalog_multiselect'].indexOf(filter.type) > -1 && (
                    <MultiSelect values={filter.values} name={filter.field} setter={setter} getter={getter} styles={styles} />
                )}

                {filter.type && ['range', 'pim_catalog_metric'].indexOf(filter.type) > -1 && (
                    <Range name={filter.field} min={filter.min} max={filter.max} reset={reset} setter={setter} getter={getter} styles={styles} />
                )}

                {filter.type && filter.type === "categories" && (
                    <CategoryTree values={filter.values} name={filter.field} setter={setter} getter={getter} styles={styles} clearFilters={clearFilters} />
                )}

                {filter.type && filter.type === "colors" && (
                    <Colors values={filter.values} name={filter.field} setter={setter} getter={getter} styles={styles} />
                )}
            </div>
        )}
        </>
    )
};

export default FilterBlock;