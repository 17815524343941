import React, { useEffect } from "react"
import AOS from 'aos'

import 'aos/dist/aos.css'

const AosInit = ({ children, classes, attrs = {} }) => {
    useEffect(() => {
          AOS.init();
          // AOS.refreshHard();
    }, []);

    const defaults = {
        'data-aos': "fade-up",
        'data-aos-duration': 1000
    };

    const settings = {...defaults, ...attrs};

    return (
        <div className={classes} {...settings}>
            {children}
        </div>
    )
}

export default AosInit