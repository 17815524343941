// extracted by mini-css-extract-plugin
export var actionCard = "details-module--actionCard--7a60f";
export var addCategory = "details-module--addCategory--b91f3";
export var addRow = "details-module--addRow--5b609";
export var columnTitle = "details-module--columnTitle--5b02a";
export var extraDetails = "details-module--extraDetails--3344c";
export var extraDetailsWrapper = "details-module--extraDetailsWrapper--6947e";
export var first = "details-module--first--7eb2b";
export var grantDetails = "details-module--grantDetails--f038b";
export var header = "details-module--header--f6c0d";
export var last = "details-module--last--e6e59";
export var loading = "details-module--loading--2fc09";
export var root = "details-module--root--63075";
export var rowTitle = "details-module--rowTitle--54929";
export var rowValue = "details-module--rowValue--ccf31";
export var spendSplit = "details-module--spendSplit--3185d";
export var withBg = "details-module--withBg--fa433";