import React, { useEffect, useState } from 'react';
import * as styles from './ContentfulBlockGrid.module.css';

import { useWindowDimensions } from '../../../helpers/general';

import Container from '../../atoms/Container/Container';

const GenericCard = ({cardDetails}) => {
  return(
    <a href={cardDetails?.link} className={styles.cardContainer}>
      <div className={styles.imageContainer}>
          <img alt={cardDetails.label} src={cardDetails.image}></img>
      </div>
      <span className={styles.name}>{cardDetails.label}</span>
    </a>
  )
}

const ContentfulGrid = props => {

  const { size, slidesToDisplayWeb, slidesToDisplayMobile, cardList } = props;
  const [slidesToDisplay, setSlidesToDisplay] = useState(3);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if(width < 800) setSlidesToDisplay(slidesToDisplayMobile);
    else setSlidesToDisplay(slidesToDisplayWeb);
  }, [width, slidesToDisplayMobile, slidesToDisplayWeb]);

  const containerStyling = {
    gridTemplateColumns: `repeat(${slidesToDisplay}, 1fr)`
  }

  const normalizedCardList = cardList.map((card) => {
    return {
      label: card.label,
      link: card.link?.url,
      image: card.cardImage.image ? `${card.cardImage.image.file.url}?w=400&h=400&fit=fill` : 'about:blank', // TODO: Replace with default
    }
  });

  return (
    <div className={ styles.root } >
      <Container size={typeof size === 'string' && size?.toLowerCase()}>
        <div className={styles.gridContainer} style={containerStyling}>
          {normalizedCardList && normalizedCardList.map((cardDetails, cardIndex) => {
            return <GenericCard cardDetails={cardDetails} key={cardIndex} />
          })}
        </div>
      </Container>
    </div>
  );
};

export default ContentfulGrid;
