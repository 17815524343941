import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/organisms/Seo/Seo';
import Layout from '../components/organisms/Layout/Layout';
import EventsAll from '../components/organisms/EventsAll/EventsAll';

const WPEventsCategory = ({ data, pageContext }) => {
    const events = (data && data.allWpEvent.nodes) || [];
    // console.log(pageContext);
    return (
        <Layout>
            <Seo title={`Events`} />
            <EventsAll events={events} tabId={pageContext.slug} />

        </Layout>
    );
}

export default WPEventsCategory;

export const pageQuery = graphql`
  query WPEventsCategory($id: String!) {
    allWpEvent(
        filter: {eventCategories: {nodes: {elemMatch: {id: {eq: $id}}}}, eventFieldsSide: {displayTo: {in: ["public", null]}}}
        sort: {fields: eventFieldsSide___startDate, order: ASC}
    ) {
        nodes {
            id
            status
            uri
            slug
            title
            featuredImage {
                node {
                    publicUrl
                    sourceUrl
                    localFile {
                        childImageSharp {
                          fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                            src
                          }
                        }
                    }
                }
            }
            eventFieldsSide {
                startDate
                endDate
                endTime
                startTime
                club {
                  ... on WpClub {
                    id
                    title
                    databaseId
                    clubFieldsSide {
                      logo {
                        sourceUrl
                        localFile {
                            childImageSharp {
                              fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                                src
                              }
                            }
                        }
                      }
                    }
                  }
                }
              }
            eventTags {
                nodes {
                    uri
                    name
                }
            }
            eventCategories {
              nodes {
                slug
              }
            }
        }
    }
  }
`;
