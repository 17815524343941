import React, { useEffect, useState } from 'react'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import Button from '../../atoms/Button/Button';

import { camelCase } from 'lodash';
import { useWindowDimensions } from '../../../helpers/general';

import * as styles from './ContentfulBlockCopy.module.css';
import EmbeddedForm from './EmbeddedForm';

const ContentfulBlockCopy = props => {
    const { 
        size,
        content,
        maxWidth,
        fontSize,
        fontSizeDesktop,
        fontSizeMobile,
        lineHeight,
        alignment,
        ctaButton,
        containerSpacing,
        containerAlignment,
        backgroundColor,
        padding,
        data
    } = props;

    const { width } = useWindowDimensions();
    const [textContainerStyle, setTextContainerStyles] = useState({});

    let contentFontSize = fontSize;
    if(width < 1200 && width > 800) contentFontSize = fontSizeDesktop;

    let buttonFontSize = ctaButton?.fontSize;
    if(width < 800) {
        buttonFontSize = ctaButton?.fontSizeMobile;
        contentFontSize = fontSizeMobile;
    } 
    
    const mapButtonSize = () => {
        let buttonSize = '';

        if(width < 800) {
            if(ctaButton?.sizeMobile === 'Medium') return buttonSize;
            if(ctaButton?.sizeMobile === 'No Width' || ctaButton?.sizeMobile === 'Full Width') { return camelCase(ctaButton?.sizeMobile);}
            else {
                return ctaButton?.sizeMobile?.toLowerCase(); //smallest small large
            }
        } else {
            if(ctaButton?.size) {
                if(ctaButton?.size === 'Medium') return buttonSize;
                if(ctaButton?.size === 'No Width' || ctaButton?.size === 'Full Width') return camelCase(ctaButton?.size);
                else {
                    return ctaButton?.size?.toLowerCase(); //smallest small large
                }
            }
        }
        return buttonSize;
    }

    const hasCustomFontSizes = () => {
        if(fontSize !== null) return true
        if(fontSizeDesktop !== null) return true
        if(fontSizeMobile !== null) return true
        return false;
    }

    const determineContainerAlignment = () => {
        if(containerAlignment === null || containerAlignment === undefined) return({});
        if(containerAlignment === 'Left') return({});
        if(containerAlignment === 'Center') return({ marginLeft: 'auto', marginRight: 'auto' })
        if(containerAlignment === 'Right') return ({marginLeft: 'auto'})
    }

    const rootProperty = {
        backgroundColor: backgroundColor ? backgroundColor?.hexCode : 'var(--standard-light-grey)'
    }

    const buttonCustomStyling = {
        fontSize: buttonFontSize,
        marginTop: ctaButton?.marginTop ? ctaButton?.marginTop : '16px',
    }

    // const styleProperty = useMemo(() => {
    //     const calculateLineHeight = () => {
    //         if(contentFontSize) {
    //             if(lineHeight === undefined || lineHeight === null) return contentFontSize;
    //             if(lineHeight === 'Regular') return(`calc(${contentFontSize} + 8px)`)
    //             if(lineHeight === 'Large') return(`calc(${contentFontSize} + 15px)`)
    //         }
    //     }

    //     return {
    //         maxWidth: width > 800 ? maxWidth : '',
    //         fontSize: contentFontSize,
    //         lineHeight: calculateLineHeight(),
    //         textAlign: alignment,
    //     };
    // }, [width, alignment, contentFontSize, maxWidth, lineHeight]);

    useEffect(() => {
        const calculateLineHeight = () => {
            if(contentFontSize) {
                if(lineHeight === undefined || lineHeight === null) return contentFontSize;
                if(lineHeight === 'Regular') return(`calc(${contentFontSize} + 8px)`)
                if(lineHeight === 'Large') return(`calc(${contentFontSize} + 15px)`)
            }
        }

        setTextContainerStyles({
            maxWidth: width > 800 ? maxWidth : '',
            fontSize: contentFontSize,
            lineHeight: calculateLineHeight(),
            textAlign: alignment,
        })
    }, [maxWidth, contentFontSize, alignment, lineHeight, width])

    return (
        <section className={`${styles.pageCopy} ${padding !== null ? styles[`padding${padding}`] : ''}`} style={rootProperty}>
            <AosInit>
                <Container spacing={containerSpacing?.toLowerCase()} size={size?.toLowerCase()}>
                    <div 
                        className={`${styles.textContainer} ${hasCustomFontSizes() === true ? styles.customFontSize : ''}`}
                        style={{...textContainerStyle, ...determineContainerAlignment()}}>
                        {content && (<ContentfulRichText raw={content.raw} />)}

                        {data?.page?.slug === '/alumni' ? <div className={`${styles.orttoFormContainer}`} dangerouslySetInnerHTML={{ __html: EmbeddedForm }} /> :
                            ctaButton ?
                                <Button
                                    size={mapButtonSize()}
                                    inlineStyling={buttonCustomStyling}
                                    href={ctaButton?.link?.url}
                                    type={'a'}
                                    level={'primary'}
                                >
                                    {ctaButton.label}
                                </Button> :
                                ''
                        }
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockCopy;






