import React, { useState, useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby'

import Container from '../../atoms/Container/Container';

import * as styles from './EatsList.module.css';
import ContentCard from '../../molecules/ContentCard/ContentCard';

const EatsList = (props) => {

  const [locations, setLocations] = useState(false);
  const wpEatsListQuery = useStaticQuery(graphql`
    query EatsList {
      allWpEatsLocation(sort: {fields: eatsLocationOptions___sort}) {
        nodes {
          name
          eats {
            nodes {
              status
              uri
              title
              slug              
              featuredImage {
                node {
                  publicUrl
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fixed(width: 420, height: 130) {
                        src
                      }
                    }
                  }
                }
              }
              usueatsFields {
                openingHours {
                  days
                  hours
                }
              }
            }
          }
        }
      }
    }
  `)

  useMemo(() => {
    if (!locations) {
      const locationQuery = (wpEatsListQuery && wpEatsListQuery.allWpEatsLocation.nodes) || [];
      // for development purposes only; remove this when sufficient data is imported
      // const sampleObject = locationQuery[0].eats.nodes[0];
      // const devLocationList = locationQuery.map((sampleLocation) => {
      //   return {
      //     ...sampleLocation,
      //     eats: {
      //       nodes: [sampleObject, sampleObject, sampleObject, sampleObject, sampleObject]
      //     }
      //   }
      // });
      // end of dev script

      setLocations(locationQuery);
      // setLocations(devLocationList);
    }
  }, [locations, wpEatsListQuery]);

  return (
    <Container>
      <div className={ styles.EatsList }>
        {locations && locations.map((location, locationIndex) => (
          <div className={styles.locationContainer} key={locationIndex}>
              <h3 className={styles.location}>{location.name}</h3>
              <div className={styles.eatGrid}>
                {location.eats.nodes && location.eats.nodes.map((eat, eatIndex) => (
                    <ContentCard key={eatIndex} cardInfo={eat} />
                ))}
                {location?.eats?.nodes?.length === 0 && <span>No assigned Eats for this location</span>}
              </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default EatsList;
