import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, isUndefined, head } from 'lodash';
// import { bcApi } from '../../../helpers/bigcommerce'
import { bcApi } from '../../../helpers/bigcommerce';

import AddressCard from '../../../components/molecules/AddressCard/AddressCard';
import AddressForm from '../../../components/molecules/AddressForm/AddressForm';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
// import PageTitle from "../../components/atoms/PageTitle/PageTitle"

import * as styles from './usu.module.css';
import MyUsuTab from '../../../components/organisms/MyUsuTab/MyUsuTab';
import AuthContext from '../../../context/AuthProvider';

const Addresses = () => {
  const auth = useContext(AuthContext);
  const customerid = auth && auth.state.customerId;
  const [addresses, setAddresses] = useState([]);
  const [active, setActive] = useState(null);
  const [fetched, toggleFetched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const addAddress = () => setActive({});

  const editAddress = address => setActive(address);

  const cancelUpdate = () => setActive(null);

  const handleResponse = (response, isNew) => {
    const address = head(response.data);
    if (address) {
      let updated = isNew
        ? [...addresses, address]
        : addresses.map(a => (a.id === address.id ? address : a));
      updated.filter(x => x);
      setAddresses(updated);
    }
  };

  const saveAddress = address => {
    if (isEmpty(address)) {
      return;
    }
    setIsSaving(true);
    if (isUndefined(address.id)) {
      bcApi(`customers/addresses`, 'POST', [
        { ...address, customer_id: customerid },
      ]).then(({ response, status }) => {
        if (status === 200 && !isEmpty(response.data)) {
          handleResponse(response, true);
        }
        setActive(null);
        setIsSaving(false);
      });
    } else {
      // update address
      bcApi(`customers/addresses`, 'PUT', [address]).then(
        ({ response, status }) => {
          if (status === 200 && !isEmpty(response.data)) {
            handleResponse(response);
          }
          setActive(null);
          setIsSaving(false);
        }
      );
    }
  };

  const removeAddress = addressId => {
    // delete address
    const endpoint = `customers/addresses?id:in=${addressId}`;
    bcApi(endpoint, 'DELETE').then(response => {
      if (response.status === 204) {
        setAddresses(addresses.filter(x => x.id !== addressId));
      }
    });
  };

  useEffect(() => {
    if (!fetched && customerid > 0) {
      bcApi(`customers/addresses?customer_id:in=${customerid}`).then(
        ({ response, status }) => {
          if (typeof response === 'object' && status === 200) {
            setAddresses(response.data);
          }
          toggleFetched(true);
        }
      );
    }
  }, [fetched, customerid]);

  return (
    <div className={styles.root}>
      <section className={['containerWhite'].join(' ')}>
        {fetched && addresses && addresses.length === 0 && active === null && (
          <span style={{display: 'block', marginTop: '16px'}}>You have no saved addresses</span>
        )}

        {fetched && !active && (
          <div>
            {addresses?.map((address, addressIndex) => (
              <AddressCard
                address={address}
                key={addressIndex}
                onEdit={() => editAddress(address)}
                onDelete={() => removeAddress(address.id)}
              />
            ))}
          </div>
        )}

        <AddressForm
          address={active}
          visible={!!active}
          isSaving={isSaving}
          onSubmit={saveAddress}
          onCancel={cancelUpdate}
        />

        {!fetched && <div className='mt-5' >Fetching your stored addresses...</div>}
      </section>
      <AddressCard active={active} onAddAddress={addAddress} responsiveButton />
      
    </div>
  );
};

const AddressesOutput = () => (
  <AccountPageWrapper metaTitle='Account - Addresses' breadcrumbTitle={'Addresses'} title='Welcome to my USU Portal'>
    <MyUsuTab pageId={'address'}>
      <Addresses />
    </MyUsuTab>
  </AccountPageWrapper>
);

export default AddressesOutput;
