import React from 'react'

const Facebook = () => (
  <svg width={17} height={28} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 17 28'>
    <path
      d="M5.554 27.045V15.61H1.398v-4.73h4.156V7.273c0-4.101 2.443-6.367 6.181-6.367 1.79 0 3.663.32 3.663.32v4.027h-2.063c-2.033 0-2.667 1.262-2.667 2.556v3.07h4.539l-.726 4.73h-3.813v11.436H5.554Z"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>

)

export default Facebook