import React from 'react';

import { returnAlt, returnSrc } from '../../../helpers/contentful';
import AosInit from '../../atoms/Aos/Aos';
import * as styles from './ContentfulImageGrid.module.css';

const ContentfulImageGrid = props => {

  const {
    backgroundColor,
    topLeft,
    topRight,
    center,
    bottomLeft,
    bottomRight,
  } = props;

  const bgColor = backgroundColor ? backgroundColor?.hexCode : 'var(--standard-white)';

  const centerImageAlt = returnAlt(center);
  const centerImageSrc = returnSrc(center);

  const topLeftAlt = returnAlt(topLeft);
  const topLeftSrc = returnSrc(topLeft);

  const topRightAlt = returnAlt(topRight);
  const topRightSrc = returnSrc(topRight);

  const bottomLeftAlt = returnAlt(bottomLeft);
  const bottomLeftSrc = returnSrc(bottomLeft);

  const bottomRightAlt = returnAlt(bottomRight);
  const bottomRightSrc = returnSrc(bottomRight);

  const styleProperties = {
    backgroundColor: bgColor
  }

  return (
    
    <div className={ styles.ContentfulImageGrid } style={styleProperties}>
      <div>
        <AosInit attrs={{'data-aos': 'fade-left'}}>
          <img className={styles.genericImage} alt={topLeftAlt} src={`${topLeftSrc}?w=512&h=690&fit=fill`} />
        </AosInit>
      </div>
      <div className={styles.centerImage}>
        <AosInit>
          <img alt={centerImageAlt} src={`${centerImageSrc}?w=1500&h=1500&fit=fill`} />
        </AosInit>
      </div>
      <div className={styles.hideOnMobile}>
        <AosInit attrs={{'data-aos': 'fade-right'}}>
          <img className={styles.genericImage} alt={topRightAlt} src={`${topRightSrc}?w=512&h=690&fit=fill`} />
        </AosInit>
      </div>

      <div>
        <AosInit attrs={{'data-aos': 'fade-left'}}>
          <img className={styles.genericImage} alt={bottomLeftAlt} src={`${bottomLeftSrc}?w=512&h=690&fit=fill`} />
        </AosInit>
      </div>
      
      <div className={styles.hideOnMobile}>
        <AosInit attrs={{'data-aos': 'fade-right'}}>
          <img className={styles.genericImage} alt={bottomRightAlt} src={`${bottomRightSrc}?w=512&h=690&fit=fill`} />
        </AosInit>
      </div>

    </div>
  );
};

export default ContentfulImageGrid;
