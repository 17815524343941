import React from 'react';
import * as styles from './ContentfulOneContentTwoMediaLayout.module.css';

import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import ContentfulButton from '../../molecules/ContentfulButton/ContentfulButton';
import Container from '../../atoms/Container/Container';

import { returnAlt, returnSrc } from '../../../helpers/contentful/index';

const ContentfulOneContentTwoMediaLayout = props => {
  const { content, backgroundColor, button, largerPicture, picture } = props;

  const largerPictureAlt = returnAlt(largerPicture);
  const largerPictureSrc = returnSrc(largerPicture);

  const pictureAlt = returnAlt(picture);
  const pictureSrc = returnSrc(picture);

  return (
    <div className={ styles.ContentfulOneContentTwoMediaLayout } style={{backgroundColor: backgroundColor?.hexCode}}>
      <Container spacing={'min'} size={'large'}>
        <div className={styles.content}>
          <div className={styles.writeupContainer}>
            <ContentfulRichText raw={content.raw}/>
            <div className={styles.ctaContainer}>
            <ContentfulButton {...button}></ContentfulButton>
            {/* {button && 
              <Button size={'small'} type={'a'} href={button.link?.url} level={'primary'}>
                {button?.label}
              </Button>} */}
            </div>
          </div>
          <div className={styles.largerPictureContainer}>
            <img src={`${largerPictureSrc}?w=430&h=540&fit=fill`} alt={largerPictureAlt} />
          </div>
          <div className={styles.pictureContainer}>
            <img src={`${pictureSrc}?w=260&h=340&fit=fill`} alt={pictureAlt} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContentfulOneContentTwoMediaLayout;
