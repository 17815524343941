import React from 'react'
import Icon from '../Icon/Icon';

import * as styles from './KlarnaInfo.module.css'

const KlarnaInfo = () => {
    return (
        <div className={styles.modal}>
            <div className={`${styles.header} ${styles.bottomLine}`}>
                <div className={styles.svgSection}>
                    <Icon symbol="klarna" />
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomLine}`}>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentAdd" />
                    </div>
                    <p className={styles.blockSubtitle}>Add</p>
                    <p className={styles.blockMessage}>Add items what you want to the bag.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentCheckout" />
                    </div>
                    <p className={styles.blockSubtitle}>Checkout</p>
                    <p className={styles.blockMessage}>Select Klarna at checkout.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentRepay" />
                    </div>
                    <p className={styles.blockSubtitle}>Repay</p>
                    <p className={styles.blockMessage}>Pay off in 4 interest-free installments.</p>
                </div>
            </div>
            <div className={styles.footer}>
                <ul>
                    <li>Use your own valid debit or credit card to pay (no prepaid cards).</li>
                    <li>You must be 18 years old and capable of entering into a legally binding contract. </li>
                    <li>The first payment is charged when the merchant confirms your order (usually the shipping date), the next 3 payments are automatically charged every 2 weeks after that.</li>
                    <li>There are no interest charges with Pay later in 4 installments, and no fees when you follow your automatic payment schedule. If you are delayed in making a payment, we may charge late fees. Any missed installments, including any added late fees, will be rolled over to the next scheduled Due Date. </li>
                    <li>If you cancel your purchase or return your goods we will automatically waive the amount you owe us (as soon as this has been confirmed by the merchant). If you already paid us for a refunded order, we will refund the amount you paid back to you.</li>
                    <li>If you have questions about the quality or shipping of your order, please contact the merchant where you made the purchase.</li>
                    <li>The issuer of your card may charge interest or fees under your agreement with them. If you have any questions about those charges contact your card issuer.</li>
                </ul>
            </div>
        </div>
    );
}

export default KlarnaInfo