import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../atoms/Button/Button'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    root: {
        '& h2': {
            fontSize: 20,
            fontFamily :'"National",Arial,sans-serif'
        },

    }, // a style rule
    label: {
        fontSize: '22px!important'
    }, // a nested style rule
});

const ConfirmDialog = ({ children, title, message, onOk, onCancel, btnOk, btnCancel, disableBackdropClick = false }) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
    }

    const handleCancel = (event, reason) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (onCancel) {
            onCancel()
        }
        setOpen(false)
    }

    const handleOk = () => {
        if (onOk) {
            onOk()
        }
        setOpen(false)
    }
    const classes = useStyles();
    return (
        <>
            <span role="presentation" onClick={handleClick}>{children}</span>
            <Dialog
                // disableBackdropClick
                disableEscapeKeyDown
                keepMounted
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={open}
                onClose={(event, reason) => handleCancel(event, reason)}
            >
                <DialogTitle id="confirmation-dialog-title" className={classes.root}>
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} level="secondary" size="small" type="span" className={classes.label}>
                        {btnCancel || 'Cancel'}
                    </Button>
                    <Button onClick={handleOk} level="primary" size="small" type="span" className={classes.label}>
                        {btnOk || 'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDialog