import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import AuthContext from '../../../context/AuthProvider';
import { handleWebDav } from '../../../helpers/bigcommerce';
import { getBase64 } from '../../../helpers/general';
import { getPerson, processCustomFields, updatePerson } from '../../../helpers/ortto';
import Container from '../../atoms/Container/Container';
import * as styles from './ContentfulFormTemplate.module.css';


const bcPath = process.env.GATSBY_BC_PATH;
const ContentfulFormTemplate = ({
    id,
    formTitle,
    formSubtitle,
    contentfulfields
}) => {
    const orttoUserLoaded = useRef(false);
    const [dynamicFields, setDynamicFields] = useState([])
    const auth = useContext(AuthContext);
    const userEmail = (auth && auth.state.email) || false;
    const [loading, setLoading] = useState(false);

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            // Check here if there are images on the form
            const imageFields = dynamicFields.filter(field => field.file)

            if (imageFields.length > 0) {
                const base64requests = []
                const webDavRequests = []
                //Convert images to base64 before uploading to webdav
                imageFields.forEach(field => {
                    base64requests.push(getBase64(field.file))
                });
                const images = await Promise.all(base64requests)
                images.forEach((element, index) => {
                    webDavRequests.push(handleWebDav(imageFields[index].value, element))
                });
                await Promise.all(webDavRequests)
            }

            //Process the custom fields here (will check existing and create if not)
            const orttoFieldStatus = await processCustomFields(dynamicFields)
          

            if(orttoFieldStatus) {
                let customFields = {};
                if (dynamicFields) {
                    dynamicFields.forEach(field => {
                        if (!['first-name', 'last-name', 'email'].includes(field.type)) {
                            customFields[field.type] = field.value;
                        }
                    })
                }
                const updateResponse = await updatePerson(
                    [
                        {
                            email: userEmail,
                            customFields: customFields
                        }
                    ]
                );
    
                if (updateResponse.status === 200 && "people" in updateResponse.response) {
                    //TODO here. wWhat to display when form is submitted? 
                    toast.success('Form has been submitted.');
                }
            } else {
                toast.error('An error occured while trying to submit the form.');
            }
          

        } catch (error) {
            toast.error('An error occured while trying to submit the form.');
        } finally {
            setLoading(false)
        }
    }


    const dynamicLinkInputHandler = (e) => {
        const newFields = [...dynamicFields];
        const findField = dynamicFields.find(field => field.type === e.currentTarget.name);
        const findFieldIndex = dynamicFields.findIndex(field => field.type === e.currentTarget.name);

        let newFieldItem = {};
        if (findField) {
            if (e.currentTarget.type === 'file') {
                const file = e.target.files[0];
                newFieldItem = {
                    ...findField, file: file,
                    value: `${uuidv4()}.${file.name.split('.')[1]}`
                }
            } else {
                newFieldItem = { ...findField, value: e.currentTarget.value }
            }
            newFields[findFieldIndex] = newFieldItem;
        } else {
            if (e.currentTarget.type === 'file') {
                const file = e.target.files[0];
                newFields.push({
                    type: e.currentTarget.name,
                    file: file,
                    value: `${uuidv4()}.${file.name.split('.')[1]}`
                })
            } else {
                newFields.push({
                    type: e.currentTarget.name,
                    value: e.currentTarget.value
                })
            }
        }

        setDynamicFields(newFields)
    }

    useEffect(() => {
        const pageInit = async () => {
            const fieldsToFetch = contentfulfields.map((field) => `str:cm:${field.orttoFieldName.replace(/ /g, '-').toLowerCase()}`);
            const result = await getPerson(fieldsToFetch.concat(["first", "last", "email"]), userEmail);
            const defaultFields = [];
            if (result.status === 200 && "contacts" in result.response && result.response.contacts.length > 0) {
                const orttoFields = result.response.contacts[0].fields;

                fieldsToFetch.forEach(element => {
                    let currentField;
                    let type = '';
                    if (element === 'str:cm:first-name') {
                        currentField = orttoFields['str::first'];
                        type = 'first-name'
                    } else if (element === 'str:cm:last-name') {
                        currentField = orttoFields['str::last'];
                        type = 'last-name';
                    } else if (element === 'str:cm:email') {
                        currentField = orttoFields['str::email'];
                        type = 'email';
                    } else {
                        currentField = orttoFields[element];
                        type = element.replace('str::', '').replace('str:cm:', '');
                    }
                    if (currentField) {
                        defaultFields.push({
                            type: type,
                            value: currentField
                        })
                    }
                });
                setDynamicFields(defaultFields)
            }
            orttoUserLoaded.current = true;
        }

        if (userEmail && !orttoUserLoaded.current) pageInit();

    }, [userEmail, contentfulfields])


    let formFields = contentfulfields.map((field) => {
        let formField = <div></div>;
        const uniqueId = field.orttoFieldName.replace(/ /g, '-').toLowerCase();
        const data = dynamicFields.find(initField => initField.type === uniqueId);
        switch (field.type) {
            case 'TextBox':
                formField = <div>
                    <p >{field.label} </p >
                    <input name={uniqueId} id={uniqueId} value={data ? data.value : ''} onChange={(e) => dynamicLinkInputHandler(e)} required={field.required} placeholder={field.label} />
                </div>
                break;
            case 'TextArea':
                formField = <div>
                    <p >{field.label}</p >
                    <textarea name={uniqueId} id={uniqueId} value={data ? data.value : ''} onChange={(e) => dynamicLinkInputHandler(e)} rows={3} required={field.required} placeholder={field.label}></textarea>
                </div>
                break;
            case 'Image':
                formField = <div>
                    <p >{field.label}</p >
                    <input type="file" name={uniqueId} id={uniqueId} onChange={(e) => dynamicLinkInputHandler(e)} />
                    <div>{console.log(data)}
                        {data && data.file ? <img src={URL.createObjectURL(data.file)} alt={field.label} />
                            : <img src={data ? `${bcPath}/content/form-images/${data.value}` : ''} alt={field.label} />
                        }

                    </div>
                </div>
                break;
            case 'Dropdown':
                formField = <div>
                    <p >{field.label}</p>
                    <select name={uniqueId} id={uniqueId} value={data ? data.value : ''} onChange={(e) => dynamicLinkInputHandler(e)} required={field.required}>
                        <option value=''>&nbsp;</option>
                        {field.options.map((option) => (
                            <option key={option.replace(/ /g, '-').toLowerCase()} value={option.replace(/ /g, '-').toLowerCase()}>{option}</option>
                        ))}
                    </select>
                </div>
                break;
            default:
                break;
        }
        return formField;

    })
    return (
        <Container>
            <div className={styles.root}>
                <h1>{formTitle}</h1>
                <h3>{formSubtitle}</h3>
                <form action="" onSubmit={formSubmitHandler} >
                    {formFields}
                    <button disabled={loading} type='submit' className={styles.submit}>
                        {loading ? 'Please wait...' : 'Submit'}
                    </button>
                </form>
            </div>
        </Container>
    )
}

export default ContentfulFormTemplate