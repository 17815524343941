import React, {useState, useCallback, useContext, useEffect} from 'react';
import { debounce } from 'lodash';

import CartContext from '../../../context/CartProvider';
import Icon from '../../atoms/Icon/Icon';

import * as styles from './AdjustItem.module.css';

const AdjustItem = (props) => {

  const {isTransparent, initQty, item, disabled} = props;
  const [qty, setQty] = useState(initQty);

  const ctx = useContext(CartContext);
  const { updateCartItemQuantity } = ctx;

  const handleOnChange = (newQty, oldQty) => {
    if(newQty === '') {
      setQty('');
      return;
    }

    const num = parseInt(newQty);
    if(isNaN(num) === false) handler(num, oldQty);
    setQty(num);
  }

  // eslint-disable-next-line
  const handler = useCallback(debounce((quantity, oldQty) => {
    if(quantity !== '' && quantity !== '0') {
      updateCartItemQuantity(item, quantity, oldQty);
    }
  }, 1500), []);

  useEffect(() => {
    if(qty !== item.quantity) setQty(item.quantity);
  // eslint-disable-next-line
  },[item])

  return (
    <div
      disabled={disabled}
      className={`${styles.root} ${isTransparent === true ? styles.transparent : ''} ${isTransparent === true ? styles.noPadding : ''}`}
    >
      <div className={styles.iconContainer} role={'presentation'} onClick={() => {
        if(disabled === true) return;
        if(qty <= 1) return;
        handleOnChange(qty-1, qty);
        // setQty(qty-1);
      }}>
        <Icon symbol={'minusAlt'}></Icon>
      </div>
      <div className={styles.inputContainer}>
        <input
          disabled={disabled}
          className={`${isTransparent === true ? styles.transparentInput : ''}`}
          onChange={(e) => handleOnChange(e.target.value, qty)}
          type={'number'}
          value={qty} />
      </div>
      <div role={'presentation'} onClick={() => {
        if(disabled === true) return;
        // setQty(qty+1);
        handleOnChange(qty+1, qty);
        }} className={styles.iconContainer}>
        <Icon symbol={'plus'}></Icon>
      </div>
    </div>
  );
};

export default AdjustItem;
