import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import { get } from 'lodash';
import { decode } from 'he';
import { navigate } from 'gatsby'
import Layout from '../../organisms/Layout/Layout';
import Seo from '../../organisms/Seo/Seo';
import Container from '../../atoms/Container/Container';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import PageTitle from '../../atoms/PageTitle/PageTitle';
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu';

import * as styles from './AccountPageWrapper.module.css';
import { getStorage } from '../../../helpers/general';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';

const AccountPageWrapper = ({
  children,
  metaTitle,
  title,
  breadcrumbTitle,
  breadcrumbs = [],
  bgRaw,
  hideMenu = false,
  menuType,
  location,
  subtitle,
  hideSubtitle,
}) => {
  const auth = useContext(AuthContext);
  const userChecked = auth && auth.state.userChecked;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const customerId = auth && auth.state.customerId;
  const userEmail = auth && auth.state.email;
  const usuUserData = auth && auth.state.usu;
  const [hotswap, setHotswap] = useState([]);

  // console.log("User state", auth.state);

  useEffect(() => {
    // console.log(fieldErrors);
    if (userChecked && !isLoggedIn && typeof window !== 'undefined') {
      console.log("Not logged - push out");
      window.location = '/login';
    } else {
      const _hotswap = getStorage('_uaca');
      if (_hotswap) {
        const hsObj = JSON.parse(_hotswap);
        setHotswap(hsObj);
      }
    }
  }, [isLoggedIn, userChecked]);

  const actionHotswap = (locationState) => {
    if (typeof window !== 'undefined') {
      navigate(window.location.pathname ,{
          state: locationState
      })
    }
  }
  if (isLoggedIn) {
    const childPage = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          customerid: customerId,
          useremail: userEmail,
        });
      }

      return child;
    });

    return (
      <Layout>
        <Seo title={`Account - ${metaTitle}`} />
        {menuType === 'clubManage' && (
          <div className={styles.administeringBar}>
            Club Executive view administering &nbsp;
            {(hotswap && hotswap.length > 1) && (
              <div className={styles.hotswap}>
                <strong>{decode(get(location, 'state.clubTitle', 'Undefined'))}</strong> <Icon symbol="chevronDown" />
                <div className={styles.list}>
                  {hotswap.filter(c => c.clubId !== get(location, 'state.clubId', 0)).map((c, cId) => (
                    <Button key={cId} type='span' link={true} onClick={() => actionHotswap(c)}>{decode(c.clubTitle)}</Button>
                  ))}
                </div>
              </div>
            )}
            {(!hotswap || hotswap.length <= 1) && (
              <strong>{decode(get(location, 'state.clubTitle', 'Undefined'))}</strong>
            )}
          </div>
        )}
        <Container size='large'>
          <div className={styles.breadcrumbWrap}>
            <Breadcrumbs
              crumbs={[
                { link: '/', label: 'Home' },
                { link: '/account/', label: 'Account' },
                ...breadcrumbs,
                { label: `${breadcrumbTitle || title}` },
              ]}
            />
          </div>

          {hideMenu ? (
            childPage
          ) : (
            <div className={`grid grid-1585 ${styles.gridAccount}`}>
              <div className={styles.sideMenu}>
                <AccountPageMenu
                  isLoggedIn={isLoggedIn}
                  menuType={menuType}
                  location={location}
                  usuUserData={usuUserData}
                />
              </div>
              <div>
                <>
                  {title && <PageTitle customMode>{title}</PageTitle>}
                  {hideSubtitle ||
                  <span className={styles.subtitle}>
                    {subtitle ? subtitle : `Hi ${usuUserData?.FirstName}!`}
                  </span>
                  }
                  <div
                    className={`${title && 'mt-5'} ${
                      styles.childItem
                    } ${bgRaw && styles.childItemPlain}`}>
                    {childPage}
                  </div>
                </>
              </div>
            </div>
          )}
        </Container>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Seo title='Login' />
        <Container></Container>
      </Layout>
    );
  }
};

export default AccountPageWrapper;
