import React, { useState } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { wpAllNew } from '../../../helpers/wordpress';
import DataTable from '../../organisms/DataTable/DataTable';
import Icon from '../../atoms/Icon/Icon';
import { useEffect } from 'react';

const GrantsList = ({
  location,
  filteredResults = false,
  extraTopActions = [],
  staffView = false
}) => {
  const [grants, setGrants] = useState(null);
  const [hasActiveSemester, setActiveSemester] = useState(false);
  const {
    allWpGrantType: { nodes: grantTypes },
  } = useStaticQuery(graphql`
      query {
        allWpGrantType {
          nodes {
            name
            slug
            id: databaseId
            controls: grantDisplayControl {
              enabled
              displayWithinDates {
                to
                from
              }
            }
          }
        }
      }
  `);

  const topActions = staffView ? [] : [
    {
      label: 'New Grant Request',
      icon: <Icon symbol='fileOther' />,
      event: () => navigate(`/account/my-usu/clubs/manage/finances/grants/new`, {
        state: {
          ...location.state,
          hasActiveSemester
        }
      }),
    }
  ];

  const rowActions = staffView ? [
    {
      label: 'View',
      cta: data =>
        navigate(`/account/manage-finances/grant-details`, {
          state: {
            ...location.state,
            fromClubManage: true,
            grant: data,
          },
        }),
    }
  ] : [
    {
      label: 'Manage',
      cta: data =>
        navigate(`/account/my-usu/clubs/manage/finances/grants/details`, {
          state: {
            ...location.state,
            grant: data,
          },
        }),
    },
  ]

  const fieldMap = (grant, grantType) => {
    const fields = {
      id: grant.id,
      name: decodeURI(grant.title.rendered),
      club: grant.acf.club.post_title,
      clubId: grant.acf.club.ID,
      type: grantType?.name,
      status: grant.acf.status
          .substr(
          grant.acf.status.lastIndexOf(':') + 1,
          grant.acf.status.length
          )
          .trim(),
      acquittal: grant.acf.acquittal,
      valueGranted: grant.acf.value_granted,
      valueProvided: grant.acf.value_provided,
      submittedBy: grant.acf.submitted_by,
      submittedDate: grant.date,
      reviewedBy: grant.acf.reviewed_by,
      reason: grant.acf.reason,
      associated_event: grant.acf.associated_event,
      approvedBy: grant.acf.approved_by,
      paidBy: grant.acf.paid_by,
      additionalInformation: grant.acf.additional_information
    }

    if (staffView) {
      fields.staff_notes = grant.acf.staff_notes;
    }

    return fields;
  }

  useEffect(() => {
    const fetchFinances = async () => {
      const fetchGrantsList = async () => {
          const _grants = [];
          try {
              if (location.state.clubId) {
                  const { response: allGrantsList } = await wpAllNew(`getClubGrantsList`, {
                    clubId: location.state.clubId,
                    as: staffView ? 'staff' : 'exec',
                    fieldSet: 1
                  });
                  allGrantsList.map(grant => {
                    // Check if we have an pending or active semester grant and update state
                    const grantType = grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]));
                    if (grantType && (grantType?.slug === "semester" || grantType?.slug === "semester2") && grant.acf.status.toLowerCase() !== 'rejected' && !grant.acf.acquittal) {
                      setActiveSemester(true);
                    }
                    return true;
                  });
                  const grantsList = filteredResults ? allGrantsList.filter(g => g.acf.status.toLowerCase() === 'paid' && !g.acf.acquittal) : allGrantsList;
                  _grants.push(...grantsList.map(grant => {
                    const grantType = grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]));
                    return fieldMap(grant, grantType);
                  }))
              }
          } catch (e) {
              console.error(e);
          } finally {
              return _grants;
          }
      };

      const _grants = await fetchGrantsList();

      setGrants(_grants);
    }

    if (grants === null) {
      fetchFinances();
    }
  })
  
  return (
    <>
      {grants === null ? (
        <>Fetching grants...</>
      ) : (
        <DataTable
          tableData={grants}
          bulkActions={[]}
          topActions={[
            ...extraTopActions,
            ...topActions,
          ]}
          headingKeys={[
            { label: 'Type', data_key: 'type', sortable: true, filterable: true },
            { label: 'Status', data_key: 'status', sortable: true, filterable: true },
            {
              label: 'Amount Requested',
              data_key: 'valueGranted',
              sortable: false,
              format: 'currency',
            },
            {
              label: 'Amount Provided',
              data_key: 'valueProvided',
              sortable: false,
              format: 'currency',
            },
            { label: 'Submitted by', data_key: 'submittedBy', filterable: true },
            {
              label: 'Submitted date',
              data_key: 'submittedDate',
              sortable: true,
              format: 'date',
            },
          ]}
          rowActions={rowActions}
        />
      )}
    </>
  );
};

export default GrantsList;

