import React from 'react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';

import * as styles from './maintenance.module.css';
import Logo from '../components/atoms/Logo/Logo';

const Maintenance = () => {
  return (
    <Layout bare={true} noPadding={true}>
      <Seo title="Currently under maintenance" />
      <div className={styles.layout}>
        <Container>
            <div className={styles.logo}>
                <Logo />
            </div>
            <h1 className={styles.title}>We're busy innovating</h1>
            <p className={styles.description}>
                We are currently working on improving the experience for you! Please check back again in a few minutes.
            </p>
        </Container>
      </div>
    </Layout>
  );
};

export default Maintenance;
