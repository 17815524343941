/**
 * Stripe Helper
 **/

/**
 * Stripe Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Bepoz API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { stripeApi } from '../helpers/stripe'

    stripeApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function stripeApi(endpoint, body, method = 'POST') {
  const options = {
      method: method ? method : 'GET',
      credentials: 'same-origin',
      mode: 'same-origin'
  };
  
  if (body) {
      let bodyString = body;
      if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
      }
  
      options.body = bodyString;
  }
  
  const parseJson = async response => {
      const text = await response.text();
      try {
      const json = JSON.parse(text);
      return json;
      } catch (err) {
      return text;
      }
  };
  
  const encodedEndpoint = Buffer.from(endpoint).toString('base64');
  
  if (!(typeof fetch === 'undefined')) {
      return await fetch(
      `${process.env.LAMBDA_PATH}stripe?endpoint=${encodedEndpoint}`,
      options
      ).then(async res => ({ response: await parseJson(res), status: res.status }));
  } else {
      return {response: null, status: 404}
  }
}

/**
 * Setup Payment Intent
 *
 * Create the payment object with stripe to initiate the payment form
 * 
 * @param   {Number} amount            Amount the transaction is for
 * @param   {String} connectId         The Stripe Connect ID for the transaction
 * @param   {String} customerId        The Stripe Customer ID for the transaction
 * @param   {Object} metaData          A key:value object of data to store against the payment intent
 * 
 * @return  {Object}                {response, status}
 *
    import { paymentIntent } from '../helpers/stripe'

    paymentIntent(10, 'acct_1234', 'cus_6754', {}).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function paymentIntent(amount, connectId, customerId, metaData) {
  // console.log(amount, connectId, metaData);
  const response = await stripeApi('paymentIntent', {amount, connectId, customerId, metaData});
  
  return response;
}

/**
 * Fetch Stripe Customer Record
 *
 * Fetch the customer record on stripe for a given email address
 * 
 * @param   {String} email         The email address of the user
 * @param   {String} connectId     The ID for the connect account the customer should be attached to
 * 
 * @return  {Object}                {response, status}
 *
    import { fetchCustomer } from '../helpers/stripe'

    fetchCustomer('email@domain.com', 'acct_1234').then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function fetchCustomer(email, connectId) {
  // console.log(email);
  const response = await stripeApi('fetchCustomer', {email, connectId});
  
  return response;
}

/**
 * Create Stripe Customer Record
 *
 * Create the customer record on stripe for a given email address
 * 
 * @param   {String} email         The email address of the user
 * @param   {String} name          The name of the user
 * @param   {Object} metaData      A key:value object of additional data to attach to the user
 * @param   {String} connectId     The ID for the connect account the customer should be attached to
 * 
 * @return  {Object}                {response, status}
 *
    import { createCustomer } from '../helpers/stripe'

    createCustomer('email@domain.com', 'Bob Smith', {customerId: 123}).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function createCustomer(email, name, metaData, connectId) {
  // console.log(email, name, metaData);
  const response = await stripeApi('createCustomer', {email, name, metaData, connectId});
  
  return response;
}

/**
 * Update Stripe Customer Record
 *
 * Update the customer record on stripe for a given email address
 * 
 * @param   {String} id            Stripe customer ID
 * @param   {Object} data          Data to update the customer record with
 * @param   {String} connectId     The ID for the connect account the customer should be attached to
 * 
 * @return  {Object}                {response, status}
 *
    import { updateCustomer } from '../helpers/stripe'

    updateCustomer('cus_123', {phone: '1234', metadata: {'Customer ID': '9876'}}, 'acct_1234').then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function updateCustomer(id, data, connectId) {
  // console.log(id, data);
  const response = await stripeApi('updateCustomer', {id, data, connectId});
  
  return response;
}

/**
 * Upsert Stripe Customer Record
 *
 * Check if a customer record on stripe for a given email address already exists, update and return the object, otherwise create
 * 
 * @param   {String} email         The email address of the user
 * @param   {String} name          The name of the user
 * @param   {Object} metaData      A key:value object of additional data to attach to the user
 * 
 * @return  {Object}                {response, status}
 *
    import { upsertCustomer } from '../helpers/stripe'

    upsertCustomer('email@domain.com', 'Bob Smith', {customerId: 123}).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function upsertCustomer(email, name, metaData, connectId) {
  // console.log(email, name, metaData);
  const fetchCustomer = await stripeApi('fetchCustomer', {email, connectId});
  // console.log(fetchCustomer);
  if (String(fetchCustomer.status).startsWith("2")) {
    if (fetchCustomer.response.data.length > 0) {
      // Customer record found
      // console.log(fetchCustomer.response.data);
      const record = fetchCustomer.response.data[0];
      const updateCustomer = await stripeApi('updateCustomer', {id: record.id, data: {name, metadata: metaData}, connectId});
      if (String(updateCustomer.status).startsWith("2")) {
        return updateCustomer;
      } else {
        return {status: 500, response: {error: 'Update call failed', response: updateCustomer}};
      }
    } else {
      // No record found for email - create
      const newCustomer = await stripeApi('createCustomer', {email, name, metaData, connectId});
      // console.log(newCustomer);
      if (String(newCustomer.status).startsWith("2")) {
        return newCustomer;
      } else {
        return {status: 500, response: {error: 'Create call failed', response: newCustomer}};
      }
    }
  } else {
    // TODO: Handle error with call
    return {status: 500, response: {error: 'Fetch call failed', response: fetchCustomer}};
  }
}

export { 
  stripeApi,
  paymentIntent,
  fetchCustomer,
  createCustomer,
  updateCustomer,
  upsertCustomer
};
