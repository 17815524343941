// extracted by mini-css-extract-plugin
export var ContentfulBlockLinkContainer = "ContentfulBlockLinkContainer-module--ContentfulBlockLinkContainer--482b6";
export var containerCenter = "ContentfulBlockLinkContainer-module--containerCenter--fea1b";
export var containerLeft = "ContentfulBlockLinkContainer-module--containerLeft--abc00";
export var containerRight = "ContentfulBlockLinkContainer-module--containerRight--49ee7";
export var paddingBottomLarge = "ContentfulBlockLinkContainer-module--paddingBottomLarge--a46aa";
export var paddingBottomMedium = "ContentfulBlockLinkContainer-module--paddingBottomMedium--18b93";
export var paddingBottomSmall = "ContentfulBlockLinkContainer-module--paddingBottomSmall--900cf";
export var paddingBottomXLarge = "ContentfulBlockLinkContainer-module--paddingBottomXLarge--9b697";
export var paddingLarge = "ContentfulBlockLinkContainer-module--paddingLarge--d06d5";
export var paddingMedium = "ContentfulBlockLinkContainer-module--paddingMedium--4efed";
export var paddingNone = "ContentfulBlockLinkContainer-module--paddingNone--3302b";
export var paddingSmall = "ContentfulBlockLinkContainer-module--paddingSmall--09010";
export var paddingXLarge = "ContentfulBlockLinkContainer-module--paddingXLarge--097df";