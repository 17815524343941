import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import { readTime } from '../../../helpers/general';
// import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
// import SocialShare from '../../molecules/SocialShare/SocialShare';
// import Button from '../../atoms/Button/Button';

import './content.css';
import * as styles from './BlogPost.module.css';
import Container from '../../atoms/Container/Container';

const BlogPost = ({ post }) => {
    const title = post.title;
    // const slug = post.slug;
    const authorName = post.author_name;
    // const authorUri = post.author_uri;
    const date = post.published_date;
    const content = post.content;
    const processedContent = post.contentArr;
    // const categories = post.categories || [];
    // const thumbnail = post.thumbnail_path;
    // const categoryUri = post.categories?.length > 0 ? post.categories[0].uri : 'all';
    // const categoryName = post.categories?.length > 0 ? post.categories[0].name : 'all';

    const categories = useMemo(() => {
        return post.categories || [];
    }, [post]);
    const categoryName = post.categories?.length > 0 ? post.categories[0].name : 'all';

    // const timeToRead = useMemo(() => {
    //     if (content) {
    //       return readTime(content);
    //     }
    //     return 0;
    // }, [content]);

    const categoriesTags = useMemo(() => {
        if (categories) {
          return categories.map((cat, catIdx) => (
            <Link to={cat.uri} key={catIdx} className={`${styles.categoryLink} link`}>
              {cat.name}
            </Link>
          ));
        }
        return [];
    }, [categories]);

    // const thumbnailUrl = useMemo(() => {
    //     if (thumbnail) {
    //       return thumbnail.replace('http:', 'https:');
    //     }
    
    //     return thumbnail;
    // }, [thumbnail])

    return (
        <>
            <Container size={'medium'}>
                {/* {thumbnailUrl && <div className={styles.postImageContainer}>
                    <div className={styles.postImage} style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>
                    <img
                        loading="lazy"
                        className={styles.postImage}
                        src={thumbnailUrl}
                        alt={title}
                    />
                </div>} */}

                <div className={styles.blog}>
                    {/* <Breadcrumbs crumbs={[{ link: '/', label:'Home' }, { link: '/blog/', label: 'Blog' }, { link: categoryUri, label: categoryName ? categoryName : '' }, { label: title }]} /> */}
                    
                    {/* <div className={styles.postImageContainer}>
                        <img
                            loading="lazy"
                            className={styles.postImage}
                            src={thumbnailUrl}
                            alt={title}
                        />
                    </div> */}

                    <div className={styles.postBody}>
                        <div className={styles.postTitle}>
                            <h1>{title}</h1>
                            <div className={styles.postDate}>
                                <span>
                                {categoryName === 'Blogs' ? authorName : date} · {categories && categories.length > 0 ? categoriesTags : ``}
                                {/* TODO: Handle if under blog category show below instead */}
                                {/* By {authorName} · {categories && categories.length > 0 ? `${categoriesTags}` : ``} */}
                                </span>
                            </div>
                        </div>
                        {processedContent && (
                            <>
                            {processedContent.map((node, nodeIndex) => (
                                <React.Fragment key={nodeIndex}>
                                    {typeof node === 'string' && (
                                        <div
                                            className={`${styles.postContent} post-body`}
                                            dangerouslySetInnerHTML={{
                                            __html: node
                                            }}
                                        />
                                    )}
                                    {typeof node === 'object' && (
                                        <>
                                            {node}
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                            </>
                        )}

                        {!processedContent && content && (
                            <div
                                className={`${styles.postContent} post-body`}
                                dangerouslySetInnerHTML={{
                                __html: content
                                }}
                            />
                        )}

                        {/* {categoryName && (
                            <div className={styles.viewMore}>
                                <Button level="primary" href={categoryUri}>More {categoryName}</Button>
                            </div>
                        )} */}
                    </div>
                    {/* <div className={styles.socials}>
                        <SocialShare url={`${process.env.SITE_URL}${slug}`} text={title} imageUrl={thumbnailUrl} />
                    </div>
                    {post && post.tags && (
                        <div className={styles.postTags}>
                            POSTED IN: 
                            {post.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className={styles.postTag}>
                                    <a href={`/blog/tag/${tag.slug}`}>{tag.name}</a>
                                </span>
                            ))}
                        </div>
                    )} */}
                    {/* {categoriesTags && <div className={styles.topCategories}>{categoriesTags}</div>} */}
                </div>
            </Container>
        </>
    );
};

BlogPost.propTypes = {
  post: PropTypes.shape({}).isRequired
};

export default BlogPost;
