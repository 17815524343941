// import { Link } from 'gatsby';
import React, { useContext, useMemo } from 'react';
import Marquee from "react-fast-marquee";
// import Accordion from '../../../molecules/AccordionAlt/Accordion';
// import Dropdown from '../../../atoms/Dropdown/Dropdown';
// import FormInputField from '../../../atoms/FormInputField/FormInputField';
import ContentfulContext from '../../../../context/ContentfulProvider';
import GlobalContent from '../../../atoms/ContentfulGlobalContent/ContentfulGlobalContent';
import Icon from '../../../atoms/Icon/Icon';
import Accordion from '../../../molecules/AccordionAlt/Accordion';
// import Config from '../../../../config.json';
import Container from '../../../atoms/Container/Container';

// import footerSampleLinks from './footer.json';

import * as styles from './Footer.module.css';
// import * as customAccordionStyles from './CustomAccordion.module.css'
import SmartLink from '../../../atoms/SmartLink/SmartLink';

const X_DUPLICATE_MESSAGE = 10;

// convert to component if needed
const Store = ({icon, title, link}) => {
    return(
      <div className={styles.store} onClick={()=>window.open(link)} role={'presentation'}>
          <Icon symbol={icon}/>
          <span>{title}</span>
      </div>
    )
}

const Footer = ({pageBannerLinks}) => {
  const contentful = useContext(ContentfulContext);

  const mainFooterMenu = useMemo(() => (contentful && contentful.footerMenu?.menuGroups?.map(menuGroup => menuGroup)) || [], [contentful]);
  const secondaryFooterMenu = useMemo(() => (contentful && contentful.secondaryFooterMenu?.map(menuItem => menuItem)) || [], [contentful]);
  const socialLinks = useMemo(() => (contentful && contentful.socialLinks) || [], [contentful]);
  const storeLink = useMemo(() => (contentful && contentful.storeLinks) || [], [contentful]);
  const defaultBannerLinks = useMemo(() => (contentful && contentful.bannerLinks) || [], [contentful]);
  
  // const marqueeCollection = Array(10).fill(marqueeMessage);
  // const marqueeMessage = bannerMessage !== undefined ? bannerMessage : contentful?.bannerMessage;

  const bannerCollection = [];
  if(Array.isArray(pageBannerLinks) === true && pageBannerLinks.length > 1) {
    for(let x = 0; x < X_DUPLICATE_MESSAGE; x++) {
      bannerCollection.push(...pageBannerLinks);
    }
  } else {
    for(let x = 0; x < X_DUPLICATE_MESSAGE; x++) {
      bannerCollection.push(...defaultBannerLinks);
    }
  }

  const handleSocialClick = (platform) => {
    window.open(socialLinks[platform]);
  }

  const renderSocialLinks = () => {
    return(
      <div className={styles.socialContainer}>
        <div onClick={() => handleSocialClick('instagram')} role={'presentation'} className={styles.socialIconContainer}>
          <Icon symbol={'instagram'}></Icon>
        </div>
        <div onClick={() => handleSocialClick('facebook')} role={'presentation'} className={styles.socialIconContainer}>
          <Icon symbol={'facebook'}></Icon>
        </div>
        <div onClick={() => handleSocialClick('youTube')} role={'presentation'} className={`${styles.socialIconContainer}`}>
          <Icon symbol={'youtubeAlt'}></Icon>
        </div>
        <div onClick={() => handleSocialClick('tiktok')} role={'presentation'} className={`${styles.socialIconContainer}`}>
          <Icon symbol={'tiktok'}></Icon>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <Marquee speed={26} gradient={false}>
        <div className={styles.marqueeContainer}>
          {/* {bannerCollection.length === 0 && marqueeCollection.map((element, index) => <span className={styles.marqueeMessage} key={index}>{element}</span>)} */}
          {bannerCollection.length !== 0 && bannerCollection?.map((bLink, index) => {
            return(
              <a key={index} href={bLink.link.url} className={styles.marqueeMessage}>
                {bLink.message}
              </a>
            )
          })}
        </div>
      </Marquee >
      <Container size={'xl'}>
        <div className={styles.content}>
          <div className={styles.contentGrid}>

            <div className={styles.policyContainer}>
           
              <div className={styles.storeContainer}>
                <Store title={'App Store'} icon={'appStore'} link={storeLink?.appStore} />
                <Store title={'Google Play'} icon={'playStore'} link={storeLink?.playStore} />
              </div>
            </div>

            {/* <div className={styles.footerLinkWrapper}> */}
              {mainFooterMenu.map((linkCollection, indexLink) => {
                return(
                  <div className={`${styles.footerGroup} ${styles.footerWebGroup}`} key={indexLink}>
                    <span className={styles.menuGroupLabel}>{linkCollection.label}</span>
                    <div className={styles.footerLinkContainer} key={indexLink}>
                      {linkCollection?.menuItems?.map((footerLink, footerLinkIndex) => <SmartLink key={footerLinkIndex} linkStyle={styles.link} menuLink={footerLink} />)}
                    </div>
                    {indexLink === mainFooterMenu.length - 1 && renderSocialLinks()}
                  </div>
                )
              })}
            {/* </div> */}

            <div className={styles.mobileFooterLinkWrapper}>
              {mainFooterMenu.map((linkCollection, indexLink) => {
                  return(
                    <div key={indexLink} className={styles.footerGroup}>
                      <Accordion customStyle={styles} maxHeight={'1000px'} emptyMessage={'No Links'} type={'plus'} title={linkCollection.label}>
                        <div className={styles.footerLinkContainer} key={indexLink}>
                          {linkCollection?.menuItems?.map((footerLink, footerLinkIndex) => <SmartLink key={footerLinkIndex} linkStyle={styles.link} menuLink={footerLink} />)}
                        </div>
                      </Accordion>
                    </div>
                  )
                })}
            </div>

          </div>
        </div>

        <div className={styles.mobileFooterSocialWrapper}>
          {renderSocialLinks()}
        </div>
        
        <div className={styles.reminderContainer}>
          <GlobalContent showTitle={false} name={'Footer Message'}></GlobalContent>
        </div>

        <div className={styles.contentBottomWrapper}>
          <div className={styles.contentBottomContainer}>
              <span>© {new Date().getFullYear()} USU</span>
              {secondaryFooterMenu?.map((secondaryFooterLink, index) => <SmartLink key={index} menuLink={secondaryFooterLink}></SmartLink>)}
              {/* <Link to="/">Sitemap</Link>
              <Link to="/">Privacy Policy</Link>
              <Link to="/">Terms of Use</Link> */}
          </div>
          {/* <div className={styles.linkFooterContainer} role={'presentation'} onClick={() => setShowFooterLinks(true)}>
            <span>Full list of links</span>
            <Icon symbol={'caretDown'}></Icon>
          </div> */}
        </div>
      </Container>
      {/* <div className={styles.contentBottomContainer}>
          <Container size={'large'}>
            <span>2021 (c) . Built by Matter. Powered by JAMM</span>
            <div className={styles.contentBottom}>
              <div className={styles.settings}>
                <Dropdown label={'Country/Region'} optionList={Config.currencyList}/>
                <Dropdown label={'Language'} optionList={Config.languageList}/>
              </div>
              <div className={styles.copyrightContainer}>
                <div className={styles.creditCardContainer}>
                  {Config.paymentOptions.amex && <img className={styles.amexSize} src={'/amex.png'} alt={'amex'}></img>}
                  {Config.paymentOptions.mastercard && <img className={styles.masterSize} src={'/master.png'} alt={'mastercard'}></img>}
                  {Config.paymentOptions.visa && <img className={styles.visaSize} src={'/visa.png'} alt={'visa'}></img>}
                </div>
                <span>2021 (c) . Built by Matter. Powered by JAMM</span>
              </div>
            </div>
          </Container>
      </div> */}
      {/* {showFooterLinks === true && <>
        <div className={styles.footerCompleteLink}>
          {footerSampleLinks.map((footerGroup) => {
            return(<div className={styles.footerGroupContainer}>
              <span className={styles.footerGroupLabel}>{footerGroup.groupLabel}</span>
              {footerGroup?.links.map((footerLink) => 
                <Link className={styles.footerLink} to={footerLink.link}>{footerLink.label}</Link>)
              }
            </div>)
          })}
            <div className={styles.footerClose} role={'presentation'} onClick={() => setShowFooterLinks(false)}>
              <span>Close</span>
              <Icon symbol={'caretDown'}></Icon>
            </div>
        </div>
        <div className={styles.footerBackdrop}></div>
      </>} */}

      {/* <div className={`${styles.footerBackdrop} ${showFooterLinks === true ? '' : styles.backdropHide}`}></div>
      <div className={`${styles.footerCompleteLink} ${showFooterLinks === true ? styles.footerShow : styles.footerHide}`}>
        {footerSampleLinks.map((footerGroup) => {
          return(<div key={footerGroup?.groupLabel} className={styles.footerGroupContainer}>
            <span className={styles.footerGroupLabel}>{footerGroup.groupLabel}</span>
            {footerGroup?.links.map((footerLink) => 
              <Link key={footerLink.label} className={styles.footerLink} to={footerLink.link}>{footerLink.label}</Link>)
            }
          </div>)
        })}
          <div className={styles.footerClose} role={'presentation'} onClick={() => setShowFooterLinks(false)}>
            <span>Close</span>
            <Icon symbol={'caretDown'}></Icon>
          </div>
      </div> */}

    </div>
  );
};

export default Footer;
