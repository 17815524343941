import React from 'react';
import * as styles from './Flag.module.css';

const Flag = props => {

  const { title = 'sample', color='var(--standard-white)', bgColor='var(--standard-black)'} = props;

  return (
    <div className={ styles.Flag } style={{backgroundColor: bgColor}}>
      <span style={{color: color}} className={styles.title}>{title}</span>
    </div>
  );
};

export default Flag;
