// extracted by mini-css-extract-plugin
export var buttonGroup = "EventSubmissionForm-module--buttonGroup--706ca";
export var changeSelection = "EventSubmissionForm-module--changeSelection--83747";
export var checkBox = "EventSubmissionForm-module--checkBox--a3280";
export var close = "EventSubmissionForm-module--close--7f646";
export var containerLoading = "EventSubmissionForm-module--containerLoading--19636";
export var dialogContent = "EventSubmissionForm-module--dialogContent--5aa4e";
export var dropZone = "EventSubmissionForm-module--dropZone--7be3c";
export var dropZoneLabel = "EventSubmissionForm-module--dropZoneLabel--1f4f7";
export var dropZoneNotice = "EventSubmissionForm-module--dropZoneNotice--d1011";
export var editWarningText = "EventSubmissionForm-module--editWarningText--20e6f";
export var error = "EventSubmissionForm-module--error--d3aa9";
export var eventTimeList = "EventSubmissionForm-module--eventTimeList--6c2db";
export var genericCta = "EventSubmissionForm-module--genericCta--9bb08";
export var getAvailabilityCta = "EventSubmissionForm-module--getAvailabilityCta--df39e";
export var results = "EventSubmissionForm-module--results--3d438";
export var role = "EventSubmissionForm-module--role--7c11d";
export var roles = "EventSubmissionForm-module--roles--af90f";
export var roomControls = "EventSubmissionForm-module--roomControls--cc570";
export var roomsLoader = "EventSubmissionForm-module--roomsLoader--b9f12";
export var root = "EventSubmissionForm-module--root--9bc98";
export var selectButton = "EventSubmissionForm-module--selectButton--9ca74";
export var selectContainer = "EventSubmissionForm-module--selectContainer--8d683";
export var spaceFormContainer = "EventSubmissionForm-module--spaceFormContainer--1c8e9";
export var spacioVenueDetails = "EventSubmissionForm-module--spacioVenueDetails--90163";
export var submitButton = "EventSubmissionForm-module--submitButton--2ab81";
export var success = "EventSubmissionForm-module--success--0e0b8";
export var top = "EventSubmissionForm-module--top--f0b3b";
export var updateResourceBtn = "EventSubmissionForm-module--updateResourceBtn--13738";