import React from 'react'

import * as styles from './Container.module.css'

const Container = ({ children, size, spacing, fullMobile, classes }) => {
  return (
    <div className={`
      ${styles.container} 
      ${classes ? classes : ''}
      ${size ? styles[size] : ''} 
      ${spacing ? styles[spacing] : ''}
      ${fullMobile === true ? styles.fullMobile : ''}
      `}>
      {children}
    </div>
  )
}

export default Container
