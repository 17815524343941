import React from 'react'; // , { useContext, useMemo, useState }
import { useStaticQuery, graphql } from 'gatsby'; // , Link

// import { transformToEventDate } from '../../../helpers/general'; 
// import { getPerson, updatePerson } from '../../../helpers/ortto';
// import { success } from '../../../helpers/toast';
import { transformWpEvent } from '../../../helpers/wordpress';

// import AuthContext from '../../../context/AuthProvider';
// import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import JoinEvent from '../../atoms/JoinEvent/JoinEvent';

import * as styles from './ContentfulBlockEventButton.module.css';

const ContentfulBlockEventButton = props => {

  // const { ctaText } = props;
  // const auth = useContext(AuthContext);
  // const [status, setStatus] = useState(null);

  // const isLoggedIn = auth && auth.state.isLoggedIn;
  // const userEmail = (auth && auth.state.email) || false;
  
  // connect wordpress data via slug
  const segments = typeof window !== 'undefined' ? window.location.href.split('/') : undefined;
  const eventName = segments ? (segments.pop() || segments.pop()) : undefined; // Handle potential trailing slash
  // const orttoKey = eventName ? `event-${eventName}` : undefined;
  
  const eventsData = useStaticQuery(graphql`
      query {
        allWpEvent {
          edges {
            node {
              id
              date
              title
              slug
              eventFieldsMain {
                dayTripLength
                dayTripTimes
                dietaryRequirements
                domesticInternational
                findOutDayTrips
                futureDayTrips
              }
              eventFieldsSide {
                startDate
                endDate
                startTime
                endTime
                location
                eventFee
                club {
                  ... on WpClub {
                    title
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `);
  
  const eventDetailsArray = eventsData?.allWpEvent?.edges?.filter((e) => e.node.slug === eventName).map((e) => e?.node);
  const eventDetails = eventDetailsArray.length === 1 ? eventDetailsArray[0] : undefined;
  const eventData = eventDetails !== undefined ? transformWpEvent(eventDetails) : undefined;

  // useMemo(async () => {
  //   // If user is logged in, check if they are already a member of the club
  //   if (isLoggedIn && status === null) {
  //       const result = await getPerson(["tags"], userEmail);
  //       if (result.status === 200 && "contacts" in result.response && result.response.contacts.length > 0) {
  //           // Successfully found
  //           if (result.response.contacts[0].fields && result.response.contacts[0].fields.tags && result.response.contacts[0].fields.tags.indexOf(orttoKey) > -1) {
  //               setStatus(true);
  //           } else {
  //               setStatus(false);
  //           }
  //       } else {
  //           setStatus(false);
  //       }
  //   }
  // }, [isLoggedIn, userEmail, status, orttoKey]);

  // const joinEvent = async () => {
  //   if (status === false) {
  //       const clubKey = orttoKey;
  //       const userKey = userEmail;

  //       const person = [
  //           {
  //               email: userKey,
  //               tags: [clubKey],
  //           }
  //       ]
  //       const updateResponse = await updatePerson(person);

  //       if (updateResponse.status === 200 && "people" in updateResponse.response) {
  //           // Successfully added
  //           setStatus(true);
  //           success('Event registered');
  //       } else {
  //           // Error occurred
  //           // TODO: Show error somewhere
  //       }
  //   }
  // }

  return (
    <div className={ styles.ContentfulBlockEventButton }>
      <Container>
        <div className={styles.root}>
          {eventData && <JoinEvent event={eventData} />}
          {/* {isLoggedIn 
           ?(
           <Button
              fullWidth
              level={'primary'}
              type={'button'}
              disabled={status !== false}
              onClick={() => joinEvent()}>
             {status === true ? 'Registered' : ctaText}
            </Button>)
           :(<Button level="primary" type="a" href="/login/">Log in</Button>)
          }
          <div className={styles.fieldListContainer}>
            {eventDetails?.eventFieldsSide.startDate && eventDetails?.eventFieldsSide.endDate && 
              <div className={styles.fieldContainer}>
                <span className={styles.label}>Date: </span>
                <span>{transformToEventDate(eventDetails?.eventFieldsSide?.startDate, eventDetails?.eventFieldsSide?.endDate, true).join(' - ')}</span>
              </div>
            }
            {eventDetails?.eventFieldsSide.eventFee &&
              <div className={styles.fieldContainer}>
                <span className={styles.label}>Fee: </span>
                <span>${eventDetails?.eventFieldsSide.eventFee}</span>
              </div>
            }
            {eventDetails?.eventFieldsSide.location &&
              <div className={styles.fieldContainer}>
                <span className={styles.label}>Location: </span>
                <span>${eventDetails?.eventFieldsSide.location}</span>
              </div>
            }
            {eventDetails?.eventFieldsSide?.club?.title && eventDetails?.eventFieldsSide?.club?.slug && 
              <div className={styles.fieldContainer}>
                <span className={styles.label}>Host: </span>
                <Link to={`/clubs/${eventDetails?.eventFieldsSide?.club?.slug}`}>
                  <span>{eventDetails?.eventFieldsSide?.club?.title}</span>
                </Link>
              </div>
            }

          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default ContentfulBlockEventButton;
