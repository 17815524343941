import React, { useState, useContext, useMemo, useEffect } from 'react';
import { get } from 'lodash'; // , set
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import Icon from '../../atoms/Icon/Icon';
import DataTable from '../DataTable/DataTable';
// import Button from '../../atoms/Button/Button';
import ReceiptDialog from '../../molecules/ReceiptDialog/ReceiptDialog';
import { getBase64, extractDate } from '../../../helpers/general';
import {
  wpApiNew,
  wpAllNew,
  createNewReceipt,
  uploadMedia,
  deleteReceipt,
} from '../../../helpers/wordpress';

import * as styles from './receipts.module.css';

const LoadingState = () => (
  <div className={styles.loading}>
    <p>Loading...</p>
  </div>
);

const ReceiptsList = ({ receipts, headers, hasAcquittal, onNewReceipt, extraTopActions, onReceiptUpdate, deleteReceipt, withRowActions, bulkActions }) => {
  const topActions = []
  if (!hasAcquittal) {
    topActions.push({
      label: 'Add Receipt',
      icon: <Icon symbol='fileOther' />,
      event: () => onNewReceipt(),
      // navigate(`/account/my-usu/clubs/manage/grants/new-request`, {
      //   state: { clubSlug, clubId, clubTitle, clubStatus, grants },
      // }),
    })
  }
  return (
    <DataTable
      tableData={receipts}
      topActions={[
        ...extraTopActions,
        ...topActions
      ]}
      bulkActions={bulkActions} 
      headingKeys={headers}
      noFirstAction={true}
      rowActionsFlag={true}
      rowActions={withRowActions ? [
        {
          label: 'Update receipt',
          cta: data => {
            onReceiptUpdate(data);
          },
        },
        {
          label: 'Delete receipt',
          cta: data => {
            deleteReceipt(data);
          },
        }
      ] : []}></DataTable>
  );
};

const Receipts = ({ clubId, grantId, hasAcquittal, remit, type, customReceiptData = false, extraTopActions = [], withRowActions = false, bulkActions = false, filterGrant = false }) => {
  const auth = useContext(AuthContext);
  const memberDetails = auth && get(auth, 'state');

  const [isLoading, setIsLoading] = useState(true);
  const [receipts, setReceipts] = useState([]);
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  const [updateReceipt, setUpdateReceipt] = useState(null);
  const [submittedGrants, setSubmittedGrants] = useState(null);

  const headers = useMemo(() => {
    // if (type === "latest") {
    //   return [
    //     {
    //       label: 'Value',
    //       data_key: 'value',
    //       sortable: false,
    //       format: 'currency',
    //     },
    //     {
    //       label: 'Submitted date',
    //       data_key: 'submittedDate',
    //       sortable: false,
    //     },
    //   ]
    // } else {
      return [
        {
          label: 'Receipt',
          data_key: 'receipt',
          format: 'image',
        },
        {
          label: 'Value',
          data_key: 'value',
          sortable: false,
          format: 'currency',
        },
        { 
          label: 'Detail', 
          data_key: 'detail', 
          sortable: false,
          format: 'html'
        },
        {
          label: 'Submitted',
          data_key: 'submitted',
          sortable: false,
          format: 'html'
        },
        ...(filterGrant ? [{
          label: 'Grant',
          data_key: 'grant',
          sortable: false,
          format: 'html',
          filterable: true,
          hidden: true
        }] : []),
      ]
    // }
  }, [filterGrant])

  const receiptData = (rData, acquittalGrants = null, apprvAcquittalsReceipts = null) => {
    const approved = apprvAcquittalsReceipts ? apprvAcquittalsReceipts.filter((acquit) => {
      return acquit.receipt.ID === rData.id
    }) : [];

    const notAllowedGrant = acquittalGrants ? acquittalGrants.filter((acquit) => {
      return acquit.grant.ID === rData?.acf?.grant?.ID;
    }) : [];

    const _receiptData = {
      id: rData.id,
      receipt: rData.acf.receipt,
      value: rData.acf.value,
      grant: rData.acf.grant.post_title.split(' - ')[1],
      grant_id: rData.acf.grant.ID,
      description: rData.acf.description,
      date: rData.acf.date_of_purchase,
      submittedBy: rData.acf.submitted_by,
      submittedDate: rData.acf.submitted,
      editable: approved.length > 0 || notAllowedGrant.length > 0 ? false : true
    };

    _receiptData.detail = `For: ${_receiptData.grant}<br />Date of Purchase: ${_receiptData.date}<br />${_receiptData.description}`;
    _receiptData.submitted = `By: ${_receiptData.submittedBy}<br />${_receiptData.submittedDate}`;

    return _receiptData;
  }

  useEffect(() => {
    try {
      const fetchReceipts = async () => {
        const endpoint = () => {
          if (clubId && type === 'latest') {
            // return `club_receipts?club=${clubId}&all=true&per_page=5`
            return {action: 'getClubReceiptsList', body: {
              clubId,
              type: 'all',
              limit: 5
            }}
          }

          if (clubId && type === 'all') {
            // return `club_receipts?club=${clubId}&all=true`
            return {action: 'getClubReceiptsList', body: {
              clubId,
              type: 'all'
            }}
          }

          if (clubId && grantId) {
            // return `club_receipts?grant=${grantId}&club=${clubId}`
            return {action: 'getClubReceiptsList', body: {
              clubId,
              grantId
            }}
          }

          // Default
          // return `club_receipts?grant=0&club=0`
          return {action: 'getClubReceiptsList', body: {
            clubId: 0,
            grantId: 0
          }}
        }

        // To identify if receipts is still available for edit.
        // let apprvAcquittalsReceipts = [];
        let acquittalGrants = [];
        if (clubId) {
          const { response: _acquittalsList } = await wpAllNew(`getClubAcquittalsList`, {
            clubId,
            type: 'all',
            fieldSet: 1
          });
          _acquittalsList.map((acquit) => {
            // const receipts = get(acquit, 'acf.receipts');
            const grants = get(acquit, 'acf.grants');
            // apprvAcquittalsReceipts.push(...receipts);
            acquittalGrants.push(...grants);
            return true;
          })
          setSubmittedGrants(acquittalGrants);
        }

        let receipts = [];
        if (customReceiptData) {
          receipts = customReceiptData;
        } else {
          const _endpoint = endpoint();
          const { response: _receipts } = type === 'latest' ? await wpApiNew(_endpoint.action, _endpoint.body) : await wpAllNew(_endpoint.action, _endpoint.body);
          receipts = _receipts;
        }
        let spent = 0;
        const mapped = receipts.map(receipt => {
          spent += Number(receipt.acf.value);

          return receiptData(receipt, acquittalGrants);
        });
        remit && remit(spent);
        setReceipts(mapped);
      };

      fetchReceipts();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [clubId, grantId, type, remit, customReceiptData]);

  const onSubmit = async values => {
    setIsLoading(true);
    toast.info("Uploading receipt...");

    try {
      // upload image
      let doc = null;
      const fileName = `Grant-Receipt-${clubId}-${values.grant || grantId}-${extractDate(
        values.date
      )}`;
      
      // Delete the existing recipt in WP Media list
      if (updateReceipt && values.file) {
        const filenames = updateReceipt?.receipt.split('/');
        // search for ID
        const { response: media } = await wpApiNew('getMediaData', {
          filename: filenames[filenames.length - 1],
          fieldSet: 1
        });
        // Delete media
        await wpApiNew('deleteMedia', {
          clubId,
          mediaId: media.id
        })
      }

      if (values.file) {
        const file = values.file;
        const { type } = file;
        const fileType = type.substring(type.indexOf('/') + 1, type.length);

        const base64Doc = await getBase64(values.file);
        doc = await uploadMedia(
          `${fileName}.${fileType}`,
          base64Doc,
          type
        );
      }

      const { usu: user } = memberDetails;
      const _receiptData = {
        grant: values.grant || grantId,
        club: clubId,
        dateOfPurchase: values.date,
        description: values.description,
        value: values.value,
        ...(doc && { receipt: doc.response.id }),
        submittedBy: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
        ...(fileName && { receiptName: fileName }),
        ...(updateReceipt && { id: updateReceipt.id })
      };

      const { response: newReceipt } = await createNewReceipt(_receiptData);
      const newReceiptData = receiptData(newReceipt, submittedGrants);

      const mergeReceiptData = receipts.map((receipt) => {
        if (receipt.id === newReceiptData.id) {
          return newReceiptData;
        } else {
          return receipt;
        }
      })
      setUpdateReceipt(null);
      setReceipts([
        ...mergeReceiptData,
      ]);

      setIsReceiptDialogOpen(false);
      toast.success("Receipt upload successful!");
    } catch (e) {
      console.error(e);
      toast.error("Receipt upload failed! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReceipt = async data => {
    setIsLoading(true);
    toast.info("Deleting receipt...");

    try {
      await deleteReceipt(clubId, data.id);
      const mergeReceiptData = receipts.filter((receipt) => {
        return receipt.id !== data.id;
      })
      setReceipts([
        ...mergeReceiptData,
      ]);
      toast.success("Receipt delete successful!");
    } catch (e) {
      console.error(e);
      toast.error("Receipt delete failed! Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const onClose = () => {
    setIsReceiptDialogOpen(false);
    setUpdateReceipt(null)
  }

  return (
    <>
      {isLoading ? (
        <LoadingState />
      ) : (
        <ReceiptsList
          receipts={receipts}
          headers={headers}
          hasAcquittal={hasAcquittal}
          onNewReceipt={() => setIsReceiptDialogOpen(true)}
          onReceiptUpdate={(data) => {
            setUpdateReceipt(data);
            setIsReceiptDialogOpen(true);
          }}
          deleteReceipt={(data) => {
            handleDeleteReceipt(data);
          }}
          extraTopActions={extraTopActions}
          withRowActions={withRowActions}
          bulkActions={bulkActions}
        />
      )}
      <ReceiptDialog
        open={isReceiptDialogOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        clubId={clubId}
        grantId={grantId}
        isUpdate={updateReceipt ? true : false}
        updateReceipt={updateReceipt}
      />
    </>
  );
};

export default Receipts;

