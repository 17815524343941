// extracted by mini-css-extract-plugin
export var addCartButtonWrapp = "ProductCard-module--addCartButtonWrapp--c0ccd";
export var basicView = "ProductCard-module--basicView--7ad20";
export var btnOutOfStock = "ProductCard-module--btnOutOfStock--14190";
export var flag = "ProductCard-module--flag--45c24";
export var fullImage = "ProductCard-module--fullImage--5c962";
export var fullView = "ProductCard-module--fullView--bdc19";
export var imageContainer = "ProductCard-module--imageContainer--755f9";
export var imageSlider = "ProductCard-module--imageSlider--e5f42";
export var loading = "ProductCard-module--loading--72fb8";
export var overflowScroll = "ProductCard-module--overflowScroll--12586";
export var price = "ProductCard-module--price--3541b";
export var productCard = "ProductCard-module--productCard--ff4fd";
export var productCardHead = "ProductCard-module--productCardHead--73fad";
export var productDetails = "ProductCard-module--productDetails--b7d7b";
export var productImage = "ProductCard-module--productImage--6ad3e";
export var productMainCardBody = "ProductCard-module--productMainCardBody--2c945";
export var productName = "ProductCard-module--productName--af7c6";
export var productSku = "ProductCard-module--productSku--fdb0a";
export var productSliderImage = "ProductCard-module--productSliderImage--8e2e6";
export var productSliderImageWrapp = "ProductCard-module--productSliderImageWrapp--1b876";
export var removeIcon = "ProductCard-module--removeIcon--af2a0";
export var salePrice = "ProductCard-module--salePrice--20197";
export var slickSlide = "ProductCard-module--slick-slide--e24ca";
export var textBox = "ProductCard-module--textBox--54c80";
export var viewButton = "ProductCard-module--viewButton--c4c93";