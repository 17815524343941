import { Link } from 'gatsby';
import React from 'react';
import { transformWpEat, transformWpHealth } from '../../../helpers/wordpress';
import * as styles from './ContentCard.module.css';
import parse  from 'html-react-parser';

const ContentCard = ({ cardInfo, type = 'eat' }) => {
  if (!cardInfo) return <div>Requires WP Object</div>;

  const info =
    type === 'eat' ? transformWpEat(cardInfo) : transformWpHealth(cardInfo);
  // const featuredImage = 'https://via.placeholder.com/430x130';
  const featuredImage = info?.featuredImage;
  const link = type === 'eat' ? `/food-drink/${info.slug}` : `/health-services/${info.slug}`

  return (
    <div className={styles.ContentCard}>
      {/* <div style={{backgroundImage: `url(${eat?.featuredImage})`, minHeight: '130px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div> */}
      <Link to={link}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${featuredImage})` }}></div>
        <h5 className={styles.title}>{info.title}</h5>

        {info.openingHours &&
          info.openingHours.map((hours, hoursIndex) => (
            <div className={`${styles.hours} ${info.excerpt ? styles.bold : ''}`} key={hoursIndex}>
              <span className={styles.days}>{hours.days}</span>
              <span>{hours.hours}</span>
            </div>
          ))}
        {type === 'health' && (
          <div className={styles.contentText}>
            <p >{parse(info.excerpt)}</p>
            <p >{info.promotion}</p>
          </div>
        )}
      </Link>
    </div>
  );
};

export default ContentCard;

