// extracted by mini-css-extract-plugin
export var blue = "PageBanner-module--blue--6e444";
export var breadCrumb = "PageBanner-module--breadCrumb--597b4";
export var containerContent = "PageBanner-module--containerContent--99aaf";
export var header = "PageBanner-module--header--82670";
export var latte = "PageBanner-module--latte--3486f";
export var medium = "PageBanner-module--medium--ab826";
export var pink = "PageBanner-module--pink--68388";
export var purple = "PageBanner-module--purple--5c8ff";
export var root = "PageBanner-module--root--72458";
export var small = "PageBanner-module--small--a7d26";
export var subtitle = "PageBanner-module--subtitle--81cc9";
export var twitter = "PageBanner-module--twitter--3a56c";