import React from 'react'

const Plus = () => (
    <svg
    width={15}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'0 0 15 15'}
    strokeWidth={2}
  >
    <path d="M7.5 1v13M1 7.5h13" stroke="currentColor" />
  </svg>
)

export default Plus