// extracted by mini-css-extract-plugin
export var EventCard = "EventCard-module--EventCard--3dc8c";
export var date = "EventCard-module--date--d8888";
export var dateContainer = "EventCard-module--dateContainer--479ac";
export var dateCustom = "EventCard-module--dateCustom--5c9fd";
export var detailContainer = "EventCard-module--detailContainer--65985";
export var eventSublabel = "EventCard-module--eventSublabel--fe8f7";
export var image = "EventCard-module--image--a1a8a";
export var image1x1 = "EventCard-module--image1x1--9d95f";
export var imageContainer = "EventCard-module--imageContainer--39092";
export var imageCustom = "EventCard-module--imageCustom--287ee";
export var link = "EventCard-module--link--6562a";
export var name = "EventCard-module--name--c1353";
export var titleCustom = "EventCard-module--titleCustom--1151b";