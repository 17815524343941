import React, { useContext } from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { getStorage } from '../../../helpers/general'
import PriceContext from '../../../context/PriceProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

// INHERIT STYLES FROM PARENT COMPONENT

const Price = props => {
    const prices = useContext(PriceContext);
    const {entityId, price, calculated_price, sale_price, options = {}} = props
    const styles = props.styles || {};

    const currencyData = useStaticQuery(graphql`
        query {
            allBigCommerceCurrencies(filter: {enabled: {eq: true}, is_default: {eq: true}}) {
                edges {
                    node {
                        currency_code
                        currency_exchange_rate
                    }
                }
            }
        }
    `)

    let currencyCode = currencyData?.allBigCommerceCurrencies.edges[0].node.currency_code;
    let rate = currencyData?.allBigCommerceCurrencies.edges[0].node.currency_exchange_rate;
    const sessionCurrencyJson = getStorage('currency');
    if (sessionCurrencyJson) {
        const sessionCurrency = JSON.parse(sessionCurrencyJson);
        currencyCode = sessionCurrency.currentCurrency;
        rate = sessionCurrency.rates[currencyCode];
    }

    const calculatePrice = (price) => {
        return price * rate;
    }

    const latestProduct =
        prices && prices[entityId] && (!('override' in options) || options.override === false)
            ? prices[entityId]
            : {
                price,
                calculated_price,
                sale_price
                };

    latestProduct.price = latestProduct.price && typeof latestProduct.price === 'object' ? latestProduct.price.tax_inclusive : latestProduct.price;
    latestProduct.calculated_price = latestProduct.calculated_price && typeof latestProduct.calculated_price === 'object' ? latestProduct.calculated_price.tax_inclusive : latestProduct.calculated_price;
    latestProduct.sale_price = latestProduct.sale_price && typeof latestProduct.sale_price === 'object' ? latestProduct.sale_price.tax_inclusive : latestProduct.sale_price;
    latestProduct.retail_price = latestProduct.retail_price && typeof latestProduct.retail_price === 'object' ? latestProduct.retail_price.tax_inclusive : latestProduct.retail_price;

    return (
        <div className={styles.prices}>
          {latestProduct.sale_price && latestProduct.sale_price !== 0 && latestProduct.sale_price !== null && latestProduct.sale_price !== latestProduct.price ? (
            <>
              {/* SALE PRICE DISPLAY */}
              <span className={`${styles.price} ${styles.onSale}`}>
                <CurrencyFormatter
                  currency={currencyCode}
                  amount={calculatePrice(latestProduct.calculated_price)}
                />
              </span>
              {latestProduct.sale_price && (
                <>
                  <span className={styles.salePrice}>
                    <CurrencyFormatter
                      currency={currencyCode}
                      amount={calculatePrice(latestProduct.price)}
                    />
                  </span>
                  {!('hideDiscountPrice' in options) && <span className={styles.savings}>
                    <CurrencyFormatter
                      currency={currencyCode}
                      amount={calculatePrice(latestProduct.calculated_price - latestProduct.price)}
                    />
                  </span>}
                </>
              )}
            </>
          ) : (
            <>
              {latestProduct.retail_price > latestProduct.price ? (
                <>
                  {/* PERMANENT DISCOUNT PRICE DISPLAY */}
                  <span className={`${styles.price} ${styles.onSale}`}>
                    <CurrencyFormatter
                      currency={currencyCode}
                      amount={calculatePrice(latestProduct.price)}
                    />
                  </span>
                  {latestProduct.retail_price && (
                    <>
                      <span className={styles.salePrice}>
                        <CurrencyFormatter
                          currency={currencyCode}
                          amount={calculatePrice(latestProduct.retail_price)}
                        />
                      </span>
                      {!('hideDiscountPrice' in options) && <span className={styles.savings}>
                        <CurrencyFormatter
                          currency={currencyCode}
                          amount={calculatePrice(latestProduct.retail_price - latestProduct.price)}
                        />
                      </span>}
                    </>
                  )}
                </>
              ) : (
                <span className={styles.price}>
                  <CurrencyFormatter
                    currency={currencyCode}
                    amount={
                      latestProduct.calculated_price
                        ? calculatePrice(latestProduct.calculated_price)
                        : calculatePrice(latestProduct.price)
                    }
                  />
                </span>
              )}
            </>
          )}
        </div>
    );
}

export default Price
