import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function ContentfulAsset({ id }) {
    const assetData = useStaticQuery(graphql`
        query {
            allContentfulAsset {
                nodes {
                  id: contentful_id
                  title
                  file {
                    url
                    contentType
                  }
                }
            }
        }
    `)
    const assets = assetData?.allContentfulAsset.nodes;

    const assetObj = assets.find(asset => asset.id === id);

    return (
        <>
            {assetObj && assetObj.file.contentType.startsWith('image/') && (
                <img loading="lazy" src={`${assetObj.file.url}?w=1100`} alt={assetObj.description} />
            )}
        </>
    )
}

export default ContentfulAsset