import React from 'react'

const Klarna = () => (
    <svg width={40} height={28} viewBox="0 0 40 28" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M3.176 26.688A3.147 3.147 0 010 23.51V3.176A3.147 3.147 0 013.176 0H36.22a3.147 3.147 0 013.177 3.176v20.335a3.147 3.147 0 01-3.177 3.177H3.176z"
          fill="#FFB3C7"
        />
        <path
          d="M35.703 1.231a2.47 2.47 0 012.462 2.463v19.698a2.47 2.47 0 01-2.462 2.462H3.693a2.47 2.47 0 01-2.462-2.462V3.694A2.47 2.47 0 013.694 1.23h32.01zm0-1.23H3.693C1.724 0 0 1.6 0 3.693v19.698c0 2.093 1.6 3.693 3.694 3.693h32.01c2.092 0 3.693-1.6 3.693-3.693V3.694C39.397 1.6 37.796 0 35.703 0z"
          fill="#F0A2B6"
        />
        <g fill="#0A0B09">
          <path d="M34.691 14.659a.846.846 0 00-.842.85c0 .468.377.849.842.849.465 0 .842-.38.842-.85s-.377-.85-.842-.85zm-2.77-.657c0-.643-.544-1.163-1.215-1.163s-1.215.52-1.215 1.163c0 .642.544 1.163 1.215 1.163s1.215-.52 1.215-1.163zm.005-2.26h1.341v4.52h-1.341v-.289c-.38.26-.837.414-1.33.414a2.375 2.375 0 01-2.364-2.385 2.375 2.375 0 012.364-2.386c.493 0 .95.154 1.33.414v-.289zm-10.735.588v-.589h-1.373v4.521h1.377v-2.11c0-.713.764-1.095 1.295-1.095h.016v-1.315c-.545 0-1.046.235-1.315.588zm-3.42 1.672c0-.643-.545-1.163-1.216-1.163-.671 0-1.215.52-1.215 1.163 0 .642.544 1.163 1.215 1.163s1.216-.52 1.216-1.163zm.004-2.26h1.341v4.52h-1.341v-.289c-.379.26-.836.414-1.33.414a2.375 2.375 0 01-2.364-2.385 2.375 2.375 0 012.364-2.386c.494 0 .951.154 1.33.414v-.289zm8.074-.122c-.536 0-1.043.168-1.382.63v-.508H23.13v4.52h1.352v-2.376c0-.687.457-1.024 1.007-1.024.59 0 .928.356.928 1.015v2.385h1.34v-2.875c0-1.052-.829-1.767-1.91-1.767zm-13.722 4.642h1.404V9.727h-1.404v6.535zm-6.167.002h1.487V9.726H5.96v6.538zm5.2-6.538a5.225 5.225 0 01-1.52 3.71l2.054 2.828H9.858l-2.233-3.073.576-.435a3.766 3.766 0 001.504-3.03h1.455z" />
        </g>
      </g>
    </svg>
)

export default Klarna