import React from 'react'

const Add = () => (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="currentColor"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <path d="M1.138 5.897h9.517M5.897 10.655V1.138" />
    </g>
  </svg>
)

export default Add