import React, { useContext, useEffect } from "react";
import { get } from "lodash";
import AuthContext from "../context/AuthProvider";

const RedirectLangExchange = () => {
    const auth = useContext(AuthContext);

    useEffect(() => {
        if (auth && get(auth, 'state.userChecked', false) && get(auth, 'state.isLoggedIn', false) && get(auth, 'state.usu', false) && typeof window !== 'undefined') {
            const usuUserData = get(auth, 'state.usu', false);
            window.location = `https://secure.usuonline.com/langex/?membernumber=${usuUserData?.MemberNumber}&bepozid=${usuUserData?.BepozID}`;
        } else if (auth && get(auth, 'state.userChecked', false) && !get(auth, 'state.isLoggedIn', false)) {
            window.location = `/login/`
        }
    }, [auth]);
    
    return (
        <></>
    )
}

export default RedirectLangExchange