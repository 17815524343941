import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { get } from "lodash"
import { persistLocation } from '../../../../../../../helpers/general';
import { authClubExec } from '../../../../../../../helpers/wordpress';
import AccountPageWrapper from '../../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AccordionCard from '../../../../../../../components/atoms/AccordionCard/AccordionCard';

import Details from '../../../../../../../components/molecules/GrantDetails/GrantDetails';
import Receipts from '../../../../../../../components/organisms/Receipts/Receipts';

import * as styles from './../details.module.css';
// import Acquittal from './acquittal';

const GrantDetails = ({ location }) => {
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [spent, setSpent] = useState(null);

    useEffect(() => {
        if (isAuthed === null || (isAuthed && club  && club.id !== get(location, 'state.clubId'))) {
            authClubExec(get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, location, club]);

    const updateSpent = (value) => {
        setSpent(value);
    }

    return location.state && location.state.grant ? (
        <>
            <AccordionCard title="Details" showContent={['Pending', 'Rejected'].indexOf(get(location, 'state.grant.status')) > -1 ? true : false}>
                <Details grant={get(location, 'state.grant')} club={get(location, 'state')} spent={spent} sharedStyles={styles} />
            </AccordionCard>
            {(['Pending', 'Rejected'].indexOf(get(location, 'state.grant.status')) === -1 || get(location, 'state.grant.acquittal')) && (
                <AccordionCard title="Receipts">
                    <Receipts clubId={get(location, 'state.clubId')} grantId={get(location, 'state.grant.id')} hasAcquittal={get(location, 'state.grant.acquittal')} remit={updateSpent} />
                </AccordionCard>
            )}
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(isAuthed === null) && (
                <div>Fetching grant data</div>
            )}
            {/* No clubs found */}
            {(isAuthed === true && club === false) && (
                <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
            {/* No clubs found */}
            {(isAuthed === false) && (
                <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    const backToFinancesPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances`,{
            state: persistedLocation.state
        })
    }

    const backToGrantsPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances/grants`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Grant Detail" breadcrumbTitle="Grant Detail" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}, { onClick: () => backToFinancesPage(), label: "Club Finances"}, { onClick: () => backToGrantsPage(), label: "Manage Grants"}]} menuType="clubManage" location={persistedLocation}>
            <GrantDetails location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

