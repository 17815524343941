import React from 'react';
import {
  normalizeContainerSize,
} from '../../../helpers/contentful';
import Container from '../../atoms/Container/Container';

const ContentfulBlockSpotify = props => {
  const { containerSize, embedCode } = props;


  return (
    <div >
      <Container size={normalizeContainerSize(containerSize)}>
        <div dangerouslySetInnerHTML={{__html : embedCode.embedCode}}></div>
      </Container>
    </div>
  );
};

export default ContentfulBlockSpotify;

