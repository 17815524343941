import React from 'react'

const Bag = () => (
  <svg
    width={24}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 24 29'
  >
    <path
      clipRule="evenodd"
      d="M2 13.375a2 2 0 0 1 2-2h16.625a2 2 0 0 1 2 2v10.938a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V13.374Z"
      stroke="#000"
      strokeWidth={2.5}
    />
    <ellipse
      cx={12.068}
      cy={4.856}
      rx={5.38}
      ry={2.856}
      stroke="#000"
      strokeWidth={2.5}
      strokeLinecap="square"
    />
  </svg>
)

export default Bag