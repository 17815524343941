import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/organisms/Seo/Seo';
import Layout from '../../components/organisms/Layout/Layout';
import EventsAll from '../../components/organisms/EventsAll/EventsAll';

const WPEventsPage = ({ data, pageContext }) => {
  const events = (data && data.allWpEvent.nodes) || [];

  const filterPublic = events.filter(
    event => event.slug.indexOf('-igm-meeting') === -1
  );

  return (
    <Layout>
      <Seo title='Events' />
      <EventsAll events={filterPublic} tabId='all-events' />
    </Layout>
  );
};

export default WPEventsPage;

export const pageQuery = graphql`
  query WPEventsPage {
    allWpEvent(sort: { fields: eventFieldsSide___startDate, order: ASC }, filter: {eventFieldsSide: {displayTo: {in: ["public", null]}}}) {
      nodes {
        id
        status
        uri
        slug
        title
        featuredImage {
          node {
            publicUrl
            sourceUrl
            localFile {
              childImageSharp {
                fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                  src
                }
              }
            }
          }
        }
        eventFieldsSide {
          startDate
          endDate
          endTime
          startTime
          club {
            ... on WpClub {
              id
              title
              databaseId
              clubFieldsSide {
                logo {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
        eventTags {
          nodes {
            uri
            name
          }
        }
        eventCategories {
          nodes {
            slug
          }
        }
      }
    }
  }
`;

