import React from 'react';

const Image = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_209_218)'>
        <path
          d='M0.00598145 9C0.00598145 7.4087 0.638123 5.88258 1.76334 4.75736C2.88856 3.63214 4.41468 3 6.00598 3H42.006C43.5973 3 45.1234 3.63214 46.2486 4.75736C47.3738 5.88258 48.006 7.4087 48.006 9V39C48.006 40.5913 47.3738 42.1174 46.2486 43.2426C45.1234 44.3679 43.5973 45 42.006 45H6.00598C4.41468 45 2.88856 44.3679 1.76334 43.2426C0.638123 42.1174 0.00598145 40.5913 0.00598145 39V9V9ZM3.00598 36V39C3.00598 39.7956 3.32205 40.5587 3.88466 41.1213C4.44727 41.6839 5.21033 42 6.00598 42H42.006C42.8016 42 43.5647 41.6839 44.1273 41.1213C44.6899 40.5587 45.006 39.7956 45.006 39V28.5L33.675 22.659C33.3937 22.5181 33.0751 22.4692 32.7645 22.5193C32.4538 22.5693 32.1668 22.7158 31.944 22.938L20.814 34.068L12.834 28.752C12.5459 28.5602 12.2003 28.4739 11.8558 28.5078C11.5113 28.5417 11.1892 28.6937 10.944 28.938L3.00598 36ZM18.006 16.5C18.006 15.3065 17.5319 14.1619 16.688 13.318C15.844 12.4741 14.6995 12 13.506 12C12.3125 12 11.1679 12.4741 10.324 13.318C9.48009 14.1619 9.00598 15.3065 9.00598 16.5C9.00598 17.6935 9.48009 18.8381 10.324 19.682C11.1679 20.5259 12.3125 21 13.506 21C14.6995 21 15.844 20.5259 16.688 19.682C17.5319 18.8381 18.006 17.6935 18.006 16.5Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_209_218'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Image;
