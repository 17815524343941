import React from 'react'

const Italic = () => (
    <svg width="48px" height="48px" viewBox="-12 -8 48 48" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <polygon points="0 27.4285714 0 23.1428571 5.87142857 23.1428571 13.5 4.28571429 6.85714286 4.28571429 6.85714286 0 24 0 24 4.28571429 18.1285714 4.28571429 10.5 23.1428571 17.1428571 23.1428571 17.1428571 27.4285714"></polygon>
            </g>
        </g>
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="48" width="48"><path fill="currentColor" d="M10 40v-5h6.85l8.9-22H18V8h20v5h-6.85l-8.9 22H30v5Z"/></svg>
)

export default Italic