import React from "react"
import Icon from "../../components/atoms/Icon/Icon"

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import DataTable from "../../components/organisms/DataTable/DataTable"

const DummyTable = ({ location }) => {

  const sampleData = [
    { 
      id: 123123,
      certificate_name: 'A Certificate',
      student_name: 'Student Name',
      uploaded_date: 'Jun 23 2022'
    },
    { 
      id: 123434,
      certificate_name: 'H Certificate 2',
      student_name: 'Student Name 2',
      uploaded_date: 'Jun 1 2022'
    },
    { 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },
    { 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },
    { 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    },{ 
      id: 123765,
      certificate_name: 'B Certificate 3',
      student_name: 'Student Name 3',
      uploaded_date: 'Jun 30 2022'
    }
  ]

  const headingKeys = [
    { label: 'Certificate Name', data_key: 'certificate_name', sortable: true },
    { label: 'Student Name', data_key: 'student_name', sortable: true },
    { label: 'Uploaded Date', data_key: 'uploaded_date', sortable: true, format: 'date' },
  ]

  const handlePreview = (data) => {
    console.log('handleData', data)
  }

  const handleCreate = (data) => {
    console.log('handleData', data)
  }

  const handleBulkAction = (action, data) => {
    console.log(action, data);
  }

  return (
    <>
      <DataTable 
        tableData={sampleData} 
        bulkActions={[
          { label: 'Archive', key: 'archive' },
          { label: 'Delete', key: 'delete' }
        ]}
        handleBulkAction={handleBulkAction}
        topActions={[
          { label: 'Create new event', icon: <Icon symbol="calendar" />, event: (data) => handleCreate(data) }
        ]}
        headingKeys={headingKeys}
        rowActions={[
          { label: 'Preview', cta: (data) => handlePreview(data) },
          { label: 'Details', href: '/dummy' }
        ]}
        perPage={5}
        showSearch={true} />
    </>
  )
}

const DummyTableOutput = () => (
  <AccountPageWrapper bgRaw={true} metaTitle="Account - Dummy Table" title="Dummy Table">
      <DummyTable />
  </AccountPageWrapper>
)

export default DummyTableOutput