import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import Slider from '../../atoms/Slider/Slider';
import ProductGrid from '../ProductGrid/ProductGrid';
import Button from '../../atoms/Button/Button';
import * as styles from './FeaturedProducts.module.css';
import Container from '../../atoms/Container/Container';

import { useWindowDimensions } from '../../../helpers/general';

// const defaultResponsive = [
//   {
//     breakpoint: 1023,
//     settings: {
//       slidesToShow: 3,
//       slidesToScroll: 1
//     }
//   },
//   {
//     breakpoint: 767,
//     settings: {
//       slidesToShow: 2,
//       slidesToScroll: 1
//     }
//   },
//   {
//     breakpoint: 500,
//     settings: {
//       slidesToShow: 1,
//       slidesToScroll: 1
//     }
//   }
// ];

function FeaturedProducts(props) {
  const { width } = useWindowDimensions();
  const { size } = props;

  const [products, setProducts] = useState(false);
  const [displayProducts, setDisplayProducts] = useState(false);
  const [limit, setLimit] = useState(3);

  const sectionQuery = useStaticQuery(graphql`
    query {
      featuredProducts: allBigCommerceProducts(filter: {is_featured: {eq: true}}) {
        edges {
          node {
            id
            entityId: bigcommerce_id
            name
            sku
            price
            calculated_price
            retail_price
            sale_price
            brand_id
            categories
            warranty
            custom_url {
              url
            }
            images {
              url_standard
              url_thumbnail
              url_zoom
              id
              description
              image_file
              is_thumbnail
              sort_order
            }
            variants {
              product_id
              id
              sku
            }
            custom_fields{
              name
              value
            }
          }
        }
      }
    }
  `);

  useMemo(() => {
    if (!products) {
      setProducts(sectionQuery?.featuredProducts.edges || []);
    }
  }, [sectionQuery, products]);

  useEffect(() => {
    if(width < 800) setLimit(2);
    else if(width > 800) setLimit(3);

  }, [width]);

  useEffect(() => {
      let newProducts = products;
      if (newProducts.length > limit) {
        newProducts = newProducts.slice(0, limit);
      }
      setDisplayProducts(newProducts);
  }, [limit, products]);
  
  return (
    <div data-featuredproducts className={styles.featuredContainer}>
      <Container size={size ? size.toLowerCase(): ''}>
        <h3 className={styles.title}>Don't Miss</h3>
        <ProductGrid columns={limit} products={displayProducts} />
        {/* <div className={styles.productContainer}>
          {displayProducts && displayProducts.map(node => {
            return <ProductCard key={node.node.id} data={node.node} />
          })}
        
        </div> */}
        {/* <Slider
          // settings={sliderSettings}
          // infinite={false}
          // lazyLoad={true}
          // slidesToShow={3}
          // slidesToScroll={1}
          // responsive={props.responsive || defaultResponsive}
        > */}
          {/* {displayProducts && displayProducts.map(node => (
            <ProductCard key={node.node.id} data={node.node} />
          ))}
        </Slider> */}
      </Container>


      {props.buttonLink && (
        <div className="flex-center">
          <Button href={props.buttonLink} level="primary">
            {props.buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
}

export default FeaturedProducts;
