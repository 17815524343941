import React, { useState, useMemo, useEffect, useRef } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby'
import { decode } from 'he';
import { getQuerystringValue, slugifyClubCategory } from '../../../helpers/general';
import AccordionAlt from '../../molecules/AccordionAlt/Accordion';
import Container from '../../atoms/Container/Container';

import * as styles from './ClubList.module.css';

const ClubList = props => {
  const { size } = props;
  
  // const category = window.location.search
  const clubListRef = useRef(null)
  const category = typeof window !== 'undefined' ? getQuerystringValue('category') : false;

  const [clubList, setClubList] = useState(false);
  const [pendingClubList, setPendingClubList] = useState(false);

  const wpCategoryClubQuery = useStaticQuery(graphql`
    query ClubCategoryList {
      allWpClubCategory(sort: {order: ASC, fields: name}) {
        edges {
          node {
            name
            clubs {
              nodes {
                databaseId
                title
                link
              }
            }
          }
        }
      }

      allWpPendingClub(filter: {status: {in: ["signatures", "compliant"]}, databaseId: {ne: 4668}}) {
        nodes {
          title
          link
          uri
        }
      }
    }
  `)
  

  useMemo(() => {
    if (!clubList) {
      const normalizedClubList = wpCategoryClubQuery?.allWpClubCategory.edges.map((category) => {

        // testing out layouts - remove this once populated
        // const sampleClubList = [
        //   'Zumba Club (example)',
        //   'Arts and Crafts Society (example)', 
        //   'BarberSoc - USU A Cappella Society (example)', 
        //   'Commerce Revue Society (example)',
        //   '101 Coaching Sessions (example)',
        //   'Marching Band Association(SUMBA) (example)', 
        //   'Movement and Dance Society (example)',
        //   'Musical Society (SUMS) (example)'];
        const c = category.node;  
        // const clubList = [...c.clubs?.nodes?.map((t) => t.title)].sort();
        // console.log(c);



        const clubList = c.clubs?.nodes?.map((t) => {
          if (t.databaseId !== 4668) {
            return({
              title: decode(t.title),
              link: t.link
            })
          }

          return false;
        }).filter(t => t !== false);

        clubList.sort((a,b) => a.title.localeCompare(b.title));

        //https://stackoverflow.com/questions/51009090/sort-and-group-objects-alphabetically-by-first-letter-javascript
        let data = clubList.reduce((r, e) => {
          let symbol = e.title[0];
          if(!r[symbol]) r[symbol] = {symbol, clubs: [{title: e.title, link: e.link}]}
          else r[symbol].clubs.push({title: e.title, link: e.link});
          return r;
        }, {})
        let result = Object.values(data);
        return {
          name: c.name,
          clubGroup: result,
        }
      });
      setClubList(normalizedClubList || []);
    }

    if (!pendingClubList && typeof window !== "undefined") {
      const currUrl = window.location.href;
      const list = wpCategoryClubQuery?.allWpPendingClub.nodes.map(club => ({
        title: decode(club.title),
        link: club.link,
        uri: club.uri
      }));
      list.sort((a,b) => a.title.localeCompare(b.title));

      const data = list.reduce((r, e) => {
        let symbol = e.title[0];
        if (currUrl.startsWith('https://usu.edu.au')) {
          if (e.title.indexOf("(TEST)") < 0) {
            if(!r[symbol]) r[symbol] = {symbol, clubs: [{title: e.title, link: e.link, uri: e.uri}]}
            else r[symbol].clubs.push({title: e.title, link: e.link, uri: e.uri});
          }
        } else {
          if(!r[symbol]) r[symbol] = {symbol, clubs: [{title: e.title, link: e.link, uri: e.uri}]}
          else r[symbol].clubs.push({title: e.title, link: e.link, uri: e.uri});
        }
        return r;
      }, {})
      let result = Object.values(data);

      setPendingClubList(result || []);
    }
  }, [wpCategoryClubQuery, clubList, pendingClubList]);

  useEffect(() => {
    if(category !== false) clubListRef.current.scrollIntoView();
  }, [category])
  
  return (
    <div className={ styles.ClubList } ref={clubListRef}>
      <Container  size={typeof size === 'string' ? size.toLocaleLowerCase() : ''}>
      {clubList !== false && clubList?.map((clubGroup, cgIndex) => {
        return(
        <AccordionAlt 
          emptyMessage={'No Listed Clubs'} 
          key={cgIndex} 
          title={clubGroup.name} 
          value={category === slugifyClubCategory(clubGroup.name)} 
          customStyle={styles}
        >
          {clubGroup?.clubGroup.length !== 0 && clubGroup?.clubGroup.map((club, cIndex) => {
            return(
              <div key={cIndex} className={styles.groupContainer}>
                <span className={styles.symbol}>{club.symbol}</span>
                <div className={styles.clubListContainer}>
                  
                    {club?.clubs.map((clubObject, cnIndex) => {
                      return(
                        <div key={cnIndex} className={styles.linkContainer}>
                          <Link to={clubObject.link} className={styles.clubName}>{clubObject.title}</Link>
                        </div>
                      ) 
                    })}
                </div>
              </div>
            )
          })}
        </AccordionAlt>
        )
      })}
      {pendingClubList.length > 0 && (
        <AccordionAlt 
          emptyMessage={'No Listed Clubs'} 
          key={'pendingclubs'} 
          title={'Pending clubs'} 
          value={category === 'pending'} 
          customStyle={styles}
        >
          {pendingClubList.map((club, cIndex) => {
            return(
              <div key={cIndex} className={styles.groupContainer}>
                <span className={styles.symbol}>{club.symbol}</span>
                <div className={styles.clubListContainer}>
                  
                    {club?.clubs.map((clubObject, cnIndex) => {
                      return(
                        <div key={cnIndex} className={styles.linkContainer}>
                          <Link to={clubObject.uri} className={styles.clubName}>{clubObject.title}</Link>
                        </div>
                      ) 
                    })}
                </div>
              </div>
            )
          })}
        </AccordionAlt>
      )}
      </Container>
    </div>
  );
};

export default ClubList;