import React from 'react'

const Search = () => (
    <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'0 0 20 20'}
  >
    <path
      d="M19.333 19.333 14 14m-5.333 2.667a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
      stroke="currentColor"
    />
  </svg>
)

export default Search