import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/organisms/Seo/Seo';
import Layout from '../../components/organisms/Layout/Layout';
import EventsForYou from '../../components/organisms/EventsForYou/EventsForYou';
const WPEventsForYouPage = ({ data, pageContext }) => {
    const events = (data && data.allWpEvent.nodes) || [];
    return (
        <Layout>
            <Seo title="Events" />
            <EventsForYou events={events}/>

        </Layout>
    );
}

export default WPEventsForYouPage;

export const pageQuery = graphql`
  query WPEventsForYouPage {
    allWpEvent(sort: {fields: eventFieldsSide___startDate, order: ASC}, filter: {eventFieldsSide: {displayTo: {nin: "none"}}}) {
        nodes {
            id
            status
            uri
            slug
            title
            featuredImage {
                node {
                    publicUrl
                    sourceUrl
                    localFile {
                      childImageSharp {
                        fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                          src
                        }
                      }
                    }
                }
            }
            eventFieldsSide {
                startDate
                startTime
                endDate
                endTime
            }
            eventTags {
                nodes {
                    uri
                    name
                }
            }
            eventFieldsSide {
                club {
                  ... on WpClub {
                    databaseId
                    slug
                    title
                    clubFieldsSide {
                      logo {
                        sourceUrl
                        localFile {
                          childImageSharp {
                            fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                              src
                            }
                          }
                        }
                      }
                    }
                    clubCategories {
                      nodes {
                        slug
                        name
                      }
                    }
                  }
                }
            }
        }
    }
  }
`;

