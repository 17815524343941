import React, { useState } from 'react';

import Button from '../../atoms/Button/Button';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import SizeList from '../../molecules/SizeList/SizeList';
import SwatchList from '../../molecules/SwatchList/SwatchList';

import { generateMockProductData } from '../../../mockData/mock';
// import AddItemNotificationContext from '../../context/AddItemNotificationProvider'; // TODO: Replace with JAMM version

import * as styles from './QuickView.module.css';


const QuickView = (props) => {

  const {close, buttonTitle = 'Add to Bag'} = props; 
  const sampleProduct = generateMockProductData(1, 'sample')[0];

  // const ctxAddItemNotification = useContext(AddItemNotificationContext);
  // const showNotification = ctxAddItemNotification.showNotification;
  const [activeSwatch, setActiveSwatch] = useState(sampleProduct.colorOptions[0]);
  const [activeSize, setActiveSize] = useState(sampleProduct.sizeOptions[0]);

  const handleAddToBag = () => {
    close();
    // showNotification();
  }

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <h4>Select Options</h4>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.productContainer}>
          <span className={styles.productName}>{sampleProduct.name}</span>
          <div className={styles.price}>
            <CurrencyFormatter amount={sampleProduct.price}></CurrencyFormatter>
          </div>
          <div className={styles.productImageContainer}>
            <img alt={sampleProduct.alt} src={sampleProduct.image}></img>
          </div>
        </div>

        <div className={styles.sectionContainer}>
          <SwatchList 
            swatchList={sampleProduct.colorOptions}
            activeSwatch={activeSwatch}
            setActiveSwatch={setActiveSwatch}
          />
        </div>

        <div className={styles.sectionContainer}>
          <SizeList 
            sizeList={sampleProduct.sizeOptions}
            activeSize={activeSize}
            setActiveSize={setActiveSize}
          />
        </div>

        <Button type="button" onClick={()=>handleAddToBag()} fullWidth level={'primary'}>{buttonTitle}</Button>
      </div>
    </div>
  );
};

export default QuickView;
