import React, { useState } from "react"
import { Link, navigate } from "gatsby"
// import { Builder, BuilderComponent } from '@builder.io/react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import FormInputField from "../components/atoms/FormInputField/FormInputField";
import Button from "../components/atoms/Button/Button";

import * as styles from './404.module.css';

const PageNotFound = () => {
  // if (Builder.isPreviewing || Builder.isEditing) {
  //   return <BuilderComponent model="page" />;
  // }

  const [search, setSearch] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${search}`);
  }

  return (
    <Layout disablePaddingBottom>
      <Seo title="Page Not Found" />
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>404</h1>
          <h5>We can't find the page you're looking for - but hey, why not check out some of these instead:</h5>
          <div className={styles.ctas}>
            <Link href="/events" className={`${styles.cta} ${styles.events}`}>
              <span><img src="https://usu.wpengine.com/wp-content/uploads/2024/10/Events.jpg"/></span>
              <h6>Events</h6>
            </Link>
            <Link href="/shop" className={`${styles.cta} ${styles.merch}`}>
              <span><img src="https://usu.wpengine.com/wp-content/uploads/2024/10/USYDStore-scaled.jpg"/></span>
              <h6>USYD Store</h6>
            </Link>
            <Link href="/about/careers/" className={`${styles.cta} ${styles.join}`}>
              <span><img src="https://usu.wpengine.com/wp-content/uploads/2024/10/Jobs-scaled.jpg"/></span>
              <h6>Job Opportunities</h6>
            </Link>
            <Link href="/clubs" className={`${styles.cta} ${styles.clubs}`}>
              <span><img src="https://usu.wpengine.com/wp-content/uploads/2024/10/Clubs-scaled.jpg"/></span>
              <h6>Clubs</h6>
            </Link>
          </div>
          {/* <form onSubmit={(e) => handleSubmit(e)}>
            <div className={styles.searchContainer}>
              <FormInputField 
                id={'name'}
                value={search}
                handleChange={(_, e) => setSearch(e)}
                type={'text'}
              />
              <Button type={'submit'} level={'primary'}>search</Button>
            </div>
          </form> */}
        </div>
      </Container>
    </Layout>
  );
};

export default PageNotFound;
