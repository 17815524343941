import React from 'react'

const VisaLogo = () => (
    <svg
      width={40} height={28}
      viewBox="0 0 43 30"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="prefix__a" d="M0 29.563V0h43v29.563z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.031 28.89a3.327 3.327 0 01-3.36-3.359v-21.5a3.327 3.327 0 013.36-3.36H38.97a3.327 3.327 0 013.36 3.36v21.5a3.327 3.327 0 01-3.36 3.36H4.03z"
          fill="#F2F2F2"
          fillRule="nonzero"
        />
        <g>
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M38.969 0H4.03C1.881 0 0 1.747 0 4.031v21.5c0 2.285 1.747 4.032 4.031 4.032H38.97c2.284 0 4.031-1.747 4.031-4.032v-21.5C43 1.747 41.253 0 38.969 0m0 1.344a2.695 2.695 0 012.687 2.687v21.5a2.695 2.695 0 01-2.687 2.688H4.03a2.695 2.695 0 01-2.687-2.688v-21.5A2.695 2.695 0 014.03 1.344H38.97"
            fill="#D8D8D8"
            fillRule="nonzero"
            mask="url(#prefix__b)"
          />
        </g>
        <path
          d="M13.572 9.54l-2.688 7.123-.268-1.479c-.672-1.88-2.016-3.359-3.763-4.3l2.419 9.138h2.822l4.3-10.347-2.822-.134zm18.544 6.72c.268-.538 1.075-2.957 1.075-2.957s.268-.537.403-.94l.134.94s.538 2.419.672 2.956h-2.284zm3.359-6.72h-2.15c-.672-.134-1.21.27-1.478.807l-4.031 9.54h2.822s.403-1.343.537-1.612h3.494c.134.403.269 1.613.269 1.613h2.687L35.475 9.54zm-6.988.27a7.609 7.609 0 00-2.418-.404c-2.688 0-4.569 1.344-4.569 3.494.134 1.344 1.075 2.419 2.419 2.822 1.075.537 1.478.806 1.343 1.344-.134.672-.94 1.209-1.612 1.075-.94 0-1.747-.135-2.553-.538l-.403-.134-.403 2.284c.94.403 2.015.538 3.09.538 2.822 0 4.703-1.344 4.703-3.494 0-1.21-.671-2.15-2.284-2.822-.94-.537-1.478-.806-1.478-1.344 0-.403.537-.94 1.612-.94.672 0 1.344.134 2.016.403h.134l.404-2.285zm-12.63 10.212l1.746-10.347h2.688l-1.747 10.347h-2.688z"
          fill="#00579D"
          fillRule="nonzero"
        />
        <path
          d="M8.466 9.54H4.03v.27c2.957.537 5.51 2.687 6.585 5.374l-.941-4.703a1.29 1.29 0 00-1.21-.94"
          fill="#F9A732"
          fillRule="nonzero"
        />
      </g>
    </svg>
)

export default VisaLogo