import React from 'react'
import { graphql } from 'gatsby'
import BlogList from '../components/organisms/BlogList/BlogList'
import { Helmet } from 'react-helmet'

const IndexPage = ({ data, pageContext }) => {
  const posts = (data && data.posts?.edges) || [];
  const categories = (data && data.categories.nodes) || [];

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${process.env.SITE_URL}/blog/`} />
      </Helmet>
      <BlogList
        posts={posts}
        categories={categories}
        pageContext={pageContext}
        breadcrumbs={[{label: 'Latest posts' }]}
        title="Blog"
      />
    </>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    posts: allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              uri
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              uri
              slug
            }
          }
        }
      }
    }
    categories: allWpCategory(filter: {count: {gt: 0}}) {
      nodes {
        id
        uri
        name
        description
      }
    }
  }
`;
