import { graphql, navigate, useStaticQuery } from 'gatsby';
import { get, isArray } from 'lodash';
import moment from 'moment'
import { decode } from 'he';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import {
  getBase64,
  getStorage,
  removeStorage,
  // setStorage,
  formatEventDateTime
} from '../../../helpers/general';
import { getPerson, getTags, sendEmail } from '../../../helpers/ortto';
import {
  uploadMedia,
  wpApiNew
} from '../../../helpers/wordpress';
import {
  deleteSpacioBookings,
  getFunction,
  getSpacioBooking,
  getSpacioResource, postSpacioResource, updateSpacioBookingsStatus
} from '../../../helpers/usuapi';
import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import DateTimeInput from '../../atoms/DateTimeInput/DateTimeInput';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import Icon from '../../atoms/Icon/Icon';
import RichTextField from '../../atoms/RichTextField/RichTextField';
import SelectField from '../../atoms/SelectField/SelectField';
import OrttoEmailTemplate from '../../organisms/OrttoEmailTemplate/OrttoEmailTemplate';
import * as styles from './EventSubmissionForm.module.css';
import WeekdaysSelector from '../../atoms/WeekdaysSelector/WeekdaysSelector';
import Loader from '../../atoms/Loader/Loader';
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';
import Checkbox from '../../atoms/Checkbox/Checkbox';

const decisionArray = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];

const locationArray = [
  { text: 'USU Managed Venues', value: 'usu_managed_venues' },
  { text: 'University Managed Spaces', value: 'university_managed_spaces' },
  { text: 'Off Campus', value: 'off_campus' },
];

const validateForm = (values, riskAssessmentForm) => {
  // console.log(values)
  if (!values.event_name) {
    return 'Event name is required.';
  }
  if (!values.event_description) {
    return 'Event description is required.';
  }
  if (!values.start_time) {
    return 'Start time is required.';
  }
  if (!values.end_time) {
    return 'End time is required.';
  }
  if (!values.date_from) {
    return 'Date from is required.';
  }
  if (!values.date_to) {
    return 'Date to is required.';
  }
  if (!values.venue_location) {
    return 'Venue is required.';
  } else {
    if (
      values.venue_location === 'usu_managed_venue' &&
      !values.venue_remarks
    ) {
      return 'Venue booking ID is required.';
    }
  }

  if (
    (values.alcohol_available && values.alcohol_available === 'yes') ||
    (values.overnight_event &&
      values.overnight_event === 'yes' &&
      values.food_available &&
      values.food_available === 'yes')
  ) {
    if (!riskAssessmentForm) {
      return 'Please attach the risk assessment form.';
    }
  }

  if (values.date_from && values.date_to) {
    if (values.date_to < values.date_from)
      return 'Start date must not be later than end date.';
    if (
      values.date_to === values.date_from &&
      values.start_time > values.end_time
    )
      return 'Start time must not be later than end time.';
  }
};

const initState = {
  event_name: '',
  event_description: '',
  joint_event: '',
  recurring_event: '',
  date_from: '',
  date_to: '',
  start_time: '',
  end_time: '',
  event_fee: '',
  venue_location: '',
  venue_remarks: '',
  venue_attachment: null,
  risk_assessment_form: null,
  covid_marshal: '',
  phone_number: '',
  email: '',
  overnight_event: '',
  food_available: '',
  alcohol_available: '',
  charity_event: '',
  event_funding: '',
  enable_tickets: '',
  limit_registration_amount: '',
  allocate_covid_safety: false,
  registration_override: '',
  cta_label: '',
  bld_cd: '',
  num_par: '',
  is_rec: 'no',
  ev_dur: '',
  repeat_days: '',
  repeat_type: '',
  repeat_times: '',
  rec_digits: '',
  end_date: '',
  resource_id: '',
  time_slot: '',
  room_name: '',
  accept_booking_tc: '',
  display_to: 'public',
  send_members_email: false
};

const EventSubmissionForm = ({
  clubId,
  eventData,
  customData,
  location,
  isStaff = false,
}) => {
  const alcoholRef = useRef();
  const attachmentRef = useRef();
  // const formRef = useRef();
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const roomSelectRef = useRef();
  const timeSelectRef = useRef();
  const auth = useContext(AuthContext);
  const { MemberNumber, Email } = auth.state.usu; // Email, Mobile, FirstName, LastName,
  const [values, setValues] = useState(initState);
  const [saving, setSaving] = useState(false);

  const [isNewBooking, setIsNewBooking] = useState(false);
  const [club, setClub] = useState(false);
  const [selectedClub, setSelectedClub] = useState(clubId);
  const [disableActions, setDisable] = useState(false);
  const [testingLabel, setTesting] = useState('Send test');
  const [displayClubSelect, setClubSelect] = useState(false);
  const [gettingRooms, setGettingRooms] = useState(false);
  const [booking, setBooking] = useState(false);
  const [hasBooking, setHasBooking] = useState(false);
  const [roomsSelection, setRoomsSelection] = useState(null);
  const [timesSelection, setTimesSelection] = useState(null);
  const [spacioBookings, setSpacioBookings] = useState(null);
  const [flagSpacioForm, setFlagSpacioForm] = useState(false);
  const [rooms, setRooms] = useState(null);
  const [recurrBookingEdit, setRecurrBookingEdit] = useState(null);
  const [maxDuration, setMaxDuration] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [riskAssessment, setRiskAssessment] = useState();
  const [emailData, setEmailData] = useState({
    subject: '',
    body: '',
  });
  const [emailInitial, setEmailInitial] = useState({
    subject: '',
    body: '',
  });
  const [specialEvent, setSpecialEvent] = useState(false);
  const [roles, setRoles] = useState(null);

  const requiredRoles = useMemo(() => {
    return [
      {
        role: 'President',
        amount: 1,
      },
      {
        role: 'Secretary',
        amount: 1,
      },
      {
        role: 'Treasurer',
        amount: 1,
      },
    ];
  }, []);

  const bookFormFields = ['bld_cd', 'num_par', 'is_rec', 'ev_dur', 'repeat_days', 'repeat_type', 'repeat_times', 'rec_digits', 'end_date', 'date_from'];
  const validateBookForm = (values) => {
    if (!values.event_name) {
      return 'Event name is required.';
    }
    if (!values.bld_cd) {
      return 'Building code is required.';
    }
    if (!values.num_par) {
      return 'Number of participants is required.';
    }
    if (!values.is_rec) {
      return 'Select if this is a recurring event or not.';
    }
    // if (!values.end_time) {
    //   return 'End time is required.';
    // }
    // if (!values.start_time) {
    //   return 'Date to is required.';
    // }
    if (!values.ev_dur) {
      return 'Duration is required.';
    }
    if (!values.date_from) {
      return 'Date from is required.';
    }
  
    if (values.is_rec && values.is_rec === 'yes') {
      if (!values.repeat_days || (values.repeat_days && values.repeat_days.length < 1)) {
        return 'Repeat days is required.';
      }

      if (!values.repeat_type) {
        return 'Repeat by is required.';
      }

      if (values.repeat_type && values.repeat_type === 'repeat_times' && !values.repeat_times) {
        return 'Repeat times is required.';
      }

      if (values.repeat_type && values.repeat_type === 'repeat_times' && values.repeat_times > 12) {
        return 'Repeat times should not be above 12';
      }
  
      if (values.repeat_type && values.repeat_type === 'end_date' && !values.end_date) {
        return 'Repeat end date is required.';
      }
    }
  
    if (isStaff) {
      if (!values.event_categories) {
        return 'Event category is required.';
      } else if(values.event_categories && displayClubSelect && !selectedClub && !club) {
        return 'Club is required.';
      } 
    }
  };

  const { allWpEventCategory, allWpClub } = useStaticQuery(graphql`
    query {
      allWpEventCategory {
        nodes {
          value: databaseId
          text: name
        }
      }

      allWpClub(sort: { fields: title, order: ASC }) {
        nodes {
          value: databaseId
          text: title
        }
      }
    }
  `);

  const eventCategories = allWpEventCategory?.nodes;
  const clubList = allWpClub?.nodes;

  const prepFields = (status, eventId) => {
    const data = {};
    data.status = status;
    data.title = `{${club.slug}} ${eventId} Invite email`;
    data.content = emailData.body;
    data.fields = {};
    data.fields.club = club.id;
    data.fields.subject = emailData.subject;
    if ('id' in emailData && emailData.id !== null && emailData.id !== '') {
      data.id = emailData.id;
    }

    return data;
  };

  const isClubCategory = useCallback(
    id => {
      const found = eventCategories.find(ec => Number(ec.value) === Number(id));
      setClubSelect(found && found.text === 'Club events');
    },
    [eventCategories]
  );

  const defineSaveData = () => {
    return {
      title: values.event_name,
      excerpt: values.event_description,
      content: values.event_description,
      event_categories: values.event_categories,
      status: 'draft',
      fields: {
        //Side fields
        club: selectedClub,
        start_date: values.date_from,
        end_date: values.date_to,
        start_time: values.start_time,
        end_time: values.end_time,
        event_fee: values.event_fee,
        joint_event: values.joint_event === 'yes' ? true : false,
        recurring_event: values.is_rec, // values.recurring_event,
        // Additional fields
        bld_cd: values.bld_cd,
        num_par: values.num_par,
        ev_dur: values.ev_dur,
        recurring_info: {
          repeat_days: values.repeat_days,
          repeat_type: values.repeat_type,
          repeat_times: values.repeat_times,
        },
        rec_digits: values.rec_digits,
        resource_id: values.resource_id,
        time_slot: values.time_slot,
        room_name: values.room_name,
        //Venue
        venue_location: values.venue_location,
        venue_remarks: values.venue_remarks,
        //Marshal
        marshal_name: values.covid_marshal,
        marshal_phone_number: values.phone_number,
        marshal_email: values.email,
        //SPACE details bool
        overnight_event: values.overnight_event === 'yes' ? true : false,
        food_availability: values.food_available === 'yes' ? true : false,
        alcohol_availability: values.alcohol_available === 'yes' ? true : false,
        charity_event: values.charity_event === 'yes' ? true : false,
        cs_event_funding: values.event_funding === 'yes' ? true : false,
        registration_override: values.registration_override,
        cta_label: values.cta_label,
        // Ticketing details
        enable_tickets: values.enable_tickets === 'yes' ? true : false,
        limit_registration_amount: values.limit_registration_amount,
        // Space API Details
        exec_member_number: values.exec_member_number || MemberNumber,
        exec_email: values.exec_email || Email,
        display_to: values.display_to,
        // Others
        send_members_email: values.send_members_email
      },
    };
  };

  const eventFormSubmitHandler = () => {
    const checkForm = validateForm(values, riskAssessment);

    if (checkForm) {
      toast.error(checkForm, {autoClose: 10000});
      return;
    }

    if ((values.venue_location === 'usu_managed_venues' && !eventData) || (values.venue_location === 'usu_managed_venues' && eventData && flagSpacioForm)) {
      return bookEvent();
    } else {
      return continueSubmitHandler();
    }    
  };

  const continueSubmitHandler = async (eventId = null) => {
    try {
      setSaving(true);
      const eventUpsert = {
        clubId: club.id
      }

      let saveData = defineSaveData();

      if (eventId) {
        saveData = {...saveData, ...{fields: {...saveData.fields, ...{ venue_remarks: eventId }} }};
      }

      if (!saveData.event_categories || saveData.event_categories === '') {
        const found = eventCategories.find(ec => ec.text === 'Club events');
        if (found) {
          saveData.event_categories = found.value;
        }
      }

      if (eventData) {
        eventUpsert.eventId = eventData.id;
      }

      if (selectedFile) {
        const base64Doc = await getBase64(selectedFile);
        const attachmentFile = await uploadMedia(selectedFile.name, base64Doc, selectedFile.type);
        saveData.fields.venue_attachment = attachmentFile.response.id;
      }

      if (values.alcohol_available && values.alcohol_available === 'yes') {
        const base64Doc = await getBase64(riskAssessment);
        const attachmentFile = await uploadMedia(
          riskAssessment.name,
          base64Doc,
          riskAssessment.type
        );
        saveData.fields.risk_assessment_form = attachmentFile.response.id;
      }

      const eventResponse = await wpApiNew('upsertEvent', {...eventUpsert, object: saveData});

      if (String(eventResponse.status).startsWith('2')) {
        //Remove storage
        removeStorage('event_form');
        removeStorage('event_club');

        const emailContent = prepFields('draft', eventResponse.response.id);
        await wpApiNew('upsertCommunication', {
          clubId: club.id,
          communicationId: emailContent.id || false,
          object: emailContent
        })

        if (['igm', 'agm'].indexOf(specialEvent) > -1) {
          const historyRecord = await wpApiNew('upsertClubHistory', {
            clubId: club.id,
            object: {
              title: `${
                club.title.rendered
              } ${specialEvent.toUpperCase()}${specialEvent === 'agm' ?
                ` ${club.acf.agm_month}` : ''} ${new Date().getFullYear()}`,
              status: 'publish',
              fields: {
                club: club.id,
                event: eventResponse.response.id,
                current_club_roles: club.acf.club_roles,
                current_club_execs: club.acf.club_execs,
                new_club_roles: roles,
                status: 'running',
              },
            }
          });
          await wpApiNew('upsertClub', {clubId: club.id, object: {
            fields: {
              club_roles: roles,
              current_igmagm_history_record: historyRecord.response.id,
            },
          }});
        }

        toast.success(
          eventData
            ? 'Event details have been saved'
            : 'Event has been submitted.', 
          {autoClose: 10000}
        );

        navigate(
          isStaff
            ? '/account/manage-events/'
            : `/account/my-usu/clubs/manage`,
          {
            state: {
              ...location.state,
            },
          }
        );
      } else {
        toast.error(
          'An unexpected error has occurred.\nPlease try again later.', {autoClose: 10000}
        );
        setSaving(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('An unexpected error has occurred.\nPlease try again later.', {autoClose: 10000});
      setSaving(false);
    } finally {
      // setSaving(false);
      // if (!eventData) {
      //   setValues(initState);
      // }
    }
  }

  const bookEvent = async (approve = null, event = false) => {
    setBooking(true);
    const checkForm = validateBookForm(values);
    let isFailed = false;

    if (checkForm) {
      toast.error(checkForm, {autoClose: 10000});
      isFailed = true;
    }

    if (!values.resource_id) {
      toast.error('Room is required.', {autoClose: 10000});
      isFailed = true;
    }

    if (!values.time_slot) {
      toast.error('Time slot is required.', {autoClose: 10000});
      isFailed = true;
    }

    if (!values.accept_booking_tc) {
      toast.error('You must read and accept our Terms & Conditions for bookings.', {autoClose: 10000});
      isFailed = true;
    }

    if (isFailed) {
      setBooking(false);
      return false;
    }

    const slots = values.time_slot.split('-');
    const startTime = moment(`2023-01-01 ${slots[0]}`).format('HH:mm');
    const endTime = moment(`2023-01-01 ${slots[1]}`).format('HH:mm');
    const formBody = [];
    let clubName = null;

    const clubData = await wpApiNew('getClubData', {
      clubId: selectedClub,
      fieldSet: 0,
      auth: false
    });
    if (String(clubData.status).startsWith('2') && clubData.response) {
      clubName = clubData.response.title.rendered;
    }

    if (!clubName && club) {
      clubName = club.title.rendered
    }

    formBody.push({
      ResourceId: values.resource_id,
      EventName: decode(values.event_name),
      NumOfParticipants: values.num_par,
      EventDate: moment(values.date_from).format('DDMMYYYY'),
      EventStartTime: startTime,
      EventEndTime: endTime,
      ClubName: decode(clubName),
      IsRecurrence: values.is_rec === 'yes' ? true : false,
      RecurrenceDetail: values.is_rec === 'yes' ? values.rec_digits : '',
      UsuNo: MemberNumber,
    })

    if (eventData && eventData.acf.venue_location === 'usu_managed_venues') {
      // For deletiong of event
      if (eventData.venue_remarks !== '') {
        formBody[0]['EventId'] = eventData.acf.venue_remarks;
      }
    }

    if (recurrBookingEdit) {
      // For deletiong of event
      if (recurrBookingEdit.event_id !== '') {
        formBody[0]['EventId'] = recurrBookingEdit.event_id;
      }
    }

    if (!recurrBookingEdit && spacioBookings && spacioBookings.length > 1) {
      // Work on deleting the old events and reoccured events.
      await deleteSpacioBookings({
        is_all: true,
        event_id: eventData.acf.venue_remarks
      });

      if (formBody && formBody[0] && formBody[0]['EventId']) {
        delete formBody[0]['EventId']
      }
    }
    
    postSpacioResource(...formBody)
      .then(async (data) => {
        setBooking(false);
        if (data.status === 200) {
          if (data.response && 'Message' in data.response) {
            if (data.response.Message.indexOf('successfully') > -1) {
              toast.success(data.response ? `${data.response.Message} - click submit below to save your records.` : 'Update successfully.', {autoClose: 10000});
              await getSpacioBooking({
                  'function': 'event_info',
                  'event_id': eventData.acf.venue_remarks
                }).then((data) => {
                    if (data.status === 200) {
                      if ((data.response && 'Message' in data.response) || (data.response && data.response.length < 1)) {
                        toast.error(data.response ? data.response.Message : 'Something went wrong please try again later.', {autoClose: 10000});
                        return;
                      }
                      
                      setSpacioBookings(data.response)
                    } else {
                      toast.error('Something went wrong, please try again.', {autoClose: 10000});
                      return;
                    }          
                });

              setBooking(false);
              setRoomsSelection(null);
              setTimesSelection(null)
              setRecurrBookingEdit(null);
              setRooms(null)
              setFlagSpacioForm(false);
            } else {
              toast.error(data.response ? data.response.Message : 'No rooms available for your selected dates and time.', {autoClose: 10000});
              return false;
            }
            return true;
          }
          setHasBooking(true);
          // console.log(data.response.Description);
          // setValues({...values, ...{venue_remarks: data.response.Description }})
          setTimeout(() => {
            if (event) {
              return continueEventHandler(approve, data.response.Description)
            } else {
              return continueSubmitHandler(data.response.Description)
            }
          }, 500);
          // toast.success('Booking request sent, please click submit button below to save your record.', {autoClose: 10000});
          return true;
        } else {
          toast.error('Something went wrong, please try again.', {autoClose: 10000});
          return false;
        }
      });
  }

  const eventHandler = async approve => {
    if ((values.venue_location === 'usu_managed_venues' && !eventData) || (values.venue_location === 'usu_managed_venues' && eventData && flagSpacioForm)) {
      return bookEvent(approve, true);
    } else {
      return continueEventHandler(approve);
    }
  };

  const continueEventHandler = async (approve, eventId = null) => {
    try {
      setSaving(true);
      const eventUpsert = {
        clubId: club.id,
        fieldSet: 1
      }
      let saveData = {};
      
      if (isStaff) {
        saveData = defineSaveData();
      }

      if (eventId) {
        saveData = {...saveData, ...{fields: {...saveData.fields, ...{ venue_remarks: eventId }} }};
      }

      saveData.status = approve ? 'publish' : 'private';

      if (eventData) {
        eventUpsert.eventId = eventData.id;
      }

      if (selectedFile) {
        const base64Doc = await getBase64(selectedFile);
        const attachmentFile = await uploadMedia(selectedFile.name, base64Doc, selectedFile.type);
        saveData.fields.venue_attachment = attachmentFile.response.id;
      }

      const theEvent = await wpApiNew('upsertEvent', {...eventUpsert, object: saveData});

      const formattedDate = formatEventDateTime(
        theEvent.response.acf.start_date,
        theEvent.response.acf.end_date,
        theEvent.response.acf.start_time,
        theEvent.response.acf.end_time,
        true
      );

      const emailContent = prepFields(approve ? 'publish' : 'draft', theEvent.response.id);
      await wpApiNew('upsertCommunication', {
        clubId: club.id,
        communicationId: emailContent.id || false,
        object: emailContent
      });
      if (approve && saveData.fields.send_members_email) {
        sendInviteEmail({
          name: decode(theEvent.response.title.rendered),
          date: `${formattedDate.formatDate} ${formattedDate.formatTime}`,
          link: `https://usu.edu.au/events/${theEvent.response.slug}/`
        });
      }

      // Get submitted exec details
      let execData = false;
      if (saveData.fields.exec_email) {
        const _execData = await getPerson(
          ['first', 'last', 'email'],
          saveData.fields.exec_email,
          'str::email'
        );
        if (_execData && _execData.status === 200 && _execData.response.contacts.length > 0) {
          execData = _execData.response.contacts[0];
        }
      }
      
      if (saveData && saveData.fields.venue_location === 'usu_managed_venues' && saveData.fields.venue_remarks !== '') {
        await updateSpacioBookingsStatus({
          is_all: true,
          event_id: saveData.fields.venue_remarks,
          status: approve ? 'APPROVED' : 'REJECTED'
        })

        if (approve && execData) {
          const emailDetails = {
            subject: `USU Event Booking Confirmation`,
            body: `
            <p>Thanks ${execData.fields['str::first']},</p>
            <p>Your room booking request is confirmed.</p>
            <p><strong>Booking ID:</strong> ${saveData.fields.venue_remarks}</p>
            <p><strong>Event Date/Time:</strong> ${saveData.fields.venue_remarks}</p>
            <p><strong>Event Name:</strong> ${saveData.title}</p>
            <p><strong>Room:</strong> ${saveData.fields.room_name}</p>
            <p><strong>Club:</strong> ${club ? decode(club.title.rendered) : '--'}</p>
            <p><strong>Booked by:</strong> ${execData.fields['str::first']}</p>
            <p>To change or cancel your booking, you must login to your <a href="https://usu.edu.au/account/my-usu/clubs/">Clubs Portal</a>.</p>
            <p>Before using the room, please ensure you understand and adhere to the following:</p>
            <ul>
              <li>Clubs may not bring in their own alcohol to any Club room</li>
              <li>Club rooms are booked free of charge and come as they are. We do not offer set-up or equipment. Chairs or other items that are ordinarily in the space may have been removed at the time of your booking</li>
              <li>Access to the Dance Studio must be arranged with the Clubs team directly</li>
              <li>At the conclusion of your room booking, rooms must be returned to their original, clean and tidy condition. All rubbish must be removed and all furniture replaced. The organiser will be held responsible for any damages and/or cleaning required and will be charged accordingly</li>
            </ul>
            `,
          };
          const options = {
            variables: {},
            email_name: 'USU Booking Confirmation',
            from_name: 'USU',
            reply_to: Email,
            emailData: emailDetails
          }

          const emailRecipients = [{
            email: execData.fields['str::email'],
            first: execData.fields['str::first'],
            last: execData.fields['str::last']
          }];
          await processEmail(emailRecipients, null, options).then(result => {
            if (result) {
              setTimeout(() => {
                setDisable(false);
              }, 5000);
            } else {
              setDisable(false);
              // TODO: Inform that something went wrong
            }
          });
        }
      }

      // Send notification
      if (approve && execData) {
        const emailDetails = {
          subject: `Your event has been approved`,
          body: `
          <p>Hello ${execData.fields['str::first']},</p>
          <p>Thank you for submitting notice for ${club ? decode(club.title.rendered) : '--'}'s event, ${decode(theEvent.response.title.rendered)} to be held on ${formattedDate.formatDate} ${formattedDate.formatTime}.</p>
          <p>This email is to inform you that the event notice has been reviewed and approved by the Clubs Team. The event will be available for members to register their interest within 2 hours.</p>
          <p>Don't hesitate to reach out if you have any questions.</p>
          <p>Kind regards,</p>
          <p>The Clubs Team</p>
          `,
        };
        const options = {
          variables: {},
          email_name: 'Event approval notice',
          from_name: 'USU Clubs Team',
          reply_to: 'clubs@usu.edu.au',
          emailData: emailDetails
        }

        const emailRecipients = [{
          email: execData.fields['str::email'],
          first: execData.fields['str::first'],
          last: execData.fields['str::last']
        }];
        await processEmail(emailRecipients, null, options).then(result => {
          if (result) {
            toast.success('Event has been approved.');
          } else {
            toast.warn('Event has been approved, but email notice failed to send.');
          }
        });
      } else if (execData) {
        const emailDetails = {
          subject: `Your event has been rejected`,
          body: `
          <p>Hello ${execData.fields['str::first']},</p>
          <p>This email is to inform you that ${club ? decode(club.title.rendered) : '--'}'s event, ${decode(theEvent.response.title.rendered.replace('Private: ', ''))}, scheduled for ${formattedDate.formatDate} ${formattedDate.formatTime} has been rejected. Please note that all events held by clubs must be approved by the Clubs Team and so if you wish to hold the event, you will be required to resubmit the pre-event form with sufficient notice.</p>
          <p>For more information on why the event was rejected, or assistance on resubmitting, please don't hesitate to reach out.</p>
          <p>Kind regards,</p>
          <p>The Clubs Team</p>
          `,
        };
        const options = {
          variables: {},
          email_name: 'Event rejection notice',
          from_name: 'USU Clubs Team',
          reply_to: 'clubs@usu.edu.au',
          emailData: emailDetails
        }

        const emailRecipients = [{
          email: execData.fields['str::email'],
          first: execData.fields['str::first'],
          last: execData.fields['str::last']
        }];
        await processEmail(emailRecipients, null, options).then(result => {
          if (result) {
            toast.success('Event has been rejected.');
          } else {
            toast.warn('Event has been rejected, but email notice failed to send.');
          }
        });
      } else if (approve) {
        toast.warn('Event has been approved, but email notice failed to send.');
      } else {
        toast.warn('Event has been rejected, but email notice failed to send.');
      }

      navigate('/account/manage-events/', {
        state: {
          ...location.state,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error('An unexpected error has occurred.\nPlease try again later.', {autoClose: 10000});
    } finally {
      setSaving(false);
    }
  }

  const disregard = () => {
    navigate('/account/manage-events/', {
      state: {
        ...location.state,
      },
    });
  }

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    if (id === 'event_categories') {
      isClubCategory(value);
    }
    if (id === 'change_club') {
      setSelectedClub(value);
    } else {
      tempValues[id] = value;
    }
    if (id === 'resource_id') {
      const timeSelection = [];
      if (rooms && rooms.length > 0) {
        rooms.map((room) => {
          if (room.resource_id === parseInt(value)) {
            tempValues['room_name'] = room.resource_name;
            room.events.map((event, i) => {
              const startTime = moment(`2023-01-01 ${event.event_start}`).format('h:mm a');
              const endTime = moment(`2023-01-01 ${event.event_end}`).format('h:mm a');
              const time = `${startTime} - ${endTime}`;
              timeSelection.push({ text: time, value: `${event.event_start}-${event.event_end}` })
              return event;
            })
          }

          return room;
        })
        setTimesSelection(timeSelection);
      }
    }
    if (bookFormFields.indexOf(id) > -1) {
      setBooking(false);
      setRoomsSelection(null);
      setTimesSelection(null)
      setRooms(null)
      setValues({...values, ...{venue_remarks: ''}})
    }
    if (id === 'is_rec' && value === 'yes') {
      tempValues['limit_registration_amount'] = '';
    }
    if (id === 'end_date') {
      tempValues['date_to'] = value;
    }
    if (id === 'repeat_times') {
      const date_from = values.date_from;
      const dateCalc = new Date(date_from);
      dateCalc.setDate(dateCalc.getDate() + (value * 7));
      const newDateTo = `${dateCalc.getFullYear()}-${String(dateCalc.getMonth() + 1).padStart(2, '0')}-${dateCalc.getDate()}`;
      tempValues['date_to'] = newDateTo;
    }
    setValues(tempValues);
  };

  const onHandleChangeBookingDate = (id, value) => {
    const tempValues = Object.assign({}, values);
    tempValues[id] = value;
    if (values.is_rec === 'no') {
      tempValues['date_to'] = value;
    }

    setValues(tempValues);
  }

  const onHandleChangeTimeSlot = (id, value) => {
    const tempValues = Object.assign({}, values);
    tempValues[id] = value;
    
    const times = value.split('-');
    tempValues.start_time = times[0];
    tempValues.end_time = times[1];

    setValues(tempValues);
  }

  const onHandleLimitBlur = (id, value) => {
    const limitRegos = Number(value);
    const roomParticipants = Number(values.num_par);
    const tempValues = Object.assign({}, values);
    if (roomParticipants && roomParticipants < limitRegos) {
      tempValues['limit_registration_amount'] = roomParticipants;
      setValues(tempValues);
      toast.warn(`Participants are less than your limit of ${limitRegos}. Limit set to match participants.`, {autoClose: 10000});
    } else if (roomParticipants && value === '' && values.is_rec === 'no') {
      tempValues['limit_registration_amount'] = roomParticipants;
      setValues(tempValues);
      toast.warn(`You can not allow more than ${roomParticipants} registrations. Limit set to match participants.`, {autoClose: 10000});
    }
  }

  const onHandleParticipantsBlur = (id, value) => {
    const roomParticipants = Number(value);
    const limitRegos = Number(values.limit_registration_amount);
    const tempValues = Object.assign({}, values);
    if (values.is_rec === 'yes') {
      tempValues['limit_registration_amount'] = '';
      setValues(tempValues);
    } else if (limitRegos && roomParticipants < limitRegos) {
      tempValues['limit_registration_amount'] = value;
      setValues(tempValues);
      toast.warn(`Participants are less than your limit of ${limitRegos}. Limit set to match participants.`, {autoClose: 10000});
    } else if (!limitRegos) {
      tempValues['limit_registration_amount'] = value;
      setValues(tempValues);
      toast.warn(`Limiting registrations to match participant number to avoid over registrations.`, {autoClose: 10000});
    }
  }

  const showSpacioForm = (flag) => {
    if (!flag) {
      setBooking(false);
      setRoomsSelection(null);
      setTimesSelection(null);
      setRooms(null);
      setRecurrBookingEdit(null);
    }

    setFlagSpacioForm(flag);
  }

  const handleDaysRepeat = (value) => {
    let tempValues = Object.assign({}, values);
    tempValues['repeat_days'] = value;
    setValues(tempValues);
  }

  const formatTime = time => {
    if (!time) return '';
    
    const regex = /([0-9]{1,2}):([0-9]{2}) (.*)/;
    const match = time.match(regex);

    let hour = Number(match[1]) === 12 ? 0 : Number(match[1]);
    if (match[3] === 'pm') hour = hour + 12;
    hour = ('0' + hour).slice(-2);
    return `${hour}:${match[2]}`;
  };

  const formatDate = date => {
    if (date) {
      let fDate = moment(date);

      return fDate.format('DD/MM/YYYY hh:mm A')
    }

    return;
  }

  const isGMEvent = useCallback(() => {
    // TODO: Need better indicator than this, even though titles are set by us
    if (customData || eventData) {
      if (((customData && customData.event_name) || (eventData && eventData.title.rendered)).endsWith('Inaugural General Meeting')) {
        setSpecialEvent('igm');
        return 'igm';
      } else if (((customData && customData.event_name) || (eventData && eventData.title.rendered)).endsWith('Annual General Meeting')) {
        setSpecialEvent('agm');
        return 'agm';
      }
    }

    return false;
  }, [customData, eventData]);

  useMemo(() => {
    if (eventData) {
      // console.log(eventData.content.rendered);
      const currentState = {
        event_name: eventData.title.rendered,
        event_categories: eventData.event_categories
          ? eventData.event_categories[0]
          : '',
        club: eventData.acf.club,
        event_description: eventData.content.rendered,
        joint_event: eventData.acf.joint_event ? 'yes' : 'no',
        recurring_event: eventData.acf.recurring_event,
        event_fee: eventData.acf.event_fee,
        date_from: eventData.acf.start_date.replace(
          /([0-9]{4})([0-9]{2})([0-9]{2})/,
          '$1-$2-$3'
        ),
        date_to: eventData.acf.end_date.replace(
          /([0-9]{4})([0-9]{2})([0-9]{2})/,
          '$1-$2-$3'
        ),
        start_time: formatTime(eventData.acf.start_time),
        end_time: formatTime(eventData.acf.end_time),
        venue_location: eventData.acf.venue_location,
        venue_remarks: eventData.acf.venue_remarks,
        venue_attachment: eventData.acf.venue_attachment,
        risk_assessment_form: eventData.acf.risk_assessment_form,
        covid_marshal: eventData.acf.marshal_name,
        phone_number: eventData.acf.marshal_phone_number,
        email: eventData.acf.marshal_email,
        overnight_event: eventData.acf.overnight_event ? 'yes' : 'no',
        food_available: eventData.acf.food_availability ? 'yes' : 'no',
        alcohol_available: eventData.acf.alcohol_availability ? 'yes' : 'no',
        charity_event: eventData.acf.charity_event ? 'yes' : 'no',
        event_funding: eventData.acf.cs_event_funding ? 'yes' : 'no',
        enable_tickets: eventData.acf.enable_tickets ? 'yes' : 'no',
        limit_registration_amount: eventData.acf.limit_registration_amount,
        allocate_covid_safety: eventData.acf.marshal_name ? true : false,
        registration_override: eventData.acf.registration_override,
        cta_label: eventData.acf.cta_label,
        bld_cd: eventData.acf.bld_cd,
        num_par: eventData.acf.num_par,
        is_rec: eventData.acf.recurring_event,
        ev_dur: eventData.acf.ev_dur,
        repeat_days: eventData.acf.recurring_info?.repeat_days,
        repeat_type: eventData.acf.recurring_info?.repeat_type,
        repeat_times: eventData.acf.recurring_info?.repeat_times,
        end_date: eventData.acf.end_date.replace(
          /([0-9]{4})([0-9]{2})([0-9]{2})/,
          '$1-$2-$3'
        ),
        exec_member_number: eventData.acf.exec_member_number,
        exec_email: eventData.acf.exec_email,
        room_name: eventData.acf.room_name,
        // resource_id: eventData.acf.resource_id,
        // time_slot: eventData.acf.time_slot
        display_to: eventData.acf.display_to,
        send_members_email: isArray(eventData.acf.send_members_email) && eventData.acf.send_members_email.length > 0 ? 
          (eventData.acf.send_members_email[0] === '1' ? true : false) : false
      };

      setValues(currentState);
      isClubCategory(currentState.event_categories);

      if (eventData.acf.club) {
        wpApiNew('getClubData', {
          clubId: eventData.acf.club.ID,
          fieldSet: 1,
          auth: false
        }).then(clubData => {
          if (String(clubData.status).startsWith('2') && clubData.response) {
            setClub(clubData.response);
            setSelectedClub(eventData.acf.club.ID);

            const clubRoles = get(clubData, 'response.acf.club_roles', []);
            const theRoles = [...requiredRoles];
            clubRoles && clubRoles.map(role => {
              const exists = theRoles.find(r => r.role === role.role);
              if (!exists) {
                theRoles.push(role);
              }

              return true;
            });
            setRoles(theRoles);

            // Search for communication
            wpApiNew('getClubCommunicationsList', {
              clubId: clubData.response.id,
              search: `%20${eventData.id}%20invite%20email`,
              draftOnly: true,
              fieldSet: 1
            }).then(email => {
              if (String(email.status).startsWith('2') && email.response) {
                // Should only be 1 at this stage, so grab the first
                const emailContent = email.response[0];
                if (emailContent) {
                  const emailDetails = {
                    subject: emailContent.acf.subject,
                    body: emailContent.content.rendered,
                    club: clubData.response.id,
                    id: emailContent.id,
                  };
  
                  setEmailInitial(emailDetails);
                  setEmailData(emailDetails);
                }
              }
            });
          }
        });
      }

      if (eventData.acf.venue_location === 'usu_managed_venues' && eventData.acf.venue_remarks !== '') {
        getSpacioBooking({
          'function': 'event_info',
          'event_id': eventData.acf.venue_remarks
        })
          .then((data) => {
            if (data.status === 200) {
              if ((data.response && 'Message' in data.response) || (data.response && data.response.length < 1)) {
                toast.error(data.response ? data.response.Message : 'Something went wrong please try again later.', {autoClose: 10000});
                return;
              }
              
              setSpacioBookings(data.response)
            } else {
              toast.error('Something went wrong, please try again.', {autoClose: 10000});
              return;
            }          
          });
      }
    }

    getFunction({
      'function': 'dur'
    }).then((data) => {
        if (data.status === 200) {
          if (data.response && data.response.length > 0) {
            setMaxDuration(data.response[0]?.max_hours_limit_from_all || 2);
          }
        } else {
          return;
        }          
    });
  }, [eventData, requiredRoles, isClubCategory]);

  useMemo(() => {
    const fetchClub = async () => {
      const clubData = await wpApiNew('getClubData', {
        clubId: selectedClub,
        auth: true,
        fieldSet: 99
      }); // Uses most fields - deep review would be required to lock down
      if (String(clubData.status).startsWith('2') && clubData.response) {
        setClub(clubData.response);
        const clubRoles = get(clubData, 'response.acf.club_roles', []) || [];
        const theRoles = [...requiredRoles];
        clubRoles.map(role => {
          const exists = theRoles.find(r => r.role === role.role);
          if (!exists) {
            theRoles.push(role);
          }

          return true;
        });
        setRoles(theRoles);

        const isGM = isGMEvent();
        let emailDetails = '';
        if (isGM === 'ign') {
          emailDetails = {
            subject: `RSVP to the ${decode(clubData.response.title.rendered)} Inaugural General Meeting`,
            body: `
            <p>Hi ##MEMBERNAME##,</p>
            <p>We're excited to inform you that ${decode(clubData.response.title.rendered)} has been approved to progress to the next stage of setup of our club!</p>
            <p>You are receiving this email because you had expressed interest in the club. Because of this, we now invite you to join the clubs IGM meeting to gain more information about the club and participate in voting members into club positions.</p>
            <p>Due to high activity, the USU website takes a little bit longer to load events and meetings for you to register your attendance. Please allow a few hours to follow the link below to RSVP to the IGM where you'll receive your unique QR check-in code, enabling the ability for you to vote.</p>
            <p>##EVENTLINK##</p>
            <p>We look forward to seeing you there!</p>
            `,
          };
        } else if (isGM === 'agm') {
          emailDetails = {
            subject: `RSVP to the ${decode(clubData.response.title.rendered)} Annual General Meeting`,
            body: `
            <p>Hi ##MEMBERNAME##,</p>
            <p>It's time for the clubs AGM where you get your say in the management of the club.</p>
            <p>We appreciate you showing keen interest in wanting to attend the meeting!</p>
            <p>Due to high activity, the USU website takes a little bit longer to load events and meetings for you to register your attendance.</p>
            <p>Please RSVP via the link below a few hours after you receive this email.</p>
            <p>##EVENTLINK##</p>
            <p>We look forward to seeing you there!</p>
            `,
          };
        } else {
          emailDetails = {
            subject: `RSVP to the ${decode(clubData.response.title.rendered)} new event`,
            body: `
            <p>Hello ##MEMBERNAME##,</p>
            <p>You're receiving this email as your club, ${decode(clubData.response.title.rendered)}, has scheduled an event, ##EVENTNAME##, for ##EVENTDATE##.</p>
            <p>You'll be able to register your intent to attend via the below link, which will be accessible within 2 hours:</p>
            <p>##EVENTLINK##</p>
            <p>If you are unable to access the above event link, please wait at least twelve hours before attempting again.</p>
            <p>Kind regards,</p>
            <p>${decode(clubData.response.title.rendered)}</p>
            `,
          };
        }

        setEmailInitial(emailDetails);
        setEmailData(emailDetails);
      }
      return true;
    };

    if (customData) {
      setValues(customData);
    }

    if (selectedClub) {
      fetchClub();
    }
  }, [customData, selectedClub, requiredRoles, isGMEvent]);

  // const parseTime = time => {
  //   let returnTime = '';
  //   if (time) {
  //     const tempTime = time.split(':');
  //     const hour = Number(tempTime[0]);

  //     if (hour > 12) {
  //       returnTime = `${hour - 12}:${tempTime[1]} PM`;
  //     } else {
  //       returnTime = `${tempTime.join(':')} AM`;
  //     }
  //   }
  //   return returnTime;
  // };

  // const ivvyRedirect = event => {
  //   event.preventDefault();
  //   setStorage('event_form', JSON.stringify(values));
  //   setStorage('event_club', JSON.stringify(location.state));
  //   formRef.current.submit();
  // };

  useMemo(() => {
    const pageInit = () => {
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const bookingId = params.get('bookingId');
        const getPrevFields = getStorage('event_form');
        if (getPrevFields && bookingId) {
          const values = {
            ...JSON.parse(getPrevFields),
            venue_remarks: bookingId,
          };

          setValues(values);
          setIsNewBooking(true);
        }
      }
    };
    pageInit();
  }, [location.search]);

  const updateRoles = (value, field, index) => {
    const tempRoles = [...roles];
    tempRoles[index][field] = value;
    setRoles(tempRoles);
  };

  const removeRole = index => {
    const tempRoles = [...roles];
    tempRoles.splice(index, 1);
    setRoles(tempRoles);
  };

  const addRole = () => {
    const tempRoles = [...roles];
    tempRoles.push({
      role: '',
      amount: 1,
    });
    setRoles(tempRoles);
  };

  const updateValue = (field, value) => {
    const state = { ...emailData };
    if (field !== 'subject' && state.subject === '') {
      /* Initialisation of the richtext body clears the subject from state, so ensure its populated */
      state.subject = emailInitial.subject;
    }
    if ('club' in emailInitial) {
      state.club = emailInitial.club;
    }
    if ('id' in emailInitial) {
      state.id = emailInitial.id;
    }
    state[field] = value;
    setEmailData({ ...state });
  };

  const returnBody = body => {
    updateValue('body', body);
  };

  const charCount = () => {
    return 120 - emailData.subject.length;
  };

  const processEmail = (recipients, eventDetails, options = null) => {
    const emailsProcessed = recipients.map(async recipient => {
      let replacedDynamic = options ? options.emailData.body : emailData.body;
      let variables = {
        MEMBERNAME: recipient.first,
        EVENTNAME: eventDetails?.name,
        EVENTDATE: eventDetails?.date,
        EVENTLINK: `<a href="${eventDetails?.link}">${eventDetails?.link}</a>`,
      };
      if (options) {
        variables = options.variables;
      }

      Object.keys(variables).map(variable => {
        replacedDynamic = replacedDynamic.replace(
          `##${variable}##`,
          variables[variable]
        );
        return true;
      });
      const emailBody = renderToString(
        <OrttoEmailTemplate
          logo={null}
          clubName={decode(club.title.rendered)}
          content={replacedDynamic}
        />
      );
      // console.log("Email Body:", emailBody);

      const emailOptions = {
        html_body: emailBody,
        subject: options ? options.emailData.subject : emailData.subject,
        email_name: options ? options.email_name : `${
          decode(club.title.rendered)
        } ${specialEvent ? specialEvent.toUpperCase() : 'event'} Invite email`,
        from_name: options ? decode(options.from_name) : decode(club.title.rendered),
        reply_to: options ? options.reply_to : club.acf.details.email,
      };

      return await sendEmail(emailOptions, [recipient]);
    });

    return Promise.all(emailsProcessed).then(response => {
      if (response.length > 0) {
        return { status: 200, response: { emails: response } };
      } else {
        return { status: 500, response: { error: 'Error occurred' } };
      }
    });
  };

  const sendTest = () => {
    setDisable(true);
    setTesting(`Sending test...`);
    const emailRecipients = [{
        email: auth.state.email,
        first: auth.state.usu.FirstName,
        last: auth.state.usu.LastName
    }];
    // const emailRecipients = [
    //   {
    //     email: 'ash@matterdesign.com.au',
    //     first: 'Ash',
    //     last: 'Durham',
    //   },
    // ];

    const quickFormatTime = (date, time) => {
      return new Date(`${date} ${time}`).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase();
    }

    const currentDate = new Date();
    const currentTime = currentDate.toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase();
    const formattedDate = formatEventDateTime(
      values.date_from !== '' ? values.date_from.replaceAll('-', '') : `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}`,
      values.date_to !== '' ? values.date_to.replaceAll('-', '') : `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}`,
      values.start_time !== '' ? quickFormatTime(values.date_from, values.start_time) : currentTime,
      values.end_time !== '' ? quickFormatTime(values.date_to, values.end_time) : currentTime,
      true
    );

    processEmail(
      emailRecipients,
      {
        name: values.event_name !== '' ? values.event_name : 'Example event name',
        date: `${formattedDate.formatDate} ${formattedDate.formatTime}`,
        link: 'https://usu.edu.au/events/example-url/'
      }
    ).then(result => {
      if (
        String(result.status).startsWith('2') &&
        'emails' in result.response
      ) {
        setDisable(false);
        setTesting('Sent!');
        setTimeout(() => {
          setTesting(`Send test`);
        }, 5000);
      } else {
        setDisable(false);
        // TODO: Inform that something went wrong
      }
    });
  };

  const viewRooms = async () => {
    const checkForm = validateBookForm(values);
    if (roomSelectRef && roomSelectRef.current) {
      roomSelectRef.current.target.val('');
    }

    if (timeSelectRef && timeSelectRef.current) {
      timeSelectRef.current.target.val('');
    }

    // if (!values.event_categories || values.event_categories === '') {
    //   const foundCategory = eventCategories.find(ec => ec.text === 'Club events');
    //   }
    // }

    if (checkForm) {
      toast.error(checkForm, {autoClose: 10000});
      return;
    }

    setBooking(false);
    setRoomsSelection(null);
    setTimesSelection(null)
    setRooms(null)
    setGettingRooms(true);
    let isPerformanceClub = false;
    const clubData = await wpApiNew('getClubData', {
      clubId: selectedClub,
      fieldSet: 2,
      auth: false
    });
    if (String(clubData.status).startsWith('2') && clubData.response) {
      if (clubData.response.club_categories.length > 0) {
        isPerformanceClub = clubData.response.club_categories.includes(36) ? true : false;
      }
    } else {
      setGettingRooms(false);
      toast.error('Something went wrong, please try again.', {autoClose: 10000});
    }

    // const startTime = moment(`${values.date_from} ${values.start_time}`);
    // const endTime = moment(`${values.date_from} ${values.end_time}`);
    // const duration = endTime.diff(startTime, 'minutes');
    const duration = parseFloat(values.ev_dur) * 60;
    const formBody = [];
    // bld_cd=M&is_per=false&ev_date=07072023&ev_dur=120&num_par=15&is_rec=true&rec_info=000010031072023

    const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri'];
    let recDigits = '';
    if (values.is_rec === 'yes') {
      if (values.repeat_days && values.repeat_days.length > 0) {
        weekdays.map((day) => {
          if (values.repeat_days.includes(day)) {
            recDigits = `${recDigits}1`;
          } else {
            recDigits = `${recDigits}0`;
          }

          return day;
        })
        // Include 00 for sat and sun
        recDigits += `00`;
      } else {
        recDigits = `0000000`;
      }

      if (values.repeat_type === 'repeat_times' && values.repeat_times) {
        const repeatDigit = String(values.repeat_times).padStart(8, '0');
        recDigits += repeatDigit;
      } else if (values.repeat_type === 'end_date' && values.end_date) {
        recDigits += moment(values.end_date).format('DDMMYYYY')
      }
    } else {
      recDigits = '000000000000000';
    }
      
    formBody.push({
      bld_cd : values.bld_cd,
      ev_date: moment(values.date_from).format('DDMMYYYY'),
      ev_dur: duration > 0 ? duration : 60,
      is_per: isPerformanceClub,
      num_par: values.num_par,
      is_rec: values.is_rec === 'yes' ? true : false,
      rec_info: recDigits
    })

    await getSpacioResource(...formBody)
      .then((data) => {
        setGettingRooms(false);
        if (data.status === 200) {
          if (data.response && 'Message' in data.response) {
            toast.error(data.response ? data.response.Message : 'No rooms available for your selected dates and time.', {autoClose: 10000});
            return;
          }
          const selection = [];
          data.response.map((room) => {
            selection.push({ text: room.resource_name, value: room.resource_id });
            return room;
          });
          setValues({...values, ...{rec_digits : recDigits, is_per: isPerformanceClub, resource_id : '', time_slot: '', venue_remarks: ''}})
          setRooms(data.response)
          setRoomsSelection(selection)
        } else {
          toast.error('Something went wrong, please try again.', {autoClose: 10000});
          return;
        }
      });
  }

  const editRecurResource = (booking) => {
    setValues({...values, ...{is_rec: 'no' }})
    setRecurrBookingEdit(booking);
    setFlagSpacioForm(true);
  }

  const isSpacioFieldDisabled = (flag = false) => {
    if (flag && recurrBookingEdit) {
      return true;
    }

    if (hasBooking) {
      return true;
    }

    return false;
  }

  const getDurationValues = () => {
    const arrValues = [];
    let max = maxDuration || 2;
    let running = .5;
    do {
      const splitted = running.toString().split('.');
      arrValues.push({
        value: running,
        text: `${splitted[0] !== '0' ? `0${splitted[0]}` : `00`}:${splitted.length > 1 && splitted[1] > 0 ? '30' : '00'} (${splitted.length < 2 ? (`${splitted[0]} hour${splitted[0] > 1 ? 's' : ''}`) : (
          splitted[0] === '0' ? 
            `30 minutes` : 
            `${splitted[0]}${splitted.length > 1 ? '.5 ' : ' '}hour${splitted[0] > 1 || splitted.length > 1 ? 's' : ''}`
        )})`
      })
      running += .5;
    } while (running <= max)

    return arrValues;
  }
 
  const sendInviteEmail = eventDetails => {
    setDisable(true);

    getTags(`club-${club.slug}`).then(async tags => {
      if (String(tags.status).startsWith('2') && tags.response.length > 0) {
        const tagId = tags.response.find(tag => `club-${club.slug}` === tag.name);
        const memberList = await getPerson(
          ['first', 'last', 'email'],
          tagId.id,
          'tag_id'
        );

        if (
          memberList.status === 200 &&
          'contacts' in memberList.response &&
          Array.isArray(memberList.response.contacts)
        ) {
          const emailRecipients = memberList.response.contacts.map(member => {
              return {
                  email: member.fields['str::email'],
                  first: member.fields['str::first'],
                  last: member.fields['str::last']
              }
          });
          // const emailRecipients = [
          //   {
          //     email: 'ash@matterdesign.com.au',
          //     first: 'Ash',
          //     last: 'Durham',
          //   },
          // ];

          processEmail(emailRecipients, eventDetails).then(result => {
            if (result) {
              setTimeout(() => {
                setDisable(false);
              }, 5000);
            } else {
              setDisable(false);
              // TODO: Inform that something went wrong
            }
          });
        }
      }
    });
  };
  return (
    <Container>
      <div className={styles.root}>
        <section>
          <FormInputField
            id='event_name'
            type='input'
            labelName='Event name'
            placeholder='Event name'
            disabled={specialEvent ? true : false}
            value={decode(values['event_name'])}
            handleChange={onHandleChange}
          />

          {isStaff && eventCategories && (
            <SelectField
              id='event_categories'
              placeholder={'Select '}
              label={'Event Category'}
              data={eventCategories}
              value={values['event_categories']}
              handleChange={onHandleChange}
            />
          )}

          {isStaff && displayClubSelect && (
            <SelectField
              id='change_club'
              placeholder={'Select '}
              label={'Host club'}
              data={clubList}
              value={get(values, 'club.ID', get(values, 'club', null))}
              handleChange={onHandleChange}
            />
          )}

          <FormInputField
            id='event_description'
            type='richtext'
            labelName='Event description'
            placeholder='Event description'
            value={values['event_description']}
            handleChange={onHandleChange}
          />

          <div className={['igm', 'agm'].indexOf(specialEvent) === -1 ? `grid grid-50 grid-responsive ` : `grid grid-responsive`}>
            {['igm', 'agm'].indexOf(specialEvent) === -1 && (
              <SelectField
                id='joint_event'
                placeholder={'Select '}
                label={'Is this a joint event?'}
                data={decisionArray}
                value={values['joint_event']}
                handleChange={onHandleChange}
              />
            )}
            <FormInputField
              id='event_fee'
              type='number'
              labelName={'Ticket price'}
              placeholder={'Ticket price (leave blank if free)'}
              value={values['event_fee']}
              handleChange={onHandleChange}
            />
          </div>

          <div className={`grid grid-50 grid-responsive `}>
            <DateTimeInput
              id='date_from'
              type='date'
              label='Date from'
              placeholder='Date from'
              value={values['date_from']}
              handleChange={onHandleChange}
              ref={startDateRef}
              minDays={specialEvent ? 14 : null}
            />

            <DateTimeInput
              id='date_to'
              type='date'
              label='Date to'
              placeholder='Date to'
              value={values['date_to']}
              handleChange={onHandleChange}
              ref={endDateRef}
              minDays={specialEvent ? 14 : null}
            />
          </div>
          <div className={`grid grid-50 grid-responsive `}>
            <DateTimeInput
              id='start_time'
              type='time'
              label='Start time'
              placeholder='Start time'
              value={values['start_time']}
              handleChange={onHandleChange}
              ref={startTimeRef}
            />

            <DateTimeInput
              id='end_time'
              type='time'
              label='End time'
              placeholder='End time'
              value={values['end_time']}
              handleChange={onHandleChange}
              ref={endTimeRef}
            />
          </div>
        </section>
        <section>
          <div className={`grid grid-50 grid-responsive `}>
            <FormInputField
              id='limit_registration_amount'
              type='number'
              labelName={'Limit registrations'}
              placeholder={'Leave blank if unlimited'}
              value={values['limit_registration_amount']}
              handleChange={onHandleChange}
              handleBlur={onHandleLimitBlur}
              disabled={values.is_rec === 'yes'}
            />

            <SelectField
              id='enable_tickets'
              value={values['enable_tickets'] || 'no'}
              label={'Enable ticketing/check-ins?'}
              data={decisionArray}
              handleChange={onHandleChange}
            />
          </div>
          <div className={`grid grid-50 grid-responsive `}>
            <FormInputField
              id='event_fee'
              type='number'
              labelName={'Ticket price'}
              placeholder={'Ticket price (leave blank if free)'}
              value={values['event_fee']}
              handleChange={onHandleChange}
            />
          </div>
        </section>

        <section>
          <div className={`grid grid-50 grid-responsive `}>
            <SelectField
              id='venue_location'
              placeholder={'Select venue'}
              value={values['venue_location']}
              label={'Where will you be holding your event?'}
              data={locationArray}
              handleChange={onHandleChange}
            />
          </div>
          <div>
            {(eventData && eventData.acf.venue_location) ||
            (isNewBooking && values.venue_remarks) ||
            (values.venue_location &&
              values.venue_location !== 'usu_managed_venues') ? (
                <>
                 {(eventData && eventData.acf.venue_location === 'usu_managed_venues' && spacioBookings && spacioBookings.length > 0) ? (
                  !flagSpacioForm && (
                    <div className={styles.spacioVenueDetails}>
                      <span className={styles.updateResourceBtn} role="presentation" onClick={() => showSpacioForm(true)}>Update Event Resource</span><br/>
                      <p>Status: <span className={`badge`}>{spacioBookings[0].event_status}</span><br/>
                      Venung Booking ID: {eventData && eventData.acf.venue_remarks}<br/>
                      {spacioBookings && spacioBookings.length > 1 && (
                        'Recurring Event'
                      )}</p>
                      <ul className={styles.eventTimeList}>
                        {spacioBookings && spacioBookings.map((booking, index) => (
                          <li key={index}>
                            <p>
                            {spacioBookings && spacioBookings.length > 1 && (
                              <><span className={styles.genericCta} role="presentation" onClick={() => editRecurResource(booking)}>Edit Room/Time</span><br/></>
                            )}
                            Room Name: {booking.resource_name}<br/>
                            Start Date: {formatDate(booking.event_start)}<br/>
                            End Date: {formatDate(booking.event_end)}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                ) : (
                  <FormInputField
                    id='venue_remarks'
                    type={'textarea'}
                    labelName={
                      values.venue_location === 'usu_managed_venues'
                        ? 'Venue booking ID'
                        : `Please type your venue location and then attach your ${
                            values.venue_location === 'university_managed_spaces'
                              ? 'Uni venues booking confirmation.'
                              : 'booking confirmation if you have one.'
                          } `
                    }
                    placeholder={
                      values.venue_location === 'usu_managed_venues'
                        ? 'Venue booking ID'
                        : 'Venue details'
                    }
                    value={values['venue_remarks']}
                    handleChange={onHandleChange}
                  />
                 )}
                </>
            ) : null}
          </div>
          {values.venue_location &&
            values.venue_location !== 'usu_managed_venues' && (
              <div>
                <div className={`grid grid-50 grid-responsive `}>
                  <div>
                    <p className={styles.dropZoneLabel}>
                      {selectedFile ? (
                        <>{selectedFile.name}</>
                      ) : (
                        <>Attachments (optional)</>
                      )}
                    </p>

                    <p className={styles.dropZoneNotice}>
                      Must be PDF and cannot exceed 1MB.
                    </p>
                  </div>
                  <div className={styles.selectContainer}>
                    <input
                      multiple={false}
                      type='file'
                      ref={attachmentRef}
                      onChange={e =>
                        e.target.files.length > 0
                          ? setSelectedFile(e.target.files[0])
                          : undefined
                      }
                    />
                    <Button
                      onClick={() => attachmentRef?.current?.click()}
                      type={'button'}
                      level='primary'
                      className={styles.selectButton}>
                      Select file
                    </Button>
                  </div>
                  {eventData && eventData.acf.venue_attachment && (
                    <div>
                      <p className={styles.dropZoneLabel}>
                        Current attachment:
                      </p>
                      <a
                        target={'_blank'}
                        href={eventData.acf.venue_attachment.url}
                        className={styles.dropZoneNotice}
                        rel='noreferrer'>
                        <u>{eventData.acf.venue_attachment.filename}</u>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
            {((!eventData &&
            values.venue_location === 'usu_managed_venues') || (flagSpacioForm)) && (
              <div className={`${styles.spaceFormContainer} ${gettingRooms ? styles.containerLoading : ''}`}>
                {spacioBookings && spacioBookings.length > 0 && showSpacioForm && !hasBooking && (
                  <span className={`${styles.updateResourceBtn} mb-5`} role="presentation" onClick={() => showSpacioForm(false)}>Cancel</span>
                )}
                <p className='mb-5'>
                  If Clubs wish to book University Venues, they should first make these bookings by themselves using the <Button type="a" link={true} target="_blank" href="https://resourcebooker.sydney.edu.au/">Uni's Resource Booker system</Button>.
                </p>
                <div className={`grid grid-50 grid-responsive`}>
                  <SelectField
                    id='bld_cd'
                    placeholder={'Select '}
                    value={values['bld_cd']}
                    label={
                      'Select your preferred building'
                    }
                    data={[
                      {text: 'Manning House', value: 'M'},
                      {text: 'Holme Building', value: 'H'},
                      {text: 'Wentworth Building', value: 'W'},
                    ]}
                    handleChange={onHandleChange}
                    disabled={isSpacioFieldDisabled()}
                  />
                  <DateTimeInput
                    id='date_from'
                    type='date'
                    label='Date'
                    placeholder='Date'
                    value={values['date_from']}
                    handleChange={onHandleChangeBookingDate}
                    ref={startDateRef}
                    disabled={isSpacioFieldDisabled()}
                  />
                </div>
                <div className={`grid grid-50 grid-responsive `}>
                  <SelectField
                    id='ev_dur'
                    placeholder={'Select '}
                    label={'Event duration (maximum allowed time 2hours)'}
                    data={getDurationValues()}
                    value={values['ev_dur']}
                    handleChange={onHandleChange}
                    disabled={isSpacioFieldDisabled()}
                  />
                  <FormInputField
                    id='num_par'
                    type='number'
                    labelName={'Number of Participants'}
                    placeholder={'Number of Participants'}
                    value={values['num_par']}
                    handleChange={onHandleChange}
                    handleBlur={onHandleParticipantsBlur}
                    disabled={isSpacioFieldDisabled()}
                  />
                </div>
                <div className={`grid grid-50 grid-responsive `}>
                  <SelectField
                    id='is_rec'
                    placeholder={'Select '}
                    label={'Is Recurring?'}
                    data={decisionArray}
                    value={values['is_rec']}
                    handleChange={onHandleChange}
                    disabled={isSpacioFieldDisabled(true)}
                  />
                  {eventData && recurrBookingEdit && (
                  <div className={styles.editWarningText}>
                    <p>Updating if its recurring field is not allowed for updating reoccurred event, if you wish to update this part proceed to update the main booking.</p>
                  </div>
                  )}
                </div>
                {values && values.is_rec === 'yes' && (
                  <>
                    <h6 className={`mb-5`}>Recurring Configuration</h6>
                    <div className={`grid grid-responsive `}>
                      <WeekdaysSelector handleChange={handleDaysRepeat} id={"weekdays"} values={values['repeat_days']} label={"Select repeat days of the week"} />
                    </div>
                    <div className={`grid grid-50 grid-responsive `}>
                      <SelectField
                        id='repeat_type'
                        placeholder={'Select '}
                        label={'Repeat by'}
                        data={[
                          { text: 'Repeating times', value: 'repeat_times' },
                          { text: 'Finish by end date', value: 'end_date' },
                        ]}
                        value={values['repeat_type']}
                        handleChange={onHandleChange}
                        disabled={isSpacioFieldDisabled()}
                      />
                      {values.repeat_type && values.repeat_type === 'repeat_times' && (
                        <FormInputField
                          id='repeat_times'
                          type='number'
                          max='12'
                          labelName={'Repeat times'}
                          placeholder={'Max repeating value is 12'}
                          value={values['repeat_times']}
                          handleChange={onHandleChange}
                          disabled={isSpacioFieldDisabled()}
                        />
                      )}
                      {values.repeat_type && values.repeat_type === 'end_date' && (
                        <DateTimeInput
                          id='end_date'
                          type='date'
                          label='End date'
                          maxDays={90}
                          placeholder='Repeat end date'
                          value={values['end_date']}
                          handleChange={onHandleChange}
                          ref={endDateRef}
                          disabled={isSpacioFieldDisabled()}
                        />
                      )}
                    </div>
                    <div className={styles.editWarningText}>
                      <p><strong>Please note:</strong> On-site event registrations are not available for recurring events. If you need to manage registrations on a per event basis, it is recommended that you utilise an external ticketing system and use the "Override registration location" field below to link to it.</p>
                    </div>
                    <div className={styles.editWarningText}>
                      <p><strong>Please note:</strong> If you require booking amendments during the recurring timeline, please contact the Clubs team for support.</p>
                    </div>
                  </>
                )}
                {!hasBooking && (<div className={`${styles.roomControls} mt-2 mb-5`}>
                  <span
                    className={styles.getAvailabilityCta}
                    role="presentation"
                    onClick={() => viewRooms()}>
                    Get Availability
                  </span>
                  {gettingRooms && (
                    <div className={styles.roomsLoader}>
                      <Loader />
                    </div>
                  )}  
                </div>)}
                {!gettingRooms && roomsSelection && roomsSelection.length > 0 && (
                  <>
                    {eventData && spacioBookings && (
                    <p>
                      Previous Timeslot: {formatDate(recurrBookingEdit ? recurrBookingEdit.event_start : spacioBookings[0].event_start)} - {formatDate(recurrBookingEdit ? recurrBookingEdit.event_end : spacioBookings[0].event_end)}
                    </p>)}
                    <div className={`grid grid-50 grid-responsive `}>
                      <SelectField
                        id='resource_id'
                        placeholder={'Select '}
                        label={'Select room'}
                        data={roomsSelection}
                        value={values['resource_id']}
                        handleChange={onHandleChange}
                        ref={roomSelectRef}
                        disabled={isSpacioFieldDisabled()}
                      />
                      {timesSelection && timesSelection.length > 0 && (
                        <SelectField
                          id='time_slot'
                          placeholder={'Select '}
                          label={'Select time slot'}
                          data={timesSelection}
                          value={values['time_slot']}
                          handleChange={onHandleChangeTimeSlot}
                          ref={timeSelectRef}
                          disabled={isSpacioFieldDisabled()}
                        />
                      )}
                    </div>
                    {!hasBooking && <div className={`grid grid-responsive`}>
                      <Checkbox
                        id="accept_booking_tc"
                        value={values['accept_booking_tc']}
                        label={`I accept all booking <a href="https://usu.edu.au/article/room-bookings-tcs" target="_blank" rel="nofollow">Terms & Conditions.</a>`}
                        action={() => onHandleChange('accept_booking_tc', !values['accept_booking_tc'])}
                        isChecked={values['accept_booking_tc']}
                      />
                      {spacioBookings && spacioBookings.length > 1 && recurrBookingEdit && (
                        <ConfirmDialog
                          title="Are you sure?"
                          disableBackdropClick={true}
                          message={spacioBookings && spacioBookings.length > 1 && !recurrBookingEdit ? 
                            'Are you sure you want to update this recurring booking details this will delete existing reoccurred bookings and create a new set, you cannot revert this action.'
                            : 'Are you sure you want to update this booking details, you cannot revert this action.'}
                          onOk={() => bookEvent()}
                        >
                          <Button
                            type='button'
                            level='primary'
                            disabled={booking}>
                            Update Booking
                          </Button>
                        </ConfirmDialog>
                      )}
                    </div>}
                    {values && values['venue_remarks'] && hasBooking && <div>
                      <h6 className={`mt-5`}>Building room and time has been booked with event ID: {values && values['venue_remarks'] && values['venue_remarks']}</h6>
                    </div>}
                  </>
                )}
              </div>
            )}
        </section>

        {['igm', 'agm'].indexOf(specialEvent) === -1 && (
          <section>
            <div className={`grid grid-50 grid-responsive `}>
              <SelectField
                id='overnight_event'
                placeholder={'Select '}
                value={values['overnight_event']}
                label={'Is this an overnight event?'}
                data={decisionArray}
                handleChange={onHandleChange}
              />
              <SelectField
                id='food_available'
                placeholder={'Select '}
                value={values['food_available']}
                label={'Will food be available at this event?'}
                data={decisionArray}
                handleChange={onHandleChange}
              />
            </div>
            <div className={`grid grid-50 grid-responsive `}>
              <SelectField
                id='alcohol_available'
                placeholder={'Select '}
                value={values['alcohol_available']}
                label={'Will alcohol be served at this event?'}
                data={decisionArray}
                handleChange={onHandleChange}
              />
              <SelectField
                id='event_funding'
                placeholder={'Select '}
                value={values['event_funding']}
                label={
                  'Will the club be applying for C&S Event Funding for this event?'
                }
                data={decisionArray}
                handleChange={onHandleChange}
              />
            </div>
            {(values.alcohol_available && values.alcohol_available === 'yes') || 
            (values.overnight_event &&
              values.overnight_event === 'yes') ||
            (values.overnight_event &&
              values.overnight_event === 'yes' &&
              values.food_available &&
              values.food_available === 'yes') ? (
              <div className={`grid grid-50 grid-responsive `}>
                <div>
                  <p className={styles.dropZoneLabel}>
                    {riskAssessment ? (
                      <>{riskAssessment.name}</>
                    ) : (
                      <>Risk assessment form</>
                    )}
                  </p>

                  <p className={styles.dropZoneNotice}>
                    Must be PDF or DOC and cannot exceed 1MB.
                    <br />
                    <a
                      target={'_blank'}
                      href={
                        'https://usu.wpengine.com/wp-content/uploads/2023/09/RAF-Template.docx'
                      }
                      rel='noreferrer'>
                      <u>Download form</u>
                    </a>
                    <br />
                    <span>For additional support at events, <a href="https://contactusu.freshdesk.com/en/support/solutions/articles/51000361856-how-club-executives-respond-to-issues-at-events" target="_blank" rel='noreferrer noopener'><u>read this factsheet</u></a></span>
                  </p>
                </div>
                <div className={styles.selectContainer}>
                  <input
                    multiple={false}
                    type='file'
                    ref={alcoholRef}
                    accept='.pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    onChange={e =>
                      e.target.files.length > 0
                        ? setRiskAssessment(e.target.files[0])
                        : undefined
                    }
                  />
                  <Button
                    onClick={() => alcoholRef?.current?.click()}
                    type={'button'}
                    level='primary'
                    className={styles.selectButton}>
                    Select file
                  </Button>
                </div>
                {eventData && eventData.acf.risk_assessment_form && (
                  <div>
                    <p className={styles.dropZoneLabel}>Current attachment:</p>
                    <a
                      target={'_blank'}
                      className={styles.dropZoneNotice}
                      href={eventData.acf.risk_assessment_form.url}
                      rel='noreferrer'>
                      <u>{eventData.acf.risk_assessment_form.filename}</u>
                    </a>
                  </div>
                )}
              </div>
            ) : null}
          </section>
        )}

        {['igm', 'agm'].indexOf(specialEvent) > -1 && (
          <section>
            <p>
              Define the roles that will be required to be filled in the{' '}
              {specialEvent.toUpperCase()} vote. There are required positions
              that have been preset for you. You are free to add any additional
              as required.
            </p>
            <p>
              At the {specialEvent.toUpperCase()}, attendees will be able to log
              into their accounts and vote in other attendees to these
              positions.
            </p>
            {roles && (
              <div className={styles.roles}>
                {roles.map((role, roleIndex) => {
                  const disabled =
                    typeof requiredRoles.find(r => role.role === r.role) !==
                    'undefined';
                  return (
                    <div key={roleIndex} className={styles.role}>
                      <div className={styles.name}>
                        <FormInputField
                          labelName='Role:'
                          id='role'
                          value={role.role}
                          customHandleChange={e =>
                            updateRoles(e.target.value, 'role', roleIndex)
                          }
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.amount}>
                        <FormInputField
                          labelName='Amount:'
                          id='amount'
                          value={role.amount}
                          customHandleChange={e =>
                            updateRoles(
                              Number(e.target.value),
                              'amount',
                              roleIndex
                            )
                          }
                          disabled={disabled}
                        />
                      </div>
                      {!disabled && (
                        <div
                          role='presentation'
                          className={styles.close}
                          onClick={() => removeRole(roleIndex)}>
                          <Icon symbol='close' />
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className='buttonGroup stretch'>
                  <Button
                    level='secondary'
                    type='button'
                    disabled={disableActions}
                    onClick={() => addRole()}>
                    Add new role
                  </Button>
                </div>
              </div>
            )}
          </section>
        )}

        {/* {['igm', 'agm'].indexOf(specialEvent) > -1 && ( */}
          <section class="p-0">
            {/* Create  */}
            <div className='formField'>
              <Checkbox
                id="send_members_email"
                value={values['send_members_email']}
                label="Send automated emails to members"
                action={() => onHandleChange('send_members_email', !values['send_members_email'])}
                isChecked={values['send_members_email']}
              />
            </div>
            <div className={`formField ${!values['send_members_email'] ? 'hidden' : ''}`}>
              <label htmlFor='emailSubject'>
                Email subject to club members{' '}
                <span className='floatRight'>
                  {charCount()} characters remaining
                </span>
              </label>
              <input
                type='text'
                id='emailSubject'
                defaultValue={emailInitial.subject}
                onChange={e => updateValue('subject', e.target.value)}
              />
            </div>
            <div className={`formField ${!values['send_members_email'] ? 'hidden' : ''}`}>
              <label htmlFor='emailBody'>Email body to club members</label>
              {emailInitial.body !== '' && (
                <RichTextField
                  defaultValue={emailInitial.body}
                  returnValue={returnBody}
                />
              )}
            </div>
            <div className={`buttonGroup stretch ${!values['send_members_email'] ? 'hidden' : ''}`}>
              <div>
                <Button
                  level='secondary'
                  type='button'
                  disabled={disableActions}
                  onClick={() => sendTest()}>
                  {testingLabel}
                </Button>
                <p style={{ fontSize: '14px', lineHeight: '18px' }}>A test of this email will be sent to {Email}</p>
              </div>
              <div style={{ fontSize: '14px', lineHeight: '18px' }}>
                <h6>Dynamic variables:</h6>
                <div>##MEMBERNAME##</div>
                <div>##EVENTNAME##</div>
                <div>##EVENTDATE##</div>
                <div>##EVENTLINK##</div>
              </div>
            </div>
          </section>
        {/* )} */}

        {!isStaff && (
          <p>
            <a
              href={specialEvent ? '/article/annual-general-meetings' : '/article/pre-event-forms'}
              target="_blank"
              rel="noreferrer noopener"
              role={'button'}
            >For more information check the FAQ</a>{' '}
            {/* For more information please fill in the{' '}
            <a
              href='/contact'
              target="_blank"
              rel="noreferrer noopener"
              role={'button'}
            >
              <u>contact us form</u>{' '}
            </a>{' '} */}
          </p>
        )}

        {/* {isStaff && ( */}
          <section>
            <div className={`grid grid-50 grid-responsive grid-start`}>
              <FormInputField
                id='registration_override'
                type='input'
                labelName='Override registration location'
                placeholder='URL to 3rd party'
                value={values['registration_override']}
                handleChange={onHandleChange}
                note="NOTE: Populating this field will disconnect standard site functionality and prevent tracking event registrations"
              />
              <div style={{alignSelf: 'flex-start'}}>
                <FormInputField
                  id='cta_label'
                  type='input'
                  labelName='Override Button label'
                  placeholder='Default: Register'
                  value={values['cta_label']}
                  handleChange={onHandleChange}
                  note="NOTE: This is the text on the button that members will click to register for the event"
                />
              </div>
            </div>
          </section>
        {/* )} */}

        <section>
          <div className={`grid grid-50 grid-responsive`}>
            <SelectField
              id='display_to'
              value={values['display_to'] || 'public'}
              label={'Where should this display?'}
              data={[
                { text: 'Listed for all to see', value: 'public' },
                { text: 'Only to members of the club', value: 'members' },
                { text: 'Hidden (must have the URL)', value: 'none' },
              ]}
              handleChange={onHandleChange}
            />
          </div>
        </section>

        <section>
          {eventData ||
          (values.venue_location === 'usu_managed_venues') ||
          (values.venue_location &&
            values.venue_location !== 'usu_managed_venues') ? (
            <div className={styles.buttonGroup}>
              {!isStaff && (
                <Button
                  type={'button'}
                  level='primary'
                  disabled={saving}
                  onClick={eventFormSubmitHandler}
                  className={styles.submitButton}>
                  {saving ? 'Please wait...' : (eventData
                    ? 'Save details'
                    : specialEvent
                    ? `Setup ${specialEvent.toUpperCase()}`
                    : 'Submit application')}
                </Button>
              )}

              {((eventData && isStaff) || isStaff) && (
                <>
                  <Button
                    type={'button'}
                    level='primary'
                    disabled={saving}
                    onClick={() => eventHandler(true)}
                    className={styles.submitButton}>
                    Approve
                  </Button>
                  <Button
                    type={'button'}
                    level='primary'
                    disabled={saving}
                    onClick={() => eventHandler(false)}
                    className={styles.submitButton}>
                    Reject
                  </Button>
                </>
              )}
              
              {eventData && (
                <Button
                  type={'button'}
                  level='secondary'
                  disabled={saving}
                  onClick={() => disregard()}
                  className={styles.submitButton}>
                  Cancel
                </Button>
              )}
            </div>
          ) : null}
        </section>
      </div>
    </Container>
  );
};

export default EventSubmissionForm;

