// extracted by mini-css-extract-plugin
export var customFontSize = "ContentfulBlockCopy-module--customFontSize--632e7";
export var orttoFormContainer = "ContentfulBlockCopy-module--orttoFormContainer--2b101";
export var padded = "ContentfulBlockCopy-module--padded--7b6e9";
export var paddingLarge = "ContentfulBlockCopy-module--paddingLarge--d337a";
export var paddingMedium = "ContentfulBlockCopy-module--paddingMedium--4c2af";
export var paddingSmall = "ContentfulBlockCopy-module--paddingSmall--16965";
export var pageCopy = "ContentfulBlockCopy-module--pageCopy--00d5d";
export var separatorBottomonly = "ContentfulBlockCopy-module--separatorBottomonly--beeec";
export var separatorTopandBottom = "ContentfulBlockCopy-module--separatorTopandBottom--24760";
export var separatorToponly = "ContentfulBlockCopy-module--separatorToponly--39497";
export var textContainer = "ContentfulBlockCopy-module--textContainer--f00b8";
export var textSizeLarge = "ContentfulBlockCopy-module--textSizeLarge--fc4a8";
export var textSizeStandard = "ContentfulBlockCopy-module--textSizeStandard--727df";