import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import CartContext from '../../../context/CartProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Button from '../../atoms/Button/Button';
import * as styles from './CartSummary.module.css';
import Dialog from "../../atoms/Dialog/Dialog"

const CouponForm = () => {
  const {
    state: {
      coupons,
      cart: { currency },
      cartLoading,
    },
    addCoupons,
    removeCoupons,
    initCheckout
  } = useContext(CartContext);

  const [initLoad, setInitLoad] = useState(true);
  const [applying, setApply] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [assignedCoupon, setAssignedCoupon] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [checkCartLoading, setCheckCartLoading] = useState(false);

  const onClickToggle = () => {
    setShowForm(!showForm);
  };

  const onChangeValue = e => {
    const { value } = e.target;
    setCouponCode(value);
  };

  const onSubmitForm = () => {
    setApply(true);
    addCoupons(couponCode).then(response => {
      setTimeout(() => {
        setAssignedCoupon(response);
        initCheckout();
        setApply(false);
      }, 400);
    }).catch(response => {
      // console.log("Error: ", response);
      setApply(false);
      setDialogMessage(response.title);
    });
  };

  const handleRemoveCoupon = code => () => {
    setApply(true);
    removeCoupons(code).then(response => {
      setTimeout(() => {
        setAssignedCoupon([]);
        initCheckout();
        setApply(false);
      }, 400)
    });
  };

  const clearDialog = () => {
    setDialogMessage(false);
  }

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
      setAssignedCoupon(coupons);
    }
  }, [initLoad, coupons, assignedCoupon]);

  // Coupon changes
  useEffect(() => {
    if (cartLoading) {
      setCheckCartLoading(true);
    }
  }, [cartLoading]);

  useEffect(() => {
    if (checkCartLoading && !cartLoading) {
      setAssignedCoupon(coupons);
      setCheckCartLoading(false);
    }
  }, [checkCartLoading, cartLoading, coupons]);

  return (
    <>
      {isEmpty(assignedCoupon) ? (
        <div className="flex-between">
          <span className={styles.bcCartSummaryItemLabel}>Coupon Code</span>
          <span
            data-button
            role="presentation"
            onClick={onClickToggle}
            className={styles.ctaValue}
          >
            {showForm ? 'Cancel' : 'Add Code'}
          </span>
        </div>
      ) : (
        assignedCoupon.map(coupon => (
          <div className="flex-between" key={coupon.code}>
            <div className={styles.bcCartSummaryItemLabel}>
              <div>{coupon.name || `Coupon (${coupon.code})`}</div>
              <span
                data-button
                role="presentation"
                onClick={handleRemoveCoupon(coupon.code)}
                className={styles.bcCartSummaryToggle}
              >
                {applying ? 'Removing...' : 'Remove'}
              </span>
            </div>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                currency={currency.code}
                amount={coupon.discounted_amount}
                ignoreTax={true}
              />
            </span>
          </div>
        ))
      )}
      {isEmpty(assignedCoupon) && showForm && (
        <div className={styles.couponFormContainer}>
          <div className="formField mb-0">
            <input
              type="text"
              name="couponCode"
              placeholder="Enter your coupon code"
              value={couponCode || ''}
              onChange={onChangeValue}
            />
          </div>
          <Button
            type="button"
            level="primary"
            fullWidth
            disabled={applying}
            onClick={onSubmitForm}
          >
            {applying ? 'Applying...' : 'Apply'}
          </Button>
        </div>
      )}
      <Dialog open={dialogMessage ? true : false} title="Applying coupon" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
    </>
  );
};

export default CouponForm;
