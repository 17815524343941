import React from 'react';

// Alphabetical order please

import Add from './add';
import AddReview from './addReview';
import AfterPayArrows from './afterpay-arrows';
import AfterPayLogo from './afterpay-logo';
import AfterPayLogoAlt from './afterpay-logo-alt';
import AfterPay from './afterpay';
import Album from './album';
import Alert from './alert';
import Amex from './amex';
import AppStore from './appStore';
import Arrow from './arrow';
import ArrowDown from './arrowDown';
import ArrowDownAlt from './arrowDownAlt';
import ArrowLeft from './arrowLeft';
import ArrowLink from './arrowLink';
import ArrowRight from './arrowRight';
import ArrowWithTail from './arrowWithTail';
import Bag from './bag';
import BagAlt from './bagAlt';
import BagPlus from './bagPlus';
import Bin from './bin';
import BinAlt from './binAlt';
import Burger from './burger';
import Calendar from './calendar';
import Caret from './caret';
import CaretDown from './caretDown';
import Cart from './cart';
import CartAdd from './cartAdd';
import Check from './check';
import Chevron from './chevron';
import ChevronDown from './chevronDown';
import CheckAlt from './checkAlt';
import CheckFilled from './checkFilled';
import CheckOutline from './checkOutline';
import Click from './click';
import Close from './close';
import Clock from './clock';
import CreditCard from './creditCard';
import Cross from './cross';
import Currency from './currency';
import Cycle from './cycle';
import Discord from './discord';
import Delivery from './delivery';
import Edit from './edit';
import Equaliser from './equaliser';
import Export from './export';
import Facebook from './facebook';
import FacebookAlt from './facebookAlt';
import FacebookInverse from './facebookInverse';
import FileDoc from './fileDoc';
import FileImg from './fileImg';
import FileMp3 from './fileMp3';
import FileMp4 from './fileMp4';
import FileOther from './fileOther';
import FilePdf from './filePdf';
import FilePpt from './filePpt';
import FileXls from './fileXls';
import FileZip from './fileZip';
import Filter from './filter';
import FilterAlt from './filterAlt';
import Folder from './folder';
import FolderUp from './folderUp';
import GiftBox from './gift-box';
import Hamburger from './hamburger';
import Heart from './heart';
import HeartAlt from './heartAlt';
import HeartFill from './heartFill';
import Headset from './headset';
import HelpCircle from './help-circle';
import House from './house';
import InfoCircle from './info-circle';
import InfoSolid from './info-solid';
import Instagram from './instagram';
import InstagramAlt from './instagramAlt';
import Klarna from './klarna';
import KlarnaMin from './klarna-min';
import Link from './link';
import Linkedin from './linkedin';
import List from './list';
import Logout from './logout';
import Paypal from './paypal';
import PaypalMin from './paypal-min';
import PaypalColor from './paypal-color';
import MapMarker from './map-marker';
import MasterCard from './master-card';
import Minus from './minus';
import MinusAlt from './minusAlt';
import PaymentAdd from './paymentAdd';
import PaymentCheckout from './paymentCheckout';
import PaymentRepay from './paymentRepay';
import Phone from './phone';
import PhoneAlt from './phoneAlt';
import Pinterest from './pinterest';
import PinterestInverse from './pinterestInverse';
import PinterestMin from './pinterestMin';
import Play from './play';
import PlayStore from './playStore';
import Plus from './plus';
import Purse from './purse';
import Question from './question';
import QuickView from './quickView';
import Search from './search';
import SearchAlt from './searchAlt';
import Star from './star';
import StarBlack from './star-black';
import StoreLocator from './store-locator';
import Swatch from './swatch';
import Tick from './tick';
import Tiktok from './tiktok';
import Translate from './translate';
import Truck from './truck';
import TruckMoving from './truckMoving';
import Twitter from './twitter';
import TwitterAlt from './twitterAlt';
import TwitterInverse from './twitterInverse';
import Upload from './upload';
import User from './user';
import UserAlt from './userAlt';
import View from './view';
import Visa from './visa';
import Website from './website';
import WhiteBag from './whiteBag';
import Youtube from './youtube';
import YoutubeAlt from './youtubeAlt';
import ZipLogo from './zip-logo';
import ZipMin from './zip-min';
import ZipMoneyLogo from './zip-money-logo';
import ZipPayLogo from './zip-pay-logo';
import ListEdit from './list-edit';
import Members from './members';
import Mail from './mail';
import Gear from './gear';
import Repeat from './repeat';

function Icon(props) {
  switch (props.symbol) {
    case 'add':
      return <Add />;
    case 'addReview':
      return <AddReview />;
    case 'afterpayArrows':
      return <AfterPayArrows />;
    case 'afterPayLogo':
      return <AfterPayLogo />;
    case 'afterPayLogoAlt':
      return <AfterPayLogoAlt />;
    case 'afterPay':
      return <AfterPay />;
    case 'album':
      return <Album />;
    case 'alert':
      return <Alert />;
    case 'amex':
      return <Amex />;
    case 'appStore':
      return <AppStore />;
    case 'arrow':
      return <Arrow />;
    case 'arrowDown':
      return <ArrowDown />;
    case 'arrowDownAlt':
      return <ArrowDownAlt />;
    case 'arrowLeft':
      return <ArrowLeft />;
    case 'arrowLink':
      return <ArrowLink />;
    case 'arrowRight':
      return <ArrowRight />;
    case 'arrowWithTail':
      return <ArrowWithTail />;
    case 'bag':
      return <Bag />;
    case 'bagAlt':
      return <BagAlt />;
    case 'bagPlus':
      return <BagPlus />;
    case 'bin':
      return <Bin />;
    case 'binAlt':
      return <BinAlt />;
    case 'burger':
      return <Burger />;
    case 'calendar':
      return <Calendar />;
    case 'caret':
      return <Caret />;
    case 'caretDown':
      return <CaretDown />;
    case 'cart':
      return <Cart />;
    case 'cartAdd':
      return <CartAdd />;
    case 'check':
      return <Check />;
    case 'chevron':
      return <Chevron />;
    case 'chevronDown':
      return <ChevronDown />;
    case 'checkAlt':
      return <CheckAlt />;
    case 'checkFilled':
      return <CheckFilled />;
    case 'checkOutline':
      return <CheckOutline />;
    case 'click':
      return <Click />;
    case 'close':
      return <Close />;
    case 'clock':
      return <Clock />;
    case 'creditcard':
      return <CreditCard />;
    case 'cross':
      return <Cross />;
    case 'currency':
      return <Currency />;
    case 'cycle':
      return <Cycle />;
    case 'delivery':
      return <Delivery />;
    case 'discord':
      return <Discord />;
    case 'edit':
      return <Edit />;
    case 'equaliser':
      return <Equaliser />;
    case 'export':
      return <Export />;
    case 'facebook':
      return <Facebook />;
    case 'facebookAlt':
      return <FacebookAlt />;
    case 'facebookInverse':
      return <FacebookInverse />;
    case 'fileDoc':
      return <FileDoc />;
    case 'fileDocx':
      return <FileDoc />;
    case 'fileImg':
      return <FileImg />;
    case 'filePng':
      return <FileImg />;
    case 'fileJpg':
      return <FileImg />;
    case 'fileJpeg':
      return <FileImg />;
    case 'fileMp3':
      return <FileMp3 />;
    case 'fileMp4':
      return <FileMp4 />;
    case 'fileOther':
      return <FileOther />;
    case 'filePdf':
      return <FilePdf />;
    case 'filePpt':
      return <FilePpt />;
    case 'fileXls':
      return <FileXls />;
    case 'fileXlsx':
      return <FileXls />;
    case 'fileCsv':
      return <FileXls />;
    case 'fileZip':
      return <FileZip />;
    case 'filter':
      return <Filter />;
    case 'filterAlt':
      return <FilterAlt />;
    case 'folder':
      return <Folder />;
    case 'folderUp':
      return <FolderUp />;
    case 'gear':
      return <Gear />;
    case 'giftbox':
      return <GiftBox />;
    case 'hamburger':
      return <Hamburger />;
    case 'heart':
      return <Heart />;
    case 'heartAlt':
      return <HeartAlt />;
    case 'heartFill':
      return <HeartFill />;
    case 'headset':
      return <Headset />;
    case 'helpCircle':
      return <HelpCircle />;
    case 'house':
      return <House />;
    case 'infoCircle':
      return <InfoCircle />;
    case 'infoSolid':
      return <InfoSolid />;
    case 'instagram':
      return <Instagram />;
    case 'instagramAlt':
      return <InstagramAlt />;
    case 'klarna':
      return <Klarna />;
    case 'klarna-min':
      return <KlarnaMin />;
    case 'link':
      return <Link />;
    case 'linkedin':
      return <Linkedin />;
    case 'list':
      return <List />;
    case 'list-edit':
      return <ListEdit />;
    case 'logout':
      return <Logout />;
    case 'mail':
      return <Mail />;
    case 'members':
      return <Members />;
    case 'paypal':
      return <Paypal />;
    case 'paypal-min':
      return <PaypalMin />;
    case 'paypal-color':
      return <PaypalColor />;
    case 'mapMarker':
      return <MapMarker />;
    case 'masterCard':
      return <MasterCard />;
    case 'minus':
      return <Minus />;
    case 'minusAlt':
      return <MinusAlt />;
    case 'paymentAdd':
      return <PaymentAdd />;
    case 'paymentCheckout':
      return <PaymentCheckout />;
    case 'paymentRepay':
      return <PaymentRepay />;
    case 'phone':
      return <Phone />;
    case 'phoneAlt':
      return <PhoneAlt />;
    case 'pinterest':
      return <Pinterest />;
    case 'pinterestInverse':
      return <PinterestInverse />;
    case 'pinterestMin':
      return <PinterestMin />;
    case 'play':
      return <Play />;
    case 'playStore':
      return <PlayStore />;
    case 'plus':
      return <Plus />;
    case 'purse':
      return <Purse />;
    case 'question':
      return <Question />;
    case 'quickView':
      return <QuickView />;
    case 'repeat':
      return <Repeat />;
    case 'search':
      return <Search />;
    case 'searchAlt':
      return <SearchAlt />;
    case 'star':
      return <Star />;
    case 'starBlack':
      return <StarBlack />;
    case 'storeLocator':
      return <StoreLocator />;
    case 'swatch':
      return <Swatch />;
    case 'tick':
      return <Tick />;
    case 'tiktok':
      return <Tiktok />;
    case 'translate':
      return <Translate />;
    case 'truck':
      return <Truck />;
    case 'truckMoving':
      return <TruckMoving />;
    case 'twitter':
      return <Twitter />;
    case 'twitterAlt':
      return <TwitterAlt />;
    case 'twitterInverse':
      return <TwitterInverse />;
    case 'upload':
      return <Upload />;
    case 'user':
      return <User />;
    case 'userAlt':
      return <UserAlt />;
    case 'view':
      return <View />;
    case 'visa':
      return <Visa />;
    case 'website':
      return <Website />;
    case 'whiteBag':
      return <WhiteBag />;
    case 'youtube':
      return <Youtube />;
    case 'youtubeAlt':
      return <YoutubeAlt />;
    case 'zipLogo':
      return <ZipLogo />;
    case 'zip-min':
      return <ZipMin />;
    case 'zipMoneyLogo':
      return <ZipMoneyLogo />;
    case 'zipPayLogo':
      return <ZipPayLogo />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;

