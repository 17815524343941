import React from 'react';

const Search = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.7059 16.2894L14.3147 12.8982C15.4099 11.5004 16.0028 9.77462 15.9978 7.99885C15.9978 6.41682 15.5286 4.87031 14.6497 3.55489C13.7708 2.23948 12.5215 1.21424 11.0599 0.608823C9.59829 0.00340478 7.98998 -0.155 6.43834 0.153639C4.8867 0.462279 3.46144 1.2241 2.34277 2.34277C1.2241 3.46144 0.462279 4.8867 0.153639 6.43834C-0.155 7.98998 0.00340478 9.59829 0.608823 11.0599C1.21424 12.5215 2.23948 13.7708 3.55489 14.6497C4.87031 15.5286 6.41682 15.9978 7.99885 15.9978C9.77462 16.0028 11.5004 15.4099 12.8982 14.3147L16.2894 17.7059C16.4781 17.8921 16.7325 17.9965 16.9976 17.9965C17.2627 17.9965 17.5172 17.8921 17.7059 17.7059C17.7991 17.613 17.873 17.5026 17.9235 17.3811C17.974 17.2595 17.9999 17.1292 17.9999 16.9976C17.9999 16.866 17.974 16.7357 17.9235 16.6142C17.873 16.4927 17.7991 16.3823 17.7059 16.2894V16.2894ZM1.99967 7.99885C1.99967 6.81232 2.35152 5.65244 3.01071 4.66588C3.66991 3.67932 4.60686 2.91039 5.70306 2.45633C6.79927 2.00227 8.00551 1.88346 9.16923 2.11494C10.333 2.34642 11.4019 2.91779 12.2409 3.75679C13.0799 4.59579 13.6513 5.66474 13.8828 6.82847C14.1142 7.9922 13.9954 9.19843 13.5414 10.2946C13.0873 11.3908 12.3184 12.3278 11.3318 12.987C10.3453 13.6462 9.18538 13.998 7.99885 13.998C6.40845 13.9958 4.88381 13.3631 3.75922 12.2385C2.63464 11.1139 2.00187 9.58925 1.99967 7.99885Z'
        fill='black'
      />
    </svg>
  );
};

export default Search;
