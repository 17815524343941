import React from 'react'

const PlayStore = () => (
    <svg
    width={20}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 20 22'
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={7}
      width={9}
      height={7}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.984 7.653H19.3v5.807h-7.315V7.653Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m18.94 9.855-3.817-2.202-3.139 3.034 2.869 2.773 4.086-2.359a.711.711 0 0 0 .36-.623.713.713 0 0 0-.36-.623Z"
        fill="currentColor"
      />
    </g>
    <mask
      id="b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={0}
      width={14}
      height={11}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.072.138h13.404v10.049H1.072V.138Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.476 7.279-4.2-2.424L1.072.138l10.395 10.049 3.008-2.908Z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1.225 21.088 9.066-4.995 3.914-2.26-2.738-2.646-10.242 9.9Z"
      fill="currentColor"
    />
    <mask
      id="c"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={11}
      height={21}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.448.545H10.95v20.294H.448V.545Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#c)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m.458.545-.01 20.294L10.95 10.687.458.545Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default PlayStore