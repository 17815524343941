import { navigate } from 'gatsby';
import { get } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Loader from '../../../../components/atoms/Loader/Loader';
import MyUsuClub from '../../../../components/atoms/MyUsu/MyUsuClub';
import AccountPageWrapper from '../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import MyUsuTab from '../../../../components/organisms/MyUsuTab/MyUsuTab';
import AuthContext from '../../../../context/AuthProvider';
import { getStorage, setStorage, persistLocation } from '../../../../helpers/general';
import { getPerson, updatePerson } from '../../../../helpers/ortto';
import { wpApiNew, wpAllNew } from '../../../../helpers/wordpress';
import * as styles from '../usu.module.css';

const Clubs = ({ location }) => {
  const auth = useContext(AuthContext);
  const userEmail = auth && auth.state.email;
  const [clubList, setClubList] = useState(null);
  const [usuNumber, setUsuNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [userTags, setTags] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (userEmail) {
      const _usuNumber = auth.state.object.form_fields.find(
        field => field.name === 'usunumber'
      );
      if (_usuNumber) setUsuNumber(_usuNumber.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const eventClickHandler = (clubSlug, clubId, clubTitle, clubStatus) => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: {
        clubSlug,
        clubId,
        clubTitle,
        clubStatus,
      },
    });
  };
  const fetchClubList = useCallback(
    async _currentPage => {
      setLoading(true);
      const result = await getPerson(['Tags'], userEmail);
      if (
        result.status === 200 &&
        'contacts' in result.response &&
        Array.isArray(result.response.contacts)
      ) {
        // Successfully found
        if (get(result.response.contacts[0], 'fields.tags', false)) {
          setTags(result.response.contacts[0].fields.tags);
          const clubSlugs = result.response.contacts[0].fields.tags
            .filter(
              tag => tag.indexOf('club-') === 0 || tag.indexOf('clubro-') === 0
            )
            .map(tag => tag.replace('club-', '').replace('clubro-', ''));
          if (usuNumber === '2303541' || usuNumber === '2351174') {
            // Ash D's member number
            clubSlugs.push(
              ...[
                'musical-society',
                'science-revue-society',
                'linguistics-society',
                'cs-office-test-club',
                'chopsticks-accas',
                'mechanical-undergraduates',
                'mechatronics-organisation',
                'arts-crafts',
                'university-of-sydney-puzzle-society',
                'civil-engineers',
                'businessone'
              ]
            );
          }
          if (clubSlugs.length > 0) {
            const clubs = await wpAllNew('getClubsList', {
              callId: 0,
              fieldSet: 0,
              supportData: [clubSlugs.join(',')]
            });
            if (
              String(clubs.status).startsWith('2') &&
              clubs.response.length > 0
            ) {
              // for (const pair of clubs.headers.entries()) {
              //     if (pair[0] === 'x-wp-totalpages') {
              //         setTotalPages(pair[1]);
              //     }
              // }
              // const totalPages = getHeader(clubs.headers, 'x-wp-totalpages');
              // if (totalPages) {
              //   setTotalPages(totalPages);
              // }
              //Check if there's a booking Id from Ivvy and redirect back to event submission
              if (location.search) {
                const prevClub = getStorage('event_club');
                let clubObj = prevClub ? JSON.parse(prevClub) : null;
                if (clubObj) {
                  navigate(
                    `/account/my-usu/clubs/manage/event-submission${location.search}`,
                    {
                      state: clubObj,
                    }
                  );
                }
              }

              const adminList = [];
              const list = clubs.response.map(
                club =>
                  new Promise(res => {
                    const memberLevel = result.response.contacts[0].fields.tags.find(
                      tag => tag.indexOf(`clubLevel-${club.slug}-`) > -1
                    );
                    const isExec = club.acf.isExec;
                      // (club.acf.club_execs &&
                      //   club.acf.club_execs.find(
                      //     member =>
                      //       member.member_number === usuNumber ||
                      //       usuNumber === '2351174'
                      //   )) ||
                      // false;
                    if (isExec && club.status === 'publish') {
                      adminList.push({
                        clubSlug: club.slug,
                        clubId: club.id,
                        clubTitle: club.title.rendered,
                        clubStatus: club.status,
                      });
                    }
                    if (
                      (club.status === 'ign' || club.acf.agm_functionality) &&
                      club.acf.current_igmagm_history_record
                    ) {
                      wpApiNew('getClubHistoryData', {
                        clubId: club.id,
                        historyId: club.acf.current_igmagm_history_record.ID,
                      }).then(record => {
                        if (
                          String(record.status).startsWith('2') &&
                          record.response
                        ) {
                          club.eventId = record.response.acf.event.ID;

                          wpApiNew('getEventData', {
                            eventId: record.response.acf.event.ID,
                            fieldSet: 3
                          }).then(
                            event => {
                              club.nominationsOpen =
                                String(event.status).startsWith('2') &&
                                event.response.status === 'publish';

                              wpApiNew('checkEventRegistration', {
                                eventId: record.response.acf.event.ID
                              }).then(checkIn => {
                                if (
                                  String(checkIn.status).startsWith('2') &&
                                  Array.isArray(checkIn.response)
                                ) {
                                  club.memberCheckedIn =
                                    checkIn.response.length > 0;
                                  if (club.memberCheckedIn) {
                                    club.memberVoted = false;
                                    club.nominationsOpen = false;

                                    wpApiNew('checkGmVote', {
                                      eventId: event.response.id
                                    }).then(votes => {
                                      if (
                                        String(votes.status).startsWith('2') &&
                                        votes.response.length > 0
                                      ) {
                                        club.memberVoted = true;
                                      }

                                      res(club);
                                    });
                                  } else {
                                    res(club);
                                  }
                                } else {
                                  res(club);
                                }
                              });
                            }
                          );
                        } else {
                          res(club);
                        }
                      });
                    } else if (!memberLevel && club.status === 'publish') {
                      // Fallback for Ortto in the event that clubLevel tag isn't set
                      const personData = [
                        {
                          email: userEmail,
                          tags: [`clubLevel-${club.slug}-Standard`],
                        },
                      ];
                      updatePerson(personData).then(() => {
                        res(club);
                      });
                    } else {
                      res(club);
                    }
                  })
              );

              Promise.all(list).then(arr => {
                setClubList(arr);
                // USU Admin Club Accounts to power hotswapping
                setStorage('_uaca', JSON.stringify(adminList), true);
              });
            } else {
              // Failed fetch of club data
              setClubList(false);
            }
          } else {
            // no club tags found
            setClubList(false);
          }
        } else {
          // No tags found
          setClubList(false);
        }
      } else {
        // Failed fetch of tags
        setClubList(false);
      }
      setLoading(false);
    },
    [userEmail, usuNumber, location.search]
  );

  useMemo(() => {
    if (clubList === null && usuNumber !== '') {
      fetchClubList(1);
    }
  }, [clubList, usuNumber, fetchClubList]);

  const leaveClub = async (slug, level) => {
    // setLoading(true);
    const clubKey = `club-${slug}`;
    const clubLevelKey = `clubLevel-${slug}-${level}`;
    const userKey = userEmail;

    const person = [
      {
        email: userKey,
        unset_tags: [clubKey, clubLevelKey],
      },
    ];
    const updateResponse = await updatePerson(person);

    if (updateResponse.status === 200 && 'people' in updateResponse.response) {
      // Successfully added
      fetchClubList(1);
      // setLoading(false);
    } else {
      // Error occurred
      // TODO: Show error somewhere
    }
  };

  // const changePage = async page => {
  //   if (page !== currentPage) {
  //     setCurrentPage(page);
  //     fetchClubList(page);
  //   }
  // };

  // const outputPaging = () => {
  //   const output = [];
  //   for (let p = 1; p <= totalPages; p++) {
  //     output.push(
  //       <div
  //         role='presentation'
  //         className={p === currentPage ? styles.current : undefined}
  //         onClick={() => changePage(p)}>
  //         {p}
  //       </div>
  //     );
  //   }

  //   return output;
  // };

  return (
    <div className={styles.root} style={{ opacity: loading ? '0.5' : '1' }}>
      {/* List clubs */}
      <p>Here is the list of all clubs you have joined:</p>
      {clubList && Array.isArray(clubList) ? (
        <div>
          {clubList.map((club, clubIndex) => {
            return (
              <MyUsuClub
                hasLink
                eventClick={eventClickHandler}
                club={club}
                key={clubIndex}
                onLeave={leaveClub}
                usuNumber={usuNumber}
                level={
                  userTags
                    .find(t => t.indexOf(`clubLevel-${club.slug}-`) === 0)
                    ?.replace(`clubLevel-${club.slug}-`, '') || 'Standard'
                }
              />
            );
          })}

          {/* {totalPages > 1 && (
            <div className={styles.paging}>{outputPaging()}</div>
          )} */}
        </div>
      ) : (
        <div>
          {/* Fetching clubs */}
          {clubList === null && <div>Fetching your clubs</div>}
          {/* No clubs found */}
          {clubList === false && (
            // https://matterdesign.atlassian.net/browse/USU-496
            <div>
              You have not joined any{' '}
              <a target='_blank' rel='noopener noreferrer' href='/clubs'>
                <u>clubs</u>
              </a>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
};

const ClubsOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - My USU'
      breadcrumbTitle={'Clubs'}
      title='Welcome to my USU Portal'>
      <MyUsuTab pageId={'clubs'}>
        <Clubs location={persistedLocation} />
      </MyUsuTab>
    </AccountPageWrapper>
  )
};

export default ClubsOutput;

