// extracted by mini-css-extract-plugin
export var contentContainer = "Layout1-module--contentContainer--85179";
export var headerContainer = "Layout1-module--headerContainer--4811c";
export var imagesContainer = "Layout1-module--imagesContainer--75815";
export var landscapePictureContainer = "Layout1-module--landscapePictureContainer--63d22";
export var leftContainer = "Layout1-module--leftContainer--704c2";
export var portraitPictureContainer = "Layout1-module--portraitPictureContainer--a5bf0";
export var rightContainer = "Layout1-module--rightContainer--2a93c";
export var root = "Layout1-module--root--ade01";
export var showOnMobile = "Layout1-module--showOnMobile--a50d1";
export var smallPictureContainer = "Layout1-module--smallPictureContainer--62657";