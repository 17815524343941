import React from 'react';

import Swatch from '../../atoms/Swatch/Swatch';
import * as styles from './SwatchList.module.css';

const SwatchList = (props) => {

  const {label, swatchList, handleVariantChange, variantSpecs, id} = props;
  const variant = variantSpecs?.filter((tempVariant) => tempVariant.option_id === id)[0];

  let newSwatchList = []
  if(swatchList.length > 12) newSwatchList = swatchList.slice(0, 12)

  return (
    <div className={styles.root}>
      <span className={styles.label}>Select {label}: <strong>{variant?.label}</strong></span>
      <div className={styles.swatchSelection}>
        {newSwatchList?.map((colorChoice, index) => {          
          return(<Swatch
            key={index} 
            data={colorChoice} 
            handleVariantChange={(tempVariant) => handleVariantChange(tempVariant)}
            isActive={variant?.id === colorChoice?.id}
          />) 
        })}
      </div>
    </div>
  );
};

export default SwatchList;
