import React, { useState } from 'react';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './Accordion.module.css';

const Accordion = (props) => {
  const { 
    title, 
    type='caret', 
    customStyle, 
    children = false, 
    emptyMessage = 'No Content', 
    maxHeight = false,
    value = false,
  } = props;
  
  const [open, setOpen] = useState(value);
  const combinedStyling = {...styles, ...customStyle}

  const icon = type === 'caret' ? <Icon symbol={'arrowDown'}></Icon> : <Icon symbol={`${open === true ? 'minusAlt': 'plus'}`}></Icon>;

  return (
    <div className={combinedStyling.accordionRoot}>
      <div 
        className={`${combinedStyling.accordionHeader}`} 
        role={'presentation'} onClick={()=>setOpen(!open)}>
        <span className={`${combinedStyling.accordionTitle}`}>
          {title}
        </span>
        <div className={`${combinedStyling.iconContainer} ${open === false ? styles.rotate : ''}`}>
          {icon}
        </div>
      </div>
      <div
        style={{maxHeight: open === true && maxHeight !== false ? maxHeight : ''}} 
        className={`${styles.accordionContent} ${open === true ? styles.show : styles.hide}`}>
        {children !== false && children}
        {children === false && <span className={styles.emptyMessage}>{emptyMessage}</span>}
      </div>
    </div>
  );
};

export default Accordion;
