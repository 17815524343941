import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import CartContext from '../../../context/CartProvider';
import Button from '../../atoms/Button/Button';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import CouponForm from './CouponForm';
// import ShippingInfo from './ShippingInfo';
// import GiftCertificate from './GiftCertificate';
// import FastCheckout from '../../atoms/FastCheckout/FastCheckout';
import * as styles from './CartSummary.module.css';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';

const CartSummary = ({ cartType, onClick, nextStepValid, cartStep, cartFunc, editCartFunc }) => {
  const ctx = useContext(CartContext);
  const cart = get(ctx, 'state.cart', {});
  const checkout = get(ctx, 'state.checkout', {});
  const isValidCartItemsGradHire = ctx && ctx.isValidCartItemsGradHire;
  const { currency, cartAmount, numberItems } = cart;
  const checkoutAmount = (checkout && 'subtotal_inc_tax' in checkout && typeof checkout.subtotal_inc_tax === "number") ? checkout.subtotal_inc_tax : cartAmount;
  const taxTotal = (checkout && 'tax_total' in checkout && typeof checkout.tax_total === 'number') ? checkout.tax_total : false; // GST value not provided in cart
  const grandTotal = (checkout && 'grand_total' in checkout && typeof checkout.grand_total === "number") ? checkout.grand_total : cartAmount;
  const shippingTotal = (checkout && 'shipping_cost_total_inc_tax' in checkout && typeof checkout.shipping_cost_total_inc_tax === 'number') ? checkout.shipping_cost_total_inc_tax : 0;

  const [tax, setTax] = useState();

  // console.log(cart, checkout);

  useEffect(() => {
    // hard coded skus; 
    // to refactor: BC configuration of product checking via is_taxable field 
    const nonTaxableSku = ['20183', '13610'];
    if(checkout && 'tax_total' in checkout) {

      // check for nontaxable in digitalItems;
      const digitalItems = checkout?.cart?.line_items?.digital_items || cart?.lineItems?.digital_items || [];

      // compute for tax reduction
      const totalNonTaxableAmount = digitalItems
        .filter(item => nonTaxableSku.includes(item.sku))
        .map((item) => item.original_price)
        .reduce((partialSum, a) => partialSum + a, 0);    
      setTax((cartAmount - totalNonTaxableAmount) / 11);
    }
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [checkout]);

  // disables shipping view
  const hideShippingCost = () => {
    const physicalItems =  checkout?.cart?.line_items?.physical_items || cart?.lineItems?.physical_items;
    if(physicalItems.length !== 0) return false;
    return true;
  }

  const renderPreviewCartItems = () => {
    const physicalItems =  checkout?.cart?.line_items?.physical_items || cart?.lineItems?.physical_items;
    const digitalItems = checkout?.cart?.line_items?.digital_items || cart?.lineItems?.digital_items;

    let aggregatedItems = [];
    if(physicalItems) aggregatedItems.push(...physicalItems);
    if(digitalItems) aggregatedItems.push(...digitalItems);
    
    return(<div className={styles.previewCartItemsContainer}>
      {aggregatedItems.map((item) => {
        return(<div key={item.id} className={styles.previewCartItem}>
          <div className={styles.imageContainer}>
            <img alt={item.name} src={item.image_url}></img>
          </div>
          <div className={styles.itemMetaContainer}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.qty}>Qty: {item.quantity}</span>
          </div>
          <div className={styles.itemPreviewSubtotal}>
          {item.sale_price !== item.original_price
            
            && 
            <span className={styles.originalPrice}>
            <CurrencyFormatter 
            appendZero={true} 
            currency={currency.code} 
            amount={item.original_price * item.quantity} />
            </span>
            }

            <span>
              <CurrencyFormatter 
                appendZero={true} 
                currency={currency.code} 
                amount={item.sale_price * item.quantity} />
            </span>
      
          </div>
        </div>)
      })}
    </div>)
  }

  if (!numberItems) {
    return null;
  }

  if (cartType === 'full') {
    return (
      <div className={styles.bcCartSummary}>
        <div className={styles.summaryHeader}>
          <p className={styles.bcCartSummaryTitle}>Order summary</p>
          {!cartStep && <span role={'presentation'} onClick={editCartFunc}>Edit Cart</span>}
        </div>
        {!cartStep && renderPreviewCartItems()}
        <div className={styles.bcCartSummaryItemsContainer}>
          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}>Subtotal</span>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter appendZero={true} currency={currency.code} amount={checkoutAmount} />
            </span>
          </div>

          {hideShippingCost() || 
          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            {/* removing add info - usu 386 */}
            {/* <ShippingInfo checkout={checkout} /> */}
            <span className={styles.bcCartSummaryItemLabel}>Shipping</span>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                appendZero={true}
                currency={currency.code}
                amount={shippingTotal} 
              />
            </span>
          </div>}

          { tax === undefined || tax === 0 ||
          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}>GST</span>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                appendZero={true}
                currency={currency.code}
                amount={taxTotal || tax} 
              />
            </span>
          </div>
          }

          {/* Coupon Codes */}
          <div className={styles.bcCartSummaryItem}>
            <CouponForm />
          </div>

          {/* Gift Certificate */}
          {/* <div className={styles.bcCartSummaryItem}>
            <GiftCertificate />
          </div> */}
        </div>

        <div className={clsx('flex-between', styles.bcCartSummaryItemTotal)}>
          <span className={styles.bcCartSummaryItemTotalLabel}>
            Grand Total
          </span>
          <span className={styles.bcCartSummaryItemTotalLabel}>
            <CurrencyFormatter appendZero={true} currency={currency.code} amount={grandTotal} />
          </span>
        </div>
        
        {!isValidCartItemsGradHire() && (
          <div className={clsx('flex-start', styles.bcCartCheckoutButton)}>
            <MessageAlert type="warn">
              Apologies but some products in your cart cannot be checkout.
            </MessageAlert>
          </div>
        )}

        <div className={clsx('flex-end', styles.bcCartCheckoutButton)}>
          {cartStep && (
            <Button fullWidth level="primary" type="button" disabled={!nextStepValid ? !nextStepValid : (!isValidCartItemsGradHire() ? true : false)} onClick={() => cartFunc()}>
              Checkout
            </Button>
          )}
          {/* {!cartStep && (
            <Button fullWidth level="primary" href="/checkout">
              Checkout
            </Button>
          )} */}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.bcMiniCartSummary}>
      <div data-mini-subtotal className="flex-between">
        <span>Subtotal</span>
        <span>
          <CurrencyFormatter currency={currency.code} amount={cartAmount} />
        </span>
      </div>
      <div className={styles.buttonWrapp}>
        <Button level="primary" href="/cart" onClick={onClick}>
          <span>View Cart</span>
        </Button>
        {/* <FastCheckout cart_id={cart.cartId} /> */}
        <Button level="secondary" href="/checkout">
          Checkout
      </Button>
      </div>
    </div>
  );
};

export default CartSummary;
