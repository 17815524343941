import React from 'react'

const Calendar = () => (
  <svg width={22} height={25} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 22 25'>
    <title>{"Group 6"}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11 14.104c-.346 0-.637-.117-.87-.351a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.87.234-.235.525-.352.871-.352s.637.117.87.351c.235.234.352.525.352.87 0 .347-.117.638-.351.872a1.183 1.183 0 0 1-.871.351Zm-4.889 0c-.346 0-.636-.117-.87-.351a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.87.235-.235.525-.352.871-.352s.637.117.87.351c.235.234.352.525.352.87 0 .347-.117.638-.351.872a1.183 1.183 0 0 1-.87.351Zm9.778 0c-.346 0-.637-.117-.87-.351a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.87.234-.235.525-.352.87-.352.347 0 .637.117.872.351.234.234.351.525.351.87 0 .347-.117.638-.351.872a1.183 1.183 0 0 1-.871.351ZM11 18.994c-.346 0-.637-.118-.87-.352a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.871.234-.234.525-.351.871-.351s.637.117.87.351c.235.234.352.525.352.87 0 .347-.117.637-.351.872a1.183 1.183 0 0 1-.871.351Zm-4.889 0c-.346 0-.636-.118-.87-.352a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.871.235-.234.525-.351.871-.351s.637.117.87.351c.235.234.352.525.352.87 0 .347-.117.637-.351.872a1.183 1.183 0 0 1-.87.351Zm9.778 0c-.346 0-.637-.118-.87-.352a1.183 1.183 0 0 1-.352-.871c0-.346.117-.637.351-.871.234-.234.525-.351.87-.351.347 0 .637.117.872.351.234.234.351.525.351.87 0 .347-.117.637-.351.872a1.183 1.183 0 0 1-.871.351Z"
        fill="#000"
        fillRule="nonzero"
      />
      <g stroke="#000" strokeWidth={2.5} transform="translate(0 1.5)">
        <rect x={1.25} y={1.938} width={19.5} height={19.5} rx={2} />
        <path
          strokeLinecap="square"
          d="M2.063 6.875h17.875M4.813 1.375V0M17.188 1.375V0"
        />
      </g>
    </g>
  </svg>
)

export default Calendar