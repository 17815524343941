import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  ContentState,
  EditorState,
  getDefaultKeyBinding,
  RichUtils
} from 'draft-js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Bold from './icons/bold';
import BulletList from './icons/bulletList';
import Italic from './icons/italic';
import NumberList from './icons/numberList';
import Underline from './icons/underline';
// import Undo from './icons/undo';
// import Redo from './icons/redo';
// import Link from './icons/link';
import 'draft-js/dist/Draft.css';
import { useDropzone } from 'react-dropzone';
import { getBase64 } from '../../../helpers/general';
import { uploadMedia } from '../../../helpers/wordpress';
import Dialog from '../Dialog/Dialog';
import FormInputField from '../FormInputField/FormInputField';
import useDisclosure from '../UseDisclosure/UseDisclosure';
import { FromHTMLConverter, ToHTMLConverter } from './Converter';
import Image from './icons/image';
import Link from './icons/link';
import { decorators, decoratorsRaw } from './Link';
import * as styles from './RichTextField.module.css';
/* Docs: https://draftjs.org/docs/ */
/* HTML Conversions: https://github.com/HubSpot/draft-convert */

/* TODO:
    - Utilise Undo and Redo functionality
*/

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const imageDecorators = composeDecorators(
  resizeablePlugin.decorator,
  focusPlugin.decorator
);

const imagePlugin = createImagePlugin({
  decorator: imageDecorators,
});



const plugins = [imagePlugin, focusPlugin, resizeablePlugin];

const RichTextField = ({
  defaultValue,
  returnValue,
  readOnly,
  target = '',
}) => {
  const startingValue = () => {
    if (defaultValue !== '') {
      const blocks = convertFromHTML(FromHTMLConverter)(defaultValue);
      const newContentState = ContentState.createFromBlockArray(
        blocks.getBlocksAsArray()
      );


      const editorState = EditorState.createWithContent(newContentState);
      return editorState;
    } else return () => EditorState.createEmpty();
  };

  const [editorState, setEditorState] = useState(startingValue());
  const [lastSent, setLastSent] = useState(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [mode, setMode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: loading,
    onOpen: onLoad,
    onClose: onStopLoad,
  } = useDisclosure();
  const theEditor = useRef(null);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setSelectedImage(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  });

  useEffect(() => {
    if (editorState !== lastSent) {
      setLastSent(editorState);
      const rawContent = convertToHTML(ToHTMLConverter)(
        editorState.getCurrentContent()
      );
      
      returnValue(rawContent, target);
    }
  }, [returnValue, editorState, lastSent, target]);

  const StyleButton = ({ onToggle, style, active, label, name }) => {
    const onToggleFn = e => {
      e.preventDefault();
      onToggle(style);
    };

    let className = `RichEditor-styleButton ${styles.styleButton}`;
    if (active) {
      className += ` RichEditor-activeButton ${styles.activeButton}`;
    }

    return (
      <span
        role='presentation'
        style={{ position: 'relative' }}
        className={className}
        onMouseDown={onToggleFn}>
        {label}
      </span>
    );
  };

  const BLOCK_TYPES = [
    // {label: 'H1', style: 'header-one'},
    // {label: 'H2', style: 'header-two'},
    // {label: 'H3', style: 'header-three'},
    // {label: 'H4', style: 'header-four'},
    // {label: 'H5', style: 'header-five'},
    // {label: 'H6', style: 'header-six'},
    // {label: 'Blockquote', style: 'blockquote'},
    { label: 'UL', style: 'unordered-list-item', icon: <BulletList /> },
    { label: 'OL', style: 'ordered-list-item', icon: <NumberList /> },
    // {label: 'Code Block', style: 'code-block'},
  ];

  const BlockStyleControls = props => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className={`RichEditor-controls ${styles.controls}`}>
        {BLOCK_TYPES.map(type => (
          <StyleButton
            key={type.label}
            name={type.label}
            active={type.style === blockType}
            label={type.icon || type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD', icon: <Bold /> },
    { label: 'Italic', style: 'ITALIC', icon: <Italic /> },
    { label: 'Underline', style: 'UNDERLINE', icon: <Underline /> },
    // {label: 'Monospace', style: 'CODE'},
  ];

  const InlineStyleControls = props => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
      <div className={`RichEditor-controls ${styles.controls}`}>
        {INLINE_STYLES.map(type => (
          <StyleButton
            key={type.label}
            name={type.label}
            active={currentStyle.has(type.style)}
            label={type.icon || type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const ENTITY_STYLES = [
    { label: 'Link', style: 'LINK', icon: <Link /> },
    { label: 'Image', style: 'IMAGE', icon: <Image /> },
  ];

  const EntityStyleControls = props => {
    // const {editorState} = props;
    // const selection = editorState.getSelection();

    return (
      <div className={`RichEditor-controls ${styles.controls}`}>
        {ENTITY_STYLES.map(type => (
          <StyleButton
            key={type.label}
            name={type.label}
            // active={type.style === blockType}
            label={type.icon || type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = blockType => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = inlineStyle => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleEntityType = entityType => {
    console.log(entityType);
    let mode = '';
    const contentState = editorState.getCurrentContent();
    switch (entityType) {
      case 'IMAGE':
        mode = 'image';
        break;
      case 'LINK':
        mode = 'link';
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
          const startKey = editorState.getSelection().getStartKey();
          const startOffset = editorState.getSelection().getStartOffset();
          const blockWithLinkAtBeginning = contentState.getBlockForKey(
            startKey
          );
          const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

          let url = '';
          if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            url = linkInstance.getData().url;
          }
          setLinkUrl(url);
        }
        break;

      default:
        break;
    }
    setMode(mode);
    onOpen();
  };

  const setLink = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: linkUrl }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
      decorator: decorators,
    });

    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    setLinkUrl('');
    onClose();
  };

  const setImage = async () => {
    try {
      let imageInput = imageUrl;
      onLoad();
      if (selectedImage) {
        const base64Image = await getBase64(selectedImage);
        const { response: image } = await uploadMedia(
          selectedImage.name,
          base64Image,
          selectedImage.type
        );

        imageInput = image.source_url;
      }
      if (imageInput) {
        onClose();
        setSelectedImage();
        setImageUrl('');
        setEditorState(imagePlugin.addImage(editorState, imageInput));
      }
    } catch (error) {
    } finally {
      onStopLoad();
      onClose();
    }
  };

  let className = `RichEditor-editor ${styles.editor}`;
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled'
    ) {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className={`RichEditor-root ${styles.root}`}>
      <div className={styles.controlBar}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <EntityStyleControls
          editorState={editorState}
          onToggle={toggleEntityType}
        />
      </div>
      <div
        role='presentation'
        className={className}
        onClick={() => theEditor.current.focus()}>
        <Editor
          readOnly={readOnly}
          // blockStyleFn={getBlockStyle}
          // customStyleMap={styleMap}
          decorators={decoratorsRaw}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={setEditorState}
          plugins={plugins}
          // placeholder=""
          ref={theEditor}
          spellCheck={true}
        />
      </div>
      <Dialog
        open={isOpen}
        size='xs'
        bindState={onClose}
        disableAutoClose
        onOk={mode === 'link' ? setLink : setImage}
        onCancel={() => {
          onClose();
          setSelectedImage();
          setImageUrl('');
        }}
        hideBtnOk={loading}
        hideBtnCancel={loading}>
        {mode === 'link' && (
          <>
            <h5 className={styles.popupTitle}>Enter URL:</h5>
            <FormInputField
              placeholder='URL'
              type='text'
              value={linkUrl}
              customHandleChange={e => setLinkUrl(e.target.value)}
            />
          </>
        )}
        {mode === 'image' && (
          <>
            <div className={styles.dropZone} {...getRootProps()}>
              <input
                {...getInputProps()}
                multiple={false}
                accept='.jpeg,.jpg,.png'
              />

              {selectedImage ? (
                <img src={URL.createObjectURL(selectedImage)} alt='' />
              ) : (
                <p>Drag image here, or click to select image</p>
              )}
            </div>

            <h5 className={styles.imagePopupTitle}>or enter URL below</h5>

            <FormInputField
              placeholder='URL'
              type='text'
              value={imageUrl}
              customHandleChange={e => setImageUrl(e.target.value)}
            />
            {loading && (
              <p className={styles.processing}>
                Processing image. Please wait...
              </p>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default RichTextField;

