/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import QrScanner from 'qr-scanner';
import parse from 'html-react-parser'
import { Link, navigate } from "gatsby";
import { get } from "lodash";
// import parse from 'html-react-parser';
import DataTable from '../../../../../components/organisms/DataTable/DataTable';
import Icon from '../../../../../components/atoms/Icon/Icon';
import { getTags, getPerson } from "../../../../../helpers/ortto";
import { authClubExec, wpApiNew, wpAllNew } from "../../../../../helpers/wordpress";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import Dialog from "../../../../../components/atoms/Dialog/Dialog";

import * as styles from '../../usu.module.css';
import { decodeQRData, extractDate, persistLocation } from "../../../../../helpers/general";
import Loader from "../../../../../components/atoms/Loader/Loader";

const EventGuests = ({location}) => {
    // const userEmail = auth && auth.state.email;
    const [isAuthed, setAuthed] = useState(null);
    const [guestList, setGuestList] = useState(null);
    const [exportGuestList, setExportGuestList] = useState(null);
    const [showScanner, setScanner] = useState(false);
    const [scanner, defineScanner] = useState(false);
    const [captured, setCaptured] = useState(false);
    const [captureResult, setCaptureResult] = useState(null);
    const [club, setClub] = useState();

    const headingKeys = [
        { label: 'Member Name', data_key: 'member_name', sortable: true },
        { label: 'Email', data_key: 'email', sortable: true },
        { label: 'Date Registered', data_key: 'date_joined', sortable: true, format: 'dateTime', hideMobile: true },
        { label: 'Checked in', data_key: 'checked_in' },
        { label: 'Type', data_key: 'check_in_type' },
    ];

    if (location.state.clubStatus === 'ign') {
        headingKeys.push({ label: 'Voted', data_key: 'voted'});
    }

    const fetchGuestList = useCallback(async () => {
        // Fetch event guests
        const tags = await getTags(`event-${location.state.eventSlug}`);
        const eventCheckIns = await wpAllNew('getEventCheckInList', {
            clubId: location.state.clubId,
            eventId: location.state.eventId
        });
        // console.log(tags);
        // console.log(eventCheckIns);
        if (
            (String(tags.status).startsWith('2') && Array.isArray(tags.response)) &&
            (String(eventCheckIns.status).startsWith('2') && Array.isArray(eventCheckIns.response))
        ) {
            const checkIns = eventCheckIns.response;
            const tagId = tags.response.find(tag => `event-${location.state.eventSlug}` === tag.name);
            const list = [];
            const rsvpList = [];

            // RSVPs
            if (tagId) {
                const rsvps = await getPerson(
                    ['first', 'last', 'email', 'MemberNumber'],
                    tagId.id,
                    'tag_id'
                );

                if (
                    rsvps.status === 200 &&
                    'contacts' in rsvps.response &&
                    Array.isArray(rsvps.response.contacts)
                ) {
                    let votes = null;
                    if (location.state.clubStatus === 'ign') {
                        const votesData = await wpAllNew('getClubVotesList', {
                            clubId: location.state.clubId,
                            fieldSet: 1
                        });
                        if (String(votesData.status).startsWith('2') && votesData.response) {
                            votes = votesData.response;
                        }
                    }
                    const _rsvpList = rsvps.response.contacts.map(guest => {
                        const name = `${guest.fields['str::first'] || ''} ${guest.fields['str::last'] || ''}`.trim();
                        const dateJoined =
                            guest.fields.tags
                                .find(
                                t =>
                                    t.indexOf(`eventJoined-${location.state.eventSlug}-`) ===
                                    0
                                )
                                ?.replace(`eventJoined-${location.state.eventSlug}-`, '') ||
                            '';
                        const checkedIn = (checkIns && checkIns.find(c => c.acf.member_number === guest.fields['str:cm:membernumber'])) || false;
                        return {
                            id: guest.id,
                            member_name: name || "(No name set)",
                            member_number: guest.fields['str:cm:membernumber'],
                            email: guest.fields['str::email'],
                            date_joined: dateJoined !== '' ? parseInt(dateJoined, 10) : dateJoined,
                            checked_in: checkedIn ? true : false,
                            check_in_type: 'Attendee',
                            voted: (votes && typeof votes.find(v => v.acf.member_number === guest.fields['str:cm:membernumber']) !== 'undefined') || false
                        }
                    });

                    list.push(..._rsvpList);
                    rsvpList.push(..._rsvpList);
                }
            }

            // Guests
            if (checkIns) {
                const guests = checkIns.filter(cin => !rsvpList.find(rsvp => rsvp.member_number === cin.acf.member_number));
                const memberNumbers = guests.map(g => g.acf.member_number);
                if (memberNumbers.length > 0) {
                    const checkInProfiles = await getPerson(['first', 'last', 'email', 'MemberNumber'], memberNumbers, 'str:cm:membernumber');
                    const gList = guests.map(g => {
                        const record = checkInProfiles.response.contacts.find(gp => gp.fields['str:cm:membernumber'] === g.acf.member_number);
                        const name = `${record.fields['str::first'] || ''} ${record.fields['str::last'] || ''}`.trim();
                        const dateJoined =
                            record.fields.tags
                                .find(
                                t =>
                                    t.indexOf(`eventJoined-${location.state.eventSlug}-`) ===
                                    0
                                )
                                ?.replace(`eventJoined-${location.state.eventSlug}-`, '') ||
                            '';
                        return {
                            id: record.id,
                            member_name: name || "(No name set)",
                            member_number: record.fields['str:cm:membernumber'],
                            email: record.fields['str::email'],
                            date_joined: dateJoined !== '' ? parseInt(dateJoined, 10) : dateJoined,
                            checked_in: true,
                            check_in_type: 'Guest',
                            voted: false
                        }
                    });

                    list.push(...gList);
                }
            }
                    
            setGuestList(list);
            const _list = [ ...list ];
            const exportList = _list.map(m => {
                const regoRecord = { ...m };
                if (Number.isInteger(regoRecord.date_joined)) {
                    regoRecord.date_joined = extractDate(regoRecord.date_joined, true);
                }

                return regoRecord;
            });

            setExportGuestList(exportList);
        }
    }, [location]);

    useMemo(() => {
        if (isAuthed === null || (isAuthed  && club && club !== get(location, 'state.clubId'))) {
            if ((!location.state || !('clubId' in location.state)) && typeof window !== 'undefined') {
                window.location = '/account/my-usu/clubs';
            }
            setGuestList(null)
            authClubExec(get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(get(location, 'state.clubId'));

                    if (location.state.eventSlug) {
                        fetchGuestList();
                    } else {
                        setGuestList(false);
                    }
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, location, fetchGuestList , club]);

    /* Scan QR code functionality */
    const captureValue = useCallback((result) => {
        setScanner(false);
        setCaptured(true);
        const scanString = decodeQRData(result);
        try {
            const scanData = JSON.parse(scanString);
            /* check QR is for the correct event */
            if (scanData.eventId !== location.state.eventId) {
                throw new Error("<h6 style='color: red;'>Denied</h6><br />QR not for this event");
            }

            /* check member number is on the attending list and hasn't checked in yet */
            const foundMember = guestList.find(guest => guest.member_number === scanData.memberId);
            if (!foundMember) {
                /* Theoretically, this should never happen as you can only get the QR by being on the list. Means data issue with Ortto */
                throw new Error("<h6 style='color: red;'>Denied</h6><br />Member not on list");
            } else {
                /* member on list - check if they have checked in already, otherwise, check them in */
                return wpApiNew('execCheckEventRegistration', {
                    clubId: location.state.clubId,
                    eventId: scanData.eventId,
                    memberNumber: scanData.memberId
                }).then(checkIn => {
                    if (String(checkIn.status).startsWith('2') && Array.isArray(checkIn.response)) {
                        const checkedIn = (checkIn.response.length > 0) || false;
                        if (checkedIn) {
                            setCaptureResult("<h6 style='color: red;'>Denied</h6><br />Already checked in");
                            return true;
                        } else {
                            wpApiNew('execCheckInMemberToEvent', {
                                clubId: location.state.clubId,
                                eventId: scanData.eventId,
                                memberNumber: scanData.memberId
                            }).then(checkInAttempt => {
                                if (String(checkInAttempt.status).startsWith('2') && checkInAttempt.response) {
                                    setCaptureResult(`<h6 style='color: green;'>Checked In!</h6><br /><strong>Name:</strong> ${foundMember.member_name}`);
                                    return true;
                                }
                            })
                        }
                    } else {
                        setCaptureResult("<h6 style='color: red;'>Error</h6><br />Unable to check attending list");
                        return true;
                    }
                }).catch(error => {
                    // throw new Error(error);
                    setCaptureResult("<h6 style='color: red;'>Error</h6><br />Unable to check attending list");
                    return true;
                })
            }
        } catch (e) {
            setCaptureResult(e);
        }
        
    }, [location, guestList]);

    useEffect(() => {
        const viewport = document.getElementById('viewport');
        if (typeof window !== 'undefined' && viewport && showScanner) {
            try {
                let theScanner = scanner;
                if (!scanner) {
                    const _scanner = new QrScanner(viewport, result => captureValue(result));
                    QrScanner.hasCamera().then(hasCamera => {
                        if (hasCamera) {
                            defineScanner(_scanner);
                            theScanner = _scanner;

                            theScanner.start();
                        } else {
                            console.log('no camera found');
                            setCaptureResult("Camera required to use the feature");
                        }
                    });
                }
            } catch (e) {
                console.log(e);
                setCaptureResult("Error starting scanner");
            }
        }

        if (typeof window !== 'undefined' && viewport && scanner && !showScanner) {
            scanner.stop();
        }
    }, [showScanner, scanner, captureValue]);

    return (
        <>
            {(guestList) ? (
                <>
                    <DataTable
                        tableData={guestList}
                        exportData={exportGuestList}
                        allowExport
                        topActions={[
                            {
                                label: 'Scan Attendees',
                                icon: <Icon symbol='search' />,
                                event: () => setScanner(true),
                            },
                        ]}
                        headingKeys={headingKeys}
                    />
                    <Dialog open={showScanner} onCancel={() => {setScanner(false); setGuestList(null); fetchGuestList()}} size="xs" hideBtnCancel hideBtnOk>
                        <div className={styles.scanner}>
                            <video id="viewport"></video>
                            <p className={styles.cameraRequired}><span>Camera required to use this feature</span></p>
                        </div>
                    </Dialog>
                    <Dialog open={captured} onCancel={() => {setCaptured(false); setScanner(true); setCaptureResult(null)}} size="xs" hideBtnCancel hideBtnOk>
                        {captureResult === null ? (
                            <div style={{height: '50px', textAlign: 'center'}}>
                                <Loader />
                            </div>
                        ) : (
                            <div style={{textAlign: 'center'}}>
                                {typeof captureResult === 'string' && (<>{parse(captureResult)}</>)}
                                {typeof captureResult === 'object' && (<>{parse(captureResult.message)}</>)}
                            </div>
                        )}
                    </Dialog>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Checking authorisation */}
                    {((isAuthed === null && guestList === null) || (isAuthed === true && guestList === null)) && (
                        <div>Fetching your guest list</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && guestList === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementEventGuests = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" title={`Event guests`} breadcrumbTitle={`Event guests`} breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <EventGuests location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementEventGuests