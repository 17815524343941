// extracted by mini-css-extract-plugin
export var actions = "wishlists-module--actions--0e9bb";
export var active = "wishlists-module--active--ddae4";
export var show = "wishlists-module--show--4ddaf";
export var wishlistEmpty = "wishlists-module--wishlistEmpty--fbf06";
export var wishlistError = "wishlists-module--wishlistError--aba43";
export var wishlistErrorContainer = "wishlists-module--wishlistErrorContainer--89930";
export var wishlistHead = "wishlists-module--wishlistHead--4af89";
export var wishlistItem = "wishlists-module--wishlistItem--17707";
export var wishlistItemEditor = "wishlists-module--wishlistItemEditor--b34db";
export var wishlistRow = "wishlists-module--wishlistRow--3b0a0";