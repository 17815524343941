// extracted by mini-css-extract-plugin
export var bagContainer = "ProductCard-module--bagContainer--56459";
export var detailsContainer = "ProductCard-module--detailsContainer--a496b";
export var heartContainer = "ProductCard-module--heartContainer--cf366";
export var heartFillContainer = "ProductCard-module--heartFillContainer--feaf0";
export var hide = "ProductCard-module--hide--b4cde";
export var imageContainer = "ProductCard-module--imageContainer--9405c";
export var meta = "ProductCard-module--meta--73150";
export var originalPrice = "ProductCard-module--originalPrice--49c86";
export var prices = "ProductCard-module--prices--73a4d";
export var productName = "ProductCard-module--productName--cdb9f";
export var root = "ProductCard-module--root--3829c";
export var salePrice = "ProductCard-module--salePrice--a0ecf";
export var show = "ProductCard-module--show--70917";