import React from 'react';
import * as styles from './Layout1.module.css';

import { returnAlt, returnSrc } from '../../../../helpers/contentful';
import Container from '../../../atoms/Container/Container';
import ContentfulRichText from '../../../atoms/ContentfulRichText/ContentfulRichText';

const Layout1 = props => {

  const { title, portraitFirstPicture, landscapePicture, portraitSecondPicture, smallPicture, content } = props;

  const portraitFirstPictureAlt = returnAlt(portraitFirstPicture);
  const portraitFirstPictureSrc = returnSrc(portraitFirstPicture);

  const landscapePictureAlt = returnAlt(landscapePicture);
  const landscapePictureSrc = returnSrc(landscapePicture);

  const portraitSecondPictureAlt = returnAlt(portraitSecondPicture);
  const portraitSecondPictureSrc = returnSrc(portraitSecondPicture);

  const smallPictureAlt = returnAlt(smallPicture);
  const smallPictureSrc = returnSrc(smallPicture);

  return (
    <div className={ styles.root } style={props.mainStyles}>
      <Container>
        <div className={styles.headerContainer}>
          <h1>{title}</h1>
        </div>
      </Container>
      <div className={styles.imagesContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.portraitPictureContainer}>
                <img src={`${portraitFirstPictureSrc}?w=468&h=460&fit=fill`} alt={portraitFirstPictureAlt}></img>
            </div>
          </div>
          <div className={styles.centerContainer}>
            <div className={styles.landscapePictureContainer}>
                <img src={`${landscapePictureSrc}?w=520&h=600&fit=fill`} alt={landscapePictureAlt}></img>
            </div>
          </div>
          <div className={styles.rightContainer}>
              <div className={styles.portraitPictureContainer}>
                <img src={`${portraitSecondPictureSrc}?w=468&h=460&fit=fill`} alt={portraitSecondPictureAlt}></img>
              </div>
              <div className={styles.smallPictureContainer}>
                <img src={`${smallPictureSrc}?w=210&h=210&fit=fill`} alt={smallPictureAlt}></img>
              </div>
          </div>
      </div>
      <Container>
        <div className={styles.contentContainer}>
          <ContentfulRichText raw={content.raw}></ContentfulRichText>
        </div>
      </Container>
    </div>
  );
};

export default Layout1;
