import React from 'react';
import { decode } from 'he'

// import Icon from '../Icon/Icon';

import * as styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ crumbs }) => {
    let crumbsOutput = crumbs;
    if (crumbsOutput && typeof crumbsOutput !== 'object') {
        if (crumbsOutput.indexOf('>') > -1) {
            crumbsOutput = crumbsOutput.split('>');
        } else {
            crumbsOutput = [crumbsOutput];
        }
    }
    return (
        <div className={styles.root}>
            {crumbsOutput && crumbsOutput.map((crumb, crumbIndex) => (
                <div className={styles.crumbMainContainer} key={crumbIndex}>
                    <span className={styles.crumbContainer}>
                        {/* {crumbIndex > 0 && (
                            <span className={styles.spacer}>&nbsp;</span>
                            // issue on mobile view
                        )} */} 
                        {typeof crumb === 'object' && 'link' in crumb && crumb.link !== '' && (
                            <a className={styles.crumb} href={crumb.link}>{decode(crumb.label.trim())}</a>
                        )}
                        {typeof crumb === 'object' && 'onClick' in crumb && crumb.onClick && (
                            <span role="presentation" className={`${styles.crumb} ${styles.link}`} onClick={crumb.onClick}>{decode(crumb.label.trim())}</span>
                        )}
                        {typeof crumb === 'object' && (!('link' in crumb) || crumb.link === '') && (!('onClick' in crumb) || !crumb.onClick) && (
                            <span className={styles.crumb}>{decode(crumb.label.trim())}</span>
                        )}
                        {typeof crumb !== 'object' && (
                            <span className={styles.crumb}>{decode(crumb.trim())}</span>
                        )}
                    </span>
                    <span className={styles.spacer}>&nbsp;</span>
                </div>
            ))}
        </div>
    )
}

export default Breadcrumbs