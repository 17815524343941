import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Container from '../../atoms/Container/Container';
import ContentCard from '../../molecules/ContentCard/ContentCard';
import * as styles from './HealthServicesList.module.css';

const HealthServicesList = props => {
  const { allWpHealth } = useStaticQuery(graphql`
    query {
      allWpHealth {
        nodes {
          status
          uri
          title
          excerpt
          slug
          featuredImage {
            node {
              publicUrl
              sourceUrl
              localFile {
                childImageSharp {
                  fixed(width: 420, height: 130, cropFocus: CENTER, fit: OUTSIDE, background: "#F9F9F9") {
                    src
                  }
                }
              }
            }
          }
          healthAndServicesFields {
            openingHours {
              days
              hours
            }
            address {
              address
              email
              country
              contact
              postcode
              state
              suburb
            }
            promotion
          }
        }
      }
    }
  `);

  return (
    <Container>
      <div className={styles.grid}>
        {allWpHealth?.nodes.map((service, index) => (
          <div className={styles.container} key={index}>
            <ContentCard cardInfo={service} type='health' />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default HealthServicesList;

