import React from 'react'

const CartAdd = () => (
  <svg width="25" height="21" viewBox="0 0 25 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.8335 14.367L19.4612 7.96254C19.5283 7.69797 19.4696 7.41722 19.302 7.20179C19.1275 6.98063 18.8581 6.8558 18.5766 6.86564H6.1922L5.3076 3.12378C5.21134 2.71583 4.842 2.43144 4.423 2.44264H0.884599C0.396049 2.44264 0 2.83869 0 3.32724C0 3.81579 0.396049 4.21184 0.884599 4.21184H3.71532L6.05951 14.3405C6.24419 15.1403 6.95477 15.7081 7.77563 15.7116H16.1174C16.9304 15.711 17.6384 15.1563 17.8335 14.367ZM7.51909 20.1346C8.25192 20.1346 8.84599 19.5406 8.84599 18.8077C8.84599 18.0749 8.25192 17.4808 7.51909 17.4808C6.78627 17.4808 6.1922 18.0749 6.1922 18.8077C6.1922 19.5406 6.78627 20.1346 7.51909 20.1346ZM15.0382 18.8077C15.0382 18.0749 15.6323 17.4808 16.3651 17.4808C17.0979 17.4808 17.692 18.0749 17.692 18.8077C17.692 19.5406 17.0979 20.1346 16.3651 20.1346C15.6323 20.1346 15.0382 19.5406 15.0382 18.8077ZM16.1174 13.9424L17.4443 8.63484H6.55488L7.78447 13.9424H16.1174Z" fill="currentColor"/>
    <circle cx="19.0198" cy="5.98077" r="3.98077" fill="#00A2DA" stroke="#00A2DA" strokeWidth="3" data-circle/>
    <path d="M19.0185 3.3269V9.51921" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M15.9238 6.4231H22.1161" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

export default CartAdd