import React from 'react'

const Paypal = () => (
    <svg viewBox="0 0 66 18" width={66} height={18} xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M7.883.369H2.828a.702.702 0 00-.695.593L.09 13.926a.42.42 0 00.417.486h2.413c.346 0 .64-.25.694-.593l.552-3.497a.702.702 0 01.693-.593h1.6c3.331 0 5.253-1.612 5.755-4.806.226-1.397.01-2.495-.644-3.263C10.85.815 9.576.369 7.883.369zm.584 4.735C8.19 6.918 6.804 6.918 5.464 6.918H4.7l.535-3.388a.421.421 0 01.416-.356h.35c.913 0 1.774 0 2.22.52.265.311.346.773.245 1.41zM22.996 5.045h-2.421a.422.422 0 00-.416.356l-.107.677-.17-.245c-.524-.761-1.693-1.015-2.86-1.015-2.675 0-4.96 2.026-5.405 4.868-.231 1.418.098 2.774.902 3.72.738.869 1.794 1.231 3.05 1.231 2.155 0 3.35-1.386 3.35-1.386l-.107.673a.421.421 0 00.415.488h2.181c.347 0 .64-.252.694-.594l1.309-8.286a.42.42 0 00-.415-.487zm-3.375 4.712c-.233 1.384-1.331 2.312-2.731 2.312-.703 0-1.265-.225-1.626-.653-.358-.424-.494-1.028-.38-1.7.218-1.372 1.334-2.33 2.713-2.33.688 0 1.247.228 1.615.659.369.435.515 1.043.41 1.712zM35.89 5.045h-2.433a.705.705 0 00-.582.309l-3.355 4.942-1.422-4.75a.705.705 0 00-.675-.5h-2.39a.422.422 0 00-.4.557l2.68 7.864-2.52 3.557a.421.421 0 00.344.665h2.43c.23 0 .446-.112.577-.302l8.092-11.68a.421.421 0 00-.346-.662z"
          fill="#253B80"
        />
        <path
          d="M43.945.369h-5.057a.702.702 0 00-.693.593L36.15 13.926a.42.42 0 00.416.486h2.595a.492.492 0 00.484-.415l.58-3.675a.702.702 0 01.694-.593h1.6c3.331 0 5.252-1.612 5.755-4.806.227-1.397.009-2.495-.645-3.263C46.91.815 45.637.369 43.945.369zm.583 4.735c-.275 1.814-1.662 1.814-3.003 1.814h-.762L41.3 3.53a.42.42 0 01.416-.356h.35c.912 0 1.774 0 2.219.52.265.311.346.773.244 1.41zM59.057 5.045h-2.42a.42.42 0 00-.415.356l-.107.677-.17-.245c-.525-.761-1.693-1.015-2.86-1.015-2.675 0-4.959 2.026-5.404 4.868-.23 1.418.097 2.774.901 3.72.74.869 1.794 1.231 3.05 1.231 2.155 0 3.35-1.386 3.35-1.386l-.107.673a.421.421 0 00.417.488h2.18c.345 0 .64-.252.693-.594l1.31-8.286a.422.422 0 00-.418-.487zm-3.375 4.712c-.232 1.384-1.331 2.312-2.731 2.312-.702 0-1.265-.225-1.626-.653-.358-.424-.492-1.028-.38-1.7.22-1.372 1.334-2.33 2.713-2.33.688 0 1.246.228 1.615.659.37.435.516 1.043.41 1.712zM61.911.724l-2.075 13.202a.42.42 0 00.415.486h2.087c.346 0 .64-.25.694-.593L65.078.855a.421.421 0 00-.415-.487h-2.336a.422.422 0 00-.416.356z"
          fill="#179BD7"
        />
      </g>
    </svg>
)

export default Paypal