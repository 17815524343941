import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../../atoms/Button/Button'
import Container from '../../atoms/Container/Container'
import * as styles from './InterestForm.module.css'

// const sampleInterests = [
//   { id: 1, text: 'Art, Music & Performance', selected: true },
//   { id: 2, text: 'Language', selected: false },
//   { id: 3, text: 'Political', selected: false },
//   { id: 4, text: 'Professional', selected: false },
//   { id: 5, text: 'Academic', selected: false },
//   { id: 6, text: 'Hobby & Special Interests', selected: false },
//   { id: 7, text: 'Sport & Recreation', selected: false },
//   { id: 8, text: 'Interest Name', selected: false },
//   { id: 9, text: 'Interest Name', selected: false },
// ]

const InterestForm = ({ selected, callback, overrideBtnLabel, btnStyle, overrideRoot, emptyText }) => {
  const [interests, setInterests] = useState([]);

  const interestsQuery = useStaticQuery(graphql`
        query {
          interests: allWpClubCategory {
            nodes {
              name
              slug
            }
          }
        }
    `)
  const interestsData = interestsQuery?.interests.nodes;

  const handleInterestChange = (slug) => {
    const newInterests = [...interests];
    const index = newInterests.indexOf(slug);
    if (index === -1) {
      newInterests.push(slug);
    } else {
      newInterests.splice(index, 1);
    }
    setInterests(newInterests)
  }

  useEffect(() => {
    if (selected) {
      const slugs = selected.filter(slug => interestsData.find(interest => interest.slug === slug));
      setInterests(slugs);
    }
  }, [selected, interestsData])

  const action = () => {
    if (callback) {
      callback(interests);
    } else {
      console.log("No callback registered to action data");
    }
  }

  const generateText = () => {
    if(emptyText && interests.length === 0) return emptyText;
    if(overrideBtnLabel) return overrideBtnLabel;
    return 'Update';
  }

  return (
    <Container>
      <div className={overrideRoot ? styles.customRoot : styles.root}>
        {interestsData.map((interest, index) => (
          <div key={index} className={`${styles.interestCard} ${interests.indexOf(interest.slug) > -1 && styles.active}`} onClick={() => handleInterestChange(interest.slug)} role="presentation">
            <p>{interest.name}</p>
            <button>
              {interests.indexOf(interest.slug) > -1 ?
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.66675 8L6.66675 12L13.3334 4" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                :
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00008 13.3332V7.99984M8.00008 7.99984V2.6665M8.00008 7.99984H13.3334M8.00008 7.99984H2.66675" stroke="black" strokeWidth="2" strokeLinecap="round" />
                </svg>}
            </button>
          </div>
        ))}
      </div>
      <div className={`text-center mt-5 ${overrideRoot && styles.overrideButtonParent}`}>
        <Button
          type={'button'}
          level='primary'
          disabled={btnStyle && btnStyle.indexOf('disabled') > -1}
          className={`${styles.submitButton} ${btnStyle}`}
          onClick={() => action()}
        >
          {generateText()}
          {/* {overrideBtnLabel || 'Update'} */}
        </Button>
      </div>

    </Container>
  )
}

export default InterestForm