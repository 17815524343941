import React, { useState, useRef } from 'react';
import Slider from '../../atoms/Slider/Slider';
import * as styles from './CustomSlider.module.css';
import Icon from '../../atoms/Icon/Icon';
import { useWindowDimensions } from '../../../helpers/general';

const CustomSlider = (props) => {

  const { slidesToShow = 3, title, children } = props;
  const sliderRef = useRef(null);
  const { width } = useWindowDimensions();

  // override slides to show on smaller devices
  let toShow = slidesToShow;
  if(width < 1000) toShow = 2;
  if(width < 550) toShow = 1;

  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(children?.length > toShow ? false : true);

  const onSliderChangeHandler = (index) => {
    if(index === 0) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
    
    if(index + toShow === children?.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }

  const sliderSettings = {
    slidesToShow: slidesToShow,
    customPaging: () => <div></div>,
    afterChange: (index) => onSliderChangeHandler(index),
    infinite: false,
    responsive: [
      {breakpoint: 1000, settings: {slidesToShow: 2}},
      {breakpoint: 550, settings: {slidesToShow: 1}}
    ]
  }

  return (
    <div className={ styles.CustomSlider }>
      <div className={styles.headerSliderContainer}>
          <h3>{title}</h3>
          <div className={styles.controlContainer}>
            <div 
              role={'presentation'} 
              className={`${styles.previousContainer} ${disablePrev === true ? styles.disable :''}`} 
              onClick={() => sliderRef.current.slickPrev()}>
              <Icon symbol={'arrowWithTail'}></Icon>
            </div>
            <div 
              role={'presentation'} 
              className={`${styles.nextContainer} ${disableNext === true ? styles.disable :''}`} 
              onClick={() => {
                // check for detecting overflows;
                if(disableNext === false) sliderRef.current.slickNext();
              }}>
              <Icon symbol={'arrowWithTail'}></Icon>
            </div>
          </div>
        </div>
        <Slider settings={sliderSettings} sliderRef={sliderRef}>
          {children}
        </Slider>
    </div>
  );
};

export default CustomSlider;
