import { useState, useEffect } from 'react';
import { navigate } from 'gatsby'
import { get } from 'lodash';
import xml2js from 'xml2js'
import QRCode from 'qrcode'
import CryptoJS from 'crypto-js';
import {format , parse, isSameMonth, isSameDay} from 'date-fns';

/**
 * Track recently viewed products
 *
 * Handles tracking of product views and stores product IDs in session for display on the site
 *
 * @param   {Number} productId       The prodoct ID to track
 *
    import { trackRV } from '../helpers/general'

    trackRV(productId).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
function trackRV(productId) {
  const sessionKey = '__trackRV';
  const displayAmount = 10;

  if (typeof sessionStorage !== 'undefined') {
    const recentlyViewed = getStorage(sessionKey);
    let recentlyViewedArr = [];

    if (recentlyViewed !== null) {
      recentlyViewedArr = JSON.parse(recentlyViewed);
    }

    const existIndex = recentlyViewedArr.indexOf(productId);

    if (existIndex > -1) {
      recentlyViewedArr.splice(existIndex, 1);
    }

    recentlyViewedArr.unshift(productId);

    if (recentlyViewedArr.length > displayAmount) {
      recentlyViewedArr.pop();
    }

    setStorage(sessionKey, JSON.stringify(recentlyViewedArr), true);
  }
}

/**
 * Get local / session / cookie storage information
 *
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return { storage: 'sessionStorage', value: sessionValue };
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return { storage: 'localStorage', value: localValue };
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 *
 * Handles browser storage information functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return { storage: 'cookie', value: c.substring(name.length, c.length) };
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 *
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 *
 * Handles browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 *
 * Handles setting browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 *
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 *
 * Handles setting browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 *
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire, path) {
  // console.log('dodo:', key);
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=${path || '/'}`;
  }
}

/**
 * Remove local / session / cookie storage
 *
 * Handles removing browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 *
 * Handles removing browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 *
 * Checks the provided email address and validates its format
 *
 * @param   {String} email  The email address
 *
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Validate strong password format
 *
 *
 * @param   {String} password  The password
 *
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Validate phone format
 *
 * Checks the provided phone number and validates its format
 *
 * @param   {String} number  The phone number
 *
    import { validatePhoneNumber } from '../helpers/general'

    validatePhoneNumber(number)
 */
function validatePhoneNumber(number) {
  const regex = /^[- +()]*[0-9][- +()0-9]*$/;

  return regex.test(number);
}

/**
 * Is value numeric
 *
 * Determine whether variable is a number
 *
 * @param {*} str
 *
    import { isNumeric } from '../helpers/general'

    isNumeric(value)
 */
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false // we only process strings and numbers!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * Is value an object
 *
 * Determine whether variable is an object
 *
 * @param {*} obj
 *
    import { isObject } from '../helpers/general'

    isObject(obj)
 */
function isObject(obj) {
  return (Array.isArray(obj) === false && typeof obj === 'object')
}

function formatPrice(amount, currency = "AUD", appendZero = false) {
  let displayAmount = (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';

  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  });
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(obj => obj.type === 'currency');
    const decimalValue = formattedPriceParts.find(obj => obj.type === 'fraction');
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  }
  
  return formattedPrice;
}

/**
 * Get ordinal for date number
 *
 * Given a date number, this will return the suffix for a date
 *
 * @param   {Number} n  The date
 *
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
      ? 'nd'
      : n % 10 === 2
        ? 'nd'
        : n % 10 === 3
          ? 'rd'
          : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Get slug of the product from given uri
 *
 * @param {string} uri the url of the product
 * example: http://your-store-url.mybigcommerce.com/your-product/
 * @returns {string} slug of the product
 * example: /product/your-product/
 */
function getUriFromURL(urlStr = '') {
  const slug = urlStr
    .split('/')
    .filter(x => x)
    .pop();
  if (slug) {
    return `/product/${slug}/`;
  }
  return '';
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Capitalise first letter
 *
 * Capitalise the first letter in the provided string
 *
 * @param   {string} string   The string
 *
 * import { ucFirst } from '../helpers/general'
 *
 * ucFirst(string)
 *
 * @returns {string} the capitalised string
 */
 function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Fetch title
 *
 * Determine which title for a product to use
 *
 * @param   {string} bcData   The BC product title
 * @param   {string} otherData   The string to split by
 *
 * import { fetchTitle } from '../helpers/general'
 *
 * fetchTitle(bcData, otherData)
 *
 * @returns {string} title
 */
function fetchTitle(bcData, otherData) {
  let title = '';
  /* Add logic here if there are other sources of the product name */
  if (otherData?.name) {
    title = otherData.name;
  } else if (bcData.name !== undefined) {
    const splitTitle = dissectTitle(bcData.name, ' (');
    title = splitTitle[0];
  }

  return title;
}

/**
 * Dissect title
 *
 * Split out title to reveal the design type from the product name
 *
 * @param   {string} productTitle   The product title
 * @param   {string} splitBy        The string to split by
 *
 * import { dissectTitle } from '../helpers/general'
 *
 * dissectTitle(productTitle, splitBy)
 *
 * @returns {array} splitTitle
 */
function dissectTitle(productTitle, splitBy) {
  let titleParts = [''];
  if (productTitle !== undefined) {
    titleParts = productTitle.split(splitBy);
  }

  return titleParts;
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 *
 * Provide on the fly window dimensions
 *
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 *
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading = window.scriptsLoading && window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        }
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback])
}

/**
 * Decode unicode characters
 *
 * Provides a way to decode a string containing unicode characters for logic purposes
 *
    import { decodeUnicode } from '../helpers/general'

    decodeUnicode(content);
 */
function decodeUnicode(text) {
  var map = {
    '&amp;': '&',
    '&#038;': "&",
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#8217;': "'",
    '&#8216;': "'",
    '&#8211;': "-",
    '&#8212;': "-",
    '&#8230;': "…",
    '&#8221;': '"',
    '&#8243;': '"'
  };
  // console.log("Decoding", text);
  return text.replace(/&[\w\d#]{2,5};/g, function (m) { return map[m]; });
}

function rewriteShippingOptionLabel(label, hideType = false) {
  let modLabel = label;
  /* Write logic to rename shipping option labels from BC */
  return modLabel;
}

/**
 * dataLayerPush
 *
 * Enables a data push connection to a connected GTM container
 *
    import { dataLayerPush } from '../helpers/general'

    dataLayerPush(eventName, {extraVariable: 'value'}, productData, linkToNavigate);

    Predefined event names:
      - SearchResults: sends list of products listed in the search result
        eg: dataLayerPush('SearchResults', null, productObject);

      - AllProductsOnPage: sends list of products listed on the current page (PLP, PDP, etc)
        eg: dataLayerPush('AllProducts On Page', null, productObject);

      - AddedToWishlist: sends product that was added to the wishlist
        eg: dataLayerPush('AddedToWishlist', null, productObject);

      - ProductClick: sets up enhanced ecomm tracking when a product is clicked to be viewed
        eg: dataLayerPush('ProductClick', null, productObject, path);

      - ProductView: sets up enhanced ecomm tracking when viewing a product page
        eg: dataLayerPush('ProductView', null, productObject);

      - AddedToCart: sets up enhanced ecomm tracking when adding a product (or products) to cart
        eg: dataLayerPush('AddedToCart', null, productObject);

      - RemovedFromCart: sets up enhanced ecomm tracking when removing a product (or products) to cart
        eg: dataLayerPush('RemovedFromCart', null, productObject);

      - Checkout: sends checkout data to track prior to payment
        eg: dataLayerPush('Checkout', null, checkoutObject, '/checkout/');
 */
function dataLayerPush(eventName, extraData, productData, linkTo, listObj) {
  // Ensure window is available before moving forward
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // console.log('dataLayer Data', extraData, productData, linkTo);

    const object = {
      ...extraData,
      event: eventName
    }

    let productsObject = false;

    let productDataCategories = {};

    if (productData && productData.categories) {
      productData.categories.forEach((category, i) => {
          productDataCategories[`item_category${i}`] = category.name
      });
    }

    const productObject = product => ({
      item_id: product.sku,
      // id: product.entityId || product.entity_id || product.productId || product.product_id || product.id || 0,
      item_name: product.title || product.name || '',
      discount: product.price > product.calculated_price ? product.price - product.calculated_price : 0,
      item_brand: 'USU',
      item_variant: product.name && product.name.indexOf(' - ') > -1 ? product.name.split(' - ').pop() : product.title && product.title.indexOf(' - ') > -1 ? product.title.split(' - ').pop() : undefined,
      ...productDataCategories,
      item_list_id: listObj ? listObj.bigcommerce_id : undefined,
      item_list_name: listObj ? listObj.name : undefined,
      price: product.price || product.sale_price || 0,
      currency: 'AUD',
      // category: product.categories,
      quantity: product.quantity || 1,
    });

    if (productData) {
      const products = !Array.isArray(productData) ? [productData] : productData;
      productsObject = [...products.map(product => productObject(product))]
    }

    productsObject = JSON.parse(JSON.stringify(productsObject));

    switch (eventName) {
      case 'SearchResults':
      case 'productImpressions':
        if (productsObject) {
          object.ecommerce = {
            items: productsObject
          }
        }
        break;

      case 'productClick':
        if (productsObject) {
          // object.product = productsObject;
          object.ecommerce = {
            items: productsObject
          }
        }
        break;

      case 'productDetail':
        if (productsObject) {
          // object.product = productsObject;
          object.ecommerce = {
            value: productsObject[0].price,
            items: productsObject
          }
        }
        break;

      case 'viewCart':
        if (productsObject) {
          object.ecommerce = {
            items: productsObject
          }
        }
        break;

      case 'addToCart':
        if (productsObject) {
          object.ecommerce = {
            items: productsObject
          }
        }
        break;

      case 'removeFromCart':
        if (productsObject) {
          object.ecommerce = {
            items: productsObject
          }
        }
        break;

      case 'beginCheckout':
          if (productsObject) {
            object.ecommerce = {
              items: productsObject
            }
          }
          break;

      case 'addShippingInfo':
          if (productsObject) {
            object.ecommerce = {
              items: productsObject
            }
          }
          break;

      case 'addBillingInfo':
          if (productsObject) {
            object.ecommerce = {
              items: productsObject
            }
          }
          break;

      case 'Checkout':
        if (productsObject) {
          object.checkout = {
            totalAmount: productsObject.grandTotal,
            tax: productsObject.taxTotal,
            shipping: productsObject.shippingCostTotal,
            products: []
          };

          const prodTypes = Object.keys(productsObject.cart.lineItems);
          prodTypes.map((type) => {
            if (productsObject.cart.lineItems[type].length > 0) {
              productsObject.cart.lineItems[type].map((item) => {
                const itemId = `${item.productId}_${item.variantId}`;
                const itemName = `${item.name} (${item.sku})`;
                const product = productObject({ id: itemId, title: itemName, price: item.salePrice, quantity: item.quantity });
                object.checkout.products.push(product);
                return true;
              });
            }
            return true;
          });
        }
        break;

      default:
        // do nothing
        break;
    }

    // Check if GTM is loaded
    if (linkTo) {
      if (typeof window.google_tag_manager !== 'undefined') {
        object.eventCallback = () => {
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else {
            navigate(linkTo)
          }
        };
      } else {
        // Handler for when GTM is not present
        if (linkTo.startsWith('http')) {
          document.location = linkTo;
        } else {
          navigate(linkTo)
        }
      }
    }

    // console.log('Pushing DataLayer: ', object);
    window.dataLayer.push(object);
  }
}

function parseXmlToJson(xml) {
  return new Promise((res, rej) => {
    xml2js.parseString(xml, { trim: true }, (err, result) => {
      if (err) {
        rej(err);
      }

      res(result);
    });
  })
}

function transformToEventDate(startDate, startTime, endDate, endTime, includeYear = false, includeTime = true) {

  let formattedDates = [];

  if (startDate) {
    const dateStartObject = new Date(startDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3'));
    if (endDate && endDate !== startDate) {
      const dateEndObject = new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3'));
      if (dateStartObject.getMonth() === dateEndObject.getMonth()) {
        formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { day: 'numeric' })}${includeTime ? ` ${startTime}` : ''}`); // insert start but don't include month
      } else {
        if (includeYear) {
          formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: 'numeric' })} ${dateStartObject.getFullYear()}${includeTime ? ` ${startTime}` : ''}`);
        } else {
          formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: 'numeric' })}${includeTime ? ` ${startTime}` : ''}`); // insert start date
        }
      }

      if (includeYear) {
        formattedDates.push(`${dateEndObject.toLocaleString('en-AU', { month: 'short', day: 'numeric' })} ${dateEndObject.getFullYear()}${includeTime ? ` ${endTime}` : ''}`); // insert end date
      } else {
        formattedDates.push(`${dateEndObject.toLocaleString('en-AU', { month: 'short', day: 'numeric' })}${includeTime ? ` ${endTime}` : ''}`); // insert end date
      }

    } else if (endTime && endTime !== startTime) {
      if (includeYear) {
        formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: '2-digit' })} ${dateStartObject.getFullYear()} ${startTime} - ${endTime}`); // no end date
      } else {
        formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: '2-digit' })} ${startTime} - ${endTime}`); // no end date
      }
      
    } else if (includeYear) {
      formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: '2-digit' })} ${dateStartObject.getFullYear()} ${startTime}`); // no end date
    } else {
      formattedDates.push(`${dateStartObject.toLocaleString('en-AU', { month: 'short', day: 'numeric' })}${includeTime ? ` ${startTime}` : ''}`); // no end date
    }
  }

  return formattedDates;
}


function formatEventDateTime (
  startdate,
  enddate,
  starttime,
  endtime,
  longDate = false
) {
  let displayFormat = 'dd-MM-yy';
  const startDateObj = parse(startdate, 'yyyyMMdd', new Date());
  const endDateObj = parse(enddate, 'yyyyMMdd', new Date());
  const formatStartDate = format(startDateObj , displayFormat)
  const formatEndDate = format(endDateObj , displayFormat)
  let formatDate = formatStartDate;
  let formatTime = '';

  if(longDate) {
    // Change format - JR
    // isSameMonth
    const isTheSameMonth = isSameMonth(startDateObj, endDateObj);
    const isTheSameDay = isSameDay(startDateObj, endDateObj);
    if (isTheSameDay) {
      formatDate = `${format(startDateObj , 'd')} ${format(startDateObj , 'LLL')} ${starttime ?? ''} to ${endtime ?? ''}`
    } else if (isTheSameMonth) {
      formatDate = `${format(startDateObj , 'd')} - ${format(endDateObj , 'd')} ${format(startDateObj , 'LLL')} ${starttime ?? ''} to ${endtime ?? ''}`
    } else {
      formatDate = `${format(startDateObj , 'd')} ${format(startDateObj , 'LLL')} - ${format(endDateObj , 'd')} ${format(endDateObj , 'LLL')} ${starttime ?? ''} to ${endtime ?? ''}`
    }
  } else {
    if(formatStartDate !== formatEndDate) formatDate += ` to ${formatEndDate}`;
    if(starttime && endtime) formatTime = `${starttime} to ${endtime}`;
  }

  return{
    formatDate,
    formatTime
  }
}

function formatWpAddresses(addresses) {

  if (!addresses) return '';
  let final = '';

  if (addresses.address) {
    final += addresses.address;
  }

  if (addresses.suburb) {
    final = `${final !== '' ? final + ', ' : ''}${addresses.suburb}`;
  }

  if (addresses.state) {
    final = `${final !== '' ? final + ', ' : ''}${addresses.state}`;
  }

  if (addresses.country) {
    final = `${final !== '' ? final + ', ' : ''}${addresses.country}`;
  }

  if (addresses.postCode) {
    final = `${final !== '' ? final + ', ' : ''}${addresses.postCode}`;
  }

  return final;
}

function getBase64(file) {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

function extractDate(dateTime, withTime = false) {
  if (dateTime === '' || typeof dateTime === 'undefined') return dateTime;

  const dateObject = new Date(dateTime);
  const date = dateObject.toLocaleDateString('en-AU', { year: 'numeric', month: 'short', day: '2-digit' }).replace(',', '');
  if (withTime) {
    const time = dateObject.toLocaleTimeString('en-AU', { hour12: true, hour: 'numeric', minute: '2-digit' });
    return `${date}, ${time}`;
  }

  return date;
}

function isPastDate(date, withTime = false) {
  const current = new Date();
  const flagDate = new Date(extractDate(date, withTime));

  return flagDate.getTime() < current.getTime();
}


function authUSUStaff(auth) {
  const isStaff = get(auth, 'state.usu.USUStaffType', get(auth, 'USUStaffType', false));
  return isStaff && Number(isStaff) > 0;
}

/**
 * Check and return whether staff have permission for the particular use case
 *
 * Levels:
 * 0: Non USU Staff
 * 1: Staff with no club admin access
 * 2: Staff with standard club admin access
 * 3: Staff with supervisor club admin access
 * 4: Staff with USU IT access
 *
 * @param {object} auth     USU API user object
 * @param {array} requires  Level IDs to give access to
 */
function hasStaffPermissions(auth, requires = []) {
  const staffLevel = Number(get(auth, 'state.usu.USUStaffType', get(auth, 'USUStaffType', false)));
  const check = [];
  if (Array.isArray(requires)) {
    check.push(...requires);
  } else if (Number.isInteger(requires)) {
    check.push(requires);
  }
  return check.indexOf(staffLevel) > -1;
}

function checkChangedField (oldVal, draftVal, newVal)  {
  let retVal = '';
  if (draftVal) {
    if (draftVal !== newVal) {
      retVal = newVal;
    } else {
      retVal = draftVal;
    }
  } else if (oldVal !== newVal) {
    retVal = newVal;
  }
  return retVal;
};

function slugifyClubCategory(str) {
  const normalizedClubName = str
                            .toLowerCase()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '');
  return normalizedClubName;
}

function getGrantValue (requestType, club, clubMembers, clubFundTiers, values) {
  // check first if the request type is a semester grant
  let tier = undefined;
  let submitValue = 0;
  if (requestType === 'semester 1' || requestType === 'semester 2') {
    const lastYearDate = new Date();
    lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);
    const splitDate = (dateString) => {
      const dateParts = dateString.split('/');
      return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    }
    const startDate = club.acf.start_date ? splitDate(club.acf.start_date) : new Date(club.date);
    if (startDate > lastYearDate) {
      // Less than a year old
      tier = clubFundTiers.clubFundingTiers[0];
    } else {
      // Older than a year, determine by member size
      // get amount based on number of members
      tier = clubFundTiers.clubFundingTiers.find(({ min, max, value }) => {
        return clubMembers ? clubMembers.length >= (min || 0) && clubMembers.length <= max : 0;
      });
    }
  } else if (requestType === 'discretionary') {
      submitValue = values.value;
  } else if (['camp', 'welcome fest'].indexOf(requestType) > -1) {
      values.receipts.map(async receipt => {
          submitValue += Number(receipt.value);
      });
  } else {
    // TODO: add descretionary grant
    tier = clubFundTiers.nonStandardClubFundingTiers.find(
      ({ name }) => name.toLowerCase() === requestType
    );
  }
  
  return tier ? tier.value : submitValue;
};

function generateQR(data, appendURL = '') {
  return new Promise(res => {
    let value = data;
    if (typeof value === 'object') {
      value = JSON.stringify(data);
    }

    const encrypt = CryptoJS.AES.encrypt(value, process.env.USU_SITE_SALT).toString();

    QRCode.toDataURL(`${appendURL}${encrypt}`).then(response => {
      res(response);
    });
  });
}

function decodeQRData(QRData) {
  return CryptoJS.AES.decrypt(QRData, process.env.USU_SITE_SALT).toString(CryptoJS.enc.Utf8);
}

function persistLocation(location) {
  const storeKey = "__Ust";

  if ((!location || !('state' in location) || location.state === null)) {
    const storedLocation = getStorage(storeKey);
    try {
      const staleLocation = JSON.parse(CryptoJS.AES.decrypt(storedLocation, process.env.USU_SITE_SALT).toString(CryptoJS.enc.Utf8));
      return { state: staleLocation };
    } catch {
      return { state: {} };
    }
    
  }

  const encryptState = CryptoJS.AES.encrypt(JSON.stringify(location.state), process.env.USU_SITE_SALT).toString();
  setStorage(storeKey, encryptState);
  return location;
}

function dateFormat(dateString) {
  if (String(Number(dateString)).length === 8) {
    const _s = String(dateString);
    const dateYear = _s.substring(0, 4);
    const dateMonth = _s.substring(4, 6);
    const dateDate = _s.substring(6, 8);

    const _d = new Date();
    _d.setFullYear(dateYear);
    _d.setMonth(Number(dateMonth) - 1);
    _d.setDate(dateDate);

    return extractDate(_d);
  } else if (dateString !== '' && typeof dateString !== 'undefined') {
    return extractDate(dateString);
  }
};

/**
 * 
 * @param {string} date // Date should be in format of yyyyMMdd
 * @param {string} time // (Optional) Time should be in format of hh:mm a
 * @returns 
 */
function parseDateTime(date, time) {
  if (time !== '' && time !== null) {
    return parse(`${date} ${time}`, 'yyyyMMdd hh:mm a', new Date());
  } else {
    return parse(`${date}`, 'yyyyMMdd', new Date())
  }
}

export {
  trackRV,
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  validateStrongPassword,
  validatePhoneNumber,
  isNumeric,
  isObject,
  formatPrice,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  ucFirst,
  getUriFromURL,
  fetchTitle,
  dissectTitle,
  useWindowDimensions,
  useScript,
  decodeUnicode,
  rewriteShippingOptionLabel,
  dataLayerPush,
  parseXmlToJson,
  transformToEventDate,
  formatWpAddresses,
  getBase64,
  extractDate,
  checkChangedField,
  authUSUStaff,
  hasStaffPermissions,
  slugifyClubCategory,
  getGrantValue,
  generateQR,
  decodeQRData,
  formatEventDateTime,
  persistLocation,
  dateFormat,
  isPastDate,
  parseDateTime
};
