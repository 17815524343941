import React from 'react'

const AfterPayLogo = () => (
    <svg
    width={74}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 74 26'
  >
    <path
      d="M61.224 25.55H12.776C5.719 25.55 0 19.833 0 12.776 0 5.72 5.72 0 12.775 0h48.45C68.28 0 74 5.72 74 12.775c.005 7.052-5.72 12.776-12.776 12.776Z"
      fill="#B2FCE4"
    />
    <path
      d="m62.811 9.583-1.61-.92-1.633-.936c-1.079-.619-2.428.158-2.428 1.405v.21c0 .116.06.223.163.278l.758.433c.21.121.47-.032.47-.274V9.28c0-.247.265-.4.48-.28l1.488.857 1.484.851a.319.319 0 0 1 0 .554l-1.484.851-1.489.856a.32.32 0 0 1-.479-.279v-.247c0-1.246-1.35-2.028-2.428-1.405l-1.633.936-1.61.92c-1.084.624-1.084 2.192 0 2.815l1.61.921 1.633.936c1.079.618 2.428-.159 2.428-1.405v-.21a.315.315 0 0 0-.163-.279l-.758-.432a.314.314 0 0 0-.47.274v.498a.32.32 0 0 1-.48.279l-1.488-.856-1.484-.851a.319.319 0 0 1 0-.554l1.484-.851 1.489-.857a.32.32 0 0 1 .479.28v.246c0 1.247 1.35 2.029 2.428 1.405l1.633-.935 1.61-.921c1.084-.628 1.084-2.191 0-2.815ZM52.25 9.89l-3.768 7.784h-1.563l1.41-2.908-2.22-4.876h1.605l1.424 3.266 1.554-3.266h1.558Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.586 14.7v.717h1.359V9.89h-1.359v.698a2.21 2.21 0 0 0-1.712-.795c-1.503 0-2.698 1.237-2.698 2.86 0 1.638 1.149 2.853 2.665 2.853.726 0 1.336-.307 1.745-.805Zm-1.517-3.628c.829 0 1.503.651 1.503 1.582 0 .93-.674 1.582-1.502 1.582-.829 0-1.503-.675-1.503-1.582 0-.917.674-1.582 1.502-1.582Z"
      fill="#000"
    />
    <path
      d="M22.55 14.19c-.475 0-.61-.178-.61-.643v-2.452h.875V9.89h-.875V8.541h-1.391v1.35h-1.796v-.55c0-.465.177-.642.665-.642h.308V7.63h-.67c-1.15 0-1.694.377-1.694 1.526v.74h-.772v1.2h.772v4.322h1.391v-4.322h1.796v2.708c0 1.126.433 1.614 1.559 1.614h.716V14.19h-.275Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2 14.3c-.745 0-1.331-.465-1.429-1.283h4.238a1.75 1.75 0 0 0 .033-.354c0-1.66-1.196-2.875-2.843-2.875-1.637 0-2.875 1.205-2.875 2.875s1.173 2.843 2.843 2.843c1.381 0 2.433-.75 2.763-1.922h-1.428c-.265.442-.716.717-1.303.717Zm-.024-3.293c.689 0 1.275.433 1.373 1.149h-2.764c.14-.73.707-1.15 1.391-1.15ZM33.52 9.89v7.779h1.4V14.76c.4.466.972.74 1.67.74 1.503 0 2.698-1.237 2.698-2.861s-1.172-2.852-2.665-2.852c-.726 0-1.336.312-1.745.819V9.89H33.52Zm2.875 1.182c.828 0 1.502.689 1.502 1.582 0 .898-.674 1.582-1.502 1.582-.829 0-1.503-.675-1.503-1.582 0-.917.674-1.582 1.503-1.582ZM44.203 14.7v.717h1.359V9.89h-1.359v.698a2.21 2.21 0 0 0-1.712-.795c-1.502 0-2.698 1.237-2.698 2.86 0 1.638 1.15 2.853 2.666 2.853.725 0 1.335-.307 1.744-.805Zm-1.516-3.628c.828 0 1.502.651 1.502 1.582 0 .93-.674 1.582-1.502 1.582s-1.503-.675-1.503-1.582c0-.917.675-1.582 1.503-1.582Z"
      fill="#000"
    />
    <path
      d="M31.07 10.43s.344-.642 1.196-.642c.363 0 .595.126.595.126v1.41s-.511-.317-.981-.252-.768.493-.768 1.07v3.27h-1.405V9.89h1.358v.54h.005Z"
      fill="#000"
    />
  </svg>
);

export default AfterPayLogo;