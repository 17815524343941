import React, { createContext, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { renderToString } from 'react-dom/server';
import GraphQLContext from './GraphQLProvider';
import { bcApi } from '../helpers/bigcommerce';
import {
  registerAccount,
  sms2FA,
  email2FA,
  validate2FA,
  appLogin,
  initLogin,
  validateLogin,
  confirmPassword,
} from '../helpers/auth';
import {
  removeStorage,
  getStorage,
  setStorage,
  infoStorage,
} from '../helpers/general';
import OrttoEmailTemplate from '../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate';

const AuthContext = createContext();

const initialState = {
  userChecked: false,
  isLoggedIn: false,
  customerId: 0,
  email: '',
  support: {},
};

export const AuthProvider = ({ children }) => {
  const graphqlCxt = useContext(GraphQLContext);
  const graphqlQuery = graphqlCxt && graphqlCxt.query;
  const [state, setState] = useState(initialState);

  const customerGroupData = useStaticQuery(graphql`
    query {
      allBigCommerceCustomerGroups {
        edges {
          node {
            name
            id: bigcommerce_id
            discount_rules {
              amount
              method
              type
              category_id
            }
            is_default
            is_group_for_guests
          }
        }
      }
    }
  `);
  const customerGroups = {};
  customerGroupData?.allBigCommerceCustomerGroups.edges.map(group => {
    customerGroups[group.node.id] = group.node;

    return true;
  });

  const addSupport = (key, value) => {
    const support = state.support;
    if (!(key in support)) {
      support[key] = value;
      setState({ ...state, support });
    }
  };

  const checkLoggedIn = () => {
    const sessionData = infoStorage('_loggedIn');
    if (sessionData) {
      validateLogin(sessionData)
        .then(response => {
          if (
            String(response.status).startsWith('2') &&
            response.response.token
          ) {
            setStorage(
              '_loggedIn',
              response.response.token,
              sessionData.storage === 'sessionStorage' ? true : false
            );

            setState({
              ...state,
              ...response.response.object,
            });
            // resolve(response)
          } else {
            clearStorage();
            setState({ ...state, userChecked: true, onFetchLogin: false });
          }
        })
        .catch(error => {
          console.log(error);
          clearStorage();
          setState({ ...state, userChecked: true, onFetchLogin: false });
        });
    } else {
      clearStorage();
      setState({ ...state, userChecked: true, onFetchLogin: false });
    }
  };

  // eslint-disable-next-line
  useEffect(() => checkLoggedIn(), []);

  const refreshData = () => {
    if (state.isLoggedIn) {
      // fetchLogin(state.customerId);
      checkLoggedIn();
    }
  };

  const afterAuth = async customerId => {
    const afterAuthCalls = getStorage('_afterAuth');
    let actionResult = false;

    if (afterAuthCalls) {
      const afterAuthCallsObject = JSON.parse(afterAuthCalls);
      if (afterAuthCallsObject.action === 'saveWishlist') {
        // console.log("_afterAuth should be removed");
        removeStorage('_afterAuth');
        actionResult = await state.support.wishlist.saveWishlist(
          afterAuthCallsObject.name,
          true,
          customerId
        );
      }
    }

    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      !actionResult
    ) {
      // console.log("Redirecting normally");
      const browserLastPage = !document.referrer.startsWith(
        process.env.SITE_URL
      )
        ? process.env.SITE_URL
        : document.referrer.indexOf('logout')
        ? process.env.SITE_URL
        : document.referrer;
      const userLastPage = getStorage('lastPage');
      const forcedPage = getStorage('forcedPage');
      const returnUrl = forcedPage || userLastPage || browserLastPage;
      removeStorage('forcedPage');
      window.location = returnUrl;
    }

    return actionResult;
  };

  const logout = () => {
    // TODO: Update to work with more secure process
    setState(initialState);
    clearStorage(true);

    bcApi('carts').then(async ({ response }) => {
      if (response && 'data' in response && 'id' in response.data) {
        await bcApi(`carts/${response.data.id}`, 'PUT', { customer_id: 0 });
      }
      graphqlQuery(`
          mutation Logout {
            logout {
              result
            }
          }
        `).then(response => {
        if (typeof window !== 'undefined') {
          window.location = '/';
        }
      });
    });
  };

  const clearStorage = fullClear => {
    removeStorage('_loggedIn');
    removeStorage('_isWholesale');
    removeStorage('_isPending');
    if (fullClear) {
      removeStorage('__jammcd');
    }
  };

  const emailCheck = (email, password) => {
    return new Promise((res, rej) => {
      const template = renderToString(
        <OrttoEmailTemplate
          content={`
          <p>Hi ##FIRSTNAME##,</p>
          <p>You are receiving this email as we have received a request to login to your account. If this was made by you, please use the code below to enter into the site.
          <p style="font-size: 20px;"><strong>##CODE##</strong></p>
          <p>If you did not make this request, please contact USU.</p>
      `}
        />
      );
      email2FA(email, password, template)
        .then(response => {
          if (String(response.status).startsWith('2')) {
            setState({
              ...state,
              ...response.response.object,
            });

            res(response);
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  };

  const smsCheck = (email, password) => {
    return new Promise((res, rej) => {
      sms2FA(email, password)
        .then(response => {
          // console.log('SMS CHECK RESPONSE', response);

          if (String(response.status).startsWith('2')) {
            setState({
              ...state,
              ...response.response.object,
            });

            res(response);
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  };

  const validateCode = (code, token) => {
    return new Promise((res, rej) => {
      validate2FA(code, token)
        .then(response => {
          // console.log('VALIDATE CODE RESPONSE', response);

          if (String(response.status).startsWith('2')) {
            setState({
              ...state,
              ...response.response.object,
            });

            res(response);
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  }

  const checkPassword = (email, password) => {
    return new Promise((res, rej) => {
      confirmPassword(email, password)
        .then(response => {
          res(response);
        })
        .catch(e => {
          rej(e);
        });
    });
  };

  const appAuth = token => {
    return new Promise((res, rej) => {
      appLogin(token)
        .then(async response => {
          // console.log("initLogin response", response);
          if (
            String(response.status).startsWith('2') &&
            response.response.token
          ) {
            setStorage('_loggedIn', response.response.token);

            setState({
              ...state,
              ...response.response.object,
            });

            res(response);
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  };

  const login = (email, password, remember) => {
    return new Promise((res, rej) => {
      initLogin(email, password)
        .then(async response => {
          // console.log("initLogin response", response);
          if (
            String(response.status).startsWith('2') &&
            response.response.token
          ) {
            setStorage(
              '_loggedIn',
              response.response.token,
              remember ? false : true
            );

            setState({
              ...state,
              ...response.response.object,
            });

            await afterAuth(response.response.object.customerId);

            res(response);
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  };

  const signup = usuFields => {
    return new Promise((res, rej) => {
      registerAccount(usuFields)
        .then(async response => {
          if (String(response.status).startsWith('2')) {
            if (!usuFields.cart && response.response.token) {
              // Log user in as its a free account
              setStorage('_loggedIn', response.response.token);

              setState({
                ...state,
                ...response.response.object,
              });

              await afterAuth(response.response.object.customerId);

              res(response);
            } else {
              res(response);
            }
          } else {
            rej(response);
          }
        })
        .catch(e => rej(e));
    });
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        customerGroups,
        setState,
        addSupport,
        refreshData,
        smsCheck,
        emailCheck,
        validateCode,
        appAuth,
        login,
        logout,
        signup,
        checkPassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
