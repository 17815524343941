import React from 'react';

import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import { returnSrc } from '../../../helpers/contentful';

import * as styles from './ContentfulBlockDual.module.css';

const ContentfulBlockDual = ({content, image, backgroundColor}) => {

  const srcImage = returnSrc(image);

  return (
    <div className={ styles.ContentfulBlockDual }>
      <div className={styles.contentContainer} style={{backgroundColor: backgroundColor?.hexCode}}>
        <div className={styles.textContainer}>
          <ContentfulRichText raw={content.raw}></ContentfulRichText>
        </div>
      </div>
      <div className={styles.imageContainer} style={{backgroundImage: `url("${srcImage}?w=1280&h=550&fit=fill")`}} />
    </div>
  );
};

export default ContentfulBlockDual;
