import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import * as styles from './ContentfulAssetHyperlink.module.css';

const ContentfulAssetHyperlink = props => {

  const { node, children } = props;
  
  const contentful_id = node?.data?.target?.sys?.id;
  const assetData = useStaticQuery(graphql`
    query {
        allContentfulAsset {
            nodes {
              id: contentful_id
              title
              file {
                url
                contentType
              }
            }
        }
    }
  `)

  const assets = assetData?.allContentfulAsset.nodes;
  const assetObj = assets.find(asset => asset.id === contentful_id);
  
  return (
    <a rel="noreferrer" target="_blank" href={`https:${assetObj.file.url}`}>
      {children}
    </a>
  );
};

export default ContentfulAssetHyperlink;
