import React from 'react';
import * as styles from './Toggle.module.css';

const Toggle = ({ label, id, checked, onChange }) => {
  return (
    <div className={styles.container}>
      <div className={styles.toggleSwitch}>
        <input
          type='checkbox'
          className={styles.checkbox}
          name={label}
          id={id}
          checked={checked}
          onChange={onChange}
        />
        <label className={styles.label} htmlFor={id}>
          <span className={styles.inner} />
          <span className={styles.switchItem} />
        </label>
      </div>

      {label && <span className={styles.text}>{label}</span>}
    </div>
  );
};

export default Toggle;

