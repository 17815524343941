import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useLocation } from '@reach/router';
import { graphql, Link, navigate } from 'gatsby'
import { debounce } from 'lodash';

import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Icon from '../components/atoms/Icon/Icon';
import Seo from '../components/organisms/Seo/Seo';

// import { freshdeskApi } from '../helpers/freshdesk';
import AlgoliaArticleSearch from '../components/molecules/Search/searches/AlgoliaArticleSearch';

import * as styles from './faqs.module.css';
import Button from '../components/atoms/Button/Button';
import Loader from '../components/atoms/Loader/Loader';
import { slugifyClubCategory } from '../helpers/general';

const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');

const FaqPage = ({data}) => {
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState();
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchCount, setSearchCount] = useState(0);

    const [categoryList, setCategoryList] = useState([]);
    const [currentCategory, setCurrentCategory] = useState();
    const [folderList, setFolderList] = useState([])

    useEffect(() => {
        if (urlParams && typeof window !== 'undefined') {
            const getCategory = urlParams.get('category');
            const selected = categoryList.filter((a) => slugifyClubCategory(a.name) === getCategory)[0];
            if (selected) {
                setCurrentCategory(selected);
            }

            const folder = urlParams.get('folder');
            if (folder) {
                const element = document.getElementById(folder);
                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - 120;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }
        }
    }, [categoryList])
    // eslint-disable-next-line
    const handler = useCallback(debounce(async (search) => {
        if(search === '') {
            setSearchResults(undefined);
            return
        };
        setSearchLoading(true);
        const searchResults = await AlgoliaArticleSearch(search);
        setSearchLoading(false);
        if(searchResults) {
            const searchHits = searchResults.hits.filter((h) => h.status === 2);
            const groups = searchHits.reduce((groups, article) => {
                const group = (groups[article.category_name] || []);
                const slugTitle = article.title.toLowerCase().replace(/ /g,'-').replace(/[-]+/g, '-').replace(/[^\w-]+/g,'');
                const tempArticle = {...article, url: slugTitle}
                group.push(tempArticle);
                groups[article.category_name] = group;
                return groups;
              }, {});
            setSearchCount(searchHits.length);
            setSearchResults(groups);
        } else {
            setSearchCount(0);
            setSearchResults(undefined);
        }
      }, 1500), []);

    useMemo(() => {
        if(categoryList !== []) {
            const fdCategories = (data && data.allFreshDeskCategories.nodes) || [];
            fdCategories.sort((a, b) => a.name === 'Membership' ? -1 : 1) // Custom sort
            setCurrentCategory(fdCategories[0]);
            setCategoryList(fdCategories);

        }
    }, [categoryList, data]);

    const handleChangeCategory = (e) => {
        // setCurrentCategory(categoryList[e.target.value]);
        urlParams.set('category', slugifyClubCategory(categoryList[e.target.value].name));
        urlParams.delete('folder');
        navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    }

    useEffect(() => {
        const filteredFolder = data.allFreshDeskFolders.nodes.filter((folder) => folder.category_id === currentCategory.category_id);
        
        // aggregate articles
        const transformedFolder = filteredFolder.map((folder) => {
            const articleFolders = data.allFreshDeskArticles.nodes.filter((article) => article.status === 2 && article.folder_id === folder.folder_id);
            return {
                ...folder,
                articles: articleFolders
            }
        })
        setFolderList(transformedFolder);

    }, [currentCategory, data])

    return ( 
            <Layout>
                <Seo title="FAQs" />
                <div className={styles.root}>
                <Container size={'medium'}>
                    <div className={styles.searchContainer}>
                        <h3 className={styles.searchTitle}>FAQ</h3>
                        <div className={styles.searchTermContainer}>
                            <input 
                                type="text" 
                                id="searchTerm" 
                                name="searchTerm" 
                                placeholder='Search'
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    handler(e.target.value);
                                }}
                                value={searchTerm} />
                            <div className={styles.searchIconContainer}>
                                <Icon symbol={'search'}></Icon>
                            </div>
                        </div>

                    </div>
                    {searchLoading === true &&
                    <div className={styles.loaderContainer}>
                        <Loader/>
                    </div> }
   
                    {searchResults === undefined && 
                    <div className={styles.mainContainer}>
                        <div className={styles.categoryControlContainer}>
                            <span className={styles.labelCategory}>Select a category:</span>
                            <div className={styles.selectContainer}>    
                                <select onChange={handleChangeCategory}>
                                {categoryList.length !== 0 && 
                                    categoryList.map((category, cIndex) => 
                                    <option
                                        key={category.category_id} 
                                        value={cIndex}
                                        selected={currentCategory && category.category_id === currentCategory.category_id ? true : false}>
                                        {category.name}
                                    </option>)
                                }
                                </select>
                                <Icon symbol={'caret'}></Icon>
                            </div>
                        </div>

                        <div className={styles.folderGrid}>
                            {folderList.map((folder) => {
                                return(
                                    <div key={folder.id} id={slugifyClubCategory(folder.name)}>
                                        <h4 className={styles.folderName}>{folder.name}</h4>
                                        <div className={styles.articleListContainer}>
                                            {folder?.articles?.map((article) => <Link key={article.id} className={styles.articleLink} to={`/article/${article.url}`}>{article.title}</Link>)}
                                            {folder?.articles.length === 0 && <span>No articles under this folder</span>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {folderList?.length === 0 && <span>No folders under this category</span>}
                    </div>
                    }

                    {searchResults && 
                    <div className={styles.searchResultsContainer}>
                        <div className={styles.searchControl}>
                            <span className={styles.searchLabel}>Search Results: {searchCount} articles found</span>
                            <Button type={'button'} onClick={() => {
                                setSearchTerm('');
                                setSearchResults(undefined);
                            }} size={'noWidth'} level={'primary'}>
                                Clear Search
                            </Button>
                        </div>
                        <div className={styles.folderGrid}>
                            {Object.keys(searchResults).map((key, index) => {
                                return(
                                    <div key={index}>
                                        <h4 className={styles.folderName}>{key}</h4>
                                        <div className={styles.articleListContainer}>
                                            {searchResults[key].map((article) => <Link key={article.id} className={styles.articleLink} to={`/article/${article.url}`}>{article.title}</Link>)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    }

                </Container>
                </div>
            </Layout>
    );
}
 
export default FaqPage;

export const pageQuery = graphql`
  query FDCategoriesPage {
    allFreshDeskCategories {
        nodes {
        category_id
        id
        name
        }
    }
    allFreshDeskArticles {
        nodes {
            article_id
            category_id
            id
            folder_id
            tags
            title
            url
            status
        }
    }
    allFreshDeskFolders {
        nodes {
            name
            id
            category_id
            folder_id
        }
    }
  }
  
`;