import React, { useState, useContext, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import { sendResetInstructions } from '../helpers/usuapi';
import Button from '../components/atoms/Button/Button';
import Dialog from '../components/atoms/Dialog/Dialog';
import { getStorage } from '../helpers/general';

import * as styles from './login.module.css';
import { success } from '../helpers/toast';

const validForm = mobile => {
  if (!mobile) {
    return 'Mobile number is required.';
  }
  // if (!validateEmail(email)) {
  //   return 'Invalid email address.';
  // }
};

const ForgotPasswordPage = () => {
  const auth = useContext(AuthContext);
  // const handleForgotPassword = auth && auth.forgotPassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [mobile, setMobile] = useState('');
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState({});

  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/account/`);
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const clearDialog = () => {
    setDialogOpen(false);
    navigate(`/login/`);
  };

  const attemptForgotPassword = (e) => {
    e.preventDefault();
    const validationMsg = validForm(mobile);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }

    setLoading(true);
    sendResetInstructions(mobile).then((response) => {
      if(response.status === 200) {
        setLoading(false);
        success('SMS Sent');
        setMobile('');
        setMessage({
          ...msg,
          error: '',
        })
      }
    })

    // handle sms notification - will be coming from usu api

    // handleForgotPassword(mobile).then(result => {
    //   // This returns true regardless as we don't confidently get anything back from BC yet.
    //   if (result) {
    //     setMessage({
    //       dialogSuccess:
    //         'If your email address exists in the system, you will receive an email with instructions to reset your password.'
    //     });
    //     setDialogOpen(true);
    //   } else {
    //     setMessage({
    //       dialogError: 'Sorry, something went wrong. Try again later.'
    //     });
    //     setDialogOpen(true);
    //   }
    // });
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <Seo title="Reset Password" />
        <Container>
          <div className={`grid grid-50 ${styles.forgotContainer}`}>
            <div className={`${styles.createAccount} ${styles.formContainer}`}>
                <div>
                  <h6>Forgotten your login details?</h6>
                  <p>If you've forgotten your login details, please enter your mobile number below to receive your USU number via SMS.</p>
                </div>
                <div className={styles.smsContainer}>
                  <form noValidate onSubmit={(e) => attemptForgotPassword(e)}>
                      <div className={`formField ${styles.forgotPasswordInput}`}>
                          <label className={styles.formFieldLabel} htmlFor="mobile">Mobile Number</label>
                          <input 
                            type="number" 
                            name="mobile" 
                            value={mobile} 
                            placeholder="Mobile Number"
                            onChange={(e) => setMobile(e.target.value)} 
                          />
                          {msg.error && <span className={styles.forgotError}>{msg.error}</span>}
                      </div>
                      <Button 
                        fullWidth 
                        disabled={loading} 
                        level="primary" 
                        type="buttonSubmit">
                          {loading ? 'Requesting' : 'Send'}
                      </Button>
                      <span className={styles.forgotReminder}>
                        If you don't receive an SMS, please contact us <Link to={'/contact'}>here</Link>.
                      </span>
                  </form>
                  <div className={styles.forgotPasswordLinkDiv}>
                    <Link to="/login/">Back to Login</Link>
                  </div>
                </div>
            </div>
            <div className={`${styles.createAccount} ${styles.flexContainer}`}>
              <div>
                <h6>Not a USU member?</h6>
                <p>Create an account with us and you'll be able to:</p>
                <ul className="bullets">
                    <li>Check out faster</li>
                    <li>Save multiple shipping addresses</li>
                    <li>Access your order history</li>
                    <li>Track new orders</li>
                    {/* <li>Save items to your wishlist</li> */}
                </ul>
                </div>
                <div className={styles.joinButtonContainer}>
                  <Button href="/join/" level="primary">Join Now</Button>
                </div>
            </div>
          </div>

          <Dialog
            open={dialogOpen}
            title="Reset request made"
            size="sm"
            hideBtnCancel
            disableBackdropClick
            onOk={() => clearDialog()}
          >
            {msg.dialogSuccess && (
              <p>{msg.dialogSuccess}</p>
            )}
            {msg.dialogError && (
              <p>{msg.dialogError}</p>
            )}
          </Dialog>
        </Container>
      </Layout>
    );
  } else return null
};

export default ForgotPasswordPage;
