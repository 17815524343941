import React from 'react'

const Upload = () => (
    <svg width={22} height={16} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 22 16'>
    <defs>
      <clipPath id="a">
        <path d="M1600 0v850H0V0h1600Z" />
      </clipPath>
      <clipPath id="b">
        <path d="M11.05 0c1.883 0 3.462.68 4.737 2.038C17.063 3.396 17.7 5.024 17.7 6.925v.6c1.2-.033 2.217.354 3.05 1.162.833.809 1.25 1.83 1.25 3.063 0 1.15-.417 2.142-1.25 2.975-.833.833-1.825 1.25-2.975 1.25H11.75c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-6.45L8.175 10.1 7.1 9.025l3.9-3.9 3.9 3.9-1.075 1.075-2.075-2.075v6.45h6.025c.75 0 1.392-.267 1.925-.8.533-.533.8-1.175.8-1.925s-.267-1.392-.8-1.925a2.624 2.624 0 0 0-1.925-.8H16.2v-2.1c0-1.483-.504-2.758-1.512-3.825-1.009-1.067-2.255-1.6-3.738-1.6s-2.733.533-3.75 1.6C6.183 4.167 5.675 5.442 5.675 6.925H5.2c-1.033 0-1.908.362-2.625 1.087C1.858 8.738 1.5 9.633 1.5 10.7c0 1.033.367 1.92 1.1 2.663.733.741 1.617 1.112 2.65 1.112h3.5v1.5h-3.5c-1.433 0-2.667-.517-3.7-1.55C.517 13.392 0 12.158 0 10.725c0-1.3.412-2.446 1.238-3.438C2.063 6.296 3.125 5.7 4.425 5.5c.333-1.617 1.117-2.938 2.35-3.962C8.008.512 9.433 0 11.05 0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-1272 -278)">
      <g clipPath="url(#b)" transform="translate(1272 278)">
        <path d="M0 0h22v15.975H0V0z" />
      </g>
    </g>
  </svg>
)

export default Upload