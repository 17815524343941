import React from 'react';

import Brand from "../../atoms/Brand/Brand";
import Search from '../../molecules/Search/Search';

import * as styles from './SearchOverlay.module.css';

const SearchOverlay = props => {

  const {showing, close} = props;

  return (
    <div className={`${styles.SearchOverlay} ${showing === true ? styles.showSearch : styles.hideSearch}`}>
      <div className={styles.searchHeaderContainer}>
        <Brand size='md'/>
        <span role={'presentation'} onClick={close} className={styles.close}>close</span>
      </div>
      <Search 
        showing={showing} 
        close={close} 
      />
    </div>
  );
};

export default SearchOverlay;
