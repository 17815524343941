import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';
import Button from '../../atoms/Button/Button';
import * as styles from './AddressCard.module.css';

const AddressCard = ({ address, onEdit, onDelete, onAddAddress, responsiveButton = false, active}) => {
  const handleClickEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit();
    }
  };

  const handleClickRemove = () => {
    if (typeof onDelete === 'function') {
      onDelete();
    }
  };

  if(active !== null && isEmpty(address)) {
    return <></>
  }

  if (isEmpty(address)) {
    return (
      <div
        className={`flex-left ${ styles.mobileButton }`}
        role="presentation"
      >
        <Button 
          type="button"
          className={'smallButton'}
          onClick={onAddAddress}
          level="primary">
          New address
        </Button>
      </div>
    );
  }

  return (
    <div className={`pt-4 ${styles.root}`}>
      <div className={styles.addressContent}>
        <div className={styles.fullName}>
          {address.first_name} {address.last_name}
        </div>
        {address.phone && (
          <div className={styles.addressProperty}>{address.phone}</div>
        )}
        {/* {address.company && (
          <div className={styles.addressProperty}>{address.company}</div>
        )} */}
        <div className={styles.addressProperty}>{address.address1}</div>
        {address.address2 && (
          <div className={styles.addressProperty}>{address.address2}</div>
        )}
        <div className={styles.addressProperty}>
          {address.city}, {address.state_or_province}, {address.postal_code}
        </div>
        <div className={styles.addressProperty}>{address.country}</div>
      </div>

      <div className={styles.addressAction}>
        <span
          role="presentation"
          data-button
          className="icon-wrap"
          onClick={handleClickEdit}
        >
          Edit
        </span>
        <ConfirmDialog
          title="Are you sure?"
          message="Are you sure you want to remove the selected address? This cannot be undone."
          onOk={handleClickRemove}
        >
          <span data-button className="icon-wrap">
            Delete
          </span>
        </ConfirmDialog>
      </div>
    </div>
  );
};

AddressCard.propTypes = {
  address: PropTypes.shape({}),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddAddress: PropTypes.func,
  responsiveButton : PropTypes.bool
};

AddressCard.defaultProps = {
  address: {},
  onEdit: () => null,
  onAddAddress: () => null,
  onDelete: () => null,
  responsiveButton: false
};

export default AddressCard;
