import React, { useContext } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import CartContext from '../../../context/CartProvider';
import CartSummary from '../../molecules/CartSummary/CartSummary';
import CartHeader from './components/CartHeader';
import CustomItems from './components/CustomItems';
import StandardItems from './components/StandardItems';
import GiftCertificateItems from './components/GiftCertificateItems';

import * as styles from './Cart.module.css';
import Loader from '../../atoms/Loader/Loader';

// TODO: Update buttons to atom component
// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const Cart = ({ cartType, onClick }) => {
  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const { state, removeItemFromCart, updateCartItemQuantity } = ctx;
  const { currency, lineItems, productInfo, numberItems } = state.cart;
  const { updatingItem, cartLoading } = state;

  // if (cartLoading) {
  //   return (
  //     <div className="bc-cart__empty">
  //       <h2 className="bc-cart__title--empty">
  //         <em>Loading Cart</em>
  //       </h2>
  //     </div>
  //   );
  // }

  if (!numberItems) {
    return (
      <div className="text-center">
        <p className="subtitle small text-center">Your cart is empty.</p>
        <Link to="/shop" className={styles.bcCartLink}>
          Take a look around.
        </Link>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        cartType === 'full' && styles.bcCartContainer,
        cartType === 'mini' && styles.bcMiniCartContainer,
        styles.cartContainer
      )}
    >
      <div className="bc-cart-error">
        <p className="bc-cart-error__message"></p>
      </div>

      {cartType === 'full' && <CartHeader />}
      <div className={clsx(cartType === 'mini' && styles.bcMiniCartContent)}>
        <div className={`bc-cart-list ${cartType === 'full' ? styles.cartListContainerFull: ''}`} data-cartitemlist>
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.physical_items}
            productInfo={productInfo}
            cartType={cartType}
          />
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.digital_items}
            productInfo={productInfo}
            cartType={cartType}
          />
          <CustomItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.custom_items}
            productInfo={productInfo}
            cartType={cartType}
          />
          <GiftCertificateItems
            currency={currency}
            updatingItem={updatingItem}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.gift_certificates}
            productInfo={productInfo}
            cartType={cartType}
          />
        </div>
        {cartType === 'mini' && <CartSummary cartType={cartType} onClick={onClick} />}
      </div>
      {cartLoading && 
      <>
        <div className={styles.backdropCart}/>
        <div className={styles.loadingContainer}>
          <Loader/>
        </div>
      </>
      }
    </div>
  );
};

export default Cart;
