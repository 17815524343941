// extracted by mini-css-extract-plugin
export var block = "AfterPayInfo-module--block--8a292";
export var blockMessage = "AfterPayInfo-module--blockMessage--eddb5";
export var blockSubtitle = "AfterPayInfo-module--blockSubtitle--12619";
export var body = "AfterPayInfo-module--body--0e7de";
export var bottomLine = "AfterPayInfo-module--bottomLine--43a53";
export var footer = "AfterPayInfo-module--footer--e9a8f";
export var header = "AfterPayInfo-module--header--059be";
export var imgContainer = "AfterPayInfo-module--imgContainer--2f29a";
export var intro = "AfterPayInfo-module--intro--238b2";
export var modal = "AfterPayInfo-module--modal--18237";
export var svgSection = "AfterPayInfo-module--svgSection--49cdd";
export var titles = "AfterPayInfo-module--titles--23471";