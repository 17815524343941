import React from 'react';
import * as styles from './ContentfulBanner.module.css';

import SmartLink from '../../atoms/SmartLink/SmartLink';

const ContentfulBanner = props => {

  const { label, cta } = props;

  return (
    <div className={ styles.ContentfulBanner }>
      <span className={styles.label}>{label}</span>
      <SmartLink menuLink={cta} linkStyle={styles.link} />
    </div>
  );
};

export default ContentfulBanner;
