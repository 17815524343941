import React, { useEffect, useState, useRef, useContext } from 'react';
import { has, isEqual } from 'lodash';
import Container from '../../atoms/Container/Container';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Price from '../../molecules/Price/Price';
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Bnpl from '../../atoms/BNPL/BNPL';
import Flag from '../../atoms/Flag/Flag';

import AuthContext from '../../../context/AuthProvider';
import { formatPrice } from '../../../helpers/general';
import StockStatus from '../../molecules/StockStatus/StockStatus';
import * as styles from './ProductDetails.module.css';
import SwatchList from '../SwatchList/SwatchList';
import SizeList from '../SizeList/SizeList';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import CartContext from '../../../context/CartProvider';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    padding: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    fontFamily: 'National',
  },
  arrow: {
    '&:before': {
      border: '1px solid #fff',
    },
    color: '#fff',
  },
}))(Tooltip);

const ProductDetails = props => {
  const { product, stickyInfo } = props;
  const [quantity, setQuantity] = useState(1);
  const [priceObj, setPriceObj] = useState(product || {});
  const [inStock, setInStock] = useState({});
  const [variantSpecs, setVariantSpecs] = useState([]);
  const [activeVariant, setActiveVariant] = useState(false);
  const [variantOutOfStock, setVariantOutOfStock] = useState(false);
  const [pageInit, setPageInit] = useState(false);

  const auth = useContext(AuthContext);
  const rewardsGroup = (auth && Object.values(auth?.customerGroups).find(g => g.name === 'Rewards members'))
  const hasMemberDiscount = (rewardsGroup && rewardsGroup.discount_rules.find(d => {
    return product.categories.find(c => Number(c.id) === Number(d.category_id))
  })) || false;

  // const isMember = (auth && auth.state?.customerGroup === 'Rewards members') ? true : false;

  const cart = useContext(CartContext);
  const isValidGradProductToCart = cart && cart.isValidGradProductToCart;  

  const shippingOptionsAnchor = useRef();
  useEffect(() => {
    if (
      product &&
      typeof product.inventory_tracking !== 'undefined' &&
      typeof product.inventory_level !== 'undefined' &&
      typeof product.inventory_warning_level !== 'undefined' &&
      product.inventory_tracking === 'product' &&
      product.inventory_level < 1
    ) {
      console.log('No inventory found', product);
      setQuantity(0);
    }

    // Set active variant
    if (product && product.variants.length > 0 && !activeVariant) {
      setActiveVariant(product.variants[0].entityId);
      setVariantSpecs(product.variants[0].option_values);
    } else if (product && product.variants.length === 0 && !activeVariant) {
      setActiveVariant(true);
    }

    // Page init functions
    if (!pageInit) {
      setPageInit(true);
      // Open first description accordion at page load
      const description = document.querySelector('[data-description]');
      if (description) {
        const h4s = description.querySelectorAll('h4');
        if (h4s.length > 0) {
          h4s[0].classList.toggle('open');
          let nextElement = h4s[0].nextElementSibling;
          while (nextElement && !nextElement.matches('h4')) {
            nextElement.classList.toggle(styles.active);
            nextElement = nextElement.nextElementSibling;
          }
        }
      }
    }
  }, [product, pageInit, activeVariant]);

  const handleVariantChange = (id, tempVariant) => {
    const newVariantSpecs = variantSpecs.map(variant => {
      if (id === variant.option_id) {
        return {
          id: tempVariant.id,
          label: tempVariant.label,
          option_display_name: variant.option_display_name,
          option_id: variant.option_id,
        };
      } else {
        return variant;
      }
    });

    setVariantSpecs(newVariantSpecs);
  };
  const handleSizeChange = (id, selectedVariant) => {
    if (selectedVariant) {
      setVariantOutOfStock(false);
      let tempVariant = selectedVariant.split('|');
      const newVariantSpecs = variantSpecs.map(variant => {
        if (id === variant.option_id) {
          return {
            id: Number(tempVariant[0]),
            label: tempVariant[1],
            option_display_name: variant.option_display_name,
            option_id: variant.option_id,
          };
        } else {
          return variant;
        }
      });

      const productVariantLevel = product.variants.find(variant => {
        const findVariant = variant.option_values.find(
          option => option.id === Number(tempVariant[0])
        );
        if (findVariant) return true;
        return false;
      });

      if (productVariantLevel && productVariantLevel.inventory_level === 0) {
        setVariantOutOfStock(true);
      }

      setVariantSpecs(newVariantSpecs);
    }
  };

  // need to be in tune with rate; same with the <Price /> component
  const calculateRewardsPrice = () => {
    const productPrice = priceObj?.sale_price
      ? priceObj?.sale_price
      : priceObj?.price
      ? priceObj?.price
      : priceObj?.calculated_price;

    if (hasMemberDiscount) {
      if (hasMemberDiscount.method === 'percent') {
        const perc = (100 - Number(hasMemberDiscount.amount)) / 100;
        
        return formatPrice(productPrice * perc);
      }
      // TODO: If a different method is ever set, we'll need to review and account for it
      return formatPrice(productPrice);
    }
    return formatPrice(productPrice);
  };

  const onStatusUpdate = _status => {
    setInStock(_status);
  };

  useEffect(() => {
    const tempActiveVariant = product.variants.filter(variant =>
      isEqual(variant.option_values, variantSpecs)
    );

    if (tempActiveVariant !== undefined && tempActiveVariant?.length !== 0) {
      setActiveVariant(tempActiveVariant[0].entityId);
    }
  }, [variantSpecs, product.variants]);

  useEffect(() => {
    if (product?.variants?.length !== 0) {
      const variant = product.variants.filter(
        v => activeVariant === v.entityId
      );

      if (variant.length !== 1) return;

      const newPriceObj = {
        ...priceObj,
        sale_price: variant[0].sale_price,
        price: variant[0].price,
        calculated_price: variant[0].calculated_price,
      };
      setPriceObj(newPriceObj);
    }
    // eslint-disable-next-line
  }, [activeVariant]);

  const rewardsPrice = calculateRewardsPrice();

  return (
    <div className={styles.productDetails}>
      {/* Tag Locations */}
      <div>
        { (priceObj.sale_price === null || 
          priceObj.sale_price === 0 || 
          priceObj.sale_price === undefined) || (
          <Flag
            title={'on sale'}
            color={'var(--standard-white)'}
            bgColor={'var(--sale)'}
          />
        )}
        {/* Future tags if needed */}
      </div>

      {/* Title Container */}
      <div className={styles.titleContainer}>
        <div>
          <h1 className='h3'>{product && product.name}</h1>
        </div>
      </div>

      <div className={styles.reviewInventoryCheck}>
        <div className={styles.inventoryCheckContainer}>
          <StockStatus
            key='status'
            product={product}
            onStatusUpdate={onStatusUpdate}
          />
        </div>
      </div>

      {/* Price */}
      <div className={styles.priceContainer}>
        <span className={styles.priceLabel}>RRP</span>
        <Price
          styles={styles}
          entityId={priceObj.product_id}
          price={priceObj.price}
          calculated_price={priceObj.calculated_price}
          sale_price={priceObj.sale_price}
          options={{ ...priceObj.priceOptions, hideDiscountPrice: true }}
        />
        {hasMemberDiscount && (
          <>
            <div
              className={`${styles.verticalLine} ${rewardsPrice === 0 &&
                styles.hidden}`}></div>
            <div
              className={`${styles.rewardsPriceContainer}  ${rewardsPrice === 0 &&
                styles.hidden}`}>
              <div className={styles.rewardsLabelContainer}>
                <span className={styles.rewardsLabel}>Rewards Price</span>
                <LightTooltip
                  title={
                    <span>
                      Get 10% off <br /> Join
                      <Link to='/rewards'>
                        {' '}
                        <u>USU Rewards</u>
                      </Link>{' '}
                    </span>
                  }
                  arrow
                  interactive>
                  <div>
                    <Icon symbol={'infoCircle'}></Icon>
                  </div>
                </LightTooltip>
              </div>
              <span className={styles.rewardsPrice}>
                <CurrencyFormatter amount={rewardsPrice} />
              </span>
            </div>
          </>
        )}
      </div>

      {/* Payment Options */}
      <Bnpl product={product} suppliers={['afterpay']} />

      <div key='warranty' className={styles.warranty}>
        {product.warranty}
      </div>

      {product?.options
        ?.filter(productOption => productOption.type === 'swatch')
        .map(productOption => {
          return (
            <div
              className={styles.optionContainer}
              key={`${productOption.id}-swatch`}>
              <SwatchList
                id={productOption.id}
                label={productOption.display_name}
                swatchList={productOption.option_values}
                variantSpecs={variantSpecs}
                handleVariantChange={tempVariant =>
                  handleVariantChange(productOption.id, tempVariant)
                }
              />
            </div>
          );
        })}

      {product?.options
        ?.filter(productOption => productOption.type === 'rectangles')
        .map(productOption => {
          return (
            <div
              className={styles.optionContainer}
              key={`${productOption.id}-list`}>
              <SizeList
                id={productOption.id}
                label={product.display_name}
                boxList={productOption.option_values}
                variantSpecs={variantSpecs}
                handleVariantChange={handleSizeChange}
              />
            </div>
          );
        })}

      {/* Checkout Container */}
      {product.availability === 'disabled' ? (
        <div className={styles.addToCartContainer} ref={shippingOptionsAnchor}>
          <Button level='primary' size='fullWidth' href='/contact-us/'>
            Contact us
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.checkoutContainer}>
            {cart && !isValidGradProductToCart(product) && (
              <div>
                <MessageAlert type="warn">
                 An item in your cart restricts this product from adding it into your cart, <Link to="/confirm-order"><u>Go to cart</u></Link>.
                </MessageAlert>
              </div>
            )}
            <div className={styles.checkoutContent}>
              {/* Add to Cart Button */}
              <div className={styles.inputQtyContainer}>
                <input
                  value={quantity}
                  onChange={e => setQuantity(e.target.value)}></input>
              </div>
              <div className={styles.addToCartContainer}>
                <AddToCartButton
                  fullWidth
                  productId={product.entityId}
                  product={product}
                  variantId={activeVariant}
                  // customPrice = {isMember === true ? rewardsPrice : undefined}
                  disabled={
                    inStock?.available === 'no-stock' || variantOutOfStock
                      ? true
                      : false
                  }
                  quantity={quantity}>
                  {(inStock && inStock?.available === 'no-stock') ||
                  variantOutOfStock
                    ? 'Out of Stock'
                    : inStock?.available === 'po-stock'
                    ? 'Pre-Order'
                    : 'Add to cart'}
                </AddToCartButton>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Sticky Header - Web */}
      <div
        className={`
            ${styles.stickyAddToCart}
            `}>
        <Container size='large'>
          <div
            className={`
                    ${styles.stickAddToCartContent}
                    ${stickyInfo === true ? styles.border : ''}
                    `}>
            {/* Left Side Container */}
            <div className={styles.stickyAddToCartLeft}>
              {/* Image Container */}
              <div className={styles.stickyAddToCartImageContainer}>
                <img
                  alt={'product_image_on_cart'}
                  src={
                    has(product.images, 'node')
                      ? product.images.node.url
                      : has(product.images, 'url_zoom')
                      ? product.images.url_zoom
                      : product.images.url_standard
                  }
                />
              </div>
              {/* Details Container */}
              <div className={styles.stickAddToCartDetails}>
                {priceObj && priceObj.sale_price > 0 && (
                  <span className={styles.onSaleTag}>ON SALE</span>
                )}
                <span className={styles.productTitleSticky}>
                  {product && product.name}
                </span>
                <Price
                  styles={styles}
                  entityId={priceObj.product_id}
                  price={priceObj.price}
                  calculated_price={priceObj.calculated_price}
                  sale_price={priceObj.sale_price}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProductDetails;

