import React from 'react'
import { graphql } from 'gatsby'
import BlogList from '../components/organisms/BlogList/BlogList'

const Category = props => {
  const { data, pageContext } = props
  // const { title: siteTitle } = data.site.siteMetadata
  const { name: category, largeGridView } = pageContext
  const {
    allWpPost: { edges: posts },
    allWpCategory: { nodes: categories }
  } = data;

  return (
    <BlogList
      posts={posts || []}
      categories={categories || []}
      pageContext={pageContext}
      breadcrumbs={[{link: '/blog/', label: 'Blog' }, {label: `Category: ${category}`}]}
      title={`${category}`} // | ${siteTitle}
      largeGridView={largeGridView}
    />
  );
}

export default Category

export const pageQuery = graphql`
query CategoryPage($slug: String!) {
  
  site {
    siteMetadata {
      title
    }
  }
  
  allWpPost(
    filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
  ) {
    edges {
      node {
        ...PostListFields
      }
    }
  }

  allWpCategory(filter: {count: {gt: 0}}) {
    nodes {
      id
      uri
      name
      description
    }
  }
  
}
`