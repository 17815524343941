import React from 'react'

const CheckFilled = () => (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm3.159 4.869L6.67 9.355l-2.25-2.25-1.131 1.131L6.67 11.62 12.291 6l-1.132-1.131z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
)

export default CheckFilled