import React from "react";

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import MyUsuTab from "../../components/organisms/MyUsuTab/MyUsuTab";
import PaymentMethod from "../../components/organisms/PaymentMethod/PaymentMethod";
import * as styles from './my-usu/usu.module.css';

const Account = () => {

    return (
        <div className={styles.root}>

            {/* <div className={`${styles.root} ${styles.withBg}`}> */}
                <section className={styles.sectionContainer}>
                    <PaymentMethod />
                </section>
            {/* </div> */}
        </div>
    )
}

const AccountOutput = () => (
    <AccountPageWrapper 
        metaTitle="Account - Dashboard" 
        breadcrumbTitle={"My USU"} 
        title="Welcome to my USU Portal"
    >
        <MyUsuTab pageId={'member'}>
            <Account />
        </MyUsuTab>
    </AccountPageWrapper>
)

export default AccountOutput