
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './SortBy.module.css';
import Icon from "../../atoms/Icon/Icon";
import sortValues from './SortByData.json'

const SortBy = ({ onChange = () => {}, currentSort }) => {
  const [showPop, setShowPop] = useState(false);
  const [current, setCurrent] = useState('');
  const [init, setInit] = useState(true);

  useEffect(() => {
    if (init) {
      setInit(false);
      if (currentSort !== current) {
        setCurrent(currentSort);
        const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
        let hasFilters = false;
        urlParams.forEach((v, k) => {
          if (
            k === 'colors' || 
            k === 'price' || 
            k === 'brand' || 
            k.indexOf('filter_') === 0
          ) {
            hasFilters = true;
          }
        });
        if (!hasFilters) {
          onChange(null, currentSort);
        }
      }
    }
  }, [init, currentSort, current, onChange]);

  const updateSort = (e, value) => {
    setCurrent(value);
    onChange(e, value);
    setShowPop(!showPop);
  }

  return (
    <div data-sortby>
      <div className={styles.innerContainer} onMouseLeave={() => setShowPop(false)} role="presentation">
        <Link to="#" type="a" theme="secondary" className={styles.triggerButton} onClick={() => setShowPop(!showPop)}>
            Sort By <Icon symbol="arrowDown"/>
        </Link>
        <div className={`${styles.optionPop} ${showPop ? styles.isActive : ''}`}>
          <ul>
            {sortValues && sortValues.map((item, index) => (
              <li key={index} 
              onClick={(e) => updateSort(e, item.value)} 
              className={`${currentSort === item.value && styles.activeSort}`}
              role="presentation">{ item.label }</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SortBy;