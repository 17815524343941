import React from 'react'

const ZipMoneyLogo = () => (
    <svg width="1316" height="297" viewBox="0 0 1316 297" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M18.2668 239.308L25.3481 297H273.257L265.145 230.929H149.558L148.548 222.742L255.012 148.346L247.899 90.5366H0L8.11266 156.607H123.891L124.912 164.869L18.2668 239.308Z" fill="#1A0826"/>
    <path d="M277.319 90.5366L302.667 297H1054.95L1029.6 90.5366H277.319Z" fill="#AA8FFF"/>
    <path d="M1314.52 164.869C1308.79 118.468 1272.34 90.345 1222.79 90.547H1057.81L1083.16 297.011H1157.41L1152.33 255.714H1230.89C1292.67 255.703 1320.96 217.181 1314.52 164.869ZM1222.81 197.83L1145.19 197.915L1139.11 148.367L1217.17 148.431C1235.52 148.654 1244.92 158.989 1246.43 173.13C1247.37 182.232 1243.2 197.819 1222.81 197.819V197.83Z" fill="#1A0826"/>
    <path d="M339.552 63.289C352.609 49.2714 350.333 25.9276 334.468 11.1492C318.603 -3.62933 295.156 -4.24623 282.099 9.77134C269.041 23.7889 271.317 47.1327 287.183 61.9112C303.048 76.6897 326.494 77.3066 339.552 63.289Z" fill="#1A0826"/>
    <path d="M362.444 187.633L370.195 250.77H332.407L318.372 136.448H353.459L394.575 179.181L426.005 136.448H459.806L473.841 250.77H436.212L428.366 187.315L400.455 223.667H397.744L362.444 187.633Z" fill="#1A0826"/>
    <path d="M530.587 252.365C524.582 251.367 518.694 249.764 513.012 247.58C502.595 243.642 493.5 236.847 486.77 227.973C480.1 219.148 475.932 207.935 474.266 194.332V194.013C473.478 188.137 473.496 182.181 474.32 176.31C475.058 171.138 476.654 166.125 479.04 161.477C483.859 152.364 491.535 145.084 500.89 140.754C510.715 135.97 522.432 133.577 536.042 133.577C542.48 133.554 548.906 134.141 555.234 135.332C561.151 136.448 566.928 138.213 572.458 140.595C582.99 145.095 592.203 152.201 599.231 161.243C606.171 170.224 610.424 181.094 611.99 193.853L612.075 194.491C612.893 200.572 612.925 206.733 612.171 212.822C611.508 218.048 609.947 223.12 607.557 227.814C602.9 236.639 595.68 243.203 585.898 247.505C576.116 251.808 564.314 253.959 550.492 253.959C543.824 253.99 537.165 253.456 530.587 252.365ZM566.866 214.087C571.629 210.047 573.451 203.458 572.331 194.321C571.182 184.965 567.713 178.135 561.922 173.832C556.13 169.53 548.769 167.375 539.838 167.368C530.807 167.368 523.956 169.576 519.285 173.992C514.614 178.408 512.841 185.142 513.969 194.194V194.353C515.032 202.951 518.402 209.409 524.08 213.726C529.758 218.043 537.119 220.194 546.164 220.18C555.195 220.166 562.095 218.138 566.866 214.098V214.087Z" fill="#1A0826"/>
    <path d="M653.936 182.849L662.272 250.77H625.281L611.246 136.448H648.556L711.469 202.136L703.409 136.448H740.241L754.276 250.77H718.401L653.936 182.849Z" fill="#1A0826"/>
    <path d="M878.858 250.77H766.152L752.117 136.448H862.452L866.109 166.219H792.425L794.307 181.53H860.155L862.951 204.327H797.104L799.007 219.797H875.062L878.858 250.77Z" fill="#1A0826"/>
    <path d="M925.971 250.77L920.388 205.326L864.578 136.448H907.629L936.943 173.917L958.209 136.448H1001.1L959.835 205.486L965.396 250.77H925.971Z" fill="#1A0826"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="1315.42" height="297" fill="white"/>
    </clipPath>
    </defs>
    </svg>
)

export default ZipMoneyLogo