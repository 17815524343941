/**
 * Returns title value for Contentful images
 * 
 * 
 * @param   {Object} image 
 * 
 */

function returnAlt (image) { 
    return image?.image?.title 
};

/**
 * Returns src value for Contentful images
 * 
 * 
 * @param   {Object} image 
 * 
 */
function returnSrc (image) {
    const protocol = 'https';
    const fallbackImage = 'https://via.placeholder.com/400x200.png?text=Place+Image+Here';

    return `${protocol}:${image?.image?.file?.url}` || fallbackImage;
}

/**
 * Returns padding values
 * 
 * 
 * @param   {String} padding || None || Small || Medium || Large
 * 
 */
 function mapPaddingValues (padding) {
    
    switch (padding) {
        case 'None':
            return '0px';
        case 'Small':
            return '24px 0px';  
        case 'Medium':
            return '48px 0px';
        case 'Large':
            return '64 0px';  
        default:
            return ''
    }
}

/**
 * Returns lowercase size
 * 
 * 
 * @param   {String} size || Default || Small || Medium || Large
 * 
 */
 function normalizeContainerSize (containerSize) {
    
    let size = '';
    if(typeof containerSize === 'string' && containerSize !== 'Default') {
        size = containerSize.toLowerCase();
    }

    return size;
}

    
export {
    returnAlt,
    returnSrc,
    mapPaddingValues,
    normalizeContainerSize,
};
