import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { get } from "lodash";
import AuthContext from '../../../context/AuthProvider';
import CartContext from '../../../context/CartProvider';
import { bcApi } from '../../../helpers/bigcommerce';
import { formatPrice, setStorage } from '../../../helpers/general';
import { selectMembership } from '../../../helpers/usuapi';
import Button from '../../atoms/Button/Button';
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';
import Radio from '../../atoms/Radio/Radio';
import * as styles from './PaymentMethod.module.css';
const PaymentMethod = () => {
  const auth = useContext(AuthContext);
  const presentationRef = useRef();
  const [sId, setSId] = useState('');
  const [membership, setMembership] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(false);
  const [membershipOptions, setMembershipOptions] = useState([]);

  const cart = useContext(CartContext);

  const upgradeClickHandler = () => {

      presentationRef.current.click();
  };

  const membershipProceedHandler = () => {
    if(!selectedPackage) {
        toast.error('Please select a membership type')
        return;
    }
    const productMap = {
        '26': '20183',
        '1': '13610',
    };
    if (selectedPackage in productMap) {
        setLoading(true);
        let alreadyAdded = false;
        const cartItems = get(cart, 'state.cart.lineItems', {});
        Object.keys(cartItems).forEach(itemType => {
            cartItems[itemType].forEach(lineItem => {
                if (Object.values(productMap).indexOf(lineItem.sku) > -1) {
                    alreadyAdded = true;
                }
            });
        });
        
        if (alreadyAdded && typeof window !== 'undefined') {
            window.location = '/confirm-order';
        } else {
          const regoObject = {
            firstname: get(auth, 'state.usu.FirstName', 'Unknown'),
            lastname: get(auth, 'state.usu.LastName', 'Unknown'),
            Email: get(auth, 'state.email', 'Unknown@unknown.com'),
            Mobile: get(auth, 'state.usu.Mobile', '0400000000'),
          }
          setStorage('memberInfo', JSON.stringify(regoObject), true);
          bcApi(`catalog/products?sku:in=${productMap[selectedPackage]}`).then(resp => {
              // console.log("Found product", resp);
              if (String(resp.status).startsWith('2') && 'data' in resp.response) {
                  const product = resp.response.data[0];

                  bcApi(`catalog/products/${product.id}/modifiers`).then(response => {
                      // console.log(response);
                      if (String(response.status).startsWith('2') && 'data' in response.response) {
                          const result = {...response.response};
                          // console.log(result);

                          const optionSelections = result.data.map(option => {
                              if (option.display_name === 'Member number') {
                                  return {
                                      option_id: option.id,
                                      option_value: sId
                                  }
                              } else return false;
                          }).filter(o => o);
                          // console.log(optionSelections);
                          const cartItems = {
                              line_items: [{
                                  quantity: 1,
                                  product_id: product.id,
                                  option_selections: optionSelections
                              }]
                          };

                          bcApi('carts/items', 'POST', cartItems).then(resp2 => {
                              if (String(resp2.status).startsWith('2') && 'data' in resp2.response) {
                                  window.location = '/confirm-order';
                              } else {
                                console.log(resp2);
                                  toast.error("An unexpected error occurred (Cart)");
                                  setLoading(false);
                              }
                          });
                      } else {
                          toast.error("An unexpected error occurred (Package Config error)");
                          setLoading(false);
                      }
                  });
              } else {
                  toast.error("An unexpected error occurred (Package Not Found)");
                  setLoading(false);
              }
          })
        }
    } else {
        toast.error("An unexpected error occurred (Invalid Package)");
        setLoading(false);
    }

  };

  useEffect(() => {
    const initComponent = async () => {

      setLoading(true);
      if (auth.state && auth.state.usu) {
        setSId(auth.state.usu.MemberNumber);
        setMembership(auth.state.usu.MembershipName);
      }

      selectMembership(sId, true)
        .then(response => {
          if (String(response.status).startsWith('2') && Array.isArray(response.response)) {
            const options = response.response.filter(type => type.price !== "0")
            setMembershipOptions(options);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };
    initComponent();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [auth]);

  return (
    <div className={`${styles.root}`}>
      <div className={`flex-between`}>
        <div className={styles.membershipDetails}>
          <span>
            <strong>Membership Type: </strong>
            <span>{membership}</span>
          </span>
          <span>
            <strong>Membership ID: </strong> 
            <span>{sId}</span>
          </span>
        </div>
        {(membership === 'USU Member' && membershipOptions) && (
          <Button
            type={'span'}
            disabled={loading}
            onClick={upgradeClickHandler}
            className={`smallButtonGray ${styles.button} `}>
            {loading ? 'Please wait...' : 'Upgrade to Rewards'}
          </Button>
        )}
      </div>

      <ConfirmDialog
        title='Select membership'
        message={
          <div>
            {membershipOptions &&
              membershipOptions.map((option, oIndex) => (
                <div
                  className={`formField ${styles.membershipOption}`}
                  key={oIndex}>
                  <Radio
                    value={option.mid}
                    label={`<span class="${styles.optionName}">${
                      option.name
                    }</span> - ${
                      option.price === '0'
                        ? 'Free'
                        : `$${formatPrice(option.price)}`
                    }`}
                    name='package'
                    action={() => setSelectedPackage(String(option.mid))}
                  />
                </div>
              ))}
          </div>
        }
        onOk={membershipProceedHandler}>
        <div ref={presentationRef}></div>
      </ConfirmDialog>
    </div>
  );
};
export default PaymentMethod;

