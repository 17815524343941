import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { head } from 'lodash';
import Tab from '../../atoms/Tab/Tab';
import * as styles from './Tabs.module.css';
import { Link } from 'gatsby';

const Tabs = ({ items, noWrap, onChangeTab, align = 'center', mode, color, active, isDropDown = false }) => {
  const firstItem = head(items);
  const currentActive = items.find(item => item.active);
  const [activeTab, setActiveTab] = useState(
    active ? active : (
      currentActive ? currentActive.itemKey : (
        firstItem !== undefined ? firstItem.itemKey : undefined
      )
    )
  );

  const [showDropdown, setShowDropdown] = useState(false)

  const tabListClasses = [styles.tabList];

  if (align in styles) {
    tabListClasses.push(styles[align]);
  }

  const tabListClassOutput = tabListClasses.join(' ');

  const onClickTabHandle = tab => {
    setActiveTab(tab);
    if (onChangeTab) {
      onChangeTab(tab);
    }
  };

  // bind
  useEffect(() => {
    if (active !== undefined) {
      setActiveTab(active);
    }
  }, [active])

  return (
    <>
      <div className={clsx(styles.root, noWrap && styles.noWrap, isDropDown && styles.rootDropDown)}>
        <ul className={`${tabListClassOutput} ${mode === 'full' ? styles.full : ''}`}>
          {items.map(item => {
            if (item.label === "Uncategorized") {
              return null;
            }

            return (
              <Tab
                color={color}
                mode={mode}
                key={item.itemKey}
                label={item.label}
                onClick={onClickTabHandle}
                itemKey={item.itemKey}
                uri={item.uri}
                isDropDown={isDropDown}
                active={activeTab === item.itemKey || item.active}
              />
            );
          })}
        </ul>
        {isDropDown && 
        <button onClick={() => setShowDropdown(!showDropdown)}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 5.56L2.413 5H13.607L14 5.54L8.373 11H7.546L2 5.56Z" fill="black" />
          </svg>
        </button>
        }
        
      </div>
      {isDropDown && 
        <div className={styles.dropDownContainer}>
          {isDropDown && <div className={`${styles.tabDropDown} ${showDropdown ? styles.tabDropDownActive : ''}`}>
            {items.map(item => (
              <Link key={item.itemKey} to={item.uri} >
                {item.label}
              </Link>
            )
            )}
          </div>}
        </div>
      }
    </>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChangeTab: PropTypes.func
};

export default Tabs;
