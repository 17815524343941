import React, { useCallback, useContext, useMemo, useState } from "react";
import { renderToString } from 'react-dom/server';
import { Link, navigate } from "gatsby";
import { get } from "lodash";
import { decode } from "he";
import { toast } from "react-toastify";
// import parse from 'html-react-parser';
import AuthContext from "../../../../../context/AuthProvider";
import { persistLocation } from "../../../../../helpers/general";
import { getPerson, getTags, sendEmail } from "../../../../../helpers/ortto";
import { wpApiNew, authClubExec } from "../../../../../helpers/wordpress";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import RichTextField from "../../../../../components/atoms/RichTextField/RichTextField";
import SelectField from "../../../../../components/atoms/SelectField/SelectField";
import Button from "../../../../../components/atoms/Button/Button";
import OrttoEmailTemplate from "../../../../../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate";

import * as styles from '../../usu.module.css';

const EditCampaign = ({location}) => {
    const auth = useContext(AuthContext);
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [clubMemberships, setClubMemberships] = useState([]);
    const [init, setInit] = useState(false);
    const [disableActions, setDisable] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('draft');
    const [savingLabel, setSaving] = useState('Save for later');
    const [testingLabel, setTesting] = useState('Send test');
    const [sendingLabel, setSending] = useState('Send');
    const [campaignData, setCampaignData] = useState({
        name: '',
        subject: '',
        body: '',
        audience: `club-${location.state.clubSlug}`,
        club: location.state.clubId,
        id: location.state.campaignId
    });
    const [campaignInitial, setCampaignInitial] = useState({
        name: '',
        subject: '',
        body: '',
        audience: `club-${location.state.clubSlug}`,
        club: location.state.clubId,
        id: location.state.campaignId
    });

    const fetchCampaign = useCallback(async () => {
        // Fetch campaign data
        const campaign = await wpApiNew('getClubCommunicationData', {
            clubId: location.state.clubId,
            communicationId: location.state.campaignId,
            fieldSet: 1
        });
        if (String(campaign.status).startsWith("2") && campaign.response) {
            // console.log(campaign.response);
            const regex = /{.*} (.*)/gm;
            const emailName = regex.exec(campaign.response.title.rendered);
            const cData = {
                name: emailName[1],
                subject: campaign.response.acf.subject,
                body: campaign.response.content.rendered,
                audience: campaign.response.acf.audience,
                club: location.state.clubId,
                id: location.state.campaignId
            };
            // console.log(cData);
            setCampaignData(cData);
            setCampaignInitial(cData);
            setCurrentStatus(campaign.response.status);
            if (campaign.response.status === 'publish') {
                setTesting('Send preview');
            }
            setInit(true);
        } else {
            // TODO: Handle if campaign can't be found
            setInit(true);
        }
    }, [location]);

    useMemo(() => {
        if (isAuthed === null || (isAuthed && club && club.id !== get(location, 'state.clubId'))) {
            authClubExec(get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    const _levels = [{value: `club-${location.state.clubSlug}`, text: 'All members'}];
                    const fees = authed.response.acf.fees;
                    const freeOption = authed.response.acf.disable_free_memberships;
                    if (!freeOption) _levels.push({value: `clubLevel-${location.state.clubSlug}-Standard`, text: 'Standard'});
                    if(fees) fees.map(f => _levels.push({value: `clubLevel-${location.state.clubSlug}-${f.label}`, text: f.label}));
                    setClubMemberships(_levels);
                    if (location.state.campaignId) {
                        fetchCampaign()
                    } else if (location.state.startingPoint) {
                        // console.log(location.state.startingPoint);
                        const cData = {
                            ...location.state.startingPoint,
                            club: get(location, 'state.clubId'),
                            id: location.state.campaignId
                        };
                        // console.log(cData);
                        setCampaignData(cData);
                        setCampaignInitial(cData);
                        setInit(true);
                    } else {
                        setInit(true);
                    }
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, location, fetchCampaign , club]);

    const updateValue = (field, value) => {
        const state = {...campaignData};
        state[field] = value;
        setCampaignData({...state});
    }

    const returnBody = (body) => {
        updateValue('body', body);
    }

    const charCount = () => {
        return 120 - campaignData.subject.length;
    }

    const prepFields = (status) => {
        const data = {};
        data.status = status;
        data.title = `{${location.state.clubSlug}} ${campaignData.name === '' ? 'Unnamed' : campaignData.name}`;
        data.content = campaignData.body;
        data.fields = {};
        data.fields.club = campaignData.club;
        data.fields.subject = campaignData.subject;
        data.fields.audience = campaignData.audience;
        if (campaignData.id !== null && campaignData.id !== '') {
            data.id = campaignData.id;
        }

        return data;
    }

    const processEmail = (recipients) => {
        if (club) {
            // console.log("Club data: ", club);
            const emailBody = renderToString(<OrttoEmailTemplate logo={club.acf.logo} clubName={decode(club.title.rendered)} content={campaignData.body} />);
            // console.log("Email Body:", emailBody);

            const emailOptions = {
                html_body: emailBody,
                subject: campaignData.subject,
                email_name: `${decode(club.title.rendered)} club communication - ${campaignData.name}`,
                from_name: club.acf.club_preferences.sender_name,
                reply_to: club.acf.club_preferences.sender_email
            };
            
            return sendEmail(emailOptions, recipients).then(postResponse => {
                // console.log(postResponse);

                return postResponse;
            });
        } else {
            return false;
        }
    }

    const saveDraft = () => {
        setDisable(true);
        setSaving('Saving...');
        // console.log('Save draft:', campaignData);
        const data = prepFields('draft');
        try {
            wpApiNew('upsertCommunication', {
                clubId: club.id,
                communicationId: data.id || false,
                object: data
            }).then(response => {
                // console.log(response);
                if (String(response.status).startsWith("2") && 'id' in response.response) {
                    setSaving('Saved!');
                    navigate(`/account/my-usu/clubs/manage/communicate`,{
                        state: location.state
                    })
                } else {
                    setDisable(false);
                    setSaving('Save for later');
                    toast.error("An error occurred. Please try again.");
                }
            })
        } catch (e) {
            setDisable(false);
            setSaving('Save for later');
            toast.error("An error occurred. Please try again.");
        }
    }

    const sendTest = () => {
        setDisable(true);
        setTesting(`Sending ${currentStatus === 'publish' ? 'preview' : 'test'}...`);
        // console.log(`Send ${currentStatus === 'publish' ? 'preview' : 'test'}:`, campaignData);

        const data = prepFields(currentStatus);
        const emailRecipients = [{
            email: auth.state.email,
            first: auth.state.object.first_name,
            last: auth.state.object.last_name
        }];
        // const emailRecipients = [{
        //     email: "ash@matterdesign.com.au",
        //     first: "Ash",
        //     last: "Durham"
        // },{
        //     email: "sruti@matterdesign.com.au",
        //     first: "Sruti",
        //     last: "Venkat"
        // }];

        try {
            wpApiNew('upsertCommunication', {
                clubId: club.id,
                communicationId: data.id || false,
                object: data
            }).then(response => {
                // console.log(response);
                if (String(response.status).startsWith("2") && 'id' in response.response) {
                    processEmail(emailRecipients).then(result => {
                        // console.log('Result:', result);
            
                        if (String(result.status).startsWith("2") && 'emails' in result.response) {
                            setDisable(false);
                            setTesting('Sent!');
                            setTimeout(() => {
                                setTesting(`Send ${currentStatus === 'publish' ? 'preview' : 'test'}`);
                            }, 5000);
                        } else {
                            setDisable(false);
                            setTesting('Send test');
                            toast.error("An error occurred and couldn't send your test to you. Please try again.");
                        }
                    });
                } else {
                    setDisable(false);
                    setTesting('Send test');
                    toast.error("An error occurred attempting to save the email details. Please try again.");
                }
            });
        } catch (e) {
            setDisable(false);
            setTesting('Send test');
            toast.error("An error occurred. Please save your changes, refresh the page, and try again.");
        }
    }

    const sendBulkEmail = () => {
        setDisable(true);
        setSending('Sending...');
        // console.log('Send email:', campaignData);
        try {
            const data = prepFields('publish');
            wpApiNew('upsertCommunication', {
                clubId: club.id,
                communicationId: data.id || false,
                object: data
            }).then(response => {
                // console.log(response);
                if (String(response.status).startsWith("2") && 'id' in response.response) {
                    getTags(campaignData.audience).then(tags => {
                        if (String(tags.status).startsWith("2") && Array.isArray(tags.response) && tags.response.length > 0) {
                            const tag = tags.response.find(t => t.name === campaignData.audience);
                            const tagId = tag && tag.id;

                            if (tagId) {
                                getPerson(['first', 'last', 'email'], tagId, 'tag_id').then(people => {
                                    if (String(people.status).startsWith("2") && 'contacts' in people.response) {
                                        const emailRecipients = people.response.contacts.map(contact => ({
                                            email: contact.fields['str::email'],
                                            first: contact.fields['str::first'] || '',
                                            last: contact.fields['str::last'] || ''
                                        }));
                                        // const emailRecipients = [{
                                        //     email: "ash@matterdesign.com.au",
                                        //     first: "Ash",
                                        //     last: "Durham"
                                        // }];
                                        
                                        processEmail(emailRecipients).then(result => {
                                            // console.log('Result:', result);
                                
                                            if (result) {
                                                setSending('Sent!');
                                                setTimeout(() => {
                                                    setCurrentStatus('publish');
                                                    setDisable(false);
                                                    navigate(`/account/my-usu/clubs/manage/communicate`,{
                                                        state: location.state
                                                    })
                                                }, 5000);
                                            } else {
                                                setDisable(false);
                                                setSending('Send');
                                                toast.error("An error occurred and couldn't send your campaign. Please try again.");
                                            }
                                        });
                                            
                                    } else {
                                        setDisable(false);
                                        setSending('Send');
                                        toast.error("An error occurred fetching recipients details. Please try again.");
                                    }
                                });
                            } else {
                                setDisable(false);
                                setSending('Send');
                                toast.error("No audience reference found. Please try another or refresh the page and try again.");
                            }
                        } else {
                            setDisable(false);
                            setSending('Send');
                            toast.error("An error occurred fetching audience details. Please try again.");
                        }
                    });
                } else {
                    setDisable(false);
                    setSending('Send');
                    toast.error("An error occurred attempting to save the email details. Please try again.");
                }
            });
        } catch (e) {
            setDisable(false);
            setSending('Send');
            toast.error("An error occurred. Please save your changes, refresh the page, and try again.");
        }
    }

    return (
        <>
            {(isAuthed) ? (
                <>
                    <div className={`${styles.root}`}>
                        {/* Create  */}
                        <div className="formField">
                            <label htmlFor="audience">Recipients</label>
                            <SelectField id="audience" value={campaignData.audience} data={clubMemberships} handleChange={updateValue} />
                        </div>
                        <div className="formField">
                            <label htmlFor="campaignName">Campaign name</label>
                            <input type="text" id="campaignName" value={campaignData.name} onChange={(e) => updateValue('name', e.target.value)} />
                        </div>
                        <div className="formField">
                            <label htmlFor="campaignSubject">Email subject <span className="floatRight">{charCount()} characters remaining</span></label>
                            <input type="text" id="campaignSubject" value={campaignData.subject} onChange={(e) => updateValue('subject', e.target.value)} />
                        </div>
                        <div className="formField">
                            <label htmlFor="campaignBody">Email body</label>
                            {init && (
                                <RichTextField defaultValue={campaignInitial.body} returnValue={returnBody} />
                            )}
                        </div>
                        <div className="buttonGroup stretch">
                            {currentStatus === 'draft' && (
                                <>
                                    <Button level="secondary" type="button" disabled={disableActions} onClick={() => saveDraft()}>{savingLabel}</Button>
                                    <Button level="secondary" type="button" disabled={disableActions} onClick={() => sendTest()}>{testingLabel}</Button>
                                    <Button level="primary" type="button" disabled={disableActions} onClick={() => sendBulkEmail()}>{sendingLabel}</Button>
                                </>
                            )}
                            {currentStatus === 'publish' && (
                                <>
                                    <Button level="secondary" type="button" disabled={disableActions} onClick={() => sendTest()}>{testingLabel}</Button>
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Checking authorisation */}
                    {isAuthed === null && (
                        <div>Setting up form...</div>
                    )}
                    {/* Not authorised */}
                    {isAuthed === false && (
                        <div>You are not authorised to create a campaign. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementEditCampaign = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = (flag = null) => {
        navigate(`/account/my-usu/clubs/manage${flag ? `/${flag}` : ``}`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" title={`${persistedLocation.state?.campaignId ? 'Edit' : 'New'} Campaign`} breadcrumbTitle={`${persistedLocation.state?.campaignId ? 'Edit' : 'New'} Campaign`} breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}, { onClick: () => backToManagementPage('communicate'), label: 'Club Communications'}]} menuType="clubManage" location={persistedLocation}>
            <EditCampaign location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementEditCampaign