import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/organisms/Layout/Layout';
import FreshDeskArticle from '../components/organisms/FreshDeskArticle/FreshDeskArticle';
import Seo from '../components/organisms/Seo/Seo';


const FDArticle = ({ data }) => {
    const { freshDeskArticles, allFreshDeskCategories, allFreshDeskFolders } = data;
    const metaTitle = freshDeskArticles?.seo?.title ? freshDeskArticles?.seo?.title : `${freshDeskArticles.title} | University of Sydney Union`;

    let category = allFreshDeskCategories.nodes.filter((category) => category.category_id === freshDeskArticles.category_id);
    let folder = allFreshDeskFolders.nodes.filter((folder) => folder.folder_id === freshDeskArticles.folder_id);

    category = category.length === 1 ? category[0] : undefined;
    folder = folder.length === 1 ? folder[0] : undefined;

  return (
    <Layout>
        {/* <Seo title={metaTitle} description={metaDescription} /> */}
        <Seo title={metaTitle} />
        <FreshDeskArticle category={category} folder={folder} article={freshDeskArticles} />
    </Layout>
  );
};

export default FDArticle

export const pageQuery = graphql`
  query FDArticlePage($id: String!) {
    freshDeskArticles(id: {eq: $id}) {
            article_id
            category_id
            id
            folder_id
            tags
            title
            description
            url
    }
    allFreshDeskFolders {
        nodes {
            name
            id
            category_id
            folder_id
        }
    }
    allFreshDeskCategories {
        nodes {
        category_id
        id
        name
        }
    }
  }
  
`;

//51000286484