// extracted by mini-css-extract-plugin
export var cartBackdrop = "MiniCart-module--cartBackdrop--67245";
export var cartItemsContainer = "MiniCart-module--cartItemsContainer--47eab";
export var cartLoading = "MiniCart-module--cartLoading--3ada4";
export var closeContainer = "MiniCart-module--closeContainer--3777f";
export var emptyContainer = "MiniCart-module--emptyContainer--7b4b7";
export var linkContainer = "MiniCart-module--linkContainer--e6c83";
export var root = "MiniCart-module--root--18ab7";
export var summaryContainer = "MiniCart-module--summaryContainer--81c83";
export var summaryContent = "MiniCart-module--summaryContent--50fcb";
export var taxNotes = "MiniCart-module--taxNotes--1bf9f";
export var titleContainer = "MiniCart-module--titleContainer--d763e";
export var total = "MiniCart-module--total--baef2";
export var totalContainer = "MiniCart-module--totalContainer--b255d";
export var totalLabel = "MiniCart-module--totalLabel--01e80";