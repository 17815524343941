import React from 'react'

const BagPlus = () => (
    <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        >
        <path
        d="M9.6 8.533V7.467a6.4 6.4 0 0 1 12.8 0v1.066m-6.4 6.4V25.6m-5.333-5.333h10.666M5.123 13.63l-1.66 14.933a2.133 2.133 0 0 0 2.12 2.37h20.833a2.133 2.133 0 0 0 2.12-2.37l-1.659-14.933a2.133 2.133 0 0 0-2.12-1.898H7.243c-1.087 0-2 .818-2.12 1.898Z"
        stroke="currentColor"
        />
    </svg>
)

export default BagPlus