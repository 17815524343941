// extracted by mini-css-extract-plugin
export var addresses = "Eat-module--addresses--e1ce1";
export var content = "Eat-module--content--bfd88";
export var detailsGrid = "Eat-module--detailsGrid--cd1b1";
export var divider = "Eat-module--divider--30e43";
export var eatGrid = "Eat-module--eatGrid--84433";
export var eventBanner = "Eat-module--eventBanner--dd4f7";
export var gallery = "Eat-module--gallery--56e19";
export var galleryItem = "Eat-module--galleryItem--9dcc2";
export var main = "Eat-module--main--1b9be";
export var menu = "Eat-module--menu--7d4c8";
export var menus = "Eat-module--menus--41f8e";
export var openinghours = "Eat-module--openinghours--1a5d9";
export var pageBanner = "Eat-module--pageBanner--59c11";
export var root = "Eat-module--root--d9e09";