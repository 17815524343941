import { get } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
import { success } from '../../helpers/toast'
import InterestForm from '../../components/molecules/InterestForm/InterestForm'
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper'
import AuthContext from '../../context/AuthProvider'
import { getPerson, updatePerson } from '../../helpers/ortto'
import * as styles from './interests.module.css'

const Interests = () => {
  // TODO: Fetch interests from Ortto and Update Ortto with selections once submitted
  const [currentInterests, setCurrentInterests] = useState(null);
  const [btnLabel, setBtnLabel] = useState('Update');
  const [btnStyle, setBtnStyle] = useState(null);
  const auth = useContext(AuthContext);
  const userEmail = (auth && auth.state.email) || false;

  useEffect(() => {
    if (currentInterests === null && userEmail) {
      getPerson(['tags'], userEmail).then(response => {
        if (String(response.status).startsWith('2') && response.response.contacts.length === 1) {
          const tags = get(response, 'response.contacts[0].fields.tags', []);
          const slugs = tags.filter(tag => tag.startsWith('interest-')).map(tag => tag.replace('interest-', ''));
          setCurrentInterests(slugs);
        } else {
          setCurrentInterests(false);
        }
      });
    }
  }, [currentInterests, userEmail]);

  const updateInterests = (newInterests) => {
    setBtnLabel('Updating...');
    setBtnStyle('disabled');
    const unsetInterests = [...currentInterests];
    const tags = newInterests.map(interest => {
      const tag = `interest-${interest}`;
      const index = unsetInterests.indexOf(interest);
      if (index > -1) {
        unsetInterests.splice(index, 1);
      }
      return tag;
    });

    const unsetTags = unsetInterests.map(interest => `interest-${interest}`);

    const person = [{
      'email': userEmail,
      'tags': tags,
      'unset_tags': unsetTags
    }];

    updatePerson(person).then(response => {
      setBtnLabel('Update');
      setBtnStyle(null);
      success("Your interests have been updated");
    });
  }

  return (
    <AccountPageWrapper metaTitle="Account - Update Your Interests" title="Update your interests" bgRaw={true}>
      <p className={styles.subtitle}>You'll see top Events about these interests on your What’s on page.</p>

      {currentInterests === null && (
        <>Loading interests...</>
      )}

      {currentInterests === false && (
        <>An error occurred. Please refresh.</>
      )}

      {Array.isArray(currentInterests) && (
        <InterestForm selected={currentInterests} callback={updateInterests} overrideBtnLabel={btnLabel} btnStyle={btnStyle} overrideRoot/>
      )}

    </AccountPageWrapper>
  )
}

export default Interests