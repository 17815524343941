import React from 'react'
import * as styles from './Placeholder.module.css';
const Placeholder = ({isSmall}) => {
    return (
        <div className={`${styles.root} ${isSmall && styles.small}`}>
            <svg width="44px" height="40px" viewBox="0 0 44 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="My-USU---Events" transform="translate(-206.000000, -526.000000)" fill="#CACACA">
                        <g id="Group-13-Copy-6" transform="translate(173.000000, 491.000000)">
                            <path d="M62.15,35 L77,60.5555556 L62.6900085,60.5554174 C62.4007499,68.5823703 55.8673392,75 47.85,75 C39.6485715,75 33,68.2842712 33,60 C33,51.7157288 39.6485715,45 47.85,45 C50.5234324,45 53.0318622,45.7135962 55.1987895,46.9625061 L47.3,60.5555556 L62.6900085,60.5554174 C62.6966503,60.3711074 62.7,60.1859488 62.7,60 C62.7,54.4161655 59.6794586,49.5449424 55.1987895,46.9625061 L62.15,35 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Placeholder