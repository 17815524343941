import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { transformWpHealth } from '../helpers/wordpress';
import Health from '../components/organisms/Health/Health';

const WPHealth = ({ data }) => {
  const { wpHealth: rawHealth } = data;
  const health = transformWpHealth(rawHealth);
  const metaTitle = health.seo.title
    ? health.seo.title
    : `${health.title} | University of Sydney Union`;
  const metaDescription = health.seo.metaDesc
    ? health.seo.metaDesc
    : health.excerpt;


  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} />
      <Health health={health} />
    </Layout>
  );
};

export default WPHealth;

export const pageQuery = graphql`
  query HealthByID($id: String!) {
    wpHealth(id: { eq: $id }) {
      id
      status
      uri
      slug
      title
      content
      excerpt
      featuredImage {
        node {
          publicUrl
          sourceUrl
        }
      }
      healthAndServicesFields {
        address {
          address
          email
          country
          contact
          postcode
          state
          suburb
        }
        mapEmbed
        promotion
        openingHours {
          days
          hours
        }
      }
      seo {
        title
        metaDesc
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          publicUrl
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          publicUrl
          sourceUrl
        }
      }
      healthLocations {
        nodes {
          uri
          name
        }
      }
    }
  }
`;

