import React from 'react'

const Cross = () => (
    <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 12 12"}
  >
    <path d="m1.2 1.2 9.6 9.6m-9.6 0 9.6-9.6" stroke="currentColor" />
  </svg>
)

export default Cross