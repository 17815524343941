import React from 'react';
import { get } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

function ContentfulEntry({ id }) {
    const mediaEntryData = useStaticQuery(graphql`
        query {
            allContentfulMediaLink {
                nodes {
                  id
                  image {
                    file {
                      url
                      fileName
                    }
                  }
                  name
                  url
                  contentful_id
                  internal {
                    type
                  }
                }
            }
        }
    `)
    const mediaEntry = mediaEntryData?.allContentfulMediaLink.nodes;
    const mediaObj = mediaEntry.find(entry => entry.contentful_id === id);

    const blockType = get(mediaObj, 'internal.type');
    
    if (mediaEntry && mediaObj && blockType === 'ContentfulMediaLink') {
        return (
            <a rel="noreferrer" target="_blank" href={mediaObj.url}>
                <img loading="lazy" src={`${mediaObj.image?.file.url}?w=1100`} alt={mediaObj.image?.file.fileName} />
            </a>
        )
    } else {
        return null;
    }
}

export default ContentfulEntry