import { get } from 'lodash';
import React, { useState } from 'react';
import AosInit from '../../atoms/Aos/Aos';
import AccordionAlt from '../AccordionAlt/Accordion';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import * as styles from './ContentfulBlockAccordion.module.css';

const ContentfulBlockAccordion = props => {
  const { 
    label,
    items,
    layout,
    size,
  } = props;

  const [active, setActive] = useState([]);
  const backgroundColor = get(props, 'backgroundColour.hexCode', false);
  const textColor = get(props, 'backgroundColour.supportingColour', false);
  const defaultLayout = (layout === undefined || layout === null || layout === 'Left Icon') ? 'Left Icon' : 'Right Icon';

  const mainStyles = {};
  if (backgroundColor) {
      mainStyles.backgroundColor = backgroundColor;
      mainStyles.color = textColor;
  }

  const toggleActive = (index) => {
    if (active.includes(index)) {
      setActive(active.filter(val => val !== index));
    } else {
      setActive([...active, index]);
    }
  }

  return (
    <section className={`${styles.blockAccordion}`} style={mainStyles}>
      <AosInit>
        <Container size={typeof size === 'string' ? size.toLocaleLowerCase() : ''}>
          {defaultLayout === 'Left Icon' && 
            <>
            <h4 className={`${styles.heading} h4`}>{label}</h4>
            <div className={styles.accordionContainer}>
              {items && items.map((item, index) => (
                <div key={index} className={`${styles.accordionItem} ${active.includes(index) ? styles.active : ''}`}>
                  <div className={styles.trigger} tabIndex={index} role="button" onKeyDown={() => {}} onClick={() => toggleActive(index)}>
                    <div className={styles.icon}><span></span><span></span></div>
                    <h6>{item.heading}</h6>
                  </div>
                  <div className={styles.content}>
                    {item?.content?.raw && 
                      <ContentfulRichText raw={item.content.raw} />
                    }
                  </div>
                </div>
              ))}
            </div>
            </>
          }

          {defaultLayout === 'Right Icon' && 
          <>
            <h4 className={`${styles.heading} h4`}>{label}</h4>
              {items && items.map((item, index) => (
                <AccordionAlt emptyMessage = {'No Listed Items'} key={index} title={item.heading} customStyle={styles}>
                  <div className={styles.content}>
                    {item?.content?.raw && 
                      <ContentfulRichText raw={item.content.raw} />
                    }
                  </div>
                </AccordionAlt>
              ))}
          </>
          }

        </Container>
      </AosInit>
    </section>
  );
};

export default ContentfulBlockAccordion;
