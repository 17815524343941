import React from 'react';

import AosInit from '../../atoms/Aos/Aos';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulImageCollage.module.css';
import { returnAlt, returnSrc } from '../../../helpers/contentful';

const ContentfulImageCollage = props => {

  const { centerImage, leftImage, rightImage, caption, backgroundColor } = props;

  const bgColor = backgroundColor ? backgroundColor?.hexCode : 'var(--standard-white)';
  
  const centerImageAlt = returnAlt(centerImage);
  const centerImageSrc = returnSrc(centerImage);

  const leftImageAlt = returnAlt(leftImage);
  const leftImageSrc = returnSrc(leftImage);

  const rightImageAlt = returnAlt(rightImage);
  const rightImageSrc = returnSrc(rightImage);

  const styleProperties = {
    backgroundColor: bgColor
  }
  
  return (
    <div className={ styles.ContentfulImageCollage } style={styleProperties}>
      <div className={styles.collageContainer}>

        <div className={styles.centerImage}>
          <AosInit  attrs={{'data-aos': 'fade-down'}}>
            <img alt={centerImageAlt} src={`${centerImageSrc}?w=540&h=720`} />
          </AosInit>

          <div className={`${styles.imageContainer} ${styles.leftImageContainer}`}>
            <AosInit attrs={{'data-aos': 'fade-left'}}>
              <img alt={leftImageAlt} src={`${leftImageSrc}?w=600&h=400`} />
            </AosInit>
          </div>

          <div className={`${styles.imageContainer} ${styles.rightImageContainer}`}>
            <AosInit attrs={{'data-aos': 'fade-right'}}>
              <img alt={rightImageAlt} src={`${rightImageSrc}?w=600&h=400`} />
            </AosInit>
          </div>
        </div>

      </div>
      <div className={styles.captionContainer}>
        <AosInit>
          <ContentfulRichText raw={caption?.raw} />
        </AosInit>
      </div>
    </div>
  );
};

export default ContentfulImageCollage;
