import React, { useMemo, useState, useContext} from 'react'
import { searchUser, getUserSchedule, mapShiftStatus } from '../../helpers/rosterfy';

import AuthContext from '../../context/AuthProvider';
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import DataTable from '../../components/organisms/DataTable/DataTable';
import NoVolunteerDialog from '../../components/molecules/NoVolunteerDialog/NoVolunteerDialog';
import Loader from '../../components/atoms/Loader/Loader';
import Icon from '../../components/atoms/Icon/Icon';

import * as styles from './volunteer.module.css';
import { Link, navigate } from 'gatsby';

const VolunteerAccountPage = () => {

    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    
    const [loading, setLoading] = useState(false);
    const [rosterfyDialog, setRosterfyDialog] = useState(false);
    const [rosterfyId, setRosterfyId] = useState();
    const [shiftList, setShiftList] = useState();
    const [usuNumberState, setUsuNumberState] = useState();

    const NothingScheduled = () => <span className={styles.nothingScheduled}>Nothing scheduled - <Link to={'/vteam-events'}> Click here to register for an event.</Link></span>;
    
    useMemo(() => {        
        if(isLoggedIn === true) {
            // check for existing rosterfy id
            // to refactor: bind this to auth context
            // if(Array.isArray(auth.state.object.form_fields)) {
            const usuNumber = auth.state.usu?.MemberNumber;
            const usuEmail = auth.state.usu?.Email;
            
            searchUser(usuNumber, usuEmail).then((response) => {
                // To Do: go back here when auth is cleared out
                if(response === undefined) { 
                    setRosterfyId(false); 
                    setRosterfyDialog(true);
                    setUsuNumberState(usuNumber);
                }
                else setRosterfyId(response.response[0].object.id);
            })
            // }
        }
        //eslint-disable-next-line
      }, [isLoggedIn])

      useMemo(() => {
          if(rosterfyId !== undefined) {
              setLoading(true);
              getUserSchedule(rosterfyId).then((response) => {
                
                const normalizedShift = response.map((shift) => {
                    return({
                        ...shift,
                        time: `${shift.start_timestamp} - ${shift.end_timestamp}`,
                        status_name: mapShiftStatus(shift.status),
                    })
                })

                  setShiftList(normalizedShift);
                  setLoading(false);
              })
          }
      }, [rosterfyId])

    return(
    <AccountPageWrapper bgRaw={true} metaTitle="Account - Volunteering" title="VTeam" breadcrumbTitle={'Volunteer'}>        
        {/* to be styled when table is finished */}
        {/* {shiftList !== undefined && 
            <div className={styles.gridHeader}>
                <span>Event</span>
                <span>Date</span>
                <span>Time</span>
                <span>Status</span>
            </div>
        } 

        {
            shiftList?.map((shift) => {
            return(
            <div className={styles.gridRow} key={shift.shift_id}>
                <span>{shift.event_name}</span>
                <span>{shift.date}</span>
                <span>{shift.start_timestamp} - {shift.end_timestamp}</span>
                <span>{mapShiftStatus(shift.status)}</span>
            </div>)
        })} */}
        {/* {shiftList?.length === 0 && <span>Nothing scheduled</span>} */}

        {rosterfyId === false ? <span className={styles.nothingScheduled}>No volunteer account. <a rel='noreferrer' target="_blank" href={'https://usuvteam.rosterfy.co/register'}> You can register here.</a></span> : 
            shiftList?.length === 0 ? <NothingScheduled /> :
            <DataTable 
                topActions={[
                    { label: 'Event List', icon: <Icon symbol="calendar" />, event: () => navigate('/vteam-events') }
                ]}
                headingKeys={[
                    {label: 'Event', data_key: 'event_name'},
                    {label: 'Date', data_key: 'date'},
                    {label: 'Time', data_key: 'time'},
                    {label: 'Status', data_key: 'status_name'},
                ]}
                tableData={shiftList}
                bulkAction={false} 
            />
        }

        <div className={styles.loaderContainer}>
            {loading && <Loader></Loader>}
        </div>

        <NoVolunteerDialog show={rosterfyDialog} setShow={setRosterfyDialog} usuNumber={usuNumberState}  />
    </AccountPageWrapper>

    )
}

export default VolunteerAccountPage