import React from 'react';
import * as styles from './Hero.module.css';
import Button from '../../atoms/Button/Button';
import { Link } from 'gatsby';

const Hero = (props) => {
  const {title, subtitle, ctaText, ctaAction, image, maxWidth, ctaStyle, ctaLink, ctaTo, header} = props;
  return (
    <div className={styles.root} style={{backgroundImage: `url(${image})`}}>
      <div className={styles.content} style={{maxWidth: maxWidth}}>
        {header && 
          <span className={styles.header}>{header}</span>
        }
        {title && <h2 className={styles.title}>{title}</h2>}
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        {ctaText && <Button 
          type="button"
          className={`${styles.ctaButton} ${ctaStyle}`} 
          level={'primary'} onClick={ctaAction}>
            {ctaText}
        </Button>}
        {ctaLink && 
          <Link className={styles.ctaLink} to={ctaTo}>{ctaLink}</Link>
        }
      </div>
    </div>
  );
};

export default Hero;
