import React from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import Button from '../../components/atoms/Button/Button';
import Icon from '../../components/atoms/Icon/Icon';

import * as styles from './toast.module.css';

/**
 * Custom Toast Functions
 */
function success(message) {
    toast.success(message, {
        icon:
          <div style={{width: '20px', height: '20px', color: 'var(--standard-black'}}>
            <Icon symbol={'check'}/>
          </div>
      });
}

function successAddToCart(message) {
  const Msg = () => {
    return(
      <div>
        <div className={styles.messageContainer}>
          <div className={styles.iconContainer}><Icon symbol={'check'}/></div>
          <span>{message}</span>
        </div>
        {/* don't use links */}
        <div className={styles.buttonToast}>
          <Button 
            fullWidth 
            type={'button'} 
            onClick={() => navigate('/confirm-order')} 
            level={'primary'}>
            Checkout
          </Button>
        </div>
      </div>
    )
  }

  toast(<Msg></Msg>)
}

function error(message) {
    toast.error(message);
}

export { success, error, successAddToCart };