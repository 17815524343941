import React from 'react'

import { get } from 'lodash';
import { mapPaddingValues, normalizeContainerSize } from '../../../helpers/contentful/index';

import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';


import * as styles from './ContentfulBlockColumns.module.css';

const ContentfulBlockColumns = props => {
    const { 
        column1, 
        column2, 
        column3,
        containerSize,
        padding,
        maxWidth,
    } = props;
    const backgroundImage = get(props, 'backgroundImage.image.file.url', false);
    const backgroundColour = get(props, 'backgroundColour.hexCode', false); 
    const textColour = get(props, 'backgroundColour.supportingColour', false); 

    const mainStyles = {};
    if (backgroundImage) {
        mainStyles['backgroundImage'] = `url(${backgroundImage})`;
    }
    if (backgroundColour) {
        mainStyles['backgroundColor'] = backgroundColour;
        mainStyles['color'] = textColour;
    }

    let size = normalizeContainerSize(containerSize);

    const contentStyles = {};
    contentStyles['padding'] = mapPaddingValues(padding);

    const columnStyles = {};
    columnStyles['maxWidth'] = maxWidth;

    return (
        <section className={styles.pageColumns} style={mainStyles}>
            <AosInit>
                <Container size={size}>
                    <div className={`${styles.contentContainer} ${styles.innateContainer}`} style={contentStyles}>
                        {column1 && column1.raw && (<div className={styles.column} style={columnStyles}><ContentfulRichText raw={column1.raw} /></div>)}
                        {column2 && column2.raw && (<div className={styles.column} style={columnStyles}><ContentfulRichText raw={column2.raw} /></div>)}
                        {column3 && column3.raw && (<div className={styles.column} style={columnStyles}><ContentfulRichText raw={column3.raw} /></div>)}
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockColumns;