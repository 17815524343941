import React from 'react';

const ArrowWithTail = () => (

  <svg
    width={38}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 38 20'
  >
    <path fill="currentColor" d="M0 8.5h36v3H0z" />
    <path d="M38 10 28 0l-2 2 8 8-8 8 2 2 10-10Z" fill="currentColor" />
  </svg>
);

export default ArrowWithTail;
