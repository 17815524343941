import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ReactTooltip from 'react-tooltip';
import BindContext from '../../../context/BindProvider'

import { setStorage } from "../../../helpers/general";

import Header from "../Header/v2/Header"
import Footer from "../Footer/v2/Footer"


// CSS not modular here to provide global styles
import "./Layout.css"
import "./Globals.css"
import * as styles from './Layout.module.css';

const Layout = ({ children, bare = false, noPadding = false, slim = false, disablePaddingBottom = false, headerBgColor, bannerLinks }) => {
  const bind = useContext(BindContext);
  const initBind = bind && bind.init

  const [headerHeight, setHeaderHeight] = useState(0);
  // const [messages, setMessages] = useState([]);
  // const [fetchedMessages, setFetchedMessages] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (typeof window !== 'undefined' && ['/login', '/login/', '/join', '/join/', '/forgotpassword', '/forgotpassword/'].indexOf(window.location.pathname) === -1) {
    setStorage('lastPage', window.location.href, true);
  }

  // useEffect(() => {
  //   if (messages.length === 0 && !fetchedMessages) {
  //     setFetchedMessages(true);
  //     const fetchedMessages = data.allBuilderModels.globalInfoBannerContent.map((message) => {
  //       return {
  //         text: message.data.text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'),
  //         link: message.data.link,
  //       }
  //     });
  //     setMessages(fetchedMessages);
  //   }
  //   initBind()
  // }, [initBind, data, messages, setMessages, fetchedMessages, setFetchedMessages])

  // const messages = [
  //   {text: "This is a hardcoded example message", link: "/shop/shop-all"}
  // ]

  useEffect(() => {
    initBind && initBind()
  }, [initBind])

  return (
    <>
      <Helmet>
        {/* Add any sitewide scripts here */}
        <script>
          {`
          window.fwSettings={
            'widget_id':51000002472
          };
          !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
          `}
        </script>
        <script type='text/javascript' src='https://aus-widget.freshworks.com/widgets/51000002472.js' async defer></script>

        {/* Google Tag Manager */}
        <script type="text/javascript">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K26KJS');
          `}
        </script>

        {/* Google Search Console Verification */}
        <meta name="google-site-verification" content="wRXmSknVDHj4XmE3sfm7dfbZ1dB6SrkafhBsM-oMFQs" />
      </Helmet>

      {/* Google Tag Manager - Body Script */}
      <noscript>
          {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K26KJS" height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
      </noscript>

      <ReactTooltip />
      {!bare && (
        <Header
          setHeaderHeight={setHeaderHeight}
          siteTitle={data.site.siteMetadata?.title || `Title`}
          headerBgColor={headerBgColor}
        />
      )}
      <main
        style={!noPadding ? {paddingTop: `${headerHeight}px`} : {padding: `0px`}}
        className={`${styles.main} ${disablePaddingBottom === true ? styles.disablePaddingBottom : ''}`}>
        {children}
      </main>
      {(!slim && !bare) && (
        <Footer pageBannerLinks={bannerLinks} />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
