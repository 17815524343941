import React from 'react'

const AmexLogo = () => (
    <svg
    width={40} height={28}
    viewBox="0 0 43 30"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="prefix__a" d="M21.5 0H43v29.563H0V0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M38.969 0H4.03C1.881 0 0 1.747 0 4.031v21.5c0 2.285 1.747 4.032 4.031 4.032H38.97c2.284 0 4.031-1.747 4.031-4.032v-21.5C43 1.747 41.253 0 38.969 0"
            fill="#22ADE7"
            fillRule="nonzero"
            mask="url(#prefix__b)"
          />
        </g>
        <path
          d="M8.063 15.05h2.284l-1.075-2.688-1.21 2.688zm19.08-2.688v1.747h2.554v1.344h-2.553V17.2h2.822l2.015-2.419-1.747-2.284-3.09-.134zm8.198 6.45l-2.15-2.553-2.285 2.553h-5.643v-7.928h5.912l2.016 2.553 2.15-2.553h2.15l-3.225 3.897 3.09 4.031h-2.015zm-11.288 0h-2.15V12.9l-2.284 5.912h-1.747L15.319 12.9v5.912H11.69l-.672-2.015H7.39l-.672 2.015H4.703l3.225-7.928h2.419l3.09 7.525v-7.525h2.957l2.284 5.241 2.284-5.24h3.091v7.928zm11.96-4.03l2.418-3.091 1.882-2.285h-5.644l-.403.538-1.075 1.21-.941-1.21-.403-.538h-11.69l-.404.806-1.075 2.42-1.075-2.42-.269-.806h-5.24v2.016l-.403-1.21-.404-.806H7.122l-.403.806-3.225 7.929-.807 2.015H7.66l.27-.94.402-1.075h1.747l.403 1.075.269.94h5.913v-.672l.268.672h3.494l.134-.134v.134h11.02l.402-.403 1.21-1.344 1.209 1.344.403.538h5.51l-1.747-2.285-2.553-3.225z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
)

export default AmexLogo