import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../context/AuthProvider'
import Container from '../../atoms/Container/Container'
import EventCard from '../../molecules/EventCard/EventCard'
import EventsTab from '../EventsTab/EventsTab'
import { getPerson } from '../../../helpers/ortto';
import * as styles from './EventsForYou.module.css';
import { get } from 'lodash'

const EventsForYou = ({ events }) => {
    const auth = useContext(AuthContext);
    const userEmail = (auth && auth.state.email) || false;
    const [personalisedEvents, setPeronalisedEvents] = useState(null);
    const [filter, setFilter] = useState('month');
    const [matchData, setMatchData] = useState({});

    const padFigure = figure => {
        return String(figure).padStart(2, '0');
    };

    useEffect(() => {
        if (events && userEmail) {
            getPerson(['tags'], userEmail).then(response => {
                if (String(response.status).startsWith('2') && response.response.contacts.length === 1) {
                    const mData = {};
                    const tags = get(response, 'response.contacts[0].fields.tags', []);
                    const slugs = tags.filter(tag => tag.startsWith('interest-')).map(tag => tag.replace('interest-', ''));
                    const clubs = tags.filter(tag => tag.startsWith('club-')).map(tag => tag.replace('club-', ''));
                    const currentDay = padFigure(new Date().getDate());
                    const currentMonth = padFigure(new Date().getMonth());
                    const currentYear = new Date().getFullYear();
                    const current = Number(`${currentYear}${currentMonth}${currentDay}`);
                    const filteredEvents = events.filter(event => {
                        const club = get(event, "eventFieldsSide.club.slug");
                        const clubName = get(event, "eventFieldsSide.club.title");
                        const endDate = event.eventFieldsSide.endDate;
                        const dateEndObject = endDate ? new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')) : null;
                        const end = dateEndObject ? Number(`${dateEndObject.getFullYear()}${padFigure(dateEndObject.getMonth())}${padFigure(dateEndObject.getDate())}`) : null;

                        if (get(event, 'eventFieldsSide.club.databaseId', 0) === 4668) {
                            return false;
                        }

                        if (end < current) {
                            return false;
                        }

                        if (club) {
                            const matches = clubs.indexOf(club) > -1;
                            if (matches) {
                                mData[event.id] = `Because you are a member of ${clubName}`;
                                return true;
                            }
                        } else if (event.slug.indexOf('-igm-meeting') > -1) {
                            const clubNameRendered = event.title.replace(' IGM meeting', '');
                            mData[event.id] = `Because you have shown interest in ${clubNameRendered}`;
                            return true;
                        }
                        const clubCategories = get(event, "eventFieldsSide.club.clubCategories.nodes");
                        if (clubCategories) {
                            const matches = clubCategories.filter(category => slugs.indexOf(category.slug) > -1);
                            // if (matches) { possible bug here
                            if (matches.length !== 0) {
                                mData[event.id] = `Because you are interested in ${matches[0]?.name}`;
                                return true;
                            }
                        }
                        return false;
                    });

                    const dateFiltered = filteredEvents;
                    if(filter === 'month' || filter === 'future') {
                        const currentMonth = new Date().getMonth();
                        const dateFilteredEvents = dateFiltered.filter((e) => {
                            const startDate = e.eventFieldsSide.startDate;
                            const dateStartObject = new Date(startDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3'));

                            if(filter === 'month' && currentMonth === dateStartObject.getMonth()) {
                                return e;
                            } else if(filter === 'future' && currentMonth <= dateStartObject.getMonth()) {
                                return e
                            }
                            return null;
                        })
                        setPeronalisedEvents(dateFilteredEvents);
                    } else {
                        setPeronalisedEvents(dateFiltered);
                    }
                    setMatchData(mData);
                } else {
                    setPeronalisedEvents(false);
                }
            });
        }
    }, [events, userEmail, filter]);
    return (
        <>
            <Container>
                <div className={styles.eventsHeaderContainer}>
                    <h1 className={styles.title}>What's On</h1>
                </div>
            </Container>
            <EventsTab pageId={'events-for-you'} setFilter={setFilter}>
                <div className={styles.root}>
                    {personalisedEvents && (
                        <>
                            {personalisedEvents.length > 0 ? (
                                <>
                                    {personalisedEvents.map((event, eventIndex) => (
                                        <EventCard eventObj={event} key={eventIndex} match={event.id in matchData ? matchData[event.id] : null} />
                                    ))}
                                </>
                            ) : (
                                <>No events match your interests</>
                            )}
                        </>
                    )}

                    {personalisedEvents === null && (
                        <>Personalising your event list...</>
                    )}

                    {personalisedEvents === false && (
                        <>An error occurred. Please refresh.</>
                    )}
                </div>
            </EventsTab>
        </>
    )
}

export default EventsForYou