import React from 'react';

import { GraphQLProvider } from './src/context/GraphQLProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { CartProvider } from './src/context/CartProvider';
import { ContentfulProvider } from './src/context/ContentfulProvider';
import { BindProvider } from './src/context/BindProvider';
import { setStorage, getStorage } from './src/helpers/general';

import ToastWrapper from './src/components/molecules/ToastWrapper/ToastWrapper';

require('dotenv').config()
export const wrapRootElement = ({ element }) => (
  <GraphQLProvider bcPath={process.env.BC_PATH} domain={process.env.SITE_URL}>
    <AuthProvider bcPath={process.env.BC_PATH} clientId={process.env.BC_CLIENT_ID}>
      <WishlistProvider>
        <PriceProvider>
          <CartProvider>
            <ContentfulProvider>
              <BindProvider>
                {element}
                <ToastWrapper />
              </BindProvider>
            </ContentfulProvider>
          </CartProvider>
        </PriceProvider>
      </WishlistProvider>
    </AuthProvider>
  </GraphQLProvider>
);

export const onClientEntry = () => {
  const maintenanceKey = getStorage('_fmk');
  if (process.env.SITE_MAINTENANCE === "true" && window.location.pathname !== '/maintenance' && window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') === -1 && maintenanceKey !== '92ef3ed2ca050dc623ed949ec04ccb56') {
    window.location = '/maintenance';
  } else if (window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') > -1) {
    setStorage('_fmk', '92ef3ed2ca050dc623ed949ec04ccb56');
  }
}