// extracted by mini-css-extract-plugin
export var ProductCategorySlider = "ProductCategorySlider-module--ProductCategorySlider--6c563";
export var categoryCardContainer = "ProductCategorySlider-module--categoryCardContainer--91c0c";
export var categoryName = "ProductCategorySlider-module--categoryName--af0f2";
export var controlContainer = "ProductCategorySlider-module--controlContainer--74866";
export var disable = "ProductCategorySlider-module--disable--25eb8";
export var headerSliderContainer = "ProductCategorySlider-module--headerSliderContainer--a8b5d";
export var hideOnMobile = "ProductCategorySlider-module--hideOnMobile--39d96";
export var nextContainer = "ProductCategorySlider-module--nextContainer--04d72";
export var overflowContainer = "ProductCategorySlider-module--overflowContainer--dd086";
export var previousContainer = "ProductCategorySlider-module--previousContainer--a0aee";
export var productCardContainer = "ProductCategorySlider-module--productCardContainer--c10c8";
export var sliderContainer = "ProductCategorySlider-module--sliderContainer--ff69c";