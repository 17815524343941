import React from 'react'

const Cart = () => (
    <svg
      viewBox="0 0 25 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.908 20.18a1.636 1.636 0 11-3.273 0 1.636 1.636 0 013.273 0zm9.271-1.636a1.636 1.636 0 100 3.272 1.636 1.636 0 000-3.272zm1.811-3.84l2.007-7.897a1.09 1.09 0 00-.196-.938 1.09 1.09 0 00-.895-.415H7.636L6.544.84A1.09 1.09 0 005.454 0H1.09a1.09 1.09 0 000 2.182h3.49l2.89 12.49a2.182 2.182 0 002.117 1.69h10.286a2.182 2.182 0 002.116-1.658zm-.48-7.068l-1.636 6.545H9.6L8.083 7.636H21.51z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
)

export default Cart