import React from 'react'

const TruckMoving = () => (
    <svg viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M31.134 7.901l-2.151-.437-1.01-3.06a2.677 2.677 0 00-2.52-1.815h-1.716v-1.58C23.737.437 23.267 0 22.73 0H9.38c-.572 0-1.009.47-1.009 1.009v11.095c0 .572.47 1.009 1.009 1.009h1.11a2.891 2.891 0 002.89 2.891 2.913 2.913 0 002.892-2.891h7.733a2.891 2.891 0 105.783 0h1.11c.572 0 1.009-.471 1.009-1.009V8.876c.033-.47-.303-.874-.774-.975zm-17.752 6.59c-.74 0-1.379-.605-1.379-1.378 0-.74.605-1.379 1.379-1.379.773 0 1.378.605 1.378 1.379 0 .773-.605 1.378-1.378 1.378zm10.355-7.296V4.102h1.715c.47 0 .908.303 1.076.773l.773 2.32h-3.564zm3.195 7.296c-.74 0-1.379-.605-1.379-1.378 0-.74.605-1.379 1.379-1.379.74 0 1.378.605 1.378 1.379-.034.773-.639 1.378-1.378 1.378zM5.918 2.354H.807A.818.818 0 000 3.16c0 .437.37.806.807.806h5.11c.438 0 .807-.37.807-.806a.797.797 0 00-.806-.807zM5.918 5.75H2.085a.818.818 0 00-.807.806c0 .437.37.807.807.807h3.833c.437 0 .806-.37.806-.807.034-.47-.336-.807-.806-.807zM5.918 9.112H3.362a.818.818 0 00-.807.807c0 .437.37.807.807.807h2.556c.437 0 .806-.37.806-.807a.797.797 0 00-.806-.807z" />
      </g>
    </svg>
)

export default TruckMoving