import React, { useEffect, useState } from 'react';
import Button from '../../../atoms/Button/Button';

import Checkbox from '../../../atoms/Checkbox/Checkbox'

// import * as styles from PARENT

const MultiSelect = ({ name, values, setter, getter, styles }) => {
    const [chunkLevel, setChunkLevel] = useState(1);
    const [displayValues, setDisplayValues] = useState([]);

    useEffect(() => {
        if (values) {
            // const updated = values.slice(0, (chunkLevel * 5));
            const updated = values;
            setDisplayValues(updated);
        }
    }, [values, chunkLevel]);

    const handleMore = (e) => {
        e.preventDefault();
        setChunkLevel(999); // To show all respectively OLD (chunkLevel + 1)
    };

    return (
        <div className={`${styles.options} ${styles.multiSelectOptions}`}>
            {displayValues && displayValues.map((value, valueIndex) => {
                const isChecked = `${name}=${value.key}` in getter ? true : false;
                // TODO: The below fails on examples like '8K' and replaces it with a '8'. If actual numbers are needed, further logic will need to be required.
                // const label = Number.isInteger(parseInt(value.label)) ? parseInt(value.label) : value.label;
                const label = value.label;

                return (
                    <div key={valueIndex} className={styles.multiSelectItem}>
                        <Checkbox key={valueIndex} name={name} value={value.key} label={label} action={setter} isChecked={isChecked} />
                    </div>
                )
            })}
            {values && values.length > 5 && (chunkLevel * 5) < values.length && (
                <Button type="a" href="#" link={true} className={styles.moreButton} onClick={handleMore}>
                    + More
                </Button>
            )}
        </div>
    )
};

export default MultiSelect;