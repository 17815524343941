import React from 'react'

const Twitter = () => (
    <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'0 0 16 16'}
  >
    <path
      d="M15.976 1.564a.533.533 0 0 0-.929-.32 2.73 2.73 0 0 1-1.307.813 3.67 3.67 0 0 0-2.513-.99 3.775 3.775 0 0 0-3.772 3.777v.469c-3.19-.247-4.897-2.67-5.357-3.9a.533.533 0 0 0-.977-.051C.005 3.598-.167 5.767.644 7.614c.711 1.618 2.14 2.907 4.167 3.761C3.89 12.028 2.34 12.8.533 12.8a.533.533 0 0 0-.296.977c1.733 1.156 3.548 1.156 5.237 1.156h.052c5.184 0 9.386-4.21 9.386-9.4v-.51c.388-.394.69-.797.874-1.319.207-.582.248-1.256.19-2.14Z"
      fill="currentColor"
    />
  </svg>
)

export default Twitter