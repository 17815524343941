import React, { useState } from 'react';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './ContentfulBlockTimelineAccordion.module.css';

const ContentfulBlockTimelineAccordion = props => {
  const { 
    title,
    timelineList
  } = props;

  const [active, setActive] = useState([]);

  const toggleActive = (index) => {
    if (active.includes(index)) {
      setActive(active.filter(val => val !== index));
    } else {
      setActive([...active, index]);
    }
  }

  return (
    <Container> 
      <div className={ styles.root }>
        <div className={`${styles.heading} h4`}>{title}</div>
        <div className={styles.body}>
          {timelineList && timelineList.map((timeline, index) => (
            <div key={index} className={`${styles.section} ${active.includes(index) ? styles.active : ''}`}>
              <div className={`${styles.sectionHeading}`} tabIndex={index} role="button" onKeyDown={() => {}} onClick={() => toggleActive(index)}>
                <span className={`h7`}>{timeline.heading}</span>
                <Icon symbol="chevronDown"/>
              </div>
              <div className={styles.sectionActivities}>
                <div className={styles.inner}>
                  {timeline.activities && timeline.activities.map((activity, key) => (
                    <div key={key} className={styles.activity}>
                      <div className={styles.timeLabel}>
                        <Icon symbol="clock"/><span>{activity.time}</span>
                      </div>
                      <div className={styles.content}>
                        {activity.description && <ContentfulRichText raw={activity.description.raw} />}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ContentfulBlockTimelineAccordion;
