import React from 'react';
import Icon from '../Icon/Icon';
import RichTextField from '../RichTextField/RichTextField'
import * as styles from './FormInputField.module.css';

const FormInputField = React.forwardRef((props, ref) => {
  const {
    id,
    type = 'text',
    labelName,
    value,
    pattern,
    min,
    max,
    handleChange,
    customHandleChange,
    handleBlur,
    placeholder,
    disabled,
    note,
    error,
    required,
    icon
  } = props;

  const handleRichTextValue = (body) => {
    handleChange(id, body)
  }

  return (
    <div className={`formField ${styles.formField}`}>
      {labelName !== undefined && 
        <label htmlFor={id} className={styles.label}>
          {labelName} {required === true ? <span>*</span> : ''}
        </label>
      }
      {((type === 'text') || (type === 'input')) && (
        <input
          id={id}
          name={id}
          type="text"
          value={value}
          pattern={pattern}
          placeholder = {placeholder}
          className={`${styles.input} ${ icon ? styles.conditionalIconPadding : ''} ${error && error ? styles.fieldRequired : ''}`}
          onChange={customHandleChange ? customHandleChange : e => handleChange(id, e.target.value)}
          onBlur={handleBlur ? e => handleBlur(id, e.target.value) : undefined}
          disabled={disabled}
          ref={ref}
        />
      )}
      {(type === 'number') && (
        <input
          id={id}
          name={id}
          type="number"
          min={min}
          max={max}
          value={value}
          pattern={pattern}
          placeholder = {placeholder}
          className={`${styles.input} ${error && error ? styles.fieldRequired : ''}`}
          onChange={e => handleChange(id, e.target.value)}
          onBlur={handleBlur ? e => handleBlur(id, e.target.value) : undefined}
          disabled={disabled}
        />
      )}
      {(type === 'password') && (
        <input 
          id={id}
          name={id}
          type="password" 
          value={value}
          placeholder = {placeholder}
          pattern={pattern}
          className={`${styles.input} ${error && error ? styles.fieldRequired : ''}`}
          onChange={e => handleChange(id, e.target.value)}
          onBlur={handleBlur ? e => handleBlur(id, e.target.value) : undefined}
          disabled={disabled}
          required={required}
        />
      )}
      {(type === 'email') && (
        <input 
          id={id}
          name={id}
          type="email"
          value={value}
          pattern={pattern}
          placeholder = {placeholder}
          className={`${styles.input} ${error ? styles.fieldRequired : ''}`}
          onChange={e => handleChange(id, e.target.value)}
          onBlur={handleBlur ? e => handleBlur(id, e.target.value) : undefined}
          disabled={disabled}
        />
      )}
      {(type === 'textarea') && (
        <textarea
          id={id}
          name={id}
          value={value}
          pattern={pattern}
          placeholder = {placeholder}
          className={`${styles.textarea} ${error ? styles.fieldRequired : ''}`}
          onChange={e => handleChange(id, e.target.value)}
          onBlur={handleBlur ? e => handleBlur(id, e.target.value) : undefined}
          disabled={disabled}
        /> 
      )}
      {(type === 'richtext') && (
        <RichTextField defaultValue={value} returnValue={handleRichTextValue} target={id} />
      )}
      {note && (
        <span className={styles.note}>{note}</span>
      )}
      {error && (
        <span className={'error'}>{error}</span>
      )}
      {icon && (
        <div className={styles.iconContainer}>
          <Icon symbol={icon} />
        </div>
      )}
    </div>
  );
});

export default FormInputField;
