import React, { useState } from 'react';
import Icon from '../../Icon/Icon'
// import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import AfterPayInfo from "../../AfterPayInfo/AfterPayInfo";
import Dialog from "../../Dialog/Dialog";

const AfterPay = ({ product, styles }) => {
    const [displayTnC, setDisplayTnC] = useState(false);
    // const payments = 4;
    const orderLimit = 1500;

    const thePrice = () => {
        let thePrice = 0;
        if ('detailedPrices' in product) {
          if (product.detailedPrices.sale_price.is_on_sale) {
            thePrice = product.detailedPrices.sale_price.tax_inclusive;
          } else {
            thePrice = product.detailedPrices.price.tax_inclusive;
          }
        } else if ('sale_price' in product && product.sale_price > 0) {
          thePrice = product.sale_price;
        } else {
          thePrice = product.price;
        }

        return thePrice;
    }

    // const calculation = () => {
    //     return <CurrencyFormatter
    //       currency='AUD'
    //       amount={thePrice() / payments}
    //     />
    // }

    if (thePrice() <= orderLimit) {
        return (
            <div className={styles.option} role="presentation" onClick={() => setDisplayTnC(!displayTnC)}>
                <span>Buy now, pay later with</span>
                <div className={styles.afterpay}>
                    <Icon symbol='afterPayLogoAlt'></Icon>
                </div>
                {/* <span>{payments} payments of {calculation()} <Icon symbol="helpCircle" /></span> */}
                
                <Dialog title={'After Pay Info'} open={displayTnC} size="md" hideBtnCancel hideBtnOk>
                    <AfterPayInfo />
                </Dialog>
            </div>
        )
    }

    return null;
}

export default AfterPay