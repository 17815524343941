// extracted by mini-css-extract-plugin
export var ContentfulBlockOne = "Layout2-module--ContentfulBlockOne--ce44b";
export var contentContainer = "Layout2-module--contentContainer--4c60e";
export var landscapeContainer = "Layout2-module--landscapeContainer--9573c";
export var leftContainer = "Layout2-module--leftContainer--e75af";
export var mainGrid = "Layout2-module--mainGrid--4d660";
export var mobileGrid = "Layout2-module--mobileGrid--57ce8";
export var portraitContainer = "Layout2-module--portraitContainer--09483";
export var rightContainer = "Layout2-module--rightContainer--a675b";
export var smallImageContainer = "Layout2-module--smallImageContainer--4159c";
export var title = "Layout2-module--title--4c35b";