// extracted by mini-css-extract-plugin
export var adjustItemContainer = "MiniCartItem-module--adjustItemContainer--1d5d9";
export var closeContainer = "MiniCartItem-module--closeContainer--e243e";
export var controlContainer = "MiniCartItem-module--controlContainer--f344f";
export var detailsContainer = "MiniCartItem-module--detailsContainer--e77f5";
export var disabled = "MiniCartItem-module--disabled--606a5";
export var imageContainer = "MiniCartItem-module--imageContainer--1f657";
export var meta = "MiniCartItem-module--meta--beb0c";
export var metaContainer = "MiniCartItem-module--metaContainer--20e7c";
export var mobileRemove = "MiniCartItem-module--mobileRemove--3ac77";
export var name = "MiniCartItem-module--name--43aad";
export var priceContainer = "MiniCartItem-module--priceContainer--c7ceb";
export var remove = "MiniCartItem-module--remove--711ee";
export var root = "MiniCartItem-module--root--44653";
export var size = "MiniCartItem-module--size--97fb9";