// extracted by mini-css-extract-plugin
export var centerContainer = "Layout1-module--centerContainer--67b44";
export var contentContainer = "Layout1-module--contentContainer--dad47";
export var headerContainer = "Layout1-module--headerContainer--cd981";
export var imagesContainer = "Layout1-module--imagesContainer--ea637";
export var landscapePictureContainer = "Layout1-module--landscapePictureContainer--3b0d9";
export var leftContainer = "Layout1-module--leftContainer--1ace8";
export var portraitPictureContainer = "Layout1-module--portraitPictureContainer--df556";
export var rightContainer = "Layout1-module--rightContainer--7c1d4";
export var root = "Layout1-module--root--18dbf";
export var smallPictureContainer = "Layout1-module--smallPictureContainer--fbee1";