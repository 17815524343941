import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { wpAllNew } from '../../../helpers/wordpress';
import DataTable from '../../organisms/DataTable/DataTable';
import Icon from '../../atoms/Icon/Icon';
// import * as styles from './AcquittalsList.module.css';

const AcquittalsList = ({
  location,
  filteredResults = false,
  extraTopActions = [],
  staffView = false
}) => {
  const [acquittals, setAcquittals] = useState(null);

  const topActions = staffView ? [] : [
    {
      label: 'Submit an Acquittal',
      icon: <Icon symbol='fileOther' />,
      event: () => navigate(`/account/my-usu/clubs/manage/finances/acquittals/submission`, {
        state: { ...location.state },
      }),
    }
  ];

  const rowActions = staffView ? [
    {
      label: 'View',
      cta: data =>
        navigate(`/account/manage-finances/acquittal-details`, {
          state: {
            ...location.state,
            fromClubManage: true,
            acquittal: data,
          },
        }),
    }
  ] : [
    {
      label: 'Manage',
      cta: data =>
        navigate(`/account/my-usu/clubs/manage/finances/acquittals/details`, {
          state: {
            ...location.state,
            acquittal: data,
          },
        }),
    },
  ]

  const fieldMap = (acquittal) => {
    const fields = {
      id: acquittal.id,
      name: decodeURI(acquittal.title.rendered),
      club: acquittal.acf.club.post_title,
      clubId: acquittal.acf.club.ID,
      status: acquittal.acf.status
          .substr(
              acquittal.acf.status.lastIndexOf(':') + 1,
              acquittal.acf.status.length
          )
          .trim(),
      calculatedReceived: acquittal.acf.calculated_received,
      calculatedSpent: acquittal.acf.calculated_spent,
      submittedBy: acquittal.acf.submitted_by,
      submittedDate: acquittal.date,
      reviewedBy: acquittal.acf.reviewed_by,
      approvedBy: acquittal.acf.approved_by,
      summary: acquittal.acf.summary,
      explanation: acquittal.acf.explanation,
      grants: acquittal.acf.grants,
      receipts: acquittal.acf.receipts,
      grants_count: acquittal.acf.grants.length,
      receipts_count: acquittal.acf.receipts.length,
    }

    if (staffView) {
      fields.staff_notes = acquittal.acf.staff_notes;
    }

    return fields;
  }

  useEffect(() => {
      const fetchFinances = async () => {
          const fetchAcquittalsList = async () => {
              const _acquittals = [];
              try {
                  if (location.state.clubId) {
                      const { response: acquittalsList } = await wpAllNew(`getClubAcquittalsList`, {
                        clubId: location.state.clubId,
                        type: filteredResults ? 'pending' : 'all',
                        as: staffView ? 'staff' : 'exec',
                        fieldSet: 0
                      });
                      _acquittals.push(...acquittalsList.map(acquittal => fieldMap(acquittal)))
                  }
              } catch (e) {
                  console.error(e);
              } finally {
                  return _acquittals;
              }
          };

          const _acquittals = await fetchAcquittalsList();

          setAcquittals(_acquittals);
      }

      if (acquittals === null) {
        fetchFinances();
      }
  });

  return (
    <>
      {acquittals === null ? (
        <>Fetching acquittals...</>
      ) : (
        <DataTable
          tableData={acquittals}
          bulkActions={[]}
          topActions={[
            ...extraTopActions,
            ...topActions,
          ]}
          headingKeys={[
            { label: 'Status', data_key: 'status', sortable: true, filterable: true },
            { label: 'Submitted by', data_key: 'submittedBy', filterable: true },
            {
              label: 'Submitted date',
              data_key: 'submittedDate',
              sortable: true,
              format: 'date',
            },
          ]}
          rowActions={rowActions}
        />
      )}
    </>
  );
};

export default AcquittalsList;