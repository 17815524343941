import React from 'react'

const Minus = () => (
    <svg viewBox="0 0 12 4" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.138 1.897h9.517"
        stroke="currentColor"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
)

export default Minus