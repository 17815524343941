import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '../../atoms/Container/Container';
import BlogPostCard from '../../molecules/BlogPostCard/BlogPostCard';
import Button from '../../atoms/Button/Button';

import * as styles from './PostList.module.css'

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const PostList = ({ posts, viewMode, cardSize, titleOnly, options = {} }) => {
  const classes = useMemo(() => {
    const initClass = [];
    if (viewMode === 'grid') {
      initClass.push(styles.postsGrid);
    } else {
      initClass.push(styles.postsList);
    }
    if (cardSize) {
      initClass.push(styles[cardSize]);
    }
    return initClass.join(' ');
  }, [viewMode, cardSize]);

  const [displayPosts, setDisplayPosts] = useState(posts.slice(0, 9));
  const [showIndex, setShowIndex] = useState(0);

  const showMore = (index) => {
    const newRange = index * 3;
    setShowIndex(index);
    setDisplayPosts(posts.slice(0, (newRange + 9)));
  };

  return (
    <Container>
      <div className={`${classes} ${'clearBottom' in options ? styles.clearBottom : ''}`}>
        {displayPosts && displayPosts.map(post => (
          <BlogPostCard
            size={cardSize}
            key={post.id}
            title={post.title}
            excerpt={post.excerpt}
            slug={post.slug}
            categories={post.categories}
            content={post.content}
            authorName={post.author_name}
            authorUri={post.author_uri}
            date={post.published_date}
            viewMode={viewMode}
            thumbnail={post.thumbnail_path || 'https://via.placeholder.com/570x413?text=Not+Found'}
            titleOnly={titleOnly}
            noCategories={true}
          />
        ))}
      </div>
      {posts.length > 9 && posts.length > displayPosts.length && (
        <div className={styles.moreButtonContainer}>
          <Button type="button" theme="primary" size="small" onClick={(e) => showMore(showIndex + 1)}>Show more</Button>
        </div>
      )}
    </Container>
  );
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  viewMode: PropTypes.oneOf(['list', 'grid'])
};

PostList.defaultProps = {
  viewMode: 'list'
};

export default PostList;

export const wpPostListFields = graphql`
  fragment PostListFields on WpPost {
    id
    title
    excerpt
    uri
    content
    author {
      node {
        name
        uri
        avatar {
          url
        }
      }
    }
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        uri
        sourceUrl
        localFile {
          childImageSharp {
            fixed(width: 570, height: 413, cropFocus: CENTER) {
              src
            }
          }
        }
      }
    }
    tags {
      nodes {
        slug
        name
      }
    }
    categories {
      nodes {
        name
        uri
        slug
      }
    }
    seo {
      title
      metaDesc
    }
    databaseId
  }
`;

export const bcPostListFields = graphql`
  fragment BCPostListFields on BigCommercePosts {
    url
    title
    id
    body
    summary
    author
    published_date {
      date(formatString: "MMMM DD, YYYY")
    }
    tags
    thumbnail_path
  }
`;