import React from 'react'

const Export = () => (
    <svg width={17} height={17} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="a">
        <path d="M1600 0v2098H0V0h1600Z" />
      </clipPath>
      <clipPath id="b">
        <path d="M1.594 11.608v3.798h13.812v-3.798H17v3.798c0 .425-.16.797-.478 1.116-.319.319-.69.478-1.116.478H1.594c-.425 0-.797-.16-1.116-.478C.16 16.203 0 15.832 0 15.406v-3.798h1.594ZM9.297 0v9.855l3.187-3.188 1.143 1.142L8.5 12.936 3.373 7.809l1.143-1.142 3.187 3.188V0h1.594Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-1336 -284)">
      <g clipPath="url(#b)" transform="translate(1336 284)">
        <path d="M0 0h17v17H0V0z" />
      </g>
    </g>
  </svg>

)

export default Export