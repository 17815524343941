import React, { useContext, useMemo } from 'react' // , { useContext, useMemo, useState }
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import JoinEvent from '../../atoms/JoinEvent/JoinEvent'
import Container from '../../atoms/Container/Container'
import parse from 'html-react-parser';
import * as styles from './Events.module.css'
import ContentfulContext from '../../../context/ContentfulProvider';


const Events = ({ event }) => {

    const siteConfig = useContext(ContentfulContext);
    const clubMediaDefaults = useMemo(() => (siteConfig && siteConfig.clubMediaDefaults) || [], [siteConfig]);

    return (
        <>
            <div className={`${styles.root}${event.club ? ` ${styles.clubEvent}` : ''}`}>
                {/* <div  style={{ backgroundImage: `url(https://images.unsplash.com/photo-1496307042754-b4aa456c4a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}> */}
                <div className={`${styles.eventBanner}`} style={{ backgroundImage: `url(${event?.bannerImage || (clubMediaDefaults && clubMediaDefaults?.banner ? `https:${clubMediaDefaults.banner}` : '/placeholder-new.jpg')})`, backgroundPosition: 'center right', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}> {/*${(!event.club && event?.bannerImage) ? ` ${styles.shade}` : ''}*/}
                    {/* <Container>
                        <h1>{event.title}</h1>
                    </Container> */}
                </div>
                <Container>
                    <div className={styles.bread}>
                        <Breadcrumbs crumbs={[{ 'label': 'Events', 'link': '/events/' }, { 'label': event.title }]} />
                    </div>
                    <div className={styles.title}>
                        <h1>{event.title}</h1>
                    </div>
                    <div className={styles.content}>
                        <div className="main">
                            {event.content && parse(event.content)}
                        </div>
                        <div className='side'>
                            <JoinEvent event={event} />
                        </div>
                    </div>

                </Container>
            </div>
            {/* https://matterdesign.atlassian.net/browse/USU-557 */}
            {/* <RelatedEvents /> */}
        </>
    )
}

export default Events