// extracted by mini-css-extract-plugin
export var articleLink = "faqs-module--articleLink--3fbd7";
export var articleListContainer = "faqs-module--articleListContainer--fda34";
export var categoryControlContainer = "faqs-module--categoryControlContainer--233b7";
export var folderGrid = "faqs-module--folderGrid--8a26a";
export var folderName = "faqs-module--folderName--02953";
export var labelCategory = "faqs-module--labelCategory--f5721";
export var loaderContainer = "faqs-module--loaderContainer--a1687";
export var mainContainer = "faqs-module--mainContainer--d0df5";
export var root = "faqs-module--root--7e66a";
export var searchContainer = "faqs-module--searchContainer--43e01";
export var searchControl = "faqs-module--searchControl--7b755";
export var searchIconContainer = "faqs-module--searchIconContainer--82ec4";
export var searchLabel = "faqs-module--searchLabel--fcb84";
export var searchResultsContainer = "faqs-module--searchResultsContainer--a3139";
export var searchTermContainer = "faqs-module--searchTermContainer--777ed";
export var searchTitle = "faqs-module--searchTitle--aee2c";
export var selectContainer = "faqs-module--selectContainer--b75f0";