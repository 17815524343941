import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
// import { embedCheckout } from '@bigcommerce/checkout-sdk';
import AuthContext from '../context/AuthProvider';
import CartContext from '../context/CartProvider';
import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import Loader from "../components/atoms/Loader/Loader"
import { bcLoginUrl } from '../helpers/bigcommerce'

const Checkout = () => {
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const ctx = useContext(CartContext);
    const auth = useContext(AuthContext);
    const { redirectUrls } = get(ctx,'state.cart',{});
    const { customerId, isLoggedIn, userChecked } = get(auth,'state',{});
    
    useEffect(() => {
        // if ('embedded_checkout_url' in redirectUrls && !checkoutLoaded) {
        //     embedCheckout({
        //         containerId: 'checkout',
        //         url: redirectUrls.embedded_checkout_url,
        //     });
        //     setCheckoutLoaded(true);
        // }
        
        if ('checkout_url' in redirectUrls && userChecked && !checkoutLoaded) {
            // console.log("Is Logged In?", isLoggedIn);
            // console.log("Redirect URL", redirectUrls.checkout_url);
            if (isLoggedIn) {
                bcLoginUrl(customerId, redirectUrls.checkout_url).then(url => {
                    // console.log("Logged in", url);
                    window.location = url;
                });
            } else {
                // console.log("Logged out", redirectUrls.checkout_url);
                window.location = redirectUrls.checkout_url;
            }
            setCheckoutLoaded(true);
        }
    }, [redirectUrls, customerId, isLoggedIn, userChecked, checkoutLoaded]);

    // return (
    //     <Layout>
    //         <div id="checkout"></div>
    //     </Layout>
    // )

    return (
        <Layout slim={true}>
            <Seo title="Transferring..." />
            <Container size="large">
                <div className="flex-center flex-middle" style={{
                    flexDirection: 'column',
                    height: 'calc(100vh - 110px)'
                }}>
                    <Loader />
                    <h6 style={{
                        paddingTop: '40px',
                        textAlign: 'center'
                    }}>Please wait while we configure your secure payment page...</h6>
                </div>
            </Container>
        </Layout>
    )
}

export default Checkout