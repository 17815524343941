import React from "react"
import Slick from "react-slick";

import './slick.css'
import './slick-theme.css'
import * as styles from './Slider.module.css';

const Slider = props => {
    const { children, settings, spacing, sliderRef } = props;

    const customPaging = () => {
        return(<div className={styles.mobilePagination}></div>)
    }

    const defaultSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        touchThreshold: 5,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: `${styles.customDots}`,
        customPaging: customPaging
    }

    const outputSettings = { ...defaultSettings, ...settings }
    
    return (
        <div className={`${styles.root} ${spacing === true ? styles.spacing : ''}`}>
            <Slick ref={sliderRef !== undefined ? sliderRef : undefined} {...outputSettings}>
                { children }
            </Slick>
        </div>
    )
}

export default Slider