import React from 'react'
import { graphql } from 'gatsby'

import Club from '../components/organisms/Club/Club';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

import { transformWpClub } from '../helpers/wordpress';

const WPClub = ({ data }) => {
    const padFigure = (figure) => {
        return String(figure).padStart(2, '0');
    }

    const { wpClub: rawClub, allWpEvent: allEvents } = data;
    const club = transformWpClub(rawClub);
    
    const events = allEvents.nodes.filter(event => { 

        if (event.eventFieldsSide.club?.id !== club.id) return false;
        const currentDay = padFigure(new Date().getDate());
        const currentMonth = padFigure(new Date().getMonth());
        const currentYear = new Date().getFullYear();
        const current = Number(`${currentYear}${currentMonth}${currentDay}`);
        const endDate = event.eventFieldsSide?.endDate;

        if (endDate) {
            const dateEndObject = new Date(endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3'));
            const end = Number(`${dateEndObject.getFullYear()}${padFigure(dateEndObject.getMonth())}${padFigure(dateEndObject.getDate())}`);
            
            if (end > current) {
                return event;
            }

            if (end === current) {
                const currentTime = Number(`${new Date().getHours()}${new Date().getMinutes()}`);
                const endTime = event.eventFieldsSide?.endTime;

                if (endTime === '') return event;
                
                const timeEndObject = new Date(`${endDate.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')} ${endTime}`);
                const checkTime = Number(`${timeEndObject.getHours()}${padFigure(timeEndObject.getMinutes())}`);

                if (checkTime > currentTime) {
                    return event;
                }
            }

            return false;
        }
        
        return event;
    });
    const metaTitle = club.seo.title ? club.seo.title : `${club.title} | University of Sydney Union`;
    const metaDescription = club.seo.metaDesc ? club.seo.metaDesc : club.excerpt;

    return (
        <Layout disablePaddingBottom>
            <Seo title={metaTitle} description={metaDescription} />
            <Club club={club} events={events} />
        </Layout>
    );
};

export default WPClub

export const pageQuery = graphql`
    query ClubByID($id: String!) {
        wpClub(id: { eq: $id }) {
            id
            databaseId
            status
            slug
            uri
            title
            content
            excerpt
            featuredImage {
                node {
                    publicUrl
                    sourceUrl
                }
            }
            clubFieldsSide {
                logo {
                    publicUrl
                    sourceUrl
                    localFile {
                        childImageSharp {
                            fixed(width: 300, height: 300, cropFocus: CENTER, fit: OUTSIDE) {
                                src
                            }
                        }
                    }
                }
                disableFreeMemberships
                fees {
                    label
                    value
                }
                registered
                status
                type
                stripeConnectId
            }
            clubFieldsMain {
                whyJoin
                fomo
                meetups
                socialLinks {
                    facebook
                    twitter
                    linkedin
                    instagram
                    pinterest
                    discord
                    website
                }
            }
            seo {
                title
                metaDesc
                metaKeywords
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    publicUrl
                    sourceUrl
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    publicUrl
                    sourceUrl
                }
            }
            clubCategories {
                nodes {
                    uri
                    name
                }
            }
            clubTags {
                nodes {
                    uri
                    name
                }
            }
        }

        allWpEvent(sort: {fields: eventFieldsSide___startDate, order: ASC}) {
            nodes {
                id
                status
                uri
                slug
                title
                eventFieldsSide {
                    startDate
                    endDate
                    club {
                        ... on WpClub {
                            id
                        }
                    }
                }
            }
        }
    }
`;