import React from 'react';
import * as styles from './BillingAddress.module.css';

const BillingAddress = ({ title, address }) => {
  if (!address) {
    return <div />;
  }
  return (
    <div className={styles.root}>
      {title && <div className={styles.title}>{title}</div>}
      <div>{`${address.first_name} ${address.last_name}`}</div>
      {address.company && <div>{address.company}</div>}
      <div>{address.street_1}</div>
      {address.street_2 && <div>{address.street_2}</div>}
      <div>{`${address.city}, ${address.state}, ${address.zip}`}</div>
      <div>{address.country}</div>
    </div>
  );
};

export default BillingAddress;
