import React, { useContext, useMemo, useState } from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import AuthContext from "../../../context/AuthProvider";
import { hasStaffPermissions, persistLocation } from "../../../helpers/general";
import AccountPageWrapper from "../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import EventSubmissionForm from '../../../components/molecules/EventSubmissionForm/EventSubmissionForm';

import * as styles from '../my-usu/usu.module.css';

const EventSubmission = ({location}) => {
    const auth = useContext(AuthContext);
    const [authed, setAuthed] = useState(null);
    // const memberNumber = auth && get(auth, 'state.usu.MemberNumber');

    useMemo(() => {
        if (authed === null && get(auth, 'state.userChecked')) {
            const isStaff = hasStaffPermissions(auth, [2, 3, 4]);
            setAuthed(isStaff);
        }
    }, [auth, authed, setAuthed]);

    return (
        <>
            {(authed) ? (
                <>
                    {location.state && (
                        <EventSubmissionForm location={location} isStaff={true} />
                    )}
                </>
            ) : (
                <div className={styles.root}>
                    {/* Checking authorisation */}
                    {authed === null && (
                        <div>Setting up form...</div>
                    )}
                    {/* Not authorised */}
                    {authed === false && (
                        <div>You are not authorised to create an event. Return back to <Link to="/account/manage-events/"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const StaffEventSubmission = ({location}) => {
    let title = `Create Event`;
    const persistedLocation = persistLocation(location);

    return (
        <AccountPageWrapper metaTitle="Account - USU Management" title={title} breadcrumbTitle={title} breadcrumbs={[{ link: '/account/manage-events/', label: 'Manage events' }]} location={location}>
            <EventSubmission location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default StaffEventSubmission