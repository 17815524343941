import React from 'react';
import Container from '../../../atoms/Container/Container';
import { returnAlt, returnSrc } from '../../../../helpers/contentful';
import * as styles from './Layout3.module.css';
import MobileLayout from './MobileLayout';
import ContentfulRichText from '../../../atoms/ContentfulRichText/ContentfulRichText';

const Layout3 = props => {

  const { title, landscapePicture, portraitPicture, smallPicture, content, size } = props;

  const landscapePictureAlt = returnAlt(landscapePicture);
  const landscapePictureSrc = returnSrc(landscapePicture);

  const portraitPictureAlt = returnAlt(portraitPicture);
  const portraitPictureSrc = returnSrc(portraitPicture);

  const smallPictureAlt = returnAlt(smallPicture);
  const smallPictureSrc = returnSrc(smallPicture);

  const contentfulImages = {
    landscapePictureAlt, landscapePictureSrc,
    portraitPictureAlt, portraitPictureSrc,
    smallPictureAlt, smallPictureSrc
  }

  return (
    <div className={ styles.ContentfulBlockTwo }>
      <Container size={size ? size.toLowerCase() : ''}>
      <div className={styles.titleContainer}>
        <h1>{title}</h1>
      </div>
      <div className={`${styles.imagesContainer} ${styles.hideOnMobile}`}>
        <div className={`${styles.portraitContainer}`}>
          <img src={`${portraitPictureSrc}?w=442&h=605&fit=fill`} alt={portraitPictureAlt}></img>
        </div>
        <div className={styles.rightImagesContainer}>
          <div className={styles.landscapeContainer}>
            <img src={`${landscapePictureSrc}?w=750&h=520&fit=fill`} alt={landscapePictureAlt}></img>
          </div>
          <div className={`${styles.smallPictureContainer} ${styles.hideOnMobile}`}>
            <img src={`${smallPictureSrc}?w=230&h=230&fit=fill`} alt={smallPictureAlt}></img>
          </div>
        </div>
      </div>
      <MobileLayout {...contentfulImages} />
      {content?.raw && 
        <div className={styles.contentContainer}>
          <ContentfulRichText raw={content.raw} />
        </div>
      }

      </Container>
    </div>
  );
};

export default Layout3;
