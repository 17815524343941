import React, { createContext, useContext } from 'react';
import { v4 } from 'uuid'
import { get } from 'lodash';
import markerSDK from '@marker.io/browser';

import AuthContext from './AuthProvider';
import WishlistContext from './WishlistProvider';

import { getStorage, setStorage, hasStaffPermissions } from "../helpers/general";

const BindContext = createContext();

export const BindProvider = ({ children }) => {
    const auth = useContext(AuthContext);
    const authAddSupport = auth && auth.addSupport;
    const wishlist = useContext(WishlistContext);

    const init = async () => {
        authAddSupport('wishlist', wishlist);

        // Enable Marker.io for staff
        if (hasStaffPermissions(auth, [1, 2, 3, 4]) && typeof window !== undefined && window.location.origin !== 'https://localhost:8000') {
            await markerSDK.loadWidget({
                project: '64ddd7e8429d1d91a979c32d',
                reporter: {
                    email: get(auth, 'state.usu.Email'),
                    fullName: `${get(auth, 'state.usu.FirstName')} ${get(auth, 'state.usu.LastName')}`,
                },
            });
        }

        // Set session token
        if (!getStorage('__jammut')) {
            setStorage('__jammut', v4());
        }
    }

    return (
        <BindContext.Provider value={{init}}>{children}</BindContext.Provider>
    );
};
    
export default BindContext;