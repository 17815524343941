import React from 'react';

const ArrowLink = () => (

<svg
    width={25}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 25 26'
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.752 3.743V0H25v18.248h-3.743V6.5L2.647 25.11 0 22.463l18.72-18.72H6.751Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLink;
