import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import WishlistContext from '../../context/WishlistProvider';
import CartContext from '../../context/CartProvider';

import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import ProductGrid from '../../components/organisms/ProductGrid/ProductGrid';
import ConfirmDialog from '../../components/atoms/ConfirmDialog/ConfirmDialog';
import * as styles from './wishlists.module.css';

const AccountWishlists = () => {
  const wishlistContext = useContext(WishlistContext);
  const cartContext = useContext(CartContext);
  const addAllToCart = cartContext && cartContext.addAllToCart;
  const { getAccountWishlists, getWishlistItems, checkNameDuplicate, setExistingWishlists } = wishlistContext || {};

  const [wishlistsData, setWishlistsData] = useState({
    wishlists: [],
    wishlistsFetching: false,
    wishlistsFetched: false,
    activeWishlist: -1,
    wishlistItems: [],
    wishlistProducts: [],
    activeEditor: -1,
    updateInProgress: false
  });

  const [newWishlistName, setNewWishlistName] = useState('');
  const [updateWishlistName, setUpdateWishlistName] = useState('');

  const [wishlistError, setWishlistError] = useState();
  const [wishlistUpdateError, setWishlistUpdateError] = useState();

  const [showNewWishlist, toggleShowNewWishlist] = useState(false);
  const [showWishlistItems, setShowWishlistItems] = useState();

  const viewWishlist = (id, items) => {
    if (items.length === 0) {
      setShowWishlistItems(false);
      return;
    }

    getWishlistItems(items, 300).then(wishlistProducts => {
      setShowWishlistItems(true);
      setWishlistsData({
        ...wishlistsData,
        ...{ activeWishlist: id, wishlistItems: items, wishlistProducts }
      });
    });
  };

  const editWishlist = (editor, name) => {
    if (wishlistsData.activeEditor !== editor) {
      setWishlistsData({ ...wishlistsData, ...{ activeEditor: editor, wishlistName: name } });
    } else {
      setWishlistsData({ ...wishlistsData, ...{ activeEditor: -1 } });
    }
  };

  const addNewWishlist = () => {
    const checkResult = checkNameDuplicate(newWishlistName);
    if (checkResult === false) {
      setWishlistError('Wishlist Name Already Exist');
      return;
    }

    if (newWishlistName) {
      wishlistContext
        .saveWishlist(newWishlistName)
        .then(({ response, status }) => {
          if (response.data && status === 201) {
            setWishlistsData({
              ...wishlistsData,
              ...{
                wishlists: [...wishlistsData.wishlists, response.data]
              }
            });
          }
          setNewWishlistName('');
          setWishlistError(undefined);
          toggleShowNewWishlist(false);
          getAccountWishlists();
        });
    } else {
      setWishlistError('Wishlist Name Cannot be Empty');
      return;
    }
  };

  const updateWishlist = (wishlistIndex, wishlistId, wishlistItems) => () => {
    if(!updateWishlistName) {
      setWishlistUpdateError('Wishlist Name Cannot be Empty');
      return;
    }
    
    const result = checkNameDuplicate(updateWishlistName);
    if(result === false) {
      setWishlistUpdateError('Wishlist Name Already Exist');
      return;
    }

    setWishlistsData({ ...wishlistsData, ...{ updateInProgress: true } });
    wishlistContext
      .updateAccountWishlist(
        wishlistId,
        wishlistsData.wishlistName,
        wishlistItems
      )
      .then(response => {
        if (response.data) {
          const data = wishlistsData.wishlists;
          data[wishlistIndex].name = response.data.name;
          getAccountWishlists();
          setWishlistsData({
            ...wishlistsData,
            ...{
              wishlists: data,
              activeEditor: -1,
              updateInProgress: false
            }
          });
        }
      });
  };

  const removeWishlist = wishlistId => {
    wishlistContext.removeWishlist(wishlistId).then(() => {
      const _wishlists = wishlistsData.wishlists;
      let deleteIndex = -1;
      _wishlists.map((wishlist, wishlistIndex) => {
        if (wishlist.id === wishlistId) {
          deleteIndex = wishlistIndex;
        }

        return true;
      });
      _wishlists.splice(deleteIndex, 1);

      const _userExistingWishlistName = _wishlists.map((item) => item.name);
      setExistingWishlists(_userExistingWishlistName);

      setWishlistsData({
        ...wishlistsData,
        ...{
          activeWishlist: -1,
          wishlistItems: [],
          wishlistProducts: [],
          wishlists: _wishlists
        }
      });
    });
  };

  const removeWishlistItem = (productId, variantId) => {
    wishlistContext
      .removeFromAccountWishlist(
        wishlistsData.activeWishlist,
        wishlistsData.wishlistItems,
        null,
        productId,
        variantId
      )
      .then(newList => {
        if (newList.length > 0) {
          wishlistContext
            .getWishlistItems(newList, 300)
            .then(wishlistProducts => {
              setWishlistsData({
                ...wishlistsData,
                ...{ wishlistItems: newList, wishlistProducts }
              });
            });
        } else {
          // if newList length is zero, delete list
          removeWishlist(wishlistsData.activeWishlist);
        }
      });
  };

  const items = useMemo(() => {
    if (wishlistsData.wishlists) {
      return wishlistsData.wishlists;
    }
    return [];
  }, [wishlistsData]);

  useEffect(() => {
    if (
      !wishlistsData.wishlistsFetched &&
      !wishlistsData.wishlistsFetching &&
      typeof getAccountWishlists === 'function'
    ) {
      setWishlistsData({ ...wishlistsData, ...{ wishlistsFetching: true } });
      getAccountWishlists().then(wishlistsAPIData => {
        if (wishlistsAPIData.data) {
          setWishlistsData({
            ...wishlistsData,
            ...{
              wishlists: wishlistsAPIData.data,
              wishlistsFetching: false,
              wishlistsFetched: true
            }
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [wishlistsData]);

  return (
    <div>
      {!isEmpty(items) && (
        <div className="dataTable mb-4">
          <div className={styles.wishlistHead}>
            <div>
              <span>Wishlist Name</span>
            </div>
            <div>
              <span>Items</span>
            </div>
            <div>
              <span>Action</span>
            </div>
          </div>
          {items.map((wishlist, wishlistIndex) => {
            const active = wishlistIndex === wishlistsData.activeEditor;
            return (
              <div className={styles.wishlistItem} key={wishlist.id}>
                <div className={styles.wishlistRow}>
                  <div>
                    <span
                      role="presentation"
                      data-button
                      onClick={() => viewWishlist(wishlist.id, wishlist.items)}
                    >
                      {wishlist.name}
                    </span>
                  </div>
                  <div>
                    <span>{wishlist?.items?.length}</span>
                  </div>
                  <div>
                    <span
                      role="presentation"
                      data-button
                      className="icon-wrap"
                      onClick={() => viewWishlist(wishlist.id, wishlist.items)}
                    >
                      View
                    </span>
                    <span
                      role="presentation"
                      data-button
                      className="icon-wrap"
                      onClick={() => {
                        editWishlist(wishlistIndex, wishlist.name);
                        setWishlistUpdateError();
                        setUpdateWishlistName('')
                        toggleShowNewWishlist(false);
                      }}
                    >
                      {active ? 'Cancel' : 'Edit'}
                    </span>
                    <ConfirmDialog
                      title="Are you sure?"
                      disableBackdropClick={true}
                      message="Are you sure you want to remove this wishlist? It will not be recoverable after you proceed."
                      onOk={() => removeWishlist(wishlist.id)}
                    >
                      <span data-button className="icon-wrap">
                        Delete
                      </span>
                    </ConfirmDialog>
                  </div>
                </div>
                <div
                  className={clsx(
                    styles.wishlistItemEditor,
                    active && styles.active
                  )}
                >
                  <div className="formField">
                    <input type="text" 
                      value={updateWishlistName} 
                      onChange={(e) => setUpdateWishlistName(e.target.value)} 
                    />
                  </div>
                  <div className={`${styles.wishlistErrorContainer} ${wishlistUpdateError !== undefined ? styles.show : ''}`}>
                    <span className={styles.wishlistError}>{wishlistUpdateError}</span>
                  </div>
                  <div className="formField">
                    <Button
                      level="primary"
                      size="small"
                      type="button"
                      disabled={wishlistsData.updateInProgress}
                      onClick={updateWishlist(
                        wishlistIndex,
                        wishlist.id,
                        wishlist.items
                      )}
                    >
                      {active && wishlistsData.updateInProgress && (
                        <span>Updating...</span>
                      )}
                      {!wishlistsData.updateInProgress && <span>Update</span>}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {showWishlistItems && wishlistsData.wishlistProducts.length > 0 && (
        <>
          <ConfirmDialog
            message="Would you also like to clear this wishlist from your account?"
            btnOk="Yes"
            onOk={() => {
              addAllToCart(wishlistsData.wishlistItems);
              removeWishlist(wishlistsData.activeWishlist);
            }}
            btnCancel="No"
            onCancel={() => addAllToCart(wishlistsData.wishlistItems)}
          >
            <Button
              level="primary"
              type="button"
            >
              Add all items to cart
            </Button>
          </ConfirmDialog>
          <ProductGrid
            products={wishlistsData.wishlistProducts}
            removeFunction={removeWishlistItem}
          />
        </>
      )}

      {showWishlistItems === false && (
        <span>Wishlist is empty.</span>
      )}


      {wishlistsData.wishlistsFetched && items.length === 0 && (
        <div>
          You have no wishlists. Build your first list by adding products using
          the <Icon symbol="heart" /> icon, then click "Save Wishlist" via the
          section in the header.
        </div>
      )}

      {!wishlistsData.wishlistsFetched && (
        <span>Fetching your wishlists...</span>
      )}

      <div className={`formField ${showNewWishlist ? 'show' : 'hidden'}`}>
        <input
          type="text"
          value={newWishlistName}
          onChange={e => setNewWishlistName(e.target.value)}
        />
        <div className={`${styles.wishlistErrorContainer} ${wishlistError !== undefined ? styles.show : ''}`}>
          <span className={styles.wishlistError}>{wishlistError}</span>
        </div>
      </div>
      {wishlistsData.wishlistsFetched && (
        <div className={`flex-center mt-5 ${styles.actions}`}>
          <Button
            level="primary"
            type="button"
            onClick={
              showNewWishlist
                ? addNewWishlist
                : () => { 
                  setWishlistsData({ ...wishlistsData, ...{ activeEditor: -1 } });
                  toggleShowNewWishlist(true)
                }
            }
          >
            {showNewWishlist ? 'Save Wishlist' : 'New Wishlist'}
          </Button>

          {showNewWishlist && (
            <Button
              level="primary"
              type="button"
              className="ml-4 show"
              onClick={() => {
                setNewWishlistName('');
                setWishlistError(undefined);
                toggleShowNewWishlist(false);
                setWishlistsData({ ...wishlistsData, ...{ activeEditor: -1 } });
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const AccountWishlistsOutput = () => {
  return (
    <AccountPageWrapper metaTitle="Account - Wishlists" title="Wishlists">
      <AccountWishlists />
    </AccountPageWrapper>
  );
};
export default AccountWishlistsOutput;
