import React, { useEffect, useRef, useState } from 'react';
// import Sticky from 'react-stickynode';
import { fetchTitle, trackRV } from '../../../helpers/general';
import Container from '../../atoms/Container/Container';
import ProductDetails from '../../molecules/ProductDetails/ProductDetails';
import ProductImages from '../../molecules/ProductImages/ProductImages';
import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import ProductInformation from '../../organisms/ProductInformation/ProductInformation';

import * as styles from './Product.module.css';

const ProductPageTemplate = props => {
  const { data } = props
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [ymal, setYmal] = useState([]);
  const sliderRef = useRef();
  // const [rvCount, setRvCount] = useState(7);

  if (data && 'entityId' in data) {
    trackRV(data.entityId);
  }


  useEffect(() => {

    // Set Breadcrumbs
    if (data && breadcrumbs.length === 0 && data.categories.length > 0) {
      const breadcrumbRaw = {};
      let breadcrumbData = [{ link: '/shop/all', label: 'Shop' }];

      data.categories.map(cat => {
        breadcrumbRaw[cat.parent_id] = cat;
        return true;
      });

      if (data.categories.length > 0) {
        breadcrumbData.push({ link: data.categories[0].url, label: data.categories[0].name });
      }
      // while (breadcrumbStart !== false) {
      //   if (breadcrumbStart in breadcrumbRaw) {



      //     breadcrumbData.push({ link: breadcrumbRaw[breadcrumbStart].url, label: breadcrumbRaw[breadcrumbStart].name });
      //     breadcrumbStart = breadcrumbRaw[breadcrumbStart].id;
      //   } else {
      //     breadcrumbStart = false;
      //   }
      // }
      breadcrumbData.push({ label: fetchTitle(data) });
      setBreadcrumbs(breadcrumbData);
    }

    // Set you may also like
    if (data && data.youMayAlsoLike.length > 0 && ymal.length === 0) {
      let randomIndex = 0;
      let randomiseProducts = data.youMayAlsoLike;
      let currentIndex = randomiseProducts.length;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [randomiseProducts[currentIndex], randomiseProducts[randomIndex]] = [
          randomiseProducts[randomIndex], randomiseProducts[currentIndex]];
      }

      if (randomiseProducts.length === 0) randomiseProducts = false;
      setYmal(randomiseProducts);
    }
  }, [data, breadcrumbs, ymal]);

  return (
    <div className={styles.productPage}>

      {data && (
        <div>
          <Container size="large">
            <div className={styles.breadcrumbsSm}>
              <Breadcrumbs crumbs={breadcrumbs} />
            </div>

            <div id="productDetails" className={styles.productTop}>

              <div className={styles.productTopEntry}>
                <div
                  className={`${styles.productTopCol} ${styles.colThumbnails}`}
                  data-img-count={data ? (data.images?.length || 0) : 0}
                >
                  <ProductImages
                    images={data.images}
                    identifier="product-images"
                    type="thumbnails"
                    igRef={sliderRef}
                  />
                </div>
                <div className={`${styles.productTopCol} ${styles.colImages}`}>
                  <ProductImages
                    images={data.images}
                    identifier="product-images"
                    type="gallery"
                    igRef={sliderRef}
                  />
                </div>
                <div className={`${styles.productTopCol} ${styles.colDetails}`}>
                  <div className={styles.breadcrumbs}>
                    <Breadcrumbs crumbs={breadcrumbs} />
                  </div>
                  <ProductDetails product={data} type="full" />
                </div>
              </div>
            </div>
          </Container>
          <div className={styles.pinkBackground}>
            <Container size={'large'}>
              <ProductInformation details={data.description} />
              <div className={styles.border}></div>
              {ymal.length > 0 && (
                <div>
                  <h4 className={styles.subheader}>You may also like</h4>
                  <ProductGrid overflowScroll={true} products={ymal.slice(0, 3)} columns={3} loadInventory={true} />
                </div>
              )}
            </Container>
          </div>

          {/* <Reviews product={data} reviewsList={true} sku={data.sku} type='carousel' /> */}

          {/* {ymal.length > 0 && (
            <div>
              <Container size="large">
                <div className={styles.bestSelling}>
                  <h4>You may also like</h4>
                  <ProductGrid products={ymal.slice(0, 4)} columns="4" loadInventory={true} />
                </div>
              </Container>
            </div>
          )} */}

          {/* <RecentlyViewed
            currentProductId={data.entityId}
            limit={rvCount}
            columns={rvCount}
            withContainer={true}
            title='Recently viewed products'
            styles={styles}
          /> */}
        </div>
      )}
    </div>
  );
};

export default ProductPageTemplate;
