import React from 'react';
import Layout from '../../components/organisms/Layout/Layout';
import Container from '../../components/atoms/Container/Container'
import Breadcrumbs from '../../components/atoms/Breadcrumbs/Breadcrumbs'
import PageTitle from '../../components/atoms/PageTitle/PageTitle';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import PurchaseForm from '../../components/molecules/PurchaseForm/PurchaseForm';
import Seo from '../../components/organisms/Seo/Seo';

import * as styles from './giftCertificates.module.css';

const GiftcertificatesPage = () => {
  return (
    <Layout>
      <Seo title="Purchase Gift Certificates" />
      <Container size="large">
        <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:'Gift Certificates'}]}/>
        
        <PageTitle title="Gift Certificates" titleClass={styles.title}>
        
        </PageTitle>

        <GiftCertificatesTab pageId={'purchase-gift-certificate'}>
          <PurchaseForm />
        </GiftCertificatesTab>
      </Container>
    </Layout>
  );
};

export default GiftcertificatesPage;
