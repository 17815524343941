import { useContext, useEffect } from "react"
import CartContext from '../context/CartProvider';

const LoadQuote = () => {
    const cart = useContext(CartContext);
    const loadQuote = cart && cart.loadQuote;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search.substring(1));
            if (params.get('quoteToken')) {
                loadQuote(params.get('quoteToken')).then(() => {
                    window.location = '/cart/';
                    // window.location = '/confirm-order/';
                });
            } else {
                window.location = '/';
            }
        }
        
    }, [loadQuote])

    return null
}

export default LoadQuote