import React from 'react';
// import AuthContext from '../../../context/AuthProvider';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import StartClubForm from '../../../components/molecules/StartClubForm/StartClubForm';
// import { hasStaffPermissions } from '../../../helpers/general';
import * as styles from './club.module.css';
import { navigate, useStaticQuery, graphql } from 'gatsby';

const Application = () => {
  // const auth = useContext(AuthContext);
  // const usuUserData = auth && auth.state.usu;

  const {
    wp: {
      acfOptionsGeneral: { siteManagement : { startAClubCapability } },
    }
  } = useStaticQuery(graphql`
      query {
        wp {
          acfOptionsGeneral {
            siteManagement {
              startAClubCapability
            }
          }
        }
      }
  `);

  return (
    <div className={`${styles.root} ${styles.noBorder}`}>
      <div>
        <p>
          Please complete this form once your club has formed a constitution and
          its ready to be uploaded to this form.
        </p>
        <p>
          Your application will be reviewed by a panel of students, Board and
          USU staff. You will be contacted via email regarding the outcome. If
          your club is accepted, the next step will be to organise an AGM with
          the help of our staff.
        </p>
        <p>
          Applications are accepted in the first month of each semester.
          Proposed clubs will be required to meet the following criteria:
        </p>
        <ul>
          <li>Will substantially contribute to the Objects of the Program</li>
          <li>Will uphold the Principles of the Program</li>
          <li>
            Is substantially different from existing clubs in the Program based
            on its aims, activities, and membership base
          </li>
          <li>Will function primarily as an on-campus community</li>
          <li>
            Is not controlled or substantially influenced by external
            organisations
          </li>
        </ul>
        <p>
          For more information please fill in the{' '}
          <a
            href='/contact'
            role={'button'}
            onClick={() => navigate('/contact')}>
            <u>contact us form</u>{' '}
          </a>{' '}
        </p>
        {!(startAClubCapability) && (
          <p><br /><strong>New club submissions are currently closed.</strong></p>
        )}
      </div>

      {(startAClubCapability) && (
        <div className={styles.fullSpan}>
          {(!startAClubCapability) && (
            // Form is hidden to all non-staff members currently.<br />
            <p style={{fontStyle: 'italic', paddingLeft: '40px'}}>Toggle the visibility of this form in Wordpress to enable logged in users to see it.</p>
          )}
          <StartClubForm />
        </div>
      )}
    </div>
  );
};

const Output = () => (
  <AccountPageWrapper
    metaTitle='Start a Club - Application'
    title='Start a club'>
    <Application />
  </AccountPageWrapper>
);

export default Output;

