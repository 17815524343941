import React from 'react';
import * as styles from './Layout1.module.css';

import { returnAlt, returnSrc } from '../../../../helpers/contentful';
import Container from '../../../atoms/Container/Container';
import ContentfulRichText from '../../../atoms/ContentfulRichText/ContentfulRichText';
import MobileLayout from './MobileLayout';

const Layout1 = props => {

  const { title, landscapePicture, portraitPicture, smallPicture, content, size } = props;

  const landscapePictureAlt = returnAlt(landscapePicture);
  const landscapePictureSrc = returnSrc(landscapePicture);

  const portraitPictureAlt = returnAlt(portraitPicture);
  const portraitPictureSrc = returnSrc(portraitPicture);

  const smallPictureAlt = returnAlt(smallPicture);
  const smallPictureSrc = returnSrc(smallPicture);

  const contentfulImages = {
    landscapePictureAlt, landscapePictureSrc,
    portraitPictureAlt, portraitPictureSrc,
    smallPictureAlt, smallPictureSrc
  }

  return (
    <div className={ styles.root }>
      <Container size={size ? size.toLowerCase() : ''}>
          <div className={styles.headerContainer}>
            <h1>{title}</h1>
          </div>
          <div className={styles.imagesContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.landscapePictureContainer}>
                    <img src={`${landscapePictureSrc}?w=840&h=600&fit=fill`} alt={landscapePictureAlt}></img>
                </div>
                
                {content?.raw && 
                  <div className={styles.contentContainer}>
                    <ContentfulRichText raw={content.raw}></ContentfulRichText>
                  </div>
                }

              </div>
              <div className={styles.rightContainer}>
                  <div className={styles.portraitPictureContainer}>
                    <img src={`${portraitPictureSrc}?w=380&h=507&fit=fill`} alt={portraitPictureAlt}></img>
                  </div>
                  <div className={styles.smallPictureContainer}>
                    <img src={`${smallPictureSrc}?w=250&h=250&fit=fill`} alt={smallPictureAlt}></img>
                  </div>
              </div>
          </div>
          <MobileLayout {...contentfulImages}/>
          {content?.raw && 
            <div className={`${styles.contentContainer} ${styles.showOnMobile}`}>
              <ContentfulRichText raw={content?.raw}></ContentfulRichText>
            </div>  
          }

      </Container>
    </div>
  );
};

export default Layout1;
