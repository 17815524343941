import React, { useState, useContext, useEffect, useMemo } from "react"
// import { scroller } from 'react-scroll';
import { get } from "lodash";
import AuthContext from '../../../context/AuthProvider';
import CartContext from "../../../context/CartProvider";
import Button from "../../atoms/Button/Button"
import Dialog from "../../atoms/Dialog/Dialog"
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Radio from "../../atoms/Radio/Radio";
import MessageAlert from "../../atoms/MessageAlert/MessageAlert";
import { getStorage, validateEmail, isObject, formatPrice, setStorage } from '../../../helpers/general'
import { findMember, selectMembership, translateErrorMessage } from "../../../helpers/usuapi";

import * as styles from './SignupForm.module.css';
import { bcApi, handleBcAddToCartError } from "../../../helpers/bigcommerce";
import InterestForm from "../../molecules/InterestForm/InterestForm";
import SelectField from "../../atoms/SelectField/SelectField";

const SignupForm = () => {
    const auth = useContext(AuthContext);
    const handleRegistration = auth && auth.signup;

    const cart = useContext(CartContext);
    const { state, removeItemFromCart } = cart;
    const { cartFetched } = state;
    const { lineItems } = state.cart;
    const [cartChecked, setChecked] = useState(false);
    
    const userEmail = getStorage('_customEmail');
    const [init, setInit] = useState(false);
    const [fields, setFields] = useState({
        mobile: '',
        terms: false,
        studentId: '',
        staffId: '',

    });
    const [dialogMessage, setDialogMessage] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState(false);
    // const [formWarnMsg, setFormWarnMsg] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [memberObject, setMemberObject] = useState({});
    const [membershipOptions, setMembershipOptions] = useState([]);
    const [isSending, setIsSending] = useState(false);
    // const [isSubmitted, setIsSubmitted] = useState(false);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [emptyError, setEmptyError] = useState({});

    const productMap = useMemo(() => ({
        '26': '20183',
        '1': '13610',
    }), []);

    useEffect(() => {

        if (!init && userEmail) {
            setFields({ ...fields, ...{ email: userEmail } })
        }

        setInit(true);
    }, [userEmail, fields, init])

    useEffect(() => {
        // console.log("Cleaning...", cartFetched, cartChecked);
        if (cartFetched && !cartChecked) {
            // console.log("Checking clean cart");
            // Check if rewards item in cart and prep to allow process to occur again
            // console.log("Line items", lineItems)
            const digitalItems = get(lineItems, "digital_items", []);
            const rewardsItem = digitalItems.find(di => Object.values(productMap).indexOf(di.sku) > -1);
            if (rewardsItem) {
                // Found item - remove it. Page will refresh if item is the only one in cart
                // const oldRemoved = removeItemFromCart(rewardsItem.id);
                // console.log(oldRemoved);
            }
            setChecked(true);
        }
    }, [lineItems, productMap, cartFetched, cartChecked, setChecked, removeItemFromCart])

    useEffect(() => {
        window.scroll({top: 25, left: 0, behavior: 'smooth'});
    }, [currentStep])

    const validateForm = (step, checkFields) => {

        const newError = emptyError;
        if (!(`step${step}` in newError)) {
            newError[`step${step}`] = {};
        }
        let isValid = true;
        // console.log('fields', fields);
        checkFields.forEach((item) => {
            // console.log('item:', item);
            if (item === 'email') {
                if (validateEmail(fields[item])) {
                    newError[`step${step}`][item] = false;
                } else {
                    newError[`step${step}`][item] = true;
                    isValid = false
                }
            } else if (item === 'terms') {
                if (fields[item]) {
                    newError[`step${step}`][item] = false;
                } else {
                    newError[`step${step}`][item] = true
                    isValid = false
                }
               
            }
            else if (item in fields && fields[item] !== '') {
                newError[`step${step}`][item] = false
            } else {
                newError[`step${step}`][item] = true
                isValid = false
            }
        });
        // console.log('errors', newError);
        setEmptyError(newError);
        setDisplayValidation(true);
        // scroller.scrollTo('signup-form', {
        //     duration: 800,
        //     delay: 0,
        //     offset: -200,
        //     smooth: 'easeInOutQuart',
        // });
        // console.log('isValid', isValid);
        return isValid
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const setFieldValue = (fieldName, fieldValue) => {
        setDisplayValidation(false);
        setFormErrorMsg(false);
        const newField = {};
        if (isObject(fieldName)) {
            Object.keys(fieldName).map(field => {
                newField[field] = fieldName[field];
                return true;
            })
        } else {
            newField[fieldName] = fieldValue;
        }
        setFields({ ...fields, ...newField });
        // const requiredField = {};
        // requiredField[fieldName] = fieldValue !== '';
        // setRequiredFields({ ...requiredFields, ...requiredField });
    }

    const stepOne = () => {
        // TODO: Check USUAPI for user validation and move to next step
        setIsSending(true);
        const requiredFields = [
            'memberType',
            'lastName',
            'terms',
        ];

        switch (fields.memberType) {
            case 'student':
                requiredFields.push('studentId');
                break;

            case 'staff':
                requiredFields.push('staffId');
                break;

            case 'other':
                requiredFields.push(...['firstName', 'email', 'mobile']);
                break;

            default:
                break;
        }

        // console.log(requiredFields);
        // console.log('valid?', validateForm(1, requiredFields));

        if (validateForm(1, requiredFields) === true) {
            /* is valid */
            switch (fields.memberType) {
                case 'student':
                    findMember('FindSID', { id: fields.studentId, lastname: fields.lastName }).then(response => {
                        // console.log('Student', response);
                        if (String(response.status).startsWith('2') && response.response[0].Code === "00") {
                            // Found member successfully
                            const memberData = response.response[0];
                            console.log(memberData);
                            setMemberObject(memberData);
                            setFieldValue({
                                firstName: memberData.Firstname,
                                email: memberData.Email,
                                mobile: memberData.Mobile,
                                country: memberData.Country
                            });
                            setCurrentStep(2);
                            setFormErrorMsg(false);
                            setIsSending(false);
                        } else if (String(response.status).startsWith('2')) {
                            const errorMessage = translateErrorMessage(response.response[0]);
                            setFormErrorMsg(errorMessage);
                            setIsSending(false);
                        } else {
                            setFormErrorMsg("An unexpected error occurred. Please try again.");
                            setIsSending(false);
                        }
                    });
                    break;

                case 'staff':
                    findMember('FindStaffID', { id: fields.staffId, lastname: fields.lastName }).then(response => {
                        // console.log('Staff', response);
                        if (String(response.status).startsWith('2') && response.response[0].Code === "00") {
                            // Found member successfully
                            const memberData = response.response[0];
                            setMemberObject(memberData);
                            setFieldValue({
                                firstName: memberData.Firstname,
                                email: memberData.Email,
                                mobile: memberData.Mobile,
                                country: memberData.Country
                            });
                            setCurrentStep(2);
                            setFormErrorMsg(false);
                            setIsSending(false);
                            // window.scroll({top: 50, left: 0, behavior: 'smooth'});
                        } else if (String(response.status).startsWith('2')) {
                            const errorMessage = translateErrorMessage(response.response[0]);
                            setFormErrorMsg(errorMessage);
                            setIsSending(false);
                        } else {
                            setFormErrorMsg("An unexpected error occurred. Please try again.");
                            setIsSending(false);
                        }
                    });
                    break;

                case 'other':
                    findMember('FindMember', { email: fields.email, lastname: fields.lastName, firstname: fields.firstName, mobile: fields.mobile }).then(response => {
                        // console.log('non member', response);
                        if (String(response.status).startsWith('2') && (response.response[0].Code === "00" || response.response[0].Code === "01")) {
                            // Found member successfully
                            const memberData = response.response[0];
                            setMemberObject(memberData);
                            // console.log(memberData);
                            setFieldValue({
                                firstName: fields.firstName = '' ? memberData.Firstname : fields.firstName,
                                email: fields.email = '' ? memberData.Email : fields.email,
                                mobile: fields.mobile = '' ? memberData.Mobile : fields.mobile,
                                country: fields.country = '' ? memberData.Country : fields.country
                            });
                            stepTwo();
                        } else if (String(response.status).startsWith('2')) {
                            const errorMessage = translateErrorMessage(response.response[0]);
                            setFormErrorMsg(errorMessage);
                            setIsSending(false);
                        } else {
                            setFormErrorMsg("An unexpected error occurred. Please try again.");
                            setIsSending(false);
                        }
                    });

                    break;

                default:
                    break;
            }
        } else {
            setIsSending(false);
        }
    }

    const stepTwo = () => {
        // Check USUAPI to confirm data and fetch available membership options to display on step 3
        setIsSending(true);
        const requiredFields = [
            'firstName',
            'lastName',
            'email',
            'mobile'
        ];

        if (validateForm(2, requiredFields) === true) {
            // console.log('Fields:', fields);
            let sid = '';
            if (fields.memberType === 'student') sid = fields.studentId;
            else if (fields.memberType === 'staff') sid = fields.staffId;

            selectMembership(sid, (fields.memberType === 'student')).then(response => {
                // console.log(response);
                if (String(response.status).startsWith('2') && response.response.length > 0) {
                    setMembershipOptions(response.response);
                    setFormErrorMsg(false);
                    setIsSending(false);
                    setCurrentStep(3);
                } else {
                    setFormErrorMsg("No membership options are available for your account");
                    setIsSending(false);
                }
            });
        } else {
            setIsSending(false);
        }
    }

    const stepThree = (interests) => {
        // Select interests
        setFieldValue('interests', interests);
        // console.log(interests);
        // window.scroll({top: 50, left: 0, behavior: 'smooth'}); //USU-413
        setCurrentStep(4);
    }

    const stepFour = () => {
        // Select membership type and action accordingly
        setIsSending(true);
        const requiredFields = [
            'package'
        ];

        if (validateForm(4, requiredFields) === true) {
            const membershipLevel = membershipOptions.find(option => option.mid === fields.package);
            // console.log("fields", fields);
            const regoObject = {
                firstname: fields.firstName,
                lastname: fields.lastName,
                Mobile: fields.mobile,
                Email: fields.email,
                Country: fields.country,
                mid: fields.package,
                staffID: '',
                usydstaff: 0,
                sid: '',
                student: 0,
                interests: fields.interests
            };
            if (fields.memberType === 'student') {
                regoObject.sid = fields.studentId;
                regoObject.student = 1;
            } else if (fields.memberType === 'staff') {
                regoObject.staffID = fields.staffId;
                regoObject.usydstaff = 1;
            }

            if (membershipLevel.price === "0") {
                // Free membership flow
                // console.log('Free account creation started');
                handleRegistration(regoObject).then(() => {
                    // console.log('Free account created: ', response);
                    setIsSending(false);
                    setCurrentStep(4);
                    window.location = '/account';
                })
            } else {
                // Paid membership flow
                // console.log('Paid account creation started');
                regoObject.cart = '1';
                setStorage('memberInfo', JSON.stringify(regoObject), true);
                handleRegistration(regoObject).then(object => {
                    // console.log(object);
                    const memberNumber = object.response.object.usu.MemberNumber;
                    // console.log('Paid account created: ', memberNumber);
                    if (fields.package in productMap) {
                        bcApi(`catalog/products?sku:in=${productMap[fields.package]}`).then(resp => {
                            // console.log("Found product", resp);
                            if (String(resp.status).startsWith('2') && 'data' in resp.response) {
                                const product = resp.response.data[0];

                                bcApi(`catalog/products/${product.id}/modifiers`).then(response => {
                                    if (String(response.status).startsWith('2') && 'data' in response.response) {
                                        const result = {...response.response};
                                        // console.log(result);
        
                                        const optionSelections = result.data.map(option => {
                                            if (option.display_name === 'Member number') {
                                                return {
                                                    option_id: option.id,
                                                    option_value: memberNumber
                                                }
                                            } else return false;
                                        }).filter(o => o);
                                        // console.log(optionSelections);
                                        const cartItems = {
                                            line_items: [{
                                                quantity: 1,
                                                product_id: product.id,
                                                option_selections: optionSelections
                                            }]
                                        };
            
                                        bcApi('carts/items', 'POST', cartItems).then(resp2 => {
                                            if (String(resp2.status).startsWith('2') && 'data' in resp2.response) {
                                                window.location = '/confirm-order';
                                            } else {
                                                const errMessage = handleBcAddToCartError(resp2.response, resp2.status);
                                                setFormErrorMsg(errMessage);
                                                setIsSending(false);
                                            }
                                        });
                                    } else {
                                        setFormErrorMsg("An unexpected error occurred");
                                        setIsSending(false);
                                    }
                                });
                            } else {
                                setFormErrorMsg("An unexpected error occurred");
                                setIsSending(false);
                            }
                        })
                    } else {
                        setFormErrorMsg("An unexpected error occurred");
                        setIsSending(false);
                    }
                });
            }
        } else {
            setIsSending(false);
        }
    }

    // const moveStep = (step) => {
    //     if (step < currentStep) {
    //         setCurrentStep(step);
    //         setIsSending(false);
    //     }
    // }

    // if (isSubmitted) {
    //     return (
    //         <div className={styles.thankYou}>
    //             <h6>Thank you</h6>
    //             <p>Text</p>
    //         </div>
    //     )
    // } else {

      return (
        <div className={styles.formWrap}>
            {/* Removing steps - awaiting design (https://matterdesign.atlassian.net/browse/USU-485) */}
            {/* <div className={styles.steps}>  
                <span className={`${styles.step}${currentStep >= 1 ? ` ${styles.enabled}` : ''}`} onClick={() => moveStep(1)} role="presentation">1</span>
                <span className={`${styles.step}${currentStep >= 2 ? ` ${styles.enabled}` : ''}`} onClick={() => moveStep(2)} role="presentation">2</span>
                <span className={`${styles.step}${currentStep >= 3 ? ` ${styles.enabled}` : ''}`} onClick={() => moveStep(3)} role="presentation">3</span>
                <span className={`${styles.step}${currentStep >= 4 ? ` ${styles.enabled}` : ''}`} onClick={() => moveStep(4)} role="presentation">4</span>
            </div> */}

            <div className={styles.errorContainer}>
                {(formErrorMsg && (
                    <MessageAlert type="error" message={formErrorMsg} />
                ))}
            </div>

            {/* {(formWarnMsg && (
                <MessageAlert type="warn" message={formWarnMsg} />
            ))} */}

            <div id="signup-form" className={styles.signupForm}>
                {/* STEP 1 */}
                <div className={`${styles.step} ${styles.stepOne}${currentStep === 1 ? ` ${styles.active}` : ''}`}>
                    <div className={styles.contain}>
                        <div className={styles.reminderContainer}>
                            <p className={styles.membershipReminder}>Choose your Membership type. </p>
                            <span className={styles.subtitle}>
                                Not sure? <a target='_blank' rel='noopener noreferrer' href={'/membership'}><u>Learn more</u></a>
                            </span>
                        </div>
                        <SelectField
                            id={'memberType'}
                            handleChange={setFieldValue}
                            data={[
                                { value: '', text: 'Choose One' },
                                { value: 'student', text: 'Student' },
                                { value: 'staff', text: 'Staff' },
                                { value: 'other', text: 'Other' },
                            ]}
                        />
                        {/* <div className={`formField${(displayValidation && get(emptyError, 'step1.memberType')) ? ' error' : ''}`}>
                            <select
                                name="memberType"
                                onChange={(e) => setFieldValue('memberType', e.target.value)}
                            >
                                <option value=''>Member Type</option>
                                <option value="student">Student</option>
                                <option value="staff">Staff</option>
                                <option value="other">Other</option>
                            </select>
                            {(displayValidation && get(emptyError, 'step1.memberType')) && (
                                <span className={`error ${styles.error}`}>Please select a member type</span>
                            )}
                        </div> */}

                        {(fields && fields.memberType === 'student') && (
                            <div className={`formField${(displayValidation && get(emptyError, 'step1.studentId')) ? ' error' : ''}`}>
                                <input type="text" name='studentId' placeholder="Student ID" value={fields.studentId} onChange={(e) => {
                                    if (isNaN(e.target.value) === false) {
                                        setFieldValue('studentId', e.target.value);
                                    }
                                }} />
                                {(displayValidation && get(emptyError, 'step1.studentId')) && (
                                    <span className={`error ${styles.error}`}>Student ID is required</span>
                                )}
                            </div>
                        )}

                        {(fields && fields.memberType === 'staff') && (
                            <div className={`formField${(displayValidation && get(emptyError, 'step1.staffId')) ? ' error' : ''}`}>
                                <input type="text" name='staffId' value={fields.staffId} placeholder="Staff ID" onChange={(e) => {
                                    if (isNaN(e.target.value) === false) {
                                        setFieldValue('staffId', e.target.value);
                                    }
                                }} />
                                {(displayValidation && get(emptyError, 'step1.staffId')) && (
                                    <span className={`error ${styles.error}`}>Staff ID is required</span>
                                )}
                            </div>
                        )}

                        {(fields && fields.memberType === 'other') && (
                            <div className={`formField${(displayValidation && get(emptyError, 'step1.firstName')) ? ' error' : ''}`}>
                                <input type="text" name='firstName' placeholder="First Name" onChange={(e) => setFieldValue('firstName', e.target.value)} />
                                {(displayValidation && get(emptyError, 'step1.firstName')) && (
                                    <span className={`error ${styles.error}`}>First name is required</span>
                                )}
                            </div>
                        )}

                        {(fields && (fields.memberType === 'student' || fields.memberType === 'staff' || fields.memberType === 'other')) && (
                            <div className={`formField${(displayValidation && get(emptyError, 'step1.lastName')) ? ' error' : ''}`}>
                                <input type="text" name='lastName' placeholder="Last Name (Family Name)" onChange={(e) => setFieldValue('lastName', e.target.value)} />
                                {(displayValidation && get(emptyError, 'step1.lastName')) && (
                                    <span className={`error ${styles.error}`}>Last name is required</span>
                                )}
                            </div>
                        )}

                        {(fields && fields.memberType === 'other') && (
                            <>
                                <div className={`formField${(displayValidation && get(emptyError, 'step1.email')) ? ' error' : ''}`}>
                                    <input type="email" name='email' placeholder="Email Address" onChange={(e) => setFieldValue('email', e.target.value)} />
                                    {(displayValidation && get(emptyError, 'step1.email')) && (
                                        <span className={`error ${styles.error}`}>Email is required</span>
                                    )}
                                </div>

                                <div className={`formField${(displayValidation && get(emptyError, 'step1.mobile')) ? ' error' : ''}`}>
                                    <input type="text" name='mobile' placeholder="Mobile Number" value={fields.mobile} onChange={(e) => {
                                        // allow plus sign as a first character
                                        if(e.target.value === '+') {
                                            setFieldValue('mobile', e.target.value);
                                        } else if (isNaN(e.target.value) === false) {
                                            setFieldValue('mobile', e.target.value);
                                        }
                                    }} />
                                    {(displayValidation && get(emptyError, 'step1.mobile')) && (
                                        <span className={`error ${styles.error}`}>Mobile is required</span>
                                    )}
                                </div>
                            </>
                        )}

                        {(fields && fields.memberType && fields.memberType !== '') && (
                            <>
                                <div className={`${styles.terms} formField${(displayValidation && get(emptyError, 'step1.terms')) ? ' error' : ''}`}>
                                    <Checkbox
                                        name="terms"
                                        label="By joining the USU, you agree to the 
                                            <a target='_blank' rel='noopener noreferrer' href='https://assets.ctfassets.net/n92mdxt3dwn7/4mWhEK5TYOZil0ZLlPID3C/288f3a38fa4adc3555c73adbac8a662d/2022_USU_Membership-Terms_Conditions.pdf'><u>Terms and Conditions</u></a>,
                                            the 
                                            <a target='_blank' rel='noopener noreferrer' href='/terms-of-use'><u>Terms of Service</u></a> 
                                            and the 
                                            <a target='_blank' rel='noopener noreferrer' href='/privacy-policy'><u>Privacy Policy</u></a>."
                                        //  <a href='/terms-of-use'><u>Terms of Service</u></a> and <a href='/privacy-policy'><u>Privacy Policy</u></a>"
                                        action={(e) => setFieldValue('terms', e.target.checked)}
                                        isChecked={fields.terms}
                                    />
                                    {(displayValidation && get(emptyError, 'step1.terms')) && (
                                        <span className={`error ${styles.error}`}>Please agree to the above to continue</span>
                                    )}
                                </div>

                                <div className={`formField ${styles.buttonContainer}`}>
                                    <Button className={styles.formSubmit} level="primary" type="button" disabled={isSending} onClick={() => stepOne()}>{isSending ? 'Please wait...' : 'Next'}</Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* STEP 2 */}
                <div className={`${styles.step} ${styles.stepTwo}${currentStep === 2 ? ` ${styles.active}` : ''}`}>
                    <div className={styles.contain}>
                        <div className={styles.reminderContainer}>
                            <p className={styles.membershipReminder}>Please confirm your contact details</p>
                        </div>
                        {(memberObject.is_student === 'True' && memberObject.is_active === 'True') && (
                            <MessageAlert type="warn">
                                It looks like you're already a Member, your USU number is <strong>{memberObject.Membernumber}</strong><br />
                                You can <Button link={true} href="/login/">login</Button> or continue to upgrade to Annual Rewards
                            </MessageAlert>
                        )}
                        <div className={`formField${(displayValidation && get(emptyError, 'step2.firstName')) ? ' error' : ''}`}>
                            <input type="text" name='firstName' defaultValue={fields.firstName} placeholder="First Name" onChange={(e) => setFieldValue('firstName', e.target.value)} />
                            {(displayValidation && get(emptyError, 'step2.firstName')) && (
                                <span className={`error ${styles.error}`}>First name is required</span>
                            )}
                        </div>
                        <div className={`formField${(displayValidation && get(emptyError, 'step2.lastName')) ? ' error' : ''}`}>
                            <input type="text" name='lastName' defaultValue={fields.lastName} placeholder="Last Name (Family Name)" onChange={(e) => setFieldValue('lastName', e.target.value)} />
                            {(displayValidation && get(emptyError, 'step2.lastName')) && (
                                <span className={`error ${styles.error}`}>Last name is required</span>
                            )}
                        </div>
                        <div className={`formField${(displayValidation && get(emptyError, 'step2.email')) ? ' error' : ''}`}>
                            <input type="email" name='email' defaultValue={fields.email} placeholder="Email Address" onChange={(e) => setFieldValue('email', e.target.value)} />
                            {(displayValidation && get(emptyError, 'step2.email')) && (
                                <span className={`error ${styles.error}`}>Email is required</span>
                            )}
                        </div>
                        <div className={`formField${(displayValidation && get(emptyError, 'step2.mobile')) ? ' error' : ''}`}>
                            <input type="text" name='mobile' value={fields.mobile} placeholder="Mobile Number" onChange={(e) => {
                                // allow plus sign as a first character
                                if(e.target.value === '+') {
                                    setFieldValue('mobile', e.target.value);
                                } else if (isNaN(e.target.value) === false) {
                                    setFieldValue('mobile', e.target.value);
                                }
                            }} />
                            {(displayValidation && get(emptyError, 'step2.mobile')) && (
                                <span className={`error ${styles.error}`}>Mobile is required</span>
                            )}
                        </div>

                        <div className={`formField ${styles.buttonContainer}`}>
                            <Button className={styles.formSubmit} level="primary" type="button" disabled={isSending} onClick={() => stepTwo()}>{isSending ? 'Please wait...' : 'Next'}</Button>
                            <Button className={styles.formSubmit} type={'button'} level='secondary' onClick={() => setCurrentStep(currentStep-1)}>Back</Button>
                        </div>
                    </div>
                </div>

                {/* STEP 3 */}
                <div className={`${styles.step} ${styles.stepThree}${currentStep === 3 ? ` ${styles.active}` : ''}`}>
                    <div className={`${styles.reminderContainer} ${styles.reminderInterestForm}`}>
                        <p className={styles.membershipReminder}>Let us know your interests to get started...</p>
                    </div>
                    <InterestForm callback={stepThree} overrideBtnLabel="Next" btnStyle={styles.backButtonInterestForm} emptyText={'Skip and select later'} />
                    <div className={`${styles.backButtonContainerStepThree}`}>
                        <Button
                            type={'button'}
                            level='secondary'
                            className={styles.formSubmit}
                            onClick={() => setCurrentStep(currentStep-1)}
                            >
                            Back
                        </Button>
                    </div>
                </div>

                {/* STEP 4 */}
                <div className={`${styles.step} ${styles.stepFour}${currentStep === 4 ? ` ${styles.active}` : ''}`}>
                    <div className={styles.contain}>
                        <div className={`${styles.reminderContainer}`}>
                            <p className={styles.membershipReminder}>Select below (almost done!)</p>
                        </div>
                        {(membershipOptions && memberObject.is_student === 'True' && memberObject.is_active === 'True' && !membershipOptions.find(mo => mo.price === '0')) && (
                            <MessageAlert type="warn">
                                It looks like you're already a Member, your USU number is <strong>{memberObject.Membernumber}</strong><br />
                                You can <Button link={true} href="/login/">login</Button> or continue to upgrade to Annual Rewards
                            </MessageAlert>
                        )}
                        {membershipOptions && membershipOptions.map((option, oIndex) => (
                            <div
                                className={`formField ${styles.membershipOption}${(displayValidation && get(emptyError, 'step4.package')) ? ' error' : ''}`} key={oIndex}>
                                <Radio 
                                    divClick 
                                    value={option.mid}
                                    isChecked={option.mid === fields.package} 
                                    label={`<span class="${styles.optionName}">${option.name || option.Name}</span> - ${option.price === "0" ? "Free" : `$${formatPrice(option.price)}`}`} 
                                    name="package" 
                                    action={() => setFieldValue('package', option.mid)} 
                                />
                            </div>
                        ))}

                        {(displayValidation && get(emptyError, 'step4.package')) && (
                            <span className={`error ${styles.error}`}>Please select a membership option before being able to continue</span>
                        )}

                        <div className={`formField ${styles.buttonContainer}`}>
                            <Button className={styles.formSubmit} level="primary" type="button" disabled={isSending} onClick={() => stepFour()}>{isSending ? 'Please wait...' : 'Next'}</Button>
                            <Button
                                type={'button'}
                                level='secondary'
                                className={styles.formSubmit}
                                onClick={() => setCurrentStep(currentStep-1)}
                                >
                                Back
                            </Button>
                        </div>
                    </div>
                </div>

                <Dialog open={dialogMessage ? true : false} title="Account creation unsuccessful" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
            </div>
        </div>
    );
    // }
};

export default SignupForm;
