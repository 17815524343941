import React from 'react';

const ArrowDown = () => (
    <svg
    width={23}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 23 13'
  >
    <path
      d="m20.68 10.68-9.226-9.107-9.24 9.107"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowDown;
