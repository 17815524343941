/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react'; // , useContext, useCallback, useMemo
import { getStorage, useWindowDimensions } from '../../../helpers/general'
// import GraphQLContext from '../../../context/GraphQLProvider';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import Container from '../../atoms/Container/Container';
import Filters from '../Filters/Filters';
// import PageTitle from '../../atoms/PageTitle/PageTitle';
import ProductGrid from '../ProductGrid/ProductGrid';
import SortBy from '../SortBy/SortBy'
import Icon from '../../atoms/Icon/Icon';
// import { has } from 'lodash';
// import FeaturedProducts from '../components/organisms/FeaturedProducts/FeaturedProducts'
import Pagination from '../../atoms/Pagination/Pagination';
// import ProductCardLoading from '../../molecules/ProductCardLoading/ProductCardLoading';
import ProcessSearch from '../../molecules/Search/ProcessSearch.js';
import { dataLayerPush } from '../../../helpers/general';

import * as styles from './Catalog.module.css'
import CartContext from '../../../context/CartProvider.js';
import { get } from 'lodash';

const CatalogTemplate = props => {
    // const { category, brand, products, categories, pageContext, foundFilters, promos, allBrandData } = props;
    const { category, brand, products, categories, pageContext, foundFilters, promos } = props;
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(category);
    const [activeFilters, setActiveFilters] = useState({});
    const [currentFilters, setCurrentFilters] = useState({});
    const [currentProducts, setCurrentProducts] = useState([]);
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [pagination, setPagination] = useState(0);
    const [maxPage, setMaxPage] = useState(1);
    const [showFilter, setShowFilter] = useState(true);

    const [loading, setLoading] = useState(true);
    const [loadingPager, setLoadingPager] = useState(false);
    const [loadingSortBy, setLoadingSortBy] = useState(false);

    const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
    const [sortBy, setSortBy] = useState(urlParams.get('sortBy') && urlParams.get('sortBy') !== '' ? urlParams.get('sortBy') : getStorage('jamm_sortby'));

    const [showPagination, setShowPagination] = useState(false);
    const [paginationContext, setPaginationContext] = useState(false);

    const cartCtx = useContext(CartContext);
    const ctxLoadCart = get(cartCtx, 'loadCart');
    const [loadCart, setLoadedCart] = useState(false);

    const productsPerPage = 60;

    const [hideSidebar, setHideSidebar] = useState(false);
    // const graphQLContext = useContext(GraphQLContext);
    // const [productInventories, setProductInventories] = useState([]);
    const [idsForInventories, setIdsForInventories] = useState([]);
    const { width } = useWindowDimensions();


    // Turn on the option to hide filters that are not relevant to the products been shown
    if (brand) {
        foundFilters['brand'] = [brand.code];
    }
    const [relevantFilter, setRelevantFilter] = useState(foundFilters ? {
        colors: foundFilters.colors,
        price: foundFilters.price,
        brand: foundFilters.brand,
        sizes: foundFilters.sizes,
    //   filter_example: foundFilters.filter_example,
    } : {
        colors: [],
        price: [],
        brand: [],
        sizes: [],
        // filter_example: [],
    });

    const loadMore = (e, page) => {
        e.preventDefault();
        if (!loading) {
          if (page) {
            setPagination(page - 1);
            const _activeFilters = activeFilters;
            if (typeof _activeFilters[`page=${page}`] !== 'undefined') {
              delete _activeFilters[`page=${page}`];
            }
            _activeFilters[`page=${page - 1}`] = page - 1;
            setActiveFilters({..._activeFilters, cache: Math.random(10)})
          }
          setLoadingPager(true)
        }
    };

    const onSortBy = (e, param) => {
        if (e) e.preventDefault();
        if (!loadingSortBy) {
          setSortBy(param);
          setLoadingSortBy(true);
        }
    }

    const onFilterDiv = (e) => {
        if (e.target.tagName === 'DIV' && `${e.target.getAttribute('class')}`.indexOf('Catalog-module--filterWrap') > -1) {
          e.preventDefault();
          if (e.pageX > 260 && window.innerWidth < 768) {
            setHideSidebar(!hideSidebar)
          }
        }
    }

    const onFilterChange = (_filter) => {
        setActiveFilters({..._filter, cache: Math.random(10)})

        const _params = [];
        Object.keys(_filter).map(v => v.split('=')).map(v => {
            if (
                v[0] === 'colors' ||
                v[0] === 'price' ||
                v[0] === 'brand' ||
                v[0] === 'sizes' ||
                v[0].indexOf('filter_') === 0
            ) {
                _params[v[0]] = typeof _params[v[0]] !== 'undefined' ? [..._params[v[0]], v[1]] : [v[1]]
            }
            return v
        })
        const _query = Object.keys(_params).filter(k => k !== 'categories').map(k => encodeURIComponent(k) + '=' + encodeURIComponent(_params[k].join(','))).join('&');
        window.history.replaceState({}, ``, `${window.location.pathname}${_query !== '' ? `?${_query}` : ''}`)
    };

    // checks for active  filter
    const checkForActiveFilter = (filter) => {
        let result = false;
        const activeFilterKeys = Object.keys(activeFilters).filter((af) => {
            if(af.substring(0,5) === filter) return af;
            return undefined;
        });

        if(activeFilterKeys.length !== 0) {
            result = true;
        }
        return result;
    }

    const constructBreadcrumbs = (startCategory) => {
        const tempBreadcrumb = [];

        let tempCategory = startCategory;
        let parentId = tempCategory.parent_id;

        do {
            const newBreadcrumbs = {
                link: tempCategory.custom_url.url,
                label: tempCategory?.name,
            }
            tempBreadcrumb.push(newBreadcrumbs);
            if(tempCategory.parent_id === 0) {
                tempBreadcrumb.push({label: 'Shop', link: '/shop/all'});
                return tempBreadcrumb.reverse();
            }

            parentId = tempCategory.parent_id;
            //eslint-disable-next-line
            tempCategory = categories.find(c => c.bigcommerce_id === parentId);

        } while (parentId !== 0);

        return tempBreadcrumb;
    }

    useMemo(() => {
        if (
          !loadCart &&
          typeof window !== 'undefined' &&
          window.location.search.indexOf('cartId') > -1
        ) {
          setLoadedCart(true);
          const cartId = window.location.search.replace('?cartId=', '');
          ctxLoadCart(cartId);
        }
    
      }, [ctxLoadCart, loadCart]);

    /**
     * Main useEffect
     */
    useEffect(() => {
        // Init

        // Set Breadcrumbs
        if (category && categories && breadcrumbs.length === 0) {
            const breadcrumbRaw = {};
            // let breadcrumbStart = categories.find(cat => cat.code === category.code).parentIdentifier;
            // let breadcrumbData = [{label: (category && category.labels) ? category.labels.en_AU : ''}];
            let breadcrumbStart = categories.find(cat => cat.bigcommerce_id === category.bigcommerce_id)?.parent_id;
            let breadcrumbData = [{label: (category && category.name) ? category.name : ''}];
            categories.map(cat => {
                // breadcrumbRaw[cat.code] = cat;
                breadcrumbRaw[cat.bigcommerce_id] = cat;
                return true;
            });
            while (breadcrumbStart !== false) {
                if (breadcrumbStart in breadcrumbRaw) {
                    // if (breadcrumbRaw[breadcrumbStart].path && breadcrumbRaw[breadcrumbStart].labels) {
                    if (breadcrumbRaw[breadcrumbStart].name) {
                        // breadcrumbData.push({ link: breadcrumbRaw[breadcrumbStart].path.replace(/_/g, '-'), label: breadcrumbRaw[breadcrumbStart].labels.en_AU });
                        // breadcrumbStart = breadcrumbRaw[breadcrumbStart].parentIdentifier;
                        let crumb = { label: breadcrumbRaw[breadcrumbStart].name };
                        if (breadcrumbRaw[breadcrumbStart].custom_url) {
                            let url = typeof breadcrumbRaw[breadcrumbStart].custom_url === 'object' ? breadcrumbRaw[breadcrumbStart].custom_url.url : breadcrumbRaw[breadcrumbStart].custom_url;
                            crumb = {...crumb, link: url.replace(/_/g, '-') };
                        }

                        breadcrumbData.push(crumb);
                        breadcrumbStart = breadcrumbRaw[breadcrumbStart].parent_id;
                    } else {
                        // Prevent infinite loop if dirty data is sent
                        breadcrumbStart = false;
                    }
                } else {
                    breadcrumbStart = false;
                }
            }

            if (category && category.bigcommerce_id !== 84) {
                breadcrumbData.push({label: 'Shop', link: '/shop/all'});
            }
            breadcrumbData.reverse();
            setBreadcrumbs(breadcrumbData);
        }

        if (brand && breadcrumbs.length === 0) {
            setBreadcrumbs([{ link: '/', label: 'Home' }, { link: '/brands/', label: 'Brands' }, {label: brand ? brand.values.label[0].data : '' }]);
        }

        if (initialLoad && (filteredProduct.length === 0 || sortBy !== '')) {
            setInitialLoad(false);
            const detectedFilters = window ? `${window.location.search.substring(1)}`.split('&').filter(f => {
                if (f === '') return false;

                const queryStr = f.split('=');
                if (
                    queryStr[0] === 'colors' ||
                    queryStr[0] === 'price' ||
                    queryStr[0] === 'brand' ||
                    queryStr[0] === 'sizes' ||
                    queryStr[0].indexOf('filter_') === 0
                ) {
                    return true;
                }

                return false;
            }) : [];
            // console.log(detectedFilters);
            if (detectedFilters.length > 0 || sortBy !== '') {
                setFilteredProduct([]);
            } else {
                setFilteredProduct(products);
                // console.log("Num pages greater than 1", pageContext.numberOfPages > 1)
                setShowPagination(pageContext.numberOfPages > 1)
                setIdsForInventories([...new Set([...idsForInventories, ...products.map(a => typeof a.node !== 'undefined' ? a.node : a).map(a => a.product_id)])])
            }
            setLoading(false);
            window.history.replaceState({}, ``, `${window.location.pathname}${window.location.search.split('&').filter(a => a.indexOf('cache=') < 0).join('&')}`);

        } else if ((!initialLoad && JSON.stringify(currentFilters) !== JSON.stringify(activeFilters)) || loadingPager || loadingSortBy) {
            const _paginationLoader = loadingPager;

            setLoadingSortBy(false)
            setLoadingPager(false)
            setCurrentFilters(activeFilters);
            const _filters = [];
            const _numericFilters = [];
            let overridePagination = false;
            Object.keys(activeFilters).map(a => {
                const explode = a.split('=');
                const key = explode[0];
                let value = explode[1];

                if (value && value.indexOf('range+') > -1) {
                    const subValue = value.split('+');
                    value = subValue[1];

                    if (typeof _numericFilters[key] === 'undefined') {
                        _numericFilters[key] = [value];
                    } else {
                        _numericFilters[key].push(value);
                    }
                } else  if (value && key.indexOf('price') > -1) {

                    if (typeof _numericFilters[key] === 'undefined') {
                        _numericFilters[key] = [value];
                    } else {
                        _numericFilters[key].push(value);
                    }
                } else {
                    if (typeof _filters[key] === 'undefined') {
                        _filters[key] = [value];
                    } else {
                        _filters[key].push(value);
                    }
                }
                return a;
            })

            if (typeof _filters['page'] !== 'undefined') {
                overridePagination = _filters['page'] - 1;
                setPagination(overridePagination);
                delete _filters['page'];
            }

            if (typeof _filters['categories'] !== 'undefined' || (currentCategory && 'bigcommerce_id' in currentCategory)) {
                _filters['product_categories.entityId'] = _filters['categories'] || [currentCategory.bigcommerce_id];
                delete _filters['categories']

                if (_filters['product_categories.entityId'] && _filters['product_categories.entityId'][0] !== 'undefined') {
                    const _existingCats = categories.filter(a => `${a.bigcommerce_id}` === _filters['product_categories.entityId'][0]);
                    if (_existingCats.length) {
                        // console.log('Setting Current Category', _existingCats);
                        setCurrentCategory(_existingCats[0])
                        pageContext.categoryPath = _existingCats[0].custom_url?.url || _existingCats[0].path;
                        window.history.replaceState({}, ``, `${_existingCats[0].custom_url?.url || _existingCats[0].path}${window.location.search}`)

                        const tempBreadcrumbs = constructBreadcrumbs(_existingCats[0]);
                        setBreadcrumbs(tempBreadcrumbs);
                        // console.log('Breadcrumb: ',x);
                        // console.log(breadcrumbs);
                        // setBreadcrumbs([
                        //     {link: '/shop', label: 'Shop'}
                        // ]);

                    }
                } else {
                    delete _filters['product_categories.entityId']
                }
            }

            if (brand) {
                _filters['brand'] = [brand.code];
            }

            // Insert the category in filters query
            if (category && category.code && typeof _filters['categories'] === 'undefined') {
                _filters['categories'] = [category.code];
            }

            if (typeof _filters['cache'] !== 'undefined') {
                delete _filters['cache']
            }
            if (typeof _filters['sortBy'] !== 'undefined') {
                delete _filters['sortBy']
            }

            setLoading(true)
            let outputPage = overridePagination ? overridePagination : (_paginationLoader ? pagination : 0);
            if (outputPage < 0) outputPage = 0;

            const filterQuery = Object.keys(_filters).filter(a => a !== '')
                .map((a) => `(${_filters[a].map((b) => `${a}:'${b}'`).join(' OR ')})`)
                .join(' AND ');

            const numFilterQuery = Object.keys(_numericFilters).filter(a => a !== '')
                .map((b, a) => {
                    const filters = _numericFilters[b].map((condition) => `${b}: ${condition}`)
                    return filters;
                });
            // console.log("Attrs:", productsPerPage, filterQuery, sortBy, numFilterQuery, outputPage);
            ProcessSearch('', productsPerPage, {
                page: outputPage,
                filters: filterQuery,
                sortBy: sortBy,
                rangeNumFilters: numFilterQuery
            }).then((results) => {
                setLoading(false)
                setTimeout(() => {
                    if (window) {
                        window.scroll(0, 0)
                    }
                }, 400);

                let colorList = [];
                let sizeList = [];
                let filterObj = {
                    colors: [],
                    price: [],
                    brand: relevantFilter.brand ? [relevantFilter.brand] : [],
                    // filter_example: [],
                    ...relevantFilter,
                };

                let currentPage = 0;
                let currentPublicPage = 1;
                let totalPages = 0;
                let perPage = productsPerPage;

                if (results.items.length) {
                    // console.log("Results object", results);
                    const products = results.items.find(item => 'products' in item);
                    const productsPagination = 'products' in results.totals ? results.totals.products : {
                        totalItems: 0,
                        totalPages: 0,
                        currentPage: 0,
                        perPage: 0
                    };
                    // console.log("Products object", products);
                    // console.log("Products Pagination", productsPagination);
                    if (products) {
                        const displayProducts = 'allItems' in products ? products.allItems?.map(k => {
                            if(Array.isArray(k.options) && k.options?.length !== 0) {

                                const colorOptions = k.options.filter((bcOption) => bcOption.type === 'swatch').map((bcColor) => {
                                    let colorArray = [];
                                    if(Array.isArray(bcColor.option_values) && bcColor.option_values.length !== 0) {
                                        colorArray = bcColor.option_values.map((color) => color.label);
                                    }
                                    return colorArray;
                                })
                                // filterObj.colors = colorOptions[0];
                                colorList = [...colorList, ...colorOptions[0]];
                            }

                            // Object.keys(filterObj).map(filter => {
                            //     filterObj[filter] = (k[filter] !== null && k[filter] !== undefined) ? (
                            //             Array.isArray(k[filter]) ?
                            //             [...new Set([...filterObj[filter], ...k[filter]])] :
                            //             [...new Set([...filterObj[filter], k[filter]])]
                            //         ) :
                            //         [...new Set([...filterObj[filter]])];
                            //     return true;
                            // });

                            // console.log(k);
                            if (!('brandData' in k)) {
                                // let thisBrand = allBrandData.filter((bd) => {
                                //     return bd.id === k.productId;
                                // })

                                // k.brandData = allBrandData[k.productId] ? allBrandData[k.productId].brandData : null;
                            }
                            k.imagesData = k.images;
                            // console.log(filterObj);
                            return k
                        }) : ('products' in products ? products.products?.map(k => {
                            colorList  = [...colorList, ...k.colors];;
                            sizeList  = [...sizeList, ...k.sizes];;
                            // if(Array.isArray(k.options) && k.options?.length !== 0) {


                            //     const colorOptions = k.options.filter((bcOption) => bcOption.type === 'swatch').map((bcColor) => {
                            //         let colorArray = [];
                            //         if(Array.isArray(bcColor.option_values) && bcColor.option_values.length !== 0) {
                            //             colorArray = bcColor.option_values.map((color) => color.label);
                            //         }
                            //         return colorArray;
                            //     })
                            //     // filterObj.colors = colorOptions[0];
                            //     if (colorOptions[0]) {
                            //         colorList = [...colorList, ...colorOptions[0]];
                            //     }
                            // }

                            Object.keys(filterObj).map(filter => {
                                filterObj[filter] = (k[filter] !== null && k[filter] !== undefined) ? (
                                        Array.isArray(k[filter]) ?
                                        [...new Set([...filterObj[filter], ...k[filter]])] :
                                        [...new Set([...filterObj[filter], k[filter]])]
                                    ) :
                                    [...new Set([...filterObj[filter]])];
                                return true;
                            });

                            // if (!('brandData' in k)) {
                            //     // let thisBrand = allBrandData.filter((bd) => {
                            //     //     return bd.id === k.productId;
                            //     // })

                            //     k.brandData = allBrandData[k.productId] ? allBrandData[k.productId].brandData : null;
                            // }
                            k.imagesData = k.images;
                            return k
                        }) : []);

                        // if (_paginationLoader) {
                        //   setFilteredProduct([...filteredProduct, ...(results.items.length ? results.items[0].products : [])]);
                        // } else {

                       // setFilteredProduct(products);
                        setFilteredProduct(displayProducts);
                        // }
                        if (products.allItems) {
                            setIdsForInventories([...new Set([...idsForInventories, ...displayProducts.map(b => b.entityId)])])
                        }

                        currentPage = productsPagination.currentPage;
                        currentPublicPage = productsPagination.currentPage + 1;
                        // console.log("Product total pages:", productsPagination.totalPages);
                        totalPages = productsPagination.totalPages;
                        perPage = productsPagination.perPage;
                    }
                }

                // console.log('Refining Filters', filterObj);
                // console.log(colorList);
                // post process filter object; check how each filter maps
                // Turn on the option to hide filters that are not relevant to the products been shown
                // const x = JSON.parse(activeFilters);
                //console.log(has(activeFilters, 'colors'));

                // do not recurse filters on colors
                if(colorList.length !== 0) {
                    if(checkForActiveFilter('color') === false || filterObj.colors?.length === 0) {
                        filterObj.colors = [...new Set(colorList)];
                    }
                } else {
                    filterObj.colors = [];
                }

                // do not recurse filters on sizes
                if(sizeList.length !== 0) {
                    if(checkForActiveFilter('sizes') === false || filterObj.sizes?.length === 0) {
                        filterObj.sizes = [...new Set(sizeList)];
                    }
                } else {
                    filterObj.sizes = [];
                }

                setRelevantFilter(filterObj)
                // console.log("Filtered", currentPublicPage, results, results.items[0].totalPages, results.items[0].totalPages > 1)
                setPagination(currentPublicPage)
                setMaxPage(totalPages);
                // console.log("Total Pages: ", totalPages);
                setShowPagination(totalPages > 1);
                const filterPath = `${pageContext.categoryPath}${typeof window !== 'undefined' ? window.location.search : ''}`
                setPaginationContext({
                    categoryPath: filterPath,
                    limit: perPage,
                    numberOfPages: totalPages,
                    pageNumber: currentPage,
                    nextPagePath: currentPublicPage < totalPages ? `${filterPath}&page=${(currentPage + 2)}` : '',
                    previousPagePath: currentPage > 0 ? `${filterPath}&page=${currentPage}` : '',
                    queryStringPaging: true,
                    onClick: loadMore
                })
                window.history.replaceState({}, ``, `${window.location.pathname}${window.location.search.split('&').filter(a => a.indexOf('page=') < 0).join('&')}${currentPublicPage > 1 ? `&page=${currentPublicPage}` : ''}`);
            })
            // console.log(currentCategory);

        }

        if (filteredProduct && !initialLoad && JSON.stringify(filteredProduct) !== JSON.stringify(currentProducts)) {
            dataLayerPush(
                'productImpressions',
                null,
                filteredProduct,
                null,
                currentCategory
            );

            setCurrentProducts(filteredProduct)
        }
    }, [
        category,
        categories,
        products,
        activeFilters,
        filteredProduct,
        setFilteredProduct,
        setInitialLoad,
        initialLoad,
        currentFilters,
        setCurrentFilters,
        setActiveFilters,
        pagination,
        setPagination,
        maxPage,
        setMaxPage,
        loading,
        setLoading,
        loadingPager,
        setLoadingPager,
        loadingSortBy,
        setLoadingSortBy,
        sortBy,
        setShowPagination,
        setCurrentCategory,
        idsForInventories,
    ])

    const promotionalPositions = [8, 18];

    // console.log('Breadcrumbs:', breadcrumbs);

    return (
        <div className={styles.root}>
            <Container size="large">
                <div className={`${styles.gridBody} ${showFilter === true ? 'grid-2080' : ''}`}>
                    {/* {console.log('List of Filters: ',relevantFilter)} */}
                    <div className={`${styles.filterBar} ${showFilter === false ? styles.hide : ''}`} onClick={onFilterDiv} role="presentation">
                        <Filters categories={categories} onChange={onFilterChange} relevantFilter={relevantFilter} foundFilters={foundFilters} />
                    </div>

                    <div className={`${styles.productCards} ${showFilter && styles.filterActive}`}>
                        <div className={styles.breadcrumbOverrides}>
                            <Breadcrumbs crumbs={breadcrumbs} />
                            {/* to do: tie this to category */}
                            {/* <Breadcrumbs crumbs={breadcrumbs.slice(0,1)} /> */}
                        </div>
                        <div className={styles.gridHeading}>
                            <div className={styles.upper}>
                                <div className={styles.titleContainer}>
                                    {category && (
                                        <h3>{currentCategory.name}</h3>
                                    )}
                                    {brand && (
                                        <h3>TODO: Locate Brand object</h3>
                                    )}
                                </div>
                                <div className={styles.categoryControls}>
                                    <div className={styles.filterToggle} role={'presentation'} onClick={() => setShowFilter(!showFilter)}>
                                        <span className={styles.controlLabel}>{showFilter ? 'Hide Filter' : 'Show Filter'}</span>
                                        <Icon symbol={'filter'}></Icon>
                                    </div>
                                    <div onClick={onFilterDiv} role="presentation">
                                        <Filters categories={categories} onChange={onFilterChange} relevantFilter={relevantFilter} foundFilters={foundFilters} />
                                    </div>
                                    <SortBy className={styles.sortButton} onChange={onSortBy} currentSort={sortBy}></SortBy>
                                </div>
                            </div>
                            {category && currentCategory && currentCategory.description && (
                                <div className={styles.details}>
                                    <div className={styles.categoryDescription} dangerouslySetInnerHTML={{ __html: currentCategory.description }}></div>
                                </div>
                            )}
                        </div>

                        {filteredProduct && (
                            <ProductGrid columns={width < 1100 ? 2 : showFilter === true ? 2 : 3} products={filteredProduct} inventories={[]} promotionalPositions={promotionalPositions} promotional={promos} helpBar={true} options={{ withFilters : true, cardLoading: loading }} currentCategory={currentCategory}/>
                        )}

                        {filteredProduct.length === 0 && !initialLoad && (
                            <>
                                <div className={styles.noResults}>{loading ? 'Loading...' : 'Oops, it looks like we can’t find what you’re looking for.'}</div>
                            </>
                        )}
                        {showPagination && pageContext && !paginationContext && 'categoryPath' in pageContext && ( // && Object.keys(relevantFilter).length === 0
                            <Pagination pageContext={pageContext} pathPrefix={pageContext.categoryPath.slice(0, -1)} />
                        )}
                        {showPagination && paginationContext && 'categoryPath' in paginationContext && ( //pagination <= maxPage &&
                            <Pagination pageContext={paginationContext} pathPrefix={paginationContext.categoryPath} />
                        )}

                        {/* <div className={styles.gridHeading}>
                            {category && (
                                <PageTitle titleClass="h3" title={category.categoryTitle || category.name} />
                            )}
                            {brand && (
                                <PageTitle titleClass="h3" title="TODO: Locate brand object" />
                            )}
                            {seoContent && (
                                <div className={styles.categoryDescription} dangerouslySetInnerHTML={{ __html: seoContent }}></div>
                            )}
                        </div> */}
                    </div>
                </div>
            </Container>
        </div>
    )
  }

  export default CatalogTemplate