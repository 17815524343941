import React, { useCallback, useEffect, useState } from 'react';

import FilterBlock from '../../molecules/FilterBlock/FilterBlock'
import Button from '../../atoms/Button/Button';
import Filter from '../../atoms/Icon/filter';
import Icon from "../../atoms/Icon/Icon";

import * as styles from './Filters.module.css';

//import filters from './Filters.json'; // EXAMPLE STRUCTURE
import filters from './FilterData.json'; // Algolia generated

const Filters = ({categories = [], relevantFilter = {}, stateEvent = () => {}, onChange = () => {}}) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeFilters, setActiveFilters] = useState({});
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const clearFilters = () => {
        onChange({});
        setActiveFilters({});
        setTimeout(() => {
            window.location.reload()
        }, 200)
    }

    const toggleFilter = (element, object = null, emptyFilters = false) => {
        let name, key, label, value, min, max;

        if (object !== null) {
            name = object.name;
            min = object.min;
            max = object.max;
            label = object.name;
            key = `${name}=range+${min} TO ${max}`;
        } else {
            name = element.target.name;
            value = element.target.value;
            label = element.target.dataset.label;
            key = `${name}=${value}`;
        }

        // if(emptyFilters === true) {
        //     setActiveFilters({});
        //     onChange({});
        //     return;
        // }
        
        const newFilters = activeFilters;
        if (key in newFilters) {
            // Remove option
            delete newFilters[key];
        } else {
            newFilters[key] = label
        }

        if ((typeof element.target.type !== 'undefined' && element.target.type === 'radio') || object) {
            Object.keys(newFilters).filter(a => a.indexOf(`${name}=`) > -1).map(a => {
                delete newFilters[a];
                return a;
            });
            newFilters[key] = label
        }

        onChange(newFilters);
        setActiveFilters({...newFilters});
    }

    const removeFilter = (element, key) => {
        element.stopPropagation();
        const newFilters = activeFilters;
        delete newFilters[key];

        onChange(newFilters);
        setActiveFilters({...newFilters});
    }

    const initComp = useCallback(() => {
        if (initialLoad) {
            const _params = [];
            `${window.location.search.substring(1)}`.split('&').filter(v => v !== '').map(v => {
                const queryStr = v.split('=');
                if (
                    queryStr[0] === 'colors' || 
                    queryStr[0] === 'price' || 
                    queryStr[0] === 'brand' || 
                    queryStr[0] === 'sizes' || 
                    queryStr[0].indexOf('filter_') === 0
                ) {
                    _params[queryStr[0]] = decodeURIComponent(queryStr[1]).split(',')
                }
                return v;
            })
    
            if (Object.keys(_params).length) {
                const _buildQry = {};
                Object.keys(_params).map(v => {
                    _params[v].map(w => {
                        _buildQry[`${v}=${w}`] = w;
                        return w
                    })
                    return v
                })

                if (window.location.pathname !== '') {
                    const existingCat = categories?.filter(a => {
                        if (a.custom_url) {
                            return a.custom_url.url === window.location.pathname
                        } else if (a.path) {
                            return a.path === window.location.pathname
                        } else {
                            return false;
                        }
                    });
                    if (existingCat && existingCat.length) {
                        _buildQry[`categories=${existingCat[0].bigcommerce_id}`] = existingCat[0].name;
                    }
                }

                setActiveFilters(_buildQry);
                setInitialLoad(false);
                setTimeout(() => {
                    onChange(_buildQry)
                }, 400)
            }
        }
    }, [
        onChange,
        initialLoad,
        categories,
    ]);

    useEffect(() => {
        if (initialLoad) {
            initComp();
            setInitialLoad(false);
        } else if (stateEvent) {
            stateEvent(activeFilters);
        }
    }, [
        activeFilters,
        stateEvent,
        setInitialLoad,
        initialLoad,
        initComp,
    ])

    useEffect(() => {
        if (isFilterOpen) {
            document.body.classList.add('disable-scrolling');
        } else {
            document.body.classList.remove('disable-scrolling');
        }
    }, [isFilterOpen]);


    return (
        <div data-filters className={styles.filters}>
            <div className={styles.mobileSection}>
                <span className={styles.mobileInitButton} onClick={() => setIsFilterOpen(!isFilterOpen)} role="presentation">
                    Filter <Filter/>
                </span>
            </div>

            <div className={styles.clearFilterContainer}>
                <span role={'presentation'} onClick={() => clearFilters()}>Clear Filters</span>
            </div>

            <div className={`${styles.filterSection} ${isFilterOpen ? styles.isActive : ''}`}>
                <div className={styles.innerContainer}>
                    <div className={styles.sideFilterHeader}>
                        <h6 className={`body2`}>Filter</h6>
                        <span onClick={() => setIsFilterOpen(!isFilterOpen)} role="presentation"><Icon symbol="close" /></span>
                    </div>
                    {filters && filters.map((a) => {
                        
                        if (categories && a.field === 'categories') {
                            return {...a, values: categories}
                        }
                        // if (a.field === 'brand') {
                        //     return {...a, values: relevantFilter.brand}
                        // }
                        return a;
                    }).map((a) => {
                        // console.log(relevantFilter);
                        if (Object.keys(relevantFilter).length) {
                            // console.log(relevantFilter);
                            // console.log("Rev filter:", relevantFilter);
                            // console.log("a Field:", a.field);
                            const theField = Object.keys(relevantFilter).find(rf => a.field === rf && typeof relevantFilter[rf] !== 'undefined')
                            // console.log('the field: ',theField);
                            
                            // console.log("The Field:", theField);
                            // console.log("a:", a);
                            // console.log("rev field, the field", relevantFilter[theField]);

                            if (theField && 'values' in a && theField !== 'price') {
                                // console.log( 'HIT: ',{...a, values: a.values.filter(v => relevantFilter[theField].indexOf(v.key) > -1)});
                                // const test = a.values.filter((v) => relevantFilter[theField].indexOf(v.key) > -1);
                                // console.log('Test:', relevantFilter[theField], a.values, {...a, values: a.values.filter(v => relevantFilter[theField].indexOf(v.key) > -1)});
                                // return {...a};
                                // console.log('the field: ', theField, relevantFilter[theField], a.values, a.values.filter(v => relevantFilter[theField].indexOf(v.key) > -1));
                                a.values.filter(v => {
                                    if (theField === 'colors') {
                                        // console.log('v', relevantFilter, relevantFilter[theField].indexOf(v.key) > -1)
                                    }
                                    return relevantFilter[theField].indexOf(v.key) > -1
                                })
                                return {...a, values: a.values.filter(v => relevantFilter[theField].indexOf(v.key) > -1)}
                            }

                            if (theField && 'min' in a && 'max' in a) {
                                let minValue = null;
                                let maxValue = null;

                                // if (theField === 'price') {
                                //     minValue = a.min;
                                //     maxValue = a.max;
                                // }

                                relevantFilter[theField].map(val => {
                                    const convertNum = parseFloat(val);
                                    if (minValue === null || convertNum < minValue) minValue = convertNum;
                                    if (maxValue === null || convertNum > maxValue) maxValue = convertNum;
                                    return true;
                                });
                                // console.log(theField, minValue, maxValue);
                                return {...a, min: minValue, max: maxValue }
                            }
                        }
                        return a;
                    }).map((filter, filterIndex) => {
                        
                        // console.log("filter:", filter);
                        if ((filter.values && filter.values.length > 0) || (filter.min > -1 && filter.max > -1 && filter.min !== filter.max)) {
                            
                            return (
                                <div className={styles.filterBlock} key={filterIndex} data-values={filter.values ? filter.values.length : -1}>
                                    <FilterBlock filter={filter} setter={toggleFilter} getter={activeFilters} removeFilter={removeFilter} clearFilters={clearFilters} />
                                </div>
                            )
                        } else return null
                    })}
                </div>
                <div className={styles.sideFilterFooter}>
                    <span onClick={clearFilters} className={styles.mobileInitButton} role="presentation">
                        Clear filters
                    </span>
                    <Button onClick={() => setIsFilterOpen(!isFilterOpen)} type="button" theme="primary" size="small">Show results</Button>
                </div>
            </div>
        </div>
    )
};

export default Filters;