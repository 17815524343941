import React, { useContext, useEffect, useMemo, useState } from 'react';
import AuthContext from '../../../context/AuthProvider';
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../../atoms/Container/Container';
import Tabs from '../../molecules/Tabs/Tabs';

import * as styles from './EventsTab.module.css';
import Icon from '../../atoms/Icon/Icon';

const subMenuData = [
  {
    itemKey: 'all-events',
    label: 'All events',
    uri: '/events/'
  },
];

const EventsTab = ({
  children,
  pageId,
  setFilter
}) => {
  const auth = useContext(AuthContext);
  const userChecked = auth && auth.state.userChecked;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const [subMenu, setSubMenu] = useState([]);
  const eventCategoryQuery = useStaticQuery(graphql`
    query EventCategory {
      allWpEventCategory(sort: {fields: eventCategoryOptions___sort}) {
        nodes {
          name
          slug
        }
      }
    }
  `);
  const dynamicCategories = useMemo(() => {
    return (eventCategoryQuery.allWpEventCategory.nodes || []).map(category => {
      return {
        itemKey: category.slug,
        label: category.name,
        uri: `/events/${category.slug}`
      }
    });
  }, [eventCategoryQuery]);
  
  useEffect(() => {
    const _subMenuData = [...subMenuData];
    if (userChecked && isLoggedIn) {
      _subMenuData.push({
        itemKey: 'events-for-you',
        label: 'For you',
        uri: '/events/for-you'
      })
    }
    _subMenuData.push(...dynamicCategories);
    const items = _subMenuData?.map(item => ({
      uri: item.uri,
      label: item.label,
      itemKey: item.itemKey,
      active: item.itemKey === pageId
    }));
    
    setSubMenu([...items]);
  }, [pageId, userChecked, isLoggedIn, dynamicCategories]);

  return (
    <Container>
      <div className={styles.root}>
        <div className={styles.categoryContainer}>
          <Tabs items={subMenu} noWrap={false} align="left" isDropDown />
        </div>
        <div className={styles.filterContainer}>
          <Icon symbol={'calendar'}></Icon>
          <select onChange={(e) => setFilter(e.target.value)}>
            <option value={'future'}>Upcoming</option>
            <option value={'month'}>This Month</option>
          </select>
          <div className={styles.caretIconContainer}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 5.56L2.413 5H13.607L14 5.54L8.373 11H7.546L2 5.56Z" fill="black" />
            </svg>
          </div>
        </div>
      </div>
      {children}
    </Container>
  );
};

export default EventsTab;
