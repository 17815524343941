import React from 'react';
import * as styles from './Swatch.module.css';

const Swatch = (props) => {
  const {data, isActive, handleVariantChange} = props;

  const bgColor = data?.value_data?.colors[0];
  return (
    <div 
      className={`${styles.root} ${isActive === true ? styles.isActive: ''}`} 
      onClick={() => {
        handleVariantChange(data);
      }}
      role="presentation"
    >
      <div style={{backgroundColor: bgColor}} className={styles.circle}></div>
    </div>
  );
};

export default Swatch;
