import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { transformWpEvent } from '../helpers/wordpress';
import Events from '../components/organisms/Events/Events';

const WPEvent = ({ data }) => {
    const { wpEvent: rawEvent } = data;
    const event = transformWpEvent(rawEvent);

    const metaTitle = event.seo.title ? event.seo.title : `${event.title} | University of Sydney Union`;
    const metaDescription = event.seo.metaDesc ? event.seo.metaDesc : event.excerpt;
    
    return (
        <Layout disablePaddingBottom={true}>
            <Seo title={metaTitle} description={metaDescription} />
            <Events event={event} />

        </Layout>
    );
};

export default WPEvent

export const pageQuery = graphql`
  query EventByID($id: String!) {
    wpEvent(id: { eq: $id }) {
        id
        databaseId
        status
        uri
        slug
        title
        content
        excerpt
        featuredImage {
            node {
                publicUrl
                sourceUrl
                localFile {
                    childImageSharp {
                      fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
                        src
                      }
                    }
                }
            }
        }
        eventFieldsMain {
            dayTripLength
            dayTripTimes
            dietaryRequirements
            domesticInternational
            findOutDayTrips
            futureDayTrips
            limitRegistrationAmount
            enableTickets
        }
        eventFieldsSide {
            startDate
            endDate
            startTime
            endTime
            eventFee
            location
            registrationOverride
            ctaLabel
            recurringEvent
            recurringInfo {
                repeatDays
                repeatTimes
                repeatType
            }
            timeSlot
            roomName
            club {
                ... on WpClub {
                    title
                    uri
                    id: databaseId
                    clubFieldsSide {
                        logo {
                          sourceUrl
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 300, quality: 100) {
                                src
                              }
                            }
                          }
                        }
                        stripeConnectId
                    }
                }
            }
            bannerImage {
                sourceUrl
                localFile {
                    childImageSharp {
                      fixed(width: 2000, height: 635, cropFocus: CENTER) {
                        src
                      }
                    }
                }
            }
        }
        seo {
            title
            metaDesc
            metaKeywords
            opengraphTitle
            opengraphDescription
            opengraphImage {
                publicUrl
                sourceUrl
            }
            twitterTitle
            twitterDescription
            twitterImage {
                publicUrl
                sourceUrl
            }
        }
        eventTags {
            nodes {
                uri
                name
            }
        }
    }

    allWpEvent(sort: {fields: eventFieldsSide___startDate, order: ASC}) {
        nodes {
            id
            status
            uri
            slug
            title
            eventFieldsSide {
                startDate
                endDate
                club {
                    ... on WpClub {
                        id
                    }
                }
            }
        }
    }
  }
`;
