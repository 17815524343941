import React from 'react';
import ReactPlayer from 'react-player'

import { mapPaddingValues, normalizeContainerSize } from '../../../helpers/contentful';
import Container from '../../atoms/Container/Container';

import * as styles from './ContentfulBlockVideo.module.css';

const ContentfulBlockVideo = props => {

  const { padding, containerSize, videoLink } = props;

  const contentStyling = {};
  contentStyling['padding'] = mapPaddingValues(padding);

  return (
    <div className={ styles.ContentfulBlockVideo } style={contentStyling}>
      <Container size={normalizeContainerSize(containerSize)}>
        <div className={styles.videoContainer}>
          <ReactPlayer className={styles.reactPlayer} height={'100%'} width={'100%'} url={videoLink} />
        </div>
      </Container>
    </div>
  );
};

export default ContentfulBlockVideo;
