import React, { useCallback, useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash";
import parse from 'html-react-parser';
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import { persistLocation } from "../../../../../helpers/general";
import { wpAllNew, authClubExec } from "../../../../../helpers/wordpress";
import * as styles from '../../usu.module.css';
import { decode } from "he";

const Notices = ({location}) => {
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [notifications, setClubNotifications] = useState(null);
    const [highlighted, setClubHighlighted] = useState(null);

    const fetchClubNotifications = useCallback(async () => {
        const notifications = await wpAllNew('getNotificationsList', {
            callId: 1,
            fieldSet: 1
        });
        if (String(notifications.status).startsWith('2')) {
            const _notifications = [];
            const _highlighted = [];
            
            notifications.response.map(n => {
                if (n.acf.highlight) {
                    _highlighted.push({
                        text: n.title.rendered,
                        content: n.content.rendered
                    });
                } else {
                    _notifications.push({
                        text: n.title.rendered,
                        content: n.content.rendered
                    });
                }

                return true;
            })
          setClubNotifications(_notifications);
          setClubHighlighted(_highlighted);
        }
      }, []);

    useMemo(() => {
        
        if (isAuthed === null || (isAuthed &&  club && club.id !== get(location, 'state.clubId'))) {

            authClubExec(get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    fetchClubNotifications();
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, location, fetchClubNotifications , club]);

    return (
        <>
            {(notifications || highlighted) ? (
                <div className={`${styles.root} ${styles.withBg}`}>
                    {highlighted && (
                        <div className={styles.highlighted}>
                            {highlighted.map((h, hI) => (
                                <div className={styles.highlight}>
                                    <h5>{decode(h.text)}</h5>
                                    {parse(h.content)}
                                </div>
                            ))}
                        </div>
                    )}

                    {notifications && (
                        <div className={styles.notifications}>
                            {notifications.map((n, nI) => (
                                <div className={styles.notice} key={nI}>
                                    <h6>{decode(n.text)}</h6>
                                    {parse(n.content)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club notices */}
                    {((isAuthed === null && club === null) || (isAuthed === true && club === null)) && (
                        <div>Fetching your club notices</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementNotices = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Club Notices" breadcrumbTitle="Club Notices" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <Notices location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementNotices