/**
 * USU API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Bepoz API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { usuApi } from '../helpers/usuapi'

    usuApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function usuApi(endpoint, method, body) {
    const options = {
        method: method ? method : 'GET',
        credentials: 'same-origin',
        mode: 'same-origin'
    };
    
    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
        bodyString = JSON.stringify(body);
        }
    
        options.body = bodyString;
    }
    
    const parseJson = async response => {
        const text = await response.text();
        try {
        const json = JSON.parse(text);
        return json;
        } catch (err) {
        return text;
        }
    };
    
    const encodedEndpoint = Buffer.from(endpoint).toString('base64');
    
    if (!(typeof fetch === 'undefined')) {
        return await fetch(
        `${process.env.LAMBDA_PATH}usuapi?endpoint=${encodedEndpoint}`,
        options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
    } else {
        return {response: null, status: 404}
    }
}

/**
 * Test
 * 
 * Test the connection to the USU API
 * 
 */
async function test() {
    const response = await usuApi('helloworld');
    // console.log("USUAPI Helper - Test function response:", response);

    return response;
}

/**
 * Sign in
 * 
 * Provide a user mobile number and either their SID, member number or email address
 * 
 * @param {Number} mobile       Mobile number for the user
 * @param {String} identifier   Either user SID, Member number or email address
 */
async function signIn(mobile, identifier) {
    const response = await usuApi('signin', 'POST', {
        mobile,
        var: identifier
    });

    // console.log("USUAPI Helper - Signin function response:", response);

    return response;
}

/**
 * SMS Code
 * 
 * Provide the USU member number to confirm that have access to the mobile on the account
 * 
 * @param {Number} memberNumber         Member Number
 * 
 * @return {Number} code                The generated code the user needs to enter
 */
 async function smsCode(memberNumber) {
    const response = await usuApi('smsCode', 'POST', {
        memberNumber
    });

    // console.log("USUAPI Helper - SMS Code function response:", response);

    return response;
}

/**
 * Find Member
 * 
 * Provide a user mobile number and either their SID, member number or email address
 * 
 * @param {String} searchType   Either FindSID, FindStaffID or FindMember
 * @param {Object} searchFields Depending on the searchType. For FindSID or FindStaffID = {id: '', lastname: ''}. For FindMember = {membernumber: '' OR email: '', lastname: ''}
 */
 async function findMember(searchType, searchFields) {
    const response = await usuApi('findMember', 'POST', {
        function: searchType,
        ...searchFields
    });

    // console.log("USUAPI Helper - FindMember function response:", response);

    return response;
}

/**
 * Select Membership
 * 
 * List available membership options for a given student ID
 * 
 * @param {Number} studentId    ID of the student - can be blank for non-students
 * @param {Boolean} isStudent   Is a student or not (could be staff)
 */
 async function selectMembership(studentId, isStudent) {
    const response = await usuApi('selectMembership', 'POST', {
        id: studentId,
        is_student: isStudent
    });

    // console.log("USUAPI Helper - SelectMembership function response:", response);

    return response;
}

/**
 * Online Process
 * 
 * Register user to the USU systems
 * 
 * @param {Object} memberData    Member data Object containing a minimum of first and last names, mobile, email and identifying value for their member type
 */
async function onlineProcess(memberData) {
    const response = await usuApi('onlineProcess', 'POST', memberData);

    // console.log("USUAPI Helper - OnlineProcess function response:", response);

    return response;
}

/**
 * Profile
 * 
 * Get user profile data
 * 
 * @param {String} USUNumber    USU member number
 */
async function getProfile(USUNumber) {
    const response = await usuApi('profile', 'POST', {
        USUNumber
    });

    // console.log("USUAPI Helper - GetProfile function response:", response);

    return response;
}

/**
 * Update Profile
 * 
 * Update user profile data
 * 
 * @param {Object} object    Object containing the data to update on the user
 * 
 * Example object:
 * {
 *  USUNumber: 123456,
 *  firstname: 'First',
 *  lastname: 'Last',
 *  mobile: '0400123456',
 *  email: 'see@example.com'
 * }
 */
 async function updateProfile(object) {
    const response = await usuApi('updateProfile', 'POST', object);

    // console.log("USUAPI Helper - UpdateProfile function response:", response);

    return response;
}

/**
 * Grads
 * 
 * Get user graduation information
 * 
 * @param {String} StudentID    Student ID
 */
 async function getGradsInfo(StudentID) {
    const response = await usuApi('grads', 'POST', {
        id: StudentID
    });

    // console.log("USUAPI Helper - GetGradsInfo function response:", response);

    return response;
}

/**
 * Room Hire
 * 
 * Get club information regarding to room hire
 * 
 * @param {String} ClubID    Club ID
 */
 async function getRoomHireInfo(ClubID) {
    const response = await usuApi('roomHire', 'POST', {
        id: ClubID
    });

    // console.log("USUAPI Helper - GetRoomHireInfo function response:", response);

    return response;
}

/**
 * Locker Hire
 * 
 * Get user information regarding to locker hire
 * 
 * @param {String} USUNumber    USU member number
 */
async function getLockerHireInfo(USUNumber) {
    const response = await usuApi('lockerHire', 'POST', {
        USUNumber
    });

    // console.log("USUAPI Helper - GetLockerHireInfo function response:", response);

    return response;
}

/**
 * Language Exchange
 * 
 * Get user information regarding to language exchange
 * 
 * @param {String} USUNumber    USU member number
 */
 async function getLangExchangeInfo(USUNumber) {
    const response = await usuApi('languageExchange', 'POST', {
        USUNumber
    });

    // console.log("USUAPI Helper - GetLangExchangeInfo function response:", response);

    return response;
}

/**
 * Reset Password
 * 
 * Send instructions how to reset your password
 * 
 * @param {String} Mobile    Mobile Number
 */
 async function sendResetInstructions(mobile) {
    const response = await usuApi('sendResetInstructions', 'POST', {
        mobile
    });

    return response;
}

async function getSpacioResource(object) {
    const response = await usuApi('getSpacioResource', 'POST', object);

    return response;
}

async function postSpacioResource(object) {
    const response = await usuApi('postSpacioResource', 'POST', object);

    return response;
}

async function getSpacioBooking(object) {
    const response = await usuApi('getSpacioBooking', 'POST', object);

    return response;
}

async function deleteSpacioBookings(object) {
    const response = await usuApi('deleteSpacioBookings', 'POST', object);

    return response;
}

async function updateSpacioBookingsStatus(object) {
    const response = await usuApi('updateSpacioBookingsStatus', 'POST', object);

    return response;
}

async function processFundingData(object) {
    const response = await usuApi('processFundingData', 'POST', object);

    return response;
}

async function getFunction(object) {
    const response = await usuApi('getFunction', 'POST', object);

    return response;
}


/**
 * Translates error response to a more friendly message
 * 
 * Send instructions how to reset your password
 * 
 * @param {Object} Response     Response
 */
 function translateErrorMessage(response) {

    // return the server response by default
    let message = response.Description;

    switch (response.Code) {
        case '11':
            message = 'Invalid Student ID (SID) format. Please enter your 9-digit SID number.';
            break;
        case '13':
            message = 'Invalid Staff ID format. Please check your number and try again.';
            break;
        default:
            break;
    }

    return message;
}

export { 
    usuApi,
    test,
    signIn,
    smsCode,
    findMember,
    selectMembership,
    onlineProcess,
    getProfile,
    updateProfile,
    getGradsInfo,
    getRoomHireInfo,
    getLockerHireInfo,
    getLangExchangeInfo,
    sendResetInstructions,
    translateErrorMessage,
    getSpacioResource,
    postSpacioResource,
    getSpacioBooking,
    deleteSpacioBookings,
    updateSpacioBookingsStatus,
    processFundingData,
    getFunction
};
      