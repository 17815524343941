/**
 * Ortto Helper
 **/

const fieldMap = {
  first: "str::first",
  last: "str::last",
  phone: "phn::phone",
  email: "str::email",
  city: "geo::city",
  country: "geo::country",
  birthday: "dtz::b",
  region: "geo::region",
  postal: "str::postal",
  externalId: "str::ei",
  gdpr: "bol::gdpr",
  MemberNumber: "str:cm:membernumber",
  membernumber: "str:cm:membernumber",
  mid: "str:cm:mid",
  lastregistered: "tme:cm:lastregistered",
  expiry: "dtz:cm:expiry",
  student: "bol:cm:student",
  usyd: "bol:cm:usyd",
  lifemember: "bol:cm:lifemember",
  usustaff: "bol:cm:usustaff",
  usustafflevel: "bol:cm:usustafflevel",
  bepozid: "str:cm:bepozid",
  barcode: "str:cm:barcode",
  membername: "str:cm:membername",
  sid: "str:cm:sid",
  countryorigin: "str:cm:countryorigin",
  memberstatus: "str:cm:memberstatus",
  location: "str:cm:location",
  faculty: "str:cm:faculty",
  vectronid: "str:cm:vectronid",
  specialname: "str:cm:specialname",
  // "some-field": "str:cm:some-field"
}

/**
 * API Connect
 *
 * Make a call to the API
 * 
 * @param   {String} endpoint       The Ortto API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { orttoApi } from '../helpers/ortto'

    orttoApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function orttoApi(endpoint, method, body) {
  const options = {
    method: method ? method : 'GET'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }
    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  const encodedEndpoint = Buffer.from(endpoint).toString('base64');

  return await fetch(
    `${process.env.LAMBDA_PATH}ortto?endpoint=${encodedEndpoint}`,
    options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Create or Update one or more person records
 * 
 * Pass an array with one or more person objects
 * 
 * https://help.ortto.com/developer/latest/api-reference/person/merge.html
 * 
 * @param   {Array} people      An array of person objects
 * 
 * @return  {}                  Result
 * 
    import { updatePerson } from '../helpers/ortto'

    const people = [
      {
        first: "firstname",
        last: "lastname",
        phone: "phonenumber",
        email: "emailaddress",
        city: "city",
        country: "country",
        birthday: new Date('m/d/Y'),
        region: "region/state",
        postal: "postcode",
        externalId: "someId",
        gdpr: false,
        customFields: {
          "some-field": "some value" // kebab-case keys required
        },
        tags: ["tag1", "tag2"],
        unset_tags: ["tag3"]
      }
    ]
    const updateResponse = updatePerson(people, 'externalId');
    console.log(updateResponse);
 */
async function updatePerson(people, mergeBy = 'email') {
  if (!Array.isArray(people)) return "Incorrect format passed in. An Array of person objects is required. See documentation for guidance."

  const peopleObjs = people.map((person) => {
    const returnFields = {};

    // Set fields for person
    const personFields = {};
    if (person.first) personFields["str::first"] = person.first;
    if (person.last) personFields["str::last"] = person.last;
    if (person.phone) {
      // TODO: Create ability to determine international prefixes
      personFields["phn::phone"] = {
        c: "61",
        n: person.phone.substring(1)
      }
    }
    if (person.email) personFields["str::email"] = person.email;
    if (person.city) personFields["geo::city"] = { name: person.city };
    if (person.country) personFields["geo::country"] = { name: person.country };
    if (person.birthday) {
      if (!(person.birthday instanceof Date)) {
        person.birthday = new Date(person.birthday);
      }

      personFields["dtz::b"] = {
        year: person.birthday.getFullYear(),
        month: (person.birthday.getMonth() + 1),
        day: person.birthday.getDate(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    if (person.region) personFields["geo::region"] = { name: person.region };
    if (person.postal) personFields["str::postal"] = person.postal;
    if (person.externalId) personFields["str::ei"] = person.externalId;
    if (person.gdpr) personFields["bol::gdpr"] = person.gdpr;

    if (person.customFields && Object.keys(person.customFields).length > 0) {
      Object.keys(person.customFields).map((customField) => {
        const cm = (customField in fieldMap) ? fieldMap[customField] : `str:cm:${customField}`;
        personFields[cm] = person.customFields[customField];
        return true;
      });
    }

    returnFields.fields = personFields;

    // Set tags for person
    const personTags = [];
    if (person.tags && Array.isArray(person.tags)) personTags.push(...person.tags);
    else if (person.tags && !Array.isArray(person.tags) && person.tags.length > 0) personTags.push(person.tags);
    if (personTags.length > 0) returnFields.tags = personTags;

    // Set tags to unset for person
    const personUnsetTags = [];
    if (person.unset_tags && Array.isArray(person.unset_tags)) personUnsetTags.push(...person.unset_tags);
    else if (person.unset_tags && !Array.isArray(person.unset_tags) && person.unset_tags.length > 0) personUnsetTags.push(person.unset_tags);
    if (personUnsetTags.length > 0) returnFields.unset_tags = personUnsetTags;

    return returnFields;
  });

  // console.log("Ortto People OBJ", peopleObjs);

  const _mergeBy = mergeBy === 'externalId' ? ['str::ei'] : ['str::email'];

  return await orttoApi('person/merge', 'POST', {
    "people": peopleObjs,
    "merge_by": _mergeBy,
    // "merge_strategy": 1 
  });
}
/**
 * Checks and creates custom fields if they don't exist
 * @param {*} data the custom fields
 * @returns 
 */
const processCustomFields = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fieldsToCheck = data.map((field) => {
        if (!['first-name', 'email', 'last-name'].includes(field.type)) return field.type;
        return undefined
      }).filter(Boolean);

      // Get existing custom-fields
      const { response } = await orttoApi('person/custom-field/get ', 'POST', {});

      // Check form fields against existing ortto fields
      // and return only non-existing fields
      const fieldsToPost = fieldsToCheck.map((field) => {
        const findFromOrtto = response.fields.find(oField => oField.field.id === `str:cm:${field}`)
        if (!findFromOrtto) {
          return {
            "type": "text",
            "name": field
          }
        }
        return undefined
      }).filter(Boolean);



      if (fieldsToPost.length > 0) {
        const fieldPromises = [];
        fieldsToPost.forEach(element => {
          fieldPromises.push(orttoApi('person/custom-field/create', 'POST', element));
        });
        await Promise.all(fieldPromises);
      }

      resolve(true)
    } catch (error) {
      reject(false)
    }
  });
}

/**
 * Get one or more person records
 * 
 * Pass an array with with criteria to fetch one or more person objects
 * 
 * https://help.ortto.com/developer/latest/api-reference/person/get.html
 * 
 * @param   {Array} fields      An array of person fields to return
 * @param   {String} filterValue The value to search for. Leave blank to fetch all
 * @param   {String} filter     The field to filter the fetch by to ensure field is populated
 * @param   {String} sortBy     The field to sort the fetch by
 * @param   {String} sortOrder  Direction of the sort
 * @param   {String} offset     The amount of records to start from
 * @param   {String} limit      The amount of records to return
 * 
 * @return  {Object}            An object of contacts
 * 
    import { getPerson } from '../helpers/ortto'

    const fields = [
      "first",
      "last",
      "phone",
      "email",
      "city",
      "country",
      "birthday",
      "region",
      "postal",
      "externalId",
      "gdpr",
      "some-field"
    ]
    const getResponse = getPerson(fields, "an-email@example.com");
    console.log(getResponse);
 */
async function getPerson(fields, filterValue = null, filter = 'str::email', sortBy = 'str::last', sortOrder = 'asc', offset = 0, limit = 500, rollingContacts = []) {
  const fetchFields = ["tags" ,'u4s::t'];
  fields.map(field => {
    if (field in fieldMap) {
      fetchFields.push(fieldMap[field]);
    } else {
      fetchFields.push(field);
    }
    return true;
  });

  const fetchFilter = () => {
    if (filter === 'audience_id') {
      return { audience_id: filterValue };
    } else if (filter === 'tag_id') {
      return {
        filter: {
          "$u4s::is": {
            "field_id": "u4s::t",
            "value": filterValue
          }
        }
      };
    } else if (filterValue) {
      if (Array.isArray(filterValue)) {
        return {
          filter: {
            "$and": [{
              "$or": filterValue.map(v => (
                {
                  "$str::is": {
                    "field_id": filter,
                    "value": v
                  }
                }
              ))
            }]
          }
        };
      } else {
        return {
          filter: {
            "$str::is": {
              "field_id": filter,
              "value": filterValue
            }
          }
        };
      }
    } else {
      return {
        filter: {
          "$has_any_value": {
            "field_id": filter
          }
        }
      };
    }
  }

  const people = await orttoApi('person/get', 'POST', {
    limit: limit,
    sort_by_field_id: sortBy,
    sort_order: sortOrder,
    offset: offset,
    fields: fetchFields,
    ...fetchFilter()
  });
 
  if (String(people.status).startsWith("2") && people.response.has_more) {
    const _rollingContacts = [...rollingContacts, ...people.response.contacts];
    return getPerson(fields, filterValue, filter, sortBy, sortOrder, people.response.next_offset, limit, _rollingContacts);
  } else {
    if (Array.isArray(people.response.contacts)) {
      people.response.contacts = [...rollingContacts, ...people.response.contacts];
    } else {
      people.response.contacts = [...rollingContacts];
    }

    return people;
  }
}

/**
 * Retrieve peoples subscription statuses
 * 
 * Pass an array of people with their email and/or audience ID
 * 
 * https://help.ortto.com/developer/latest/api-reference/person/subscriptions.html
 * 
 * @param   {Array} people      An array of persons with their email
 * @param   {String} audienceId The audience ID to return results for
 * 
 * @return  {Object}            An object of contacts
 * 
    import { getAudienceStatus } from '../helpers/ortto'

    const people = [
      "an-email@example.com"
    ]
    const getResponse = getAudienceStatus(people, audienceId);
    console.log(getResponse);
 */
async function getAudienceStatus(people, audienceId = null) {
  const fetch = {};

  if (audienceId) fetch.audience_id = audienceId;

  if (people && Array.isArray(people) && people.length > 0) {
    fetch.people = people.map(person => {
      return { email: person }
    })
  }

  return await orttoApi('person/subscriptions', 'POST', fetch);
}

/**
 * Subscribe or unsubscribe people to/from an audience
 * 
 * Pass an array of people with their email and subscription preference for an audience ID
 * 
 * https://help.ortto.com/developer/latest/api-reference/audience/subscribe.html
 * 
 * @param   {Array} people      An array of persons with their email
 * @param   {String} audienceId The audience ID to return results for
 * 
 * @return  {Object}            An object of contacts
 * 
    import { subscribeToAudience } from '../helpers/ortto'

    const people = [{
      email: "an-email@example.com",
      subscribed: true
    }]
    const putResponse = subscribeToAudience(audienceId, people);
    console.log(putResponse);
 */
async function subscribeToAudience(audienceId, people) {
  if (!audienceId || (!Array.isArray(people) || people.length > 0)) return "Incorrect format passed in. An Array of person objects is required. See documentation for guidance."

  return await orttoApi('audience/subscribe', 'PUT', {
    audience_id: audienceId,
    people: people
  });
}

/**
 * Create Activity
 * 
 * Track actions of people by setting an new activity
 * 
 * https://help.ortto.com/developer/latest/developer-guide/custom-activities-guide.html#create-an-activity-in-ortto
 * 
 * @param   {String} activityName       A name for the activity being tracked in kebab case
 * @param   {String} userEmail          User email to append the activity to
 * @param   {Object} attributes         Attributes to attach to the activity
 * 
 * @return  {Object}                    An object of contacts
 * 
    import { createActivity } from '../helpers/ortto'

    const attributes = {
      "some-field": "some-value",
    }
    const postResponse = createActivity(activityName, userEmail, attributes);
    console.log(postResponse);
 */
async function createActivity(activityName, userEmail, attributes) {
  if (!userEmail) return "Incorrect data passed in. User email is required."

  const attributeFields = {};
  if (attributes) {
    attributeFields.attributes = {};
    Object.keys(attributes).map(attr => {
      attributeFields.attributes[`str:cm:${attr}`] = attributes[attr];

      return true;
    });
  }

  return await orttoApi('activities/create', 'POST', {
    activities: [
      {
        activity_id: `act:cm:${activityName}`,
        fields: {
          "str::email": userEmail
        },
        ...attributeFields
      }
    ]
  });
}

/**
 * Send a transactional email
 * 
 * Send email to a person or people
 * 
 * https://help.ortto.com/developer/latest/developer-guide/using-the-api-to-send-emails.html
 * 
 * @param   {Object} emailOptions       List of override options for the email to be sent
 * @param   {Array} emailRecipients     List of recipients to receive the email
 * 
 * @return  {Object}                    An object of contacts
 * 
    import { sendEmail } from '../helpers/ortto'

    const emailOptions = {
      html_body: "<html><head></head><body>EXAMPLE</body></html>",
      subject: "Testing emailer for {{ people.first-name }}",
      email_name: "test-email"
    };

    const emailRecipients = [{
      email: "an-email@example.com",
      first: "Bob",
      last: "Smith"
    }]

    const postResponse = sendEmail(emailOptions, emailRecipients);
    console.log(postResponse);
 */
async function sendEmail(emailOptions, emailRecipients) {
  if (typeof emailOptions !== 'object' || Array.isArray(emailOptions) || emailOptions === null) return "Incorrect format passed in. An Object for email options with a minimum of html_body being required. See documentation for guidance."
  const emailDefaults = {
    from_email: process.env.ORTTO_FROM_EMAIL,
    from_name: process.env.ORTTO_FROM_NAME,
    subject: `Information from ${process.env.GENERAL_PROJECT_NAME} for you, {{ people.first-name }}`,
    email_name: 'default-email',
    liquid_syntax_enabled: true
  }

  const asset = { ...emailDefaults, ...emailOptions };

  const people = emailRecipients.map(person => {
    const fields = {
      'str::email': person.email
    };

    if ('first' in person) fields['str::first'] = person.first;
    if ('last' in person) fields['str::last'] = person.last;

    return {
      fields: fields
    };
  });

  const limit = 100;
  const total = people.length;
  const results = [];

  for (let count = 0; count < total; count += limit) {
    const segment = people.slice(count, count+limit);
    const postData = {
      asset: asset,
      emails: segment,
      skip_non_existing: true, // To prevent making changes to people in CDP as this process is meant to send emails only
      merge_strategy: 1 // To prevent making changes to people in CDP as this process is meant to send emails only
    }
    // console.log('postData', postData)

    const result = await orttoApi('transactional/send', 'POST', postData);
    results.push(result);
  }

  const emails = [];
  results.map(r => {
    emails.push(...r.response.emails);
    return true;
  });

  return {
    status: results[0].status,
    response: {
      emails: emails
    }
  };
}

/**
 * Search Tags
 * 
 * Fetch all available tags or filter with a query
 * 
 * https://help.ortto.com/developer/latest/developer-guide/
 * 
 * @param   {String} searchTerm         Include to filter out to specific tags
 * 
 * @return  {Object}                    List of found tags
 * 
    import { getTags } from '../helpers/ortto'

    const postResponse = getTags('search-term');
    console.log(postResponse);
 */
async function getTags(searchTerm) {
  const query = searchTerm ? {
    q: searchTerm
  } : null;

  return await orttoApi('tags/get', 'POST', query);
}

/**
 * Format date for Ortto
 * 
 * Return the appropriate date format to send to Ortto
 * 
 * https://help.ortto.com/developer/latest/developer-guide/
 * 
 * @param   {String} date               Either Date object or a valid date format
 * 
 * @return  {Object}                    DTZ object
 * 
    import { formatDate } from '../helpers/ortto'

    const postResponse = formatDate('2023-01-18 12:00:00 AM');
    console.log(postResponse);
 */
function formatDate(date) {
  let _date = date;
  if (!(date instanceof Date)) {
    _date = new Date(date);
  }

  return {
    year: _date.getFullYear(),
    month: (_date.getMonth() + 1),
    day: _date.getDate(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}

export {
  orttoApi,
  updatePerson,
  getPerson,
  getAudienceStatus,
  subscribeToAudience,
  createActivity,
  sendEmail,
  processCustomFields,
  getTags,
  formatDate
};
