import React from 'react'

const Filter = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.46484 10.5H13.5363" stroke="#0E0E10" strokeWidth="1.5" strokeLinecap="round"/>
    <ellipse cx="5.64425" cy="10.5" rx="1.85714" ry="2" fill="white" stroke="#0E0E10" strokeWidth="1.2"/>
    <path d="M14.5 3.5H1.5" stroke="#0E0E10" strokeWidth="1.5" strokeLinecap="round"/>
    <circle r="2" transform="matrix(-1 0 0 1 10 3.5)" fill="white" stroke="#0E0E10" strokeWidth="1.2"/>
  </svg>
)

export default Filter