const EmbeddedForm = `
<div id="653701830ac92cc29fdf50c4" style="width: 100%; height: 100%;">
    <div id="653701830ac92cc29fdf50c4-form" class="653701830ac92cc29fdf50c4-template"
        style="position: relative; display: flex; height: 100%; align-items: center; justify-content: center;">
        <style>
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 {
                box-sizing: content-box;
                width: 100%;
                font-size: 16px;
                max-width: 450px;
                max-height: 100%;
                overflow: auto;
                background-color: #fff56d;
                border: 2px solid transparent;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0);
                z-index: unset;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-contained {
                max-width: 300px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4:before {
                content: ' ';
                display: block;
                position: absolute;
                pointer-events: none;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0%;
                background-image: url();
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-contained {
                max-width: 300px;
            }

            .ap3w-embeddable-form-content {
                margin: auto;
                padding: 32px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-top {
                top: 0;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-bottom {
                bottom: 0;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-rounded {
                border-radius: 12px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-text {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-text * {
                padding-bottom: 5px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video {
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill {
                margin: 0 -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill.ap3w-video--first {
                margin: -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill.ap3w-video--last {
                margin: -32px;
                margin-top: 20px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image {
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill {
                margin: 0 -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill.ap3w-image--first {
                margin: -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill.ap3w-image--last {
                margin: -32px;
                margin-top: 20px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-reaction {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form .ap3w-input input[type=text],
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form .ap3w-input input[type=email] {
                margin-bottom: 12px;
            }

            .ap3w-embeddable-form-tcpa-wrapper {
                position: relative;
                text-align: center;
                margin-top: 12px;
            }

            .ap3w-embeddable-form-tcpa__text {
                color: #000000;
                margin: 0;
                font-size: 11px;
                line-height: 14px;
            }
        </style>
        <style>
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form-input-hidden {
                display: none;
            }
        </style>
        <div id="selected-_wl03d85u7"
            class=" ap3w-embeddable-form-653701830ac92cc29fdf50c4 ap3w-embeddable-form-653701830ac92cc29fdf50c4-full ap3w-embeddable-form-653701830ac92cc29fdf50c4-solid "
            data-select="true">
            <form id="ap3w-embeddable-form-653701830ac92cc29fdf50c4" class="ap3w-embeddable-form-content">
                <style>
                    .ap3w-text-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin: 0;
                        margin-bottom: 16px;
                        word-wrap: break-word;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4.ap3w-text--last {
                        margin-bottom: 0 !important;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 * {
                        margin: 0;
                        padding-bottom: 8px;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 *:last-child {
                        padding-bottom: 0 !important;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 a {
                        color: #000000;
                        text-decoration: underline;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h1,
                    .ap3w-text-653701830ac92cc29fdf50c4 h2,
                    .ap3w-text-653701830ac92cc29fdf50c4 h3,
                    .ap3w-text-653701830ac92cc29fdf50c4 h4,
                    .ap3w-text-653701830ac92cc29fdf50c4 h5,
                    .ap3w-text-653701830ac92cc29fdf50c4 h6,
                    .ap3w-text-653701830ac92cc29fdf50c4 p,
                    .ap3w-text-653701830ac92cc29fdf50c4 div>ul {
                        text-transform: unset;
                        text-decoration: unset;
                        text-indent: unset;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h1"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 28px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h2"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 18px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h3"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h4"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h5"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h6"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h1 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 28px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h2 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 18px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h3 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h4 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h5 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h6 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="large"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="small"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 div>ul {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }
                </style>
                <div id="selected-_jnw8yyqal" class="ap3w-text ap3w-text-653701830ac92cc29fdf50c4 ap3w-text--first ">
                    <div data-select="true">
                        <p data-size="h1">Stay in touch with USU</p>
                        <p data-size="large">We look forward to sharing upcoming alumni events and news as we approach
                            our 150 year anniversary </p>
                    </div>
                </div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_12k0n54eo" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str::email" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="email"><label
                        for="ap3w-form-input-email-653701830ac92cc29fdf50c4" class="ap3w-form-input-label">Email
                        address*</label><input type="email" id="ap3w-form-input-email-653701830ac92cc29fdf50c4" step="1"
                        name="Email address" required=""></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_0z35ixqoi" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str::last" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4" class="ap3w-form-input-label">Last name
                        (Family name)*</label><input type="text" id="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        step="1" name="Last name (Family name)" required=""></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_6jnsedfib" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str::first" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4" class="ap3w-form-input-label">First name
                        (Given name)*</label><input type="text" id="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        step="1" name="First name (Given name)" required=""></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_jj8qz3m4i" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str:cm:preferred-name" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4" class="ap3w-form-input-label">Preferred
                        Name</label><input type="text" id="ap3w-form-input-text-653701830ac92cc29fdf50c4" step="1"
                        name="Preferred Name"></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_u9j7bp8zj" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str:cm:faculty" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-hide-if-value-exists="true" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        class="ap3w-form-input-label">Faculty</label><input type="text"
                        id="ap3w-form-input-text-653701830ac92cc29fdf50c4" step="1" name="Faculty"></div>
                <style>
                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                    }

                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 input[type="checkbox"] {
                        position: absolute;
                        margin: 0;
                        padding: 0;
                        opacity: 0;
                        cursor: pointer;
                        width: 13px;
                        height: 13px;
                        flex-shrink: 0;
                    }

                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 input[type="checkbox"]+span {
                        position: relative;
                        box-sizing: border-box;
                        width: 14px;
                        height: 14px;
                        border-radius: 1px;
                        border: 2px solid #000000;
                        pointer-events: none;
                    }

                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 input[type="checkbox"]+span svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        fill: #000000;
                    }

                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 input[type="checkbox"]:checked+span svg {
                        opacity: 1;
                    }

                    .ap3w-form-input-bool-653701830ac92cc29fdf50c4 .ap3w-form-checkbox__text {
                        cursor: pointer;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        color: #000000;
                        margin: 0;
                        padding-left: 10px;
                        font-size: 14px;
                        line-height: 14px;
                    }
                </style>
                <div class="ap3w-form-input ap3w-input-bool ap3w-form-input-bool-653701830ac92cc29fdf50c4 ap3w-form-input-hidden"
                    data-field-id="bol:cm:lifemember" data-merge-strategy="protect" data-visibility="hidden"
                    data-pre-fill-option="dont_pre_fill" data-hide-if-value-exists="true" data-input-type="check"><input
                        type="checkbox"
                        id="ap3w-form-input-bool-653701830ac92cc29fdf50c4e4424760-9db4-4486-8dc3-f560075c9dff"
                        name="LifeMember"><span><svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.226 14.095c-.252 0-.497-.096-.683-.27L1.432 9.984a1 1 0 111.365-1.461l3.289 3.073 6.99-9.514a.999.999 0 111.61 1.185l-7.654 10.42a1.003 1.003 0 01-.806.408">
                            </path>
                        </svg></span><label
                        for="ap3w-form-input-bool-653701830ac92cc29fdf50c4e4424760-9db4-4486-8dc3-f560075c9dff"
                        class="ap3w-form-checkbox__text">LifeMember</label></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_rr0xvdojl"
                    class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4 ap3w-form-input-hidden"
                    data-select="true" data-field-id="str:cm:membernumber" data-merge-strategy="protect"
                    data-visibility="hidden" data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        class="ap3w-form-input-label">MemberNumber</label><input type="text"
                        id="ap3w-form-input-text-653701830ac92cc29fdf50c4" step="1" name="MemberNumber"></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_oeilgh0px" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str:cm:graduation-year" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4" class="ap3w-form-input-label">Graduation
                        Year (most recent)*</label><input type="text" id="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        step="1" name="Graduation Year (most recent)" required=""></div>
                <style>
                    .ap3w-form-input-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input,
                    .ap3w-form-input-653701830ac92cc29fdf50c4 textarea {
                        margin-top: 8px;
                        box-sizing: border-box;
                        width: 100%;
                        background-color: #FFFFFF;
                        border: 1px solid #000000;
                        color: #000000;
                        outline: none;
                        font-family: Source Sans Pro, Arial;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 13px;
                        line-height: 1.2;
                        padding: 10px 16px;
                        resize: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 .ap3w-form-input-label {
                        font-weight: bold;
                        color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }

                    .ap3w-form-input-653701830ac92cc29fdf50c4 input[type="date"]::-webkit-date-and-time-value {
                        height: 22px;
                    }
                </style>
                <div id="selected-_0oh3yfmpu" class="ap3w-form-input ap3w-form-input-653701830ac92cc29fdf50c4"
                    data-select="true" data-field-id="str:cm:occupation" data-merge-strategy="override"
                    data-pre-fill-option="dont_pre_fill" data-input-type="text"><label
                        for="ap3w-form-input-text-653701830ac92cc29fdf50c4"
                        class="ap3w-form-input-label">Occupation</label><input type="text"
                        id="ap3w-form-input-text-653701830ac92cc29fdf50c4" step="1" name="Occupation"></div>
                <style>
                    .ap3w-form-button-653701830ac92cc29fdf50c4 button {
                        position: relative;
                        cursor: pointer;
                        padding: 10px;
                        font-weight: bold;
                        outline: none;
                        border: 2px solid transparent;
                        color: #ffffff;
                        background-color: #000000;
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        flex: 1;
                    }
                </style>
                <div id="selected-_fb7rs4qf1" class=" ap3w-form-button ap3w-form-button-653701830ac92cc29fdf50c4 ">
                    <button id="ap3w-form-button-653701830ac92cc29fdf50c4" type="submit" data-select="true"
                        data-button-on-click="thank-you">Subscribe</button></div>
            </form>
        </div>
    </div>
    <div id="653701830ac92cc29fdf50c4-thank-you" class="653701830ac92cc29fdf50c4-template"
        style="position: relative; display: none; height: 100%; align-items: center; justify-content: center;">
        <style>
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 {
                box-sizing: content-box;
                width: 100%;
                font-size: 16px;
                max-width: 450px;
                max-height: 100%;
                overflow: auto;
                background-color: #fff56d;
                border: 2px solid transparent;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0);
                z-index: unset;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-contained {
                max-width: 300px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4:before {
                content: ' ';
                display: block;
                position: absolute;
                pointer-events: none;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0%;
                background-image: url();
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-contained {
                max-width: 300px;
            }

            .ap3w-embeddable-form-content {
                margin: auto;
                padding: 32px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-top {
                top: 0;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-bottom {
                bottom: 0;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4-rounded {
                border-radius: 12px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-text {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-text * {
                padding-bottom: 5px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video {
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill {
                margin: 0 -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill.ap3w-video--first {
                margin: -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-video.ap3w-video--fill.ap3w-video--last {
                margin: -32px;
                margin-top: 20px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image {
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill {
                margin: 0 -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill.ap3w-image--first {
                margin: -32px;
                margin-bottom: 28px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-image.ap3w-image--fill.ap3w-image--last {
                margin: -32px;
                margin-top: 20px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-reaction {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form {
                margin-bottom: 16px;
            }

            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form .ap3w-input input[type=text],
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form .ap3w-input input[type=email] {
                margin-bottom: 12px;
            }

            .ap3w-embeddable-form-tcpa-wrapper {
                position: relative;
                text-align: center;
                margin-top: 12px;
            }

            .ap3w-embeddable-form-tcpa__text {
                color: #000000;
                margin: 0;
                font-size: 11px;
                line-height: 14px;
            }
        </style>
        <style>
            .ap3w-embeddable-form-653701830ac92cc29fdf50c4 .ap3w-form-input-hidden {
                display: none;
            }
        </style>
        <div id="selected-_yazkjcigk"
            class=" ap3w-embeddable-form-653701830ac92cc29fdf50c4 ap3w-embeddable-form-653701830ac92cc29fdf50c4-full ap3w-embeddable-form-653701830ac92cc29fdf50c4-solid "
            data-select="true">
            <form id="ap3w-embeddable-form-653701830ac92cc29fdf50c4" class="ap3w-embeddable-form-content">
                <style>
                    .ap3w-text-653701830ac92cc29fdf50c4 {
                        position: relative;
                        margin: 0;
                        margin-bottom: 16px;
                        word-wrap: break-word;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4.ap3w-text--last {
                        margin-bottom: 0 !important;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 * {
                        margin: 0;
                        padding-bottom: 8px;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 *:last-child {
                        padding-bottom: 0 !important;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 a {
                        color: #000000;
                        text-decoration: underline;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h1,
                    .ap3w-text-653701830ac92cc29fdf50c4 h2,
                    .ap3w-text-653701830ac92cc29fdf50c4 h3,
                    .ap3w-text-653701830ac92cc29fdf50c4 h4,
                    .ap3w-text-653701830ac92cc29fdf50c4 h5,
                    .ap3w-text-653701830ac92cc29fdf50c4 h6,
                    .ap3w-text-653701830ac92cc29fdf50c4 p,
                    .ap3w-text-653701830ac92cc29fdf50c4 div>ul {
                        text-transform: unset;
                        text-decoration: unset;
                        text-indent: unset;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h1"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 28px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h2"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 18px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h3"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h4"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h5"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="h6"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h1 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 28px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h2 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 18px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h3 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h4 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h5 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 h6 {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 700;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="large"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 16px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 p[data-size="small"] {
                        font-family: Source Sans Pro, Arial;
                        font-size: 11px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }

                    .ap3w-text-653701830ac92cc29fdf50c4 div>ul {
                        font-family: Source Sans Pro, Arial;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #000000;
                        font-weight: 400;
                        font-style: normal;
                    }
                </style>
                <div id="selected-_tqf6xc01m"
                    class="ap3w-text ap3w-text-653701830ac92cc29fdf50c4 ap3w-text--first ap3w-text--last">
                    <div data-select="true">
                        <p data-size="h2">Thank you for joining our USU alumni community! </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
`

    export default EmbeddedForm