// extracted by mini-css-extract-plugin
export var categories = "BlogPostCard-module--categories--9ce55";
export var gridView = "BlogPostCard-module--gridView--6fd26";
export var listView = "BlogPostCard-module--listView--bf369";
export var postBody = "BlogPostCard-module--postBody--53824";
export var postCard = "BlogPostCard-module--postCard--16f33";
export var postContent = "BlogPostCard-module--postContent--2b82b";
export var postDate = "BlogPostCard-module--postDate--e01a5";
export var postImage = "BlogPostCard-module--postImage--06ba5";
export var postImageContainer = "BlogPostCard-module--postImageContainer--eef0e";
export var postTitle = "BlogPostCard-module--postTitle--9fa77";
export var readMore = "BlogPostCard-module--readMore--1796c";
export var topCategories = "BlogPostCard-module--topCategories--96d88";