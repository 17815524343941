import React from 'react';
import Icon from '../Icon/Icon';
import * as styles from './Dropdown.module.css';

const Dropdown = (props) => {

  const {label, optionList, action} = props;

  const onChange = (e) => {
    if (action) {
      action(e);
    }
  }

  return (
    <div className={styles.root}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.selectContainer}>
        <select onChange={(e) => onChange(e)}>
          {optionList.map((option) => 
            <option key={option.label} value={option.value} aria-label={`option ${label}`} label={option.label}/>)
          }
        </select>
        <div className={styles.caretContainer}>
          <Icon symbol={'caret'}></Icon>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
