import React, { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { navigate } from 'gatsby';
import { decode } from 'he';
import DataTable from '../DataTable/DataTable';
import AccordionCard from '../../atoms/AccordionCard/AccordionCard';
import Button from '../../atoms/Button/Button';
import RichTextField from '../../atoms/RichTextField/RichTextField';
import OrttoEmailTemplate from '../OrttoEmailTemplate/OrttoEmailTemplate';
import MessageAlert from '../../atoms/MessageAlert/MessageAlert';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import Dialog from '../../atoms/Dialog/Dialog';
import { wpApiNew, wpAllNew, uploadMedia } from '../../../helpers/wordpress';
import { ucFirst, getBase64 } from '../../../helpers/general';
import { sendEmail, getPerson } from '../../../helpers/ortto';
import { generateSubmittedDate } from '../../../helpers/date';
import { toast } from 'react-toastify';

import * as styles from './ClubGM.module.css';

const ClubGM = ({ club, auth, forApproval = false, history }) => {
    const [data, setData] = useState({});
    const [saving, setSaving] = useState(false);
    const [editFields, setEditFields] = useState(false);
    const [newMinutes, setNewMinutes] = useState(false);
    const [newConstitution, setNewConstitution] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [metaFileName, setMetaFileName] = useState({});
    const [memberData, setMemberData] = useState({});
    const [ROName, setROName] = useState(null);
    const [attendees, setAttendees] = useState([]);
    const [roles, setRoles] = useState([]);
    const [nominations, setNominations] = useState({});
    const [assignments, setAssignments] = useState([]);
    const [disableActions, setDisable] = useState(false);
    const [testingLabel, setTesting] = useState('Send test');
    const [defineRejectMessage, setRejectMessage] = useState(false);
    const [rejectMessage, updateRejectMessage] = useState('');
    const [emailData, setEmailData] = useState({
        subject: '',
        body: '',
    });
    const emailInitial = {
        subject: club.status === 'engaged' ? 'Congratulations, your club is official!' : 'Your AGM results are now in effect',
        body: club.status === 'engaged' ? `
        <p>We're excited to inform you that <strong>${club.title.rendered}<strong> is now official!</p>
        <p><strong>What does this mean?</strong></p>
        <p>The club will now be displayed on the website and members can actively join your club. You can create events for the club via the management area in your account, as well as manage your members and club page data.</p>
        <p>Your club funding will also be managed via the club management area. This does now require you to get this setup to be eligible to receive funding from USU. Log in and follow the steps to do this.</p>
        <p>Any questions or issues, please contact USU Staff for assistance.</p>
        ` : `
        <p>We're excited to inform you that the information from the General Meeting for <strong>${club.title.rendered}</strong> has been reviewed and approved.</p>
        <p><strong>What does this mean?</strong></p>
        <p>Your new club executives are now assigned and have access to the management area of the website for the club.</p>
        <p>Any questions or issues, please contact USU Staff for assistance.</p>
        `,
    };

    const coreRoles = useMemo(() => (['President', 'Secretary', 'Treasurer']), []);

    const handleUpload = (e, document) => {
        if(e === undefined) return;
        const tempSelectedFile = {...selectedFile};
        const tempMetaFileName = {...metaFileName};
        tempSelectedFile[document] = e.target.files[0];
        tempMetaFileName[document] = e.target.files[0]?.name;
		setSelectedFile({...tempSelectedFile});
        setMetaFileName({...tempMetaFileName});
        handleFieldChanges(document, 'set');
    }

    const handleFieldChanges = (field, value) => {
        const temp = editFields ? {...editFields} : {};
        temp[field] = value;
        setEditFields(temp);
    }

    const updateAssignments = () => {
        const temp = editFields ? {...editFields} : {};
        temp.new_club_execs = assignments;
        setEditFields(temp);
    }

    const modifyAssignment = (value, index) => {
        const temp = editFields ? {...editFields} : {};
        temp.new_club_execs[index].member_number = value;
        setEditFields(temp);
    }

    const saveChangesHandler = async () => {
        try {
            setSaving(true);

            if ('minutes' in editFields) {
                const base64Minutes = await getBase64(selectedFile.minutes);
                const responseMinutes = await uploadMedia(`GM-Minutes-${club.title.rendered}.pdf`, base64Minutes, selectedFile.minutes.type);
                if(String(responseMinutes.status).startsWith('2')) {
                    editFields.minutes = responseMinutes.response.id;
                }
            }

            if ('constitution' in editFields) {
                const base64Constitution = await getBase64(selectedFile.constitution);
                const responseConstitution = await uploadMedia(`GM-Constitution-${club.title.rendered}.pdf`, base64Constitution, selectedFile.constitution.type);
                if (String(responseConstitution.status).startsWith('2')) {
                    editFields.constitution = responseConstitution.response.id;
                }
            }
            
            await wpApiNew('upsertClubHistory', {
                clubId: club.id,
                historyId: club.acf.current_igmagm_history_record.ID,
                object: {
                    fields: editFields,
                }
            });

            toast.success('Changes have been saved.');
            setEditFields(false);
        } catch (error) {
            toast.error('An unexpected error has occured.\nPlease try again.');
        } finally {
            setSaving(false);
        }
    };

    const approveChangesHandler = async (rejected) => {
        try {
            setSaving(true);

            const postData = {
                status: club.status === 'igm' ? 'rejected' : 'publish',
                fields: {
                    agm_functionality: false,
                    current_igmagm_history_record: null,
                }
            };

            const historyFields = {
                status: 'finished',
                result: 'rejected',
                result_by: `${auth.usu.FirstName} ${auth.usu.LastName} (${auth.usu.MemberNumber})`,
                actioned_at: generateSubmittedDate()
            };

            const message = rejectMessage;

            if (!rejected) {
                const fields = { ...postData.fields };

                fields.club_execs = [];
                const dateObj = new Date(new Date().getTime() + (-new Date().getTimezoneOffset())*60000);
                const startDate = dateObj.toISOString().split('.')[0];
                dateObj.setFullYear(dateObj.getFullYear() + 1);
                const expiryDate = dateObj.toISOString().split('.')[0];
                dateObj.setFullYear(dateObj.getFullYear() - 1);
                dateObj.setDate(dateObj.getDate() + 21);
                const exitingExpiryDate = dateObj.toISOString().split('.')[0];

                // Add in new execs
                const memberNumbers = history.acf.new_club_execs.map(e => e.member_number);
                const _memberRecords = await getPerson(['MemberNumber', 'email'], memberNumbers, 'str:cm:membernumber');
                
                const memberRecords = {};
                _memberRecords.response.contacts.map(c => {
                    memberRecords[c.fields['str:cm:membernumber']] = c.fields['str::email'];
                    
                    return true;
                });
                history.acf.new_club_execs.map((role) => {
                    const memberEmail = memberRecords[role.member_number];
                    fields.club_execs.push({
                        member_number: role.member_number,
                        position: role.position,
                        email: role.member_number === 'vote of no confidence' ? '' : memberEmail,
                        start_date: startDate,
                        expiry_date: expiryDate
                    })
                    return true;
                });

                // Redefine prior core roles
                club.acf.club_execs.map((exec) => {
                    const isNewExec = history.acf.new_club_execs.find(newExec => exec.member_number === newExec.member_number);
                    if (coreRoles.indexOf(exec.position) > -1 && !isNewExec) {
                        fields.club_execs.push({
                            member_number: exec.member_number,
                            position: `Exiting ${exec.position}`,
                            email: exec.email,
                            start_date: startDate,
                            expiry_date: exitingExpiryDate
                        });
                    }

                    return true;
                })

                postData.status = 'publish';
                postData.fields = fields;

                historyFields.result = 'accepted';
            } else {
                postData.fields = {
                    agm_functionality: true,
                    current_igmagm_history_record: null,
                };

                historyFields.rejected_reason = message;
            }

            await wpApiNew('upsertClub', {
                clubId: data.id,
                object: postData
            });
            await wpApiNew('upsertClubHistory', {
                clubId: data.id,
                historyId: history.id,
                object: {fields: historyFields}
            });

            if (!rejected) {
                await sendCongratsEmail();
                toast.success('Club Data has been approved');
            } else {
                await sendRejectEmail(message);
                toast.success('Club Data has been rejected');
            }
            
            navigate('/account/manage-clubs/');
        } catch (error) {
            toast.error('An unexpected error has occured.\nPlease try again.');
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        const pageInit = async () => {
            const initData = {
                id: club.id,
                full_title: club.acf.full_title,
                club_display_name: club.title.rendered,
                abbr: club.acf.abbr,
                contact: club.acf.details.contact,
                email: club.acf.details.email,
                mobile: club.acf.details.mobile,
                phone: club.acf.details.phone,
                campaign_start_date: club.acf.application_settings?.campaign_start_date || new Date(new Date().getTime() + (-new Date().getTimezoneOffset())*60000).toISOString().split('.')[0],
                accepting_signatures_period: club.acf.application_settings?.accepting_signatures_period || 30,
                minimum_amount_of_signatures_required: club.acf.application_settings?.minimum_amount_of_signatures_required || 20,
            };

            if (history) {
                const memberNumbers = [];

                // Get RO Record
                if (history.acf.returning_officer && memberNumbers.indexOf(history.acf.returning_officer) === -1) {
                    memberNumbers.push(history.acf.returning_officer);
                }
                setROName(history.acf.returning_officer);

                // Set Attendees
                const attendees = await wpAllNew('getEventCheckInList', {
                    clubId: club.id,
                    eventId: history.acf.event.ID
                });
                if (String(attendees.status).startsWith('2') && attendees.response.length > 0) {
                    const attends = attendees.response.map(att => {
                        if (memberNumbers.indexOf(att.acf.member_number) === -1) {
                            memberNumbers.push(att.acf.member_number);
                        }

                        return ({member_number: att.acf.member_number, type: ucFirst(att.acf.type || 'attendee')});
                    })
                    setAttendees(attends);
                }

                // Set roles
                setRoles(history.acf.new_club_roles);

                // Set Nominations
                if (history.acf.nominations && history.acf.nominations !== '') {
                    const obj = JSON.parse(history.acf.nominations);
                    Object.keys(obj).map(p => {
                        obj[p].map(m => {
                            if (memberNumbers.indexOf(m.member_number) === -1) {
                                memberNumbers.push(m.member_number);
                            }

                            return true;
                        });
                        
                        return true;
                    });
                    setNominations(obj);
                } else {
                    setNominations({});
                }

                // Set new club execs
                if (history.acf.new_club_execs) {
                    history.acf.new_club_execs.map(e => {
                        if (memberNumbers.indexOf(e.member_number) === -1) {
                            memberNumbers.push(e.member_number);
                        }

                        return true;
                    });
                }
                setAssignments(history.acf.new_club_execs);

                // Fetch names
                if (memberNumbers.length > 0) {
                    const actualNumbers = memberNumbers.map(mn => String(Number(mn))).filter(nm => nm !== 'NaN' && nm !== '0');
                    const names = await getPerson(['first', 'last', 'MemberNumber'], actualNumbers, 'str:cm:membernumber');
                    if (String(names.status).startsWith('2') && names.response.contacts) {
                        const namesObj = {};
                        names.response.contacts.map(c => {
                            namesObj[c.fields['str:cm:membernumber']] = `${c.fields['str::first']} ${c.fields['str::last']} (${c.fields['str:cm:membernumber']})`;
                            
                            return true;
                        });
                        setMemberData(namesObj);
                    }
                }
            }

            setData({ ...data, ...initData });
            setEmailData(emailInitial);
        };
        pageInit();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const getName = (memberNumber) => {
        return memberNumber in memberData ? memberData[memberNumber] : `Name unknown (${memberNumber})`;
    }

    const updateValue = (field, value) => {
        const state = {...emailData};
        state[field] = value;
        setEmailData({...state});
    }

    const returnBody = (body) => {
        updateValue('body', body);
    }

    const charCount = () => {
        return 120 - emailData.subject.length;
    }

    const processEmail = (recipients) => {
        // console.log("Club data: ", emailData);
        const emailBody = renderToString(<OrttoEmailTemplate logo={null} clubName={null} content={emailData.body} />);
        // console.log("Email Body:", emailBody);

        const emailOptions = {
            html_body: emailBody,
            subject: emailData.subject !== '' ? emailData.subject : emailInitial.subject,
            email_name: `${decode(club.title.rendered)} Congratulations email`,
            from_name: 'USU Staff',
            reply_to: 'clubs@usu.edu.au'
        };
        
        return sendEmail(emailOptions, recipients).then(postResponse => {
            // console.log(postResponse);

            return postResponse;
        });
    }

    const processRejectEmail = (recipients, reason) => {
        // console.log("Club data: ", emailData);
        const body = `
        <p>We have reviewed the information from the General Meeting for <strong>${club.title.rendered}</strong> and unfortunately it has been rejected at this time.</p>
        <p>USU staff have provided a reason of:</p>
        <p>${reason}</p>
        <p><strong>What does this mean?</strong></p>
        <p>Your club has been reset to restart the AGM process. Please follow the prompts in your club admin area to move forward.</p>
        <p>Any questions or issues, please contact USU Staff for assistance.</p>
        `;
        const emailBody = renderToString(<OrttoEmailTemplate logo={null} clubName={null} content={body} />);
        // console.log("Email Body:", emailBody);

        const emailOptions = {
            html_body: emailBody,
            subject: "Your submitted GM has been rejected",
            email_name: `${decode(club.title.rendered)} GM Rejected email`,
            from_name: 'USU Staff',
            reply_to: 'clubs@usu.edu.au'
        };
        
        return sendEmail(emailOptions, recipients).then(postResponse => {
            // console.log(postResponse);

            return postResponse;
        });
    }

    const sendTest = () => {
        setDisable(true);
        setTesting(`Sending test...`);
        const emailRecipients = [{
            email: auth.email,
            first: auth.usu.FirstName,
            last: auth.usu.LastName
        }];
        // const emailRecipients = [{
        //     email: "ash@matterdesign.com.au",
        //     first: "Ash",
        //     last: "Durham"
        // }];

        processEmail(emailRecipients).then(result => {
            if (String(result.status).startsWith("2") && 'emails' in result.response) {
                setDisable(false);
                setTesting('Sent!');
                setTimeout(() => {
                    setTesting(`Send test`);
                }, 5000);
            } else {
                setDisable(false);
                // TODO: Inform that something went wrong
            }
        });
    }

    const sendCongratsEmail = () => {
        setDisable(true);
        
        const emailRecipients = [{
            email: club.acf.details.email,
            first: club.acf.details.contact.split(' ')[0],
            last: club.acf.details.contact.split(' ')[1]
        }];
        // const emailRecipients = [{
        //     email: "ash@matterdesign.com.au",
        //     first: "Ash",
        //     last: "Durham"
        // }];

        processEmail(emailRecipients).then(result => {
            if (result) {
                setTimeout(() => {
                    setDisable(false);
                }, 5000);
            } else {
                setDisable(false);
                // TODO: Inform that something went wrong
            }
        });
    }

    const sendRejectEmail = (reason) => {
        setDisable(true);
        
        const emailRecipients = [{
            email: club.acf.details.email,
            first: club.acf.details.contact.split(' ')[0],
            last: club.acf.details.contact.split(' ')[1]
        }];
        // const emailRecipients = [{
        //     email: "ash@matterdesign.com.au",
        //     first: "Ash",
        //     last: "Durham"
        // }];

        processRejectEmail(emailRecipients, reason).then(result => {
            if (result) {
                setTimeout(() => {
                    setDisable(false);
                }, 5000);
            } else {
                setDisable(false);
                // TODO: Inform that something went wrong
            }
        });
    }

    const confirmRejectMessage = () => {
        if (rejectMessage !== '') {
            approveChangesHandler(true);
            setRejectMessage(false);
        }
    }

    return (
        <>
        {history.acf.status === 'finished' && (
            <MessageAlert type={'error'}><strong>STATUS: FINISHED! </strong><br />This club is misconfigured. The below meeting history has already been marked at finished, however the club is still flagged as still actively updating this.</MessageAlert>
        )}

        {history.acf.status === 'running' && (
            <MessageAlert type={'warn'}><strong>STATUS: Running... </strong><br />This club is currently in the process of their meeting. If you believe this should not be the case, please update accordingly.</MessageAlert>
        )}

        {history.acf.status === 'pending' && (
            <MessageAlert type={'warn'}><strong>STATUS: Completed! </strong><br />This club has completed their meeting. Please review the data below and respond accordingly.</MessageAlert>
        )}
        
        <AccordionCard title={'Club Details'}>
            <div className={styles.root}>
                <div>Club Name: {decode(club.title.rendered)}</div>
                <div>Meeting Minutes: <Button link={true} target="_blank" href={history.acf.minutes}>View</Button>{!newMinutes && (<> | <Button type="span" link={true} onClick={() => setNewMinutes(true)}>Upload replacement</Button></>)}</div>
                {newMinutes && (
                    <div className={styles.filePickerContainer}>
                        <div className={styles.fileHelperContainer}>
                            <span className={styles.fileName}>{'minutes' in metaFileName ? metaFileName.minutes : 'Minutes of meeting'}</span>
                            <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                        </div>
                        <label className={styles.filePicker} htmlFor='minutes_file' >
                            <input id="minutes_file" type="file" name="minutes_file" onChange={(e) => handleUpload(e, 'minutes')} />
                            Select File
                        </label>
                    </div>
                )}
                <div>Updated Constitution: <Button link={true} target="_blank" href={history.acf.constitution}>View</Button>{!newConstitution && (<> | <Button type="span" link={true} onClick={() => setNewConstitution(true)}>Upload replacement</Button></>)}</div>
                {newConstitution && (
                    <div className={styles.filePickerContainer}>
                        <div className={styles.fileHelperContainer}>
                            <span className={styles.fileName}>{'constitution' in metaFileName ? metaFileName.constitution : 'Latest constitution'}</span>
                            <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                        </div>
                        <label className={styles.filePicker} htmlFor='constitution_file' >
                            <input id="constitution_file" type="file" name="constitution_file" onChange={(e) => handleUpload(e, 'constitution')} />
                            Select File
                        </label>
                    </div>
                )}
                <div>Returning Officer: {getName(ROName)}</div>
            </div>
        </AccordionCard>

        <AccordionCard title={'Attendees'}>
            <div className={styles.root}>
                <DataTable
                    tableData={attendees.map(a => ({...a, member_number: getName(a.member_number)}))}
                    headingKeys={[
                        { label: 'Member Number', data_key: 'member_number', sortable: true },
                        { label: 'Attendance Type', data_key: 'type', sortable: true },
                    ]}
                />
            </div>
        </AccordionCard>

        <AccordionCard title={'Roles and Nominations'}>
            <div className={styles.root}>
                <div className={styles.roles}>
                    {roles && roles.map((role, rIndex) => (
                        <div key={rIndex} className={styles.role}>
                            <div className={`${styles.name} ${styles.top}`}>{role.role}</div>
                            <div className={styles.results}>
                                {role.role in nominations && nominations[role.role].map((nom, nIndex) => (
                                    <div key={nIndex}>{getName(nom.member_number)}</div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </AccordionCard>

        <AccordionCard title={'Assignments'}>
            <div className={styles.root}>
                <div className={styles.roles}>
                    {assignments && assignments.filter(exec => exec.member_number !== '').map((exec, eIndex) => (
                        <div key={eIndex} className={styles.role}>
                            <div className={`${styles.name} ${styles.top}`}>{exec.position}</div>
                            <div className={styles.results}>
                                {(editFields && 'new_club_execs' in editFields) ? (
                                    <>
                                        <FormInputField
                                            id={`assignment_${exec.position}_${eIndex}`}
                                            type='input'
                                            placeholder='USU Member Number'
                                            value={exec.member_number}
                                            handleChange={(id, value) => modifyAssignment(value, eIndex)}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {getName(exec.member_number)}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                {history.acf.status === 'pending' && (
                    <Button type="span" link={true} onClick={() => updateAssignments()}>Modify assignments</Button>
                )}
            </div>
        </AccordionCard>

        <AccordionCard title={'Approval email'}>
            <div className={styles.root}>
                {/* Create  */}
                <div className="formField">
                    <label htmlFor="emailSubject">Email subject <span className="floatRight">{charCount()} characters remaining</span></label>
                    <input type="text" id="emailSubject" defaultValue={emailInitial.subject} onChange={(e) => updateValue('subject', e.target.value)} />
                </div>
                <div className="formField">
                    <label htmlFor="emailBody">Email body</label>
                    <RichTextField defaultValue={emailInitial.body} returnValue={returnBody} />
                </div>
                <div className="buttonGroup stretch">
                    <Button level="secondary" type="button" disabled={disableActions} onClick={() => sendTest()}>{testingLabel}</Button>
                </div>
            </div>
        </AccordionCard>
        
        {forApproval ? (
            <div className={`grid grid-33 grid-responsive `}>
                <Button
                    disabled={saving}
                    type={'button'}
                    onClick={() => saveChangesHandler()}
                    level={'primary'}>
                    Save changes
                </Button>
                {history.acf.status === 'pending' && (
                    <>
                        <Button
                            disabled={saving || editFields}
                            type={'button'}
                            onClick={() => setRejectMessage(true)}
                            level={'secondary'}>
                            Reject
                        </Button>
                        <Dialog open={defineRejectMessage} size="md" onCancel={() => setRejectMessage(false)} okBtnText='Confirm' disableAutoClose={false} onOk={() => confirmRejectMessage()}>
                            <p>Please give a reason as to why this AGM application has been rejected. Please note, the applicant will be notified of this reason.</p>
                            <div className="formField">
                                <textarea onChange={(e) => updateRejectMessage(e.target.value)}></textarea>
                            </div>
                        </Dialog>
                        <Button
                            disabled={saving || editFields}
                            type={'button'}
                            onClick={() => approveChangesHandler(false)}
                            level={'primary'}>
                            Approve
                        </Button>
                    </>
                )}
            </div>
        ) : (
            <></>
        )}
        </>
    );
};

export default ClubGM;

