import React from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import { useWindowDimensions } from '../../../helpers/general';
import { verify } from '../../../helpers/captcha';

import * as styles from './Captcha.module.css';

const Captcha = props => {

  const { setCaptcha, captchaRef } = props;
  const { width } = useWindowDimensions();
  //Client Side Key
  const SITE_KEY = '6LfztAMiAAAAAN0o2yO68ApOMoywCv1Uf4mCJBzp';

  const onChange = (token) => {
    const captchaRequest = verify(token);
    //backend verification
    captchaRequest.then((response) => {
      const {success} = response;
      if(success === true) {
        setCaptcha(true)
      } else {
        setCaptcha(false)
      }
    });
  }

  return (
    <div className={ styles.Captcha }>
      <ReCAPTCHA size={width > 350 ? 'normal' : 'compact'} ref={captchaRef} sitekey={SITE_KEY} onChange={onChange}/>
    </div>
  );
};

export default Captcha;
