/**
 * FreshDesk API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Fresh API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { freshdeskApi } from '../helpers/freshdesk'

    freshdeskApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
    async function freshdeskApi(endpoint, method, body) {
        const options = {
            method: method ? method : 'GET',
            credentials: 'same-origin',
            mode: 'same-origin'
        };
        
        if (body) {
            let bodyString = body;
            if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
            }
        
            options.body = bodyString;
        }
        
        const parseJson = async response => {
            const text = await response.text();
            try {
            const json = JSON.parse(text);
            return json;
            } catch (err) {
            return text;
            }
        };
        
        const encodedEndpoint = Buffer.from(endpoint).toString('base64');
        
        if (!(typeof fetch === 'undefined')) {
            return await fetch(
            `${process.env.LAMBDA_PATH}freshdesk?endpoint=${encodedEndpoint}`,
            options
            ).then(async res => ({ response: await parseJson(res), status: res.status }));
        } else {
            return {response: null, status: 404}
        }
    }
    
    export { 
        freshdeskApi,
    };
      