// import { Link } from 'gatsby';
import { get } from 'lodash';
import React, { useMemo, useContext } from 'react';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import ContentfulContext from '../../../context/ContentfulProvider';
import StoreButton from '../StoreButton/StoreButton';
import * as styles from './ContentfulBlockColumnsParagraph.module.css';

const ContentfulBlockColumnsParagraph = props => {
  const { 
    columnParagraphOne,
    columnParagraphTwo
  } = props;

  const backgroundColor = get(props, 'backgroundColour.hexCode', false);
  const textColor = get(props, 'backgroundColour.supportingColour', false);
  const contentful = useContext(ContentfulContext);
  const storeLink = useMemo(() => (contentful && contentful.storeLinks) || [], [contentful]);

  const mainStyles = {};
  if (backgroundColor) {
      mainStyles.backgroundColor = backgroundColor;
      mainStyles.color = textColor;
  }

  return (
    <section className={`${styles.blockColumnsParagraph}`} style={mainStyles}>
      <AosInit>
        <Container>
          <div className={styles.columnContainer}>
            {columnParagraphOne && 
              <div className={styles.columnOne}>
                <ContentfulRichText raw={columnParagraphOne.raw}/>
              </div>
            }
            {columnParagraphTwo && 
              <div className={styles.columnTwo}>
                <ContentfulRichText raw={columnParagraphTwo.raw}/>
              </div>
            }
          </div>
          <div className={styles.downloadBox}>
            <h6>DOWNLOAD OUR APP</h6>
            <div className={styles.storeButtonContainer}>
              <StoreButton title={'Google Play'} icon={'playStore'} link={storeLink?.playStore} />
              <StoreButton title={'App Store'} icon={'appStore'} link={storeLink?.appStore} />
            </div>
              {/* <Link to={storeLink.playStore} rel="nofollow" target="_blank" className={styles.gStoreBtn} /> */}
              {/* <Link to={storeLink.appStore} rel="nofollow" target="_blank" className={styles.appStoreBtn} /> */}
          </div>
        </Container>
      </AosInit>
    </section>
  );
};

export default ContentfulBlockColumnsParagraph;
