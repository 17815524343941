import React from 'react'
import { get } from 'lodash';
import ContentfulBanner from '../../molecules/ContentfulBanner/ContentfulBanner';
import ContentfulBlockArticle from '../../molecules/ContentfulBlockArticle/ContentfulBlockArticle';
import ContentfulBlockCarousel from '../../molecules/ContentfulBlockCarousel/ContentfulBlockCarousel';
import ContentfulBlockCodedComponent from '../../molecules/ContentfulBlockCodedComponent/ContentfulBlockCodedComponent';
import ContentfulBlockColumns from '../../molecules/ContentfulBlockColumns/ContentfulBlockColumns';
import ContentfulBlockCopy from '../../molecules/ContentfulBlockCopy/ContentfulBlockCopy';
import ContentfulBlockHero from '../../molecules/ContentfulBlockHero/ContentfulBlockHero';
import ContentfulBlockSideCopy from '../../molecules/ContentfulBlockSideCopy/ContentfulBlockSideCopy';
import ContentfulCtaBanner from '../../molecules/ContentfulCtaBanner/ContentfulCtaBanner';
import ContentfulImageCollage from '../../molecules/ContentfulImageCollage/ContentfulImageCollage';
import ContentfulImageGrid from '../../molecules/ContentfulImageGrid/ContentfulImageGrid';
import ContentfulBlockGrid from '../../molecules/ContentfulBlockGrid/ContentfulBlockGrid';
import ContentfulBlockLinkContainer from '../../molecules/ContentfulBlockLinkContainer/ContentfulBlockLinkContainer';
import ContentfulBlockTrio from '../../molecules/ContentfulBlockTrio/ContentfulBlockTrio';
import ContentfulBlockTimelineAccordion from '../../molecules/ContentfulBlockTimelineAccordion/ContentfulBlockTimelineAccordion';
import ContentfulBlockOneContentTwoMediaLayout from '../../molecules/ContentfulOneContentTwoMediaLayout/ContentfulOneContentTwoMediaLayout';
import ContentfulMenuGroup from '../../molecules/ContentfulMenuGroup/ContentfulMenuGroup';
import ContentfulBlockColumnsParagraph from '../../molecules/ContentfulBlockColumnsParagraph/ContentfulBlockColumnsParagraph';
import ContentfulBlockAccordion from '../../molecules/ContentfulBlockAccordion/ContentfulBlockAccordion';
import ContentfulBlockFours from '../../molecules/ContentfulBlockFours/ContentfulBlockFours';
import ContentfulBlockVisual from '../../molecules/ContentfulBlockVisual/ContentfulBlockVisual';
import ContentfulBlockVideo from '../../molecules/ContentfulBlockVideo/ContentfulBlockVideo';
import ContentfulBlockDual from '../../molecules/ContentfulBlockDual/ContentfulBlockDual';
import ContentfulBlockEventButton from '../../molecules/ContentfulBlockEventButton/ContentfulBlockEventButton';
import ContentfulFormTemplate from '../../molecules/ContentfulFormTemplate/ContentfulFormTemplate';
import ContentfulBlockSpotify from '../../molecules/ContentfulBlockSpotify/ContentfulBlockSpotify';

const ContentfulBlock = ({ block, size, data }) => {
    const blockType = get(block, 'internal.type');
    
    switch (blockType) {

        case 'ContentfulBanner':
            return <ContentfulBanner {...block} />

        case 'ContentfulBlockArticle':
            return <ContentfulBlockArticle {...block} size={block?.containerSize} />

        case 'ContentfulBlockCarousel':
            return <ContentfulBlockCarousel {...block} size={block?.containerSize} />

        case 'ContentfulBlockCodedComponent':
            return <ContentfulBlockCodedComponent {...block} size={block?.containerSize} />

        case 'ContentfulBlockColumns':
            return <ContentfulBlockColumns {...block} size={size} />

        case 'ContentfulBlockCopy':
            return <ContentfulBlockCopy {...block} size={block?.containerSize} data={data} />
        
        case 'ContentfulBlockDual':
            return <ContentfulBlockDual {...block} />
        
        case 'ContentfulBlockHero':
            return <ContentfulBlockHero {...block} size={size} />

        case 'ContentfulBlockEventButton':
            return <ContentfulBlockEventButton {...block} />

        case 'ContentfulBlockOneContentTwoMediaLayout':
            return <ContentfulBlockOneContentTwoMediaLayout {...block} />

        case 'ContentfulBlockTrio':
            return <ContentfulBlockTrio {...block} size={block?.containerSize}/>

        case 'ContentfulBlockLinkContainer':
            return <ContentfulBlockLinkContainer {...block} size={block?.containerSize} />

        case 'ContentfulBlockSideCopy':
            return <ContentfulBlockSideCopy {...block} size={size} />
        
        case 'ContentfulCtaBanner':
            return <ContentfulCtaBanner {...block} size={block?.containerSize}/>
        
        case 'ContentfulImageCollage':
            return <ContentfulImageCollage {...block} />

        case 'ContentfulImageGrid':
            return <ContentfulImageGrid {...block} />
        
        case 'ContentfulBlockGrid':
            return <ContentfulBlockGrid {...block} />
        
        case 'ContentfulMenuGroup':
            return <ContentfulMenuGroup {...block} />
        
        case 'ContentfulBlockVisual':
            return <ContentfulBlockVisual {...block} />
        
        case 'ContentfulBlockVideo':
            return <ContentfulBlockVideo {...block} />

        case 'ContentfulBlockSpotify':
            return <ContentfulBlockSpotify {...block} />

        case 'ContentfulBlockTimelineAccordion':
            return <ContentfulBlockTimelineAccordion {...block} />

        case 'ContentfulBlockColumnsParagraph':
            return <ContentfulBlockColumnsParagraph {...block} />

        case 'ContentfulBlockAccordion':
            return <ContentfulBlockAccordion {...block} size={block?.containerSize} />

        case 'ContentfulBlockFours':
            return <ContentfulBlockFours {...block} />

        case 'ContentfulFormTemplate':
            return <ContentfulFormTemplate {...block} />
                
        default:
            return null;
    }
}

export default ContentfulBlock;