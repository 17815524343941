import React from 'react';
import { renderToString } from 'react-dom/server';
import { decode } from 'he';
import { get } from "lodash"
import { sendEmail, getPerson } from '../ortto';
import OrttoEmailTemplate from '../../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate';

const approvedEmailBody = `
    Dear ##EXEC##,<br />
    <br />
    Thank you for submitting a ##GRANTREQUESTED## grant request for ##GRANTTYPE## on behalf of ##CLUBNAME##.<br />
    Confirming that your ##GRANTAPPROVED## grant has now been approved.<br />
    <br />
    Please ensure you keep all receipts and upload them to the receipts section listed on your Clubs main dashboard and link them to this grant.<br />
    <br />
    If you have any questions, please <a href="https://usu.edu.au/contact">contact the Clubs office<a/>.
`;

const rejectedEmailBody = `
    Dear ##EXEC##,<br />
    <br />
    Thank you for submitting a ##GRANTREQUESTED## grant request for ##GRANTTYPE## on behalf of ##CLUBNAME##.<br />
    This email is to inform you that your grant application has not been approved.<br />
    <br />
    If you have any questions, please <a href="https://usu.edu.au/contact">contact the Clubs office<a/>.
`;

async function emailer(object) {
    const submittedBy = object.submittedBy.split(' ').pop().slice(1, -1);
    const { response: execData } = await getPerson(['first', 'last', 'email'], [submittedBy], 'str:cm:membernumber');
    const execFields = get(execData, 'contacts')[0].fields;
    const personaliseContent = (object.status === 'paid' ? approvedEmailBody : rejectedEmailBody)
        .replace('##EXEC##', execFields['str::first'])
        .replace('##GRANTTYPE##', object.type)
        .replace('##GRANTREQUESTED##', `$${object.valueGranted}`)
        .replace('##GRANTAPPROVED##', `$${object.valueProvided}`)
        .replace('##CLUBNAME##', decode(object.club));
    const emailBody = renderToString(<OrttoEmailTemplate logo={null} clubName={null} content={personaliseContent} />);
    // console.log("Email Body:", emailBody);

    const emailOptions = {
        html_body: emailBody,
        subject: `Grant ${object.status === 'paid' ? 'approved' : object.status} for ${decode(object.club)}`,
        email_name: `${decode(object.club)} grant ${object.status} - ${object.type}`,
        from_name: 'USU Staff',
        reply_to: 'staff@usu.edu.au'
    };

    const emailRecipients = [{
        email: execFields['str::email'],
        first: execFields['str::first'],
        last: execFields['str::last']
    }]
    
    return sendEmail(emailOptions, emailRecipients);
}

export {
    emailer
}