import React from 'react'

const Youtube = () => (
  <svg width={34} height={25} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 34 25'>
    <path
      d="M17.146 1h.08l.335.002c1.117.006 3.73.03 6.279.134l.61.027c2.124.098 4.117.254 5.052.505a4.008 4.008 0 0 1 2.83 2.83c.62 2.317.665 6.972.668 7.625v.154c-.003.653-.048 5.308-.669 7.625a4.007 4.007 0 0 1-2.829 2.83c-2.228.596-10.444.66-12.18.667l-.239.001h-.407c-1.734-.008-9.95-.072-12.179-.669a4.007 4.007 0 0 1-2.829-2.829c-.62-2.317-.665-6.972-.668-7.625v-.154c.003-.653.047-5.308.668-7.626a4.008 4.008 0 0 1 2.83-2.829c2.138-.573 9.796-.655 11.94-.666L16.773 1h.08ZM13.8 7.4V17l8.313-4.8L13.8 7.4Z"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Youtube