import React from 'react';
import Container from '../../atoms/Container/Container';
import BlogPostCard from '../../molecules/BlogPostCard/BlogPostCard';
import * as styles from './RelatedPosts.module.css';

const RelatedPosts = ({ posts }) => {
  
  return (
    <div className={ styles.root }>
        <Container>
          <h5>Related articles</h5>
          <div className={styles.productsGrid}>
            {posts.slice(0, 3).map((post, postIndex) => (
              <BlogPostCard
                size={'medium'}
                key={post.id}
                title={post.title}
                excerpt={post.excerpt}
                slug={`${process.env.SITE_URL}/blog/${post.slug}`}
                categories={post.categories?.nodes}
                content={post.content}
                authorName={post.author_name}
                authorUri={post.author_uri}
                date={post.published_date}
                viewMode={'grid'}
                thumbnail={post.thumbnail_path || 'https://via.placeholder.com/560x560?text=Not+Found'}
                highlightView={false}
                titleOnly={false}
                noCategories={true}
              />
            ))}
          </div>
        </Container>
    </div>
  );
};

export default RelatedPosts;
