import React from "react"
// import { graphql } from 'gatsby'
import { Link, navigate } from "gatsby";

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
// import Hero from "../components/organisms/Hero/Hero"
// // TODO: Replace with dynamic data from a system or update content directly in this file
// import { defaultHeroContent } from "../components/organisms/Hero/defaultHeroContent"
import Hero from '../components/organisms/StaticHero/Hero';

import AttributeGrid from '../components/organisms/AttributeGrid/AttributeGrid';
// import BlogPreviewGrid from '../components/organisms/BlogPreviewGrid/BlogPreviewGrid';
import Highlight from '../components/molecules/Highlight/Highlight';
import ProductCollectionGrid from '../components/organisms/ProductCollectionGrid/ProductCollectionGrid';
import ProductCardGrid from '../components/organisms/ProductCardGrid/ProductCardGrid';
import Quote from '../components/atoms/Quote/Quote';
import Title from '../components/atoms/Title/Title';

// import ProductGrid from "../components/organisms/ProductGrid/ProductGrid"
// import ProductSlider from "../components/organisms/ProductSlider/ProductSlider"

import { generateMockProductData } from "../mockData/mock";

import * as styles from './index.module.css';

// export const query = graphql`
//   query HomeQuery {
//     bigCommerceGraphQL {
//       site {
//         featuredProducts {
//           edges {
//             node {
//               ...ProductFields
//             }
//           }
//         }
//         newestProducts {
//           edges {
//             node {
//               ...ProductFields
//             }
//           }
//         }
//       }
//     }
//   }

//   fragment ProductFields on BigCommerceGraphQL_Product {
//     name
//     path
//     images {
//       edges {
//         node {
//           url(width: 300, height: 300)
//         }
//       }
//     }
//     entityId
//     price: prices {
//       price {
//         value
//       }
//     }
//     calculated_price: prices {
//       retailPrice {
//         value
//       }
//     }
//     sale_price: prices {
//       salePrice {
//         value
//       }
//     }
//     variants {
//       edges {
//         node {
//           id: entityId
//         }
//       }
//     }
//   }
// `

const IndexPage = (props) => {
  // const { data } = props
  // const featuredProducts = (data && data.bigCommerceGraphQL.site.featuredProducts.edges) || [];
  // const newestProducts = (data && data.bigCommerceGraphQL.site.newestProducts.edges) || [];

  const newArrivals = generateMockProductData(3, 'shirt');
  // const blogData = generateMockBlogData(3);

  const goToShop = () => {
    navigate('/shop-all');
  }

  return (
    <Layout bgYellow headerBgColor = {'var(--color-yellow)'}>
      <Seo title="Home" />

      {/* Hero Container */}
      {/* Slider */}
      {/* <Hero>
        {defaultHeroContent.map((slide, slideIndex) => (
          <div key={slideIndex}>
            <div data-contentblock>
              <h1 data-title>{slide.heading}</h1>
              <h3 data-text>{slide.subtext}</h3>
            </div>
            <img src={slide.image} data-background alt={slide.heading} />
          </div>
        ))}
      </Hero> */}

      <div className={styles.samplePlaceholder}>

      </div>

      {/* Single */}
      <Hero 
        maxWidth={'500px'}
        image={'/banner1.png'} 
        title={'Essentials for a cold winter'} 
        subtitle={'Discover Autumn Winter 2021'}
        ctaText={'shop now'}
        ctaAction={goToShop}
      />

      {/* Message Container */}
      <div className={styles.messageContainer}>
        <p>This is a demonstration of the geneva theme for verse by <span className={styles.gold}>matter design.</span></p>
        <p>wear by <span className={styles.gold}>sunspel</span> and <span className={styles.gold}>scotch&soda</span></p>
      </div>

      {/* Collection Container */}
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'New Collection'} />
          <ProductCollectionGrid />
        </Container>
      </div>

      {/* New Arrivals */}
      <div className={styles.newArrivalsContainer}>
        <Container>
          <Title name={'New Arrivals'} link={'/shop/all'} textLink={'view all'}/>
          <ProductCardGrid spacing={true} showSlider height={480} columns={3} data={newArrivals} />
        </Container>
      </div>

      {/* Highlight  */}
      <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight 
            image={'/highlight.png'}
            altImage={'highlight image'}
            miniImage={'/highlightmin.png'}
            miniImageAlt={'mini highlight image'}
            title={'Luxury Knitwear'}
            description={`This soft lambswool jumper is knitted in Scotland, using yarn from one of the world's oldest spinners based in Fife`}
            textLink={'shop now'}
            link={'/shop'}
          />
        </Container>
      </div>

      {/* Promotion */}
      <div className={styles.promotionContainer}>
        <Hero image={'/banner2.png'} title={`-50% off \n All Essentials`} />
        <div className={styles.linkContainers}>
              <Link to={'/shop-all'}>WOMAN</Link>
              <Link to={'/shop-all'}>MAN</Link>
        </div>
      </div>

      {/* Quote */}
      <Quote 
        bgColor={'var(--standard-light-grey)'}
        title={'about geneva'}
        quote={"“We believe in two things: the pursuit of quality in everything we do, and looking after one another. Everything else should take care of itself.”"}
      />

      {/* Blog Grid */}
      {/* <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Journal'} subtitle={'Notes on life and style'}/>
          <BlogPreviewGrid data={blogData}/>
        </Container>
      </div> */}

      {/* Promotion */}
      <div className={styles.sustainableContainer}>
          <Hero 
            image={'/banner3.png'} title={'We are Sustainable'}
            subtitle={'From caring for our land to supporting our people, discover the steps we’re taking to do more for the world around us.'}
            ctaText={'read more'}
            maxWidth={'660px'}
            ctaStyle={styles.ctaCustomButton}
          />
      </div>

      {/* Social Media */}
      <div className={styles.socialContainer}>
        <Title name={'Styled by You'} subtitle={'Tag @geneva to be featured.'}/>
        <div className={styles.socialContentGrid}>
          <img src={`/social/socialMedia1.png`} alt={'social media 1'}  />
          <img src={`/social/socialMedia2.png`} alt={'social media 2'}  />
          <img src={`/social/socialMedia3.png`} alt={'social media 3'}  />
          <img src={`/social/socialMedia4.png`} alt={'social media 4'}  />
        </div>
      </div>
      <AttributeGrid />

      {/* <Container size="large">
        <ProductGrid products={featuredProducts} />
        <h2>New Products</h2>
        <ProductSlider products={newestProducts} />
      </Container> */}
    </Layout>
  )
}

export default IndexPage
