/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import PageBanner from '../components/atoms/PageBanner/PageBanner';
import Button from '../components/atoms/Button/Button';
import { getStorage } from '../helpers/general';

// import * as styles from './signup.module.css';

const ChangePasswordPage = () => {
  const auth = useContext(AuthContext);
  const handleChangePassword = auth && auth.changePassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [fields, setFields] = useState({});
  const [init, setInit] = useState({});
  const [token, setToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (isLoggedIn && typeof window !== 'undefined') {
      window.location = '/account';
    }

    if (typeof window !== 'undefined' && window.location.search !== '') {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('c')) {
        setCustomerId(searchParams.get('c'));
      }
      if (searchParams.has('t')) {
        setToken(searchParams.get('t'));
      }
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const attemptChangePassword = e => {
    e.preventDefault();
    handleChangePassword(fields, customerId, token);
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <Seo title="Change Password" />
        <PageBanner
          breadcrumb="Change Password"
          title="Change Password"
          color="latte"
          imageSize="medium"
        >
          <p>
            Passwords must be at least 7 characters and contain both alphabetic
            and numeric characters.
          </p>
        </PageBanner>
        <Container size="large">
          <form onSubmit={e => attemptChangePassword(e)}>
            <div>
              <div className={`formField`}>
                <label htmlFor="password">
                  New Password
                </label>
                <input
                  type="password"
                  name="password"
                  tabIndex="4"
                  onChange={e =>
                    setFields({
                      ...fields,
                      authentication: { new_password: e.target.value }
                    })
                  }
                />
              </div>

              <div className={`formField`}>
                <label htmlFor="password">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="password_confirm"
                  tabIndex="4"
                  onChange={e =>
                    setFields({
                      ...fields,
                      authentication: { new_password: e.target.value }
                    })
                  }
                />
              </div>
            </div>

            <div className="formField">
              <Button
                level="primary"
                type="buttonSubmit"
              >
                Update Password
              </Button>
            </div>
          </form>
        </Container>
      </Layout>
    );
  } else return null;
};

export default ChangePasswordPage;
