import React from 'react'

const ZipLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 285">
  <g>
    <path
      fill={'currentColor'}
      d="M19.7 227.91l6.66 54.25h233.16l-7.63-62.13H143.17l-.95-7.71 100.13-69.96-6.68-54.37H2.52l7.62 62.14h108.9l.96 7.76-100.3 70.02z"
    />
    <path
      fill="#aa8fff"
      d="M262.76 87.99l23.84 194.17h233.34L496.09 87.99H262.76z"
    />
    <path
      fill={'currentColor'}
      d="M764.64 157.9C759.26 114.25 725 87.8 678.37 88h-155.2L547 282.16h69.82l-4.78-38.83h73.89c58.17 0 84.78-36.24 78.71-85.43zm-86.24 31l-73 .08-5.72-46.6 73.41.06c17.27.2 26.1 9.92 27.52 23.23.88 8.55-3.04 23.22-22.21 23.22z"
    />
    <ellipse
      cx={306.9}
      cy={37.62}
      rx={32.7}
      ry={37.01}
      fill={'currentColor'}
      transform="rotate(-46.77 306.922 37.623)"
    />
  </g>
</svg>
)

export default ZipLogo