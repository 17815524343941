import React from 'react'

const ZipLogo = () => (
    <svg width={40} height={28} viewBox="0 0 43 30" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M3.467 29.129A3.435 3.435 0 010 25.662V3.467A3.435 3.435 0 013.467 0h36.066A3.435 3.435 0 0143 3.467v22.195a3.435 3.435 0 01-3.467 3.467H3.467z"
          fill="#F2F2F2"
        />
        <path
          d="M38.969 1.344a2.695 2.695 0 012.687 2.687v21.5a2.695 2.695 0 01-2.687 2.688H4.03a2.695 2.695 0 01-2.687-2.688v-21.5A2.695 2.695 0 014.03 1.344H38.97zm0-1.344H4.03C1.881 0 .001 1.747.001 4.031v21.5c0 2.285 1.746 4.031 4.03 4.031H38.97c2.284 0 4.03-1.746 4.03-4.03v-21.5C43 1.746 41.254 0 38.97 0z"
          fill="#D8D8D8"
        />
        <g transform="translate(5.658 6.79)">
          <path
            fill="#27255F"
            d="M.739 10.299l.286 2.333h10.029l-.328-2.672H6.049l-.04-.332 4.306-3.009-.287-2.338H0l.328 2.672h4.684l.04.334z"
          />
          <path
            fill="#AA8FFF"
            d="M11.193 4.28l1.026 8.352h10.036l-1.026-8.351z"
          />
          <path
            d="M32.78 7.288c-.232-1.878-1.706-3.015-3.711-3.007h-6.675l1.025 8.351h3.003l-.206-1.67h3.178c2.502 0 3.646-1.559 3.385-3.674zM29.07 8.62l-3.14.003-.246-2.004 3.158.003c.743.008 1.122.426 1.183.999.038.368-.13.999-.955.999z"
            fill="#27255F"
          />
          <ellipse
            fill="#27255F"
            transform="rotate(-46.77 13.092 2.115)"
            cx={13.092}
            cy={2.115}
            rx={1.406}
            ry={1.592}
          />
        </g>
      </g>
    </svg>
)

export default ZipLogo