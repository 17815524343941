import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useStaticQuery, graphql } from "gatsby";
import 'react-datepicker/dist/react-datepicker.css';

import Dialog from '../../atoms/Dialog/Dialog';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { wpAllNew } from '../../../helpers/wordpress';

import * as styles from './receipt-dialog.module.css';
import DateTimeInput from '../../atoms/DateTimeInput/DateTimeInput';
import SelectField from '../../atoms/SelectField/SelectField';
import { useEffect } from 'react';
import { extractDate } from '../../../helpers/general';

import moment from 'moment'

const ReceiptDialog = ({ open, onClose, onSubmit, clubId, grantId = '', isUpdate = false, updateReceipt = null }) => {
  const defaultValues = {
    init: false,
    grant: grantId,
    value: '',
    description: '',
    date: `${new Date().getFullYear()}-${String(Number(new Date().getMonth()) + 1).padStart(2, '0')}-${new Date().getDate()}`,
  };
  const [values, setValues] = useState(defaultValues);
  const [selectedFile, setSelectedFile] = useState();
  const [metaFileName, setMetaFileName] = useState(false);
  const [grants, setGrants] = useState(null);

  const dateOfPurchaseRef = useRef();
  // const [isUpdating, setIsUpdating] = useState(updateReceipt);

  const {
      allWpGrantType: { nodes: grantTypes },
  } = useStaticQuery(graphql`
      query {
        allWpGrantType {
          nodes {
            name
            id: databaseId
          }
        }
      }
  `);

  useEffect(() => {
    const fetchGrants = async () => {
      const { response: grantsList } = await wpAllNew('getClubGrantsList', {
        clubId,
        type: 'paid',
        limit: 100
      });
      const _grants = grantsList.map(grant => ({
          id: grant.id,
          // name: decodeURI(grant.title.rendered),
          type: grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]))?.name,
          status: grant.acf.status
              .substr(
              grant.acf.status.lastIndexOf(':') + 1,
              grant.acf.status.length
              )
              .trim(),
          acquittal: grant.acf.acquittal,
          // valueGranted: grant.acf.value_granted,
          valueProvided: grant.acf.value_provided,
          // submittedBy: grant.acf.submitted_by,
          submittedDate: grant.date,
          // reviewedBy: grant.acf.reviewed_by,
          // staffNotes: grant.acf.staff_notes,
          // reason: grant.acf.reason,
          // approvedBy: grant.acf.approved_by,
      })).filter(g => g.status === 'Paid' && !g.acquittal)

      // console.log(_grants);
      if (_grants.length > 0) {
        setGrants(_grants);
      } else {
        setGrants(false);
      }
    }

    if (grants === null) {
      fetchGrants();
    }
  }, [grants, clubId, grantTypes])

  useEffect(() => {
    if (updateReceipt && !values.init) {
      // setGrants(null);
      let tempValues = Object.assign({}, values);
      Object.keys(updateReceipt).map((key) => {
        // if (key === 'grant' && grants) {
        //   // const foundGrant = grants.filter((grant) => {
        //   //   return updateReceipt['grant_id'] === grant.id
        //   // });
        //   // tempValues['grant'] = foundGrant.length > 0 ? foundGrant[0].id : null;
        // } else 
        if (key === 'date') {
          const date = moment(updateReceipt[key], 'DD/MM/YYYY');
          tempValues[key] = date.format('YYYY-MM-DD');
        } else {
          tempValues[key] = updateReceipt[key];
        }
        return true;
      });
      tempValues.init = true;
      setValues(tempValues);
    }
  }, [updateReceipt, values])

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const handleUpload = e => {
    if (e === undefined) return;
    setSelectedFile(e.target.files[0]);
    setMetaFileName(e.target.files[0]?.name);
  };

  const handleSubmit = () => {
    const _values = {...values};
    if (updateReceipt) {
      _values.grant = updateReceipt['grant_id'];
    }

    if (!_values.grant) {
      toast.error('A receipt must be assigned to a grant');

      return false;
    }

    if (!updateReceipt && !selectedFile) {
      toast.error('You must include a photo of your receipt');

      return false;
    }

    if (!_values.value) {
      toast.error('You must include an amount');

      return false;
    }

    if (!_values.date) {
      toast.error('You must include purchase date');

      return false;
    }


    if ((selectedFile || updateReceipt) && _values.value && _values.date && _values.grant) {
      onSubmit({
        ..._values,
        file: selectedFile,
        value: parseFloat(_values.value),
      });
      setValues(defaultValues);
      setSelectedFile(null);
      setMetaFileName(false);
    }
  };

  const handleClose = () => {
    setValues(defaultValues);
    setSelectedFile(null);
    setMetaFileName(false);
    onClose();
  }
  
  return (
    <Dialog
      open={open}
      title={isUpdate ? 'Update Receipt' : 'Upload new receipt'}
      size='sm'
      hideBtnCancel
      hideBtnClose
      onCancel={() => handleClose()}
      onOk={() => handleSubmit()}
      okBtnText='Upload Receipt'
    >
        {grants || isUpdate ? (
          <>
            {isUpdate ? (
              <p className={styles.label}>{values.grant}</p>
            ) : (
              <SelectField
                id='grant'
                label='Grant'
                placeholder='Select a grant'
                value={values.grant}
                handleChange={onHandleChange}
                data={grants.map(grant => ({
                  text: `${grant.type} - ${extractDate(grant.submittedDate)} ($${grant.valueProvided})`,
                  value: grant.id,
                }))}
              />
            )}
            

            <DateTimeInput
              id='date'
              type='date'
              label='Date of Purchase'
              placeholder='Date of Purchase'
              handleChange={onHandleChange}
              value={values.date}
              allowPastDate={true}
              ref={dateOfPurchaseRef}
            />

            <FormInputField
              id='value'
              type='number'
              labelName='Amount'
              value={values.value}
              handleChange={onHandleChange}
              min={0}
            />

            <FormInputField
              id='description'
              type='textarea'
              labelName='Description'
              value={values.description}
              handleChange={onHandleChange}
            />
            {console.log(updateReceipt)}
              {updateReceipt && updateReceipt.receipt && (
                <div>
                  <a
                    target={'_blank'}
                    href={updateReceipt.receipt}
                    className={styles.dropZoneNotice}
                    rel='noreferrer'>
                    <u>Click here to see current attachment</u>
                  </a>
                  <br/>
                </div>
              )}
            <div className={styles.filePickerContainer}>
              <div className={styles.fileHelperContainer}>
                <span className={styles.fileName}>
                  {metaFileName ? metaFileName : 'Photo of Receipt'}
                </span>
                <span className={styles.fileHelper}>File cannot exceed 1MB.</span>
              </div>
              <label className={styles.filePicker} htmlFor='file'>
                <input
                  id='file'
                  type='file'
                  name='file'
                  onChange={handleUpload}></input>
                Select Photo
              </label>
            </div>
          </>
        ) : (
          <>
            {grants === false && (
              <>You have no active grants to submit receipts to.</>
            )}

            {grants === null && (
              <>Please wait - fetching active grants...</>
            )}
          </>
        )}
    </Dialog>
  );
};

export default ReceiptDialog;

