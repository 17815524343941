import React, { useContext, useState, useMemo } from 'react';
import AuthContext from '../../../context/AuthProvider';
import AccordionCard from '../../atoms/AccordionCard/AccordionCard';
import DataTable from "../../organisms/DataTable/DataTable";
import Button from "../../atoms/Button/Button";
import { getPerson } from '../../../helpers/ortto';
import { extractDate, authUSUStaff } from '../../../helpers/general';

import * as styles from './MeetingDetail.module.css';

const MeetingDetail = ({ history }) => {
    const auth = useContext(AuthContext);
    const isStaff = authUSUStaff(auth);
    const [nominations, setNominations] = useState(null);
    const [memberNames, setMemberNames] = useState({});
    const [newClubExecs, setNewClubExecs] = useState(null);
    const [currentClubExecs, setCurrentClubExecs] = useState(null);

    useMemo(() => {
        const prepData = async () => {
        const personFields = ['first', 'last', 'str:cm:membernumber'];
        const memberNumbers = [];

        if (history.acf.returning_officer) {
            memberNumbers.push(history.acf.returning_officer);
        }

        if (history.acf.nominations) {
            const noms = JSON.parse(history.acf.nominations);
            setNominations(noms);
        }
        
        if (history.acf.new_club_execs) {
            history.acf.new_club_execs.map(e => {
                memberNumbers.push(e.member_number);
                return true;
            });
            setNewClubExecs(history.acf.new_club_execs);
        }
        
        if (history.acf.current_club_execs) {
            history.acf.current_club_execs.map(e => {
                memberNumbers.push(e.member_number);
                return true;
            });
            setCurrentClubExecs(history.acf.current_club_execs);
        }

        if (memberNumbers.length > 0) {
            const memberData = await getPerson(personFields, memberNumbers, 'str:cm:membernumber');
            if (String(memberData.status).startsWith('2') && Array.isArray(memberData.response.contacts)) {
                const _memberNames = {};
                memberData.response.contacts.map(m => {
                    _memberNames[m.fields['str:cm:membernumber']] = `${m.fields['str::first']} ${m.fields['str::last']}`;
                    return true;
                });
                setMemberNames(_memberNames);

                if (history.acf.new_club_execs) {
                const _updatedNewClubExecs = history.acf.new_club_execs.map(ce => {
                    return {
                        ...ce,
                        name: _memberNames[ce.member_number] || "Name unknown"
                    }
                });
                setNewClubExecs(_updatedNewClubExecs);
                }

                if (history.acf.current_club_execs) {
                const _updatedCurrentClubExecs = history.acf.current_club_execs.map(ce => {
                    return {
                        ...ce,
                        name: _memberNames[ce.member_number] || "Name unknown"
                    }
                });
                setCurrentClubExecs(_updatedCurrentClubExecs);
                }
            }
        }
        }

        if (history) {
            console.log(history);
        prepData();
        }
    }, [history]);

    const fetchMemberName = (memberNumber) => {
        if (Object.keys(memberNames).length > 0 && memberNumber in memberNames) {
            return memberNames[memberNumber];
        } else if(Object.keys(memberNames).length > 0) {
            return "Name unknown";
        } else {
            return "Fetching...";
        }
    }

    return history ? (
        <>
        <AccordionCard title="Meeting results">
            <div className={styles.root}>
                    <div className="mb-5">
                        <h6>GM Result</h6>
                        {(history.acf.result && history.acf.result === 'rejected') ? (
                            <div className="mb-5">
                                Rejected ({history.acf.actioned_at ? extractDate(history.acf.actioned_at, true) : extractDate(history.modified, true)})
                                {(isStaff && history.acf.result_by) && (<> By {history.acf.result_by}</>)}
                            </div>
                        ) : (
                            <div className="mb-5">
                                Approved ({history.acf.actioned_at ? extractDate(history.acf.actioned_at, true) : extractDate(history.modified, true)})
                                {(isStaff && history.acf.result_by) && (<> By {history.acf.result_by}</>)}
                            </div>
                        )}

                        {(history.acf.rejected_reason && history.acf.rejected_reason !== '') && (
                            <div>
                                <h6>Rejection reason</h6>
                                <div>{history.acf.rejected_reason}</div>
                            </div>
                        )}
                    </div>
                {(history.acf.minutes || history.acf.constitution) && (
                    <div>
                        <h6>Meeting documents</h6>
                        <div class="grid grid-50 mt-4 mb-5">
                        {history.acf.minutes && (
                            <div className="outline text-center" style={{paddingBottom: '20px'}}>
                            <p>Minutes of meeting</p>
                            <Button href={history.acf.minutes} level="primary" target="_blank">View minutes</Button>
                        </div>
                        )}

                        {history.acf.constitution && (
                            <div className="outline text-center" style={{paddingBottom: '20px'}}>
                            <p>Constitution</p>
                            <Button href={history.acf.constitution} level="primary" target="_blank">View constitution</Button>
                        </div>
                        )}
                        </div>
                    </div>
                )}

                {history.acf.returning_officer && (
                    <div className="mb-5">
                        <h6>Acting Returning Officer</h6>
                        <div>{fetchMemberName(history.acf.returning_officer)} ({history.acf.returning_officer})</div>
                    </div>
                )}

                {history.acf.new_club_roles && (
                    <div>
                        <h6>Roles defined for the year</h6>
                        <DataTable
                            tableData={history.acf.new_club_roles}
                            headingKeys={[
                                {
                                    label: 'Role',
                                    data_key: 'role'
                                },
                                {
                                    label: 'Amount',
                                    data_key: 'amount'
                                },
                            ]}
                        />
                    </div>
                )}

                {nominations && (
                    <div>
                        <h6>Nominations</h6>
                        {Object.keys(nominations).map(position => (
                            <div>
                                <strong>{position}</strong>
                                <DataTable
                                    tableData={nominations[position]}
                                    headingKeys={[
                                        {
                                            label: 'Name',
                                            data_key: 'name'
                                        },
                                        {
                                            label: 'Member Number',
                                            data_key: 'member_number'
                                        }
                                    ]}
                                />
                            </div>
                        ))}
                    </div>
                )}

                {newClubExecs && (
                    <div>
                        <h6>Club executives voted in</h6>
                        <DataTable
                            tableData={newClubExecs}
                            headingKeys={[
                                {
                                    label: 'Position',
                                    data_key: 'position'
                                },
                                {
                                    label: 'Name',
                                    data_key: 'name'
                                },
                                {
                                    label: 'Member Number',
                                    data_key: 'member_number'
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </AccordionCard>

        <AccordionCard title="Club details prior to meeting">
            <div className={styles.root}>
                <div>
                    <h6>Roles prior to meeting</h6>
                    <DataTable
                        tableData={history.acf.current_club_roles}
                        headingKeys={[
                            {
                                label: 'Role',
                                data_key: 'role'
                            },
                            {
                                label: 'Amount',
                                data_key: 'amount'
                            },
                        ]}
                    />
                </div>

                <div>
                    <h6>Club executives prior to the meeting</h6>
                    <DataTable
                        tableData={currentClubExecs}
                        headingKeys={[
                            {
                                label: 'Position',
                                data_key: 'position'
                            },
                            {
                                label: 'Name',
                                data_key: 'name'
                            },
                            {
                                label: 'Member Number',
                                data_key: 'member_number'
                            },
                        ]}
                    />
                </div>
            </div>
        </AccordionCard>
        </>
    ) : (<></>);
};

export default MeetingDetail;
