import React from 'react'

const Check = () => (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M10 .354c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10z"
          fill="currentColor"
        />
        <path
          d="M10 .354c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm3.949 6.086l-5.611 5.608-2.813-2.813-1.414 1.414 4.227 4.23 7.026-7.025-1.415-1.414z"
          fill="#FFF56D"
          fillRule="nonzero"
        />
      </g>
    </svg>
)

export default Check