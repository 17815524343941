import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';

import CartContext from '../../../context/CartProvider';

import Button from '../../atoms/Button/Button';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import MiniCartItem from '../../atoms/DemoMiniCartItem/MiniCartItem';
import Loader from '../../atoms/Loader/Loader';
import Icon from '../../atoms/Icon/Icon';

import * as styles from './MiniCart.module.css';
import { isGradHireProduct } from '../../../helpers/bigcommerce';

const MiniCart = (props) => {

  // const sampleCartItem = {
  //   image: '/products/pdp1.jpeg',
  //   alt: '',
  //   name: 'Lambswool Crew Neck Jumper',
  //   price: 220,
  //   color: 'Anthracite Melange',
  //   size: 'xs',
  // };

  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const { state } = ctx;
  const { lineItems, numberItems, cartAmount, productInfo } = state.cart;
  const { close } = props;

  let aggregatedItems = [];
  if(lineItems?.physical_items && lineItems?.physical_items.length !== 0) {
    aggregatedItems.push(...lineItems.physical_items)
  }

  if(lineItems?.digital_items && lineItems?.digital_items.length !== 0) {
    aggregatedItems.push(...lineItems.digital_items)
  }

  const modifiedAggregatedItems = aggregatedItems.map((item) => {
    if(item?.product_id && productInfo[item.product_id]) {
      const max = productInfo[item.product_id]?.order_quantity_maximum;
      const visible = productInfo[item.product_id]?.is_visible;
      return {
        ...item,
        order_quantity_maximum: max,
        is_visible: visible
      }
    } else {
      return item;
    }
  })

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <h4>Your Bag</h4>
        <div className={styles.closeContainer} onClick={close} role={'presentation'}>
          <Icon symbol={'close'}></Icon>
        </div>
      </div>
      <div className={styles.cartItemsContainer}>
        {modifiedAggregatedItems.map((cartItem) => {
          return(
            <div key={cartItem.id}>
              <MiniCartItem
                id={cartItem.id}
                qty={cartItem.quantity}
                image={cartItem.image_url}
                options={cartItem.options}
                name={cartItem.name}
                price={cartItem.sale_price ? cartItem.sale_price : cartItem.list_price}
                item={cartItem}
                disableItemAdjustment={cartItem?.order_quantity_maximum === 1 || isGradHireProduct(cartItem.product_id)}
                is_visible={cartItem.is_visible}
                product_id={cartItem.product_id}
              />
            </div>
          );
        })}
        {!numberItems &&
          <div className={styles.emptyContainer}>
            <p className="">Your cart is empty</p>
            <Link to="/shop">
              Take a look around
            </Link>
          </div>
        }

        {state?.cartLoading === true &&
        <>
          <div className={styles.cartBackdrop} />
          <div className={styles.cartLoading}>
            <Loader />
          </div>
        </>}
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryContent}>
          <div className={styles.totalContainer}>
            <span className={styles.totalLabel}>Total</span>
            <span className={styles.total}>
              <CurrencyFormatter amount={cartAmount} appendZero />
            </span>
          </div>
          {/* <span className={styles.taxNotes}>Taxes and shipping will be calculated at checkout</span> */}
          <Button type="button" onClick={() => navigate('/confirm-order')} level={'primary'} fullWidth>Checkout</Button>
          {/* <div className={styles.linkContainer}>
            <Link to={'/'}>continue shopping</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
