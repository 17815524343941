import React from 'react'
import Icon from '../Icon/Icon';

import * as styles from './ZipPayInfo.module.css'

const ZipPayInfo = () => {
    return (
        <div className={styles.modal}>
            <div className={`${styles.header} ${styles.bottomLine}`}>
                <div className={styles.svgSection}>
                    <Icon symbol="zipLogo" />
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomLine}`}>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentAdd" />
                    </div>
                    <p className={styles.blockSubtitle}>Add</p>
                    <p className={styles.blockMessage}>Add items what you want to the bag.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentCheckout" />
                    </div>
                    <p className={styles.blockSubtitle}>Checkout</p>
                    <p className={styles.blockMessage}>Select Zip at checkout.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentRepay" />
                    </div>
                    <p className={styles.blockSubtitle}>Repay</p>
                    <p className={styles.blockMessage}>Configure your Zip account to repay how you want.</p>
                </div>
            </div>
            <div className={styles.footer}>
                <div className="flex-between">
                    <div className={styles.zipPay}>
                        <Icon symbol="zipPayLogo" />
                        <span>Up to $1000<br />Interest free always<sup>1</sup></span>
                    </div>
                    <div className={styles.zipMoney}>
                        <Icon symbol="zipMoneyLogo" />
                        <span>Over $1000<br />Interest free terms available<sup>2</sup></span>
                    </div>
                </div>
                <p>1. For Zip Pay - Minimum monthly repayments are required. A monthly account fee of $7.95 applies. This fee is waived each month you pay your statement closing balance, in full, before the due date. Available to approved applicants only. T&Cs apply.</p>
                <p>2. For Zip Money - Available to approved applicants only. The repayment advertised will repay the transaction balance within the interest free period based on the advertised price (excluding a monthly fee of $6.00 and a one-off establishment fee which may apply for new customers). Minimum monthly payments are required - paying only the minimum monthly repayment may not necessarily repay a purchase within the interest free period. Any unpaid purchase balance at he expiry of the interest free period will be charged interest at the standard annual percentage rate, currently 19.9%. Terms & conditions apply and are available on application. See your contract for further details. Credit provided by ZipMoney Payments Pty Ltd (ABN 58 164 440 993, Australian Credit Licence Number 441878.</p>
            </div>
        </div>
    );
}

export default ZipPayInfo