import React from 'react';

import * as styles from './AddToCalendarButton.module.css';
import 'add-to-calendar-button';

const AddToCalendarButton = ({ 
    name,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    location,
    small = false
}) => {
  return (
    <div className={`${styles.root} ${small ? 'smaller' : ''}`}>
        <add-to-calendar-button
            name={name}
            description={description}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            location={location}
            options="['Apple','Google','iCal','Microsoft365','Yahoo']" //'Outlook.com',
            customLabels='{"ms365": "Microsoft/Outlook"}'
            timeZone="Australia/Sydney"
            trigger="click"
            size="2"
            inline
            listStyle="modal"
            iCalFileName="Reminder-Event"
            hideCheckmark="true"
        />
    </div>
  );
};

export default AddToCalendarButton;

