import React from 'react'
import * as styles from './DateTimeInput.module.css'
import moment from 'moment'

const DateTimeInput = React.forwardRef((props, ref) => {
    const {
        id,
        type,
        label,
        placeholder,
        value,
        handleChange,
        disabled,
        minDays = null,
        maxDays = null,
        allowPastDate
    } = props;

    const disablePastDate = () => {
        const setDate = moment();
        if (minDays) {
          setDate.add(minDays, 'days');
        }
        const today = new Date(setDate.format('YYYY-MM-DD'));
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const maxSelectionDate = () => {
        if (maxDays) {
            const maxDate = moment().add(maxDays, 'days');
            return maxDate.format('YYYY-MM-DD');
        }
    }


  return (
    <div className={styles.root}>
      {label && <label htmlFor={id}>{label}</label>}

      <input
          id={id}
          type={value ? type : 'text'}
          placeholder={placeholder}
          min={(type === 'date' && !allowPastDate) ? disablePastDate() : undefined}
          max={maxDays ? maxSelectionDate() : undefined}
          value={value}
          onChange={e => handleChange(id, e.target.value)}
          ref={ref}
          disabled={disabled}
          onFocus={ref ? () => (ref.current.type = type) : undefined}
          onBlur={ref ? () => (ref.current.type = value ? type : 'text') : undefined}
      />
    </div>
  );
});

export default DateTimeInput;
