import React from 'react'
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulBlockHero.module.css';

const ContentfulBlockHero = props => {
    const { 
        size,
        heroType, 
        content,
        // configuration,
        // orientation
    } = props;
    const backgroundAsset = get(props, 'backgroundAsset.file.url', false);

    if (heroType === 'Image and Text' || heroType === 'Image Only') {
        return (
            <section className={`${styles.pageHeroImage}`} style={{backgroundImage: `url(${backgroundAsset}?w=2000&h=600&fit=fill)`}}>
                <Container size={size}>
                    {content && (
                        <ContentfulRichText raw={content.raw} />
                    )}
                </Container>
            </section>
        )
    } else {
        return (
            <section className={styles.pageHero}>
                <Container size={size}>
                {content && (
                    <ContentfulRichText raw={content.raw} />
                )}
                </Container>
            </section>
        )
    }
}

export default ContentfulBlockHero;