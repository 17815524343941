import React from 'react';

const Add = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.5 16V8.5M8.5 8.5V1M8.5 8.5H16M8.5 8.5H1'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Add;
