import React from 'react'

import Container from '../Container/Container'

import * as styles from './PageBanner.module.css'

const PageBanner = ({ children, title, color, image, imageName }) => {
  const classes = [styles.root];

  if (color in styles) {
    classes.push(styles[color])
  }

  const classOutput = classes.join(' ');

  const bannerContainerClasses = [styles.containerContent];

  if (image in styles) {
    bannerContainerClasses.push(styles[image])
  }

  if (imageName in styles) {
    bannerContainerClasses.push(styles[imageName])
  }

  const classContainerOutput = bannerContainerClasses.join(' ');

  return (
    <div className={classOutput}>
        <Container size="large">
          <div className={classContainerOutput}>
            <div className={styles.breadCrumb}>{title}</div>
            <h1 className={styles.header}>{title}</h1>
            {children && (
                <div className={styles.subtitle}>{children}</div>
            )}
          </div>
        </Container>
    </div>
  )
}

export default PageBanner
