import { useState } from "react";
/**
 * Custom hook to handle boolean values
 * @param initialVal initial value will be false if not passed
 * @returns
 */
const useDisclosure = (initialVal = false) => {
  const [isOpen, setIsOpen] = useState(initialVal);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    onOpen,
    onClose,
    toggle
  };
};
export default useDisclosure;
