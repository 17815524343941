import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { dissectTitle, getUriFromURL } from '../../../../helpers/general';
import CurrencyFormatter from '../../../atoms/CurrencyFormatter/CurrencyFormatter';
import Icon from '../../../atoms/Icon/Icon';
// import Loader from '../../../atoms/Loader/Loader';
import AdjustItem from '../../../molecules/DemoAdjustItem/AdjustItem';
// import ItemStatus from '../../../molecules/Localisr/ItemStatus';

import * as styles from '../Cart.module.css';
// import MessageAlert from '../../../atoms/MessageAlert/MessageAlert';
// import { isGradHireProduct } from '../../../../helpers/bigcommerce';

const CartItem = ({
  cartType,
  currency,
  item,
  productInfo,
  itemType,
  updatingItem,
  removeItemFromCart,
}) => {

  // case in handling rewards sem and locker hire items
  let disableAdjustItem = false;
  let isVisible = true;
  if(productInfo[item.product_id]) {
    if(productInfo[item.product_id]?.order_quantity_maximum === 1) {
      disableAdjustItem = true;
    }

    if (productInfo[item.product_id]['no_qty']) {
      disableAdjustItem = true;
    }
    
    isVisible = productInfo[item.product_id]?.is_visible;
  }
  
  // const [showAdjustMobile, setShowAdjustMobile] = useState(false);

  const [title, uri, sku] = useMemo(() => {
    const getProductTitle = () => {
      // TODO: Check for BC variant and append to title
      return dissectTitle(item.name, '(')[0];
    };

    const productTitle = getProductTitle();
    const productUri = getUriFromURL(item.url);
    if (itemType === 'gift') {
      return [
        `${productTitle} - Gift Certificate for ${item.recipient.name}`,
        productUri,
        `Theme: ${productTitle}`
      ];
    }
    return [productTitle, productUri, item.sku];
  }, [itemType, item]);

  const itemImage = useMemo(() => {
    if (cartType === 'full') {
      return (
        <div className={styles.bcCartImage}>
          {isVisible 
          ?
            <Link to={uri}>
              {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
              {itemType === 'custom' && (
                <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
              )}
            </Link>
           : <>
              {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
              {itemType === 'custom' && (
                <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
              )}
           </>
           }

        </div>
      );
    }
    if (cartType === 'mini') {
      return (
        <div className={styles.bcMiniCartImage}>
          <Link to={uri}>
            {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {itemType === 'custom' && (
              <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
            )}
          </Link>
        </div>
      );
    }
  }, [cartType, item, itemType, uri, isVisible]);

  // const formatDate = (date) => {
  //   const dateObj = new Date(date);
  //   return `${dateObj.getDate()} ${dateObj.toLocaleString('default', { month: 'long' })}, ${dateObj.getFullYear()}`;
  // }

  const isMiniCart = cartType === 'mini';
  const isUpdating = updatingItem === item.id;

  return (
    <div
      className={clsx(
        styles.bcCartItem,
        isMiniCart && styles.bcMiniCartItem,
        isUpdating && styles.disableAction,
        !isMiniCart && styles.bcCartItemFull,
      )}
      data-sku={sku}
    >
      
      <div
        className={clsx(    
          !isMiniCart && styles.bcTitleImage,
          isMiniCart && styles.bcMiniCartItemRow,
          isMiniCart && isUpdating && styles.isWorking 
        )}
      >
        {itemImage}
        <div className={styles.bcProductTitleFull}>
          {isVisible === true
          ? <Link to={uri}>{title}</Link> 
          : <span className={styles.bcProductLimitOne}>{title}</span>}
          {item.options && (
            <div className={styles.bcProductOptions}>
              {item.options.map((option, optionIndex) => (
                <span key={optionIndex}>{option.name}: {option.value}</span>
              ))}
            </div>
          )}
          {productInfo[item.product_id] && productInfo[item.product_id]['unable_item'] && (
            <span className={styles.textWarning}>
              <span className={styles.icon}><Icon symbol="infoSolid" /></span> This product cannot proceed with this checkout.
            </span>
          )}
          {/* <ItemStatus item={item}/> */}
        </div>
      </div>
        {/* <div className={styles.cartItemDetails}>
          <div className={styles.rowCartItemDetails}>
            <div className={isMiniCart ? styles.bcProductTitleMini : styles.bcProductTitleFull}>
            <div className={styles.bcProductTitleFull}>
              <Link to={uri}>{title}</Link>
              <ItemStatus item={item}/>
            </div>
            {isMiniCart && (
              <div className={styles.bcMiniCartPrice}>
                <div>
                  <CurrencyFormatter
                    currency={currency.code}
                    amount={item.extended_list_price || item.amount}
                    ignoreTax={true}
                  />
                  </div>
              </div>
            )}
          </div>
          {isMiniCart && <div className={`${styles.rowCartItemDetails} ${styles.qtyContainer}`}>
            <div className={styles.bcProductDetail}>
              Qty: {item.quantity}
              {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
                <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
              )}
            </div>
            {isUpdating ? (
              <span className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}>
                <Loader size="small" />
              </span>
            ) : (
              <span
                data-button
                role="presentation"
                className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
                onClick={() => removeItemFromCart(item.id)}
              >
                <Icon symbol="close" />
              </span>
            )}
          </div>}
        </div>
      </div> */}
        
      <div className={styles.bcCartDetails}>
        <div className={clsx('flex-middle', styles.bcCartPrice)}>
            <CurrencyFormatter
              currency={currency.code}
              amount={item.list_price || item.amount}
              ignoreTax={true}
            />
        </div>
        <AdjustItem disabled={disableAdjustItem} initQty={item.quantity} item={item} />
        <div className={clsx('flex-middle', styles.bcCartPrice)}>
          <div>
            <CurrencyFormatter
              currency={currency.code}
              amount={item.list_price * item.quantity || item.amount * item.quantity}
              ignoreTax={true}
            />
          </div>
        </div>
        <div className={`${styles.deleteIconContainer}`} role={'presentation'} onClick={() => removeItemFromCart(item.id)}>
          <Icon symbol={'binAlt'}></Icon>
        </div>
      </div>
      
      {/* {!isMiniCart && (
        <div className={styles.bcCartDetils}>
          <div className={clsx('flex-middle', styles.bcCartPrice)}>
            <div>
              <CurrencyFormatter
                currency={currency.code}
                amount={item.list_price || item.amount}
                ignoreTax={true}
              />
            </div>
          </div>
          <div className={clsx('flex-center')}>
            <AdjustItem
              isCart={true}
              item={item}
              cartType={cartType}
              updatingItem={updatingItem}
              updateCartItemQuantity={updateCartItemQuantity}
            />
          </div>
          <div className={clsx('flex-between', styles.bcCartPrice)}>
            <div>
              <CurrencyFormatter
                currency={currency.code}
                amount={item.extended_list_price || item.amount}
                ignoreTax={true}
              />
            </div>
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
          </div>
        </div>
      )} */}

      {/* {!isMiniCart && (
        <div className={styles.bcCartMobileItem}>
          <div>
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
          </div>
        </div>
      )} */}

    {/* {cartType === 'full' && 
      <div className={styles.bcCartMobileAdjustItem}>
      <AdjustItem
        item={item}
        isCart={true}
        cartType={cartType}
        updatingItem={updatingItem}
        updateCartItemQuantity={updateCartItemQuantity}
      />
    </div>} */}

    {/* {cartType === 'full' &&     
    <div className={styles.bcCartMobilePrice}>
      <div>
        <CurrencyFormatter
          currency={currency.code}
          amount={item.extended_list_price || item.amount}
          ignoreTax={true}
        />
      </div>
    </div>} */}


    </div>
  );
};

CartItem.propTypes = {
  removeItemFromCart: PropTypes.func,
  updateCartItemQuantity: PropTypes.func
};

CartItem.defaultProps = {
  removeItemFromCart: () => null,
  updateCartItemQuantity: () => null
};

export default CartItem;
