import React from 'react';
import { navigate } from 'gatsby';

import Logo from '../Logo/Logo';

import * as styles from './Brand.module.css';

const Brand = (props) => {

  const { caption, size = 'lg', color = 'var(--standard-black)' } = props;
  
  return (
    <div className={styles.root} role={'presentation'} onClick={() => navigate('/')}>
      <div
        style={{color: color}} 
        className={`${styles.logoContainer} ${size === 'md' ? styles.mdLogoContainer : ''}`}>
        <Logo />
      </div>
      {caption &&
      <div className={styles.captionContainer}>
        <span className={styles.caption}>{caption}</span>
      </div>
      }
    </div>
  );
};

export default Brand;
