// extracted by mini-css-extract-plugin
export var ClubSelection = "ClubSelection-module--ClubSelection--c776e";
export var buttonContainer = "ClubSelection-module--buttonContainer--bbce6";
export var club = "ClubSelection-module--club--57e9c";
export var clubContainer = "ClubSelection-module--clubContainer--c3925";
export var clubRow = "ClubSelection-module--clubRow--d22c0";
export var clubRow0 = "ClubSelection-module--clubRow0--49576";
export var clubRow1 = "ClubSelection-module--clubRow1--7b0ee";
export var clubRow2 = "ClubSelection-module--clubRow2--0239d";
export var headline = "ClubSelection-module--headline--e260e";
export var headlineContainer = "ClubSelection-module--headlineContainer--ec1a2";
export var subheading = "ClubSelection-module--subheading--7bfb4";