// extracted by mini-css-extract-plugin
export var block = "ZipPayInfo-module--block--b7473";
export var blockMessage = "ZipPayInfo-module--blockMessage--642da";
export var blockSubtitle = "ZipPayInfo-module--blockSubtitle--f79c6";
export var body = "ZipPayInfo-module--body--999cb";
export var bottomLine = "ZipPayInfo-module--bottomLine--a6322";
export var footer = "ZipPayInfo-module--footer--91c95";
export var header = "ZipPayInfo-module--header--90ce4";
export var imgContainer = "ZipPayInfo-module--imgContainer--edba7";
export var intro = "ZipPayInfo-module--intro--8a22a";
export var modal = "ZipPayInfo-module--modal--6007d";
export var svgSection = "ZipPayInfo-module--svgSection--694dc";
export var titles = "ZipPayInfo-module--titles--e8e21";
export var zipMoney = "ZipPayInfo-module--zipMoney--78bd9";
export var zipPay = "ZipPayInfo-module--zipPay--df84d";