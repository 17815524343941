import React, { useMemo } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { hasStaffPermissions } from '../../../helpers/general';
// import CartContext from '../../../context/CartProvider';
import * as styles from './AccountPageMenu.module.css';

const AccountPageMenu = ({ isLoggedIn, menuType, location, usuUserData, firstName }) => {
  // const cart = useContext(CartContext);
  // console.log(cart);
  const {
      wp: {
        acfOptionsGeneral: { siteManagement : { startAClubCapability } },
      }
  } = useStaticQuery(graphql`
      query {
        wp {
          acfOptionsGeneral {
            siteManagement {
              startAClubCapability
            }
          }
        }
      }
  `);

  const menuItems = useMemo(() => {

    const linkToManagementPage = (managePath) =>{
      navigate(`/account/my-usu/clubs/manage/${managePath}`,{
          state: location.state
      })
    }

    if (!get(location, 'state.clubStatus') && menuType === 'clubManage') {
      navigate(`/account/`)
    }

    /* Unauthenticated menu */
    if (!isLoggedIn) {
      return [
        <Link to={`/login`}key="login">
          Login
        </Link>,
        <Link to="/join" key="signup">
          Join USU
        </Link>
      ];
    }

    /* Club Exec of a club fetching signatures menu */
    if (menuType === 'clubManage' && ['signatures', 'compliant', 'ign'].indexOf(get(location, 'state.clubStatus', [])) > -1) {
      return [
        <Link to="/account/my-usu/clubs" key="my-clubs" activeClassName={styles.active}>
          Back to Clubs list
        </Link>,
        <div className={styles.divider} key="divider-top" />,
        <span role="presentation" key="dashboard" className={`${styles.link}`} onClick={() => linkToManagementPage('')}>Club Dashboard</span>,
        <span role="presentation" key="members" className={`${styles.link}`} onClick={() => linkToManagementPage('members')}>View Interested</span>,
        <div className={styles.divider} key="divider-bottom" />,
        <Link to="/logout" key="logout">
          Log out
        </Link>
        
      ];
    }


    /* Club Exec of a club awaiting approval menu */
    if (menuType === 'clubManage' && ['engaged'].indexOf(location?.state?.clubStatus) > -1) {
      return [
        <Link to="/account/my-usu/clubs" key="my-clubs" activeClassName={styles.active}>
          Back to Clubs list
        </Link>,
        <div className={styles.divider} key="divider-top" />,
        <span role="presentation" key="dashboard" className={`${styles.link}`} onClick={() => linkToManagementPage('')}>Club Dashboard</span>,
        <div className={styles.divider} key="divider-bottom" />,
        <Link to="/logout" key="logout">
          Log out
        </Link>
        
      ];
    }
    
    /* Club Exec of a fully approved club menu */
    if (menuType === 'clubManage' && location?.state?.clubStatus === 'publish') {
      return [
        <Link to="/account/my-usu/clubs" key="my-clubs" activeClassName={styles.active}>
          Back to Clubs list
        </Link>,
        <div className={styles.divider} key="divider-top" />,
        <span role="presentation" key="dashboard" className={`${styles.link}`} onClick={() => linkToManagementPage('')}>Club Dashboard</span>,
        <span role="presentation" key="finances" className={`${styles.link}`} onClick={() => linkToManagementPage('finances')}>Club Finances</span>,
        <span role="presentation" key="notices" className={`${styles.link}`} onClick={() => linkToManagementPage('notices')}>Club Notices</span>,
        <span role="presentation" key="preferences" className={`${styles.link}`} onClick={() => linkToManagementPage('preferences')}>Club Preferences</span>,
        <span role="presentation" key="details" className={`${styles.link}`} onClick={() => linkToManagementPage('details')}>Edit Club Details</span>,
        <span role="presentation" key="events" className={`${styles.link}`} onClick={() => linkToManagementPage('events')}>Manage Events</span>,
        <span role="presentation" key="communicate" className={`${styles.link}`} onClick={() => linkToManagementPage('communicate')}>Communicate to Members</span>,
        <span role="presentation" key="members" className={`${styles.link}`} onClick={() => linkToManagementPage('members')}>View Members</span>,
        <span role="presentation" key="club-exec" className={`${styles.link}`} onClick={() => linkToManagementPage('club-execs')}>View Club Execs</span>,
        <span role="presentation" key="resource-library" className={`${styles.link}`} onClick={() => linkToManagementPage('resource-library')}>Resource Library</span>,
        <div className={styles.divider} key="divider-bottom" />,
        <Link to="/logout" key="logout">
          Log out
        </Link>
        
      ];
    }
    
    /* USU Staff */
    const staffControl = [];
    if (hasStaffPermissions(usuUserData, [2, 3, 4])) {
      staffControl.push(...[
        // <div className={styles.divider} key="divider-top" />,
        <Link to="/account/manage-events/" key="manage-events" activeClassName={styles.active}>
          Manage Events
        </Link>,
        <Link to="/account/manage-clubs/" key="manage-clubs" activeClassName={styles.active}>
          Manage Clubs
        </Link>,
        <Link to="/account/manage-finances/" key="manage-finances" activeClassName={styles.active}>
          Manage Finances
        </Link>,
        <Link to="/account/manage-notices/" key="manage-notices" activeClassName={styles.active}>
          Manage Club Notices
        </Link>,
        <Link to="/account/manage-resources/" key="manage-resources" activeClassName={styles.active}>
          Manage Club Resources
        </Link>,
        <Link to="/account/manage-certificates/" key="manage-certificates" activeClassName={styles.active}>
          Manage Certificates
        </Link>,
        <div className={styles.divider} key="divider-middle" />,
      ]);
    }

    const startClub = [];
    if (startAClubCapability || hasStaffPermissions(usuUserData, [2, 3, 4])) {
      startClub.push(...[
        <Link to="/account/start-club" key="my-clubs" activeClassName={styles.active} style={(!startAClubCapability && hasStaffPermissions(usuUserData, [2, 3, 4])) ? {fontStyle: "italic", opacity: 0.6} : {}}>
          Start A Club
        </Link>,
      ])
    }
    // if (startAClubCapability) {
    //   startClub.push(...[
    //     <Link to="/account/start-club" key="my-clubs" activeClassName={styles.active}>
    //       Start A Club
    //     </Link>,
    //   ])
    // }

    return [
      // <Link to="/account/" key="Dashboard">
      //   Dashboard
      // </Link>,
      ...staffControl,
      <Link to="/account" key="my-details" activeClassName={styles.active}>
        My USU
      </Link>,
      ...startClub,
      <Link to="/account/interests" key="my-interests" activeClassName={styles.active}>
        Update Your Interests
      </Link>,
      <Link to="/account/orders" key="orders" activeClassName={styles.active}>
        Orders
      </Link>,
      <Link to="/account/volunteer" key="volunteering" activeClassName={styles.active}>
        Volunteering
      </Link>,
      <Link to="/account/certificates" key="certificates" activeClassName={styles.active}>
        Certificates
      </Link>,
      // <a href={`https://secure.usuonline.com/lockb_test/?membernumber=${usuUserData?.MemberNumber}&bepozid=${usuUserData?.BepozID}`} rel={'noreferrer'} target={"_blank"} key="lockerHire">Locker Hire</a>,
      <a href={`https://secure.usuonline.com/lockb/?membernumber=${usuUserData?.MemberNumber}&bepozid=${usuUserData?.BepozID}`} rel={'noreferrer'} target={"_blank"} key="lockerHire">Locker Hire</a>,
      <a href={`https://secure.usuonline.com/langex/?membernumber=${usuUserData?.MemberNumber}&bepozid=${usuUserData?.BepozID}`} rel={'noreferrer'} target={"_blank"} key="languageExchange">Language Exchange</a>,
      // <Link to="/account/settings" key="settings" activeClassName={styles.active}>
      //   Settings
      // </Link>,
      <div className={styles.divider} key="divider-bottom" />,
      <Link to="/logout" key="logout">
        Log Out
      </Link>
      
    ];
  }, [isLoggedIn, menuType, location, usuUserData, startAClubCapability]);

  return (
    <div className={styles.root}>
      {menuItems}
    </div>
  );
};

export default AccountPageMenu;
