// extracted by mini-css-extract-plugin
export var ContentfulBlockArticle = "ContentfulBlockArticle-module--ContentfulBlockArticle--74d8b";
export var paddingBottomLarge = "ContentfulBlockArticle-module--paddingBottomLarge--a63ae";
export var paddingBottomMedium = "ContentfulBlockArticle-module--paddingBottomMedium--e1480";
export var paddingBottomSmall = "ContentfulBlockArticle-module--paddingBottomSmall--2b83e";
export var paddingBottomXLarge = "ContentfulBlockArticle-module--paddingBottomXLarge--ee72f";
export var paddingLarge = "ContentfulBlockArticle-module--paddingLarge--f1234";
export var paddingMedium = "ContentfulBlockArticle-module--paddingMedium--3fc76";
export var paddingSmall = "ContentfulBlockArticle-module--paddingSmall--755ab";
export var paddingXLarge = "ContentfulBlockArticle-module--paddingXLarge--93977";
export var pageArticle = "ContentfulBlockArticle-module--pageArticle--50d51";