// extracted by mini-css-extract-plugin
export var dialogContainer = "certificates-module--dialogContainer--7bc35";
export var fileHelper = "certificates-module--fileHelper--8edb1";
export var fileHelperContainer = "certificates-module--fileHelperContainer--c2379";
export var fileName = "certificates-module--fileName--49993";
export var filePicker = "certificates-module--filePicker--f9fe4";
export var filePickerContainer = "certificates-module--filePickerContainer--fd710";
export var loaderContainer = "certificates-module--loaderContainer--80d4f";
export var promptContainer = "certificates-module--promptContainer--b5e5c";
export var promptMessage = "certificates-module--promptMessage--446ec";
export var promptTitle = "certificates-module--promptTitle--13575";
export var root = "certificates-module--root--f42c7";