import React from 'react'

const User = () => (
  <svg width={28} height={28} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 28 28'>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M14.001.75C6.682.75.75 6.682.75 14.001c0 7.318 5.933 13.251 13.251 13.251 7.317 0 13.249-5.934 13.249-13.251C27.25 6.682 21.318.75 14.001.75Zm0 2.5c5.936 0 10.749 4.813 10.749 10.751 0 5.937-4.813 10.751-10.749 10.751-5.937 0-10.751-4.814-10.751-10.751C3.25 8.063 8.063 3.25 14.001 3.25Z" />
      <path d="M13.988 6.553a5.147 5.147 0 1 1 0 10.295 5.147 5.147 0 0 1 0-10.295Zm0 2.5a2.647 2.647 0 1 0 0 5.295 2.647 2.647 0 0 0 0-5.295ZM22.265 24.265a6.304 6.304 0 0 0-6.073-6.3l-.231-.004h-3.618a6.304 6.304 0 0 0-6.3 6.073l-.004.23h2.5a3.804 3.804 0 0 1 3.608-3.798l.196-.005h3.618a3.804 3.804 0 0 1 3.799 3.608l.005.196h2.5Z" />
    </g>
  </svg>

)

export default User