import React from "react"
import ProductCard from "../../molecules/ProductCard/ProductCard"

import * as styles from'./ProductGrid.module.css'

const ProductGrid = ({ products, removeFunction, overflowScroll =false ,columns = 3, currentCategory }) => {

    const columnCount = {
        gridTemplateColumns: `repeat(${columns}, 1fr)`
    }

    if (products) {
        return (
            <div className={`${styles.root} ${overflowScroll && styles.overflowScroll}`} style={columnCount}>
                {products.map((node, index) => {
                    if ('node' in node) {
                        return <ProductCard overflowScroll={overflowScroll} key={index} data={node.node} removeFunction={removeFunction} currentCategory={currentCategory} />
                    }
                    if ('sku' in node) {
                        return <ProductCard overflowScroll={overflowScroll} key={index} data={node} removeFunction={removeFunction} currentCategory={currentCategory} />
                    }
                    return null
                })}
            </div>
        )
    } else {
        return null;
    }
}

export default ProductGrid
