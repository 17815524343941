import React from 'react'

const Search = () => (
  <svg width={27} height={27} xmlns="http://www.w3.org/2000/svg" viewBox='0 0 27 27'>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M11.606.75C5.61.75.75 5.61.75 11.606.75 17.6 5.61 22.46 11.606 22.46c5.995 0 10.855-4.86 10.855-10.855C22.461 5.61 17.601.75 11.606.75Zm0 2.5a8.355 8.355 0 0 1 8.355 8.356 8.355 8.355 0 0 1-8.355 8.355 8.355 8.355 0 0 1-8.356-8.355 8.355 8.355 0 0 1 8.356-8.356Z" />
      <path d="m18.885 17.15 7.188 7.134-1.762 1.775-7.187-7.134z" />
    </g>
  </svg>

)

export default Search