import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import { readTime } from '../../../helpers/general';

import * as styles from './BlogPostCard.module.css';

const BlogPostCard = ({
  title,
  excerpt,
  content,
  thumbnail,
  slug,
  viewMode,
  size,
  categories,
  noCategories,
  titleOnly,
  date,
  highlightView
}) => {
  const isGridView = viewMode === 'grid';
  const excerptStr = useMemo(() => {
    const str = excerpt || '';

    return str
      .replace(/<a class="more-link" (.*?)>(.*?)<\/a>/g, '')
      .replace(/<p>(.*?)<\/p>/g, '$1')
      .replace(/\[...\]/g, '... ');
  }, [excerpt]);

  const classes = useMemo(() => {
    const initClass = [styles.postCard];
    if (viewMode === 'grid') {
      initClass.push(styles.gridView);
    } else {
      initClass.push(styles.listView);
    }
    if (size) {
      initClass.push(styles[size]);
    }
    return initClass.join(' ');
  }, [viewMode, size]);

  // const timeToRead = useMemo(() => {
  //   if (content) {
  //     return readTime(content);
  //   }
  //   return 0;
  // }, [content]);

  const categoriesTags = useMemo(() => {
    if (categories) {
      return categories.map((cat, catIdx) => (
        <Link to={cat.uri} key={catIdx}>
          {cat.name}
        </Link>
      ));
    }
    return [];
  }, [categories]);

  const thumbnailUrl = useMemo(() => {
    if (thumbnail) {
      return thumbnail.replace('http:', 'https:');
    }

    return thumbnail;
  }, [thumbnail])

  const renderExcerpt = (withLink = true) => {
    return (
      <div data-blogpostcard-postexcerpt>
        <span
          className={styles.postContent}
          dangerouslySetInnerHTML={{
            __html: excerptStr
          }}
        />
        {withLink && (
          <span className={styles.readMore}>
            {slug && <Link to={slug}>read more</Link>}
          </span>
        )}
      </div>
    );
  }

  const renderDate = (withCategories = true) => {
    return (
      <div data-blogpostcard-postdate className={styles.postDate}>
        {withCategories && !noCategories && categories && (
          <div className={styles.categories}>{categoriesTags}</div>
        )}
        {/* <span>{timeToRead} min Read</span> */}
        <span>{date}</span>
      </div>
    );
  }

  return (
    <div data-blogpostcard className={classes}>
      <Link to={slug}>
        <div className={styles.postImageContainer}>
          <img
            loading="lazy"
            className={styles.postImage}
            src={thumbnailUrl}
            alt={title}
          />
        </div>
      </Link>
      <div className={styles.postBody}>
        {highlightView && ( // Used for blog page with latest view
          renderDate(false)
        )}
        <div data-blogpostcard-posttitle className={styles.postTitle}>
          {slug && (
            <Link to={slug}>
              <h6>{title}</h6>
            </Link>
          )}
          {!slug && <h6>{title}</h6>}
          {!titleOnly && !highlightView && (
            renderDate()
          )}
        </div>
        {highlightView && excerptStr && ( // Used for blog page with latest view
          renderExcerpt(false)
        )}
        {excerptStr && !highlightView && !isGridView && (
          renderExcerpt()
        )}
      </div>
    </div>
  );
};

BlogPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  content: PropTypes.string,
  thumbnail: PropTypes.string,
  slug: PropTypes.string,
  authorName: PropTypes.string,
  authorUri: PropTypes.string,
  date: PropTypes.string,
  viewMode: PropTypes.string,
  size: PropTypes.string
};

BlogPostCard.defaultProps = {
  excerpt: '',
  content: '',
  size: 'medium'
};

export default BlogPostCard;
