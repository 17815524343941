import React, { useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import * as styles from './ProductCategorySlider.module.css';
import Container from '../../atoms/Container/Container';
import CustomSlider from './../CustomSlider/CustomSlider';

// turn this into component if necessary
const ProductCategoryCard = ({category}) => {
  const fallbackImage = 'https://via.placeholder.com/500x620.png?text=Place+Category+Image+Here';
  const src = category.image_url === "" ? fallbackImage : category.image_url;
  return(
    <div className={styles.categoryCardContainer}>
      <Link to={category?.custom_url?.url || '#'}>
        <img src={src} alt={`category ${category?.name}`}></img>
        <span className={styles.categoryName}>{category?.name}</span>
      </Link>
    </div>
  )
}

const ProductCategorySlider = props => {
  const { size } = props;
  const [categories, setCategories] = useState(false);

  const categoryQuery = useStaticQuery(graphql`
    query {
      productCategories: allBigCommerceCategories(
        sort: {fields: sort_order, order: ASC}
        filter: {is_visible: {eq: true}}
      ) {
      edges {
          node {
                id
                image_url
                name
                custom_url {
                  url
                }
                is_visible
              }
            }
          }
        }
      `);

  useMemo(() => {
    if (!categories) {
      setCategories(categoryQuery?.productCategories.edges || []);
    }
  }, [categoryQuery, categories]);

  return (
    <Container size={size ? size.toLowerCase(): ''}>
      <div className={ styles.ProductCategorySlider }>
        <div className={styles.hideOnMobile}>
          <CustomSlider 
            title={'Shop by Category'} 
            cards={categories}
            slidesToShow = {3}
          >
            {categories !== false && categories?.map((category) => {
              return <ProductCategoryCard key={category?.node?.id} category={category?.node} />
            })}
          </CustomSlider>
            {categories === [] && 
              <h2>No Product Categories</h2>
            } 
          </div>
          <div className={styles.overflowContainer}>
            <h3>Shop by Category</h3>
            <div className={styles.productCardContainer}>
              {categories !== false && categories?.map((category) => {
                return <ProductCategoryCard key={category?.node?.id} category={category?.node} />
              })}
            </div>
          </div>
        </div>
      </Container>
  );
};

export default ProductCategorySlider;
