import React, { useMemo, useState, useContext, useCallback } from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import AccountPageWrapper from "../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import EventSubmissionForm from "../../../components/molecules/EventSubmissionForm/EventSubmissionForm";
import AuthContext from "../../../context/AuthProvider";
import { hasStaffPermissions, persistLocation } from "../../../helpers/general";
import { wpApiNew } from "../../../helpers/wordpress";
import * as styles from '../my-usu/usu.module.css';

const Manage = ({location}) => {
    const auth = useContext(AuthContext);
    const [authed, setAuthed] = useState(null);
    const [event, setEvent] = useState(null);

    const fetchEvent = useCallback(async () => {
        const eventData = await wpApiNew('getEventData', {
            eventId: location.state.eventId,
            fieldSet: 99
        });
    
        if (String(eventData.status).startsWith('2') && eventData.response) {
            setEvent(eventData.response);
        } else {
            // Failed fetch of club data
            setEvent(false);
        }
      }, [location]);

    useMemo(() => {
        if ((!location.state || !('eventId' in location.state)) && typeof window !== 'undefined') window.location = '/account/manage-events/';
        if (authed === null && get(auth, 'state.userChecked')) {
            const isStaff = hasStaffPermissions(auth, [2, 3, 4]);
            setAuthed(isStaff);
            if (isStaff) {
                fetchEvent();
            }
        }
    }, [auth, authed, setAuthed, fetchEvent, location]);

    return (
        <>
            {(authed && event) ? (
                    <div className={`${styles.root} ${styles.withBg}`}>
                        {/* Manage Event */}
                        <EventSubmissionForm eventData={event} location={location} isStaff={true} />
                    </div>
            ) : (
                <div className={styles.root}>
                    {/* Fetching data */}
                    {((authed === null && event === null) || (authed && event === null)) && (
                        <div>Fetching data</div>
                    )}
                    {/* No data found */}
                    {(authed && event === false) && (
                        <div>An error occurred. Return back to <Link to="/account/"><u>dashboard</u></Link></div>
                    )}
                    {/* Not authorised */}
                    {authed === false && (
                        <div>You're not authorised to manage events. Return back to <Link to="/account/"><u>dashboard</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const USUEventManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper metaTitle="Account - USU Management" bgRaw breadcrumbTitle="Edit event" title={`Edit Event`}>
            <Manage location={persistedLocation} />
        </AccountPageWrapper>
    )
}

export default USUEventManagementOutput