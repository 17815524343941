import React from 'react'
import Icon from '../Icon/Icon';

import * as styles from './PayPalInfo.module.css'

const PayPalInfo = () => {
    return (
        <div className={styles.modal}>
            <div className={`${styles.header} ${styles.bottomLine}`}>
                <div className={styles.svgSection}>
                    <Icon symbol="paypal-color" />
                </div>
            </div>
            <div className={`${styles.body} ${styles.bottomLine}`}>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentAdd" />
                    </div>
                    <p className={styles.blockSubtitle}>Add</p>
                    <p className={styles.blockMessage}>Add items what you want to the bag.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentCheckout" />
                    </div>
                    <p className={styles.blockSubtitle}>Checkout</p>
                    <p className={styles.blockMessage}>Select PayPal Pay in 4 at checkout.</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        <Icon symbol="paymentRepay" />
                    </div>
                    <p className={styles.blockSubtitle}>Repay</p>
                    <p className={styles.blockMessage}>Pay off in 4 interest-free installments.</p>
                </div>
            </div>
            <div className={styles.footer}>
                <ul>
                    <li>Offer is available to eligible PayPal account holders and availability may depend on merchant, transaction type and amount.</li>
                    <li>PayPal Pay in 4 allows you to pay for eligible purchases valued between AU$30 and $1,500 in 4 interest-free installments.</li>
                    <li>Your first instalment is due at the time you make your purchase. The next 3 installments are due every 2 weeks after your first instalment.</li>
                    <li>There is no sign-up fee and repayments are made automatically from your PayPal account using the payment method you select.</li>
                    <li>You may repay the amount you owe in full at any time, without any fees or charges.</li>
                    <li>If an instalment amount is advertised, it is based on the advertised purchase price and may not include additional costs like shipping, insurance, currency conversion, etc.</li>
                    <li>When you apply, we may carry out a credit check and if you miss a repayment, it may impact your credit rating.</li>
                    <li>Please see <a href="https://www.paypal.com/au/webapps/mpp/paypal-payin4/terms" rel="noreferrer" target="_blank">PayPal Pay in 4 terms and conditions</a> for full details.</li>
                    <li>For more information about how PayPal handles your personal information please refer to the <a href="https://www.paypal.com/au/webapps/mpp/ua/privacy-full" rel="noreferrer" target="_blank">PayPal Privacy Statement</a>.</li>
                    <li>To read the Target Market Determination for this product, see the <a href="https://www.paypal.com/au/webapps/mpp/ua/legalhub-full#tmd" rel="noreferrer" target="_blank">PayPal Legal Hub</a>.</li>
                </ul>
            </div>
        </div>
    );
}

export default PayPalInfo