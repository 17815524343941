import React, { useCallback, useMemo, useState } from 'react';
import DataTable from '../DataTable/DataTable';
import { getPerson, getTags } from '../../../helpers/ortto';
import * as styles from './MembersList.module.css';

const MembersList = ({ clubSlug }) => {
  const [memberList, setMemberList] = useState(null);

  const fetchClubMembers = useCallback(async () => {
    const result = await getTags(`club-${clubSlug}`);
    if (String(result.status).startsWith('2') && result.response.length > 0) {
        const findClub = result.response.find(
          club => club.name === `club-${clubSlug}`
        );

        const memberList = await getPerson(
            ['first', 'last', 'email'],
            findClub.id,
            'tag_id'
        );

        if (
            memberList.status === 200 &&
            'contacts' in memberList.response &&
            Array.isArray(memberList.response.contacts)
        ) {
            setMemberList(memberList.response.contacts.map(member => {
                // console.log(member);
                const name = `${member.fields['str::first'] || ''} ${member.fields['str::last'] || ''}`.trim();
                return{
                    id: member.id,
                    member_name: name || '(No name set)',
                    email: member.fields['str::email']
                }
            }))
        }
    }
  }, [clubSlug]);

  useMemo(() => {
    fetchClubMembers();
  }, [fetchClubMembers]);

  return (
    <>
      {memberList ? (
        <div className={`${styles.root} ${styles.paddingHeader}`}>
          {/* Club Edit Details */}
          <DataTable
            tableData={memberList}
            allowExport
            fileName={`${clubSlug}-members.csv`}
            headingKeys={[
              { label: 'Member Name', data_key: 'member_name', sortable: true },
              { label: 'Email', data_key: 'email', sortable: true },
            ]}
          />
        </div>
      ) : (
        <div className={styles.root}>
            <div>Fetching your club members</div>
        </div>
      )}
    </>
  );
};

export default MembersList;

