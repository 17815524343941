import React from 'react';
import { get } from 'lodash';
import { decode } from 'he';
import { decodeUnicode, formatWpAddresses, getStorage } from '../general';
import LoadProducts from '../../components/organisms/LoadProducts/LoadProducts';

import { generateSubmittedDate } from '../date';
// import { de } from 'date-fns/locale';
/**
 * Wordpress API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {Object} fields         A map of fields to return
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 2 is assumed
 * @param   {String} func           (Optional) Set endpoint functionality. Valid option is 'action'
 * 
 * @return  {Object}                {response, status}
 *
    import { wpApi } from '../helpers/wordpress'

    wpApi('endpoint', {id: 'number'}, 'POST', bodyObject, version, 'action').then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
// async function wpApi(endpoint, fields = {}, method, body, version, func) {
//   const options = {
//     method: method ? method : 'GET',
//     credentials: 'same-origin',
//     mode: 'same-origin',
//   };

//   if (body) {
//     let bodyString = body;
//     if (typeof body === 'object') {
//       bodyString = JSON.stringify(body);
//     }

//     options.body = bodyString;
//   }

//   const parseJson = async response => {
//     const text = await response.text();
//     try {
//       const json = JSON.parse(text);
//       return json;
//     } catch (err) {
//       return text;
//     }
//   };

//   const userToken = getStorage('_loggedIn');

//   return await fetch(
//     `/.netlify/functions/wordpress?${version ? `v=${version}&` : ''}${
//       func === 'action' ? `action=${endpoint}` : `endpoint=${btoa(endpoint)}`
//     }&fields=${btoa(JSON.stringify(fields))}&ut=${userToken}`,
//     options
//   ).then(async res => {
//     return {
//       response: await parseJson(res),
//       headers: res.headers,
//       status: res.status,
//     };
//   });
// }

async function wpApiNew(action, body) {
  const options = {
    method: 'POST',
    credentials: 'same-origin',
    mode: 'same-origin',
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }

    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  const userToken = getStorage('_loggedIn');
  const reqHeaders = new Headers();
  reqHeaders.set("x-usu-ut", userToken);
  options.headers = reqHeaders;

  return await fetch(
    `/.netlify/functions/wordpress?${`action=${action}`}`,
    options
  ).then(async res => {
    return {
      response: await parseJson(res),
      headers: res.headers,
      status: res.status,
    };
  });
}

// async function wpAll(endpoint, fields = {}, limit = 50) {
//   async function iterate(endpoint, page, results) {
//     const result = await wpApi(
//       `${endpoint}${
//         endpoint.indexOf('?') > -1 ? '&' : '?'
//       }per_page=${limit}&page=${page}`,
//       fields
//     );
//     if (String(result.status).startsWith('2') && result.response.length > 0) {
//       const totalPages = getHeader(result.headers, 'x-wp-totalpages');
//       if (Number(page) < Number(totalPages)) {
//         return await iterate(endpoint, page + 1, [
//           ...results,
//           ...result.response,
//         ]);
//       } else {
//         return {
//           response: [...results, ...result.response],
//           headers: result.headers,
//           status: result.status,
//         };
//       }
//     } else {
//       return result;
//     }
//   }

//   return await iterate(endpoint, 1, []);
// }

async function wpAllNew(action, body) {
  async function iterate(page, results) {
    const _body = {...body, page};
    const result = await wpApiNew(action, _body);
    if (String(result.status).startsWith('2') && result.response.length > 0) {
      const totalPages = getHeader(result.headers, 'x-wp-totalpages');
      if (Number(page) < Number(totalPages)) {
        return await iterate(page + 1, [
          ...results,
          ...result.response,
        ]);
      } else {
        return {
          response: [...results, ...result.response],
          headers: result.headers,
          status: result.status,
        };
      }
    } else {
      return result;
    }
  }

  return await iterate(1, []);
}

function getHeader(headers, key) {
  for (const pair of headers.entries()) {
    if (pair[0] === key) {
      return pair[1];
    }
  }
}

function transformWpPost(post) {
  const wpComments = get(post, 'comments.nodes', []).flatMap(node => {
    const author = node.author.node;
    return { ...node, author };
  });

  let contentObj = [];

  if ('content' in post) {
    // console.log("Converted", decodeUnicode(post.content));
    const convertedContent = decodeUnicode(post.content);
    const productsRegex = /<p>\[products type="(.*)" skus="(.*)"\]<\/p>/gm;
    const shortcodes = [...convertedContent.matchAll(productsRegex)];
    // console.log(shortcodes);
    contentObj = [convertedContent];

    if (shortcodes.length > 0) {
      shortcodes.map(shortcode => {
        const contentNodes = [...contentObj];
        contentObj = [];
        contentNodes.map(contentNode => {
          if (typeof contentNode === 'string') {
            // console.log(typeof contentNode);
            const split = contentNode.split(shortcode[0]);
            const result = [];
            split.map(s => {
              result.push(s);
              result.push(
                <LoadProducts
                  columns='4'
                  displayType={shortcode[1]}
                  definitionType='Individual SKUs'
                  definitions={shortcode[2].split(',')}
                />
              );

              return true;
            });
            result.pop();
            contentObj = [...contentObj, ...result];
          } else {
            contentObj = [...contentObj, contentNode];
          }

          return true;
        });

        return true;
      });
    }
  }

  return {
    id: post.id,
    databaseId: post.databaseId,
    comments: wpComments,
    title: post.title,
    excerpt: post.excerpt,
    slug: `/blog${post.uri}`,
    content: post.content,
    contentArr: contentObj,
    author_name: post.author ? post.author.node.name : '',
    author_uri: post.author ? post.author.node.uri : '',
    published_date: post.date,
    tags: post.tags ? post.tags.nodes : [],
    categories: (post.categories.nodes || []).map(cat => ({
      ...cat,
      uri: `/blog${cat.uri}`,
    })),
    seo: post.seo || null,
    thumbnail_path: get(
      post,
      ['featuredImage', 'node', 'localFile', 'childImageSharp', 'fixed', 'src'],
      get(
        post,
        ['featuredImage', 'node', 'sourceUrl'],
        'https://via.placeholder.com/570x413?text=USU+Post'
      )
    ),
  };
}

function transformWpClub(data) {
  // console.log(data);
  return {
    status: data.status,
    id: data.id,
    databaseId: data.databaseId,
    title: decode(get(data, ['title', 'rendered']) || data.title),
    uri: data.uri || `/clubs/${data.slug}/`,
    slug: data.slug,
    excerpt: get(data, ['excerpt', 'rendered']) || data.excerpt,
    content: get(data, ['content', 'rendered']) || data.content,
    featuredImage: data.featuredImage
      ? get(data, ['featuredImage', 'node', 'publicUrl'])
      : null,
    logo: get(
      data,
      [
        'clubFieldsSide',
        'logo',
        'localFile',
        'childImageSharp',
        'fixed',
        'src',
      ],
      get(
        data,
        ['clubFieldsSide', 'logo', 'publicUrl'],
        get(data, ['acf', 'logo', 'publicUrl'], get(data, ['acf', 'logo'], null))
      )
    ),
    fees: get(data, ['acf', 'fees']) || get(data, ['clubFieldsSide', 'fees']),
    disableFreeMemberships:
      get(data, ['acf', 'disableFreeMemberships']) ||
      get(data, ['clubFieldsSide', 'disableFreeMemberships']),
    stripeConnectId:
      get(data, ['acf', 'stripeConnectId']) ||
      get(data, ['clubFieldsSide', 'stripeConnectId']),
    registered:
      get(data, ['acf', 'registered']) ||
      get(data, ['clubFieldsSide', 'registered']),
    clubStatus:
      get(data, ['acf', 'status']) || get(data, ['clubFieldsSide', 'status']),
    clubType:
      get(data, ['acf', 'type']) || get(data, ['clubFieldsSide', 'type']),
    whyJoin:
      get(data, ['acf', 'why_join']) ||
      get(data, ['clubFieldsMain', 'whyJoin']),
    fomo: get(data, ['acf', 'fomo']) || get(data, ['clubFieldsMain', 'fomo']),
    meetups:
      get(data, ['acf', 'meetups']) || get(data, ['clubFieldsMain', 'meetups']),
    socialLinks:
      get(data, ['acf', 'social_links']) ||
      get(data, ['clubFieldsMain', 'socialLinks']),
    seo: data.seo || null,
    clubTags: (get(data, ['clubTags', 'nodes']) || []).map(tag => tag),
    clubCategories: (get(data, ['clubCategories', 'nodes']) || []).map(
      cat => cat
    ),
    applicationSettings: get(data, ['acf', 'application_settings'], null),
  };
}

function transformWpEvent(data, opts = null) {
  const mediaDefaults = opts && 'clubMediaDefaults' in opts ? opts.clubMediaDefaults : null;
  return {
    status: data.status,
    id: data.id,
    databaseId: data.databaseId,
    title: get(data, ['title', 'rendered']) || data.title,
    uri: data.uri,
    slug: data.slug,
    excerpt: get(data, ['excerpt', 'rendered']) || data.excerpt,
    content: get(data, ['content', 'rendered']) || data.content,
    featuredImage: get(
      data,
      ['featuredImage', 'node', 'localFile', 'childImageSharp', 'fixed', 'src'],
      get(
        data,
        ['featuredImage', 'node', 'publicUrl'],
        get(
          data,
          [
            'eventFieldsSide',
            'club',
            'clubFieldsSide',
            'logo',
            'localFile',
            'childImageSharp',
            'fixed',
            'src',
          ],
          get(
            data,
            ['eventFieldsSide', 'club', 'clubFieldsSide', 'logo', 'sourceUrl'],
            mediaDefaults && mediaDefaults.thumbnail ? `https:${mediaDefaults.thumbnail}` : '/default-club-logo.png'
          )
        )
      )
    ),
    bannerImage: get(
      data,
      [
        'eventFieldsSide',
        'bannerImage',
        'localFile',
        'childImageSharp',
        'fixed',
        'src',
      ],
      get(
        data,
        ['eventFieldsSide', 'bannerImage', 'sourceUrl'],
        get(data, ['acf', 'banner_image', 'sourceUrl'], '')
      )
    ),
    startDate:
      get(data, ['acf', 'start_date']) ||
      get(data, ['eventFieldsSide', 'startDate']),
    endDate:
      get(data, ['acf', 'end_date']) ||
      get(data, ['eventFieldsSide', 'endDate']),
    startTime:
      get(data, ['acf', 'start_time']) ||
      get(data, ['eventFieldsSide', 'startTime']),
    endTime:
      get(data, ['acf', 'end_time']) ||
      get(data, ['eventFieldsSide', 'endTime']),
    eventFee:
      get(data, ['acf', 'event_fee']) ||
      get(data, ['eventFieldsSide', 'eventFee']),
    limitRegistrationAmount:
      get(data, ['acf', 'limit_registration_amount']) ||
      get(data, ['eventFieldsMain', 'limitRegistrationAmount']),
    enableTickets:
      get(data, ['acf', 'enable_tickets']) ||
      get(data, ['eventFieldsMain', 'enableTickets']),
    location:
      get(data, ['acf', 'location']) ||
      get(data, ['eventFieldsSide', 'location']),
    recurringEvent:
      get(data, ['acf', 'recurring_event']) ||
      get(data, ['eventFieldsSide', 'recurringEvent']),
    recurringInfo:
      get(data, ['acf', 'recurring_info']) ||
      get(data, ['eventFieldsSide', 'recurringInfo']),
    timeSlot:
      get(data, ['acf', 'time_slot']) ||
      get(data, ['eventFieldsSide', 'timeSlot']),
    roomName:
      get(data, ['acf', 'room_name']) ||
      get(data, ['eventFieldsSide', 'roomName']),
    club: get(data, ['acf', 'club']) || get(data, ['eventFieldsSide', 'club']),
    regoQuestions: {
      dayTripLength:
        get(data, ['acf', 'day_trip_length']) ||
        get(data, ['eventFieldsMain', 'dayTripLength'], false),
      dayTripTimes:
        get(data, ['acf', 'day_trip_times']) ||
        get(data, ['eventFieldsMain', 'dayTripTimes'], false),
      dietaryRequirements:
        get(data, ['acf', 'dietary_requirements']) ||
        get(data, ['eventFieldsMain', 'dietaryRequirements'], false),
      domesticInternational:
        get(data, ['acf', 'domestic_international']) ||
        get(data, ['eventFieldsMain', 'domesticInternational'], false),
      findOutDayTrips:
        get(data, ['acf', 'find_out_day_trips']) ||
        get(data, ['eventFieldsMain', 'findOutDayTrips'], false),
      futureDayTrips:
        get(data, ['acf', 'future_day_trips']) ||
        get(data, ['eventFieldsMain', 'futureDayTrips'], false),
    },
    seo: data.seo || null,
    eventTags: (get(data, ['eventTags', 'nodes']) || []).map(tag => tag),
    registrationOverride:
      get(data, ['acf', 'registration_override']) ||
      get(data, ['eventFieldsSide', 'registrationOverride']),
    ctaLabel:
      get(data, ['acf', 'cta_label']) ||
      get(data, ['eventFieldsSide', 'ctaLabel']),
    categories: (get(data, ['eventCategories', 'nodes']) || []).map(categ => categ),
  };
}

function transformWpEat(data) {
  // console.log(data);
  const menus = (get(data, ['usueatsFields', 'menus']) || []).map(menu => {
    // const publicUrl = menu.file.publicUrl.replaceAll(
    //   menu.file.filename,
    //   menu.file.localFile.base
    // );

    return {
      name: menu.name,
      file: {
        publicUrl: menu.file.sourceUrl,
      },
    };
  });

  let addresses = get(data, ['usueatsFields', 'address']);
  let stringAddress = formatWpAddresses(addresses);

  return {
    status: data.status,
    id: data.id,
    title: data.title,
    uri: data.uri,
    slug: data.slug,
    excerpt: data.excerpt,
    content: data.content,
    featuredImage: get(
      data,
      ['featuredImage', 'node', 'localFile', 'childImageSharp', 'fixed', 'src'],
      get(
        data,
        ['featuredImage', 'node', 'publicUrl'],
        'https://via.placeholder.com/420x130?text=USU+Eats'
      )
    ),
    openingHours: get(data, ['usueatsFields', 'openingHours']),
    addresses: addresses,
    formatted_address: stringAddress,
    menus: menus,
    gallery: get(data, ['usueatsFields', 'gallery']),
    seo: data.seo || null,
    eatsLocations: (get(data, ['eatsLocations', 'nodes']) || []).map(
      location => location
    ),
  };
}

function transformWpHealth(data) {
  let addresses = get(data, ['healthAndServicesFields', 'address']);
  let promotion = get(data, ['healthAndServicesFields', 'promotion']);
  let map = get(data, ['healthAndServicesFields', 'mapEmbed']);
  let stringAddress = formatWpAddresses(addresses);

  return {
    status: data.status,
    id: data.id,
    title: data.title,
    map,
    uri: data.uri,
    slug: data.slug,
    excerpt: data.excerpt,
    content: data.content,
    featuredImage: get(
      data,
      ['featuredImage', 'node', 'localFile', 'childImageSharp', 'fixed', 'src'],
      get(
        data,
        ['featuredImage', 'node', 'publicUrl'],
        'https://via.placeholder.com/420x130?text=Health+@+USU'
      )
    ),
    openingHours: get(data, ['healthAndServicesFields', 'openingHours']),
    addresses: addresses,
    promotion: promotion,
    formatted_address: stringAddress,
    seo: data.seo || null,
    eatsLocations: (get(data, ['eatsLocations', 'nodes']) || []).map(
      location => location
    ),
  };
}

async function uploadMedia(filename, data, type) {
  return wpApiNew('uploadMedia', { filename: `${filename}`, data: data, type: type }).then(
    response => {
      // console.log(response);
      return response;
    },
    rejected => console.log(rejected)
  );
}

async function authClubExec(clubId, allowRO = false) {
  return await wpApiNew('getClubData', {
    clubId,
    fieldSet: 1,
    auth: true
  }).then(club => {
    if (String(club.status).startsWith('2') && club.response) {
      const isExec = club.response.acf.isExec;

      if (isExec) {
        if (!allowRO && club.response.acf.execPosition === 'Returning Officer') {
          return false;
        }

        return club;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
}

function extractPostSummary(blog) {
  if (blog !== undefined) {
    const blogDetails = blog.node;
    return {
      excerpt: blogDetails.excerpt,
      title: blogDetails.title,
      link: `${blogDetails.link}`,
      image: blogDetails.featuredImage.node.sourceUrl,
    };
  } else {
    return {};
  }
}

function mapWordpressStatus (status) {

    switch (status) {
      case 'draft':
        // return <span>{'Pending'}</span>
        return 'Pending'
      case 'private':
        // return <span style={{color: 'var(--error)'}}>{'Rejected'}</span>
        return 'Rejected'
      case 'publish':
        // return <span style={{color: 'var(--pill-green)'}}>{'Accepted'}</span>
        return 'Accepted'
      default:
        return status
    }

}

async function createNewReceipt({
  id,
  grant,
  club,
  submitted = generateSubmittedDate(),
  dateOfPurchase,
  description = '',
  value,
  receipt,
  submittedBy,
  receiptName
}) {
  const fieldsTemplate = {
    grant: null,
    club: null,
    approved: false,
    submitted,
    date_of_purchase: null,
    description: '',
    value: null,
    submitted_by: 'Developers Matter (2351174)',
    reviewed_by: '',
  };

  const data = {
    status: 'publish',
    title: receiptName,
    content: `${receiptName}\n\n${description}`,
    fields: {
      ...fieldsTemplate,
      grant: grant,
      club: club,
      date_of_purchase: dateOfPurchase,
      description: description,
      value: value,
      submitted_by: submittedBy,
      receipt,
    },
  };

  return wpApiNew('upsertClubReceipt', {
    clubId: club,
    object: data,
    receiptId: id || false,
    fieldSet: 1
  });
}

async function deleteReceipt(clubId, receiptId) {
  return wpApiNew('deleteClubReceipt', {
    clubId,
    receiptId
  });
}

async function submitNewGrantRequest({
  club,
  submitted = generateSubmittedDate(),
  submittedBy,
  type,
  typeName,
  reason,
  associated_event,
  value,
  funding_type,
  joint_club_list,
  event_name,
  event_date,
  event_type,
  event_description,
  detail_differences,
  member_benefits,
  cs_value_add,
  spend_split,
  risk_assessment
}) {
  const fieldsTemplate = {
    acquittal: null,
    approved_by: '',
    club: null,
    reviewed_by: '',
    staff_notes: '',
    status: 'pending',
    submitted,
    submitted_by: 'Developers Matter (2351174)',
    grant_types: null,
    value_granted: 0,
    value_provided: 0,
    // additional_information: {
    //   funding_type: 'individual',
    //   joint_club_list: '',
    //   event_name: '',
    //   event_date: '',
    //   event_type: '',
    //   event_description: '',
    //   detail_differences: '',
    //   member_benefits: '',
    //   cs_value_add: '',
    //   spend_split: '',
    //   risk_assessment: null
    // }
  };

  const postData = {
    status: 'publish',
    title: `${club.clubTitle} - ${typeName} Grant Request (${submitted})`,
    content: `
      ${club.clubTitle} - ${typeName} Grant Request (${submitted})
      ${reason ? `\n\n${reason}` : ''}
      ${event_description ? `\n\n${event_description}` : ''}
    `,
    grant_types: [Number(type)],
    fields: {
      ...fieldsTemplate,
      club: club.clubId,
      submitted: submitted,
      status: 'pending',
      submitted_by: submittedBy,
      value_granted: value, 
      reason,
      associated_event,
      additional_information: {
        funding_type: funding_type,
        joint_club_list: joint_club_list,
        event_name: event_name,
        event_date: event_date,
        event_type: event_type,
        event_description: event_description,
        detail_differences: detail_differences,
        member_benefits: member_benefits,
        cs_value_add: cs_value_add,
        spend_split: spend_split,
        risk_assessment: risk_assessment
      }
    },
  };

  return wpApiNew('upsertClubGrant', {
    clubId: club.clubId,
    object: postData
  });
}

async function createNewAcquittal({
  club,
  fields,
  submitted = generateSubmittedDate()
}) {
  const fieldsTemplate = {
    club: null,
    status: 'pending',
    summary: '',
    explanation: '',
    staff_notes: '',
    grants: [],
    receipts: [],
    no_amount_spent: false,
    calculated_received: 0,
    calculated_spent: 0,
    reviewed_by: '',
    approved: false,
    approved_by: '',
    submitted,
    submitted_by: 'Developers Matter (2351174)',
  };

  const data = {
    status: 'publish',
    title: `${club.clubTitle} Acquittal (${submitted})`,
    content: `${club.clubTitle} Acquittal (${submitted})`,
    fields: {
      ...fieldsTemplate,
      ...fields
    },
  };

  const { response: acquittal } = await wpApiNew('upsertClubAcquittal', {
    clubId: club.clubId,
    object: data
  });
  // console.log(acquittal);
  const grants = fields.grants.map(async g => {
    await wpApiNew('upsertClubGrant', {
      clubId: club.clubId,
      grantId: g.grant,
      object: {fields: {acquittal: acquittal.id}}
    });
    return true;
  });

  return Promise.all(grants).then(() => {
    return acquittal;
  })
}

export {
  // wpApi,
  wpApiNew,
  // wpAll,
  wpAllNew,
  getHeader,
  transformWpPost,
  transformWpClub,
  transformWpEvent,
  transformWpEat,
  extractPostSummary,
  authClubExec,
  uploadMedia,
  transformWpHealth,
  mapWordpressStatus,
  submitNewGrantRequest,
  createNewReceipt,
  createNewAcquittal,
  deleteReceipt,
};

