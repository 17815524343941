import React from 'react'
import Icon from '../Icon/Icon';

import Step1 from './step-1.jpg';
import Step2 from './step-2.jpg';
import Step3 from './step-3.png';

import * as styles from './AfterPayInfo.module.css'

const AfterPayInfo = () => {
    return (
        <div className={styles.modal}>
            <div className={`${styles.header} ${styles.bottomLine}`}>
                <p className={styles.intro}>Installments by</p>
                <div className={styles.svgSection}>
                    <Icon symbol="afterPayLogo" />
                </div>
                
                <div className={styles.titles}>
                    <h4>SHOP NOW</h4>
                    <h4>ENJOY NOW</h4>
                    <h4>PAY LATER</h4>
                </div>
                <p>4 interest-free payments, every two weeks, at checkout</p>
            </div>
            <div className={`${styles.body} ${styles.bottomLine}`}>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        {Step1 && (
                            <img loading="lazy" src={Step1} alt="Choose AfterPay as the payment method" />
                        )}
                    </div>
                    <p className={styles.blockSubtitle}>At checkout, select Afterpay as your payment method</p>
                    <p className={styles.blockMessage}>No new cards, use your own existing payment card</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        {Step2 && (
                            <img loading="lazy" src={Step2} alt="Complete checkout" />
                        )}
                    </div>
                    <p className={styles.blockSubtitle}>Simply complete your checkout</p>
                    <p className={styles.blockMessage}>No long forms, instant approval decision</p>
                </div>
                <div className={styles.block}>
                    <div className={styles.imgContainer}>
                        {Step3 && (
                            <img loading="lazy" src={Step3} alt="Pay in 4 installments" />
                        )}
                    </div>
                    <p className={styles.blockSubtitle}>Pay in 4 installments</p>
                    <p className={styles.blockMessage}>Pay every 2 weeks, enjoy your purchase right away</p>
                </div>
            </div>
            <div className={styles.footer}>
                <p>You must be over 18, a resident of the country offering Afterpay, and meet additional eligibility criteria to qualify. Late fees apply. Your first installment may be due at the time of purchase. Estimated payment amounts shown on product pages exlude taxes and shipping charges, which are added at checkout. For complete terms in AU/NZ visit <a href="https://www.afterpay.com/en-AU/terms-of-service" target="_blank" rel="noreferrer">afterpay.com/terms</a> </p>
            </div>
        </div>
    );
}

export default AfterPayInfo