// extracted by mini-css-extract-plugin
export var block = "PayPalInfo-module--block--cb021";
export var blockMessage = "PayPalInfo-module--blockMessage--f6380";
export var blockSubtitle = "PayPalInfo-module--blockSubtitle--63f56";
export var body = "PayPalInfo-module--body--333c9";
export var bottomLine = "PayPalInfo-module--bottomLine--b613e";
export var footer = "PayPalInfo-module--footer--36cf5";
export var header = "PayPalInfo-module--header--1e1f2";
export var imgContainer = "PayPalInfo-module--imgContainer--0dcd3";
export var intro = "PayPalInfo-module--intro--4395f";
export var modal = "PayPalInfo-module--modal--7089e";
export var svgSection = "PayPalInfo-module--svgSection--4126d";
export var titles = "PayPalInfo-module--titles--29b8b";
export var zipMoney = "PayPalInfo-module--zipMoney--02013";
export var zipPay = "PayPalInfo-module--zipPay--ef532";